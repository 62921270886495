import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Button } from "semantic-ui-react";
import Toggle from "../../../../common/Toggle";
import {
  Device,
  DeviceFilters,
  SearchDeviceResponse,
  triggerDeviceAction,
} from "../../../../../BytebeamClient";
import { OptionType } from "../../util";
import LoadingAnimation from "../../../../common/Loader";
import { Mixpanel } from "../../../common/MixPanel";
import { useHistory, useRouteMatch } from "react-router-dom";
import { StyledHeader } from "../SelectableItem";
import SummaryComponent from "./SummaryComponent";
import PhaseControlSection from "./PhaseControlSection";
import CreateActionSection from "./CreateActionSection";
import { CardContainer } from "../LiveActions";
import UnPhasedControlSection from "./UnPhasedControlSection";
import AdvanceSettingsSection from "./AdvanceSettingsSection";
import { validateTimestampInterval } from "../../../util";

import _ from "lodash";

export type PhaseInfo = {
  type: "fixed-list" | "filter-fraction-lazy" | "filter-fraction";
  device_ids?: number[];
  filter?: Record<string, string[]>;
  fraction?: number;
};

export type PhaseData = {
  id: number;
  name: string;
  trigger_on: {
    timestamp: Date | number;
  };
  info: PhaseInfo;
  status?: string;
  icon?: any;
};

type ScheduleData = {
  retry_on_failure_until: number | Date;
  release_notes: string;
  end_timestamp: Date | number;
  phases: Omit<PhaseData, "id">[];
};

type ActionRequestBody = {
  action: string;
  search_type: "default" | "all" | "allSearch";
  params: Record<string, any> | string;
  schedule?: ScheduleData;
  device_ids?: string[];
  search_key?: string;
  search_query?: string;
  metadata?: Record<string, string[]>;
};

export const CreateActionContainer = styled.div<{ marginBottom?: string }>`
  display: flex;
  width: 100%;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "25px"};
`;

export const NewActionLabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const NewActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledNonBoldHeader = styled(StyledHeader)`
  display: flex;
  align-items: center;
  margin-top: 0px;
  font-size: 18px;
  font-weight: normal;
  white-space: nowrap;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledButtons = styled(Button)`
  width: 300px;
  white-space: nowrap;
`;

export default function NewAction() {
  const history = useHistory();
  const matchedPath = useRouteMatch("/projects/:tenant/actionsv3");
  const currentDateTime = new Date();
  const nextYearTimestamp = new Date(
    new Date().setFullYear(new Date().getFullYear() + 1)
  ).getTime();

  const [mainLoading, setMainLoading] = useState<boolean>(false);

  // ------------------------- States for action creation START ------------------------- //
  const [actionName, setActionName] = useState<string>("");
  const [action, setAction] = useState<string>("");
  const [optionType, setOptionType] = useState<OptionType>(
    OptionType.NoOptionSelected
  );
  const [payload, setPayload] = useState<Record<string, any> | string>("");
  const [selectedVersion, setSelectedVersion] = useState<string>("");
  // -------------------------- States for action creation END -------------------------- //

  // ------------------------- States for phased rollout START ------------------------- //
  const [isPhasedRollout, setIsPhasedRollout] = useState<boolean>(false);
  const [phasesData, setPhasesData] = useState<PhaseData[]>([
    {
      id: 1,
      name: "Phase I",
      trigger_on: {
        timestamp: new Date(currentDateTime.getTime() + 30 * 60 * 1000), // After 30 minutes
      },
      info: {
        type: "fixed-list", //other phase type are filter-fraction-lazy and filter-fraction
        device_ids: [],
      },
    },
  ]);
  const [retryUntil, setRetryUntil] = useState<Date | number>(0);
  const [releaseNotes, setReleaseNotes] = useState<string>("");
  const [endTimestamp, setEndTimestamp] = useState<Date | number>(
    nextYearTimestamp
  );

  // -------------------------- States for phased rollout END -------------------------- //

  // ------------------------- States for unphased device selection START ------------------------- //
  const [filters, setFilters] = useState<DeviceFilters>({});
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [selectedDevices, setSelectedDevices] = useState<SearchDeviceResponse>({
    devices: [],
    count: 0,
  });
  // -------------------------- States for unphased device selection END -------------------------- //

  const handlePhasedRolloutToggleClick = useCallback(
    (e) => {
      setIsPhasedRollout(!isPhasedRollout);
    },
    [isPhasedRollout]
  );

  const getStringifiedDeviceIDs = (deviceIDArr) => {
    let arr: string[] = [];
    deviceIDArr.map((deviceID: Device) => arr.push(String(deviceID.id)));
    return arr;
  };

  const determineDeviceSelection = () => {
    let deviceArray: SearchDeviceResponse = { devices: [], count: 0 };
    let deviceIDs: string[] = [];
    let searchType: "default" | "all" | "allSearch" = "default";
    let searchKey: string = "";
    let searchQuery: string = "";
    let metadata = {};

    if (allSelected) {
      if (Object.keys(filters).length !== 0) {
        searchType = "allSearch";
        searchKey = Object.keys(filters)[0];
        searchQuery = Object.values(filters)[0][0];
        metadata = { ...filters };
      } else {
        searchType = "all";
      }
    } else {
      deviceArray = selectedDevices;
      deviceIDs = getStringifiedDeviceIDs([...Array.from(deviceArray.devices)]);
    }

    return {
      deviceIDs,
      searchType,
      searchKey,
      searchQuery,
      metadata,
    };
  };

  const isDeviceSelectedInPhases = (phase: Omit<PhaseData, "id">) => {
    const { type, device_ids } = phase.info;

    if (type === "fixed-list" && device_ids && device_ids.length === 0) {
      window.toastr.error(`No devices selected for phase: ${phase.name}`);
      return false;
    }

    return true;
  };

  const isDeviceSelectedBool = (
    body: ActionRequestBody,
    actionType: string
  ) => {
    const isDeviceSelectedPhases = body?.schedule?.phases.every((phase) =>
      isDeviceSelectedInPhases(phase)
    );

    if (
      !isPhasedRollout &&
      body?.search_type === "default" &&
      body?.device_ids?.length === 0
    ) {
      window.toastr.error(`No devices selected for action: ${actionType}`);
      return false;
    } else if (isPhasedRollout && !isDeviceSelectedPhases) {
      return false;
    } else {
      return true;
    }
  };

  const createScheduleObject = () => {
    let scheduleObject: ScheduleData = {
      retry_on_failure_until: retryUntil,
      release_notes: releaseNotes,
      end_timestamp: endTimestamp,
      phases: phasesData,
    };

    scheduleObject.phases = phasesData.map((phase) => {
      const deepCopiedPhase: PhaseData = _.cloneDeep(phase);
      const { id, ...restPhaseData } = deepCopiedPhase;

      let phaseInfo = { ...restPhaseData.info };

      if (phaseInfo.type === "fixed-list") {
        restPhaseData.info = {
          type: phaseInfo.type,
          // @ts-ignore
          device_ids: phaseInfo?.device_ids
            ?.toString()
            .split(",")
            .filter(Boolean),
        };
      } else if (
        phaseInfo.type === "filter-fraction-lazy" ||
        phaseInfo.type === "filter-fraction"
      ) {
        restPhaseData.info = {
          type: phaseInfo.type,
          filter: phaseInfo.filter,
          fraction: phaseInfo.fraction,
        };
      }

      restPhaseData.trigger_on.timestamp =
        restPhaseData.trigger_on.timestamp instanceof Date
          ? restPhaseData.trigger_on.timestamp.getTime()
          : new Date(restPhaseData.trigger_on.timestamp).getTime();

      return restPhaseData;
    });

    return scheduleObject;
  };

  const fractionSumValidation = () => {
    const filterFractions = {};
    const errorPhases = {};
    phasesData.forEach((element) => {
      // Check if the type is 'fixed-list'

      if (
        element.info.type === "fixed-list" ||
        element.info.filter === undefined
      ) {
        return;
      }
      // Process the filter and fraction
      // sort the filter keys and values and making it array before stringifying
      const sortedFilter = Object.entries(element.info.filter)
        .map(([key, value]) => [key, value.slice().sort()])
        .sort();
      const filterValue = JSON.stringify(sortedFilter);
      const fractionValue = element.info.fraction;

      // Update the sum of fractions for each filter
      if (filterFractions.hasOwnProperty(filterValue)) {
        filterFractions[filterValue] += fractionValue;
        errorPhases[filterValue].push(element.name);
      } else {
        filterFractions[filterValue] = fractionValue;
        errorPhases[filterValue] = [element.name];
      }
    });

    // Check if any filter's fractions do not sum to 100
    for (const filter in filterFractions) {
      if (filterFractions[filter] !== 100) {
        return { result: false, filter: filter, phases: errorPhases[filter] }; // Return the filter for which the sum is not 100
      }
    }

    return { result: true, filter: null, phases: null };
  };

  const createRequestBody = (
    actionType: string,
    params: Record<string, any> | string
  ) => {
    let requestBody: ActionRequestBody = {
      action: actionType,
      params,
      search_type: "default",
    };

    if (isPhasedRollout) {
      const scheduleObject = createScheduleObject();

      requestBody = {
        ...requestBody,
        schedule: scheduleObject,
      };
    } else {
      const { deviceIDs, searchType, searchKey, searchQuery, metadata } =
        determineDeviceSelection();

      requestBody = {
        ...requestBody,
        device_ids: deviceIDs,
        search_type: searchType,
        search_key: searchKey,
        search_query: searchQuery,
        metadata,
      };
    }

    return requestBody;
  };

  const triggerAction = async (
    actionType: string,
    params: Record<string, any> | string = {}
  ) => {
    const allValidIntervals = phasesData.every((phase) =>
      validateTimestampInterval(
        phase.trigger_on.timestamp,
        phase.name,
        phasesData
      )
    );

    if (
      (isPhasedRollout &&
        allValidIntervals &&
        fractionSumValidation().result) ||
      !isPhasedRollout
    ) {
      const body = createRequestBody(actionType, params);
      const isDeviceSelected = isDeviceSelectedBool(body, actionType);
      try {
        if (isDeviceSelected) {
          setMainLoading(true);
          window.toastr.info(`Triggering action: ${actionType}`);
          await triggerDeviceAction(body);
          window.toastr.success(`${actionType} triggered successfully!`);
          Mixpanel.track("Triggered Action", { action: actionType });
          history.push(`${matchedPath?.url}/live-actions`);
        }
      } catch (error) {
        console.error(error);
        window.toastr.error(`Error in triggering action: ${actionType}`);
        Mixpanel.track("Failure", {
          type: `trigger action ${actionType}`,
          error: JSON.stringify(error),
        });
      } finally {
        setMainLoading(false);
      }
    } else {
      if (!allValidIntervals) {
        window.toastr.error(
          `The time gap between consecutive phase's trigger time, should be a minimum of 5 minutes.`
        );
      } else if (!fractionSumValidation().result) {
        window.toastr.error(
          `The fraction of ${
            fractionSumValidation().phases ?? " same filters in phases "
          } should add upto 100%.`
        );
      }
    }
  };

  const executeAction = () => {
    if (action === "") {
      window.toastr.error(`Please select an action to trigger`);
    } else {
      switch (optionType) {
        case OptionType.ChooseFirmware:
          if (selectedVersion !== "")
            triggerAction("update_firmware", {
              version: selectedVersion,
            });
          else window.toastr.error(`Please select a firmware version`);
          break;
        case OptionType.UploadFirmware:
          if (selectedVersion !== "")
            triggerAction("update_firmware", {
              version: selectedVersion,
            });
          else window.toastr.error(`Please upload a firmware file`);
          break;

        case OptionType.ChooseConfig:
          if (selectedVersion !== "")
            triggerAction("update_config", {
              version: selectedVersion,
            });
          else window.toastr.error(`Please select a JSON config version`);
          break;
        case OptionType.UploadConfig:
          if (selectedVersion !== "")
            triggerAction("update_config", {
              version: selectedVersion,
            });
          else window.toastr.error(`Please create a JSON config version`);
          break;

        case OptionType.ChooseGeofence:
          if (selectedVersion !== "")
            triggerAction("update_geofence", {
              version: selectedVersion,
            });
          else window.toastr.error(`Please select a GeoFence config version`);
          break;
        case OptionType.UploadGeofence:
          if (selectedVersion !== "")
            triggerAction("update_geofence", {
              version: selectedVersion,
            });
          else window.toastr.error(`Please create a GeoFence config version`);
          break;

        case OptionType.SendFile:
          if (selectedVersion !== "")
            triggerAction("send_file", {
              id: selectedVersion,
            });
          else window.toastr.error(`Please upload file`);
          break;
        case OptionType.SendScript:
          if (selectedVersion !== "")
            triggerAction("send_script", {
              id: selectedVersion,
            });
          else window.toastr.error(`Please upload script`);
          break;

        case OptionType.UploadCommonConfig:
          if (payload !== "") {
            triggerAction(action, payload);
          } else window.toastr.error(`Please upload JSON payload`);
          break;
        case OptionType.UploadText:
          if (payload !== "") {
            triggerAction(action, payload);
          } else window.toastr.error(`Please upload text payload`);
          break;
        case OptionType.NoPayloadOption:
          triggerAction(action, {});
          break;

        default:
          break;
      }
    }
  };

  return mainLoading ? (
    <LoadingAnimation
      loaderContainerHeight="80vh"
      loadingText="Loading..."
      fontSize="20px"
      loaderSize="48px"
    />
  ) : (
    <>
      <CreateActionSection
        action={action}
        setAction={setAction}
        optionType={optionType}
        setOptionType={setOptionType}
        actionName={actionName}
        setActionName={setActionName}
        selectedVersion={selectedVersion}
        setSelectedVersion={setSelectedVersion}
        payload={payload}
        setPayload={setPayload}
      />

      <CreateActionContainer marginBottom={isPhasedRollout ? "2px" : "25px"}>
        <CardContainer marginBottom="5px">
          <NewActionWrapper>
            <StyledHeader
              as="h2"
              style={{ marginTop: "0px", marginBottom: "30px" }}
            >
              Device Details
            </StyledHeader>

            <div
              style={{
                paddingLeft: "15px",
              }}
            >
              <NewActionLabelContainer style={{ marginBottom: "20px" }}>
                <StyledNonBoldHeader
                  as="h3"
                  style={{
                    marginBottom: "15px",
                  }}
                >
                  Phased Rollout
                </StyledNonBoldHeader>
                <div style={{ marginLeft: "40px", paddingBottom: "5px" }}>
                  <Toggle
                    id="action-toggle"
                    size="large"
                    bgColor="#05DB0A"
                    checked={isPhasedRollout}
                    onChange={handlePhasedRolloutToggleClick}
                  />
                </div>
              </NewActionLabelContainer>

              {isPhasedRollout ? (
                <PhaseControlSection
                  phasesData={phasesData}
                  setPhasesData={setPhasesData}
                />
              ) : (
                <UnPhasedControlSection
                  selectedDevices={selectedDevices}
                  setSelectedDevices={setSelectedDevices}
                  filters={filters}
                  setFilters={setFilters}
                  allSelected={allSelected}
                  setAllSelected={setAllSelected}
                />
              )}
            </div>
          </NewActionWrapper>
        </CardContainer>
      </CreateActionContainer>
      {isPhasedRollout && (
        <AdvanceSettingsSection
          endTimestamp={endTimestamp}
          releaseNotes={releaseNotes}
          setEndTimestamp={setEndTimestamp}
          setReleaseNotes={setReleaseNotes}
          setRetryUntil={setRetryUntil}
        />
      )}

      <CreateActionContainer>
        <CardContainer marginBottom="5px">
          <SummaryComponent
            phasesData={phasesData}
            isPhasedRollout={isPhasedRollout}
            determineDeviceSelection={determineDeviceSelection}
            action={action}
            optionType={optionType}
            selectedVersion={selectedVersion}
            actionName={actionName}
          />
        </CardContainer>
      </CreateActionContainer>

      <ButtonsContainer>
        <StyledButtons
          primary
          size="big"
          onClick={() => {
            executeAction();
          }}
        >
          {isPhasedRollout ? "Request Approval" : "Trigger Action"}
        </StyledButtons>
      </ButtonsContainer>
    </>
  );
}
