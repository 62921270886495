import React, { ReactNode } from "react";
import { Icon } from "semantic-ui-react";
import { IconSizeProp } from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";
import styled from "styled-components";

type ErrorMessageProps = {
  message?: ReactNode | string;
  messageLess?: boolean;
  errorMessage?: boolean;
  fontSize?: string;
  marginTop?:string;
  marginBottom?:string;
  textMarginTop?: string;
  textColor?: string;
  iconColor?: string;
  iconSize?: IconSizeProp;
};

export const ErrorMessage = (props: ErrorMessageProps) => {
  const {
    message,
    messageLess,
    errorMessage,
    fontSize,
    textMarginTop,
    textColor,
    iconColor,
    marginTop,
    marginBottom,
    iconSize
  } = props;

  const ErrorMessageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: ${marginTop ? marginTop : "10px"};
    margin-bottom: ${marginBottom ? marginBottom : "10px"};
    font-size: 26px;
    color: ${iconColor ? iconColor : ({ theme }) => theme.colors["text"]};
    h3 {
      font-size: ${fontSize ? fontSize : "20px"};
      color: ${textColor ? textColor : ({ theme }) => theme.colors["text"]};
      margin-top: ${textMarginTop ? textMarginTop : "10px"};
    }
  `;

  return (
    <ErrorMessageContainer>
      {errorMessage ? (
        <Icon name="warning circle" size={iconSize?iconSize:"big"} />
      ) : (
        <Icon name="info circle" size={iconSize?iconSize:"big"} />
      )}
      {message ? (
        <h3>{message}</h3>
      ) : messageLess ? (
        <></>
      ) : (
        <h3>
          Something went wrong! Please contact{" "}
          <a href="mailto:support@bytebeam.io"> support@bytebeam.io </a> from
          your registered email id
        </h3>
      )}
    </ErrorMessageContainer>
  );
};