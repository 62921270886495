import React, { useEffect } from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/theme-chaos";
import { OptionType } from "../util";
import ThemeSchema from "../../../../theme/schema";

type UploadConfigProps = {
  setDisableNextButton: (arg0: boolean) => void;
  setOptionType: (arg0: OptionType) => void;
  optionType: OptionType;
  uploadTriggered: string;
  setUploadTriggered: (arg0: string) => void;
  uploadTriggeredEach: boolean;
  setActiveModal: (arg0: number) => void;
  activeModal: number;
  customInputJSONData: string;
  setCustomInputJSONData: (arg0: string) => void;
  theme: string;
};

export default function UploadConfig(props: UploadConfigProps) {
  const { theme } = props;

  const isJSONValid = (str: string) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (!isJSONValid(props.customInputJSONData)) {
      props.setUploadTriggered("");
      window.toastr.error("Your Configuration JSON is invalid");
    } else {
      // valid data, move ahead
      props.setCustomInputJSONData(props.customInputJSONData);
      props.setActiveModal(props.activeModal + 1);
    }
  };

  useEffect(() => {
    props.setUploadTriggered("");
    if (props.customInputJSONData)
      props.setOptionType(OptionType.UploadCommonConfig);
    else props.setOptionType(OptionType.NoOptionSelected);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.uploadTriggered === "trigger") {
      if (!props.customInputJSONData) {
        window.toastr.error("Please add a JSON config");
        props.setUploadTriggered("");
      }
      switch (props.optionType) {
        case OptionType.UploadCommonConfig:
          handleSubmit();
          break;
      }
    }
  }, [props.uploadTriggered, props.uploadTriggeredEach]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <p style={{ fontSize: "1.2rem" }}>Enter custom payload(JSON)</p>
      <AceEditor
        height="300px"
        width="100%"
        placeholder={"Enter custom JSON payload"}
        mode="json"
        theme={ThemeSchema.data[theme ?? "dark"]?.colors["ace-editor-theme"]}
        name="custom-json"
        fontSize={14}
        value={props.customInputJSONData}
        onChange={(val) => {
          props.setOptionType(OptionType.UploadCommonConfig);
          props.setCustomInputJSONData(val);
        }}
        showPrintMargin={false}
        showGutter={true}
        highlightActiveLine={true}
        setOptions={{
          enableBasicAutocompletion: false,
          enableLiveAutocompletion: false,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
        }}
        style={{
          marginBottom: "16px",
          borderRadius: "4px",
          border: `${
            ThemeSchema.data[theme ?? "dark"]?.colors["ace-editor-border"]
          }`,
          boxShadow: `${
            ThemeSchema.data[theme ?? "dark"]?.colors["ace-editor-box-shadow"]
          }`,
        }}
      />
    </>
  );
}
