import { SemanticICONS } from "semantic-ui-react";
import { AbsoluteTimeRange } from "./components/Screens/Dashboards/Datetime/TimeRange";
import { DurationInputArg2 } from "moment";

declare global {
  interface Window {
    toastr: any;
    _archbee: any;
  }
}

export type Permission = {
  devices: { [key: string]: "all" | string[] };
  tables: { [key: string]: "all" | string[] };
  homepage: string;

  viewMetadata: "all" | string[];
  editMetadata: "all" | string[];

  dashboardPermittedShareRoles: "all" | number[];

  createDashboards: boolean;

  allowedActions: "all" | string[];

  viewFiles: boolean;
  editFiles: boolean;

  viewFirmwares: boolean;
  editFirmwares: boolean;

  viewDeviceConfigs: boolean;
  editDeviceConfigs: boolean;

  viewStreams: boolean;
  editStreams: boolean;

  viewUsers: boolean;
  editUsers: boolean;

  viewRoles: boolean;
  editRoles: boolean;

  viewActionTypes: boolean;
  editActionTypes: boolean;

  showDeviceManagementTab: boolean;
  showDashboardsTab: boolean;
  showActionsTab: boolean;

  viewMetadataKeys: boolean;
  editMetadataKeys: boolean;
  editTenantSettings: boolean;

  allowCreatingDevices: boolean;
  allowMarkActionAsCompleted: boolean;
};

export type Role = {
  id: number;
  name: string;
  permissions: Permission;
};

export type ApiKey = {
  key?: any;
  role: string;
  created_by: string;
};

export type SimpleConditionOperator =
  | ">"
  | "<"
  | "="
  | "!="
  | ">="
  | "<="
  | "in";
export type CompositeConditionOperator = "and" | "or";
export type NoDateConditionOperator = "no_data";

export type SimpleCondition = {
  field: string;
  operator: SimpleConditionOperator;
  value: any;
};

export type CompositeCondition = {
  operator: CompositeConditionOperator;
  conditions: Array<Condition>;
};

export type NoDataCondition = {
  operator: NoDateConditionOperator;
};

export type Condition = SimpleCondition | CompositeCondition | NoDataCondition;

export type SessionType = {
  name: string;
  stream: string;
  condition: Condition;
  status?: string;
  last_processed_timestamps?: { [key: number]: string };
};

export type SlackNotificationParams = {
  slack_channel: string;
  bot_token: string;
};

export type WebhookNotificationParams = {
  url: string;
  headers: { [key: string]: string };
};

export type EmailNotificationParams = {
  emails: string[];
};

export type SmsNotificationParams = {
  phone_numbers: string[];
};

export type NotificationChannelParameters =
  | SlackNotificationParams
  | WebhookNotificationParams
  | EmailNotificationParams
  | SmsNotificationParams;

export type AlertNotificationRule = {
  id?: string;
  alert_rule_id: string;
  channel_type: NotificationChannelType;
  channel_parameters: NotificationChannelParameters;
  interval_seconds: number;
  last_notified_at?: number;
  notification_template: string;
};

export type AlertRuleCriticality = "critical" | "warning" | "info";

export interface AlertRule {
  id: string;
  name: string;
  criticality: AlertRuleCriticality;
  metadata_filters: { [key: string]: string[] };
  stream: string;
  condition: Condition;
  threshold_seconds: number;

  status?: string;
  last_processed_timestamps?: { [key: number]: string };
  notification_rules?: AlertNotificationRule[];
}

export type NotificationChannelType = "slack" | "webhook" | "email" | "sms";

export interface IRoleResult {
  result: Array<Role>;
}

export type TenantSettings = {
  common_settings: {
    pin_metadata: Array<string>;
  };
  dashboard_settings: {
    custom_time_ranges: {
      [x: string]:
        | AbsoluteTimeRange
        | {
            to: {
              date: string;
            };
            from: {
              date: string;
            };
          }
        | {
            to: {
              units: DurationInputArg2;
              duration: number;
            };
            from: {
              units: DurationInputArg2;
              duration: number;
            };
          };
    };
  };
  hardware_type: string;
};

export type UserSettings = {
  theme: string;
  current_release_id: string;
  changelog_id: string;
  get_started: {
    deviceType: {
      [deviceType: string]: {
        [key: string]: boolean;
      };
    };
    selectedDevice: string | undefined;
    skip: boolean;
  };
};

export type User = {
  id: string;
  name: string;
  email: string;
  role: Role;
  tenants: Array<string>;
  "tenant-settings"?: TenantSettings;
  settings?: UserSettings;
};

export interface IUser {
  name: string;
  email: string;
  roles: [number];
}

export interface IUserResult {
  result: { [key: string]: IUser };
}

export type Settings = {
  vin_number?: string;
  tenancy: "multi" | "single";
};

export type ActionType = {
  type: string;
  icon: SemanticICONS;
  payload_type: string;
  json_schema?: string | null;
  json_ui_schema?: string | null;
};

export interface Auth {
  url: string;
  type: string;
}

export function timeoutDelay(delay: number) {
  return new Promise((res) => setTimeout(res, delay));
}

// ====================== Archbee Pages Id ======================
export const urlToDocId = {
  devices: "Sodvoyqp20K79NtIZY0n4",
  firmwares: "RsPbeTeys4F9tW3FePVFA",
  configurations: "uiMrVaHAbtQ6GQeGUneQf",
  edit: "uiMrVaHAbtQ6GQeGUneQf",
  "action-status": "nulqEPayNrnDFmqWjfOGD",
  dashboards: "WrtREge7-Q0pOMril2Ybv",
  roles: "l9IpVOZ0hXo2eJ-9GsFDo",
  users: "Y0cjSH9vUM6ssv9T3i4vi",
  streams: "4HHSTvQ8AsNdQcB2rMW8s",
  metadata: "SJzWijmAQbtC4oWXHfBlw",
  action_types: "2On-vZrFiNiGOKBL7YN7b",
  api_keys: "b4N4-gb1MgrdUXTgs1ed0",
  session_types: "EfrWg45A3lRKRGg_OoeUi",
  alerts: "PGHbqYxK_QWTKPWwWT1E_",
  alert_rules: "PGHbqYxK_QWTKPWwWT1E_",
  "alert-notification": "PGHbqYxK_QWTKPWwWT1E_",

  // ACTION_V2
  inventory: "Q5k99r37BNTjWFOCdxut_",
  "action-summary": "nTXQubaX06CyPrvF1SHwy",
  "action-overview": "nTXQubaX06CyPrvF1SHwy",
  "device-overview": "nTXQubaX06CyPrvF1SHwy",
  "firmware-files": "IsAoIC5-7ftNjTTcO55ih",
  "json-configurations": "g_fyYE2WlcdWK72fBi_eQ",
  "geofence-configurations": "vjO_zNGRzJZ6Ko_pG2Rne",
};
// ==================== Archbee Pages Id Ends ====================

export function dropDownOptionsFromArray(array: string[]) {
  let options = [
    {
      key: "",
      value: "",
      text: "No options available",
    },
  ];
  if (array !== undefined && array.length > 0) {
    options = array.map((s) => {
      return {
        key: s,
        value: s,
        text: s,
      };
    });
  }
  return options;
}

export const currentReleaseId = "KiCXd96EuW-DJX44f2yiQ";

// Email ID validation
export function validateEmail(email: string): boolean {
  // Validates that the email address starts with one or more characters that are not whitespace or '@',
  // followed by the '@' symbol, followed by one or more characters that are not whitespace or '@',
  // and ends with a dot ('.') followed by one or more characters that are not whitespace or '@'
  const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // [^\s@]+ Matches one or more characters that are not whitespace or '@'
  return emailRegex.test(email);
}

// Email ID validation, more restricted
export const validateRestrictedEmail = (emailString: string): boolean => {
  const restrictedEmailRegex: RegExp =
    /^(?!.*\.{2})[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return restrictedEmailRegex.test(emailString);
};

// Whole Number Validation
export function validateWholeNumber(number: string): boolean {
  const wholeNumberRegex: RegExp = /^\d+$/;
  return wholeNumberRegex.test(number);
}

// Phone Number validation
export const validatePhoneNumber = (phoneNumber: string): boolean => {
  // This regex enforces the presence of a plus sign at the beginning (^\+), allows for 1 to 3 digits for the country code ([0-9]{1,3}), and then allows for optional characters like parentheses, hyphens, or spaces ([()\-\s]?). The rest of the digits are matched using [0-9]+. This pattern provides more flexibility for different formats of phone numbers while ensuring the plus sign for the country code.
  const phoneRegex: RegExp = /^\+[0-9]{1,3}[()\-\s]?[0-9]+$/;
  return phoneRegex.test(phoneNumber);
};

// Name validation
export function validateName(name: string): boolean {
  // [a-zA-ZÀ-ÖØ-öø-ÿ']+ Matches one or more alphabetic characters (uppercase or lowercase), accented characters, apostrophes, or hyphens.
  const nameRegex: RegExp = /^[a-zA-ZÀ-ÖØ-öø-ÿ']+(\s[a-zA-ZÀ-ÖØ-öø-ÿ']+)*$/;
  return nameRegex.test(name);
}

// Hiding Get Started for enterprise users
export const showGetStartedForHost = [
  "cloud.bytebeam.io",
  "stage.bytebeam.io",
].includes(window.location.host);
