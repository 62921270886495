import React, { useCallback, useEffect, useState } from "react";
import DeviceSelectionSection from "./DeviceSelectionSection";
import LoadingAnimation from "../../../../common/Loader";
import ActionFilterSection from "./ActionFilterSection";
import {
  Device,
  DeviceFilterOption,
  DeviceFilters,
  Key,
  SearchDeviceResponse,
  fetchAllMetadataKeys,
  fetchDeviceFilterOptions,
  searchDevices,
} from "../../../../../BytebeamClient";
import { FilterListObject } from "../../action-modals/NewActionModal";
import { ErrorMessage } from "../../../../common/ErrorMessage";
import styled from "styled-components";

const PhaseSectionContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const PhasesMenuWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 40px;
`;

type UnPhasedControlSectionProps = {
  selectedDevices: SearchDeviceResponse;
  setSelectedDevices: (devices: SearchDeviceResponse) => void;
  allSelected: boolean;
  setAllSelected: (allSelected: boolean) => void;
  filters: DeviceFilters;
  setFilters: (filters: DeviceFilters) => void;
};

const UnPhasedControlSection = (props: UnPhasedControlSectionProps) => {
  const {
    selectedDevices,
    setSelectedDevices,
    allSelected,
    setAllSelected,
    filters,
    setFilters,
  } = props;

  let abortController = new AbortController();
  const [page, setPage] = useState<number>(1);
  const [devicesLoading, setDevicesLoading] = useState<boolean>(true);
  const [filterList, setFilterList] = useState<FilterListObject[]>([]);
  const [filterOptions, setFilterOptions] = useState<DeviceFilterOption[]>([]);
  const [devices, setDevices] = useState<SearchDeviceResponse>();
  const [filterLoading, setFilterLoading] = useState<boolean>(true);
  const [allowedFilterBys, setAllowedFilterBys] = useState<Key[]>([]);
  const [pageLimit] = useState<number>(5);

  const refreshDevices = (pageNumber: number, myFilters: DeviceFilters) => {
    setDevicesLoading(true);

    abortController.abort();
    abortController = new AbortController();

    //setTimeout here is to allow change in filters/page using setState to take affect before refreshing devices;
    setTimeout(async () => {
      const status = "active";
      await searchDevices(
        myFilters,
        pageNumber,
        pageLimit,
        status,
        abortController.signal,
      ).then((devices) => {
        setDevices(devices);
        setDevicesLoading(false);
      });
    });
  };

  const fetchMetadataKeys = async () => {
    setFilterLoading(true);
    setDevicesLoading(true);
    await fetchAllMetadataKeys().then((keys) => {
      const idKey = { key: "id" };
      setAllowedFilterBys([idKey, ...keys]);
      refreshFilterOptions({}, [idKey, ...keys]);
    });
  };

  const onPageChange = useCallback(
    (e, { activePage }) => {
      setDevicesLoading(true);
      setPage(activePage as number);
      refreshDevices(activePage, filters);
    },
    [filters], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const selectDevice = (device: Device) => {
    let updatedDevices: SearchDeviceResponse = {
      devices: [...selectedDevices.devices, device],
      count: selectedDevices.count + 1,
    };

    setSelectedDevices(updatedDevices);
  };

  const clearDevice = (device: Device) => {
    let index = -1;
    selectedDevices.devices.forEach((mappedDevice) => {
      index += 1;
      if (mappedDevice.id === device.id) {
        let deviceList: Device[] = selectedDevices.devices;
        deviceList.splice(index, 1);

        let updatedDevices: SearchDeviceResponse = {
          devices: deviceList,
          count: selectedDevices.count - 1,
        };

        setSelectedDevices(updatedDevices);
      }
    });
  };

  const refreshFilterOptions = async (filters: DeviceFilters, keys) => {
    await fetchDeviceFilterOptions(filters).then((allFilterOptions) => {
      const filterOptionsMap = Object.fromEntries(
        allFilterOptions.map(({ filterName, filterValues }) => [
          filterName,
          filterValues,
        ]),
      );
      if (keys) {
        let map = keys.map((filterName) => {
          return {
            filterName: filterName.key,
            filterValues: filterOptionsMap[filterName.key],
          };
        });
        setFilterOptions(map);
      }
      setFilterLoading(false);
    });
  };

  const resetFilters = () => {
    setDevicesLoading(true);
    setPage(1);
    setFilterLoading(true);
    setAllowedFilterBys([]);
    setFilters({});
    setFilterList([]);
    setSelectedDevices({ devices: [], count: 0 });
    setAllSelected(false);
  };

  const updateFilters = (filterName: string, filterValues: string[]) => {
    const myFilters = { ...filters };

    if (filterValues.length > 0) {
      myFilters[filterName] = filterValues;
    } else {
      delete myFilters[filterName];
    }

    setFilters(myFilters);
    setPage(1);
    setDevicesLoading(true);
    setSelectedDevices({ devices: [], count: 0 });
    setAllSelected(false);

    refreshFilterOptions(myFilters, allowedFilterBys);
    refreshDevices(page, myFilters);
  };

  useEffect(() => {
    const initialFetch = () => {
      fetchMetadataKeys();
      refreshDevices(1, {});
    };

    if (
      Object.keys(filters).length === 0 &&
      (!allowedFilterBys || allowedFilterBys.length === 0)
    ) {
      initialFetch();
    }

    // return () => {
    //   abortController.abort();
    // };
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PhaseSectionContainer id={`unphasedContent`}>
      {!filterLoading && filterOptions && filterOptions?.length !== 0 && (
        <ActionFilterSection
          filterOptions={filterOptions}
          updateFilters={updateFilters}
          filterList={filterList}
          setFilterList={setFilterList}
          filters={filters}
          resetFilters={resetFilters}
        />
      )}

      {(filterLoading || devicesLoading) && (
        <LoadingAnimation
          loaderContainerHeight="350px"
          loadingText="Loading devices"
          fontSize="20px"
          loaderSize="48px"
        />
      )}
      {!filterLoading &&
        !devicesLoading &&
        devices &&
        devices.devices.length !== 0 && (
          <DeviceSelectionSection
            devices={devices}
            allSelected={allSelected}
            setAllSelected={setAllSelected}
            selectedDevices={selectedDevices}
            setSelectedDevices={setSelectedDevices}
            selectDevice={selectDevice}
            clearDevice={clearDevice}
            page={page}
            pageLimit={pageLimit}
            onPageChange={onPageChange}
          />
        )}

      {!filterLoading &&
        !devicesLoading &&
        devices?.devices.length === 0 &&
        filterOptions?.length === 0 && (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ErrorMessage marginTop="30px" message={"No devices found!"} />
          </div>
        )}
    </PhaseSectionContainer>
  );
};

export default UnPhasedControlSection;
