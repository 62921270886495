import React from "react";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";

type PhaseMenuTabProps = {
  id: number;
  phaseTabSum?: number;
  firstElement?: boolean;
  lastElement?: boolean;
  name?: string;
  active?: boolean;
  onClick: () => void;
  removePhase?: (arg0: any) => void;
  tempPhaseRemoveCheck?: boolean;
};

const PhaseMenu = styled.div<{
  idd: number;
  phaseTabSum: number;
  firstElement: boolean;
  lastElement: boolean;
  active: boolean;
}>`
  cursor: pointer;
  z-index: ${(props) => (props.active ? props.phaseTabSum : props.idd)};
  height: ${(props) => (props.active === true ? "32px" : "30px")};
  min-width: 180px;
  background-color: ${(props) =>
    props.active === true
      ? "#575757"
      : (props) => props.theme.colors["background-color"]};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.15s ease all;
  border: 1px solid #575757;
  transform: ${(props) =>
    props.active ? "scaleY(1.1) translateY(-2px)" : "none"};

  &:before {
    // bg-colored element on top of &:after element to make it look bordered (right side) (only first element) (transparent element)
    // left side triangle
    z-index: ${(props) =>
      props.active === true ? props.phaseTabSum : props.idd + 1};
    position: absolute;
    right: ${(props) => (!props.active === true ? "-21px" : "unset")};
    left: ${(props) =>
      props.firstElement
        ? "unset"
        : !props.active === true
          ? "unset"
          : "-26px"};
    border-right: ${(props) =>
      !props.active === true
        ? `21px solid transparent`
        : "0px solid transparent"};
    border-left: ${(props) =>
      props.firstElement
        ? "none"
        : !props.active === true
          ? "0px solid transparent"
          : "25px solid transparent"};
    border-bottom: ${(props) =>
      props.active
        ? "32px solid #575757"
        : `28px solid ${props.theme.colors["background-color"]}`};
    width: 0px;
    height: 0px;
    transition: 0.15s ease all;
    content: "";
  }

  &:after {
    // slighlty bigger colored element (right side) (first element)
    // right side triangle
    z-index: 1;
    position: absolute;
    right: -24px;
    border-right: ${(props) =>
      props.firstElement === true
        ? "23px solid transparent"
        : "23px solid transparent"};
    border-left: ${(props) =>
      props.firstElement === true
        ? "0px solid transparent"
        : "0px solid transparent"};
    border-bottom: ${(props) =>
      props.active ? "32px solid #575757" : "30px solid #575757"};
    width: 0px;
    height: 0px;
    transition: 0.15s ease all;
    content: "";
  }

  &:hover {
    transition: 0.15s ease all;
    transform: ${(props) =>
      props.firstElement === true
        ? "scaleY(1.1)  translateY(-2px)"
        : "scaleY(1.1) translateY(-2px)"};
  }

  &:hover .close-button {
    opacity: 1;
  }
`;

const PhaseMenuText = styled.div<{ firstElement: boolean; active: boolean }>`
  color: ${(props) => (props.active ? "#fff" : "#575757")};
  font-weight: 600;
  font-size: 16px;
  padding: ${(props) => (props.active ? "0 25px" : "0 20px")};
  transition: 0.15s ease all;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    padding: 0 25px;
  }
`;

const ClosePhaseButton = styled.div`
  position: absolute;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 5px;
  top: 5px;
  width: 10px;
  height: 10px;
  color: #fff !important;
  border-radius: 50%;
  transition: 0.15s ease all;
`;

const BorderElement = styled.div`
  display: flex;
  flex-grow: 1;
  border-bottom: 1px solid #5e5e58;
  position: relative;
  left: 20px;
  bottom: 2px;
`;

export default function PhaseMenuTab(props: PhaseMenuTabProps) {
  return (
    <>
      <PhaseMenu
        idd={props.id}
        firstElement={props.firstElement ? props.firstElement : false}
        phaseTabSum={props.phaseTabSum ? props.phaseTabSum : 100} // fallback value
        lastElement={props.lastElement ? props.lastElement : false}
        active={props.active ? props.active : false}
        onClick={props.onClick}
      >
        {props.phaseTabSum !== 2 &&
          props.active &&
          props.tempPhaseRemoveCheck && (
            <ClosePhaseButton
              className="close-button"
              onClick={(e) => {
                if (props.active && props.removePhase) props.removePhase(e);
              }}
            >
              <Icon className="close" style={{ margin: "0px" }} />
            </ClosePhaseButton>
          )}
        <PhaseMenuText
          firstElement={props.firstElement ? props.firstElement : false}
          active={props.active ? props.active : false}
        >
          {props.lastElement && (
            <Icon className="plus" style={{ marginRight: "10px" }} />
          )}
          {props.lastElement ? "Add Phase" : props.name}
        </PhaseMenuText>
      </PhaseMenu>
      {props.lastElement && <BorderElement />}
    </>
  );
}
