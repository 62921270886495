import React, { useState, useEffect } from "react";
import { Button, Dropdown, DropdownItemProps, Modal } from "semantic-ui-react";
import { IUser, Role, validateName, validateEmail } from "../../../../util";
import { StyledInput, StyledLabel } from "../actiontypes/ActionTypesModal";
import { ErrorMessage } from "../roles/CreateOrEditRoleModal";

type ErrorMessageType = {
  showValidationMessage: boolean;
  nameErrorMessage: string;
  emailErrorMessage: string;
};
interface CreateOrEditUserModalProps {
  user: IUser;
  title: string;
  onSubmit: (IUser) => void;
  trigger: React.ReactNode;
  roles: Role[];
  users: IUser[];
}

export default function CreateOrEditUserModal(
  props: CreateOrEditUserModalProps
) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = useState<DropdownItemProps[]>([]);
  const [user, setUser] = useState<IUser>(props.user);
  const [allUsersEmail, setAllUsersEmail] = useState<Set<string>>(new Set());
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>({
    showValidationMessage: false,
    nameErrorMessage: "",
    emailErrorMessage: "",
  });

  const handleNameValidationMessage = (nameValue: string) => {
    if (!validateName(nameValue) && nameValue.length > 0) {
      setErrorMessage({
        showValidationMessage: errorMessage.showValidationMessage,
        nameErrorMessage: "Please enter a valid name",
        emailErrorMessage: errorMessage.emailErrorMessage,
      });
    } else {
      setErrorMessage({
        showValidationMessage: errorMessage.showValidationMessage,
        nameErrorMessage: "",
        emailErrorMessage: errorMessage.emailErrorMessage,
      });
    }
  };

  const handleEmailValidationMessage = (emailValue: string) => {
    if (!validateEmail(emailValue) && emailValue.length > 0) {
      setErrorMessage({
        showValidationMessage: errorMessage.showValidationMessage,
        nameErrorMessage: errorMessage.nameErrorMessage,
        emailErrorMessage: "Please Enter Valid Email Id",
      });
    } else if (allUsersEmail.has(emailValue?.toLowerCase())) {
      setErrorMessage({
        showValidationMessage: errorMessage.showValidationMessage,
        nameErrorMessage: errorMessage.nameErrorMessage,
        emailErrorMessage: "Email Id Already Exist",
      });
    } else {
      setErrorMessage({
        showValidationMessage: errorMessage.showValidationMessage,
        nameErrorMessage: errorMessage.nameErrorMessage,
        emailErrorMessage: "",
      });
    }
  };

  useEffect(() => {
    setOptions(
      props.roles.map((role) => ({
        key: role.id,
        text: role.name,
        value: role.id,
      }))
    );
    if (props.users.length > 0) {
      const emailsSet = new Set(
        props.users.map((user) => user.email?.toLowerCase())
      );
      if (user.email) {
        emailsSet.delete(user.email.toLowerCase()); // remove props passed user email from set to avoid duplicate role name error on edit
      }

      setAllUsersEmail(emailsSet);
    }
  }, [props.roles]); // eslint-disable-line react-hooks/exhaustive-deps

  const onModalClose = () => {
    setOpen(false);
    setUser((prevUserValues) => ({
      ...prevUserValues,
      name: "",
      email: "",
    }));

    setErrorMessage({
      showValidationMessage: false,
      nameErrorMessage: "",
      emailErrorMessage: "",
    });
  };

  useEffect(() => {
    if (user.name && user.email && user.roles.length > 0) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [user]);

  return (
    <Modal
      className="dark"
      onClose={() => onModalClose()}
      onOpen={() => setOpen(true)}
      open={open}
      size="tiny"
      trigger={props.trigger}
    >
      <Modal.Header>{props.title}</Modal.Header>
      <Modal.Description></Modal.Description>
      <Modal.Content>
        <StyledInput labelPosition="left">
          <StyledLabel>Name</StyledLabel>
          <input
            autoFocus
            placeholder="John Doe"
            value={user.name}
            onChange={(e) => {
              setUser({ ...user, name: e.target.value });
              handleNameValidationMessage(e.target.value);
            }}
          />
        </StyledInput>
        {errorMessage.showValidationMessage &&
          errorMessage.nameErrorMessage !== "" && (
            <ErrorMessage id={"userNameErrorSpan"}>
              {errorMessage.nameErrorMessage}
            </ErrorMessage>
          )}

        <StyledInput labelPosition="left">
          <StyledLabel>Email</StyledLabel>
          <input
            placeholder="name@test.com"
            type="email"
            value={user.email}
            onChange={(e) => {
              setUser({ ...user, email: e.target.value });
              handleEmailValidationMessage(e.target.value);
            }}
          />
        </StyledInput>
        {errorMessage.showValidationMessage &&
          errorMessage.emailErrorMessage !== "" && (
            <ErrorMessage id={"userEmailErrorSpan"}>
              {errorMessage.emailErrorMessage}
            </ErrorMessage>
          )}

        <StyledInput labelPosition="left">
          <StyledLabel>Select Role</StyledLabel>
          <Dropdown
            fluid
            search
            options={options}
            value={user.roles[0]}
            onChange={(_e, d) => {
              setUser({ ...user, roles: [Number(d.value)] });
            }}
            style={{
              padding: "8px 12px",
              border: "none",
            }}
          />
        </StyledInput>
      </Modal.Content>
      <Modal.Actions>
        <Button
          secondary
          onClick={() => {
            onModalClose();
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          primary
          disabled={buttonDisabled}
          onClick={() => {
            if (
              validateName(user.name) &&
              validateEmail(user.email) &&
              !allUsersEmail.has(user.email?.toLowerCase())
            ) {
              onModalClose();
              props.onSubmit(user);
            } else {
              setErrorMessage({
                showValidationMessage: true,
                nameErrorMessage: errorMessage.nameErrorMessage,
                emailErrorMessage: errorMessage.emailErrorMessage,
              });
            }
          }}
        >
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
