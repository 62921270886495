import { Alert, AlertsData, AlertsMetaData } from "./PanelDef";
import { PanelViewComponent } from "../PanelDef";
import React from "react";
import { capitalizeFirstLetter } from "../../../util";
import { Button, Modal, Table } from "semantic-ui-react";
import moment from "moment";
import { AlertRule } from "../../../../../util";

function groupAlerts(list: Alert[]) {
  const map = new Map();

  list.forEach((item) => {
    const keyName = item.alert_rule.id;
    const collection = map.get(keyName);

    if (!collection) {
        map.set(keyName, [item]);
    } else {
        collection.push(item);
    }
  });

  return map;
}

function groupAlertRules(rules: AlertRule[]) {
  let map = rules.reduce((acc, rule) => {
    acc.set(rule.id, rule);
    return acc;
  }, new Map());

  return map;
}


type AlertListModalProps = {
  alerts: Alert[];
  alertRule: AlertRule;
  trigger: React.ReactNode;
}

export default function AlertListModal(
  props: AlertListModalProps
) {
  const [open, setOpen] = React.useState(false);

  return (
    <Modal
      className="dark"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
      trigger={props.trigger}
    >
      <Modal.Header>{capitalizeFirstLetter(props.alertRule.name)} list</Modal.Header>
      <Modal.Description></Modal.Description>
      <Modal.Content>
        <Table>
          <Table.Header>
            <Table.HeaderCell>Device Id</Table.HeaderCell>
            <Table.HeaderCell>Start Time</Table.HeaderCell>
            <Table.HeaderCell>End Time</Table.HeaderCell>
            <Table.HeaderCell>Duration</Table.HeaderCell>
          </Table.Header>

          {
            props.alerts.map((alert) => {
              return <Table.Row>
                <Table.Cell>{alert.device_id}</Table.Cell>
                <Table.Cell>{new Date(alert.start_time).toLocaleString()}</Table.Cell>                
                <Table.Cell>{new Date(alert.end_time).toLocaleString()}</Table.Cell>
                <Table.Cell>{moment.duration(alert.end_time - alert.start_time).humanize({ss: -1})}</Table.Cell>
              </Table.Row>
            })
          }
        </Table>
      </Modal.Content>
      <Modal.Actions>      
        <Button
          primary
          onClick={() => {
            setOpen(false);
          }}
        >
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

type AlertCardProps = {
  alerts: Alert[];
  alertRule: AlertRule
}

function AlertCard(props: AlertCardProps) {
  const colorMap = {
    critical: "#c53f4f",
    warning: "#9E9335",
    info: "#6D95D2"
  }
  
  const color = colorMap[props.alertRule.criticality];

  const containerStyle = {
    height: "80px",
    width: "calc(100% - 10px)",
    padding: "20px",
    color: "white",
    backgroundColor: color,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "20px",
    borderRadius: "10px",
    cursor: "pointer",
  } as React.CSSProperties

  const numberStyle = {
    fontSize: "50px",
    fontWeight: "bold"
  } as React.CSSProperties

  const nameStyle = {
    fontSize: "22px",
    marginLeft: "25px",
  } as React.CSSProperties

  return <div style={containerStyle} {...props}>
    <div style={numberStyle}>
      {props.alerts.length}
    </div>

    <div style={nameStyle}>
      {capitalizeFirstLetter(props.alertRule.name)}
    </div>
  </div>
}


type ViewAlertsState = {
};

export class ViewAlerts extends PanelViewComponent<
  AlertsMetaData,
  AlertsData,
  ViewAlertsState
> {
  

  render() {
    const alerts = this.props.data.alerts;
    if (!alerts || alerts.length === 0) {
      return <div className="panel-no-data">No Data</div>;
    }
    const groupedAlerts = groupAlerts(alerts)
    const allAlertRules = this.props.data.alertRules;
    const groupedAlertRules = groupAlertRules(allAlertRules)

    const alertIds = Array.from(groupedAlerts.keys());
    const alertRules = alertIds
      .map((alertId) => groupedAlertRules.get(alertId))
      .sort((a, b) => {
        let levelMap = {
          critical: 3,
          warning: 2,
          info: 1
        } 
      
        return levelMap[b.criticality] - levelMap[a.criticality]
      })

    const containerStyle = {
      marginTop: "50px", 
      marginLeft: "20px",
      marginRight: "20px",
      overflowY: "scroll",
      height: "calc(100% - 50px)"
    } as React.CSSProperties

    return <div style={containerStyle}>
      {
         alertRules.map((alertRule) => {
          const alerts = groupedAlerts.get(alertRule.id);

          if (alerts) {
            return (
              <AlertListModal 
                alertRule={alertRule}
                alerts={alerts}
                key={alertRule.alertName}
                trigger={
                  <AlertCard 
                    alerts={alerts}
                    alertRule={alertRule}                
                  />
                }
              />              
            )
          }

          return <div key={alertRule.alertName}></div>
        })
      }
    </div>
  }
}
