import React from "react";
import { DeviceFilterOption } from "../../../../BytebeamClient";
import { Icon, DropdownProps, Dropdown } from "semantic-ui-react";
import { FilterListObject } from "./NewActionModal";

type ActionModalDeviceFilterProps = {
  filterOptions: DeviceFilterOption[];
  onFilterSelected: (filterName: string, filterValue: string[]) => void;
  filterList: FilterListObject[];
  setFilterList: (arg0: FilterListObject[]) => void;
};

export default function ActionModalDeviceFilter(
  props: ActionModalDeviceFilterProps
) {
  let filterSelectedTimeout: any = null;

  function returnFilterValue(filterOption) {
    let returnValue: string[] = [];
    if (props.filterList.length !== 0) {
      props.filterList.forEach((item) => {
        if (item.filterName === filterOption.filterName)
          returnValue = item.filterValue;
      });
    }
    return returnValue;
  }

  const onFilterChange = (
    data: DropdownProps,
    filterOption: DeviceFilterOption
  ) => {
    const filterName = filterOption.filterName;
    const filterValue = data.value as string[];
    let singleFilterItem: FilterListObject = { filterName, filterValue };
    props.setFilterList([...props.filterList, singleFilterItem]);
    clearTimeout(filterSelectedTimeout);
    filterSelectedTimeout = setTimeout(() => {
      props.onFilterSelected(filterName, filterValue);
    }, 1000);
  };

  return (
    <div
      className="dashboard-header"
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        // marginBottom: "1rem",
      }}
    >
      {/* <div style={{ marginLeft: "auto" }}>Show Inactive Devices</div>
      <div style={{ marginRight: "30px" }}>
        <img
          src={this.props.showInactive ? toggleOff : toggleOn}
          onClick={this.props.handleToggleClick}
          style={{
            cursor: "pointer",
            height: "30px",
            marginLeft: "10px",
            marginTop: "5px",
          }}
          alt="Show inactive device toggle"
        />
      </div> */}

      {props.filterOptions.length > 0 ? (
        <>
          <span style={{ marginRight: "15px" }}>
            <Icon name="filter" />
          </span>
          <div className="dashboard-header-row-2" style={{ flexWrap: "wrap" }}>
            <div>
              {props.filterOptions.map((filterOption) => {
                // Filtering out values containing only white spaces and Mapping to Dropdown options using FlatMap
                const options = filterOption?.filterValues
                  ? filterOption.filterValues.flatMap((v) => {
                      let value = String(v);
                      if (value?.trim() !== "")
                        return [
                          {
                            key: value,
                            value: value,
                            text: value,
                          },
                        ];
                      else return [];
                    })
                  : [];

                return (
                  <span
                    className="dashboard-header-dropdown"
                    style={{ marginBottom: "1rem" }}
                    key={filterOption.filterName}
                  >
                    <Dropdown
                      placeholder={filterOption.filterName}
                      options={options}
                      selection
                      multiple
                      search
                      button
                      labeled
                      defaultValue={returnFilterValue(filterOption)}
                      onChange={(_event, data) =>
                        onFilterChange(data, filterOption)
                      }
                      disabled={false}
                    />
                  </span>
                );
              })}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
