import React, { useEffect, useState } from "react";
import { Grid, Popup, Progress, Table } from "semantic-ui-react";
import moment from "moment";
import LoadingAnimation from "../../../common/Loader";
import { styleActionProgress } from "../../DeviceMangaement/Devices/LastActionInfoTable";
import {
  ActionStatusDetail,
  TableInfo,
  fetchTableInfo,
} from "../../../../BytebeamClient";
import {
  ActionTable,
  ActionTableRow,
  OperationsButtonIcon,
} from "./LiveActionsList";
import { capitalizeFirstLetter, filterTableInfo } from "../../util";
import { useHistory, useRouteMatch } from "react-router-dom";
import { StyledHeader } from "./SelectableItem";
import { checkLogs } from "../util";

interface DeviceListProps {
  selectedDevices: Set<number>;
  handleCheckboxClick: (deviceId: number) => void;
  readonly deviceData?: ActionStatusDetail[];
  readonly actionID?: any;
  readonly loading: boolean;
  readonly allowedActions: string[] | "all";
}

function DeviceListV3(props: DeviceListProps) {
  const history = useHistory();
  const matchedPath = useRouteMatch("/projects/:tenant/actionsv3");

  const [streamsList, setStreamsList] = useState<Record<string, string[]>>({});

  useEffect(() => {
    async function getStreamList() {
      try {
        const res: TableInfo = await fetchTableInfo();
        setStreamsList(filterTableInfo(res));
      } catch (e) {
        console.log(e);
      }
    }

    getStreamList();
  }, []);
  return (
    <Grid style={{ marginBottom: "20px" }}>
      <Grid.Row>
        <Grid.Column>
          <ActionTable selectable>
            <Table.Header>
              <Table.Row textAlign="center">
                {/* <Table.HeaderCell></Table.HeaderCell> */}
                <Table.HeaderCell>Device ID</Table.HeaderCell>
                <Table.HeaderCell>Last Heartbeat</Table.HeaderCell>
                {/* <Table.HeaderCell>Phase</Table.HeaderCell> */}
                <Table.HeaderCell>Stage</Table.HeaderCell>
                <Table.HeaderCell>Errors</Table.HeaderCell>
                <Table.HeaderCell>Progress Bar</Table.HeaderCell>
                <Table.HeaderCell>Operations</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {props.loading && (
                <Table.Row>
                  <Table.Cell colSpan={8}>
                    <LoadingAnimation
                      loadingText="Loading Device List"
                      loaderSize="50px"
                      fontSize="18px"
                      marginTopText="10px"
                      loaderContainerMargin="60px 0px 60px 0px"
                    />
                  </Table.Cell>
                </Table.Row>
              )}
              {!props.loading &&
                props.deviceData?.length !== 0 &&
                props.deviceData?.map((data) => (
                  <ActionTableRow key={data?.device_id} textAlign={"center"}>
                    {/* <Table.Cell>
                      {props.allowedActions.length > 0 ? (
                        <Checkbox
                          className={
                            props.selectedDevices.has(data.device_id)
                              ? ""
                              : "checkbox-hidden"
                          }
                          onClick={() =>
                            props.handleCheckboxClick(data.device_id)
                          }
                          // your existing props here

                          // width={getColumnWidth("action_checkbox")}
                          // disabled={allSelected}
                          // checked={allSelected || selectedDevices.has(deviceId)}
                          checked={props.selectedDevices.has(data.device_id)}
                          onChange={(e, { checked }) => {
                            e.stopPropagation();
                            if (checked) {
                              // selectDevice(deviceId);
                            } else {
                              // clearDevice(deviceId);
                            }
                          }}
                        />
                      ) : null}
                    </Table.Cell> */}
                    <Table.Cell>{data?.device_id}</Table.Cell>
                    <Table.Cell>
                      <Popup
                        content={moment(data?.updated_at).format(
                          "ddd, MMM Do YYYY, HH:mm:ss"
                        )}
                        position="top center"
                        inverted
                        trigger={
                          <div>
                            {data.updated_at
                              ? capitalizeFirstLetter(
                                  moment(data.updated_at).fromNow()
                                )
                              : "--"}
                          </div>
                        }
                      />
                    </Table.Cell>
                    {/* <Table.Cell>{"--phase--"}</Table.Cell> */}
                    <Table.Cell>{data?.status}</Table.Cell>

                    <Table.Cell>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginLeft: `${
                            data?.errors.length !== 0 ? "2rem" : "0"
                          }`,
                        }}
                      >
                        {data?.errors.length !== 0 ? data.errors : "None"}
                      </div>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Progress
                          style={{ width: "100%", marginBottom: "0px" }}
                          progress="percent"
                          percent={
                            styleActionProgress(data?.status, data?.progress)[
                              "progress"
                            ]
                          }
                          indicating={
                            data?.status !== "Completed" &&
                            data?.status !== "Failed"
                          }
                          size="medium"
                          className={`action-progress ${
                            styleActionProgress(data?.status, data?.progress)[
                              "className"
                            ]
                          }`}
                        />
                      </div>
                    </Table.Cell>

                    <Table.Cell>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        {Object.entries(streamsList).filter(
                          ([stream, fields]) => checkLogs(stream)
                        ).length > 0 && (
                          <OperationsButtonIcon
                            name="file alternate outline"
                            title="Operations logs"
                            onClick={() => {
                              history.push(
                                `${matchedPath?.url}/logs/device-log?action_id=${props.actionID}&device_id=${data?.device_id}`
                              );
                            }}
                          />
                        )}
                        <OperationsButtonIcon
                          name="eye"
                          title="More details"
                          onClick={() => {
                            history.push(
                              `?action_id=${props.actionID}&device_id=${data?.device_id}`
                            );
                          }}
                        />
                      </div>
                    </Table.Cell>
                  </ActionTableRow>
                ))}
              {!props.loading && props.deviceData?.length === 0 && (
                <Table.Row textAlign="center">
                  <Table.Cell colSpan={8}>
                    <StyledHeader as="h4">No Devices Found!</StyledHeader>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </ActionTable>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default DeviceListV3;
