import React from "react";
import { AggregateValueMetaData } from "./PanelDef";
import { Tab } from "semantic-ui-react";
import RelativeTimePicker from "../../Datetime/RelativeTimePicker";
import { PanelEditComponent, PartialMetaData } from "../PanelDef";
import {
  EditMetaRoot,
  EditMetaRow,
  EditAnimatedMetaInput,
  EditAnimatedMetaDropdown,
  ThinDivider,
  LabelHeading,
  DisplayValueDiv,
  ToggleLabel,
  conditionalAggregators,
  EditPanelFormContainer,
  StyledInputDiv,
} from "../util";
import ToggleSwitch from "../../../common/ToggleSwitch";
import { TableInfo } from "../../../../../BytebeamClient";
export type EditAggregateValueMetaProps = {
  panelMeta: AggregateValueMetaData;
  tables: TableInfo;
};

export type EditAggregateValueMetaState = {
  table: string;
  column: string;
  columnType: string;
  aggregator: string[];
  textSize: number;
  autoTextSize: boolean;
  error: boolean;
};

export class EditAggregateValueMeta extends PanelEditComponent<
  AggregateValueMetaData,
  EditAggregateValueMetaState
> {
  titleRef = React.createRef<HTMLInputElement>();
  descriptionRef = React.createRef<HTMLInputElement>();
  durationRef = React.createRef<RelativeTimePicker>();
  prefixRef = React.createRef<HTMLInputElement>();
  suffixRef = React.createRef<HTMLInputElement>();

  constructor(props: EditAggregateValueMetaProps) {
    super(props);

    if (props.panelMeta) {
      this.state = {
        autoTextSize: props.panelMeta.autoTextSize,
        textSize: props.panelMeta.textSize,
        table: props.panelMeta.table,
        column: props.panelMeta.column,
        error: false,
        columnType: props.tables[props.panelMeta.table]?.filter(
          (item) => item?.name === props.panelMeta.column
        )[0].type,
        aggregator:
          typeof props.panelMeta.aggregator === "string"
            ? [props.panelMeta.aggregator]
            : props.panelMeta.aggregator,
      };
    } else {
      this.state = {
        textSize: 50,
        autoTextSize: true,
        table: "",
        column: "",
        columnType: "",
        aggregator: [],
        error: false,
      };
    }
  }

  getPanelMeta(type): PartialMetaData<AggregateValueMetaData> {
    const meta: AggregateValueMetaData = {
      type: "aggregate_value",
      id: this.props.panelMeta.id,
      title: this.titleRef.current?.value || "",
      description: this.descriptionRef.current?.value || "",
      table: this.state.table || "",
      column: this.state.column || "",
      prefix: this.prefixRef.current?.value || "",
      suffix: this.suffixRef.current?.value || "",
      aggregator: this.state.aggregator || [],
      textSize: this.state.textSize || 50,
      autoTextSize: this.state.autoTextSize,
    };

    return {
      meta: meta,
      complete: this.isValidPanelMeta(meta, type),
    };
  }

  isValidPanelMeta(meta: AggregateValueMetaData, type?: string) {
    // type is used here to differentiate between submit and refresh in edit mode
    if (
      !(!!meta.table && !!meta.column && meta.aggregator.length > 0) &&
      type === "submit"
    ) {
      this.setState({ error: true });
    } else if (type === "submit") {
      this.setState({ error: false });
    }
    return !!meta.table && !!meta.column && meta.aggregator.length > 0;
  }

  setTable(_event, data) {
    this.setState({
      table: data.value,
      column: "",
      columnType: "",
      aggregator: [],
    });
  }

  setColumn(_event, data) {
    const columnType = data.options.filter(
      (item) => item.value === data.value
    )[0].type;
    this.setState({
      column: data.value,
      columnType: columnType,
      aggregator: [],
    });
  }

  setAggregate(_event, data) {
    this.setState({
      aggregator: data.value,
    });
  }

  handleSliderChange = (event) => {
    this.setState({ textSize: parseFloat(event.target.value) });
  };


  autoTextSizeToggle() {
    const toggled = !this.state.autoTextSize;
    this.setState({ autoTextSize: toggled });
  }

  render() {
    const title = this.props.panelMeta.title;
    const description = this.props.panelMeta.description;

    const tableOptions = Object.keys(this.props.tables).map((t) => {
      return {
        key: t,
        text: t,
        value: t,
      };
    });

    let columnTypeOptions: Array<{
      key: string;
      value: string;
      text: string;
      type: string;
    }> = [];

    if (
      !!this.state.table &&
      this.props.tables &&
      this.props.tables[this.state.table]
    ) {
      columnTypeOptions = this.props.tables[this.state.table].map(
        (f: { name: string; type: string }) => {
          return {
            key: f.name,
            text: f.name,
            value: f.name,
            type: f.type,
          };
        }
      );
    } else {
      // Handle the case when the data is not available
      columnTypeOptions = [];
    }

    let aggregateOptions = conditionalAggregators(this.state.columnType).map(
      (a) => {
        return {
          key: a,
          text: a,
          value: a,
        };
      }
    );
    aggregateOptions.push({
      key: "count_distinct",
      text: "count distinct",
      value: "count_distinct",
    });

    const panes = [
      {
        menuItem: "General",
        pane: (
          <Tab.Pane key={"general"}>
            <EditPanelFormContainer>
              <div style={{ width: "100%", marginTop: "16px" }} />
              <EditMetaRow>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    autoFocus={true}
                    defaultRef={this.titleRef}
                    defaultValue={title}
                    label="Title"
                  />
                </StyledInputDiv>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    defaultRef={this.descriptionRef}
                    defaultValue={description}
                    label="Description"
                  />
                </StyledInputDiv>
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>
                <StyledInputDiv width="50%" marginTop="10px">
                  <EditAnimatedMetaDropdown
                    placeholder="Select Stream"
                    text={this.state.table || "Select Stream"}
                    search
                    selection
                    options={tableOptions}
                    onChange={this.setTable.bind(this)}
                    defaultValue={this.state.table || ""}
                    value={this.state.table || ""}
                    elementid={"AggregateValueTable"}
                    error={this.state.error && !this.state.table}
                  />
                </StyledInputDiv>
                <StyledInputDiv width="50%" marginTop="10px">
                  <EditAnimatedMetaDropdown
                    placeholder="Select Field"
                    text={this.state.column || "Select Field"}
                    search
                    selection
                    disabled={this.state.table === ""}
                    options={columnTypeOptions}
                    onChange={this.setColumn.bind(this)}
                    defaultValue={this.state.column || ""}
                    value={this.state.column || ""}
                    elementid={"AggregateValueColumn"}
                    error={this.state.error && !this.state.column}
                  />
                </StyledInputDiv>
                <StyledInputDiv width="50%" marginTop="10px">
                  <EditAnimatedMetaDropdown
                    placeholder="Aggregator"
                    // text={this.state.aggregator?.length>0 ? this.state.aggregator[0] : ["Select Aggregator"]}
                    search
                    selection
                    multiple
                    disabled={this.state.column === ""}
                    options={aggregateOptions}
                    onChange={this.setAggregate.bind(this)}
                    defaultValue={
                      this.state.aggregator?.length > 0
                        ? this.state.aggregator
                        : []
                    }
                    value={
                      this.state.aggregator?.length > 0
                        ? this.state.aggregator
                        : []
                    }
                    elementid={"AggregateValueAggregator"}
                    error={
                      this.state.error && this.state.aggregator.length === 0
                    }
                  />
                </StyledInputDiv>
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    defaultRef={this.prefixRef}
                    defaultValue={this.props.panelMeta.prefix}
                    label="Prefix"
                  />
                </StyledInputDiv>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    defaultRef={this.suffixRef}
                    defaultValue={this.props.panelMeta.suffix}
                    label="Suffix"
                  />
                </StyledInputDiv>
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "View",
        pane: (
          <Tab.Pane key={"view"}>
            <EditPanelFormContainer>
              <EditMetaRow>
                <p>
                  * These settings are applicable only for single aggregate value and not for mobile layout
                </p>
                <ToggleLabel style={{ fontWeight: "bold" }}>
                  Auto resize the Text
                </ToggleLabel>
                <ToggleSwitch
                  id="showRangeZeroToggle"
                  defaultChecked={this.props.panelMeta.autoTextSize}
                  disabled={false}
                  Text={["Yes", "No"]}
                  onToggleChange={() => {
                    this.autoTextSizeToggle();
                  }}
                />
              </EditMetaRow>
              <EditMetaRow
                style={{
                  display: this.state.autoTextSize ? "none" : "flex",
                  marginLeft: "10px",
                }}
              >
                <LabelHeading style={{ fontWeight: "normal" }}>
                  Text Size
                </LabelHeading>
                <DisplayValueDiv>{this.state.textSize}</DisplayValueDiv>
                <div style={{ width: "100%", padding: "5px 35px 0px 5px" }}>
                  <input
                    style={{ width: "100%", height: "5%" }}
                    id="typeinp"
                    type="range"
                    min="50"
                    max="200"
                    value={this.state.textSize}
                    onChange={this.handleSliderChange}
                    step="1"
                  />
                </div>
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <EditMetaRoot>
        <Tab menu={{}} panes={panes} renderActiveOnly={false} />
      </EditMetaRoot>
    );
  }
}
