import React, { useEffect, useState } from "react";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import { Breadcrumb } from "semantic-ui-react";
import { Device, fetchDeviceWithSpecificAction } from "../../../BytebeamClient";
import DeviceAccordion from "./DeviceAccordion";
import { User } from "../../../util";
import ProgressChart from "./ProgressChart";
import LoadingAnimation from "../../common/Loader";

interface DeviceOverviewParams {
  deviceID: string;
  actionID: string;
}

interface DeviceOverviewProps {
  user: User;
}

function DeviceOverview(props: DeviceOverviewProps) {
  const params = useParams<DeviceOverviewParams>();

  let abortController = new AbortController();

  const [device, setDevice] = useState<Device>();
  const [loading, setLoading] = useState(true);

  const [actionStatus, setActionStatus] = useState<string>();
  const [initiatedProgress, setInitiatedProgress] = useState(0);
  const [queuedProgress, setQueuedProgress] = useState(0);
  const [inProgressProgress, setInProgressProgress] = useState(0);
  const [failedProgress, setFailedProgress] = useState(0);
  const [completedProgress, setCompletedProgress] = useState(0);
  
  // getting the path till it matches the url
  const matchedPath = useRouteMatch('/projects/demo/actions/:actionID');

  const fetchSpecificDevice = async () => {
    abortController.abort();
    abortController = new AbortController();
    await fetchDeviceWithSpecificAction(
      parseInt(params.deviceID),
      parseInt(params.actionID),
      abortController.signal
    ).then((deviceData) => {
      setDevice(deviceData);
      setLoading(false);
      let deviceStatus = deviceData["action"]; // check here for typecast incompletion for Device
      let progress = deviceStatus.progress;
      let status = deviceStatus.status;

      if (status === "Initiated") {
        setInitiatedProgress(progress);
        setActionStatus("Initiated");
      } else if (status === "Queued") {
        setQueuedProgress(progress);
        setActionStatus("Queued");
      } else if (status === "Completed") {
        setActionStatus("Completed");
        setCompletedProgress(100);
      } else if (status === "Failed") {
        setActionStatus("Failed");
        setFailedProgress(progress);
      } else {
        setActionStatus("In Progress");
        setInProgressProgress(progress);
      }
    });
  };

  useEffect(() => {
    fetchSpecificDevice();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return loading ? (
    <LoadingAnimation
      loaderContainerHeight="65vh"
      fontSize="1.5rem"
      loadingText="Loading device data"
    />
  ) : (
    <div
      style={{
        padding: "0 5rem",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "2rem",
          //   border: "1px solid white",
          marginBottom: "3rem",
        }}
      >
        <p style={{ fontSize: "1.2rem", margin: 0 }}>
          <Breadcrumb size="big">
            <Breadcrumb.Section link>
              <Link to={`${matchedPath?.['url']}/action-overview`}>
                Action {params.actionID}
              </Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>
              Device {params.deviceID} Overview
            </Breadcrumb.Section>
          </Breadcrumb>
        </p>
      </div>

      <div
        style={{
          width: "100%",
          height: "80px",
          marginBottom: "3rem",
        }}
      >
        <ProgressChart
          setInitiatedListCount={initiatedProgress}
          setQueuedListCount={queuedProgress}
          setProgressListCount={inProgressProgress}
          setFailedListCount={failedProgress}
          setCompletedListCount={completedProgress}
          forDevice={true}
          actionStatus={actionStatus}
        />
      </div>

      {device && <DeviceAccordion device={device} user={props.user} />}
    </div>
  );
}

export default DeviceOverview;
