import React from "react";
import { Popup } from "semantic-ui-react";
import styled from "styled-components";
import ThemeSchema from "../../../theme/schema";
import { useUser } from "../../../context/User.context";

interface ProgressStatusProps {
  actionID: number;
  initiatedCount: number;
  queuedCount: number;
  inProgressCount: number;
  completedCount: number;
  failedCount: number;
  onClick?: Function;
  filterStatus?: boolean;
  activeIndex: number;
  setActiveIndex: (value: number) => any;
}

interface ProgressCircleProps {
  color: string;
}

const ProgressCircle = styled.div<ProgressCircleProps>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${(p) => p.color};
  margin: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;

  @media (max-width: 1615px) {
    width: 27px;
    height: 27px;
    margin: 0.3rem;
    font-size: 0.7rem;
  }

  @media (max-width: 1330px) {
    width: 25px;
    height: 25px;
  }
`;

export default function ProgressStatus(props: ProgressStatusProps) {
  const { user } = useUser();
  const theme = user?.settings?.theme;

  return (
    <div className="action-progress-circle-container">
      <Popup
        content={`Queued devices: ${props.queuedCount}`}
        position="top center"
        inverted
        trigger={
          <ProgressCircle
            color={`${props.queuedCount !== 0 ? ThemeSchema.data[theme ?? "dark"]?.colors[
              "progress_bar-queued"
            ] : "#80808050"}`}
            onClick={(e) => {
              e.stopPropagation();
              if (props.onClick && props.queuedCount !== 0) {
                if (props.actionID !== props.activeIndex)
                  props.setActiveIndex(props.actionID);
                props.onClick("Queued");
              } else {
                window.toastr.info("Devices not found in specified state");
              }
            }}
            id={`queued-${props.actionID}`}
          >
            <div
              style={{
                fontWeight: "bold",
                color: ThemeSchema.data[theme ?? "dark"]?.colors[
                  "progress_circle-actions-inner-text-color"
                ],
              }}
            >
              {props.queuedCount === 0 ? "" : props.queuedCount}
            </div>
          </ProgressCircle>
        }
      />

      <Popup
        content={`Initiated devices: ${props.initiatedCount}`}
        position="top center"
        inverted
        trigger={
          <ProgressCircle
            color={`${props.initiatedCount !== 0 ? ThemeSchema.data[theme ?? "dark"]?.colors[
              "progress_bar-initiated"
            ] : "#6D95D250"}`}
            onClick={(e) => {
              e.stopPropagation();
              if (props.onClick && props.initiatedCount !== 0) {
                if (props.actionID !== props.activeIndex)
                  props.setActiveIndex(props.actionID);
                props.onClick("Initiated");
              } else {
                window.toastr.info("Devices not found in specified state");
              }
            }}
            id={`initiated-${props.actionID}`}
          >
            <div
              style={{
                fontWeight: "bold",
                color: ThemeSchema.data[theme ?? "dark"]?.colors[
                  "progress_circle-actions-inner-text-color"
                ],
              }}
            >
              {props.initiatedCount === 0 ? "" : props.initiatedCount}
            </div>
          </ProgressCircle>
        }
      />

      <Popup
        content={`In Progress devices: ${props.inProgressCount}`}
        position="top center"
        inverted
        trigger={
          <ProgressCircle
            color={`${props.inProgressCount !== 0 ? ThemeSchema.data[theme ?? "dark"]?.colors[
              "progress_bar-in_progress"
            ] : "#9E933550"}`}
            onClick={(e) => {
              e.stopPropagation();
              if (props.onClick && props.inProgressCount !== 0) {
                if (props.actionID !== props.activeIndex)
                  props.setActiveIndex(props.actionID);
                props.onClick("In Progress");
              } else {
                window.toastr.info("Devices not found in specified state");
              }
            }}
            id={`inProgress-${props.actionID}`}
          >
            <div style={{ fontWeight: "bold", color: ThemeSchema.data[theme ?? "dark"]?.colors[
                  "progress_circle-actions-inner-text-color"
                ] }}>
              {props.inProgressCount === 0 ? "" : props.inProgressCount}
            </div>
          </ProgressCircle>
        }
      />

      <Popup
        content={`Completed devices: ${props.completedCount}`}
        position="top center"
        inverted
        trigger={
          <ProgressCircle
            color={`${props.completedCount !== 0 ? ThemeSchema.data[theme ?? "dark"]?.colors[
              "progress_bar-completed"
            ] : "#609D5A50"}`}
            onClick={(e) => {
              e.stopPropagation();
              if (props.onClick && props.completedCount !== 0) {
                if (props.actionID !== props.activeIndex)
                  props.setActiveIndex(props.actionID);
                props.onClick("Completed");
              } else {
                window.toastr.info("Devices not found in specified state");
              }
            }}
            id={`completed-${props.actionID}`}
          >
            <div
              style={{
                fontWeight: "bold",
                color: ThemeSchema.data[theme ?? "dark"]?.colors[
                  "progress_circle-actions-inner-text-color"
                ],
              }}
            >
              {props.completedCount === 0 ? "" : props.completedCount}
            </div>
          </ProgressCircle>
        }
      />

      <Popup
        content={`Failed devices: ${props.failedCount}`}
        position="top center"
        inverted
        trigger={
          <ProgressCircle
            color={`${props.failedCount !== 0 ? ThemeSchema.data[theme ?? "dark"]?.colors[
              "progress_bar-failed"
            ] : "#C53F4F50"}`}
            onClick={(e) => {
              e.stopPropagation();
              if (props.onClick && props.failedCount !== 0) {
                if (props.actionID !== props.activeIndex)
                  props.setActiveIndex(props.actionID);
                props.onClick("Failed");
              } else {
                window.toastr.info("Devices not found in specified state");
              }
            }}
            id={`failed-${props.actionID}`}
          >
            <div
              style={{
                fontWeight: "bold",
                color: ThemeSchema.data[theme ?? "dark"]?.colors[
                  "progress_circle-actions-inner-text-color"
                ],
              }}
            >
              {props.failedCount === 0 ? "" : props.failedCount}
            </div>
          </ProgressCircle>
        }
      />
    </div>
  );
}
