import { useEffect } from "react";

export function objectToArray(obj) {
  let resultArr = [];
  for (let key in obj) {
    let tmpObj = {};
    if (obj.hasOwnProperty(key)) {
      tmpObj[key] = obj[key];
      resultArr.push(tmpObj);
    }
  }
  return resultArr;
}

export function processFiltersArrayToInputField(arr) {
  // convert [{ message_type: "gll" }, { data: "0x123" }] to
  // [{payloadKey: "message_type", payloadValue: "gll"}, { ...etc }]
  return arr.map((item, index) => {
    return {
      payloadKey: Object.keys(item)[0],
      payloadValue: Object.values(item)[0],
    };
  });
}

export function processOutputsArrayToInputField(arr) {
  return arr.map((item, index) => {
    return {
      param: Object.keys(item)[0],
      formula: Object.values(item)[0],
    };
  });
}

export function toRoman(num) {
  if (typeof num !== "number") return false;

  var digits = String(+num).split(""),
    key = [
      "",
      "C",
      "CC",
      "CCC",
      "CD",
      "D",
      "DC",
      "DCC",
      "DCCC",
      "CM",
      "",
      "X",
      "XX",
      "XXX",
      "XL",
      "L",
      "LX",
      "LXX",
      "LXXX",
      "XC",
      "",
      "I",
      "II",
      "III",
      "IV",
      "V",
      "VI",
      "VII",
      "VIII",
      "IX",
    ],
    roman_num = "",
    i = 3;
  while (i--) roman_num = (key[+digits.pop() + i * 10] || "") + roman_num;
  return Array(+digits.join("") + 1).join("M") + roman_num;
}

export function romanToDecimal(roman) {
  const romanNumerals = {'I': 1, 'V': 5, 'X': 10, 'L': 50, 'C': 100, 'D': 500, 'M': 1000};
  let decimal = 0;
  let prevValue = 0;

  for (let i = roman.length - 1; i >= 0; i--) {
      const currentValue = romanNumerals[roman[i]];
      if (currentValue >= prevValue) {
          decimal += currentValue;
      } else {
          decimal -= currentValue;
      }
      prevValue = currentValue;
  }

  return decimal;
}

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useOutsideClickHandler(ref, doSomething) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        doSomething();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]); // eslint-disable-line react-hooks/exhaustive-deps
}
