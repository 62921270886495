import React, { useState, useEffect } from "react";
import { Menu } from "semantic-ui-react";

import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  withRouter,
  useHistory,
} from "react-router-dom";

import Streams from "./streams/Streams";

import Roles from "./roles/Roles";
import Users from "./users/Users";
import Metadatakeys from "./metadatakeys/Metadatakeys";
import { DisplayIf } from "../util";
import ActionTypes from "./actiontypes/ActionTypes";
import EndUserAuth from "./enduserauth/EndUserAuth";
import ApiKeys from "./developerAPI/ApiKeys";
import SessionTypes from "./sessions/SessionTypes";

const resolver = {
  "/settings/streams": "Streams",
  "/settings/roles": "Roles",
  "/settings/users": "Users",
  "/settings/action_types": "Action Types",
  "/settings/metadata": "Metadata",
  "/settings/api_keys": "API Keys",
  "/settings/auth": "Auth",
  "/settings/session_types": "Session Types",
};

function SettingsManagement({ user, currentTenant, location }) {
  const history = useHistory();

  let path = location.pathname;
  let indexSettings = path.indexOf("/settings");
  path = path.slice(indexSettings);

  const [activeItem, setActiveItem] = useState(
    resolver[window.location.pathname]
  );

  const permissions = user?.role?.permissions ?? {};

  const handleItemClick = (e, { name }) => {
    setActiveItem(name);
  };

  const menuTab = (name, to, shouldDisplay) => {
    return (
      <DisplayIf cond={shouldDisplay}>
        <Menu.Item
          as={Link}
          to={to}
          name={name}
          active={activeItem === name}
          onClick={handleItemClick}
        />
      </DisplayIf>
    );
  };

  useEffect(() => {
    if (location.pathname === `/projects/${currentTenant}/settings/`) {
      history.push(`/projects/${currentTenant}/settings`);
    }
    if (location.pathname === `/projects/${currentTenant}/settings`) {
      let permissions = user.role.permissions;

      if (permissions.viewStreams) {
        setActiveItem(resolver[`${path}/streams`]);
        history.push(`${location.pathname}/streams`);
      } else if (permissions.viewUsers) {
        setActiveItem(resolver[`${path}/users`]);
        history.push(`${location.pathname}/users`);
      } else if (permissions.viewRoles) {
        setActiveItem(resolver[`${path}/roles`]);
        history.push(`${location.pathname}/roles`);
      } else if (permissions.viewMetadataKeys) {
        setActiveItem(resolver[`${path}/metadata`]);
        history.push(`${location.pathname}/metadata`);
      } else if (permissions.viewActionTypes) {
        setActiveItem(resolver[`${path}/action_types`]);
        history.push(`${location.pathname}/action_types`);
      } else if (user.role.name === "admin") {
        setActiveItem(resolver[`${path}/api_keys`]);
        history.push(`${location.pathname}/api_keys`);
      }
    }

    switch (window.location.pathname) {
      case `/projects/${currentTenant}/settings/streams`:
        setActiveItem("Streams");
        break;

      case `/projects/${currentTenant}/settings/users`:
        setActiveItem("Users");
        break;

      case `/projects/${currentTenant}/settings/roles`:
        setActiveItem("Roles");
        break;

      case `/projects/${currentTenant}/settings/metadata`:
        setActiveItem("Metadata");
        break;

      case `/projects/${currentTenant}/settings/action_types`:
        setActiveItem("Action Types");
        break;

      case `/projects/${currentTenant}/settings/api_keys`:
        setActiveItem("API Keys");
        break;

      case `/projects/${currentTenant}/settings/session_types`:
        setActiveItem("Session Types");
        break;

      default:
        break;
    }
  }, [window.location.pathname, activeItem]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {activeItem !== undefined && (
        <Router basename={`/projects/${currentTenant}/`}>
          <Menu
            className="desktop-view"
            pointing
            secondary
            style={{ marginTop: 20, border: "none" }}
          >
            {menuTab("Streams", `/settings/streams`, permissions.viewStreams)}
            {menuTab("Users", `/settings/users`, permissions.viewUsers)}
            {menuTab("Roles", `/settings/roles`, permissions.viewRoles)}
            {menuTab(
              "Metadata",
              `/settings/metadata`,
              permissions.viewMetadataKeys
            )}
            {menuTab(
              "Action Types",
              `/settings/action_types`,
              permissions.viewActionTypes
            )}
            {menuTab(
              "API Keys",
              `/settings/api_keys`,
              user.role.name === "admin"
            )}
            {menuTab(
              "Session Types",
              `/settings/session_types`,
              user.role.name === "admin"
            )}

            {/* Auth is hidden till mobile SDKs are ready */}
            {/* { menuTab("Auth", "/settings/auth", true)} */}
          </Menu>

          <Switch>
            <Route path={`/settings/streams`}>
              <DisplayIf cond={permissions.viewStreams}>
                <Streams user={user} />
              </DisplayIf>
            </Route>

            <Route path={`/settings/roles`}>
              <DisplayIf cond={permissions.viewRoles}>
                <Roles user={user} />
              </DisplayIf>
            </Route>

            <Route path={`/settings/users`}>
              <DisplayIf cond={permissions.viewUsers}>
                <Users user={user} />
              </DisplayIf>
            </Route>

            <Route path={`/settings/action_types`}>
              <DisplayIf cond={permissions.viewActionTypes}>
                <ActionTypes user={user} />
              </DisplayIf>
            </Route>

            <Route path={`/settings/metadata`}>
              <DisplayIf cond={permissions.viewMetadataKeys}>
                <Metadatakeys user={user} />
              </DisplayIf>
            </Route>

            <Route path={`/settings/api_keys`}>
              <DisplayIf cond={user.role.name === "admin"}>
                <ApiKeys user={user} />
              </DisplayIf>
            </Route>

            <Route path={`/settings/session_types`}>
              <DisplayIf cond={user.role.name === "admin"}>
                <SessionTypes user={user} />
              </DisplayIf>
            </Route>

            <Route path={`/settings/auth`}>
              <DisplayIf cond={true}>
                <EndUserAuth user={user} />
              </DisplayIf>
            </Route>
          </Switch>
        </Router>
      )}
    </>
  );
}

export default withRouter(SettingsManagement);
