import React, { useState, useEffect, useRef, FormEvent } from "react";
import { Icon, Button, Form, Modal } from "semantic-ui-react";
import { uploadFile, FirmwareType } from "../../../../../BytebeamClient";
import { Mixpanel } from "../../../common/MixPanel";
import AnimatedEllipsis from "../../../common/AnimatedEllipsis";
import {
  StyledFileInput,
  StyledFileUploadButton,
} from "../../../DeviceMangaement/Devices/ActionModals/SendFileModal";

type CreateFirmwareModalProps = {
  type: string;
  id: string;
  isOpen: boolean;
  close: () => void;
  fillFirmwareTable: (showDeactivatedFirmwares?: boolean) => Promise<void>;
  allExistingFirmwareVersions: FirmwareType[];
};

function CreateFirmwareModal(props: CreateFirmwareModalProps) {
  const {
    type,
    id,
    isOpen,
    close,
    fillFirmwareTable,
    allExistingFirmwareVersions,
  } = props;

  const [versionNumber, setVersionNumber] = useState<string>("");
  const [existingFirmwareVersions, setExistingFirmwareVersions] = useState<
    Set<string>
  >(new Set());
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string>("");
  const [errors, setErrors] = useState<{ versionNumber: string }>({
    versionNumber: "",
  });
  const [fileLoaded, setFileLoaded] = useState<number>(0);
  const [fileTotal, setFileTotal] = useState<number>(0);
  const [showUploadProgress, setShowUploadProgress] = useState<boolean>(false);
  const [disableUploadButton, setDisableUploadButton] =
    useState<boolean>(false);
  const fileInput = useRef<HTMLInputElement | null>(null);

  const createFirmware = async () => {
    const url =
      type === "Edit" ? `/api/v1/firmwares/${id}` : `/api/v1/firmwares`;
    let formData = new FormData();
    formData.append("version-number", versionNumber);
    if (file !== null) formData.append("file", file);

    try {
      const res = await uploadFile(url, formData, (p) => {
        setFileLoaded(p.loaded);
        setFileTotal(p.total);
      });
      if (res.status === 201) {
        window.toastr.success(
          "Created Firmware version " + res.data["version-number"]
        );

        Mixpanel.track("Uploaded Firmware", {
          Firmware: res.data["version-number"],
        });
        await fillFirmwareTable();
        onCloseModal();
      }
    } catch (error) {
      Mixpanel.track("Failure", {
        type: "Upload Firmware",
      });
      // Handling Error for File Size exceeding limit from NGINX
      if (String(error).includes("413"))
        window.toastr.error("Upload failed due to size limit!");
      else window.toastr.error("Failed to create Firmware version :(");
      onCloseModal();
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (fileInput.current?.files) {
      const fileCount: number = fileInput.current.files.length;
      if (versionNumber.length === 0) {
        setErrors({
          versionNumber: "Please Enter a Version number",
        });
      } else if (existingFirmwareVersions.has(versionNumber)) {
        setErrors({
          versionNumber: "This firmware version already exists",
        });
      } else if (fileCount === 0) {
        setTimeout(() =>
          window.toastr.error("A firmware file must be selected!")
        );
      } else {
        // form is valid
        setShowUploadProgress(true);
        setDisableUploadButton(true);
        await createFirmware();
      }
    } else {
      setTimeout(() =>
        window.toastr.error("A firmware file must be selected!")
      );
    }
  };

  // Reset state and close modal
  function onCloseModal() {
    // Reset state
    setVersionNumber("");
    setFile(null);
    setFileName("");
    setErrors({
      versionNumber: "",
    });
    setFileLoaded(0);
    setFileTotal(0);
    setShowUploadProgress(false);
    setDisableUploadButton(false);

    // Close Modal
    close();
  }

  useEffect(() => {
    const existingVersions: Set<string> = new Set();

    allExistingFirmwareVersions.forEach((firmware: FirmwareType) => {
      existingVersions.add(firmware.version_number);
    });
    setExistingFirmwareVersions(existingVersions);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal open={isOpen} onClose={close} size="tiny" className="dark">
      <Modal.Header>Create New Firmware Version</Modal.Header>

      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Firmware Version Number</label>
            <Form.Input
              id="firmware_version_number"
              autoFocus
              error={errors.versionNumber !== "" ? errors.versionNumber : null}
              placeholder="1.0.0"
              value={versionNumber}
              onChange={(e) => {
                setVersionNumber(e.target.value);
                setErrors({
                  versionNumber: "",
                });
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Upload File from the system: </label>
            <div style={{ position: "relative", marginTop: "12px" }}>
              <StyledFileUploadButton
                fluid
                content="Select File"
                labelPosition="left"
                icon="file"
              />
              <StyledFileInput
                id="upload_file"
                type="file"
                ref={fileInput}
                onChange={(e) => {
                  if (e.target.files) {
                    setFile(e.target.files[0]);
                    setFileName(e.target.files[0].name);
                  }
                }}
              />
            </div>
            <label style={{ marginTop: "12px" }}>File Chosen: </label>
            {fileName !== "" ? fileName : "No File Chosen"}
          </Form.Field>

          {showUploadProgress && (
            <Form.Field>
              <label htmlFor="file-progress">
                Uploading Firmware File{" "}
                <AnimatedEllipsis spacing={3} dotSize={"8px"} />
              </label>
              <progress id="file-progress" max={fileTotal} value={fileLoaded} />
            </Form.Field>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          id="cancel_button"
          secondary
          onClick={() => {
            onCloseModal();
          }}
        >
          <Icon name="remove" /> Discard
        </Button>
        <Button
          id="submit_button"
          disabled={disableUploadButton}
          primary
          onClick={async (e) => {
            await handleSubmit(e);
          }}
        >
          <Icon name="checkmark" /> Upload
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default CreateFirmwareModal;
