import moment, { DurationInputArg2 } from "moment";


export type SerializedRelativeTimestamp = {
  type: "RelativeTimestamp";
  duration: number;
  units: DurationInputArg2;
}

export type SerializedAbsoluteTimestamp = {
  type: "AbsoluteTimestamp";
  date: number;
}

export type SerializedTimestamp = SerializedRelativeTimestamp | SerializedAbsoluteTimestamp;

export interface Timestamp {
  toDate(): Date;
  toString(): string;
  serialize(): SerializedTimestamp;
}

export function deserializeTimestamp(o: SerializedTimestamp) {
  if (o.type === "RelativeTimestamp") {
    return RelativeTimestamp.fromSerialized(o);
  } else {
    return AbsoluteTimestamp.fromSerialized(o);
  }
}

export class RelativeTimestamp implements Timestamp {
  duration: number;
  units: DurationInputArg2;

  static fromSerialized(o: SerializedRelativeTimestamp) {
    return new RelativeTimestamp(o.duration, o.units);
  }

  constructor(duration: number, units: DurationInputArg2) {
    this.duration = duration;
    this.units = units;
  }

  toDate() {
    return moment().subtract(this.asMomentDuration()).toDate();
  }

  toString() {
    return moment().subtract(this.asMomentDuration()).fromNow();
  }

  asMomentDuration() {
    return moment.duration(this.duration, this.units);
  }

  serialize(): SerializedRelativeTimestamp {
    return {
      type: "RelativeTimestamp",
      duration: this.duration,
      units: this.units,
    }
  }
}

export class AbsoluteTimestamp implements Timestamp {
  date: Date;

  static fromSerialized(o: SerializedAbsoluteTimestamp) {
    return new AbsoluteTimestamp(new Date(o.date));
  }

  constructor(date: Date) {
    this.date = date;
  }

  toDate() {
    return this.date;
  }

  toString() {
    return moment(this.date).format("DD-MM-YY HH:mm:ss");
  }

  serialize(): SerializedAbsoluteTimestamp {
    return {
      type: "AbsoluteTimestamp",
      date: this.date.valueOf(),
    }
  }
}
