import { SessionsData, SessionsMetaData } from "./PanelDef";
import { PanelViewComponent } from "../PanelDef";
import React from "react";
import { formatValue, TableContainer } from "../util";
import { Table } from "semantic-ui-react";
import moment from "moment";
import { DashboardLinks } from "../../DashboardLinks";
import { AbsoluteTimeRange } from "../../Datetime/TimeRange";
import { AbsoluteTimestamp } from "../../Datetime/Timestamp";

type ViewSessionsState = {};

export class ViewSessions extends PanelViewComponent<
  SessionsMetaData,
  SessionsData,
  ViewSessionsState
> {
  render() {
    const aggregates = this.props.panelMeta.aggregates;
    const data = this.props.data.data;

    return (
      <TableContainer>
        <div className="tableContentContainer">
          <Table compact selectable size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ cursor: "unset" }}>
                  Device ID
                </Table.HeaderCell>

                <Table.HeaderCell style={{ cursor: "unset" }} width={3}>
                  Start time
                </Table.HeaderCell>

                <Table.HeaderCell style={{ cursor: "unset" }} width={3}>
                  End time
                </Table.HeaderCell>

                <Table.HeaderCell style={{ cursor: "unset" }}>
                  Durations
                </Table.HeaderCell>

                {aggregates.map((aggregate) => (
                  <Table.HeaderCell
                    style={{ cursor: "unset" }}
                    key={`${aggregate.name}-${aggregate.table}-${aggregate.column}`}
                  >
                    {aggregate.name}
                  </Table.HeaderCell>
                ))}

                <Table.HeaderCell style={{ cursor: "unset" }}>
                  Dashboards
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {data.map((p, i) => {
                return (
                  <Table.Row key={`${p.device_id}-${i}`}>
                    <Table.Cell>{p.device_id}</Table.Cell>
                    <Table.Cell>
                      {new Date(p.start_time).toLocaleString()}
                    </Table.Cell>
                    <Table.Cell>
                      {new Date(p.end_time).toLocaleString()}
                    </Table.Cell>
                    <Table.Cell>
                      {moment
                        .duration(p.end_time - p.start_time)
                        .humanize({ ss: -1 })}
                    </Table.Cell>

                    {aggregates.map((aggregate) => {
                      const value = p.aggregates[aggregate.name];

                      return (
                        <Table.Cell key={`${aggregate.name}`}>
                          {formatValue(value)}
                        </Table.Cell>
                      );
                    })}

                    <Table.Cell>
                      <DashboardLinks
                        dashboardIds={
                          this.props.panelMeta.deviceDashboardIds || []
                        }
                        allDashboards={this.props.dashboards}
                        deviceId={String(p.device_id)}
                        timeRange={
                          new AbsoluteTimeRange(
                            new AbsoluteTimestamp(new Date(p.start_time)),
                            new AbsoluteTimestamp(new Date(p.end_time))
                          )
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      </TableContainer>
    );
  }
}
