import uuid from "uuid";
import { PanelDef } from "../PanelDef";
import { ViewTimeseriesTable } from "./ViewTimeseriesTable";
import { EditTimeseriesTableMeta } from "./EditTimeseriesTableMeta";
import { Query } from "../LastValue/PanelDef";

export class TimeseriesTableMetaData {
  type = "timeseries_table";
  id: string = uuid.v4();
  title: string = "";
  description: string = "";
  sortOrder: string = "desc";
  table: string = "";
  columns: string[] = [];
  rowsPerPage: number = 50;
  query: Query[] = [];
  showNumericValuesInHex?: boolean = false;
}

export type TimeseriesTablePoint = {
  id: string;
  timestamp: number;
  [key: string]: any;
};

export type TimeseriesTableData = {
  data: TimeseriesTablePoint[];
  totalRows: number;
};

export const TimeseriesTablePanelDef: PanelDef<
  TimeseriesTableMetaData,
  TimeseriesTableData
> = {
  ViewComponent: ViewTimeseriesTable,
  EditComponent: EditTimeseriesTableMeta,
  formatData: (data) => data,
  metaConstructor: TimeseriesTableMetaData,
  previewAspectRatio: { width: 500, height: 300 },
  download: "server",
};
