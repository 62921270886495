import React, { useCallback, useEffect } from "react";
import { Pagination } from "semantic-ui-react";
import {
  Device,
  DeviceFilterOption,
  DeviceFilters,
  Key,
  SearchDeviceResponse,
} from "../../../../BytebeamClient";
import { ErrorMessage } from "../../../common/ErrorMessage";
import ActionModalDeviceFilter from "./ActionModalDeviceFilter";
import { ActionModalSearchResults } from "./ActionModalSearchResults";
import { ThinDivider } from "../../Dashboards/Panel/util";
import { FilterListObject } from "./NewActionModal";
import LoadingAnimation from "../../../common/Loader";

type SelectDevicesModalProps = {
  // setDevices: (any) => any;
  devices: SearchDeviceResponse;
  setDisableNextButton: (arg0: boolean) => void;
  filters: DeviceFilters;
  setFilters: (arg0: DeviceFilters) => void;
  selectedDevices: SearchDeviceResponse;
  setSelectedDevices: (arg0: SearchDeviceResponse) => void;
  allSelected: boolean;
  setAllSelected: (arg0: boolean) => void;
  initialFetch: () => void;
  refreshDevices: (arg0: number, arg1: DeviceFilters) => void;
  refreshFilterOptions: (arg0: DeviceFilters, arg1: any) => void;
  abortController: AbortController;
  allKeys: {
    key: string;
  }[];
  allowedFilterBys: Key[];
  setAllowedFilterBys: (arg0: Key[]) => void;
  loading: boolean;
  setLoading: (arg0: boolean) => void;
  filterLoading: boolean;
  setFilterLoading: (arg0: boolean) => void;
  filterOptions: DeviceFilterOption[];
  pageLimit: number;
  page: number;
  setPage: (arg0: number) => void;
  filterList: FilterListObject[];
  setFilterList: (arg0: FilterListObject[]) => void;
};

function SelectDevicesModal(props: SelectDevicesModalProps) {
  const updateFilters = (filterName: string, filterValues: string[]) => {
    const myFilters = Object.assign({}, props.filters);

    if (filterValues.length > 0) {
      myFilters[filterName] = filterValues;
    } else {
      delete myFilters[filterName];
    }

    props.setFilters(myFilters);
    props.setFilters(myFilters);
    props.setPage(1);
    props.setLoading(true);
    props.setSelectedDevices({ devices: [], count: 0 });
    props.setAllSelected(false);

    props.refreshFilterOptions(myFilters, props.allowedFilterBys);
    props.refreshDevices(props.page, myFilters);
  };

  const onPageChange = useCallback(
    (e, { activePage }) => {
      props.setLoading(true);
      props.setPage(activePage as number);
      props.refreshDevices(activePage, props.filters);
    },
    [props.filters] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const resetFilters = () => {
    props.setLoading(true);
    props.setPage(1);
    props.setFilterLoading(true);
    props.setAllowedFilterBys([]);
    props.setFilters({});
    props.setFilterList([]);
    props.setSelectedDevices({ devices: [], count: 0 });
    props.setAllSelected(false);
  };

  const selectDevice = (device: Device) => {
    let updatedDevices: SearchDeviceResponse = {
      devices: [],
      count: 0,
    };
    updatedDevices = {
      devices: [...props.selectedDevices.devices, device],
      count: props.selectedDevices.count + 1,
    };

    props.setSelectedDevices(updatedDevices);
  };

  const clearDevice = (device: Device) => {
    let index = -1;
    props.selectedDevices.devices.forEach((mappedDevice) => {
      index += 1;
      if (mappedDevice.id === device.id) {
        let deviceList: Device[] = props.selectedDevices.devices;
        deviceList.splice(index, 1);

        let updatedDevices: SearchDeviceResponse = {
          devices: deviceList,
          count: props.selectedDevices.count - 1,
        };

        props.setSelectedDevices(updatedDevices);
      }
    });
  };

  useEffect(() => {
    if (
      Object.keys(props.filters).length === 0 &&
      (!props.allowedFilterBys || props.allowedFilterBys.length === 0)
    ) {
      props.initialFetch();
    }

    return () => {
      props.abortController.abort();
    };
  }, [props.filters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.loading && props.filterLoading) props.setDisableNextButton(true);
    else if (props.devices?.count === 0 || props.devices?.devices.length === 0)
      props.setDisableNextButton(true);
    else if (
      props.selectedDevices.count === 0 ||
      props.selectedDevices.devices.length === 0
    )
      props.setDisableNextButton(true);
    else props.setDisableNextButton(false);
  }, [props.loading, props.filterLoading, props.selectedDevices]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <p
        style={{
          fontSize: "1.3rem",
          marginTop: "2rem",
          fontWeight: "bold",
        }}
      >
        Filter Devices
      </p>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
        }}
      >
        {!props.filterLoading &&
          // !loading &&
          props.filterOptions &&
          props.filterOptions?.length !== 0 && (
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
              }}
            >
              <ActionModalDeviceFilter
                filterOptions={props.filterOptions}
                onFilterSelected={updateFilters}
                filterList={props.filterList}
                setFilterList={(value) => props.setFilterList(value)}
              />
              <div
                className={`${
                  Object.keys(props.filters).length === 0
                    ? "color-disabled"
                    : "selectable-item underline hover-underline color-blue"
                }`}
                onClick={() => {
                  if (Object.keys(props.filters).length !== 0) resetFilters();
                }}
                style={{
                  width: "150px",
                }}
              >
                Clear Selection
              </div>
            </div>
          )}
        <ThinDivider />
        {(props.filterLoading || props.loading) && (
          <LoadingAnimation
            loaderContainerHeight="350px"
            loadingText="Loading devices"
            fontSize="20px"
          />
        )}
        {!props.filterLoading &&
          !props.loading &&
          props.devices &&
          props.devices.devices.length !== 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <ActionModalSearchResults
                devices={props.devices}
                checkEnabled={true}
                allSelected={props.allSelected}
                setAllSelected={(value) => props.setAllSelected(value)}
                selectedDevices={props.selectedDevices}
                setSelectedDevices={(devices) =>
                  props.setSelectedDevices(devices)
                }
                selectDevice={(id) => selectDevice(id)}
                clearDevice={(id) => clearDevice(id)}
              />
              <div style={{ marginTop: "1rem" }}>
                <Pagination
                  boundaryRange={0}
                  defaultActivePage={props.page}
                  ellipsisItem={null}
                  siblingRange={2}
                  totalPages={Math.ceil(props.devices.count / props.pageLimit)}
                  onPageChange={onPageChange}
                />
              </div>
            </div>
          )}

        {!props.filterLoading &&
          !props.loading &&
          props.devices?.devices.length === 0 &&
          props.filterOptions?.length === 0 && (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ErrorMessage marginTop="30px" message={"No devices found!"} />
            </div>
          )}
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "3rem",
        }}
      >
        <p
          style={{
            fontSize: "1.2rem",
            fontWeight: "bold",
            marginRight: "1rem",
            marginBottom: "0",
          }}
        >
          Total Device Selected:{" "}
        </p>
        <div
          style={{
            padding: "0.5rem 1rem",
            border: "1px solid #3D3B3B",
            marginRight: "1rem",
          }}
        >
          <span id={"selected_device_count"}>
            {props.selectedDevices.count}
          </span>
        </div>
      </div>
    </>
  );
}

export default SelectDevicesModal;
