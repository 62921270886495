import React, { useState, useEffect } from "react";
import { Breadcrumb, Icon, Button, Grid, Form } from "semantic-ui-react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/theme-chaos";
import {
  editDeviceConfiguration,
  createDeviceConfiguration,
  fetchDeviceConfigurationDetails,
} from "../../../../BytebeamClient";
import { Mixpanel } from "../../common/MixPanel";
import { useHistory, useLocation } from "react-router-dom";
import ThemeSchema from "../../../../theme/schema";

type EditDeviceConfigurationsProps = {
  type: string;
  name: string;
  title: string;
  actionButtonText: string;
  theme: string;
};

function EditDeviceConfigurations(props: EditDeviceConfigurationsProps) {
  const { type, name, title, actionButtonText, theme } = props;

  const [loadingConfig, setLoadingConfig] = useState<boolean>(true);
  const [configJSON, setConfigJSON] = useState<string>("");
  const [versionName, setVersionName] = useState<string>("");
  const [existingConfigVersions, setExistingConfigVersions] = useState<
    Set<string>
  >(new Set());
  const [errors, setErrors] = useState<{ versionName: string }>({
    versionName: "",
  });

  const history = useHistory();
  const location = useLocation();

  const callDeviceConfigApi = () => {
    const configJson = JSON.parse(configJSON);
    if (type === "Edit") {
      return editDeviceConfiguration(name, configJson);
    } else {
      return createDeviceConfiguration(
        versionName,
        configJson,
        "update_config"
      );
    }
  };

  const updateDeviceConfig = async () => {
    try {
      const newConfig = await callDeviceConfigApi();
      window.toastr.success(
        `${type === "Edit" ? "Updated" : "Created"} Device Configuration ${
          newConfig.version_name
        }`
      );

      // created or updated
      history.push("/device-management/configurations");

      Mixpanel.track("Created Configuration", {
        Config: newConfig.version_name,
      });
    } catch (e) {
      Mixpanel.track("Failure", {
        type: "Upload Configuration",
        error: JSON.stringify(e),
      });
      console.log(e);
    }
  };

  async function fetchConfigDetails() {
    try {
      const config = await fetchDeviceConfigurationDetails(name);
      setLoadingConfig(false);
      setConfigJSON(JSON.stringify(config.config_json, null, 2));
      setVersionName(config.version_name);
    } catch (e) {
      console.log(e);
    }
  }

  function isJSONValid(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (versionName.length === 0) {
      setErrors({
        versionName: "Please enter a Version Name",
      });
    } else if (existingConfigVersions.has(versionName)) {
      setErrors({
        versionName: "This device config version already exists",
      });
    } else if (!isJSONValid(configJSON)) {
      window.toastr.error("Your Configuration JSON is invalid");
    } else {
      // valid data, submit
      updateDeviceConfig();
    }
  };

  useEffect(() => {
    const existingVersions: Set<string> = new Set();
    if (location?.state && type !== "Edit") {
      location.state.forEach((config) => {
        existingVersions.add(config.version_name);
      });
      setExistingConfigVersions(existingVersions);
    }
    if (type === "Edit") {
      setLoadingConfig(true);
      fetchConfigDetails();
    } else {
      setLoadingConfig(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Breadcrumb>
            <Breadcrumb.Section
              link
              onClick={() => {
                history.push("/device-management/configurations");
              }}
            >
              Device Configs
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right chevron" />
            <Breadcrumb.Section active>
              {type === "Edit" ? "Edit Config" : "New Config"}
            </Breadcrumb.Section>
          </Breadcrumb>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row centered>
        <Grid.Column width="12">
          <h3>{title}</h3>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row centered>
        <Grid.Column width="12">
          <Form onSubmit={handleSubmit}>
            <Form.Field>
              <label>Version Name</label>
              <Form.Input
                autoFocus={type !== "Edit"}
                disabled={type === "Edit"}
                error={errors.versionName !== "" ? errors.versionName : null}
                placeholder={type === "Edit" ? name : "production v1"}
                value={versionName}
                onChange={(e) => {
                  setVersionName(e.target.value);
                  setErrors({
                    versionName: "",
                  });
                }}
              />
            </Form.Field>
            <Form.Field>
              <label>Configuration JSON</label>
              <AceEditor
                height="300px"
                width="800px"
                placeholder={
                  loadingConfig ? "Loading..." : "Enter Configuration JSON"
                }
                mode="json"
                theme={ThemeSchema.data[theme ?? 'dark']?.colors["ace-editor-theme"]}
                name="config-json"
                onChange={(val) => {
                  setConfigJSON(val);
                }}
                focus={type === "Edit"}
                fontSize={14}
                value={configJSON}
                showPrintMargin={false}
                showGutter={true}
                highlightActiveLine={true}
                setOptions={{
                  enableBasicAutocompletion: false,
                  enableLiveAutocompletion: false,
                  enableSnippets: false,
                  showLineNumbers: true,
                  tabSize: 2,
                }}
                style={{
                  marginBottom: "16px",
                  borderRadius: "4px",
                  border: `${ThemeSchema.data[theme ?? "dark"]?.colors["ace-editor-border"]}`,
                  boxShadow: `${ThemeSchema.data[theme ?? "dark"]?.colors["ace-editor-box-shadow"]}`,
                }}
              />
            </Form.Field>
            <Button.Group size="tiny">
              <Button
                labelPosition="left"
                icon
                secondary
                onClick={() => {
                  history.push("/device-management/configurations");
                }}
              >
                <Icon name="cancel" />
                Discard
              </Button>
              <Button.Or />
              <Button labelPosition="left" icon primary type="submit">
                <Icon name="check" />
                {actionButtonText}
              </Button>
            </Button.Group>
          </Form>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default EditDeviceConfigurations;
