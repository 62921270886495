import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { useUser } from "../../../../context/User.context";
import LoadingAnimation from "../../../common/Loader";
import ThemeSchema from "../../../../theme/schema";

interface ActionsHistogramProps {
  setTotalCount: number;
  //   setInitiatedListCount: number;
  //   setQueuedListCount: number;
  //   setProgressListCount: number;
  //   setFailedListCount: number;
  //   setCompletedListCount: number;
  height: number;
  textInfo: boolean;
}

function ActionsHistogram(props: ActionsHistogramProps) {
  const { user } = useUser();
  const theme = user?.settings?.theme;

  const [x, setX] = useState<number[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // function removeDuplicates(arr) {
  //   return arr.filter((item, index) => arr.indexOf(item) === index);
  // }

  useEffect(() => {
    var y: number[] = [];
    var z: number[] = [];
    var final: number[] = [];

    for (var i = 0; i < 250; i++) {
      y[i] = Math.floor(Math.random() * 101);
    }

    for (var j = 0; j < 250; j++) {
      z[j] = Math.floor(Math.random() * 101);
    }

    y = y.sort();
    z = z.sort();
    z = z.reverse();

    final = y.concat(z);
    setX(final);
  }, []);

  return props.setTotalCount === 0 || props.setTotalCount === undefined ? (
    <div>
      <LoadingAnimation loadingText={`Loading bar chart`} fontSize="20px" />
    </div>
  ) : (
    <div style={{ width: "100%", height: "100%" }}>
      <Plot
        style={{ width: "100%", height: "100%" }}
        data={[
          {
            name: "Latency Histogram",
            x: x,
            // y: ["Queued", "Initiated", "In Progress", "Completed", "Failed"],
            marker: {
              showscale: false,
              color: "#7C3EFF",
              // ThemeSchema.data[theme ?? "dark"]?.colors[
              //   "chart_state-color-scheme-array"
              // ],
            },
            type: "bar",
            orientation: "v",
            showlegend: false,
            hoverinfo: "x+text",
          },
        ]}
        useResizeHandler
        layout={{
          autosize: true,

          height: props.height,
          bargap: 0.01,
          margin: {
            l: 30,
            r: 30,
            b: 20,
            t: 20,
          },
          xaxis: {
            color:
              ThemeSchema.data[theme ?? "dark"]?.colors["chart-text-color"],
            showline: true,
            showgrid: false,
            fixedrange: true,
          },
          yaxis: {
            color:
              ThemeSchema.data[theme ?? "dark"]?.colors["chart-text-color"],
            showgrid: false,
            fixedrange: true,
            ticklen: 2,
          },
          paper_bgcolor: "transparent",
          plot_bgcolor: "transparent",
        }}
        config={{
          displayModeBar: false,
        }}
      />
    </div>
  );
}

export default ActionsHistogram;
