import { DeviceRoute } from "../TrackDevices/PanelDef";

import uuid from "uuid";
import { PanelDef } from "../PanelDef";
import { ViewLocateDevices } from "./ViewLocateDevices";
import { EditLocateDevicesMeta } from "./EditLocateDevicesMeta";
import { formatData } from "../TrackDevices/PanelDef";
import { StateColor } from "../StateTimeline/PanelDef";

export class LocateDevicesMetaData {
  type = "locate_devices";
  table: string = "";
  latitude_column: string = "";
  longitude_column: string = "";
  id: string = uuid.v4();
  title: string = "";
  description: string = "";

  // device_dashboard_id is the legacy name where only one dashboard was allowed
  // device_dashboard_ids is the new name.
  device_dashboard_id?: string = "";
  device_dashboard_ids?: string[] = [];
  
  trafficLayerFlag: boolean = false;
  satelliteLayerFlag: boolean = false;

  markerIcon?: string = "default";

  colorMapping?: StateColor[] = [];

  metadataKeys?: string[] = [];
  deviceShadowKeys?: string[] = [];
}

export type DevicePosition = {
  latitude: number;
  longitude: number;
  id: string;
};

export type LocateDevicesData = DeviceRoute[];

export const LocateDevicesPanelDef: PanelDef<
  LocateDevicesMetaData,
  LocateDevicesData
> = {
  ViewComponent: ViewLocateDevices,
  EditComponent: EditLocateDevicesMeta,
  formatData: formatData,
  metaConstructor: LocateDevicesMetaData,
  previewAspectRatio: { width: 500, height: 300 },
};
