import React from "react";
import Plot from "react-plotly.js";
import { useUser } from "../../../../context/User.context";
import ThemeSchema from "../../../../theme/schema";

interface RadialChartProps {
  setTotalCount: number;
  setESPCount: number;
  setDownloadCount: number;
  setNetworkCount: number;
  height: number;
  width: number;
  textInfo: boolean;
}

function RadialChart(props: RadialChartProps) {
  const { user } = useUser();
  const theme = user?.settings?.theme;

  return props.setTotalCount === 0 || props.setTotalCount === undefined ? (
    <div></div>
  ) : (
    <div>
      <Plot
        data={[
          {
            name: "Actions Summary",
            values: [
              props.setESPCount,
              props.setDownloadCount,
              props.setNetworkCount,
            ],
            labels: [
              "ESP Failure: 103",
              "Download Failure 477",
              "Network Issue: 894",
            ],
            marker: {
              showscale: false,
              colors:
                ThemeSchema.data[theme ?? "dark"]?.colors[
                  "chart_state-color-scheme-array"
                ],
            },
            type: "pie",
            showlegend: false,
            hole: 0.8,
            textinfo: props.textInfo ? "percent" : "none",
            hoverlabel: {
              bordercolor:
                ThemeSchema.data[theme ?? "dark"]?.colors[
                  "bars-inner-text-color"
                ],
              font: {
                color:
                  ThemeSchema.data[theme ?? "dark"]?.colors[
                    "bars-inner-text-color"
                  ],
                family: "Open Sans",
                size: 12,
              },
            },
            insidetextfont: {
              color:
                ThemeSchema.data[theme ?? "dark"]?.colors[
                  "bars-inner-text-color"
                ],
              family: "Open Sans",
              size: 12,
            },
            outsidetextfont: {
              color:
                ThemeSchema.data[theme ?? "dark"]?.colors["chart-text-color"],
              family: "Open Sans",
              size: 12,
            },
          },
        ]}
        layout={{
          height: props.height,
          width: props.width,
          margin: {
            l: 0,
            r: 0,
            b: 20,
            t: 20,
          },
          paper_bgcolor: "transparent",
          plot_bgcolor: "transparent",
        }}
        config={{
          displayModeBar: false,
        }}
      />
    </div>
  );
}

export default RadialChart;
