import React, { useState, useRef, useEffect } from "react";
import { Table, Icon } from "semantic-ui-react";
import ConfirmationModal from "../../common/ConfirmationModal";
import AddNewColumn from "./AddNewColumn";
import ColumnType from "./ColumnType";
import CreateStreamModal from "./CreateStreamModal";
import { filter, mapIf, updateColumns, mapColumnIf } from "./functional";
import Layout from "../../common/Layout";
import { ErrorMessage } from "../../../common/ErrorMessage";
import useAsyncEffect from "../../common/useAsyncEffect";
import { DisplayIf, filterStreamTableInfo } from "../../util";
import {
  createStreamField,
  deleteStream,
  deleteStreamField,
  updateStreamField,
  fetchAllStreamsWithDetails,
} from "../../../../BytebeamClient";
import ConfirmationModalMessage from "../../common/ConfirmationModalMessage";
import LoadingAnimation from "../../../common/Loader";

const DeleteTableModal = ({ tableName, onConfirm }) => (
  <ConfirmationModal
    prefixContent="Delete stream"
    expectedText={tableName}
    onConfirm={onConfirm}
    trigger={<Icon link name="trash" />}
    message={
      <ConfirmationModalMessage name={tableName} type={"Stream Table"} />
    }
  />
);

const DeleteColModal = ({ columnName, onConfirm }) => (
  <ConfirmationModal
    prefixContent="Delete column"
    expectedText={columnName}
    onConfirm={onConfirm}
    trigger={<Icon link name="trash" />}
    message={<ConfirmationModalMessage name={columnName} type={"Column"} />}
  />
);

const sortColumns = (columns) => {
  columns.sort((a, b) => {
    let alphaCompare = a.name.localeCompare(b.name);
    if (a.required) {
      if (b.required) {
        return alphaCompare;
      } else {
        return -1;
      }
    } else {
      if (b.required) {
        return +1;
      } else {
        return alphaCompare;
      }
    }
  });
  return columns;
};

let requestIdInternal = 0;
const newRequestId = () => {
  return requestIdInternal++;
};

export default function Streams({ user }) {
  const [tables, setTables] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const permissions = user.role.permissions;
  const theme = user?.settings?.theme ?? "dark";

  useEffect(() => {
    // To scroll to top of the screen
    window.scrollTo(0, 0);
  }, []);

  const resetTable = async () => {
    setLoading(true);
    try {
      let response = await fetchAllStreamsWithDetails(),
        // Filtering the stream table info to remove streams name starting with uplink_ and ending with _local
        // and also filtering out columns ending with _timestamp
        filteredStreamsWithDetails = filterStreamTableInfo(
          await response,
          true
        );

      let streamsWithDetails = Object.entries(filteredStreamsWithDetails).map(
        ([streamName, streamDetails]) => {
          let stream = {
            tableName: streamName,
            // Sorting the columns by name and adding newType and status properties
            cols: sortColumns(streamDetails).map((col) => ({
              ...col,
              newType: col.type,
              status: "ready",
            })),
          };
          return stream;
        }
      );
      setTables(streamsWithDetails);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setErrorOccurred(true);
    }
  };

  useAsyncEffect(resetTable, []);
  useEffect(() => {
    document.title = "Streams | Bytebeam";
  }, []);

  const tablesRef = useRef();
  tablesRef.current = tables;

  // Normally to add a new table, you would:
  // setTable([newTable, ...tables])
  // But if it is done in a callback, you will only
  // have access to old tables. To avoid this nuance, use
  // updateTables(tables => [newTable, ...tables])
  // instead.
  const updateTables = (transition) => {
    setLoading(true);
    setTables(transition(tablesRef.current));
    setLoading(false);
  };

  const addNewCol = async (tableName, columnName, columnType) => {
    const requestId = newRequestId();
    columnName = columnName.replace(" ", "_").trim();

    updateTables(
      mapIf(
        (table) => table.tableName === tableName,
        updateColumns((cols) => [
          ...cols,
          {
            requestId,
            name: columnName,
            type: columnType,
            status: "initiated",
          },
        ])
      )
    );

    try {
      await createStreamField({
        fieldName: columnName,
        fieldType: columnType,
        streamName: tableName,
      });

      updateTables(
        mapIf(
          (table) => table.tableName === tableName,
          mapColumnIf(
            (col) => col.requestId === requestId,
            (col) => ({ ...col, status: "ready" })
          )
        )
      );

      window.toastr.success(
        `New column ${columnName} is created in ${tableName} stream successfully`
      );
    } catch (e) {
      window.toastr.error(
        `Error creating ${columnName} in ${tableName} stream column`
      );
      console.log(e);
    }
  };

  const deleteTable = (tableName) => {
    updateTables(
      mapIf(
        (table) => table.tableName === tableName,
        (table) => ({ ...table, slatedForDeletion: true })
      )
    );

    deleteStream(tableName)
      .then(() => {
        updateTables(filter((row) => row.tableName !== tableName));
        window.toastr.success(`${tableName} stream is deleted successfully`);
      })
      .catch((e) => {
        updateTables(
          mapIf(
            (table) => table.tableName === tableName,
            (table) => ({
              ...table,
              slatedForDeletion: false,
              status: "error",
              error: e.message,
            })
          )
        );
        window.toastr.error(`Error deleting ${tableName} stream`);
      });
  };

  const deleteColumn = (tableName, columnName) => {
    //
    updateTables(
      mapIf(
        (table) => table.tableName === tableName,
        mapColumnIf(
          (col) => col.name === columnName,
          (col) => ({ ...col, status: "deleting" })
        )
      )
    );

    deleteStreamField(tableName, columnName)
      .then(() => {
        updateTables(
          mapIf(
            (table) => table.tableName === tableName,
            updateColumns(filter((col) => col.name !== columnName))
          )
        );
        window.toastr.success(
          `column ${columnName} from ${tableName} stream is deleted successfully`
        );
      })
      .catch((err) => {
        updateTables(
          mapIf(
            (table) => table.tableName === tableName,
            mapColumnIf(
              (col) => col.name === columnName,
              (col) => ({ ...col, status: "error", error: err.message })
            )
          )
        );
        window.toastr.error(
          `Error deleting column ${columnName} from ${tableName} stream`
        );
      });
  };

  const editColumn = (tableName, columnName, newType) => {
    updateTables(
      mapIf(
        (table) => table.tableName === tableName,
        mapColumnIf(
          (col) => col.name === columnName,
          (col) => ({
            ...col,
            newType: newType,
            status: "editing",
          })
        )
      )
    );

    updateStreamField({
      streamName: tableName,
      fieldName: columnName,
      fieldType: newType,
    })
      .then(() =>
        updateTables(
          mapIf(
            (table) => table.tableName === tableName,
            mapColumnIf(
              (col) => col.name === columnName,
              (col) => ({
                ...col,
                type: newType,
                status: "ready",
              })
            )
          )
        )
      )
      .then(() => {
        window.toastr.success(
          `column ${columnName} type is updated to ${newType} successfully`
        );
      })
      .catch((err) => {
        updateTables(
          mapIf(
            (table) => table.tableName === tableName,
            mapColumnIf(
              (col) => col.name === columnName,
              (col) => ({
                ...col,
                newType: [col.type], // reset to old type!
                status: "error",
                error: err.message,
              })
            )
          )
        );
      });

    // TODO: add code for switching column type
    console.log(tableName, columnName, newType);
  };

  const ActiveTable = ({ tableName, cols, slatedForDeletion, error }) => {
    const columnNameSet = new Set(
      cols.map((col) => col.name.toLowerCase().replace(" ", "_").trim())
    );
    return [
      ...cols.map(({ name, type, newType, required, status }, index) => (
        <Table.Row key={tableName + ":" + name} warning={type !== newType}>
          {index === 0 && (
            <Table.Cell rowSpan={1 + cols.length} verticalAlign="top">
              {" "}
              {tableName}
            </Table.Cell>
          )}
          {/* table name only needed for first row */}
          <Table.Cell>{name}</Table.Cell>

          <Table.Cell>
            <ColumnType
              columnName={name}
              required={required}
              status={status}
              pendingType={newType}
              value={type}
              permission={permissions.editStreams}
              onChange={(newType) => editColumn(tableName, name, newType)}
            />
          </Table.Cell>

          <Table.Cell>
            {status !== "ready" && status !== "error" && status}
            {/* TODO: Add styling for status */}
            {status === "error" && JSON.stringify(error)}
            {!required && status === "ready" && (
              <DisplayIf cond={permissions.editStreams}>
                <DeleteColModal
                  columnName={name}
                  onConfirm={() => deleteColumn(tableName, name)}
                />
              </DisplayIf>
            )}
          </Table.Cell>

          {index === 0 && tableName !== "device_shadow" && (
            <Table.Cell rowSpan={1 + cols.length} verticalAlign="top">
              {error && <p>{error}</p>}
              <DisplayIf cond={permissions.editStreams}>
                <DeleteTableModal
                  tableName={tableName}
                  onConfirm={() => deleteTable(tableName)}
                />
              </DisplayIf>
            </Table.Cell>
          )}
          {/* delete table button only needed for first row */}
        </Table.Row>
      )),

      permissions.editStreams ? (
        <AddNewColumn
          tableName={tableName}
          key={tableName + ":newcol"}
          addNewCol={addNewCol}
          columnNameSet={columnNameSet}
        />
      ) : (
        <Table.Row></Table.Row>
      ),
    ];
  };

  if (errorOccurred) {
    return <ErrorMessage marginTop="270px" errorMessage />;
  }

  if (loading) {
    return (
      <LoadingAnimation
        loaderContainerHeight="65vh"
        fontSize="1.5rem"
        loadingText="Loading streams"
      />
    );
  }

  return (
    <Layout
      buttons={
        <>
          <DisplayIf cond={permissions.editStreams}>
            <CreateStreamModal
              onClose={resetTable}
              sourceStreams={tables}
              theme={theme}
            />
          </DisplayIf>
          {/*
                <Button primary floated="right" icon labelPosition='left' as="a" href={streamProtobuff}>
                  <Icon name='download' />
                  Download proto!
                </Button>
            */}
        </>
      }
    >
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Stream Name</Table.HeaderCell>
            <Table.HeaderCell>Column Name</Table.HeaderCell>
            <Table.HeaderCell>Column Type</Table.HeaderCell>

            <DisplayIf cond={permissions.editStreams}>
              <Table.HeaderCell>Column Ops</Table.HeaderCell>
            </DisplayIf>

            <DisplayIf cond={permissions.editStreams}>
              <Table.HeaderCell>Stream Ops</Table.HeaderCell>
            </DisplayIf>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {tables.length !== 0 ? (
            tables.map((table) => {
              return ActiveTable(table);
            })
          ) : (
            <Table.Row>
              <Table.Cell colspan={`${permissions.editStreams ? "5" : "3"}`}>
                <ErrorMessage marginTop="30px" message={"No Streams Found!"} />
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Layout>
  );
}
