import { MicelioStatsPanelMetaData } from "./PanelDef";
import React from "react";

import { PanelEditComponent, PartialMetaData } from "../PanelDef";
import {
  EditMetaRoot,
  EditMetaRow,
  EditPanelFormContainer,
  EditAnimatedMetaInput,
  EditAnimatedMetaDropdown,
} from "../util";
import { Tab } from "semantic-ui-react";
import { capitalizeFirstLetter } from "../../../util";

type MiceloStatsPanelState = {
  subType: string;
};

const subTypes = [
  "total_runtime",
  "total_idle_time",
  "total_pause_time",
  "total_distance",
  "daily_average_distance",
  "monthly_average_distance",
  "bussiest_hour_kms",
  "bussiest_hour_mins",
  "bussiest_hour_vehicles",
  "most_idle_hour_mins",
  "most_idle_hour_kms",
  "most_idle_hour_vehicles",
  "bussiest_vehicle_kms",
  "bussiest_vehicle_mins",
  "most_idle_vehicle_kms",
  "most_idle_vehicle_mins",
  "max_pause_interval",
  "total_vehicles",
  "fleet_asset_utilization",
  "fleet_utilization",
];

const subTypeOptions = subTypes.map((st) => {
  return {
    key: st,
    value: st,
    text: capitalizeFirstLetter(st),
  };
});

export class EditMicelioStatsPanelMeta extends PanelEditComponent<
  MicelioStatsPanelMetaData,
  MiceloStatsPanelState
> {
  titleRef = React.createRef<HTMLInputElement>();
  descriptionRef = React.createRef<HTMLInputElement>();
  deviceDashboardIdRef = React.createRef<HTMLInputElement>();

  state = {
    subType: subTypeOptions[0].value,
  };

  constructor(props) {
    super(props);

    this.state = {
      subType: props.panelMeta.subType,
    };
  }

  getPanelMeta(type): PartialMetaData<MicelioStatsPanelMetaData> {
    const meta = new MicelioStatsPanelMetaData();

    meta.id = this.props.panelMeta.id;
    meta.title = this.titleRef.current?.value || "";
    meta.description = this.descriptionRef.current?.value || "";
    meta.subType = this.state.subType;
    meta.deviceDashboardId = this.deviceDashboardIdRef.current?.value || "";

    return {
      meta: meta,
      complete: true,
    };
  }

  setSubType(_event, data) {
    this.setState({
      subType: data.value,
    });
  }

  render() {
    const description = this.props.panelMeta.description;
    const deviceDashboardId = this.props.panelMeta.deviceDashboardId || "";

    const panes = [
      {
        menuItem: "General",
        pane: (
          <Tab.Pane key={"general"}>
            <EditPanelFormContainer>
              <div style={{ width: "100%", marginTop: "16px" }} />
              <EditMetaRow>
                <div style={{ width: "48%" }}>
                  <EditAnimatedMetaInput
                    defaultRef={this.titleRef}
                    defaultValue={this.props.panelMeta.title}
                    label="Title"
                  />
                </div>
                <div style={{ width: "48%" }}>
                  <EditAnimatedMetaInput
                    defaultRef={this.descriptionRef}
                    defaultValue={description}
                    label="Description"
                  />
                </div>
              </EditMetaRow>

              <EditMetaRow>
                <div style={{ width: "50%", marginTop: "10px" }}>
                  <EditAnimatedMetaDropdown
                    placeholder="Choose Type"
                    text={capitalizeFirstLetter(this.state.subType)}
                    fluid
                    search
                    selection
                    options={subTypeOptions}
                    onChange={this.setSubType.bind(this)}
                    defaultValue={this.state.subType}
                    elementID={`tableLastValue`}
                  />
                </div>
              </EditMetaRow>

              <EditMetaRow>
                <div style={{ width: "48%" }}>
                  <EditAnimatedMetaInput
                    defaultRef={this.deviceDashboardIdRef}
                    defaultValue={deviceDashboardId}
                    label="Device dashboard ID"
                  />
                </div>
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <EditMetaRoot>
        <Tab menu={{}} panes={panes} renderActiveOnly={false} />
      </EditMetaRoot>
    );
  }
}
