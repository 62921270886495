import React, { useCallback, useEffect, useRef, useState } from "react";
import LoadingAnimation from "../../../common/Loader";
import styled from "styled-components";
import ActionButton from "./ActionButton";
import { PhaseData } from "./new-action/NewAction"; //StyledNonBoldHeader
// import CheckCircleSVG from "../../../common/CheckCircleSVG";
// import EmergencyWarningSVG from "../../../common/EmergencyWarningSVG";
// import ClockLoaderSVG from "../../../common/ClockLoaderSVG";
// import DottedCircleSVG from "../../../common/DottedCircleSVG";
import BarChart from "../LiveBarChart";
import ActionV3Legend from "./ActionV3Legend";
// import CarouselArrow from "./CarouselArrow";
import RadialChart from "./RadialChart";
import ActionsHistogram from "./ActionsHistogram";
// import SelectedFilter from "./SelectedFilter";
// import PublishedWithChangesSVG from "../../../common/PublishedWithChangesSVG";
// import ActionFilterDropdown from "./ActionFilterDropdown";
import { StyledHeader } from "./SelectableItem";
import LiveActionsList from "./LiveActionsList";
import { MenuItem, Popup } from "semantic-ui-react";
import { SelectDevicesPerPage } from "../../DeviceMangaement/Devices/Devices";
import { validateWholeNumber } from "../../../../util";
import ActionsMoreInfoCard from "./ActionsInfoCard";
import DeviceV3Overview from "./DeviceV3Overview";
import {
  ActionStatusResponse,
  ActionStatusType,
  devicesPerPageOptions,
  fetchAction,
  fetchAllActionStatus,
  fetchAllUsers,
} from "../../../../BytebeamClient";
import DeviceListView from "./DeviceListView";
import {
  RouteComponentProps,
  useHistory,
  useLocation,
  useRouteMatch,
  withRouter,
} from "react-router-dom";
import {
  StyledCardSearchPageInput,
  StyledPagination,
  StyledSecondaryDevicePerPageWidget,
} from "../../../common/commonStyledComps";
import { ErrorMessage } from "../../../common/ErrorMessage";
import Toggle from "../../../common/Toggle";
// import DonutChart from "../DonutChart";

const _ = require("lodash");

interface LiveActionsProps extends RouteComponentProps {
  doParamsExist: boolean;
  setDoParamsExist: (arg0: boolean) => void;
  setActionIDParam: (arg0: any) => void;
  setActiveItem: (arg: string) => void;
}

const MoreInfoPopUp = styled(Popup)`
  border: ${({ theme }) =>
    theme.colors["live-actions-more-info-popup-border"]} !important;

  &::before {
    border-top: ${({ theme }) =>
      theme.colors["live-actions-more-info-popup-border"]} !important;
    border-left: ${({ theme }) =>
      theme.colors["live-actions-more-info-popup-border"]} !important;
  }
`;

export const CardContainer = styled.div<{ marginBottom?: string }>`
  border-radius: 6px;
  width: 100%;
  padding: 24px 36px;
  background: ${({ theme }) =>
    theme.colors["action-card-container-background"]};
  border: ${({ theme }) => theme.colors["container-border"]};
  box-shadow: ${({ theme }) => theme.colors["container-box_shadow"]};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 0)};
`;

const NewActionContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 25px;
`;

const NewActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const PhaseButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LiveActionsButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const ActionStatsContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  transition: all 300ms ease-in-out;
`;

const RadialChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 275px;
  padding: 1.5rem 2rem 1.5rem 2rem;
  border: 1px solid ${(props) => props.theme.colors["add-button-border-color"]};
  border-radius: 5px;
  transition: all 300ms ease-in-out;
  margin-right: 1rem;
`;

const HistogramContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 265px;
  margin-left: 1rem;
  padding: 1rem 2rem 1.5rem 2rem;
  border: 1px solid ${(props) => props.theme.colors["add-button-border-color"]};
  border-radius: 5px;
  transition: all 300ms ease-in-out;
`;

const ChartContainer = styled.div`
  display: flex;
  width: 100%;
  flex-grow: 1;
  align-items: center;
  justify-content: space-evenly; // This will add space between the child elements
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FullLengthFlexContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  flex-grow: 1;
  transition: transform 0.5s ease-in-out;
`;

function LiveActions(props: LiveActionsProps) {
  // const currentDateTime = new Date();
  const [mainLoading, setMainLoading] = useState<boolean>(true);
  const [selectedItem, setSelectedItem] = useState<string>("all");
  const [selectedPhase, setSelectedPhase] = useState<string>("all");
  // const [isHistogramVisible, setIsHistogramVisible] = useState<boolean>(false);
  // const [showFilterDropdown, setShowFilterDropdown] = useState<boolean>(false);

  const history = useHistory();
  const location = useLocation();
  const matchedPath = useRouteMatch("/projects/:tenant/actionsv3");
  const searchParams = new URLSearchParams(location.search);

  const [actionIDParam, setActionIDParam] = useState<any>(
    searchParams.get("action_id")
  );
  const [deviceIDParam, setDeviceIDParam] = useState<any>(
    searchParams.get("device_id")
  );
  const [showIncompleteActions, setShowIncompleteActions] =
    useState<boolean>(false);

  // Flag state to track the operations like pagination and actions per page change
  const operationInProgressRef = useRef(false);
  const controllerRef = useRef(new AbortController());
  const recreateAbortController = () => {
    controllerRef.current?.abort?.(); // Abort any ongoing operation
    controllerRef.current = new AbortController(); // Create a new controller for the next operation
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    if (searchParams.get("action_id")) {
      setActionIDParam(searchParams.get("action_id"));
      props.setActionIDParam(searchParams.get("action_id"));
    } else {
      setActionIDParam(null);
      props.setActionIDParam(searchParams.get("action_id"));
    }

    if (searchParams.get("device_id"))
      setDeviceIDParam(searchParams.get("device_id"));
    else setDeviceIDParam(null);

    if (searchParams.get("action_id") && searchParams.get("device_id"))
      props.setDoParamsExist(true);
    else props.setDoParamsExist(false);

    if (window.location.search) {
      const searchParams = new URLSearchParams(location.search);

      if (
        searchParams.get("action_id") &&
        searchParams.get("device_id") &&
        window.location.search.includes("action_id") &&
        !window.location.search.includes("device_id")
      ) {
        props.setDoParamsExist(false);
        setDeviceIDParam(null);
      }
    }
  }, [window.location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  // -------------------- Action data fetching logic start ---------------------- //

  const [filteredData, setFilteredData] = useState(Array<ActionStatusType>());
  const [users, setUsers] = useState<{ name: string; email: string }[]>([]);
  const [totalActionsForPagination, setTotalActionsForPagination] =
    useState<number>(0);

  const [actionsPageNumber, setActionsPageNumber] = useState(1);
  const [actionsPageLimit, setActionsPageLimit] = useState(0);

  const [inputPageNumber, setInputPageNumber] = useState(0);

  const fetchAllOfTheUsers = async () => {
    const res = await fetchAllUsers();
    let userArray: { name: string; email: string }[] = [];
    Object.values(res.result).forEach((user) =>
      userArray.push({ name: user.name, email: user.email })
    );
    setUsers(userArray);
  };

  const handlePaginationInputChange = (event) => {
    const newValue = event.target.value;

    setInputPageNumber(newValue);
  };

  const getInputActivePage = (newValue: number) => {
    let pageNumber =
      newValue > 0
        ? newValue > Math.ceil(totalActionsForPagination / actionsPageLimit)
          ? Math.ceil(totalActionsForPagination / actionsPageLimit)
          : newValue
        : 1;

    return pageNumber;
  };

  const handlePaginationInputKeyDown = async (event) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      // If the pressed key is "Enter", trigger the function for changing active page
      if (validateWholeNumber(inputPageNumber?.toString())) {
        await onPaginationChange(event, {
          activePage: getInputActivePage(inputPageNumber),
        });
        setInputPageNumber(0);
      } else {
        window.toastr.error("Please enter whole number for jump to page.");
      }
    }
  };

  const onPaginationChange = useCallback(
    async (e, { activePage }) => {
      setMainLoading(true);
      operationInProgressRef.current = true; // Setting the flag to indicate pagination operation is in progress

      try {
        setActionsPageNumber(activePage);

        recreateAbortController();
        await fetchPaginatedActions(
          activePage,
          actionsPageLimit,
          controllerRef.current.signal
        );
      } catch (e) {
        window.toastr.error("Failed to change page");
        console.error("Error in changeDeviceStatus: ", e);
      } finally {
        setMainLoading(false);
        operationInProgressRef.current = false; // Reset flag when operation is complete
      }
    },
    [actionsPageLimit, filteredData] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const fetchPaginatedActions = async (
    pageNumber: number,
    pageLimit: number,
    signal: AbortSignal
  ) => {
    try {
      const res: ActionStatusResponse = await fetchAllActionStatus(
        pageNumber,
        pageLimit,
        showIncompleteActions,
        signal
      );
      setTotalActionsForPagination(res.count);
      let sortedActionList = [];

      // If show incomplete actions is true, need not to order the actions by created_at date
      sortedActionList = showIncompleteActions
        ? res.actions
        : _.orderBy(res.actions, "created_at", "desc");
      setFilteredData(sortedActionList);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchAllActions = async (count, signal) => {
    try {
      await fetchPaginatedActions(actionsPageNumber, count ?? 10, signal);
    } catch (e) {
      console.log(e);
    } finally {
      if (!operationInProgressRef.current) setMainLoading(false); // To Avoid setting main loading to false when pagination call cancel this call
    }
  };

  const changeActionsPerPage = useCallback(
    async (e, data) => {
      operationInProgressRef.current = true; // Setting the flag to indicate device per page change operation is in progress

      try {
        setMainLoading(true);
        setActionsPageLimit(data.value);
        setActionsPageNumber(1);

        window.localStorage.setItem("actionsPerPage", data.value);

        recreateAbortController();
        await fetchAllActions(data.value, controllerRef.current?.signal);
      } catch (e) {
        window.toastr.error("Failed to change number of devices per page");
        console.error("Error in changeDeviceStatus: ", e);
      } finally {
        operationInProgressRef.current = false; // Resetting flag when operation is complete
      }
    },
    [actionsPageLimit, actionsPageNumber] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    document.title = "Live Actions | Bytebeam";
    const actionsCountPerPage: number = parseInt(
      window.localStorage.getItem("actionsPerPage") ?? "10"
    );
    setActionsPageLimit(actionsCountPerPage);

    let actionsCount: number =
      actionsPageLimit !== 0 ? actionsPageLimit : actionsCountPerPage;

    let isMounted: boolean = true;
    let timeoutId: NodeJS.Timeout | null = null;

    const fetchActions = async () => {
      if (!isMounted || actionIDParam !== null || deviceIDParam !== null)
        return;
      try {
        if (!operationInProgressRef.current) {
          await fetchAllActions(actionsCount, controllerRef.current?.signal);
        }
      } catch (error) {
        console.log(error);
      } finally {
        if (isMounted) {
          timeoutId = setTimeout(fetchActions, 2 * 1000);
        }
      }
    };

    fetchActions();

    return () => {
      isMounted = false;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    actionsPageNumber,
    actionsPageLimit,
    showIncompleteActions,
    actionIDParam,
    deviceIDParam,
  ]);

  useEffect(() => {
    fetchAllOfTheUsers();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // -------------------- Action data fetching logic ends ---------------------- //

  // ------------------------------------ Action Status START ------------------------------------ //

  const [selectedAction, setSelectedAction] = useState<ActionStatusType>(
    filteredData[0]
  );

  const [, setNumTotal] = useState<number>(0);
  const [, setQueued] = useState<number>(0);
  const [, setInitiated] = useState<number>(0);
  const [, setCompleted] = useState<number>(0);
  const [, setFailed] = useState<number>(0);
  const [, setInProgress] = useState<number>(0);
  const [phasesDistributionData, setPhasesDistributionData] = useState<any>({}); // eslint-disable-line @typescript-eslint/no-unused-vars

  const fetchActionData = () => {
    fetchAction(Number(actionIDParam))
      .then((data) => {
        setSelectedAction(data);
        setMainLoading(false);
      })
      .catch((e) => console.log(e));
  };

  const dependencyArray = JSON.stringify(
    filteredData.map((action) => action?.action_id).sort((a, b) => b - a)
  );
  useEffect(() => {
    if (actionIDParam) {
      fetchActionData();
    } else {
      setSelectedAction(filteredData[0]);
    }
  }, [dependencyArray]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setInitiated(0);
    setQueued(0);
    setInProgress(0);
    setCompleted(0);
    setFailed(0);
    const statuses = selectedAction?.statuses ?? {};

    if (selectedAction) {
      const numTotal = Object.values(statuses ?? {}).reduce((a, b) => a + b, 0);
      const { Queued, Initiated, Completed, Scheduled, Failed, ...remaining } =
        statuses;

      setNumTotal(numTotal);
      setCompleted(Completed ?? 0);
      setQueued(Queued ?? 0);
      setInitiated(Initiated ?? 0);
      setFailed(Failed ?? 0);
      let totalInProgressDevices = 0;
      Object.keys(remaining).forEach((status) => {
        totalInProgressDevices = totalInProgressDevices + remaining[status];
      });

      // Set Phase level distribution also here
      if (selectedAction?.schedule?.phases?.length > 0) {
        setPhasesData(selectedAction?.schedule?.phases);
        let updatedPhasesDistributionData = {
          all: {
            Queued: Queued ?? 0,
            Initiated: Initiated ?? 0,
            InProgress: totalInProgressDevices ?? 0,
            Completed: Completed ?? 0,
            Failed: Failed ?? 0,
            Scheduled: Scheduled ?? 0,
            Total: numTotal,
          },
        };
        for (let i = 0; i < selectedAction?.schedule?.phases?.length; i++) {
          if (
            selectedAction.statuses_phased &&
            selectedAction.statuses_phased[i + ""]
          ) {
            let phaseStatusData = selectedAction.statuses_phased[i + ""];
            let phaseData = selectedAction?.schedule?.phases[i];
            let {
              Queued,
              Initiated,
              Completed,
              Scheduled,
              Failed,
              ...remaining
            } = phaseStatusData;
            const numTotal: any = Object.values(phaseStatusData).reduce(
              (a: any, b: any) => a + b
            );
            let inProgressDevices = 0;
            Object.keys(remaining).forEach((status) => {
              inProgressDevices = inProgressDevices + remaining[status];
            });
            let key = phaseData.name.toString();
            updatedPhasesDistributionData[key] = {
              Queued: Queued ?? 0,
              Initiated: Initiated ?? 0,
              InProgress: inProgressDevices ?? 0,
              Completed: Completed ?? 0,
              Scheduled: Scheduled ?? 0,
              Failed: Failed ?? 0,
              Total: numTotal,
            };
          }
        }
        setPhasesDistributionData(updatedPhasesDistributionData);
      } else {
        // Setting Only All in phase distribution
        setPhasesData([]);
        let updatedPhasesDistributionData = {
          all: {
            Queued: Queued ?? 0,
            Initiated: Initiated ?? 0,
            InProgress: totalInProgressDevices ?? 0,
            Completed: Completed ?? 0,
            Scheduled: Scheduled ?? 0,
            Failed: Failed ?? 0,
            Total: numTotal,
          },
        };
        setPhasesDistributionData(updatedPhasesDistributionData);
      }
    }
    // Here you can set selected phase as All and then set the data for all phases. and pass active phase as prop to the chart
  }, [selectedAction]); // eslint-disable-line react-hooks/exhaustive-deps

  // ------------------------------------ Action-status END ------------------------------------ //

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [phasesData, setPhasesData] = useState<PhaseData[]>([
    // {
    //   id: 1, // its not there in the api response
    //   name: "Phase I",
    //   status: "Completed",
    //   icon: <CheckCircleSVG height="15px" id="check-circle" />,
    //   timestamp: new Date(currentDateTime.getTime() + 5 * 60 * 1000),
    //   info: {
    //     type: "fixed-list", //filter-fraction-lazy , filter-fraction
    //     device_ids: [],
    //     filter: {},
    //     fraction: 0,
    //   },
    //   // deviceCount: 0,
    //   // triggerTime: "Scheduled at 03/12/24, 13:10:00", // not using
    //   // autoretry: false,
    //   // timeoutAfter: null,
    // },
  ]);

  const setActiveButton = (name: string) => {
    setSelectedItem(name);
  };

  const setActivePhase = (name: string) => {
    setSelectedPhase(name);
  };

  const getPhaseScheduleTimestamp = () => {
    let phaseIndex = selectedAction?.schedule?.phases.findIndex(
      (phase) => phase.name === selectedPhase
    );

    if (phaseIndex >= 0) {
      let returnTimeStamp =
        selectedAction?.schedule?.phases[phaseIndex].trigger_on.timestamp;
      return returnTimeStamp;
    }
  };

  const sections = ["Status Distribution", "Latency Histogram"];
  const [activeSectionIndex] = useState(0);

  // const showHistogram = () => {
  //   setIsHistogramVisible(true);
  //   setActiveSectionIndex((prevIndex) => (prevIndex + 1) % sections.length);
  // };

  // const hideHistogram = () => {
  //   setIsHistogramVisible(false);
  //   setActiveSectionIndex(
  //     (prevIndex) => (prevIndex - 1 + sections.length) % sections.length
  //   );
  // };

  useEffect(() => {
    props.setActiveItem("live actions");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (mainLoading && !selectedAction && deviceIDParam && actionIDParam)
    return (
      <LoadingAnimation
        loadingText="Loading Live Actions"
        fontSize="20px"
        loaderContainerHeight="70vh"
      />
    );
  else if (
    !mainLoading &&
    !(deviceIDParam || actionIDParam) &&
    filteredData?.length === 0
  ) {
    return (
      <div
        style={{
          height: "65vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ErrorMessage
          marginTop="30px"
          message="Please trigger an Action to view its progress here."
        />
      </div>
    );
  } else {
    return (
      <>
        {deviceIDParam && actionIDParam ? (
          <NewActionContainer>
            <DeviceV3Overview
              deviceIDParam={deviceIDParam}
              actionIDParam={actionIDParam}
            />
          </NewActionContainer>
        ) : selectedAction?.action_id ? (
          <>
            <NewActionContainer>
              <NewActionWrapper>
                {!deviceIDParam && actionIDParam && (
                  <ActionButton
                    marginBottom="30px"
                    marginLeft="0px"
                    label="Back"
                    icon="left chevron"
                    onClick={() =>
                      history.push(`${matchedPath?.url}/live-actions`)
                    }
                  />
                )}
                {/* !actionIDParam &&  */}
                {selectedAction.action_id && (
                  <CardContainer>
                    <StyledHeader
                      as="h2"
                      style={{ marginTop: "0px", marginBottom: "20px" }}
                    >
                      Action Summary{" "}
                      {`(#${selectedAction?.action_id}`
                        ? `(#${selectedAction?.action_id})`
                        : ""}
                    </StyledHeader>
                    <LiveActionsButtonWrapper>
                      <PhaseButtonsWrapper>
                        {phasesData.length > 0 && (
                          <ActionButton
                            onClick={() => {
                              setActivePhase("all");
                            }}
                            label={"All Phases"}
                            selected={selectedPhase === "all"}
                            marginLeft={"10px"}
                            padding={"0.5rem 1rem"}
                          />
                        )}
                        {phasesData.map((phase) => (
                          <ActionButton
                            key={phase.name}
                            onClick={() => {
                              setActivePhase(phase.name);
                            }}
                            label={phase.name}
                            icon={phase.icon}
                            selected={selectedPhase === phase.name}
                            marginLeft={"10px"}
                            padding={"0.5rem 1rem"}
                          />
                        ))}
                      </PhaseButtonsWrapper>

                      <PhaseButtonsWrapper>
                        <MoreInfoPopUp
                          content={
                            <ActionsMoreInfoCard
                              actionType={selectedAction?.type}
                              actionName={undefined}
                              actionID={selectedAction?.action_id}
                              createdAt={selectedAction?.created_at}
                              scheduledAt={getPhaseScheduleTimestamp()}
                            />
                          }
                          position="bottom right"
                          inverted
                          trigger={
                            <ActionButton
                              onClick={() => {}}
                              label={"More Info"}
                              icon={"info circle"}
                              selected={false}
                              borderRadius={"5px"}
                              marginLeft={"10px"}
                              padding={"0.5rem 1rem"}
                            />
                          }
                        />
                        {!actionIDParam && (
                          <ActionButton
                            onClick={() => {
                              history.push(
                                `${matchedPath?.url}?action_id=${selectedAction?.action_id}`
                              );
                            }}
                            label={"View Details"}
                            icon={"eye"}
                            selected={false}
                            borderRadius={"5px"}
                            marginLeft={"10px"}
                            padding={"0.5rem 1rem"}
                          />
                        )}
                      </PhaseButtonsWrapper>
                    </LiveActionsButtonWrapper>

                    <FullLengthFlexContainer>
                      {/* {isHistogramVisible ? (
                    <CarouselArrow
                      onClick={() => hideHistogram()}
                      arrowDir="left"
                    />
                  ) : (
                    <CarouselArrow
                      onClick={() => showHistogram()}
                      arrowDir="right"
                    />
                  )} */}
                      <div
                        style={{
                          display: "flex",
                          position: "relative",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <ActionStatsContainer id="action-radial-charts-container">
                          {sections.map((section, index) => (
                            <React.Fragment key={index}>
                              {section === "Status Distribution" && (
                                <>
                                  <RadialChartContainer
                                    key={index}
                                    style={{
                                      display:
                                        index === activeSectionIndex
                                          ? "flex"
                                          : "none",
                                    }}
                                  >
                                    <StyledHeader
                                      as="h3"
                                      style={{
                                        marginTop: "0px",
                                        marginBottom: "20px",
                                      }}
                                    >
                                      Status Distribution
                                    </StyledHeader>
                                    {phasesDistributionData?.[selectedPhase]?.[
                                      "Total"
                                    ] ? (
                                      <ChartContainer>
                                        <BarChart
                                          phasedData={phasesDistributionData}
                                          selectedPhase={selectedPhase}
                                          height={180}
                                          width={350}
                                          textInfo={true}
                                        />
                                        {/* <DonutChart
                                    phasedData={phasesDistributionData}
                                    selectedPhase={selectedPhase}
                                    height={180}
                                    width={350}
                                    textInfo={true}
                                  /> */}
                                        <ActionV3Legend
                                          onClick={(label: string) => {
                                            // setActiveIndex(-1); // close the accordion when filters are triggered enabled
                                            // filterData(label);
                                          }}
                                          filterStatus={false}
                                          liStyles={{
                                            paddingBottom: "0.5rem",
                                          }}
                                        />
                                      </ChartContainer>
                                    ) : (
                                      <ChartContainer>
                                        <p>
                                          <b>No devices</b>
                                        </p>
                                      </ChartContainer>
                                    )}
                                  </RadialChartContainer>

                                  <RadialChartContainer
                                    style={{
                                      // display:
                                      //   index === activeSectionIndex
                                      //     ? "flex"
                                      //     : "none",
                                      display: "none",
                                    }}
                                  >
                                    {" "}
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <StyledHeader
                                        as="h3"
                                        style={{ marginTop: "0px" }}
                                      >
                                        Failure Cause Distribution
                                      </StyledHeader>
                                      <FlexContainer>
                                        <ActionButton
                                          onClick={() => {
                                            setActiveButton("all");
                                          }}
                                          label={"By Stage"}
                                          selected={selectedItem === "all"}
                                          marginLeft={"0px"}
                                          padding={"0.5rem 1rem"}
                                        />
                                        <ActionButton
                                          onClick={() => {
                                            setActiveButton("all");
                                          }}
                                          label={"By Error Message"}
                                          selected={selectedItem === "all"}
                                          marginLeft={"10px"}
                                          padding={"0.5rem 1rem"}
                                        />
                                      </FlexContainer>
                                    </div>
                                    <ChartContainer>
                                      <RadialChart
                                        setTotalCount={1474}
                                        setESPCount={103}
                                        setDownloadCount={477}
                                        setNetworkCount={894}
                                        height={180}
                                        width={250}
                                        textInfo={true}
                                      />
                                    </ChartContainer>
                                  </RadialChartContainer>
                                </>
                              )}
                              {section === "Latency Histogram" && (
                                <HistogramContainer
                                  style={{
                                    // display:
                                    //   index === activeSectionIndex
                                    //     ? "flex"
                                    //     : "none",
                                    display: "none",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <StyledHeader
                                      as="h3"
                                      style={{ marginTop: "0px" }}
                                    >
                                      Latency Histogram
                                    </StyledHeader>
                                    <FlexContainer>
                                      {/* dont know if these are predefined or whatever so repeating them for now, can be put in an array and mapped */}
                                      <ActionButton
                                        onClick={() => {
                                          setActiveButton("all");
                                        }}
                                        label={"Queued"}
                                        selected={selectedItem === "all"}
                                        marginLeft={"0px"}
                                        padding={"0.5rem 1rem"}
                                      />
                                      <ActionButton
                                        onClick={() => {
                                          setActiveButton("all");
                                        }}
                                        label={"Initiated"}
                                        selected={selectedItem === "all"}
                                        marginLeft={"10px"}
                                        padding={"0.5rem 1rem"}
                                      />
                                      <ActionButton
                                        onClick={() => {
                                          setActiveButton("all");
                                        }}
                                        label={"Uploading"}
                                        selected={selectedItem === "all"}
                                        marginLeft={"10px"}
                                        padding={"0.5rem 1rem"}
                                      />
                                      <ActionButton
                                        onClick={() => {
                                          setActiveButton("all");
                                        }}
                                        label={"Downloading"}
                                        selected={selectedItem === "all"}
                                        marginLeft={"10px"}
                                        padding={"0.5rem 1rem"}
                                      />
                                      <ActionButton
                                        onClick={() => {
                                          setActiveButton("all");
                                        }}
                                        label={"Installing"}
                                        selected={selectedItem === "all"}
                                        marginLeft={"10px"}
                                        padding={"0.5rem 1rem"}
                                      />
                                    </FlexContainer>
                                  </div>
                                  <ChartContainer>
                                    <ActionsHistogram
                                      setTotalCount={50}
                                      height={180}
                                      textInfo={true}
                                    />
                                  </ChartContainer>
                                </HistogramContainer>
                              )}
                            </React.Fragment>
                          ))}
                        </ActionStatsContainer>
                      </div>
                    </FullLengthFlexContainer>
                  </CardContainer>
                )}
              </NewActionWrapper>
            </NewActionContainer>

            {actionIDParam ? (
              <DeviceListView
                actionID={actionIDParam}
                setSelectedAction={setSelectedAction}
                phasedData={phasesDistributionData}
                selectedPhase={selectedPhase}
              />
            ) : (
              <NewActionContainer>
                <NewActionWrapper>
                  {filteredData?.length !== 0 ? (
                    <CardContainer>
                      <StyledHeader as="h2">Actions List</StyledHeader>
                      <FlexContainer
                        style={{
                          marginBottom: "30px",
                          justifyContent: "flex-end",
                        }}
                      >
                        {filteredData?.length > 0 && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "16px",
                            }}
                          >
                            <div>Sort by Incomplete Actions</div>
                            <div>
                              <Toggle
                                id="sortByIncompleteActions"
                                checked={showIncompleteActions}
                                onChange={() => {
                                  setShowIncompleteActions(
                                    !showIncompleteActions
                                  );
                                }}
                                style={{
                                  top: "4px",
                                }}
                              />
                            </div>
                          </div>
                        )}
                        {/* <StyledNonBoldHeader as="h3" style={{ marginTop: "5px" }}>
                        Filter By
                      </StyledNonBoldHeader>

                      <SelectedFilter
                        name="Failed"
                        icon={
                          <PublishedWithChangesSVG
                            height="20px"
                            style={{ marginRight: "10px", marginTop: "5px" }}
                          />
                        }
                        onClick={() => {}}
                      />

                      <div style={{ position: "relative" }}>
                        <ActionButton
                          onClick={() => {
                            setShowFilterDropdown(true);
                          }}
                          label={"Add"}
                          selected={selectedItem === "not decided"}
                          marginLeft={"20px"}
                          padding={"0.5rem 1rem"}
                          icon={"plus"}
                        />

                        {showFilterDropdown && (
                          <ActionFilterDropdown
                            dropdownOptions={[
                              {
                                id: 1,
                                name: "check",
                                icon: (
                                  <PublishedWithChangesSVG
                                    height="20px"
                                    style={{
                                      marginRight: "10px",
                                      marginTop: "5px",
                                    }}
                                  />
                                ),
                              },
                            ]}
                            setShowFilterDropdown={(value) =>
                              setShowFilterDropdown(value)
                            }
                            addFilter={() => {}}
                          />
                        )}
                      </div> */}
                      </FlexContainer>

                      <LiveActionsList
                        users={users}
                        action={filteredData}
                        selectedAction={selectedAction}
                        setSelectedAction={setSelectedAction}
                      />

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "nowrap",
                          gap: "16px",
                        }}
                      >
                        <StyledPagination
                          boundaryRange={0}
                          ellipsisItem={null}
                          siblingRange={2}
                          activePage={actionsPageNumber}
                          totalPages={Math.ceil(
                            totalActionsForPagination / actionsPageLimit
                          )}
                          onPageChange={onPaginationChange}
                        />

                        <StyledCardSearchPageInput
                          icon="search"
                          placeholder="Jump to page..."
                          name="activePage"
                          min={1}
                          onChange={handlePaginationInputChange}
                          onKeyDown={handlePaginationInputKeyDown}
                          type="number"
                          value={inputPageNumber ? inputPageNumber : ""}
                        />

                        <StyledSecondaryDevicePerPageWidget>
                          <MenuItem>Actions per page</MenuItem>
                          <MenuItem style={{ padding: "0px" }}>
                            <SelectDevicesPerPage
                              compact
                              selection
                              options={devicesPerPageOptions}
                              value={actionsPageLimit}
                              onChange={changeActionsPerPage}
                            />
                          </MenuItem>
                        </StyledSecondaryDevicePerPageWidget>
                      </div>
                    </CardContainer>
                  ) : (
                    <div
                      style={{
                        height: "65vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ErrorMessage
                        marginTop="30px"
                        message="Please trigger an Action to view its progress here."
                      />
                    </div>
                  )}
                </NewActionWrapper>
              </NewActionContainer>
            )}
          </>
        ) : (
          <LoadingAnimation
            loadingText="Loading Live Actions"
            fontSize="20px"
            loaderContainerHeight="70vh"
          />
        )}
      </>
    );
  }
}

export default withRouter(LiveActions);
