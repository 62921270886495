import { Responsive, WidthProvider } from "react-grid-layout";

import { FetchParams } from "../../../BytebeamClient";

import { TimeRange } from "./Datetime/TimeRange";

import React from "react";

import { PanelContainer } from "./Panel/PanelContainer";

import { ReplayState } from "./DashboardHeader";
import { Settings } from "../../../util";
import {PanelDataMap, TableInfo} from "../../../BytebeamClient";
import { DashboardsInfo } from "./ViewDashboard";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export type GridLayout = {
  lg: ReactGridLayout.Layout[],
  xs: ReactGridLayout.Layout[]
}

type DashboardBodyProp = {
  panels: PanelDataMap;

  onTimeRangeChange: (range: TimeRange) => void;
  timeRange: TimeRange;

  gridLayouts: GridLayout;
  onGridLayoutChange: (currentLayout: ReactGridLayout.Layout[], allLayouts: ReactGridLayout.Layouts) => void;
  onBreakpointChange: (newBreakpoint: string, newCols: number) => void;
  onEditPanel(panelId: string);
  onClonePanel(panelId: string);
  onDeletePanel(panelId: string);
  onRefreshPanel(panelId: string);
  replayStep: number;
  replayTimestamp: number;
  replayState: ReplayState;
  editable: boolean;
  fetchParams: FetchParams;
  panelKeyPrefix: string;
  settings: Settings;
  dashboards: DashboardsInfo[]
  setHoverPoint: (val: any) => void;
  getHoverPoint:() => any;
  tables: TableInfo;
}

type DashboardBodyState = {
  // hoverPointX: any;
  // hover: boolean;
  fullPage: boolean;
  fullPagePanelId?: string;
}

export class DashboardBody extends React.Component<DashboardBodyProp, DashboardBodyState> {
  gridProps = {
    className: "layout",
    rowHeight: 40,
    // cols: { lg: 16, md: 14, sm: 12, xs: 14, xxs: 16 },
    cols: { lg: 16, xs: 1 },
    // breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }
    breakpoints: { lg: 768, xs: 0}
  };

  state = {
    fullPage: false,
    fullPagePanelId: "",
    // hoverPointX: null,
    // hover: false
  };

  onToggleFullPage(panelId: string) {
    const rootElement = document.getElementById('root');

    if(this.state.fullPage) {
      rootElement?.classList.remove("fullpage-mode");
    } else {
      rootElement?.classList.add("fullpage-mode"); 
    }

    this.setState({fullPage: !this.state.fullPage, fullPagePanelId: panelId})
  }

  // onHoverPanel(event: PlotMouseEvent) {
  //   const pointData = (event.points && event.points.length>0) ? event.points[0] : null;
  //   let exPointData = this.props.getHoverPoint();
  //   // find difference in second between exPointData?.x and pointData?.x
  //   // if difference is more than 1 second, then set hoverPointX
  //   const exTimeStamp = exPointData?.x ? new Date(exPointData?.x).getTime() : 0;
  //   const timeStamp = pointData?.x ? new Date(pointData?.x).getTime() : 0;
  //   const diff = Math.abs(exTimeStamp - timeStamp);

  //   if((exPointData?.x !== pointData?.x) && (diff > 1000)) {
  //     this.props.setHoverPoint(pointData);
  //     this.setState({hoverPointX: pointData, hover: true});
  //   }
  // }

  // onUnHoverPanel() {
  //   this.setState({hoverPointX: null, hover: false});
  // }

  generateDOM() {
    
    return this.props.gridLayouts.lg.map((l, _i) => {
      const panelId = l["i"];
      const panelData = this.props.panels[panelId];

      if(panelData) {
        if(!this.state.fullPage || this.state.fullPagePanelId === panelId) {
          return <div key={panelId}>
            <PanelContainer
              key={`${this.props.panelKeyPrefix}-${panelId}`}
              data={panelData.data}
              panelMeta={panelData.meta}
              panelDef={panelData.panelDef}
              dashboards={this.props.dashboards}
              loading={panelData.loading}
              error={panelData.error}
              timeRange={this.props.timeRange}
              onTimeRangeChange={this.props.onTimeRangeChange}
              onEdit={this.props.onEditPanel}
              onRefresh={this.props.onRefreshPanel}
              onDelete={this.props.onDeletePanel}
              replayStep={this.props.replayStep}
              replayTimestamp={this.props.replayTimestamp}
              replayState={this.props.replayState}
              onToggleFullPage={this.onToggleFullPage.bind(this)}
              fullPage={this.state.fullPage}
              editable={this.props.editable}
              fetchParams={this.props.fetchParams}
              settings={this.props.settings}
              tables={this.props.tables}
              // onHoverPanel={this.onHoverPanel.bind(this)}
              // onUnHoverPanel={this.onUnHoverPanel.bind(this)}
              onClone={this.props.onClonePanel}
              // hoverPointX={this.state.hoverPointX}
              // hover={this.state.hover}
            />
          </div>;
        }
      }

      return <></>
    });
  }

  render() {
    if(this.state.fullPage) {
      return this.generateDOM();
    }

    return (
      <ResponsiveReactGridLayout
        {...this.gridProps}
        layouts={this.props.gridLayouts}
        onLayoutChange={this.props.onGridLayoutChange}
        onBreakpointChange={this.props.onBreakpointChange}
        // WidthProvider option
        measureBeforeMount={false}
        compactType="vertical"
        preventCollision={false}
        draggableHandle='.bytebeam-panel-header'
        isDraggable={this.props.editable && this.props.replayState === ReplayState.ReplayStopped}
        isResizable={this.props.editable && this.props.replayState === ReplayState.ReplayStopped}
      >
        {this.generateDOM()}
      </ResponsiveReactGridLayout>
    );
  }
}
