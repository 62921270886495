import React from "react";
import RemoteShell from "../../DeviceMangaement/Devices/ActionModals/RemoteShell/RemoteShell";

type TerminalProps = {
  readonly deviceId: number;
};

function Terminal(props: TerminalProps) {
  const { deviceId } = props;

  return (
    <div>
      <RemoteShell deviceId={deviceId} />
    </div>
  );
}

export default Terminal;
