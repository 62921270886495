import React, { useEffect, useState } from "react";
import { Modal, Button, Icon } from "semantic-ui-react";
import { fetchDeviceConfigurationDetails } from "../../../../../BytebeamClient";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/theme-chaos";
import LoadingAnimation from "../../../../common/Loader";
import ThemeSchema from "../../../../../theme/schema";

type ShowConfigVersionProps = {
  configVersion: any;
  isOpen: boolean;
  close: (...args: any[]) => any;
  theme: string;
};

// Have to put back to streams page and time-range selector
export function ShowConfigVersionModal(props: ShowConfigVersionProps) {
  const { configVersion, isOpen, close, theme } = props; //streams
  const [config, setConfig] = useState<any>("No config data found");
  const [loading, setLoading] = useState<boolean>(false);

  // Fetching config data
  useEffect(() => {
    const fetchConfigData = async () => {
      setLoading(true);
      const res = await fetchDeviceConfigurationDetails(configVersion);
      if (res["config_json"]) setConfig(res["config_json"]);
      setLoading(false);
    };
    if (configVersion && isOpen) fetchConfigData();
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      closeOnEscape={true}
      closeOnDimmerClick={false}
      open={isOpen}
      size="small"
      className="dark"
      onClose={close}
    >
      <Modal.Header>Device Config</Modal.Header>
      {configVersion ? (
        <Modal.Content>
          {loading ? (
            <LoadingAnimation
              loadingText="Loading"
              loaderBorderSize="3px"
              loaderSize="30px"
              fontSize="14px"
            />
          ) : (
            <AceEditor
              height="200px"
              width="100%"
              mode="json"
              theme={ThemeSchema.data[theme ?? 'dark']?.colors["ace-editor-theme"]}
              name="custom-json"
              fontSize={14}
              value={JSON.stringify(config, null, 2)}
              showPrintMargin={false}
              showGutter={true}
              highlightActiveLine={true}
              setOptions={{
                readOnly: true,
                enableBasicAutocompletion: false,
                enableLiveAutocompletion: false,
                enableSnippets: false,
                showLineNumbers: false,
                tabSize: 2,
              }}
              style={{
                marginBottom: "16px",
                borderRadius: "4px",
                border: `${ThemeSchema.data[theme ?? "dark"]?.colors["ace-editor-border"]}`,
                boxShadow: `${ThemeSchema.data[theme ?? "dark"]?.colors["ace-editor-box-shadow"]}`,
              }}
            />
          )}
        </Modal.Content>
      ) : (
        <Modal.Content>
          <h5>No Config Version found</h5>
        </Modal.Content>
      )}
      <Modal.Actions>
        <Button
          secondary
          onClick={() => {
            close();
          }}
        >
          <Icon name="remove" /> Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
