import React from "react";

import {
  Modal,
  Button,
  Tab,
  Input,
  Dropdown,
  Icon,
  DropdownProps,
  DropdownDivider,
  Label,
  Radio,
  Divider,
  Segment,
  Popup,
} from "semantic-ui-react";

import { Permission, Role } from "../../../../util";
import styled from "styled-components";
import { capitalizeFirstLetter } from "../../util";
import { homepageOptions } from "./homePageOptions";

import {
  fetchDeviceFilterOptions,
  fetchAllActionTypes,
  fetchAllRoles,
  fetchAllMetadataKeys,
  fetchAllStreamsWithDetails,
  fetchAllDashboards,
  DashboardAPIResponse,
} from "../../../../BytebeamClient";
import { AddAllColText } from "../../../common/commonStyledComps";
const _ = require("lodash");

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
  align-items: flex-start;
`;

const BottomRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-self: flex-end;
  margin-top: auto;
  justify-content: flex-end;
`;

const PaneContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const EditRoleDropdownContainer = styled.div`
  max-width: 300px;
  flex-grow: 1;
  margin-right: 20px;
  display: inline-block;
`;

const BorderlessPane = styled(Segment)`
  border: none;
`;

export const ErrorMessage = styled.span`
  color: #f00;
  font-weight: 500;
  position: relative;
  top: -8px;
  left: 4px;
`;

const HeadText = styled.h4`
  font-weight: 800;
  font-size: 1.2em;
  margin-bottom: 0px;
  margin-top: 0px;
  margin-right: 10px;
`;

const HeaderWithPopup = ({ headerContent, popupContent }) => {
  return (
    <Row>
      <div style={{ display: "flex", alignItems: "center" }}>
        <HeadText>{headerContent}</HeadText>
        <Popup
          inverted
          trigger={
            <Icon name="question circle" style={{ marginBottom: "2px" }} />
          }
          content={popupContent}
          position="top center"
        />
      </div>
    </Row>
  );
};

export const EditRoleDropdown = (props: DropdownProps) => (
  <EditRoleDropdownContainer>
    <Dropdown {...props} />
  </EditRoleDropdownContainer>
);

function SettingsPane(props: React.PropsWithChildren<{ onNext?: () => void }>) {
  return (
    <PaneContainer>
      {props.children}

      {props.onNext !== undefined ? (
        <BottomRow>
          <Button primary onClick={props.onNext}>
            Next
          </Button>
        </BottomRow>
      ) : null}
    </PaneContainer>
  );
}

type RoleConfigProps = {
  name: string;
  homepage: any;
  showDeviceManagementTab: boolean;
  showDashboardsTab: boolean;
  showActionsTab: boolean;
  dashboards: DashboardAPIResponse[];
  roleNameError: { error: boolean; message: string };
};

type RoleConfigState = {
  showDeviceManagementTab: boolean;
  showDashboardsTab: boolean;
  showActionsTab: boolean;
  roleHomepage: any;
};

class GeneralConfig extends React.Component<RoleConfigProps, RoleConfigState> {
  roleNameRef = React.createRef<HTMLInputElement>();

  constructor(props) {
    super(props);

    this.state = {
      showDeviceManagementTab: this.props.showDeviceManagementTab,
      showDashboardsTab: this.props.showDashboardsTab,
      showActionsTab: this.props.showActionsTab,
      roleHomepage: this.props.homepage,
    };
  }

  getRoleName() {
    return this.roleNameRef.current?.value;
  }

  getHomepage() {
    return this.state.roleHomepage;
  }

  shouldShowDeviceManagmentTab() {
    return this.state.showDeviceManagementTab;
  }

  shouldShowDashboardsTab() {
    return this.state.showDashboardsTab;
  }

  shouldShowActionsTab() {
    return this.state.showActionsTab;
  }

  render() {
    const dashboardOptions = [
      ...homepageOptions,

      {
        text: "Dashboards",
        value: "Dashboards",
        content: <Divider />,
        disabled: true,
      },

      ...this.props.dashboards.map((db: DashboardAPIResponse) => {
        return {
          key: `${db.config.dashboardMeta.title}`,
          value: `/dashboards/${db.id}`,
          text: `Dashboard: ${db.config.dashboardMeta.title}`,
        };
      }),
    ];
    if (
      !this.state.showDashboardsTab &&
      !this.state.showDeviceManagementTab &&
      !this.state.showActionsTab
    ) {
      this.setState({
        showDeviceManagementTab: true,
      });
    }

    let homepageOptionsToShow = dashboardOptions;

    if (!this.state.showDashboardsTab) {
      // removing dashboard home page options
      homepageOptionsToShow = dashboardOptions.filter((i) => {
        if (i.text.includes("Dashboard")) {
          return false;
        } else {
          return true;
        }
      });
    }

    if (!this.state.showDeviceManagementTab) {
      // removing devices home page options
      homepageOptionsToShow = dashboardOptions.filter((i) => {
        if (
          i.text.includes("Device" || "Firmware" || "Action Status") ||
          i.text.includes("Firmware") ||
          i.text.includes("Action Status")
        ) {
          return false;
        } else {
          return true;
        }
      });
    }

    if (!this.state.showActionsTab) {
      homepageOptionsToShow = dashboardOptions.filter(
        (i) => !i.value.startsWith("/actions")
      );
    }

    return (
      <React.Fragment>
        <Row>
          <Input labelPosition="left" style={{ width: "350px" }}>
            <Label>Role Name</Label>
            <input defaultValue={this.props.name} ref={this.roleNameRef} />
          </Input>
        </Row>
        {this.props.roleNameError.error ? (
          <ErrorMessage id={"roleErrorSpan"}>
            {this.props.roleNameError.message}
          </ErrorMessage>
        ) : (
          ""
        )}

        {/* <Divider /> */}

        <Row>
          <label style={{ marginRight: "10px" }}>
            {" "}
            Show Device Management Tab{" "}
          </label>
          <Radio
            toggle
            checked={this.state.showDeviceManagementTab}
            onChange={(_event, data) => {
              this.setState({ showDeviceManagementTab: data.checked || false });
            }}
          />
        </Row>

        <Row>
          <label style={{ marginRight: "10px" }}> Show Dashboards Tab </label>
          <Radio
            toggle
            checked={this.state.showDashboardsTab}
            onChange={(_event, data) => {
              this.setState({ showDashboardsTab: data.checked || false });
            }}
          />
        </Row>

        <Row>
          <label style={{ marginRight: "10px" }}> Show Actions Tab </label>
          <Radio
            toggle
            checked={this.state.showActionsTab}
            onChange={(_event, data) => {
              this.setState({ showActionsTab: data.checked || false });
            }}
          />
        </Row>

        <Row>
          <Input labelPosition="left" style={{ width: "350px" }}>
            <Label>Home Page</Label>
            <Dropdown
              search
              selection
              fluid
              options={homepageOptionsToShow}
              value={this.state.roleHomepage}
              onChange={(_event, data) => {
                if (data.value) {
                  this.setState({ roleHomepage: data.value });
                }
              }}
            />
          </Input>
        </Row>
      </React.Fragment>
    );
  }
}

type DeviceFiltersProps = {
  filters: { [key: string]: string[] | "all" };
  filterOptions: { [key: string]: string[] };
};

type DeviceFilter = {
  key: string;
  values: string[] | "all";
};

type FilterKeyOption = {
  key: string;
  text: string;
  value: string;
};

type DeviceFiltersState = {
  filters: DeviceFilter[];
  filterKeyOptions: FilterKeyOption[];
};

class DeviceFilters extends React.Component<
  DeviceFiltersProps,
  DeviceFiltersState
> {
  constructor(props: DeviceFiltersProps) {
    super(props);
    this.state = {
      filters: Object.keys(props.filters).map((key) => {
        return {
          key: key,
          values: props.filters[key],
        };
      }),
      filterKeyOptions: Object.keys(this.props.filterOptions).map(
        (filterKey) => {
          return {
            key: filterKey,
            text: filterKey,
            value: filterKey,
          };
        }
      ),
    };
  }

  updateFilter(index: number, filter: DeviceFilter) {
    const deviceFilters = this.state.filters;

    if (filter.values === "all") {
      this.setState({
        filters: [
          ...deviceFilters.slice(0, index),
          filter,
          ...deviceFilters.slice(index + 1),
        ],
      });
    } else {
      const values =
        filter.values.length > 1
          ? filter.values.filter((value) => value !== "all")
          : filter.values;

      this.setState({
        filters: [
          ...deviceFilters.slice(0, index),
          {
            key: filter.key,
            values: values,
          },
          ...deviceFilters.slice(index + 1),
        ],
      });
    }
  }

  addFilter(filter) {
    this.setState({ filters: [...this.state.filters, filter] });
  }

  removeFilter(index) {
    const deviceFilters = this.state.filters;

    this.setState({
      filters: [
        ...deviceFilters.slice(0, index),
        ...deviceFilters.slice(index + 1),
      ],
    });
  }

  addFilterKeyOption(index: number) {
    // add element to the dropdown array again as field has been removed

    this.setState({
      filterKeyOptions: [
        {
          key: this.state.filters[index].key,
          text: this.state.filters[index].key,
          value: this.state.filters[index].key,
        },
        ...this.state.filterKeyOptions,
      ],
    });
  }

  removeFilterKeyOption(value: string) {
    // remove selected item from the dropdown array in order to
    // not let it turn up again in the next dropdowns

    this.setState({
      filterKeyOptions: this.state.filterKeyOptions.filter(
        (option) => option.text !== value
      ),
    });
  }

  updateFilterKeyOptions(index: number, data: any) {
    // here when selected dropdown filter is changed,
    // existing selection needs to be added back to
    // the array and the newly selected one needs
    // to be removed in order to not let it turn up again in the dropdowns

    let newFilterKeyOptions = this.state.filterKeyOptions;

    newFilterKeyOptions = this.state.filterKeyOptions.filter(
      (option) => option.text !== data.value
    );

    this.setState({
      filterKeyOptions: [
        {
          key: this.state.filters[index].key,
          text: this.state.filters[index].key,
          value: this.state.filters[index].key,
        },
        ...newFilterKeyOptions,
      ],
    });
  }

  getDeviceFilters() {
    const filters = {};

    this.state.filters.forEach(
      (filter) => (filters[filter.key] = filter.values)
    );

    return filters;
  }

  // for fetching dropdown options
  componentDidUpdate(prevProps: Readonly<DeviceFiltersProps>): void {
    if (prevProps.filterOptions !== this.props.filterOptions) {
      this.setState({
        filterKeyOptions: Object.keys(this.props.filterOptions).map(
          (filterKey) => {
            return {
              key: filterKey,
              text: filterKey,
              value: filterKey,
            };
          }
        ),
      });
    }
  }

  render() {
    const props = this.props;
    const state = this.state;

    return (
      <React.Fragment>
        <HeaderWithPopup
          headerContent="Device Filters"
          popupContent="Enable or restrict access to devices for the user. Without specific metadata filters, all devices will be accessible by default."
        />

        {state.filters.map((filter, i) => {
          let filterValueOptions;

          let defaultFilterOptions = [
            {
              text: "All",
              key: "all",
              value: "all",
              onClick: (event: React.MouseEvent<HTMLDivElement>) => {
                event.preventDefault();

                this.updateFilter(i, { key: filter.key, values: "all" });
              },
            },
            {
              text: "divider",
              value: "divider",
              content: <DropdownDivider />,
              disabled: true,
            },
          ];

          if (filter.key === "id") {
            defaultFilterOptions = [];
          }

          // Checking if object is empty to avoid error for 'map of undefined'
          if (
            !_.isEmpty(props.filterOptions) &&
            props.filterOptions[filter.key]
          ) {
            filterValueOptions = [
              ...defaultFilterOptions,
              ...props.filterOptions[filter.key]?.map((o) => {
                return {
                  key: o,
                  text: o,
                  value: o + "",
                };
              }),
            ];
          } else {
            filterValueOptions = [...defaultFilterOptions];
          }

          return (
            <Row key={`${i}`}>
              <EditRoleDropdown
                placeholder="Metadata Key"
                value={filter.key}
                fluid
                search
                selection
                options={[
                  {
                    key: filter.key,
                    text: filter.key,
                    value: filter.key,
                  },
                  ...this.state.filterKeyOptions,
                ]}
                onChange={(_, data) => {
                  this.updateFilterKeyOptions(i, data);
                  this.updateFilter(i, {
                    key: data.value as string,
                    values: [],
                  });
                }}
              />

              <EditRoleDropdown
                placeholder={"Select Values"}
                options={filterValueOptions}
                fluid
                search
                selection
                multiple
                value={filter.values === "all" ? ["all"] : filter.values}
                onChange={(_event, data) =>
                  this.updateFilter(i, {
                    key: filter.key,
                    values: data.value as string[],
                  })
                }
              />

              <Button
                color="red"
                onClick={() => {
                  this.addFilterKeyOption(i);
                  this.removeFilter(i);
                }}
                icon
              >
                <Icon name="minus" />
              </Button>
            </Row>
          );
        })}

        <Row>
          <EditRoleDropdown
            placeholder="Select key to filter by"
            value={""}
            fluid
            search
            selection
            options={this.state.filterKeyOptions}
            onChange={(_, data) => {
              this.addFilter({ key: data.value as string, values: [] });
              this.removeFilterKeyOption(String(data.value));
            }}
          />
        </Row>
      </React.Fragment>
    );
  }
}

type Table = {
  name: string;
  columns: "all" | string[];
};

type DataAccessProps = {
  tables: { [key: string]: "all" | string[] };
  allTables: { [key: string]: string[] };
};

type DataAccessState = {
  tables: Table[];
};

class DataAccess extends React.Component<DataAccessProps, DataAccessState> {
  constructor(props: DataAccessProps) {
    super(props);
    this.state = {
      tables: Object.keys(props.tables).map((table) => {
        return {
          name: table,
          columns: props.tables[table],
        };
      }),
    };
  }

  updateTable(index: number, table: Table) {
    const tables = this.state.tables;

    if (table.columns === "all") {
      this.setState({
        tables: [...tables.slice(0, index), table, ...tables.slice(index + 1)],
      });
    } else {
      const columns =
        table.columns.length > 1
          ? table.columns.filter((value) => value !== "all")
          : table.columns;

      this.setState({
        tables: [
          ...tables.slice(0, index),
          {
            name: table.name,
            columns: columns,
          },
          ...tables.slice(index + 1),
        ],
      });
    }
  }

  addTable(table: Table) {
    this.setState({ tables: [...this.state.tables, table] });
  }

  removeable(index) {
    const tables = this.state.tables;

    this.setState({
      tables: [...tables.slice(0, index), ...tables.slice(index + 1)],
    });
  }

  getTables() {
    const tables = {};
    this.state.tables.forEach((table) => (tables[table.name] = table.columns));
    return tables;
  }

  getColumnOptions(table, index) {
    return [
      {
        text: "All",
        key: "all",
        value: "all",
        onClick: (event: React.MouseEvent<HTMLDivElement>) => {
          event.preventDefault();
          this.updateTable(index, { name: table.name, columns: "all" });
        },
      },
      {
        text: "divider",
        value: "divider",
        content: <DropdownDivider />,
        disabled: true,
      },
      ...(this.props.allTables[table.name] || [])
        .filter((field) => !field.endsWith("_timestamp"))
        .map((field) => {
          return {
            key: field,
            text: field,
            value: field,
          };
        }),
    ];
  }

  addAllTables() {
    const allTables = Object.keys(this.props.allTables).map((table) => {
      return {
        name: table,
        columns: "all" as "all",
      };
    });

    this.setState({ tables: allTables });
  }

  render() {
    const props = this.props;
    const state = this.state;
    let options = Object.keys(props.allTables);
    options = options.filter((name) => {
      const found = state.tables.find((tb) => tb.name === name);
      if (found) {
        return false;
      }
      return true;
    });
    let tableOptions = options.map((table) => {
      return {
        key: table,
        text: table,
        value: table,
      };
    });
    return (
      <React.Fragment>
        <HeaderWithPopup
          headerContent="Data Access"
          popupContent="Manage user's access to data streams and tables. Specify particular column access or select 'All' for full table visibility. Ensure proper access is set for a complete overview of all dashboard panel data."
        />
        {state.tables.map((table, i) => {
          const columnOptions = this.getColumnOptions(table, i);
          return (
            <Row key={table.name}>
              <EditRoleDropdown
                placeholder="Select Table"
                defaultValue={table.name}
                text={table.name}
                fluid
                search
                selection
                options={tableOptions}
                onChange={(_, data) => {
                  this.updateTable(i, {
                    name: data.value as string,
                    columns: [],
                  });
                }}
              />

              <EditRoleDropdown
                placeholder={"Select Columns"}
                options={columnOptions}
                fluid
                search
                selection
                multiple
                value={table.columns === "all" ? ["all"] : table.columns}
                onChange={(_event, data) => {
                  this.updateTable(i, {
                    name: table.name,
                    columns: data.value as string[],
                  });
                }}
              />
              <Button
                color="red"
                onClick={() => {
                  this.removeable(i);
                }}
                icon
              >
                <Icon name="minus" />
              </Button>
            </Row>
          );
        })}
        <Row
          style={{
            justifyContent: "space-between",
          }}
        >
          <EditRoleDropdown
            placeholder="Add Table"
            value={""}
            fluid
            search
            selection
            options={tableOptions}
            loading={tableOptions.length === 0 && this.state.tables?.length === 0}
            onChange={(_, data) => {
              this.addTable({ name: data.value as string, columns: [] });
            }}
          />
          {tableOptions.length > 0 && (
            <AddAllColText onClick={() => this.addAllTables()}>
              Add All Access
            </AddAllColText>
          )}
        </Row>
      </React.Fragment>
    );
  }
}

type MetadataAccessProps = {
  viewMetadata: string[] | "all";
  editMetadata: string[] | "all";
  viewMetadataKeys: boolean;
  editMetadataKeys: boolean;
  allMetadataKeys: string[];
};

type MetadataAccessState = {
  viewMetadata: string[] | "all";
  editMetadata: string[] | "all";
  viewMetadataKeys: boolean;
  editMetadataKeys: boolean;
};

class MetadataAccess extends React.Component<
  MetadataAccessProps,
  MetadataAccessState
> {
  constructor(props: MetadataAccessProps) {
    super(props);

    this.state = {
      viewMetadata: props.viewMetadata,
      editMetadata: props.editMetadata,
      viewMetadataKeys: props.viewMetadataKeys,
      editMetadataKeys: props.editMetadataKeys,
    };
  }

  getViewMetadata() {
    return this.state.viewMetadata;
  }

  getEditMetadata() {
    return this.state.editMetadata;
  }

  getViewMetadataKeys() {
    return this.state.viewMetadataKeys;
  }

  getEditMetadataKeys() {
    return this.state.editMetadataKeys;
  }

  getViewMetadataOptions() {
    return [
      {
        text: "All",
        key: "all",
        value: "all",
        onClick: (event: React.MouseEvent<HTMLDivElement>) => {
          event.preventDefault();

          this.setState({ viewMetadata: "all" });
        },
      },
      {
        text: "divider",
        value: "divider",
        content: <DropdownDivider />,
        disabled: true,
      },

      ...this.props.allMetadataKeys.map((o) => {
        return {
          key: o,
          text: capitalizeFirstLetter(o),
          value: o,
        };
      }),
    ];
  }

  getEditMetadataOptions() {
    return [
      {
        text: "All",
        key: "all",
        value: "all",
        onClick: (event: React.MouseEvent<HTMLDivElement>) => {
          event.preventDefault();

          this.setState({ editMetadata: "all" });
        },
      },
      {
        text: "divider",
        value: "divider",
        content: <DropdownDivider />,
        disabled: true,
      },

      ...this.props.allMetadataKeys.map((o) => {
        return {
          key: o,
          text: capitalizeFirstLetter(o),
          value: o,
        };
      }),
    ];
  }

  handleEditMetadataChange = (newValue) => {
    const newEditMetadata = newValue.filter((v) => v !== "all");
    const newViewMetadata = [...this.state.viewMetadata];

    // Append new items from editMetadata to viewMetadata if not already present
    newEditMetadata.forEach((item) => {
      if (!newViewMetadata.includes(item)) {
        newViewMetadata.push(item);
      }
    });

    this.setState({
      editMetadata: newEditMetadata,
      viewMetadata: newViewMetadata,
    });
  };

  render() {
    return (
      <React.Fragment>
        <Row>
          <Input labelPosition="left">
            <Label>Allow viewing metadata</Label>
            <Dropdown
              style={{ minWidth: "300px" }}
              placeholder={"Select Metadata"}
              options={this.getViewMetadataOptions()}
              fluid
              search
              selection
              multiple
              value={
                this.state.viewMetadata === "all"
                  ? ["all"]
                  : this.state.viewMetadata
              }
              onChange={(_event, data) =>
                this.setState({
                  viewMetadata: (data.value as string[])?.filter(
                    (v) => v !== "all"
                  ),
                })
              }
            />
          </Input>
        </Row>

        <Row>
          <Input labelPosition="left">
            <Label>Allow editing metadata</Label>
            <Dropdown
              style={{ minWidth: "300px" }}
              placeholder={"Select Metadata"}
              options={this.getEditMetadataOptions()}
              fluid
              search
              selection
              multiple
              value={
                this.state.editMetadata === "all"
                  ? ["all"]
                  : this.state.editMetadata
              }
              onChange={(_event, data) =>
                this.handleEditMetadataChange(data.value as string[])
              }
            />
          </Input>
        </Row>
      </React.Fragment>
    );
  }
}

type FirmwareUpdateProps = {
  viewFiles: boolean;
  editFiles: boolean;
  viewFirmwares: boolean;
  editFirmwares: boolean;
  viewDeviceConfigs: boolean;
  editDeviceConfigs: boolean;
};

type FirmwareUpdateState = FirmwareUpdateProps;

class FirmwareUpdate extends React.Component<
  FirmwareUpdateProps,
  FirmwareUpdateState
> {
  constructor(props) {
    super(props);

    this.state = props;
  }

  getState() {
    return this.state;
  }

  render() {
    return (
      <React.Fragment>
        <HeaderWithPopup
          headerContent="Inventory"
          popupContent="Permit users to access and handle firmware, files, and configuration versions."
        />
        <Row>
          <label style={{ paddingRight: "10px" }}>
            Allow user to view firmware versions{" "}
          </label>
          <Radio
            toggle
            checked={this.state.viewFirmwares}
            onChange={(_event, data) => {
              this.setState({ viewFirmwares: data.checked || false });
              this.setState({
                editFirmwares: data.checked ? this.state.editFirmwares : false,
              });
            }}
          />
        </Row>

        <Row
          style={{
            display: this.state.viewFirmwares ? "" : "none",
            paddingLeft: "10px",
          }}
        >
          <label style={{ paddingRight: "10px" }}>
            - Allow user to create, deactivate and activate firmware versions
          </label>
          <Radio
            toggle
            checked={this.state.editFirmwares}
            onChange={(_event, data) =>
              this.setState({ editFirmwares: data.checked || false })
            }
          />
        </Row>

        <Row>
          <label style={{ paddingRight: "10px" }}>
            Allow user to view files{" "}
          </label>
          <Radio
            toggle
            checked={this.state.viewFiles}
            onChange={(_event, data) => {
              this.setState({ viewFiles: data.checked || false });
              this.setState({
                editFiles: data.checked ? this.state.editFiles : false,
              });
            }}
          />
        </Row>

        <Row
          style={{
            display: this.state.viewFiles ? "" : "none",
            paddingLeft: "10px",
          }}
        >
          <label style={{ paddingRight: "10px" }}>
            - Allow user to create, deactivate and activate files
          </label>
          <Radio
            toggle
            checked={this.state.editFiles}
            onChange={(_event, data) =>
              this.setState({ editFiles: data.checked || false })
            }
          />
        </Row>

        <Row>
          <label style={{ paddingRight: "10px" }}>
            Allow user to view config versions{" "}
          </label>
          <Radio
            toggle
            checked={this.state.viewDeviceConfigs}
            onChange={(_event, data) => {
              this.setState({ viewDeviceConfigs: data.checked || false });
              this.setState({
                editDeviceConfigs: data.checked
                  ? this.state.editDeviceConfigs
                  : false,
              });
            }}
          />
        </Row>

        <Row
          style={{
            display: this.state.viewDeviceConfigs ? "" : "none",
            paddingLeft: "10px",
          }}
        >
          <label style={{ paddingRight: "10px" }}>
            - Allow user to create, edit and delete config versions{" "}
          </label>
          <Radio
            toggle
            checked={this.state.editDeviceConfigs}
            onChange={(_event, data) =>
              this.setState({ editDeviceConfigs: data.checked || false })
            }
          />
        </Row>
      </React.Fragment>
    );
  }
}

type DeviceManagementProps = {
  allowCreatingDevices: boolean;
};

type DeviceManagementState = DeviceManagementProps;

class DeviceManagement extends React.Component<
  DeviceManagementProps,
  DeviceManagementState
> {
  constructor(props) {
    super(props);
    this.state = props;
  }

  getState() {
    return this.state.allowCreatingDevices;
  }

  render() {
    return (
      <React.Fragment>
        <Row>
          <label style={{ paddingRight: "10px" }}>
            Allow creating new devices
          </label>
          <Radio
            toggle
            checked={this.state.allowCreatingDevices}
            onChange={(_event, data) =>
              this.setState({ allowCreatingDevices: data.checked || false })
            }
          />
        </Row>
      </React.Fragment>
    );
  }
}

type SettingsConfigProps = {
  editTenantSettings: boolean;
};

type SettingsConfigState = SettingsConfigProps;

class SettingsConfig extends React.Component<
  SettingsConfigProps,
  SettingsConfigState
> {
  constructor(props) {
    super(props);
    this.state = props;
  }

  getState() {
    return this.state;
  }

  render() {
    return (
      <React.Fragment>
        <HeaderWithPopup
          headerContent="Settings"
          popupContent="Give permissions to users for editing project-level settings, which include adding custom time ranges or pinning metadata in the device management section."
        />
        <Row>
          <label style={{ paddingRight: "10px" }}>
            Allow user to edit Project settings{" "}
          </label>
          <Radio
            toggle
            checked={this.state.editTenantSettings}
            onChange={(_event, data) =>
              this.setState({ editTenantSettings: data.checked || false })
            }
          />
        </Row>
      </React.Fragment>
    );
  }
}

type DashboardsProps = {
  createDashboards: boolean;
  permittedShareRoles: number[] | "all";
  allRoles: { id: number; name: string }[];
};

type DashboardsState = {
  permittedShareRoles: number[] | "all";
  createDashboards: boolean;
};

class Dashboards extends React.Component<DashboardsProps, DashboardsState> {
  constructor(props: DashboardsProps) {
    super(props);

    this.state = {
      permittedShareRoles: props.permittedShareRoles,
      createDashboards: props.createDashboards,
    };
  }

  updatePermittedShareRoles(roles) {
    this.setState({ permittedShareRoles: roles });
  }

  getPermittedShareRoles() {
    return this.state.permittedShareRoles;
  }

  canCreateDashboards() {
    return this.state.createDashboards;
  }

  render() {
    const roleOptions = [
      {
        text: "All",
        key: "all",
        value: "all",
        onClick: (event: React.MouseEvent<HTMLDivElement>) => {
          event.preventDefault();

          this.updatePermittedShareRoles("all");
        },
      },
      {
        text: "divider",
        value: "divider",
        content: <DropdownDivider />,
        disabled: true,
      },

      ...this.props.allRoles.map((role) => {
        return {
          key: role.id,
          text: role.name,
          value: role.id,
        };
      }),
    ];

    return (
      <React.Fragment>
        <HeaderWithPopup
          headerContent="Dashboards"
          popupContent="Authorize users to create or alter dashboards. Determine their ability to share these dashboards externally."
        />
        <Row>
          <label style={{ paddingRight: "10px" }}>
            Allow user to create, edit and delete dashboards
          </label>
          <Radio
            toggle
            checked={this.state.createDashboards}
            onChange={(_event, data) =>
              this.setState({ createDashboards: data.checked || false })
            }
          />
        </Row>

        <Row>
          <Input labelPosition="left">
            <Label>Allow sharing dashboards with</Label>
            <Dropdown
              style={{ minWidth: "300px" }}
              placeholder={"Select Roles"}
              options={roleOptions}
              fluid
              search
              selection
              multiple
              value={
                this.state.permittedShareRoles === "all"
                  ? ["all"]
                  : this.state.permittedShareRoles
              }
              onChange={(_event, data) =>
                this.updatePermittedShareRoles(
                  (data.value as string[])?.filter((v) => v !== "all")
                )
              }
            />
          </Input>
        </Row>
      </React.Fragment>
    );
  }
}

type ActionsProps = {
  allowedActions: string[] | "all";
  allActions: string[];
  viewActionTypes: boolean;
  editActionTypes: boolean;
  allowMarkActionAsCompleted: boolean;
};

type ActionsState = {
  allowedActions: string[] | "all";
  viewActionTypes: boolean;
  editActionTypes: boolean;
  allowMarkActionAsCompleted: boolean;
};

class Actions extends React.Component<ActionsProps, ActionsState> {
  constructor(props: ActionsProps) {
    super(props);

    this.state = {
      allowedActions: props.allowedActions,
      viewActionTypes: props.viewActionTypes,
      editActionTypes: props.editActionTypes,
      allowMarkActionAsCompleted: props.allowMarkActionAsCompleted,
    };
  }

  getAllowedActions() {
    return this.state.allowedActions;
  }

  getAllowMarkActionAsCompleted() {
    return this.state.allowMarkActionAsCompleted;
  }

  getState() {
    return this.state;
  }

  render() {
    const actionOptions = [
      {
        text: "All",
        key: "all",
        value: "all",
        onClick: (event: React.MouseEvent<HTMLDivElement>) => {
          event.preventDefault();

          this.setState({ allowedActions: "all" });
        },
      },
      {
        text: "divider",
        value: "divider",
        content: <DropdownDivider />,
        disabled: true,
      },

      ...this.props.allActions.map((action) => {
        return {
          key: action,
          text: capitalizeFirstLetter(action),
          value: action,
        };
      }),
    ];

    return (
      <React.Fragment>
        <HeaderWithPopup
          headerContent="Actions"
          popupContent="Allow users to execute Actions such as firmware and configuration updates. Give them access to mark these tasks as complete."
        />
        <Row>
          <Input labelPosition="left">
            <Label>Allow performing actions</Label>
            <Dropdown
              style={{ minWidth: "300px" }}
              placeholder={"Select Actions"}
              options={actionOptions}
              fluid
              search
              selection
              multiple
              value={
                this.state.allowedActions === "all"
                  ? ["all"]
                  : this.state.allowedActions
              }
              onChange={(_event, data) =>
                this.setState({
                  allowedActions: (data.value as string[])?.filter(
                    (v) => v !== "all"
                  ),
                })
              }
            />
          </Input>
        </Row>
        <Row>
          <label style={{ paddingRight: "10px" }}>
            Allow marking action as completed
          </label>
          <Radio
            toggle
            checked={this.state.allowMarkActionAsCompleted}
            onChange={(_event, data) =>
              this.setState({
                allowMarkActionAsCompleted: data.checked || false,
              })
            }
          />
        </Row>
      </React.Fragment>
    );
  }
}

export type EditRoleModalProps = {
  isOpen: boolean;
  role?: Role;
  title: string;
  onSubmit: (role: Role) => void;
  onCancel: () => void;
  key?: string | number | null | undefined;
};

type EditRoleModalState = {
  deviceFilterOptions: { [key: string]: string[] };
  allTables: { [key: string]: string[] };
  allRoles: Role[];
  allRolesSet: Set<string>;
  activeTab: number | string;
  allActions: string[];
  allMetadataKeys: string[];
  allDashboards: DashboardAPIResponse[];
  role: Role;
  roleNameError: { error: boolean; message: string };
};

export class EditRoleModal extends React.Component<
  EditRoleModalProps,
  EditRoleModalState
> {
  roleConfigRef = React.createRef<GeneralConfig>();
  deviceFiltersRef = React.createRef<DeviceFilters>();
  dataAccessRef = React.createRef<DataAccess>();
  metadataAccessRef = React.createRef<MetadataAccess>();
  firmwareUpdateRef = React.createRef<FirmwareUpdate>();
  // streamConfigRef = React.createRef<StreamConfig>();
  settingsConfigRef = React.createRef<SettingsConfig>();
  dashboardsRef = React.createRef<Dashboards>();
  actionsRef = React.createRef<Actions>();
  deviceManagementRef = React.createRef<DeviceManagement>();
  constructor(props) {
    super(props);

    this.state = {
      deviceFilterOptions: {},
      allTables: {},
      allRoles: [],
      allRolesSet: new Set(),
      activeTab: 0,
      allActions: [],
      allMetadataKeys: [],
      allDashboards: [],
      role: props.role
        ? props.role
        : {
            name: "",
            id: 0,
            permissions: {
              devices: {},
              tables: {},
              viewFiles: false,
              editFiles: false,
              viewFirmwares: false,
              editFirmwares: false,
              viewDeviceConfigs: false,
              editDeviceConfigs: false,
              editMetadata: [],
              viewMetadata: [],
              editStreams: false,
              viewStreams: false,
              homepage: "/",
              dashboardPermittedShareRoles: [],
              createDashboards: false,
              allowedActions: [],
              showDashboardsTab: false,
              showDeviceManagementTab: false,
              showActionsTab: false,
              viewRoles: false,
              editRoles: false,
              viewUsers: false,
              editUsers: false,
              allowMarkActionAsCompleted: false,
              editActionTypes: false,
              viewActionTypes: false,
              allowCreatingDevices: false,
              viewMetadataKeys: false,
              editMetadataKeys: false,
              editTenantSettings: false,
            },
          },
      roleNameError: { error: false, message: "" },
    };
  }

  setActiveTab(tab: number | string) {
    this.setState({ activeTab: tab });
  }

  setRoleNameError(error, message) {
    this.setState({ roleNameError: { error, message } });
  }

  onSubmit() {
    const permissions = this.state.role.permissions;

    const roleNameRegex = /^[a-zA-Z][ ?(\w.)+]*$/g;

    const newPermissions: Partial<Permission> = {
      homepage: this.roleConfigRef.current?.getHomepage(),
      devices: this.deviceFiltersRef.current?.getDeviceFilters(),
      tables: this.dataAccessRef.current?.getTables(),

      viewFiles: this.firmwareUpdateRef.current?.getState().viewFiles ?? false,
      editFiles: this.firmwareUpdateRef.current?.getState().editFiles ?? false,

      viewFirmwares: this.firmwareUpdateRef.current?.getState().viewFirmwares,
      editFirmwares: this.firmwareUpdateRef.current?.getState().editFirmwares,

      viewDeviceConfigs:
        this.firmwareUpdateRef.current?.getState().viewDeviceConfigs,
      editDeviceConfigs:
        this.firmwareUpdateRef.current?.getState().editDeviceConfigs,

      // viewStreams: this.streamConfigRef.current?.getState().viewStreams,
      // editStreams: this.streamConfigRef.current?.getState().editStreams,
      viewStreams: true,
      editStreams: false,

      createDashboards: this.dashboardsRef.current?.canCreateDashboards(),
      dashboardPermittedShareRoles:
        this.dashboardsRef.current?.getPermittedShareRoles(),

      allowedActions: this.actionsRef.current?.getAllowedActions(),

      viewMetadata: this.metadataAccessRef.current?.getViewMetadata(),
      editMetadata: this.metadataAccessRef.current?.getEditMetadata(),

      showDeviceManagementTab:
        this.roleConfigRef.current?.shouldShowDeviceManagmentTab(),
      showDashboardsTab: this.roleConfigRef.current?.shouldShowDashboardsTab(),
      showActionsTab:
        this.roleConfigRef.current?.shouldShowActionsTab() ?? false,

      // viewUsers: this.rolesAndUsersRef.current?.getState().viewUsers,
      // editUsers: this.rolesAndUsersRef.current?.getState().editUsers,
      // viewRoles: this.rolesAndUsersRef.current?.getState().viewRoles,
      // editRoles: this.rolesAndUsersRef.current?.getState().editRoles,
      viewUsers: true,
      editUsers: false,
      viewRoles: true,
      editRoles: false,

      // viewActionTypes: this.actionsRef.current?.getState().viewActionTypes,
      // editActionTypes: this.actionsRef.current?.getState().editActionTypes,
      viewActionTypes: true,
      editActionTypes: false,

      allowCreatingDevices: this.deviceManagementRef.current?.getState(),
      allowMarkActionAsCompleted:
        this.actionsRef.current?.getAllowMarkActionAsCompleted(),

      viewMetadataKeys: true,
      editMetadataKeys: false,
      editTenantSettings:
        this.settingsConfigRef.current?.getState().editTenantSettings ?? false,
    };

    if (
      this.roleConfigRef.current?.shouldShowActionsTab() ||
      this.roleConfigRef.current?.shouldShowDashboardsTab()
    ) {
      newPermissions.viewMetadataKeys = true;
      // newPermissions.viewMetadata = "all";
    }

    const updates = Object.fromEntries(
      Object.entries(newPermissions).filter(
        ([_, v]) => v !== null && v !== undefined
      )
    );
    const roleName = this.roleConfigRef.current?.getRoleName();
    const roleNameMatched = roleName?.match(roleNameRegex)?.length
      ? (roleName.match(roleNameRegex) as string[])
      : ([""] as string[]);

    if (roleName === "") {
      this.setRoleNameError(true, "Role Name cannot be empty");
      this.setActiveTab(0);
    } else if (roleNameMatched[0]?.length !== roleName?.length) {
      this.setRoleNameError(
        true,
        "Role Name should begin with character and only alphanumeric, space, underscore and dot are allowed."
      );
      this.setActiveTab(0);
    } else if (this.state.allRolesSet.has(roleName)) {
      this.setRoleNameError(true, "Role Name already exists");
      this.setActiveTab(0);
    } else {
      this.props.onSubmit({
        id: this.props.role ? this.props.role.id : 0,
        name: roleName?.trim(),
        permissions: Object.assign({}, permissions, updates),
      });
      this.setRoleNameError(false, "");
    }
  }

  async componentDidMount() {
    const p1 = fetchDeviceFilterOptions({});
    const p2 = fetchAllStreamsWithDetails();
    const p3 = fetchAllActionTypes();
    const p4 = fetchAllRoles(); // API Call can be removed if we get roles from props
    const p5 = fetchAllMetadataKeys();
    const p6 = fetchAllDashboards();

    const deviceFilters = await p1;
    const streams = await p2;
    const actions = await p3;
    const roles = await p4;
    const metadataKeys = await p5;
    const dashboards = await p6;

    const tables: { [key: string]: string[] } = {};
    Object.keys(streams).forEach((stream) => {
      let correctStreamName = stream;
      return (tables[correctStreamName] = Object.keys(streams[stream].fields));
    });

    const filterOptions: { [key: string]: string[] } = {};

    deviceFilters.forEach(
      (filter) => (filterOptions[filter.filterName] = filter.filterValues)
    );

    const allActions = actions.map((a) => a.type);

    const allMetadataKeys = metadataKeys.map((o) => o.key);

    let roleSet = new Set(roles.map((role) => role.name));

    if (this.props.role?.name) {
      roleSet.delete(this.props.role.name); // Removed props passed role name from the set to avoid duplicate role name error on edit
    }

    this.setState({
      deviceFilterOptions: filterOptions,
      allTables: tables,
      allActions: allActions,
      allRoles: roles,
      allRolesSet: roleSet,
      allMetadataKeys: allMetadataKeys,
      allDashboards: dashboards,
    });
  }

  render() {
    const props = this.props;

    const role: Role = this.state.role;

    const paneConfig = [
      {
        menuItem: "General",
        content: (
          <GeneralConfig
            name={role.name}
            roleNameError={this.state.roleNameError}
            homepage={role.permissions.homepage}
            showDeviceManagementTab={role.permissions.showDeviceManagementTab}
            showDashboardsTab={role.permissions.showDashboardsTab}
            showActionsTab={role.permissions.showActionsTab}
            dashboards={this.state.allDashboards}
            ref={this.roleConfigRef}
          />
        ),
      },
      {
        menuItem: "Device Filters",
        content: (
          <DeviceFilters
            filters={role.permissions.devices}
            filterOptions={this.state.deviceFilterOptions}
            ref={this.deviceFiltersRef}
          />
        ),
      },
      {
        menuItem: "Data Access",
        content: (
          <DataAccess
            tables={role.permissions.tables}
            allTables={this.state.allTables}
            ref={this.dataAccessRef}
          />
        ),
      },
      {
        menuItem: "Device Management",
        content: (
          <React.Fragment>
            <HeaderWithPopup
              headerContent="Device Management"
              popupContent="Allow users to add or modify devices, including control over viewing and editing associated metadata."
            />
            <DeviceManagement
              allowCreatingDevices={role.permissions.allowCreatingDevices}
              ref={this.deviceManagementRef}
            />
            <MetadataAccess
              viewMetadata={role.permissions.viewMetadata}
              editMetadata={role.permissions.editMetadata}
              viewMetadataKeys={role.permissions.viewMetadataKeys}
              editMetadataKeys={role.permissions.editMetadataKeys}
              allMetadataKeys={this.state.allMetadataKeys}
              ref={this.metadataAccessRef}
            />
          </React.Fragment>
        ),
      },
      {
        menuItem: "Dashboards",
        content: (
          <Dashboards
            createDashboards={role.permissions.createDashboards}
            permittedShareRoles={role.permissions.dashboardPermittedShareRoles}
            allRoles={this.state.allRoles}
            ref={this.dashboardsRef}
          />
        ),
      },
      {
        menuItem: "Actions",
        content: (
          <React.Fragment>
            <Actions
              allowedActions={role.permissions.allowedActions}
              viewActionTypes={role.permissions.viewActionTypes}
              editActionTypes={role.permissions.editActionTypes}
              allActions={this.state.allActions}
              allowMarkActionAsCompleted={
                role.permissions.allowMarkActionAsCompleted
              }
              ref={this.actionsRef}
            />
            <FirmwareUpdate
              viewFiles={role.permissions.viewFiles}
              editFiles={role.permissions.editFiles}
              viewDeviceConfigs={role.permissions.viewDeviceConfigs}
              editDeviceConfigs={role.permissions.editDeviceConfigs}
              viewFirmwares={role.permissions.viewFirmwares}
              editFirmwares={role.permissions.editFirmwares}
              ref={this.firmwareUpdateRef}
            />
          </React.Fragment>
        ),
      },
      {
        menuItem: "Settings",
        content: (
          <SettingsConfig
            editTenantSettings={role.permissions.editTenantSettings}
            ref={this.settingsConfigRef}
          />
        ),
      },
    ];

    const panes = paneConfig.map((c, i) => {
      const onNext =
        i === paneConfig.length - 1
          ? undefined
          : () => this.setActiveTab(i + 1);

      return {
        menuItem: c.menuItem,
        pane: {
          as: BorderlessPane,
          key: `tab-${i}-${c.menuItem}`,
          active: true,
          content: <SettingsPane onNext={onNext}>{c.content}</SettingsPane>,
        },
      };
    });

    return (
      <Modal
        open={props.isOpen}
        size="large"
        className="dark"
        closeOnEscape
        closeOnTriggerClick
      >
        <Modal.Header> {props.title} </Modal.Header>

        <Modal.Content>
          <Tab
            menu={{
              fluid: true,
              vertical: true,
              tabular: true,
              borderless: true,
            }}
            activeIndex={this.state.activeTab}
            onTabChange={(_, data) => {
              this.setActiveTab(
                data.activeIndex === undefined
                  ? this.state.activeTab
                  : data.activeIndex
              );
            }}
            panes={panes}
            renderActiveOnly={false}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button secondary onClick={() => this.props.onCancel()}>
            Cancel
          </Button>

          <Button primary onClick={() => this.onSubmit()}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
