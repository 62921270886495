import React, { useState } from "react";
import { Modal, Button, Input, Label, Icon } from "semantic-ui-react";
import { saveAs } from "file-saver";
import { createDevice } from "../../../BytebeamClient";
import { Mixpanel } from "../common/MixPanel";

const rowStyles = {
  display: "flex",
  flexDirection: "row",
  paddingTop: "10px",
  paddingBottom: "10px",
  alignItems: "center",
};

const DeviceProvisionModal = (props) => {
  const [open, setOpen] = useState(false);
  const [dedup, setDedup] = useState(true);
  const [metadata, setMetadata] = useState({});
  const submitData = async () => {
    try {
      let res = await createDevice(metadata, dedup);
      if(typeof res === "string") res = JSON.parse(res);
      console.log('Device provision response:');
      console.log(res);
      // console.log(res && (res.device_id || res.id));
      // console.log(res.id); // for Micelio
      if (res && (res.device_id || res.id)) {
        window.toastr.success("Device created successfully!");
        const deviceID = res.device_id || res.id;
        Mixpanel.track("Created Device", {
          deviceID: deviceID,
        });

        const file = new File(
          [JSON.stringify(res, null, 2)],
          `device_${deviceID}.json`,
          { type: "application/json" }
        );
        saveAs(file);
        props.onConfirm();
        setMetadata({});
        setOpen(false);
      } else {
        window.toastr.error("Device creation failed!");
        Mixpanel.track("Failure", {
          type: "Device creation",
          error: "no backend error",
          response: JSON.stringify(res),
        });
      }
    } catch (e) {
      Mixpanel.track("Failure", {
        type: "Device creation",
        error: JSON.stringify(e),
      });
      console.log(e);
    }
  };
  return (
    <Modal
      open={open}
      closeOnEscape={true}
      onClose={() => {
        setOpen(false);
      }}
      className="dark"
      size="tiny"
      trigger={
        <Button
          primary
          floated="right"
          labelPosition="left"
          icon
          onClick={() => setOpen(true)}
          style={{ whiteSpace: "nowrap" }}
        >
          <Icon name="plus" />
          Create Device
        </Button>
      }
    >
      <Modal.Header>Create Device</Modal.Header>
      <Modal.Content>
        <form id="deviceProvisionForm">
          {props.keys.map((key, i) => (
            <div style={rowStyles} key={key}>
              <Input
                autoFocus={i === 0}
                labelPosition="left"
                style={{
                  width: "92%",
                }}
              >
                <Label
                  style={{
                    margin: "0px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    paddingRight: "1.5rem !important",
                    maxWidth: "42%",
                  }}
                >
                  {key}
                </Label>
                <input
                  type="text"
                  value={metadata[key] || ""}
                  onChange={(event) => {
                    setMetadata({ ...metadata, [key]: event.target.value });
                  }}
                />
              </Input>
            </div>
          ))}
          {props.keys?.length > 0 ? (
            <div key="dedup" style={rowStyles}>
              <Input labelPosition="left">
                <Label
                  style={{
                    margin: "0px",
                  }}
                >
                  Do not create duplicate device
                </Label>
                <input
                  style={{
                    height: "22px",
                    width: "22px",
                    margin: "7px",
                  }}
                  type="checkbox"
                  onChange={(event) => setDedup(event.currentTarget.checked)}
                  checked={dedup}
                />
              </Input>
            </div>
          ) : (
            <div>
              <h2>Are you sure, you want to create new device?</h2>
              <p>
                Click on <b>Submit</b> to create new device or click{" "}
                <b>Cancel</b> to close.
              </p>
            </div>
          )}
        </form>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          primary
          onClick={() => {
            submitData();
          }}
        >
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DeviceProvisionModal;
