export enum OptionType {
  NoOptionSelected,
  ChooseConfig,
  UploadConfig,
  ChooseFirmware,
  UploadFirmware,
  SendFile,
  UploadCommonConfig,
  NoPayloadOption,
  UploadText,
  SendScript,
  UploadGeofence,
  ChooseGeofence,
}

export const checkLogs = (key: string) => {
  return (
    key.toLowerCase().includes("log") &&
    !key.toLowerCase().includes("uplink_") &&
    !key.toLowerCase().includes("_local")
  );
};
