  import React, { Fragment, useEffect } from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Polyline
} from "react-google-maps";

import { GOOGLE_MAPS_API_KEY } from "../../../../../constants/index"
import {
  MicelioTrackDevicesMetaData,
  MicelioTrackDevicesData
} from "./PanelDef";
import { PanelViewComponent } from "../PanelDef";
import { MarkerWithInfoWindow } from "../MicelioLocateDevices/ViewLocateDevices";
import { ReplayState } from "../../DashboardHeader";
import { darkTheme } from "../TrackDevices/MapStyle";
import { Settings } from "../../../../../util";

type MapComponentProps = {
  devicesLocationData: MicelioTrackDevicesData;
  deviceDashboardId: string;
  replayTimestamp: number;
  replayStep: number;
  replayState: ReplayState;
  settings: Settings;
}

const MapComponent = compose<MapComponentProps, MapComponentProps>(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?v=3.exp&key=" + GOOGLE_MAPS_API_KEY + "&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: "100%" }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)((props: MapComponentProps) => {
  const mapsRef = React.createRef<GoogleMap>();

  const [infoWindowDeviceId, setInfoWindowDeviceId] = React.useState("");

  const openInfoWindow = (deviceId: string) => {
    setInfoWindowDeviceId(deviceId);
  }

  const closeInfoWindow = (deviceId: string) => {
    if(deviceId === infoWindowDeviceId) {
      setInfoWindowDeviceId("");
    }
  }


  useEffect(() => {
    if(props.devicesLocationData.length > 0) {
      const bounds = new google.maps.LatLngBounds();

      props.devicesLocationData.forEach((deviceLocationData) => {
        deviceLocationData.coordinates.forEach((c) => {
          bounds.extend(new google.maps.LatLng(c.latitude, c.longitude))
        })
      })

      mapsRef.current?.fitBounds(bounds);
    }

  // eslint-disable-next-line
  }, [props.devicesLocationData]);

  return <GoogleMap
    ref={mapsRef}
    defaultZoom={17}
    defaultCenter={{ lat: 12.927381, lng: 77.637729 }}
    defaultOptions={{
      streetViewControl: false,
      scaleControl: false,
      mapTypeControl: true,
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.BOTTOM_CENTER
      },
      panControl: true,
      zoomControl: false,
      rotateControl: true,
      fullscreenControl: false,
      styles: darkTheme
    }}
  >
    {
      props.devicesLocationData.map((deviceLocationData) => {
        const coordinates = deviceLocationData.coordinates;
        const path = coordinates.map((c) => new google.maps.LatLng(c.latitude, c.longitude));

        let coordIndex = coordinates.length - 1;

        if (props.replayState === ReplayState.ReplayRunning ||
          props.replayState === ReplayState.ReplayPaused) {

          coordIndex = props.replayStep; 
          
          if (coordIndex > coordinates.length - 1) {
            coordIndex = coordinates.length - 1
          }
        }

        const currentLocation = coordinates[coordIndex];
      
        return <Fragment key={deviceLocationData.id}>
          <Polyline
            path={path}
            options={{
              strokeColor: "blue",
              geodesic: true,
              strokeWeight: 4
            }}
          />

          <MarkerWithInfoWindow
            deviceId={deviceLocationData.id}
            coordinates={currentLocation}
            deviceDashboardId={props.deviceDashboardId}
            metadata={deviceLocationData.metadata}
            deviceState={deviceLocationData.state}
            config={deviceLocationData.device_config}
            settings={props.settings}
            showGeoFence={true}

            isInfoWindowOpen={deviceLocationData.id === infoWindowDeviceId}
            openInfoWindow={openInfoWindow}
            closeInfoWindow={closeInfoWindow}
          />
        </Fragment>
      })
    }

  </GoogleMap>
});

export class MicelioViewTrackDevices extends PanelViewComponent<MicelioTrackDevicesMetaData, MicelioTrackDevicesData> {
  render() {
    return (
      <MapComponent
        devicesLocationData={this.props.data}
        deviceDashboardId={this.props.panelMeta.device_dashboard_id}
        replayTimestamp={this.props.replayStep}
        replayState={this.props.replayState}
        replayStep={this.props.replayStep}
        settings={this.props.settings}
      />
    );
  }
}
