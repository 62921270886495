import React, { useState, ReactNode } from "react";
import { Button, Input, Modal, Label } from "semantic-ui-react";
import { hasSpecialCharacters } from "../../util";

interface CreateMetadataModalProps {
  title: string;
  onSubmit: (key: string) => void;
  trigger: ReactNode;
  metadataKeysSet: Set<string>;
}

export default function CreateMetadataModal(props: CreateMetadataModalProps) {
  const { trigger, onSubmit } = props;
  const [metadataKey, setMetadataKey] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  // Reset the modal state when it closes
  const onModalClose = () => {
    setMetadataKey("");
    setButtonDisabled(false);
    setOpen(false);
  };

  return (
    <Modal
      className="dark"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="mini"
      trigger={trigger}
    >
      <Modal.Header>{props.title}</Modal.Header>
      <Modal.Description></Modal.Description>
      <Modal.Content>
        <Input labelPosition="left">
          <Label>Metadata Key</Label>
          <input
            autoFocus
            value={metadataKey}
            onChange={(e) => setMetadataKey(e.target.value?.replace(" ", "_"))}
          />
        </Input>
      </Modal.Content>
      <Modal.Actions>
        <Button
          secondary
          onClick={() => {
            onModalClose();
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          primary
          disabled={buttonDisabled}
          onClick={() => {
            if (metadataKey.trim() === "") {
              window.toastr.error("Metadata key cannot be empty");
            } else if (props.metadataKeysSet.has(metadataKey)) {
              window.toastr.error("Metadata key already exists");
            } else if (metadataKey.toLowerCase().trim() === "status") {
              window.toastr.error("Metadata key cannot be 'status'");
            } else if (hasSpecialCharacters(metadataKey.trim())) {
              window.toastr.error(
                "Metadata key cannot contain special characters except underscore"
              );
            } else {
              onSubmit(metadataKey.trim());
              onModalClose();
            }
          }}
        >
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
