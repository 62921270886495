import React, { memo, useEffect, useState } from "react";
import {
  Grid,
  Icon,
  IconProps,
  Popup,
  Progress,
  Table,
} from "semantic-ui-react";
import moment from "moment";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { capitalizeFirstLetter } from "../../util";
import styled from "styled-components";
import {
  ActionStatusType,
  approveAction,
  markActionAsCompleted,
} from "../../../../BytebeamClient";
import { useHistory } from "react-router-dom";
import ReleaseNotesModal from "./LiveAction/ReleaseNotesModal";
import ViewPayloadModal from "./LiveAction/ViewPayloadModal";
import ConfirmationModalMessageNonDelete from "../../common/ConfirmationModalMessageNonDelete";
import ConfirmationModal from "../../common/ConfirmationModal";
import { useUser } from "../../../../context/User.context";

export const ActionTable = styled(Table)`
  & tbody tr {
    td {
      padding: 2rem 1rem !important;
    }
  }
`;

export const ActionTableRow = styled(Table.Row)`
  cursor: pointer;

  &.selected {
    background-color: ${({ theme }) =>
      theme.colors["action-selected-table-background"]};
    color: ${({ theme }) => theme.colors["action-selected-table-text-color"]};
  }

  & .checkbox-hidden {
    opacity: 0;
  }

  &:hover .checkbox-hidden {
    opacity: 1;
  }
`;

export const OperationsButtonIcon = (props: IconProps) => {
  return (
    <Icon
      style={{
        ...props.style,
        cursor: "pointer",
        fontSize: "16px",
        color: `${({ theme }) => theme.colors["text"]}`,
      }}
      {...props}
    />
  );
};

function sumObjectValues(obj: { [key: string]: number }): number {
  return Object.values(obj).reduce((sum, current) => sum + current, 0);
}

type LiveActionsListProp = {
  readonly action: ActionStatusType[];
  readonly users: { name: string; email: string }[];
  readonly selectedAction: ActionStatusType;
  setSelectedAction: (action: ActionStatusType) => void;
};

const LiveActionsList = (props: LiveActionsListProp) => {
  const history = useHistory();
  const { user } = useUser();
  const currentUserRole = user.role.name;

  const { action, users, setSelectedAction, selectedAction } = props;
  const [email, setEmail] = useState<{ [key: string]: string }>({});
  const [showReleaseNotes, setShowReleaseNotes] = useState(false);
  const [releaseNotes, setReleaseNotes] = useState("");
  const [showPayload, setShowPayload] = useState(false);
  const [, setConfirmModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const emailMap: { [key: string]: string } = {};

    action.forEach((actionItem) => {
      const user = users.find((user) => user.name === actionItem.user_name);
      if (user) {
        emailMap[actionItem.user_name] = user.email;
      }
    });

    setEmail(emailMap);
  }, [action, users]);

  function getActionProgressStatus(action: ActionStatusType) {
    const numTotal: any = Object.values(action.statuses).reduce(
      (a: any, b: any) => a + b,
      0
    );
    const { Queued, Initiated, Completed, Scheduled, Failed, ...remaining } =
      action.statuses;
    let InProgress = 0;
    Object.keys(remaining).forEach((status) => {
      InProgress += remaining[status];
    });
    let progressPercentage =
      (((Completed || 0) + (Failed || 0)) / numTotal) * 100;
    if (Failed) {
      return {
        lastActionStatus: "Failed",
        lastActionProgress: progressPercentage.toFixed(1),
        lastActionClassName: "failed",
      };
    } else if (Completed) {
      // calculate progress
      return {
        lastActionStatus: "Completed",
        lastActionProgress: progressPercentage.toFixed(1),
        lastActionClassName: "completed",
      };
    } else if (Initiated && Initiated === numTotal) {
      return {
        lastActionStatus: "Initiated",
        lastActionProgress: 0,
        lastActionClassName: "initiated",
      };
    } else if (Queued && Queued === numTotal) {
      return {
        lastActionStatus: "Queued",
        lastActionProgress: 0,
        lastActionClassName: "queued",
      };
    } else if (Scheduled && Scheduled === numTotal) {
      return {
        lastActionStatus: "Scheduled",
        lastActionProgress: 0,
        lastActionClassName: "scheduled",
      };
    } else if (Initiated && Queued && Initiated + Queued === numTotal) {
      // calculate progress
      return {
        lastActionStatus: "Initiated",
        lastActionProgress: ((Initiated / numTotal) * 100).toFixed(1),
        lastActionClassName: "initiated",
      };
    } else {
      // calculate progress
      return {
        lastActionStatus: "InProgress",
        lastActionProgress: ((InProgress / numTotal) * 100).toFixed(1),
        lastActionClassName: "in-progress",
      };
    }
    // }
  }

  async function approvePhasedAction(actionId: string) {
    try {
      const res = await approveAction(actionId);
      console.log("Approve Response ", res);
      window.toastr.success(`Action ${actionId} approved successfully`);
    } catch (error) {
      console.log(error);
      window.toastr.error(`Failed to approve action ${actionId}`);
    }
  }

  const handleMarkAction = async (actionID: string) => {
    try {
      if (actionID) {
        let res = await markActionAsCompleted(actionID);
        if (res.ok) {
          window.toastr.success(`Marked action ${actionID} as completed`);
        }
      }
    } catch (e) {
      console.log(e);
      window.toastr.error(`Failed to mark action ${actionID} as completed`);
    }
  };

  const handleConfirmMarkComplete = (actionId: string) => {
    if (user.role.permissions.allowMarkActionAsCompleted)
      handleMarkAction(actionId);
    setConfirmModalOpen(false);
  };

  return (
    <Grid style={{ marginBottom: "20px" }}>
      <ReleaseNotesModal
        isOpen={showReleaseNotes}
        onClose={() => setShowReleaseNotes(false)}
        releaseNotes={releaseNotes}
      />
      <ViewPayloadModal
        isOpen={showPayload}
        onClose={() => setShowPayload(false)}
        actionId={selectedAction.action_id}
      />
      <Grid.Row>
        <Grid.Column>
          <ActionTable selectable>
            <Table.Header>
              <Table.Row textAlign={"center"}>
                <Table.HeaderCell>Actions</Table.HeaderCell>
                <Table.HeaderCell>Type Of Actions</Table.HeaderCell>
                <Table.HeaderCell>Triggered At</Table.HeaderCell>
                <Table.HeaderCell>User</Table.HeaderCell>
                <Table.HeaderCell>Devices</Table.HeaderCell>
                <Table.HeaderCell>Progress</Table.HeaderCell>
                <Table.HeaderCell>Operations</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {action.length !== 0 ? (
                action.map((action) => {
                  let actionProgress = getActionProgressStatus(action);
                  return (
                    <ActionTableRow
                      textAlign={"center"}
                      key={action.action_id}
                      className={
                        selectedAction.action_id === action.action_id
                          ? "selected"
                          : ""
                      }
                      onClick={() => setSelectedAction(action)}
                    >
                      <Table.Cell>{action.action_id}</Table.Cell>
                      <Table.Cell>{action.type}</Table.Cell>
                      <Table.Cell>
                        <Popup
                          content={moment(action.created_at).format(
                            "ddd, MMM Do YYYY, HH:mm:ss"
                          )}
                          position="top center"
                          inverted
                          trigger={
                            <div>
                              {action.created_at
                                ? capitalizeFirstLetter(
                                    moment(action.created_at).fromNow()
                                  )
                                : "--"}
                            </div>
                          }
                        />
                      </Table.Cell>
                      <Table.Cell>
                        {email[action.user_name] ? (
                          <Popup
                            content={email[action.user_name]}
                            position="top center"
                            inverted
                            trigger={<div>{action.user_name}</div>}
                          />
                        ) : (
                          <div>{action.user_name}</div>
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {sumObjectValues(action.statuses) ?? "-"}
                      </Table.Cell>
                      <Table.Cell width={4}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Popup
                            content={actionProgress.lastActionStatus}
                            position="top center"
                            inverted
                            trigger={
                              <Progress
                                progress
                                indicating={
                                  !(
                                    actionProgress.lastActionStatus ===
                                      "Completed" ||
                                    actionProgress.lastActionStatus === "Failed"
                                  )
                                }
                                percent={actionProgress.lastActionProgress}
                                className={actionProgress.lastActionClassName}
                                style={{ width: "150px", marginBottom: "0px" }}
                              />
                            }
                          />
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          {currentUserRole === "admin" && (
                            <OperationsButtonIcon
                              disabled={
                                action?.schedule?.status !== "pending_approval"
                              }
                              name="check"
                              title="Approve Action"
                              onClick={() => {
                                setSelectedAction(action);
                                approvePhasedAction(String(action.action_id));
                              }}
                            />
                          )}

                          <OperationsButtonIcon
                            disabled={
                              typeof action?.schedule?.release_notes !==
                              "string"
                            }
                            name="sticky note outline"
                            title="Release Notes"
                            onClick={() => {
                              setSelectedAction(action);
                              setShowReleaseNotes(true);
                              setReleaseNotes(action?.schedule?.release_notes);
                            }}
                          />
                          <OperationsButtonIcon
                            disabled={
                              action.payload_type === "none" &&
                              action.type !== "send_file" &&
                              action.type !== "send_script"
                            }
                            name="file code outline"
                            title="View Payload"
                            onClick={() => {
                              setSelectedAction(action);
                              setShowPayload(true);
                            }}
                          />
                          {user.role.permissions.allowMarkActionAsCompleted && (
                            <ConfirmationModal
                              prefixContent="Mark Action as Complete"
                              expectedText={String(action.action_id)}
                              onConfirm={() => {
                                setSelectedAction(action);
                                handleConfirmMarkComplete(
                                  String(action.action_id)
                                );
                              }}
                              trigger={
                                <OperationsButtonIcon
                                  name="check square"
                                  title="Mark Action as completed"
                                />
                              }
                              message={
                                <ConfirmationModalMessageNonDelete
                                  name={"Mark Action as Complete"}
                                  expectedText={String(action.action_id)}
                                  type={""}
                                  specialMessage=""
                                />
                              }
                            />
                          )}
                          <OperationsButtonIcon
                            name="eye"
                            title="More details"
                            onClick={() => {
                              setSelectedAction(action);
                              history.push(`?action_id=${action.action_id}`);
                            }}
                          />
                        </div>
                      </Table.Cell>
                    </ActionTableRow>
                  );
                })
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={"7"}>
                    <div
                      style={{
                        height: "60vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ErrorMessage
                        marginTop="30px"
                        message={
                          "No actions found! Please trigger an action to view its progress here."
                        }
                      />
                    </div>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </ActionTable>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default memo(LiveActionsList);
