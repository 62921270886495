export const homepageOptions = [
    {
      key: "/devices",
      value: "/device-management",
      text: "Devices",
    },
    {
      key: "/dashboards",
      value: "/dashboards",
      text: "List Dashboards",
    },
    {
      key: "/firmwares",
      value: "/device-management/firmwares",
      text: "Manage Firmware Versions",
    },
    {
      key: "/configurations",
      value: "/device-management/configurations",
      text: "Manage Device Configs",
    },
    {
      key: "/action-status",
      value: "/device-management/action-status",
      text: "View Action Status",
    },
    {
      key: "/settings",
      value: "/settings",
      text: "Settings",
    },
    {
      key: "/users",
      value: "/settings/users",
      text: "Manage Users",
    },
    {
      key: "/roles",
      value: "/settings/roles",
      text: "Manage Roles",
    },
    {
      key: "/metadata",
      value: "/settings/metadata",
      text: "Manage Metadata",
    },
    {
      key: "/action-types",
      value: "/settings/actions_types",
      text: "Manage Actions Types",
    },
    {
      key: "/actions",
      value: "/actions",
      text: "View Actions V2",
    },
    {
      key: "/actions/action-status",
      value: "/actions/action-status",
      text: "View Actions V2 Status",
    },
    {
      key: "/actions/inventory",
      value: "/actions/inventory",
      text: "View Actions V2 Inventory",
    }
  ];