import React from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";
import { DisplayIf } from "../../util";
import { Link } from "react-router-dom";

const ActionsMenu = styled(Link)<{
  firstElement: boolean;
  active: boolean;
  secondaryBackground?: boolean;
}>`
  // skewing the element for non first elements to make them parallelogram
  z-index: ${(props) => (props.active ? 2 : 1)};
  height: 40px;
  min-width: 140px;
  background: ${(props) =>
    props.active
      ? props.theme.colors["secondary_tab-background-active"]
      : props.secondaryBackground
      ? props.theme.colors["card_actions-navbar-background-color"]
      : props.theme.colors["secondary_tab-background"]};
  //   : props.theme.colors["navbar-background"]};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.15s ease all;
  transform: ${(props) =>
    props.active
      ? props.firstElement === true
        ? "scaleY(1.1) translateY(-2px)"
        : "scaleY(1.1) skew(32deg) translateY(-2px)"
      : props.firstElement === true
      ? "none"
      : "skew(32deg)"};
  &:before {
    position: absolute;
    right: -25px;
    border-right: ${(props) =>
      props.firstElement === true ? "25px solid transparent" : "none"};
    border-left: ${(props) =>
      props.firstElement === true ? "0px solid transparent" : "none"};
    border-bottom: ${(props) =>
      props.firstElement === true
        ? props.active
          ? `40px solid ${props.theme.colors["secondary_tab-background-active"]}`
          : props.secondaryBackground
          ? `40px solid ${props.theme.colors["card_actions-navbar-background-color"]}`
          : `40px solid ${props.theme.colors["secondary_tab-background"]}`
        : // ${props.theme.colors["navbar-background"]}
          "none"};
    width: 0px;
    height: 0px;
    transition: 0.15s ease all;
    content: "";
  }
  &:hover {
    transform: ${(props) =>
      props.firstElement === true
        ? "scaleY(1.1)  translateY(-2px)"
        : "scaleY(1.1) skew(32deg) translateY(-2px)"};
  }
  /* &:after {
    position: absolute;
    left: -25px;
    border-left: 25px solid transparent;
    border-right: 0px solid transparent;
    border-bottom: 40px solid #0000ff;
    width: 0px;
    height: 0px;
    content: "";
  } */
`;

const ActionsMenuWithoutLink = styled.div<{
  firstElement: boolean;
  active: boolean;
  secondaryBackground?: boolean;
}>`
  // skewing the element for non first elements to make them parallelogram
  z-index: ${(props) => (props.active ? 2 : 1)};
  height: 40px;
  min-width: 140px;
  background: ${(props) =>
    props.active
      ? props.theme.colors["secondary_tab-background-active"]
      : props.secondaryBackground
      ? props.theme.colors["card_actions-navbar-background-color"]
      : props.theme.colors["secondary_tab-background"]};
  //   : props.theme.colors["navbar-background"]};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.15s ease all;
  transform: ${(props) =>
    props.active
      ? props.firstElement === true
        ? "scaleY(1.1) translateY(-2px)"
        : "scaleY(1.1) skew(32deg) translateY(-2px)"
      : props.firstElement === true
      ? "none"
      : "skew(32deg)"};
  &:before {
    position: absolute;
    right: -25px;
    border-right: ${(props) =>
      props.firstElement === true ? "25px solid transparent" : "none"};
    border-left: ${(props) =>
      props.firstElement === true ? "0px solid transparent" : "none"};
    border-bottom: ${(props) =>
      props.firstElement === true
        ? props.active
          ? `40px solid ${props.theme.colors["secondary_tab-background-active"]}`
          : props.secondaryBackground
          ? `40px solid ${props.theme.colors["card_actions-navbar-background-color"]}`
          : `40px solid ${props.theme.colors["secondary_tab-background"]}`
        : // ${props.theme.colors["navbar-background"]}
          "none"};
    width: 0px;
    height: 0px;
    transition: 0.15s ease all;
    content: "";
  }
  &:hover {
    transform: ${(props) =>
      props.firstElement === true
        ? "scaleY(1.1)  translateY(-2px)"
        : "scaleY(1.1) skew(32deg) translateY(-2px)"};
  }
  /* &:after {
    position: absolute;
    left: -25px;
    border-left: 25px solid transparent;
    border-right: 0px solid transparent;
    border-bottom: 40px solid #0000ff;
    width: 0px;
    height: 0px;
    content: "";
  } */
`;

const ActionsMenuText = styled.div<{ firstElement: boolean; active: boolean }>`
  color: ${(props) =>
    props.active
      ? props.theme.colors["secondary_tab-text-active"]
      : props.theme.colors["secondary_tab-text"]};
  font-weight: 600;
  font-size: 16px;
  padding: ${(props) => (props.active ? "0 25px" : "0 20px")};
  transform: ${(props) =>
    props.firstElement === true ? "none" : "skew(-32deg)"};
  transition: 0.15s ease all;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    padding: 0 25px;
  }
`;

const ActionTabGapMaker = styled.div<{
  firstDivider: boolean;
  secondaryBackground?: boolean;
}>`
  width: ${(props) => (props.firstDivider === true ? "20px" : "8px")};
  position: relative;
  left: 0px;
  border-bottom: 4px solid
    ${(props) =>
      props.secondaryBackground
        ? props.theme.colors["card_actions-navbar-background-color"]
        : props.theme.colors["secondary_tab-background"]};
  transform: skew(32deg);
  z-index: 0;
`;

const BorderElement = styled.div<{ secondaryBackground?: boolean }>`
  display: flex;
  flex-grow: 1;
  border-bottom: 4px solid
    ${(props) =>
      props.secondaryBackground
        ? props.theme.colors["card_actions-navbar-background-color"]
        : props.theme.colors["secondary_tab-background"]};
`;

type ActionsMenuTabProps = {
  firstElement?: boolean;
  lastElement?: boolean;
  firstDivider?: boolean;
  name: string;
  icon: any;
  to?: string;
  displayIf: boolean;
  active: boolean;
  secondaryBackground?: boolean;
  onClick: () => void;
};

// Todo: Make the icons compatible with the themes and make this mode intune with themes.
export default function ActionsMenuTab(props: ActionsMenuTabProps) {
  const children = (
    <>
      {/* Common children elements */}
      <ActionsMenuText
        firstElement={props.firstElement ? props.firstElement : false}
        active={props.active}
      >
        {typeof props.icon === "string" ? (
          <Icon className={props.icon} style={{ marginRight: "10px" }} />
        ) : (
          <props.icon
            altColor={props.active ? "#FFFFFF" : undefined}
            style={{ marginRight: "10px", marginTop: "5px" }}
          />
        )}
        {props.name}
      </ActionsMenuText>
    </>
  );

  return (
    <>
      <DisplayIf cond={props.displayIf}>
        {!props.firstElement && (
          <ActionTabGapMaker
            firstDivider={props.firstDivider ? props.firstDivider : false}
            secondaryBackground={props.secondaryBackground}
          />
        )}
        {props.to ? (
          <ActionsMenu
            firstElement={props.firstElement ? props.firstElement : false}
            to={props.to}
            active={props.active}
            secondaryBackground={props.secondaryBackground}
            onClick={props.onClick}
          >
            {children}
          </ActionsMenu>
        ) : (
          <ActionsMenuWithoutLink
            firstElement={props.firstElement ? props.firstElement : false}
            active={props.active}
            secondaryBackground={props.secondaryBackground}
            onClick={props.onClick}
          >
            {children}
          </ActionsMenuWithoutLink>
        )}
      </DisplayIf>
      {props.lastElement && (
        <BorderElement secondaryBackground={props.secondaryBackground} />
      )}
    </>
  );
}
