import React from "react";
import { Button, Modal, Icon, Popup } from "semantic-ui-react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import RemoteShell from "./RemoteShell/RemoteShell";

type RemoteShellModalProps = {
  deviceId: number;
  isOpen: boolean;
  close: () => void;
};

export function RemoteShellModal(props: RemoteShellModalProps) {
  const { isOpen } = props;

  const fullScreenHandle = useFullScreenHandle();

  return (
    <Modal
      open={isOpen}
      onClose={props.close}
      size="fullscreen"
      className="dark"
      closeOnDimmerClick={false}
      style={{ marginLeft: "2em" }}
    >
      <Modal.Header>
        Remote Shell
        {!fullScreenHandle.active ? (
          <span className="bytebeam-panel-icons">
            <span className="bytebeam-panel-icon">
              <Popup
                content="View full screen"
                position="top center"
                inverted
                trigger={
                  <Icon
                    name="expand"
                    color="blue"
                    link
                    onClick={fullScreenHandle.enter}
                  />
                }
              />
            </span>
          </span>
        ) : (
          ""
        )}
      </Modal.Header>
      <Modal.Content>
        <FullScreen handle={fullScreenHandle}>
          {fullScreenHandle.active ? (
            <span className="bytebeam-panel-icons">
              <span className="bytebeam-panel-icon">
                <Popup
                  content="Exit full screen"
                  position="top center"
                  inverted
                  trigger={
                    <Icon
                      name="compress"
                      color="yellow"
                      link
                      onClick={fullScreenHandle.exit}
                    />
                  }
                />
              </span>
            </span>
          ) : (
            ""
          )}
          <RemoteShell deviceId={props.deviceId} />
        </FullScreen>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={() => props.close()}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
