import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { StyledHeader } from "./SelectableItem";
import { Permission } from "../../../../util";
import _ from "lodash";
import { filterTableInfo } from "../../util";
import {
  Device,
  changeDeviceStatus,
  downloadCertificates,
  fetchAllDashboards,
  fetchDeviceWithSpecificAction,
  fetchTableInfo,
  fetchAllDeviceActions,
  markAllDeviceActionAsCompleted,
  getTenantFromURL,
  // devicesPerPageOptions,
} from "../../../../BytebeamClient";
import DeviceOverview from "../../DeviceMangaement/Devices/DeviceOverview";
import { LastActionType } from "../../DeviceMangaement/Devices/LastActionInfoTable";
import {
  // Button,
  Grid,
  Icon,
  MenuItem,
  Popup,
  Progress,
  Table,
} from "semantic-ui-react";
import LoadingAnimation from "../../../common/Loader";
import { StyledGridColumn } from "../../DeviceMangaement/Devices/Device";
import { ActionButton } from "../../DeviceMangaement/Devices/ActionButtons";
import { ActivateDeviceConfirmationModal } from "../../DeviceMangaement/Devices/ActionModals/ActivateDeviceConfirmationModal";
import { ShowDeviceStreamsModal } from "../../DeviceMangaement/Devices/ActionModals/ShowDeviceStreamsModal";
import { ShowConfigVersionModal } from "../../DeviceMangaement/Devices/ActionModals/ShowConfigVersionModal";
import { ShowDeviceDashboardModal } from "../../DeviceMangaement/Devices/ActionModals/ShowDeviceDashboardModal";
import { RemoteShellModal } from "../../DeviceMangaement/Devices/ActionModals/RemoteShellModal";
import { useUser } from "../../../../context/User.context";
import { CardContainer, FlexContainer } from "./LiveActions";
import { ActionsMenuWrapper } from "./ActionsV3";
// import ActionsMenuTab from "./ActionsMenuTab";
import { StyledNonBoldHeader } from "./new-action/NewAction";
import SelectedFilter from "./SelectedFilter";
import ActionV3Button from "./ActionButton";
import ActionFilterDropdown from "./ActionFilterDropdown";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { SelectDevicesPerPage } from "../../DeviceMangaement/Devices/Devices";
// import ThemeSchema from "../../../../theme/schema";
// import { OperationsButtonIcon } from "./LiveActionsList";
// import ActionsHistoricalBarChart from "./ActionsHistoricalBarChart";
import {
  StyledCardSearchPageInput,
  StyledPagination,
  StyledSecondaryDevicePerPageWidget,
} from "../../../common/commonStyledComps";
import ConfirmationModal from "../../common/ConfirmationModal";
import ConfirmationModalMessageNonDelete from "../../common/ConfirmationModalMessageNonDelete";
import { checkLogs } from "../util";
// import Toggle from "../../../common/Toggle";

interface DeviceV3OverviewProps {
  actionIDParam: string | null;
  deviceIDParam: string | null;
}

const DeviceOverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 60px;
`;

const ErrorTableCell = styled(Table.Cell)`
  max-width: 50px;
  white-space: normal;
  word-wrap: break-word;
`;

const AuditActionTable = styled(Table)`
  & tbody tr {
    td {
      padding: 2rem 1rem !important;
    }
  }
`;

const AuditActionTableRow = styled(Table.Row)`
  cursor: pointer;
  &.selected {
    background-color: ${({ theme }) =>
      theme.colors["action-selected-table-background"]};
    color: ${({ theme }) => theme.colors["action-selected-table-text-color"]};
  }
`;

const dummyHistoricalMetadataData = [
  {
    id: 1,
    details: "Location tag changed to Bangalore",
    user: "Mock User",
    actionTime: "3 days ago",
    errors: "Action Timeout",
  },
];

// const dummyHistoricalActionData = [
//   {
//     action_id: 2954,
//     device_id: "2",
//     errors: [],
//     params:
//       '{"content-length":227862,"status":false,"url":"https://firmware.stage.bytebeam.io/api/v1/firmwares/new62/artifact","version":"new62"}',
//     progress: 0,
//     status: "Completed",
//     type: "update_firmware",
//     updated_at: "2024-01-08T06:48:39Z",
//   },
// ];

interface ConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  header: string;
  content: string;
}

export default function DeviceV3Overview(props: DeviceV3OverviewProps) {
  const { user } = useUser();

  let abortController = new AbortController();

  const [loading, setLoading] = useState<boolean>(true);
  const [device, setDevice] = useState<Device>();

  const [streams, setStreams] = useState<Record<string, string[]>>({});
  const [dashboards, setDashboards] = useState<any[]>();

  const [deviceStatus, setDeviceStatus] = useState<string>();
  const [permissions, setPermissions] = useState<Permission>();
  const [metadataKeys, setMetadataKeys] = useState<string[]>();
  const [editableMetadataKeys, setEditableMetadataKeys] =
    useState<Set<string>>();
  const [deviceID, setDeviceID] = useState<number>();
  const [lastAction, setLastAction] = useState<LastActionType>();
  const [allowedActions, setAllowedActions] = useState<string[] | "all">();
  const [stateKeysNotUniq, setStateKeysNotUniq] = useState<string[]>();
  const [stateKeys, setStateKeys] = useState<string[]>();

  const [showConfigVersion, setShowConfigVersion] = useState<boolean>(false);
  const [remoteShellDeviceID, setRemoteShellDeviceID] = useState<number>(0);

  const [openActivateConfirmationModal, setOpenActivateConfirmationModal] =
    useState<boolean>(false);
  const [openShowStreamsModal, setOpenShowStreamsModal] =
    useState<boolean>(false);
  const [openShowConfigVersionModal, setOpenShowConfigVersionModal] =
    useState<boolean>(false);
  const [openShowDeviceDashboardModal, setOpenShowDeviceDashboardModal] =
    useState<boolean>(false);

  const [historicalActions, setHistoricalActions] = useState<any[]>([]);
  const [historicalActionPage, setHistoricalActionPage] = useState<any[]>([]);
  const [historicalActionPageNumber, setHistoricalActionPageNumber] =
    useState<number>(1);
  const [historicalActionPageLimit] = useState<number>(25);
  const [historicalActionTotalPage, setHistoricalActionTotalPage] =
    useState<number>(1);
  const [historicalActionLoading, setHistoricalActionLoading] =
    useState<Boolean>(false);

  const [activeItem, setActiveItem] = useState<string>("actions");
  const [showFilterDropdown, setShowFilterDropdown] = useState<boolean>(false);
  const [, setConfirmModalOpen] = useState<boolean>(false);
  const [auditFilterOptions, setAuditFilterOptions] = useState<any[]>([
    {
      id: 2,
      name: "Successful",
      icon: "check circle",
    },
    {
      id: 3,
      name: "Initiated",
      icon: "bars",
    },
    {
      id: 4,
      name: "In-Progress",
      icon: "bars",
    },
    {
      id: 5,
      name: "Queued",
      icon: "bars",
    },
  ]);

  const [selectedAuditFilterOptions, setSelectedAuditFilterOptions] = useState<
    any[]
  >([
    {
      id: 1,
      name: "Failed",
      icon: "close icon",
    },
  ]);

  const [theme, setTheme] = useState<any>();

  const StyledDeviceOperationsButton = {
    background: theme === "light" ? "#ffffff" : "transparent",
    color: theme === "light" ? "#5C5C5C;" : "#C1C1C1",
    border: `1px solid ${theme === "light" ? "#828282" : "#C1C1C1"}`,
    marginRight: "12px",
    padding: "10px 12px",
    borderRadius: "4px",
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleItemClick = (item: string) => {
    setActiveItem(item);
  };

  const getStreamList = async () => {
    try {
      const res = filterTableInfo(await fetchTableInfo());
      setStreams(res);
    } catch (e) {
      console.log(e);
    }
  };

  const getDashboardList = async () => {
    try {
      const res = await fetchAllDashboards();
      setDashboards(res);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchSpecificDevice = async () => {
    abortController.abort();
    abortController = new AbortController();
    if (props.deviceIDParam && props.actionIDParam)
      await fetchDeviceWithSpecificAction(
        parseInt(props.deviceIDParam),
        parseInt(props.actionIDParam),
        abortController.signal
      ).then((deviceData) => {
        setDevice(deviceData);
        setPermissions(user?.role?.permissions);
        setMetadataKeys(user?.role?.permissions?.viewMetadata as string[]);
        setEditableMetadataKeys(
          new Set(user?.role?.permissions?.editMetadata || [])
        );

        setDeviceStatus(deviceData["status"]);

        setDeviceID(deviceData["id"]);
        setLastAction(deviceData["action"]);

        // Choosing not to show sequence in UI table, even though data is available
        // via API.
        setStateKeysNotUniq(
          (user?.role?.permissions?.tables["device_shadow"] as string[]).filter(
            (key: string) => key !== "sequence"
          )
        );
        setStateKeys(
          _.uniq(
            (
              user?.role?.permissions?.tables["device_shadow"] as string[]
            ).filter((key: string) => key !== "sequence")
          )
        );

        setAllowedActions(user?.role?.permissions?.allowedActions);
        setShowConfigVersion(
          _.uniq(
            (
              user?.role?.permissions?.tables["device_shadow"] as string[]
            ).filter((key: string) => key !== "sequence")
          ).includes("config_version")
        );

        setTheme(user?.settings?.theme ?? "dark");
        setLoading(false);
        fetchAllDeviceActionsFn(deviceData["id"]);
      });
  };

  const fetchAllDeviceActionsFn = async (deviceID) => {
    try {
      if (deviceID) {
        setHistoricalActionLoading(true);
        const res = await fetchAllDeviceActions(deviceID);
        setHistoricalActionLoading(false);
        setHistoricalActions(res?.device_actions);
        setHistoricalActionPage(
          res?.device_actions.slice(0, historicalActionPageLimit)
        );
        setHistoricalActionPageNumber(1);
        setHistoricalActionTotalPage(
          Math.ceil(res?.device_actions.length / historicalActionPageLimit)
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleConfirmMarkAllComplete = () => {
    // call the function that marks all device actions as complete
    handleMarkAllDeviceActions(deviceID);
    // close the modal
    setConfirmModalOpen(false);
  };

  // const toggleConfirmModal = () => {
  //   setConfirmModalOpen(!confirmModalOpen);
  // };

  const handleMarkAllDeviceActions = async (deviceID) => {
    try {
      if (deviceID) {
        let res = await markAllDeviceActionAsCompleted(deviceID);
        if (res && res?.updated_rows !== undefined) {
          window.toastr.success(`Marked all actions as complete`);
        }
        await fetchAllDeviceActionsFn(deviceID);
      }
    } catch (e) {
      console.log(e);
      window.toastr.error("Failed to mark all actions as complete.");
    }
  };

  const changePageForActions = (pageNumber: number) => {
    let newData = historicalActions.slice(
      (pageNumber - 1) * historicalActionPageLimit,
      pageNumber * historicalActionPageLimit
    );
    console.log(historicalActionPageLimit);
    setHistoricalActionPage(newData);
  };

  const downloadFile = async (data: any, key: string) => {
    const element = document.createElement("a");
    const file = new Blob([data], {
      type: "text/plain",
    });

    element.href = URL.createObjectURL(file);
    element.download = key;
    document.body.appendChild(element);

    setTimeout(() => element.click());
  };

  const downloadCertificatesFn = async (id: number) => {
    const res = await downloadCertificates(id);
    await downloadFile(JSON.stringify(res), `device_${id}.json`);
  };

  const setRemoteShellDeviceId = (deviceId: number) => {
    setRemoteShellDeviceID(deviceId);
  };

  const changeDeviceStatusFn = async (id: number, status: string) => {
    try {
      setLoading(true);
      await changeDeviceStatus(id, status);
      await fetchSpecificDevice();
      window.toastr.success(`Device status set to ${status}`);
    } catch (e) {
      window.toastr.error("Failed to change device status");
      console.error("Error in changeDeviceStatus: ", e);
    }
  };

  const addSelectedFilter = (selectedItem: any) => {
    setSelectedAuditFilterOptions([
      ...selectedAuditFilterOptions,
      selectedItem,
    ]);

    setAuditFilterOptions((auditFilterOptions) =>
      auditFilterOptions
        .filter((option) => option.id !== selectedItem.id)
        .sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0))
    );
  };

  const removeSelectedFilter = (selectedItem: any) => {
    setAuditFilterOptions(
      [...auditFilterOptions, selectedItem].sort((a, b) =>
        a.id > b.id ? 1 : a.id < b.id ? -1 : 0
      )
    );

    setSelectedAuditFilterOptions((selectedAuditFilterOptions) =>
      selectedAuditFilterOptions.filter(
        (option) => option.id !== selectedItem.id
      )
    );
  };

  const onHistoricalActionPageChange = useCallback(
    (e, { activePage }) => {
      setHistoricalActionPageNumber(activePage);
      changePageForActions(activePage);
    },
    [historicalActionTotalPage] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (user.id !== "") {
      getStreamList();
      getDashboardList();
      fetchSpecificDevice();
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  return device && !loading ? (
    <DeviceOverviewContainer>
      {deviceID && deviceStatus && streams && dashboards && (
        <>
          <ActivateDeviceConfirmationModal
            deviceId={deviceID}
            deviceStatus={deviceStatus}
            changeDeviceStatus={changeDeviceStatusFn}
            isOpen={openActivateConfirmationModal}
            close={() => setOpenActivateConfirmationModal(false)}
          />
          <ShowDeviceStreamsModal
            deviceId={deviceID}
            isOpen={openShowStreamsModal}
            streams={streams}
            close={() => setOpenShowStreamsModal(false)}
            theme={theme}
          />
          <ShowConfigVersionModal
            configVersion={device["state"]["config_version"]}
            isOpen={openShowConfigVersionModal}
            close={() => setOpenShowConfigVersionModal(false)}
            theme={theme}
          />
          <ShowDeviceDashboardModal
            deviceId={deviceID}
            isOpen={openShowDeviceDashboardModal}
            dashboards={dashboards}
            close={() => setOpenShowDeviceDashboardModal(false)}
          />
        </>
      )}

      {remoteShellDeviceID ? (
        <RemoteShellModal
          deviceId={remoteShellDeviceID}
          isOpen={remoteShellDeviceID > 0}
          close={() => setRemoteShellDeviceID(-1)}
        />
      ) : (
        <></>
      )}

      <CardContainer marginBottom="25px">
        <h1
          style={{
            fontSize: "32px",
            fontWeight: 700,
            textAlign: "left",
            marginBottom: "32px",
          }}
        >
          Device Overview
        </h1>

        <StyledHeader
          as="h3"
          style={{ marginTop: "0px", marginBottom: "20px" }}
        >
          Device ID: {props.deviceIDParam}
        </StyledHeader>

        {device &&
          permissions &&
          lastAction &&
          metadataKeys &&
          editableMetadataKeys &&
          deviceID &&
          stateKeys &&
          stateKeysNotUniq && (
            <Grid columns={3} divided>
              <DeviceOverview
                permissions={permissions}
                stateKeys={stateKeys}
                device={device}
                deviceId={deviceID}
                lastAction={lastAction}
                metadataKeys={metadataKeys}
                enablePinMetadata={false}
                editableMetadataKeys={editableMetadataKeys}
                specificAction
              />
            </Grid>
          )}

        <StyledHeader
          as="h3"
          style={{ marginTop: "40px", marginBottom: "20px" }}
        >
          Operations
        </StyledHeader>

        <Grid>
          <StyledGridColumn style={{ justifyContent: "flex-start" }} width={16}>
            <ActionButton
              onClick={() => {
                if (deviceStatus === "active" && deviceID)
                  downloadCertificatesFn(deviceID);
                else {
                  window.toastr.error(
                    "Cannot download the configuration for a deactivated device."
                  );
                }
              }}
              style={StyledDeviceOperationsButton}
            >
              <Icon name="download" /> <span>Download Config</span>
            </ActionButton>

            {allowedActions?.includes("launch_shell") && (
              <ActionButton
                onClick={() => {
                  if (deviceID) setRemoteShellDeviceId(deviceID);
                }}
                style={StyledDeviceOperationsButton}
              >
                <Icon name="terminal" /> <span>Remote Shell</span>
              </ActionButton>
            )}
            {/* Show only if config_version is in device_shadow */}
            {showConfigVersion && (
              <ActionButton
                onClick={() => setOpenShowConfigVersionModal(true)}
                style={StyledDeviceOperationsButton}
              >
                <Icon name="configure" /> <span>Config Version</span>
              </ActionButton>
            )}
            {Object.keys(user?.role?.permissions?.tables).length > 0 && (
              <ActionButton
                onClick={() => setOpenShowStreamsModal(true)}
                style={StyledDeviceOperationsButton}
              >
                <Icon name="table" /> <span>Streams</span>
              </ActionButton>
            )}
            {Object.entries(streams).filter(([stream, fields]) =>
              checkLogs(stream)
            ).length > 0 && (
              <ActionButton
                onClick={() =>
                  window.open(
                    `/projects/${getTenantFromURL()}/actionsv3/logs/device-log?device_id=${deviceID}`,
                    "_blank"
                  )
                }
                style={StyledDeviceOperationsButton}
              >
                <Icon name="file alternate outline" /> <span>Logs</span>
              </ActionButton>
            )}
            <ActionButton
              onClick={() => setOpenShowDeviceDashboardModal(true)}
              style={StyledDeviceOperationsButton}
            >
              <Icon name="dashboard" /> <span>Device Dashboards</span>
            </ActionButton>
            <ActionButton
              onClick={() => setOpenActivateConfirmationModal(true)}
              style={StyledDeviceOperationsButton}
            >
              <Icon
                name={
                  deviceStatus === "active" ? "ban" : "check circle outline"
                }
              />{" "}
              <span>
                {deviceStatus === "active" ? "Deactivate" : "Activate"}
              </span>
            </ActionButton>
          </StyledGridColumn>
        </Grid>
      </CardContainer>

      <CardContainer>
        <h1
          style={{
            fontSize: "32px",
            fontWeight: 700,
            textAlign: "left",
            marginBottom: "2px",
          }}
        >
          Historical Actions
        </h1>

        <ActionsMenuWrapper className="desktop-view">
          {/* <ActionsMenuTab
            firstElement
            name={"Actions"}
            icon={"clock"}
            displayIf={true}
            active={activeItem === "actions"}
            secondaryBackground
            onClick={() => {
              handleItemClick("actions");
            }}
          /> */}

          {/* <ActionsMenuTab
            name={"Metadata"}
            icon={"file code"}
            displayIf={true}
            active={activeItem === "metadata"}
            secondaryBackground
            onClick={() => {
              handleItemClick("metadata");
            }}
            lastElement
          /> */}
        </ActionsMenuWrapper>

        {activeItem === "metadata" ? (
          <>
            <FlexContainer style={{ marginTop: "30px", marginBottom: "30px" }}>
              <StyledNonBoldHeader as="h3" style={{ marginTop: "5px" }}>
                Filter By
              </StyledNonBoldHeader>

              {selectedAuditFilterOptions.flatMap((selectedOption) => (
                <SelectedFilter
                  name={selectedOption.name}
                  icon={selectedOption.icon}
                  onClick={() => {
                    removeSelectedFilter(selectedOption);
                  }}
                />
              ))}

              <div style={{ position: "relative" }}>
                <ActionV3Button
                  onClick={() => {
                    setShowFilterDropdown(true);
                  }}
                  label={"Add"}
                  marginLeft={"20px"}
                  padding={"0.5rem 1rem"}
                  icon={"plus"}
                />

                {showFilterDropdown && (
                  <ActionFilterDropdown
                    dropdownOptions={auditFilterOptions}
                    setShowFilterDropdown={(value) =>
                      setShowFilterDropdown(value)
                    }
                    addFilter={(selectedItem) => {
                      addSelectedFilter(selectedItem);
                    }}
                  />
                )}
              </div>
            </FlexContainer>

            <Grid style={{ marginBottom: "20px" }}>
              <Grid.Row>
                <Grid.Column>
                  <AuditActionTable selectable>
                    <Table.Header>
                      <Table.Row textAlign={"center"}>
                        <Table.HeaderCell>Details</Table.HeaderCell>
                        <Table.HeaderCell>User</Table.HeaderCell>
                        <Table.HeaderCell>Action Time</Table.HeaderCell>
                        <Table.HeaderCell width={4}>Errors</Table.HeaderCell>
                        <Table.HeaderCell>Progress</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {dummyHistoricalMetadataData.length !== 0 ? (
                        dummyHistoricalMetadataData.map((metadata, key) => (
                          <AuditActionTableRow
                            textAlign={"center"}
                            key={key}
                            // if selection is there then use below:
                            // className={selectedAction === action ? "selected" : ""}
                            // onClick={() => {}}
                          >
                            <Table.Cell>{metadata.details}</Table.Cell>
                            <Table.Cell>
                              {metadata.user ? (
                                <Popup
                                  content={"mockuser@bytebeam.io"}
                                  position="top center"
                                  inverted
                                  trigger={<div>{metadata.user}</div>}
                                />
                              ) : (
                                <div>{metadata.user}</div>
                              )}
                            </Table.Cell>
                            <Table.Cell>{metadata.actionTime}</Table.Cell>
                            <ErrorTableCell width={4}>
                              {metadata.errors}
                            </ErrorTableCell>
                            <Table.Cell>
                              {/* dummy progress bar for now */}
                              <Progress
                                progress="percent"
                                percent={
                                  // styleActionProgress(data?.status, data?.progress)[
                                  //   "progress"
                                  // ]
                                  80
                                }
                                indicating={
                                  // data?.status === "Completed" ||
                                  // data?.status === "Failed"
                                  //   ? false
                                  //   : true
                                  true
                                }
                                size="medium"
                                style={{ marginBottom: "0" }}
                                // className={`action-progress progress-margin ${
                                //   styleActionProgress(data?.status, data?.progress)[
                                //     "className"
                                //   ]
                                // }`}
                              />
                            </Table.Cell>
                          </AuditActionTableRow>
                        ))
                      ) : (
                        <Table.Row>
                          <Table.Cell colSpan={"7"}>
                            <div
                              style={{
                                height: "60vh",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <ErrorMessage
                                marginTop="30px"
                                message={"No metadata found!"}
                              />
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </Table.Body>
                  </AuditActionTable>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            {!loading && dummyHistoricalMetadataData?.length !== 0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "nowrap",
                  gap: "16px",
                }}
              >
                <StyledPagination
                  boundaryRange={0}
                  ellipsisItem={null}
                  siblingRange={2}
                  activePage={
                    // actionsPageNumber
                    1
                  }
                  totalPages={
                    // Math.ceil(
                    // totalActionsForPagination / actionsPageLimit)
                    10
                  }
                  onPageChange={() => {}}
                />

                <StyledCardSearchPageInput
                  icon="search"
                  placeholder="Jump to page..."
                  name="activePage"
                  min={1}
                  onChange={() => {}}
                  onKeyDown={() => {}}
                  type="number"
                  value={
                    // inputPageNumber ? inputPageNumber : ""
                    ""
                  }
                />

                <StyledSecondaryDevicePerPageWidget>
                  <MenuItem>Metadata per page</MenuItem>
                  <MenuItem style={{ padding: "0px" }}>
                    <SelectDevicesPerPage
                      compact
                      selection
                      options={
                        // devicesPerPageOptions
                        [5, 10, 50, 100]
                      }
                      value={
                        // actionsPageLimit
                        5
                      }
                      onChange={() => {}}
                    />
                  </MenuItem>
                </StyledSecondaryDevicePerPageWidget>
              </div>
            )}
          </>
        ) : activeItem === "actions" ? (
          <>
            {/* <FlexContainer style={{ marginTop: "30px", marginBottom: "30px" }}>
              <StyledNonBoldHeader as="h3" style={{ marginTop: "5px" }}>
                Filter By
              </StyledNonBoldHeader>

              {selectedAuditFilterOptions.flatMap((selectedOption) => (
                <SelectedFilter
                  name={selectedOption.name}
                  icon={selectedOption.icon}
                  onClick={() => {
                    removeSelectedFilter(selectedOption);
                  }}
                />
              ))}

              <div style={{ position: "relative" }}>
                <ActionV3Button
                  onClick={() => {
                    setShowFilterDropdown(true);
                  }}
                  label={"Add"}
                  marginLeft={"20px"}
                  padding={"0.5rem 1rem"}
                  icon={"plus"}
                />

                {showFilterDropdown && (
                  <ActionFilterDropdown
                    dropdownOptions={auditFilterOptions}
                    setShowFilterDropdown={(value) =>
                      setShowFilterDropdown(value)
                    }
                    addFilter={(selectedItem) => {
                      addSelectedFilter(selectedItem);
                    }}
                  />
                )}
              </div>
            </FlexContainer> */}
            {!historicalActionLoading &&
              historicalActionPage.length > 0 &&
              permissions?.allowMarkActionAsCompleted && (
                <div
                  style={{
                    marginTop: "16px",
                    marginBottom: "26px",
                    display: "flex",
                    // flexDirection: "row",
                    justifyContent: "end",
                    gap: "16px",
                  }}
                >
                  <div>
                    <ConfirmationModal
                      prefixContent="Mark All Actions as Complete"
                      expectedText={"Mark All Actions as Complete"}
                      onConfirm={handleConfirmMarkAllComplete}
                      trigger={
                        <ActionV3Button
                          onClick={() => {}}
                          label={"Mark All Complete"}
                          marginLeft={"20px"}
                          padding={"0.5rem 1rem"}
                          icon={"check circle"}
                        />
                      }
                      message={
                        <ConfirmationModalMessageNonDelete
                          name={"Mark All Actions as Complete"}
                          expectedText={"Mark All Actions as Complete"}
                          type={""}
                          specialMessage=""
                        />
                      }
                    />
                  </div>
                </div>
              )}
            <Grid style={{ marginBottom: "20px" }}>
              <Grid.Row>
                <Grid.Column>
                  <AuditActionTable selectable>
                    <Table.Header>
                      <Table.Row textAlign={"center"}>
                        <Table.HeaderCell>ID</Table.HeaderCell>
                        <Table.HeaderCell>Action Type</Table.HeaderCell>
                        {/* <Table.HeaderCell>User</Table.HeaderCell> */}
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>Action Time</Table.HeaderCell>
                        <Table.HeaderCell width={4}>Errors</Table.HeaderCell>
                        {/* <Table.HeaderCell>Progress</Table.HeaderCell> */}
                        {/* <Table.HeaderCell>Operations</Table.HeaderCell> */}
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {!historicalActionLoading &&
                      historicalActionPage.length > 0 ? (
                        historicalActionPage.map((action, key) => (
                          <AuditActionTableRow
                            textAlign={"center"}
                            key={key}
                            // if selection is there then use below:
                            // className={selectedAction === action ? "selected" : ""}
                            // onClick={() => {}}
                          >
                            <Table.Cell>{action.action_id}</Table.Cell>
                            <Table.Cell>{action.type}</Table.Cell>
                            {/* <Table.Cell>
                              {action.user ? (
                                <Popup
                                  content={"mockuser@bytebeam.io"}
                                  position="top center"
                                  inverted
                                  trigger={<div>{action.user}</div>}
                                />
                              ) : (
                                <div>{action.user}</div>
                              )}
                            </Table.Cell> */}
                            <Table.Cell>{action.status}</Table.Cell>
                            <Table.Cell>
                              {new Date(action.updated_at)?.toLocaleString(
                                "en-GB"
                              )}
                            </Table.Cell>
                            <Table.Cell width={4}>{action.errors}</Table.Cell>
                            {/* <Table.Cell>
                              <Popup
                                content={
                                  <div
                                    style={{
                                      padding: "2rem",
                                      borderRadius: "5px",
                                      border:
                                        ThemeSchema.data[theme ?? "dark"]
                                          ?.colors["container-border"],
                                      // border and border-radius doesn't work as of now. Need to figure out a workaround
                                    }}
                                  >
                                    <ActionsHistoricalBarChart
                                      setQueuedListCount={10}
                                      setInitiatedListCount={15}
                                      setDownloadingListCount={3}
                                      setInstallingListCount={9}
                                      setSendingFileListCount={34}
                                      setTotalCount={71}
                                      height={200}
                                      width={500}
                                      textInfo
                                    />
                                  </div>
                                }
                                position="left center"
                                inverted
                                trigger={
                                  <Progress
                                    progress="percent"
                                    percent={
                                      // styleActionProgress(data?.status, data?.progress)[
                                      //   "progress"
                                      // ]
                                      80
                                    }
                                    indicating={
                                      // data?.status === "Completed" ||
                                      // data?.status === "Failed"
                                      //   ? false
                                      //   : true
                                      true
                                    }
                                    size="medium"
                                    style={{ marginBottom: "0" }}
                                    // className={`action-progress progress-margin ${
                                    //   styleActionProgress(data?.status, data?.progress)[
                                    //     "className"
                                    //   ]
                                    // }`}
                                  />
                                }
                              />
                            </Table.Cell> */}
                            {/* <Table.Cell>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <OperationsButtonIcon
                                  name="file alternate outline"
                                  title="Operations logs"
                                  onClick={() => {
                                    // todo routing from here
                                    console.log("Device Ops btn clicked");
                                  }}
                                />
                              </div>
                            </Table.Cell> */}
                          </AuditActionTableRow>
                        ))
                      ) : (
                        <Table.Row>
                          <Table.Cell colSpan={"7"}>
                            <div
                              style={{
                                height: "60vh",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {historicalActionLoading ? (
                                <LoadingAnimation
                                  loaderContainerHeight="65vh"
                                  fontSize="1.5rem"
                                  loadingText="Loading Historical Actions"
                                />
                              ) : (
                                <ErrorMessage
                                  marginTop="30px"
                                  message={"No actions found! "}
                                />
                              )}
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </Table.Body>
                  </AuditActionTable>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            {!loading &&
              !historicalActionLoading &&
              historicalActionPage?.length !== 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "nowrap",
                    gap: "16px",
                  }}
                >
                  <StyledPagination
                    boundaryRange={0}
                    ellipsisItem={null}
                    siblingRange={2}
                    activePage={historicalActionPageNumber}
                    totalPages={historicalActionTotalPage}
                    onPageChange={onHistoricalActionPageChange}
                  />

                  {/* <StyledCardSearchPageInput
                    icon="search"
                    placeholder="Jump to page..."
                    name="activePage"
                    min={1}
                    onChange={() => {}}
                    onKeyDown={() => {}}
                    type="number"
                    value={
                      // inputPageNumber ? inputPageNumber : ""
                      ""
                    }
                  /> */}
                  {/* 
                  <StyledSecondaryDevicePerPageWidget>
                    <MenuItem>Actions per page</MenuItem>
                    <MenuItem style={{ padding: "0px" }}>
                      <SelectDevicesPerPage
                        compact
                        selection
                        options={devicesPerPageOptions}
                        value={historicalActionPageLimit}
                        onChange={(e, data) => {
                          console.log(data.value);
                          let pageLimit = data?.value as number ?? 10;
                          console.log(pageLimit);
                          setHistoricalActionPageLimit(pageLimit);
                          onHistoricalActionPageChange(e, { activePage: 1 });
                        }}
                      />
                    </MenuItem>
                  </StyledSecondaryDevicePerPageWidget> */}
                </div>
              )}
          </>
        ) : (
          <div
            style={{
              height: "60vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ErrorMessage
              marginTop="30px"
              message={"No data found! Please try again."}
            />
          </div>
        )}
      </CardContainer>
    </DeviceOverviewContainer>
  ) : (
    <LoadingAnimation
      loaderContainerHeight="65vh"
      fontSize="1.5rem"
      loadingText="Loading device data"
    />
  );
}
