import React from "react";
import { OptionType } from "../util";
import SelectOrUploadConfigAccordion from "./SelectOrUploadConfigAccordion";
import { DropdownOptionType } from "../../DeviceMangaement/Devices/ActionModals/UpdateConfigModal";

type SelectOrUploadConfigProps = {
  setDisableNextButton: (arg0: boolean) => void;
  setOptionType: (arg0: OptionType) => void;
  optionType: OptionType;
  uploadTriggered: string;
  setUploadTriggered: (arg0: string) => void;
  uploadTriggeredEach: boolean;
  setActiveModal: (arg0: number) => void;
  activeModal: number;
  customInputJSONData: string;
  setCustomInputJSONData: (arg0: string) => void;
  configActiveIndex: number;
  setConfigActiveIndex: (arg0: number) => void;
  setSelectedConfigVersion: (arg0: DropdownOptionType) => void;
  selectedConfigVersion: DropdownOptionType;
  selectedCustomConfigVersion: string;
  setSelectedCustomConfigVersion: (arg0: string) => void;
  configJSONOptions: DropdownOptionType[];
  fillDropdownOptions: () => void;
  allConfigOptions: Set<any> | undefined;
  theme: string;
};

export default function SelectOrUploadConfig(props: SelectOrUploadConfigProps) {
  return (
    <SelectOrUploadConfigAccordion
      setDisableNextButton={(value) => props.setDisableNextButton(value)}
      setOptionType={(option) => props.setOptionType(option)}
      optionType={props.optionType}
      uploadTriggered={props.uploadTriggered}
      setUploadTriggered={(value) => props.setUploadTriggered(value)}
      setActiveModal={(index) => props.setActiveModal(index)}
      activeModal={props.activeModal}
      customInputJSONData={props.customInputJSONData}
      setCustomInputJSONData={(data) => props.setCustomInputJSONData(data)}
      uploadTriggeredEach={props.uploadTriggeredEach}
      activeIndex={props.configActiveIndex}
      setActiveIndex={(index) => props.setConfigActiveIndex(index)}
      selectedConfigVersion={props.selectedConfigVersion}
      setSelectedConfigVersion={(version) =>
        props.setSelectedConfigVersion(version)
      }
      selectedCustomConfigVersion={props.selectedCustomConfigVersion}
      setSelectedCustomConfigVersion={(version) =>
        props.setSelectedCustomConfigVersion(version)
      }
      configJSONOptions={props.configJSONOptions}
      allConfigOptions={props.allConfigOptions}
      fillDropdownOptions={() => props.fillDropdownOptions()}
      theme={props.theme}
    />
  );
}
