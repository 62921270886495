import React from "react";
import { copyContentToClipboard } from "../../util";
import styled from "styled-components";

const TextLabel = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  line-height: 18px;
  height: 24px !important;
  width: 100% !important;
  color: ${({ theme }) => theme.colors["tooltip_primary-text"]} !important;
`;

const ListTextLabel = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  line-height: 18px;
  height: 18px !important;
  width: 100% !important;
  color: ${({ theme }) => theme.colors["tooltip_list-text-color"]};
`;

const ToolTipText = styled.ul`
  position: absolute;
  top: -48px;
  left: -48px;
  visibility: hidden;
  width: fit-content;
  background-color: ${({ theme }) => theme.colors["tooltip-background"]};
  color: ${({ theme }) => theme.colors["tooltip_primary-text"]} !important;
  text-align: left;
  padding: 8px 12px;
  border-radius: 6px;
  white-space: nowrap;
  z-index: 1;
  list-style-type: none;

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 56px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors["tooltip-background"]}
      transparent transparent transparent;
  }
`;

const ToolTip = styled.span<{ notBold?: boolean }>`
  position: relative;
  display: inline-block;
  white-space: nowrap;
  font-weight: ${({ notBold }) => (notBold ? "normal" : "bolder")} !important;

  &:hover {
    ${ToolTipText} {
      visibility: visible;
    }
  }
`;

function toSliceText(text: string, length: number): [boolean, string] {
  let sliced = false,
    slicedText = text;

  if (typeof text === "string" || typeof text === "number") {
    slicedText = String(text);
    if (slicedText?.length > length) {
      slicedText = slicedText.substring(0, length);
      sliced = true;
    }
  }
  return [sliced, slicedText];
}

type TextWithToolTipType = {
  text: string;
  length: number;
  textList?: string[];
  notBold?: boolean;
};

function TextWithToolTip(props: TextWithToolTipType) {
  let { text, length, textList, notBold } = props;

  if (textList !== undefined && textList !== null) {
    textList = textList
      ?.filter((text) => text !== undefined && text !== null && text !== "")
      ?.map((text) => String(text).trim());
  }

  let [sliced, slicedText] = toSliceText(text, length);
  if (
    slicedText === undefined ||
    slicedText === null ||
    String(slicedText).trim() === ""
  ) {
    slicedText = "--";
  }

  let showTooltip: boolean =
    sliced || (textList !== undefined && textList?.length > 0);

  function adjustTooltipPosition() {
    let tooltipTopPosition = 64;
    if (textList !== undefined && textList?.length > 0) {
      tooltipTopPosition = tooltipTopPosition + textList?.length * 18;
      if (!sliced) {
        tooltipTopPosition = tooltipTopPosition - 24;
      }
    }
    return { top: `-${tooltipTopPosition}px` };
  }

  return (
    <ToolTip
      notBold={notBold}
      onClick={async () => await copyContentToClipboard(text)}
      className="tooltip"
      style={{ cursor: "pointer" }}
    >
      {sliced ? `${slicedText}...` : slicedText}
      {showTooltip && (
        <ToolTipText
          className="tooltipText"
          style={{
            top: adjustTooltipPosition()["top"],
          }}
        >
          {sliced && <TextLabel>{text}</TextLabel>}
          {textList !== undefined &&
            textList?.length > 0 &&
            textList?.map((item) => (
              <ListTextLabel key={item}>{item}</ListTextLabel>
            ))}
        </ToolTipText>
      )}
    </ToolTip>
  );
}

export default TextWithToolTip;
