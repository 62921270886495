import React, { Component } from "react";
import { Form, Button, Modal, Icon } from "semantic-ui-react";
import { uploadFile } from "../../../../../BytebeamClient";
import styled from "styled-components";
import AnimatedEllipsis from "../../../common/AnimatedEllipsis";

type SendFileModalProps = {
  isOpen: boolean;
  close: (...args: any[]) => any;
  triggerAction: (
    arg1: string,
    arg2: { id?: string; version?: string },
    ...args: any[]
  ) => any;
  allSelected: boolean;
  selectedDevicesCount: number;
};

type SendFileModalState = {
  status: number;
  file: File;
  fileName: string;
  isUploading: boolean;
  showUploadProgress: boolean;
  fileLoaded: number;
  fileTotal: number;
  uploadedFileResponse: {
    status: number;
    data: { id: string };
  };
};

export const StyledFileInput = styled.input`
  position: absolute !important;
  top: 0px !important;
  opacity: 0;
  cursor: pointer;
  padding: 6.5px 0px !important;
`;

export const StyledFileUploadButton = styled(Button)`
  position: relative;
  width: 100%;
  background: ${(props) =>
    props.theme.colors["select_file_button-background"]} !important;
  color: ${(props) =>
    props.theme.colors["select_file_button-color"]} !important;
  font-size: 15px !important;
  font-weight: 700 !important;
  text-align: center !important;
  border: ${(props) =>
    props.theme.colors["select_file_button-border"]} !important;
  border-radius: 4px;
  cursor: pointer;
`;

enum SendFileStatus {
  FileUpload,
  ConfirmSendFile,
}

export class SendFileModal extends Component<
  SendFileModalProps,
  SendFileModalState
> {
  state = {
    status: SendFileStatus.FileUpload,
    file: new File([""], "filename"),
    fileName: "",
    isUploading: false,
    showUploadProgress: false,
    fileLoaded: 0,
    fileTotal: 0,
    uploadedFileResponse: {
      status: 0,
      data: { id: "" },
    },
  };

  fileUpload = async (file: File, fileName: string) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);

    try {
      const url = `/api/v1/file`;
      const res = await uploadFile(url, formData, (p) => {
        this.setState({
          fileLoaded: p.loaded,
          fileTotal: p.total,
        });
      });
      this.setState({ uploadedFileResponse: res });
    } catch (error) {
      // Handling Error for File Size exceeding limit from NGINX
      if (String(error).includes("413"))
        window.toastr.error("Upload failed due to size limit!");
      else window.toastr.error("Failed to upload File");
    }
  };

  onSelect = (e) => {
    if (e.target.files.length !== 0) {
      this.setState({
        file: e.target.files[0],
        fileName: e.target.files[0].name,
        showUploadProgress: true,
      });
      this.fileUpload(e.target.files[0], e.target.files[0].name);
    }
  };

  triggerSendFileAction = async () => {
    try {
      if (this.state.uploadedFileResponse.status === 201) {
        await this.props.triggerAction("send_file", {
          id: this.state.uploadedFileResponse?.data.id,
        });
      }
    } catch (error) {
      window.toastr.error("Failed to upload File");
    }
  };

  renderFileUpload() {
    return (
      <Form>
        <Form.Field>
          <label>Upload File from the system: </label>
          <div style={{ position: "relative", marginTop: "12px" }}>
            <StyledFileUploadButton
              fluid
              content="Select File"
              labelPosition="left"
              icon="file"
            />
            <StyledFileInput type="file" id="file" onChange={this.onSelect} />
          </div>
          <label style={{ marginTop: "12px" }}>File Chosen: </label>
          {this.state.fileName ? this.state.fileName : "No File Chosen"}
        </Form.Field>

        {this.state.showUploadProgress && (
          <Form.Field>
            <label htmlFor="file-progress">
              {this.state.uploadedFileResponse.status === 0 ? (
                <span>
                  File Uploading
                  <AnimatedEllipsis spacing={3} dotSize={"8px"} />
                </span>
              ) : (
                <span>File Uploaded</span>
              )}
            </label>
            <progress
              id="file-progress"
              max={this.state.fileTotal}
              value={this.state.fileLoaded}
            />
          </Form.Field>
        )}
      </Form>
    );
  }

  renderConfirmSendFile() {
    return (
      <Modal.Description>
        <div style={{ fontSize: "16px", marginBottom: "16px" }}>
          Are you ready to send <b>{this.state.fileName}</b> file to{" "}
          <b>
            {this.props.allSelected
              ? // ? `All (${this.props.selectedDevicesCount} devices)` TODO: Fix this once device count on search is available
                `All devices`
              : `${
                  this.props.selectedDevicesCount === 1
                    ? "1 device"
                    : `${this.props.selectedDevicesCount} devices`
                }`}
          </b>{" "}
          ?
        </div>
        This action will send file to all selected devices. Please click on{" "}
        <b>Send</b> button if you would like to proceed, else click on{" "}
        <b>Discard</b> button.
      </Modal.Description>
    );
  }

  renderContents() {
    switch (this.state.status) {
      case SendFileStatus.FileUpload:
        return this.renderFileUpload();

      case SendFileStatus.ConfirmSendFile:
        return this.renderConfirmSendFile();

      default:
        return this.renderFileUpload();
    }
  }

  // Reset state and close modal
  onCloseModal = () => {
    this.setState({
      status: SendFileStatus.FileUpload,
      file: new File([""], "filename"),
      fileName: "",
      isUploading: false,
      showUploadProgress: false,
      fileLoaded: 0,
      fileTotal: 0,
      uploadedFileResponse: {
        status: 0,
        data: { id: "" },
      },
    });
    this.props.close();
  };

  render() {
    const { isOpen } = this.props;
    // Next button is disabled if file is not uploaded
    const disableNextButton = this.state.uploadedFileResponse.status !== 201;

    return (
      <Modal
        open={isOpen}
        onClose={this.onCloseModal}
        size="tiny"
        className="dark"
      >
        <Modal.Header>Send File</Modal.Header>
        <Modal.Content>{this.renderContents()}</Modal.Content>
        <Modal.Actions>
          <Button
            secondary
            onClick={() => {
              this.onCloseModal();
              this.setState({ status: SendFileStatus.FileUpload });
            }}
          >
            <Icon name="remove" /> Discard
          </Button>
          <Button
            disabled={disableNextButton}
            primary
            onClick={() => {
              if (this.state.status === SendFileStatus.FileUpload) {
                this.setState({ status: SendFileStatus.ConfirmSendFile });
              }
              if (this.state.status === SendFileStatus.ConfirmSendFile) {
                this.onCloseModal();
                this.triggerSendFileAction();
              }
            }}
          >
            {this.state.status === SendFileStatus.FileUpload ? (
              <>
                Next <Icon name="angle right" />
              </>
            ) : (
              <>
                <Icon name="checkmark" /> Send
              </>
            )}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
