import React, { useState } from "react";
import { Button, Header, Image } from "semantic-ui-react";
import ContactUs from "../ContactUs/ContactUs";
import styled from "styled-components";
import PlaneGraphicSVG from "../../common/PlaneGraphic";
import WelcomeCarousel from "./WelcomeCarousel";
import { useUser } from "../../../context/User.context";
import NewProjectModal from "../NewProjectModal";
import ByteBeamLogoSVG from "../../common/ByteBeamLogoSVG";
import { breakpoints } from "../../common/breakpoints";

const { Subheader } = Header;

const PageWrapper = styled.div<{ type: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors["background-color"]};
  min-height: calc(
    100vh - ${56.5 + 80}px
  ); // 56.5px is the height of the navbar and 80px is the top and bottom padding of .app
  height: calc(100vh - ${56.5 + 80}px);

  @media (max-width: ${breakpoints.md}px) {
    flex-direction: column-reverse !important;
    height: fit-content !important;
  }

  @media (max-width: ${breakpoints.xs}px) {
    justify-content: flex-end !important;
  }
`;

const WelcomeFormWrapper = styled.div`
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  height: 100%;
  width: 100%;

  @media (max-width: ${breakpoints.md}px) {
    flex-direction: column-reverse !important;
    height: fit-content !important;
  }
`;

const WelcomeItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: calc(50% - 1px);

  @media (max-width: ${breakpoints.md}px) {
    width: 100%;

    // TODO: Remove this when the carousel is responsive
    &.carousel-item-wrapper {
      display: none !important;
    }
  }
`;

const WelcomeItemDivider = styled.div`
  align-self: center;
  height: calc(100% - 200px);
  width: 2px;
  background-color: ${({ theme }) => theme.colors["welcome-divider"]};

  @media (max-width: ${breakpoints.md}px) {
    display: none;
  }
`;

const ButtonWrapper = styled.div`
  margin: 24px 0;
`;

const PlaneGraphicWrapper = styled.div<{ type: string }>`
  position: absolute;
  bottom: 32px;
  right: 18px;

  @media (max-width: ${breakpoints.md}px) {
    display: none;
  }
`;

const LoginComponentWrapper = styled.div`
  padding: 1.5rem;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors["navbar-background"]};
  z-index: 1;

  @media (max-width: ${breakpoints.md}px) {
    margin: 0px 16px;
  }
`;

const StyledHeader = styled(Header)`
  color: ${({ theme }) => theme.colors["welcome-header-text"]};
`;

export const StyledSubHeader = styled(Subheader)`
  color: ${({ theme }) => theme.colors["welcome-header-text"]};
  background-color: transparent !important;
`;

const WelcomeWrapper = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
  }

  @media (max-width: ${breakpoints.xs}px) {
    margin-top: 5rem;
  }
`;

const authColors = {
  google: "#FFFFFF",
  linkedin: "#0A66C2",
  microsoft: "#2f2f2f",
  github: "#1B1F23",
};

const StyledLink = styled.a<{
  borderStyle?: "solid" | "dashed" | "dotted";
}>`
  color: #407bff;
  border-bottom: 1px ${(props) => props.borderStyle ?? "dashed"} #1158e1;
  padding-bottom: 2px;
  margin: 0px 4px;
  cursor: pointer;
`;

const WelcomeButtonWrapper = styled.div`
  margin-top: 3rem;
  width: 200px;
`;

const WelcomeButtonDivider = styled.div`
  margin: 2rem 0;
  text-align: center;
  font-weight: 700;
`;

const StyledWelcomeButton = styled(Button)<{ type: string }>`
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  border-radius: 40px !important;
  padding: 12px 0 !important;
  margin-bottom: 0px !important;
  border: 2px solid #407bff !important;
  box-shadow: ${({ theme }) => theme.colors["container-box_shadow"]} !important;
  background: ${(props) =>
    props.type === "filled"
      ? "#407bff"
      : props.theme.colors["background-color"]} !important;
  color: ${(props) =>
    props.type === "filled"
      ? "#ffffff"
      : props.theme.colors["welcome-header-text"]} !important;
  font-size: 16px !important;
  font-weight: 700;
`;

const CopyrightFooter = styled.div`
  position: absolute;
  bottom: 16px;
  color: ${(props) => props.theme.colors["copyright-footer-color"]};
`;

type LoginFormProps = {
  type: string;
  tryDemo?: () => void;
  setCurrentTenant?: (tenant: string) => void;
};

function LoginForm(props: LoginFormProps) {
  const { user } = useUser();
  const theme = user?.settings?.theme;

  const [showNewProjectModal, setShowNewProjectModal] = useState(false);

  const StyledButton = styled(Button)<{ auth: string }>`
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    padding: 12px 0 !important;
    margin-bottom: 20px !important;
    border: ${({ theme }) => theme.colors["login-button-border"]} !important;
    box-shadow: ${({ theme }) =>
      theme.colors["container-box_shadow"]} !important;
    background: ${(props) =>
      theme === "light" ? "transparent" : authColors[props.auth]} !important;
    color: ${(props) =>
      theme === "light"
        ? "#000000"
        : props.text_color === "light"
        ? "#ffffff"
        : "#555555"} !important;
    font-size: 1.4rem;
    font-weight: 500;
    img {
      height: 2rem;
    }
  `;

  return (
    <>
      {showNewProjectModal && props.setCurrentTenant && (
        <NewProjectModal
          isOpen={showNewProjectModal}
          setCurrentTenant={(selectedTenant) => {
            if (props.setCurrentTenant) props.setCurrentTenant(selectedTenant);
          }}
          onClose={() => setShowNewProjectModal(false)}
        />
      )}

      <PageWrapper type={props.type}>
        <WelcomeFormWrapper>
          <WelcomeItemWrapper className="carousel-item-wrapper">
            <WelcomeCarousel />
          </WelcomeItemWrapper>

          <WelcomeItemDivider />

          <WelcomeItemWrapper>
            {props.type === "login" ? (
              <LoginComponentWrapper>
                <ByteBeamLogoSVG height="40px" />
                <StyledHeader as="h1" style={{ marginBottom: "4px" }}>
                  Login to Bytebeam Cloud
                </StyledHeader>

                <ButtonWrapper>
                  <StyledButton
                    text_color="dark"
                    auth={"google"}
                    onClick={() => {
                      window.location.href = "/oauth2/google";
                    }}
                  >
                    <Image src="/assets/google-logo.svg" />
                    <p>Continue with Google</p>
                  </StyledButton>

                  <StyledButton
                    text_color="light"
                    auth={"linkedin"}
                    onClick={() => {
                      window.location.href = "/oauth2/linkedin";
                    }}
                  >
                    <Image
                      src={
                        theme === "light"
                          ? "/assets/linkedin-logo-light.svg"
                          : "/assets/linkedin-logo.svg"
                      }
                    />
                    <p>Continue with Linkedin</p>
                  </StyledButton>

                  <StyledButton
                    text_color="light"
                    auth={"microsoft"}
                    onClick={() => {
                      window.location.href = "/oauth2/microsoft";
                    }}
                  >
                    <Image src="/assets/microsoft-logo.svg" />
                    <p>Continue with Microsoft</p>
                  </StyledButton>

                  <StyledButton
                    text_color="light"
                    auth={"github"}
                    onClick={() => {
                      window.location.href = "/oauth2/github";
                    }}
                  >
                    <Image
                      src={
                        theme === "light"
                          ? "/assets/github-logo-light.svg"
                          : "/assets/github-logo.svg"
                      }
                    />
                    <p>Continue with Github</p>
                  </StyledButton>
                </ButtonWrapper>

                <StyledSubHeader>
                  By signing up, you agree to our{" "}
                  <StyledLink href="https://bytebeam.io/terms" target="_blank">
                    Terms of Service
                  </StyledLink>
                  and{" "}
                  <StyledLink
                    style={{ margin: "0px" }}
                    href="https://bytebeam.io/privacy"
                    target="_blank"
                  >
                    Privacy Policy.
                  </StyledLink>
                </StyledSubHeader>

                <StyledSubHeader
                  style={{
                    marginTop: "12px",
                  }}
                >
                  Facing any issue?
                  <ContactUs
                    trigger={
                      <StyledLink borderStyle="solid">
                        Contact the Team
                      </StyledLink>
                    }
                  />
                </StyledSubHeader>
              </LoginComponentWrapper>
            ) : (
              <WelcomeWrapper>
                <StyledHeader as="h1" style={{ marginBottom: "10px" }}>
                  Hello {user.name},
                </StyledHeader>
                <StyledSubHeader
                  style={{
                    fontSize: "1.2rem",
                  }}
                >
                  Welcome to Bytebeam Cloud<br></br> How would you like to start
                  your session?
                </StyledSubHeader>

                <WelcomeButtonWrapper>
                  <StyledWelcomeButton
                    type="filled"
                    onClick={() => {
                      setShowNewProjectModal(true);
                    }}
                  >
                    <p>Create a New Project</p>
                  </StyledWelcomeButton>

                  <WelcomeButtonDivider>
                    <p>OR</p>
                  </WelcomeButtonDivider>

                  <StyledWelcomeButton
                    type="outline"
                    onClick={() => {
                      if (props.tryDemo) props.tryDemo();
                    }}
                  >
                    <p>Try Demo</p>
                  </StyledWelcomeButton>
                </WelcomeButtonWrapper>
              </WelcomeWrapper>
            )}
          </WelcomeItemWrapper>
        </WelcomeFormWrapper>

        <PlaneGraphicWrapper type={props.type}>
          <PlaneGraphicSVG height="300px" />
        </PlaneGraphicWrapper>

        <CopyrightFooter className="copyright-footer">
          Copyright © 2024 IoTExpress Technologies Pvt. Ltd. All rights reserved
        </CopyrightFooter>
      </PageWrapper>
    </>
  );
}

export default LoginForm;
