import React, { memo, useState, useCallback, useEffect } from "react";
import { Dropdown, MenuItem } from "semantic-ui-react";
import { SelectDevicesPerPage } from "../../DeviceMangaement/Devices/Devices";
import DeviceListV3 from "./DeviceListV3";
import { StyledHeader } from "./SelectableItem";
import { CardContainer } from "./LiveActions";
import {
  ActionStatusDetail,
  devicesPerPageOptions,
  fetchAction,
  fetchDeviceActionStatusDetailsWithPhase,
} from "../../../../BytebeamClient";
import { romanToDecimal } from "../../../../helpers/helpers";
import { Permission, validateWholeNumber } from "../../../../util";
import { useUser } from "../../../../context/User.context";
import { ThinDivider } from "../../Dashboards/Panel/util";
import styled from "styled-components";
import ActionButton from "./ActionButton";
import { capitalizeFirstLetter } from "../../util";
import ThemeSchema from "../../../../theme/schema";
import {
  StyledCardSearchPageInput,
  StyledPagination,
  StyledSecondaryDevicePerPageWidget,
} from "../../../common/commonStyledComps";

const SelectedDevicesDiv = styled.div`
  padding: 20px 30px;
  font-size: 16px;
  display: flex;
  align-items: center;
  border-radius: 6px;
`;

const DevicesMoreOptionsDropDown = styled(Dropdown)`
  && {
    padding: 10px 20px !important;
    margin-left: 30px;
    background-color: transparent !important;
    border: 1px solid
      ${({ theme }) => theme.colors["devices_list-action-button-border-color"]} !important;
    color: ${({ theme }) => theme.colors["text"]} !important;
  }
`;

type DeviceListViewProps = {
  readonly actionID: string;
  setSelectedAction: (action: any) => void;
  selectedPhase: string;
  phasedData: any;
};

const DeviceListView = (props: DeviceListViewProps) => {
  const { actionID, setSelectedAction, selectedPhase } = props;
  const [devicesLoading, setDevicesLoading] = useState<boolean>(true);
  const [deviceItems, setDeviceItems] = useState<ActionStatusDetail[]>([]);
  const [devicesPageNumber, setDevicesPageNumber] = useState(1);
  const [inputPageNumber, setInputPageNumber] = useState(0);
  const [numTotal, setNumTotal] = useState<number>(0);
  const [devicesPageLimit, setDevicesPageLimit] = useState<number>(10);
  const [selectedDevices, setSelectedDevices] = useState(new Set<number>());
  const [selectedDeviceCount, setSelectedDeviceCount] = useState<number>(0);

  const devicesMoreOptions = [
    {
      type: "option 1",
    },
    {
      type: "option 2",
    },
    {
      type: "option 3",
    },
  ];

  const handleCheckboxClick = (deviceId: number) => {
    setSelectedDevices((prevSelectedDevices) => {
      const newSelectedDevices = new Set(prevSelectedDevices);
      if (newSelectedDevices.has(deviceId)) {
        newSelectedDevices.delete(deviceId);
      } else {
        newSelectedDevices.add(deviceId);
      }
      setSelectedDeviceCount(newSelectedDevices.size);
      return newSelectedDevices;
    });
  };

  const { user } = useUser();

  const theme = user?.settings?.theme;
  const permissions: Permission = user?.role?.permissions;

  const allowedActions = permissions.allowedActions || [];

  const changeDevicesPerPage = useCallback(
    async (e, data) => {
      try {
        setDevicesLoading(true);
        setDevicesPageLimit(data.value);
        setDevicesPageNumber(1);
        window.localStorage.setItem("devicesListPerPage", data.value);
        await fetchStatusDetails(1, data.value, "all");
      } catch (e) {
        window.toastr.error("Failed to change the number of devices per page");
        console.error("Error in changeDeviceStatus: ", e);
      }
    },
    [devicesPageLimit, devicesPageNumber] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handlePaginationInputChange = useCallback(
    (event) => {
      const newValue = event.target.value;
      setInputPageNumber(newValue);
    },
    [inputPageNumber] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handlePaginationInputKeyDown = (event) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      // If the pressed key is "Enter", trigger the function for changing active page
      if (validateWholeNumber(inputPageNumber.toString())) {
        console.log("success");
        onPaginationChange(event, {
          activePage:
            inputPageNumber && inputPageNumber > 0
              ? inputPageNumber > Math.ceil(numTotal / devicesPageLimit)
                ? Math.ceil(numTotal / devicesPageLimit)
                : inputPageNumber
              : 1,
        });
        setInputPageNumber(0);
      } else {
        window.toastr.error("Please enter whole number for jump to page.");
      }
    }
  };

  const fetchActionData = async () => {
    try {
      const data = await fetchAction(Number(actionID));
      setSelectedAction(data);
      const numTotal = Object.values(data.statuses ?? {}).reduce(
        (a, b) => a + b,
        0
      );
      setNumTotal(numTotal);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchStatusDetails = useCallback(
    async (devicesPageNumber, devicesPageLimit, status: string | string[]) => {
      let selectedPhaseParts = selectedPhase.split(" ");
      const romanPart = selectedPhaseParts[selectedPhaseParts.length - 1];
      const decimalPhase = romanToDecimal(romanPart);
      const selectedPhaseIndex = decimalPhase - 1;
      try {
        const res = await fetchDeviceActionStatusDetailsWithPhase(
          parseInt(actionID),
          status,
          devicesPageNumber,
          devicesPageLimit,
          selectedPhaseIndex
        );
        setDeviceItems(res.device_actions);
        setDevicesLoading(false);
      } catch (e) {
        console.log(e);
      }
    },
    [actionID, selectedPhase, devicesPageNumber, devicesPageLimit] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onPaginationChange = useCallback(
    (e, { activePage }) => {
      setDevicesLoading(true);
      setDevicesPageNumber(activePage);
      fetchStatusDetails(activePage, devicesPageLimit, "all");
    },
    [numTotal, devicesPageLimit, selectedPhase] // eslint-disable-line react-hooks/exhaustive-deps
  );

  // const fetchFilteredStatusDetails = async (status: string | string[]) => {
  //   setDevicesLoading(true);
  //   setDevicesPageNumber(1);
  //   fetchActionStatusDetails(actionID, status, 1, devicesPageLimit)
  //     .then((res) => {
  //       setDeviceItems(res.device_actions);
  //       fetchActionStatusDetails(actionID, status, 1, 10000) // need list without pagination so applying this hack :(
  //         .then((res) => {
  //           setNumTotal(res.device_actions.length);
  //           setDevicesLoading(false);
  //         })
  //         .catch((e) => {
  //           console.log(e);
  //         });
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  useEffect(() => {
    // To scroll to top of the screen
    window.scrollTo(0, 0);

    const devicesCountPerPage = parseInt(
      window.localStorage.getItem("devicesListPerPage") ?? "10"
    );
    setDevicesPageLimit(devicesCountPerPage);
    fetchStatusDetails(devicesPageNumber, devicesCountPerPage, "all");
    fetchActionData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // To scroll to top of the screen

    const devicesCountPerPage = parseInt(
      window.localStorage.getItem("devicesListPerPage") ?? "10"
    );
    setDevicesPageLimit(devicesCountPerPage);
    fetchStatusDetails(devicesPageNumber, devicesCountPerPage, "all");
    fetchActionData();
  }, [selectedPhase]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CardContainer>
      <StyledHeader as="h2" style={{ marginBottom: "30px" }}>
        Devices List
      </StyledHeader>

      {selectedDeviceCount > 0 ? (
        <>
          <SelectedDevicesDiv>
            {selectedDeviceCount} selected
            <ActionButton
              onClick={() => {
                console.log("Cancel action clicked");
              }}
              icon={"close"}
              label={"Cancel Action"}
              marginLeft={"30px"}
              borderColor={`${
                ThemeSchema.data[theme ?? "dark"]?.colors[
                  "devices_list-action-button-border-color"
                ]
              }`}
              borderRadius="4px"
              padding={"0.3rem 1.6rem"}
            />
            <ActionButton
              onClick={() => {
                console.log("Retry action clicked");
              }}
              icon={"redo"}
              label={"Retry Action"}
              marginLeft={"30px"}
              borderColor={`${
                ThemeSchema.data[theme ?? "dark"]?.colors[
                  "devices_list-action-button-border-color"
                ]
              }`}
              borderRadius="4px"
              padding={"0.3rem 1.6rem"}
            />
            <DevicesMoreOptionsDropDown
              text="More Options"
              icon={"plus"}
              button
              labeled
              className="icon"
            >
              <Dropdown.Menu>
                {devicesMoreOptions.map((devicesMoreOptions) => (
                  <Dropdown.Item
                    key={devicesMoreOptions.type}
                    content={capitalizeFirstLetter(devicesMoreOptions.type)}
                    onClick={() =>
                      console.log("clicked: ", devicesMoreOptions.type)
                    }
                  />
                ))}
              </Dropdown.Menu>
            </DevicesMoreOptionsDropDown>
          </SelectedDevicesDiv>
          <ThinDivider />
        </>
      ) : null}

      <DeviceListV3
        selectedDevices={selectedDevices}
        handleCheckboxClick={handleCheckboxClick}
        allowedActions={allowedActions}
        deviceData={deviceItems}
        actionID={actionID}
        loading={devicesLoading}
      />
      {!devicesLoading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "nowrap",
            gap: "16px",
          }}
        >
          <StyledPagination
            boundaryRange={0}
            defaultActivePage={devicesPageNumber}
            ellipsisItem={null}
            siblingRange={2}
            totalPages={Math.ceil(numTotal / devicesPageLimit)}
            onPageChange={onPaginationChange}
          />
          <StyledCardSearchPageInput
            icon="search"
            placeholder="Jump to page..."
            name="activePage"
            min={1}
            onChange={handlePaginationInputChange}
            onKeyDown={handlePaginationInputKeyDown}
            type="number"
            value={inputPageNumber ? inputPageNumber : ""}
          />

          <StyledSecondaryDevicePerPageWidget>
            <MenuItem>Devices per page</MenuItem>
            <MenuItem style={{ padding: "0px" }}>
              <SelectDevicesPerPage
                compact
                selection
                options={devicesPerPageOptions}
                value={devicesPageLimit}
                onChange={changeDevicesPerPage}
              />
            </MenuItem>
          </StyledSecondaryDevicePerPageWidget>
        </div>
      )}
    </CardContainer>
  );
};

export default memo(DeviceListView);
