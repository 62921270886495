import React, { useState, useMemo } from "react";
import { Button, Icon, Table } from "semantic-ui-react";
import CreateOrEditUserModal from "./CreateOrEditUserModal";
import { Role, IUser, IUserResult } from "../../../../util";
import useAsyncEffect from "../../common/useAsyncEffect";
import Layout from "../../common/Layout";
import { ButtonIcon, DisplayIf } from "../../util";
import { ErrorMessage } from "../../../common/ErrorMessage";
import {
  fetchAllUsers,
  deleteUser,
  fetchAllRoles,
  createUser,
  editUser,
} from "../../../../BytebeamClient";
import ConfirmationModalMessage from "../../common/ConfirmationModalMessage";
import ConfirmationModal from "../../common/ConfirmationModal";
import { Mixpanel } from "../../common/MixPanel";
import LoadingAnimation from "../../../common/Loader";

interface CreateUserButtonProps {
  onUpdate: () => void;
  roles: Role[];
  users: IUser[];
}

function CreateUserButton(props: CreateUserButtonProps) {
  async function handleSubmit(user) {
    try {
      await createUser(user);
      Mixpanel.track("Created User", {
        userName: user.name,
        userEmail: user.email,
      });
      props.onUpdate();
    } catch (e) {
      Mixpanel.track("Failure", {
        type: "user creation",
        error: JSON.stringify(e),
      });
      console.log(e);
    }
  }

  return (
    <CreateOrEditUserModal
      title="Create User"
      onSubmit={async (user) => await handleSubmit(user)}
      user={{
        name: "",
        email: "",
        roles: [1],
      }}
      roles={props.roles}
      users={props.users}
      trigger={
        <Button primary floated="right" icon labelPosition="left">
          <Icon name="plus" />
          Create User
        </Button>
      }
    />
  );
}

interface EditUserButtonProps {
  userId: string;
  user: IUser;
  onUpdate: () => void;
  roles: Role[];
  users: IUser[];
}

function EditUserButton(props: EditUserButtonProps) {
  async function handleSubmit(user) {
    try {
      await editUser(props.userId, user);
      Mixpanel.track("Edited User", {
        userName: user.name,
        userEmail: user.email,
      });
      props.onUpdate();
    } catch (e) {
      Mixpanel.track("Failure", {
        type: "user editing",
        error: JSON.stringify(e),
      });
      console.log(e);
    }
  }

  return (
    <CreateOrEditUserModal
      title="Edit User"
      onSubmit={async (user) => await handleSubmit(user)}
      user={props.user}
      roles={props.roles}
      users={props.users}
      trigger={<ButtonIcon link name="pencil" />}
    />
  );
}

export default function Users({ user }) {
  const [users, setUsers] = useState<IUserResult>({ result: {} });
  const [roles, setRoles] = useState<Role[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [errorOccured, setErrorOccured] = useState<boolean>(false);
  const permissions = user.role.permissions;

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const res = await fetchAllUsers();
      setUsers(res);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setErrorOccured(true);
    }
  };

  const handleDelete = async (userId) => {
    try {
      await deleteUser(userId);
      handleUpdate();
    } catch (e) {
      console.log(e);
    }
  };

  useAsyncEffect(async () => {
    document.title = "Users | Bytebeam";
    try {
      const res = await fetchAllRoles();
      setRoles(res);
    } catch (e) {
      console.log(e);
      setErrorOccured(true);
    }
  }, []);

  useAsyncEffect(handleUpdate, []);

  const roleNameMap = useMemo(() => {
    const map = {};
    for (const role of roles) {
      map[role.id] = role.name;
    }
    return map;
  }, [roles]);

  const lookUpRole = (id: number) => {
    if (roleNameMap[id]) {
      return roleNameMap[id];
    } else {
      return "Unknown Role";
    }
  };

  if (errorOccured) {
    return <ErrorMessage marginTop="270px" errorMessage />;
  }

  if (loading) {
    return (
      <LoadingAnimation
        loaderContainerHeight="65vh"
        fontSize="1.5rem"
        loadingText="Loading users"
      />
    );
  }

  return (
    <Layout
      buttons={
        <DisplayIf cond={permissions.editUsers}>
          <CreateUserButton
            onUpdate={handleUpdate}
            roles={roles}
            users={Object.values(users.result)}
          />
        </DisplayIf>
      }
    >
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={4}>Id</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <DisplayIf cond={permissions.viewRoles}>
              <Table.HeaderCell>Role</Table.HeaderCell>
            </DisplayIf>

            <DisplayIf cond={permissions.editUsers}>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </DisplayIf>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {Object.entries(users.result).length !== 0 ? (
            Object.entries(users.result).map(([userId, user]) => (
              <Table.Row key={userId}>
                <Table.Cell>{userId}</Table.Cell>
                <Table.Cell>{user.name}</Table.Cell>
                <Table.Cell>{user.email}</Table.Cell>
                <DisplayIf cond={permissions.viewRoles}>
                  <Table.Cell>{lookUpRole(user.roles[0])}</Table.Cell>
                </DisplayIf>
                <DisplayIf cond={permissions.editUsers}>
                  <Table.Cell>
                    <EditUserButton
                      userId={userId}
                      user={user}
                      onUpdate={handleUpdate}
                      roles={roles}
                      users={Object.values(users.result)}
                    />
                    <ConfirmationModal
                      prefixContent="Delete User"
                      expectedText={user.name}
                      onConfirm={() => handleDelete(userId)}
                      trigger={<ButtonIcon link name="trash" />}
                      message={
                        <ConfirmationModalMessage
                          name={user.name}
                          type={"User"}
                          specialMessage=""
                        />
                      }
                    />
                  </Table.Cell>
                </DisplayIf>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell
                colspan={`${
                  permissions.viewRoles && permissions.editUsers
                    ? "5"
                    : permissions.viewRoles || permissions.editUsers
                    ? "4"
                    : "3"
                }`}
              >
                <ErrorMessage marginTop="30px" message={"No Users found!"} />
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Layout>
  );
}
