import { User } from "../../../util";
import { DashboardMeta } from "./EditDashboardModal";
import { getTenantFromURL } from "../../../BytebeamClient";

export function canEditDashboard(dashboard: DashboardMeta, user: User): boolean {
  const roleId = `role:${user.role.id}`;
  const userId = `user:${user.id}`;
  const owners = dashboard.owners || [];
  const tenant = getTenantFromURL();

  return owners.indexOf(roleId) !== -1 || owners.indexOf(userId) !== -1 || user.role.name === "admin" || tenant === "demo";
}

export function canDeleteDashboard(dashboard: DashboardMeta, user: User): boolean {
  return canEditDashboard(dashboard, user);
}

export function canViewDashboard(dashboard: DashboardMeta, user: User): boolean {
  const roleId = `role:${user.role.id}`;
  const userId = `user:${user.id}`;
  const viewers = dashboard.viewers || [];
  const tenant = getTenantFromURL();

  return canEditDashboard(dashboard, user) || 
    viewers.indexOf(roleId) !== -1 ||
    viewers.indexOf(userId) !== -1 ||
    user.role.name === "admin" ||
    tenant === "demo";
}

export function canCreateDashboard(user: User): boolean {
  return user.role.permissions.createDashboards || false;
}

export function isUserAdmin(user: User): boolean {
  return user.role.name === 'admin';
}

export const refreshIntervalOptions = [
  {
    key: 'never',
    value: -1,
    text: 'never'
  },
  // {
  //   key: '1s',
  //   value: 1,
  //   text: '1s',
  // },
  {
    key: '5s',
    value: 5,
    text: '5s',
  },
  {
    key: '15s',
    value: 15,
    text: '15s',
  },
  {
    key: '30s',
    value: 30,
    text: '30s',
  },
  {
    key: '45s',
    value: 45,
    text: '45s',
  },
  {
    key: '1m',
    value: 60,
    text: '1m',
  },
  {
    key: '5m',
    value: 300,
    text: '5m',
  },
]

export const queryOperators = [
  {
    key: 'equals',
    value: '=',
    text: 'equals'
  },
  {
    key: 'not equal to',
    value: 'not=',
    text: 'not equal to'
  },
  {
    key: '>',
    value: '>',
    text: '>'
  },
  {
    key: '<',
    value: '<',
    text: '<'
  },
  {
    key: '>=',
    value: '>=',
    text: '>='
  },
  {
    key: '<=',
    value: '<=',
    text: '<='
  },
  // {
  //   key: 'IN',
  //   value: 'IN',
  //   text: 'IN'
  // },
  // {
  //   key: 'NOT IN',
  //   value: 'NOT IN',
  //   text: 'NOT IN'
  // },
  // {
  //   key: 'LIKE',
  //   value: 'LIKE',
  //   text: 'LIKE'
  // },
  // {
  //   key: 'IS NOT NULL',
  //   value: 'IS NOT NULL',
  //   text: 'IS NOT NULL'
  // },
  // {
  //   key: 'IS NULL',
  //   value: 'IS NULL',
  //   text: 'IS NULL'
  // }
];

