import uuid from "uuid";
import { PanelDef } from "../PanelDef";
import { ViewDeviceStatus } from "./ViewDeviceStatus";
import { EditDeviceStatusMeta } from "./EditDeviceStatusMeta";

export class DeviceStatusMetaData {
  type = "device_status";
  id: string = uuid.v4();
  title: string = "";
}

export type DeviceStatus = {
  id: string;
  status: string;
  timestamp: number;
}

export type DeviceStatusData = {
  data: DeviceStatus[]
};

export const DeviceStatusPanelDef: PanelDef<DeviceStatusMetaData, DeviceStatusData> =
  {
    ViewComponent: ViewDeviceStatus,
    EditComponent: EditDeviceStatusMeta,
    formatData: (data) => data,
    metaConstructor: DeviceStatusMetaData,
    previewAspectRatio: { width: 500, height: 300 },
  };
