import React from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

type SelectedFilterProps = {
  id?: number;
  name: string;
  icon: any;
  onClick: () => void;
};

const SelectedFilterWrapper = styled.div`
  cursor: pointer;
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.colors["add-button-border-color"]};
  border-radius: 17px;
  color: ${(props) => props.theme.colors["text"]};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  /* margin-bottom: 10px; */
  height: 35px;

  &:hover #icon {
    transform: scale(1.2);
  }
`;

const FilterCrossButton = styled.div`
  cursor: pointer;
  background-color: ${(props) => props.theme.colors["add-button-border-color"]};
  margin-left: 1.5em;
  padding: 0 0.5rem;
  height: 100%;
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;
  color: #1c1b1f;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconContainer = styled.div`
  transition: 0.15s ease all;
`;

export default function SelectedFilter(props: SelectedFilterProps) {
  return (
    <SelectedFilterWrapper onClick={() => props.onClick()}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "0em 0em 0em 1em",
        }}
      >
        {typeof props.icon !== "object" ? (
          <Icon
            name={props.icon}
            style={{ marginRight: "10px", marginBottom: "5px" }}
          />
        ) : (
          props.icon
        )}
        {props.name}
      </div>
      <FilterCrossButton>
        <IconContainer id="icon">
          <Icon name="close" />
        </IconContainer>
      </FilterCrossButton>
    </SelectedFilterWrapper>
  );
}
