import { LastValueColumn, LastValueMetaData } from "./PanelDef";
import React from "react";

import { Icon, Tab, Table, Accordion } from "semantic-ui-react";
import { QuerySelector, queryToObject } from "../../../common/QuerySelector";
import RelativeTimePicker from "../../Datetime/RelativeTimePicker";
import { PanelEditComponent, PartialMetaData } from "../PanelDef";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  EditMetaRoot,
  EditMetaRow,
  EditMetaDropdown,
  DeleteIconInPanel,
  EditAnimatedMetaInput,
  EditAnimatedMetaDropdown,
  ThinDivider,
  ThinHalfDivider,
  EditPanelFormContainer,
  ReorderIconInPanel,
  DraggableItem,
  EditMetaRowDraggable,
  StyledInputDiv,
  ToggleLabel,
  LabelHeading,
  DisplayValueDiv,
} from "../util";
import { TableInfo } from "../../../../../BytebeamClient";
import styled from "styled-components";
import { AddAllColText } from "../../../../common/commonStyledComps";
import ToggleSwitch from "../../../common/ToggleSwitch";

type QuerySelectorRefsType = { [key: number]: React.RefObject<QuerySelector> };

export type EditLastValueMetaProps = {
  panelMeta: LastValueMetaData;
  tables: TableInfo;
};

export type EditLastValueMetaState = {
  table: string;
  columns: Array<LastValueColumn>;
  rowKeys: number[];
  queryRefs: QuerySelectorRefsType;
  activeIndex: number;
  filter: any;
  error: boolean;
  textSize: number;
  autoTextSize: boolean;
};

export const ClearFilterSpanWrapper = styled.span`
  float: right;
  margin-right: 5%;
  color: ${({ theme }) => theme.colors["link-text-color"]} !important;
  cursor: pointer;
`;

export class EditLastValueMeta extends PanelEditComponent<
  LastValueMetaData,
  EditLastValueMetaState
> {
  titleRef = React.createRef<HTMLInputElement>();
  valueRef = React.createRef<HTMLInputElement>();
  descriptionRef = React.createRef<HTMLInputElement>();
  durationRef = React.createRef<RelativeTimePicker>();
  prefixRef = React.createRef<HTMLInputElement>();
  suffixRef = React.createRef<HTMLInputElement>();

  static defaultState(): EditLastValueMetaState {
    return {
      table: "",
      columns: [],
      rowKeys: [0],
      queryRefs: { 0: React.createRef<QuerySelector>() },
      activeIndex: -1,
      filter: null,
      error: false,
      textSize: 50,
      autoTextSize: true,
    };
  }

  constructor(props: EditLastValueMetaProps) {
    super(props);

    if (props.panelMeta) {
      this.state = this.getStateFromPanelMeta(props);
    } else {
      this.state = EditLastValueMeta.defaultState();
    }
  }

  getStateFromPanelMeta(props) {
    if (
      props.panelMeta?.query &&
      Array.isArray(props.panelMeta.query) &&
      props.panelMeta.query.length > 0 &&
      props.panelMeta.query[0] === "and"
    ) {
      props.panelMeta.query = queryToObject(props.panelMeta.query);
    }

    let rowKeys = [0];
    if (props.panelMeta.query)
      rowKeys = props.panelMeta.query.map((_m, i) => i);

    if (!rowKeys || rowKeys.length === 0) {
      rowKeys = [0];
    }

    const queryRefs = {};

    rowKeys.forEach((k) => {
      queryRefs[k] = React.createRef<QuerySelector>();
    });

    return {
      table: props.panelMeta?.table,
      columns: props.panelMeta?.columns,
      rowKeys: rowKeys,
      queryRefs: queryRefs,
      filter: props.panelMeta?.query,
      activeIndex: props.panelMeta?.query ? 0 : -1,
      error: false,
      autoTextSize: props.panelMeta.autoTextSize,
      textSize: props.panelMeta.textSize,
    };
  }

  addRow() {
    this.setState(({ rowKeys, queryRefs }) => {
      const newRowKey = Math.max(...rowKeys) + 1;
      const newRefs = Object.assign({}, queryRefs, {
        [newRowKey]: React.createRef(),
      });

      return {
        rowKeys: [...rowKeys, newRowKey],
        queryRefs: newRefs,
      };
    });
  }

  removeRow(i: number) {
    delete this.state.queryRefs[i];

    this.setState({
      rowKeys: this.state.rowKeys.filter((v) => v !== i),
      queryRefs: this.state.queryRefs,
    });
  }

  getQuery() {
    return this.state.rowKeys.map((key) => {
      const ref = this.state.queryRefs[key];

      if (ref.current == null) {
        throw new Error("Empty ref");
      }

      return ref.current.getQuery();
    });
  }

  getPanelMeta(type): PartialMetaData<LastValueMetaData> {
    const validQuery = this.state.rowKeys
      .map((key) => this.state.queryRefs[key])
      .filter((ref) => {
        return ref && ref.current && ref.current.isFilled();
      })
      .map((ref) => {
        if (!ref.current) {
          throw new Error("Should not happen");
        }
        return ref.current.getQuery();
      });

    const meta: LastValueMetaData = {
      type: "last_value",
      id: this.props.panelMeta.id,
      title: this.titleRef.current?.value || "",
      description: this.descriptionRef.current?.value || "",
      table: this.state.table || "",
      columns: this.state.columns,
      query: validQuery,
      textSize: this.state.textSize || 50,
      autoTextSize: this.state.autoTextSize,
    };

    return {
      meta: meta,
      complete: this.isValidPanelMeta(meta, type),
    };
  }

  isValidPanelMeta(meta: LastValueMetaData, type?: string) {
    // type is used here to differentiate between submit and refresh in edit mode
    if (!(!!meta.table && meta.columns.length > 0) && type === "submit") {
      this.setState({ error: true });
    } else if (type === "submit") {
      this.setState({ error: false });
    }
    return !!meta.table && meta.columns.length > 0;
  }

  setTable(_event, data) {
    this.setState({
      table: data.value,
      columns: [],
    });
  }

  removeColumn(index: number) {
    this.setState({
      columns: [
        ...this.state.columns.slice(0, index),
        ...this.state.columns.slice(index + 1),
      ],
    });
  }

  setColumn(index: number, key: string, value?: any) {
    const v = { ...this.state.columns[index] };

    v[key] = value;

    this.setState({
      columns: [
        ...this.state.columns.slice(0, index),
        v,
        ...this.state.columns.slice(index + 1),
      ],
    });
  }

  handleAccordianClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  addColumn(value?: any) {
    if (value) {
      this.setState({
        columns: [
          ...this.state.columns,
          { name: value, prefix: "", suffix: "" },
        ],
      });
    }
  }

  clearFilter(event?: any) {
    event.stopPropagation();
    for (let i = 0; i < this.state.rowKeys.length; i++) {
      this.removeRow(i);
    }
    this.setState({ filter: {} }, () => {
      this.setState(
        {
          rowKeys: [0],
          queryRefs: { 0: React.createRef<QuerySelector>() },
        },
        () => {
          this.state.queryRefs[this.state.rowKeys[0]]?.current?.clearForm();
        }
      );
    });
  }

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedColumns = Array.from(this.state.columns);
    const [removed] = reorderedColumns.splice(result.source.index, 1);
    reorderedColumns.splice(result.destination.index, 0, removed);

    this.setState({
      columns: reorderedColumns,
    });
  };

  addAllColumns(columnOptions) {
    const remainingColumns: LastValueColumn[] = columnOptions.map((c) => ({
      name: c.value,
      prefix: "",
      suffix: "",
    }));

    this.setState({
      columns: [...this.state.columns, ...remainingColumns],
    });
  }

  handleSliderChange = (event) => {
    this.setState({ textSize: parseFloat(event.target.value) });
  };


  autoTextSizeToggle() {
    const toggled = !this.state.autoTextSize;
    this.setState({ autoTextSize: toggled });
  }

  render() {
    const { activeIndex } = this.state;
    const title = this.props.panelMeta.title;
    const description = this.props.panelMeta.description;
    const rowKeys = this.state.rowKeys;

    const tableOptions = Object.keys(this.props.tables).map((t) => {
      return {
        key: t,
        text: t,
        value: t,
      };
    });

    let columnOptions: Array<{
      key: string;
      value: string;
      text: string;
      type: string;
    }> = [];
    let filterColumnOptions: Array<{
      key: string;
      value: string;
      text: string;
      type: string;
    }> = [];

    if (!!this.state.table && this.props.tables[this.state.table]) {
      const columns = this.props.tables[this.state.table].filter(
        (column: { name: string; type: string }) =>
          this.state.columns.filter((c) => c.name === column.name).length === 0
      );

      columnOptions = columns.flatMap(
        (column: { name: string; type: string }) => {
          if (column.name === "timestamp") {
            return []; // Removing timestamp columns since we already show timestamp with every columns
          }
          return [
            {
              key: column.name,
              text: column.name,
              value: column.name,
              type: column.type,
            },
          ];
        }
      );

      const filterColumns = this.props.tables[this.state.table];

      filterColumnOptions = filterColumns.map(
        (f: { name: string; type: string }) => {
          return {
            key: f.name,
            text: f.name,
            value: f.name,
            type: f.type,
          };
        }
      );
    }

    const panes = [
      {
        menuItem: "General",
        pane: (
          <Tab.Pane key={"general"}>
            <EditPanelFormContainer>
              <div style={{ width: "100%", marginTop: "16px" }} />
              <EditMetaRow>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    autoFocus={true}
                    defaultRef={this.titleRef}
                    defaultValue={title}
                    label="Title"
                  />
                </StyledInputDiv>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    defaultRef={this.descriptionRef}
                    defaultValue={description}
                    label="Description"
                  />
                </StyledInputDiv>
              </EditMetaRow>

              <ThinDivider />

              <EditMetaRow>
                <StyledInputDiv width="50%" marginTop="10px">
                  <EditAnimatedMetaDropdown
                    placeholder="Select Stream"
                    text={this.state.table || "Select Stream"}
                    search
                    selection
                    options={tableOptions}
                    onChange={this.setTable.bind(this)}
                    defaultValue={this.state.table}
                    value={this.state.table}
                    error={this.state.error && !this.state.table}
                    elementid={`tableLastValue`}
                  />
                </StyledInputDiv>
              </EditMetaRow>

              <ThinDivider />

              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {this.state.columns.map((column, index) => (
                        <Draggable
                          key={column.name}
                          draggableId={column.name}
                          index={index}
                        >
                          {(provided) => (
                            <DraggableItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              {/* Your column rendering logic is here */}

                              <EditMetaRowDraggable key={column.name}>
                                <StyledInputDiv width="50%" marginTop="10px">
                                  <EditMetaDropdown
                                    placeholder="Select Field"
                                    text={column.name}
                                    search
                                    selection
                                    options={columnOptions}
                                    onChange={(_, data) =>
                                      this.setColumn(index, "name", data.value)
                                    }
                                    defaultValue={column.name}
                                    staticLabel
                                  />
                                </StyledInputDiv>

                                <DeleteIconInPanel
                                  color="red"
                                  onClick={() => this.removeColumn(index)}
                                >
                                  <Icon name="minus" />
                                </DeleteIconInPanel>

                                <ReorderIconInPanel
                                  {...provided.dragHandleProps}
                                >
                                  <Icon name="sort" />
                                </ReorderIconInPanel>

                                <StyledInputDiv width="48%">
                                  <EditAnimatedMetaInput
                                    singleLine
                                    defaultRef={this.prefixRef}
                                    defaultValue={column.prefix}
                                    label="Prefix"
                                    onChangeEvent={(event) =>
                                      this.setColumn(
                                        index,
                                        "prefix",
                                        event.target.value
                                      )
                                    }
                                  />
                                </StyledInputDiv>

                                <StyledInputDiv width="48%">
                                  <EditAnimatedMetaInput
                                    singleLine
                                    defaultRef={this.suffixRef}
                                    defaultValue={column.suffix}
                                    label="Suffix"
                                    onChangeEvent={(event) =>
                                      this.setColumn(
                                        index,
                                        "suffix",
                                        event.target.value
                                      )
                                    }
                                  />
                                </StyledInputDiv>

                                {this.state.columns.length - 1 !== index && (
                                  <ThinHalfDivider />
                                )}
                              </EditMetaRowDraggable>
                            </DraggableItem>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              {this.state.columns.length > 0 && <ThinDivider />}

              <EditMetaRow>
                <StyledInputDiv width="50%" marginTop="20px">
                  <EditAnimatedMetaDropdown
                    placeholder="Select Field"
                    text={"Select Field"}
                    defaultValue={""}
                    value={""}
                    search
                    selection
                    options={columnOptions}
                    onChange={(_, data) => this.addColumn(data.value)}
                    disabled={columnOptions.length === 0}
                    error={this.state.error && this.state.columns.length === 0}
                    elementid={`tableLastValueSelectColumn`}
                  />
                </StyledInputDiv>
                {columnOptions.length === 0 ? null : (
                  <AddAllColText
                    onClick={() => {
                      this.addAllColumns(columnOptions);
                    }}
                  >
                    Add all columns
                  </AddAllColText>
                )}
              </EditMetaRow>

              <Accordion inverted>
                <Accordion.Title
                  active={activeIndex === 0}
                  index={0}
                  onClick={this.handleAccordianClick}
                >
                  <Icon name="dropdown" />
                  <span style={{ fontWeight: "bold" }}>Filter</span>
                  {this.state.rowKeys.length > 0 &&
                    this.state.queryRefs[
                      this.state.rowKeys[0]
                    ]?.current?.isFilled() && (
                      <ClearFilterSpanWrapper
                        onClick={(event) => this.clearFilter(event)}
                      >
                        Clear filter
                      </ClearFilterSpanWrapper>
                    )}
                </Accordion.Title>
                <Accordion.Content
                  active={activeIndex === 0}
                  style={{ paddingLeft: "0px" }}
                >
                  <div style={{ width: "100%", marginTop: "16px" }}>
                    {rowKeys.map((rowKey, i) => {
                      return (
                        <React.Fragment key={i}>
                          {i ? (
                            <div style={{ marginLeft: "45%", color: "grey" }}>
                              AND
                            </div>
                          ) : (
                            ""
                          )}

                          <Table key={`tab-${i}`}>
                            <Table.Body>
                              <QuerySelector
                                key={rowKey}
                                ref={this.state.queryRefs[rowKey]}
                                defaultValue={
                                  this.state.filter ? this.state.filter[i] : {}
                                }
                                showRemoveIcon={
                                  rowKeys.length === i + 1 && i > 0
                                }
                                showAddIcon={i === rowKeys.length - 1}
                                onRemoveRow={() => this.removeRow(rowKey)}
                                onAddRow={this.addRow.bind(this)}
                                columns={filterColumnOptions}
                                elementid={i.toString()}
                              />
                            </Table.Body>
                          </Table>
                        </React.Fragment>
                      );
                    })}

                    <div style={{ width: "100%", height: "10px" }} />
                  </div>
                </Accordion.Content>
              </Accordion>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "View",
        pane: (
          <Tab.Pane key={"view"}>
            <EditPanelFormContainer>
              <EditMetaRow>
                <p>
                * These settings are not applicable for mobile layout
                </p>
                <ToggleLabel style={{ fontWeight: "bold" }}>
                  Auto resize the Text
                </ToggleLabel>
                <ToggleSwitch
                  id="showRangeZeroToggle"
                  defaultChecked={this.props.panelMeta.autoTextSize}
                  disabled={false}
                  Text={["Yes", "No"]}
                  onToggleChange={() => {
                    this.autoTextSizeToggle();
                  }}
                />
              </EditMetaRow>
              <EditMetaRow
                style={{
                  display: this.state.autoTextSize ? "none" : "flex",
                  marginLeft: "10px",
                }}
              >
                <LabelHeading style={{ fontWeight: "normal" }}>
                  Text Size
                </LabelHeading>
                <DisplayValueDiv>{this.state.textSize}</DisplayValueDiv>
                <div style={{ width: "100%", padding: "5px 35px 0px 5px" }}>
                  <input
                    style={{ width: "100%", height: "5%" }}
                    id="typeinp"
                    type="range"
                    min="50"
                    max="200"
                    value={this.state.textSize}
                    onChange={this.handleSliderChange}
                    step="1"
                  />
                </div>
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <EditMetaRoot>
        <Tab menu={{}} panes={panes} renderActiveOnly={false} />
      </EditMetaRoot>
    );
  }
}
