import React, { ReactElement, ReactNode, useState } from "react";
import { Button, Modal, Input, TextArea } from "semantic-ui-react";
import { submitData } from "./submitData";
import { validateName, validateEmail } from "../../../util";
import LoadingAnimation from "../../common/Loader";
import styled from "styled-components";

export const StyledInput = styled(Input)`
  width: 100%;
  border-width: 1px !important;
`;

export const StyledTextArea = styled(TextArea)`
  width: 100%;
  border-width: 1px !important;
  padding: 16px 12px;
  font-size: 16px;
  &::placeholder {
    color: #aaa;
  }
  &:focus::placeholder {
    color: #676768;
  }
`;

export const StyledInputContainer = styled.div`
  width: 100%;
  border: none;
  margin-top: 24px;
`;

export const StyledPara = styled.p`
  margin-top: 16px;
  font-size: 16px;
  font-weight: 600;
`;

export const StyledLoadingComponent = styled.div`
  width: 100%;
  height: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0px;
  padding: 24px;
  font-size: 20px;
`;

function ContactUs({ trigger }: { trigger: ReactNode }): ReactElement | null {
  const [status, setStatus] = useState<string>("not-sent");
  const [open, setOpen] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  function handleSubmit(e) {
    e.preventDefault();

    let userName = name?.trim(),
      userMail = email?.trim(),
      userMessage = message?.trim();

    if (userName === "") {
      window.toastr.error("Please enter your name");
    } else if (!validateName(userName)) {
      window.toastr.error("Please enter a valid name");
    } else if (userMail === "") {
      window.toastr.error("Please enter your email");
    } else if (!validateEmail(userMail)) {
      window.toastr.error("Please enter a valid email");
    } else if (userMessage === "") {
      window.toastr.error("Please enter your message");
    } else {
      (async () => {
        const errors = await submitData(userName, userMail, userMessage);
        if (!errors) {
          setStatus("done");
        } else {
          setStatus("failed");
        }
      })();
      setStatus("loading");
    }
  }

  // Resets the state of the modal
  function onModalClose() {
    setOpen(false);
    setStatus("not-sent");
    setName("");
    setEmail("");
    setMessage("");
  }

  if (status === "done") {
    return (
      <Modal
        basic
        className="dark"
        onClose={() => onModalClose()}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={trigger}
        size="tiny"
      >
        <Modal.Header>Thanks!</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <StyledPara>We will get back to you shortly!</StyledPara>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            secondary
            onClick={() => {
              onModalClose();
              setStatus("not-sent");
            }}
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  } else if (status === "failed") {
    return (
      <Modal
        basic
        className="dark"
        onClose={() => onModalClose()}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={trigger}
        size="tiny"
      >
        <Modal.Header>We are sorry!</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <StyledPara>
              Something went wrong. Please write to us at
              <a href="mailto:contact@bytebeam.io"> contact@bytebeam.io</a>
            </StyledPara>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            secondary
            onClick={() => {
              onModalClose();
              setStatus("not-sent");
            }}
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  } else if (status === "loading") {
    return (
      <Modal
        basic
        className="dark"
        onClose={() => onModalClose()}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={trigger}
        size="tiny"
      >
        <Modal.Header>Just a minute!</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <StyledLoadingComponent>
              <LoadingAnimation
                loadingText="Loading"
                fontSize="16px"
                marginTopText="16px"
              />
            </StyledLoadingComponent>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button secondary onClick={() => onModalClose()}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  return (
    <Modal
      basic
      className="dark"
      onClose={() => onModalClose()}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={trigger}
      size="tiny"
    >
      <Modal.Header>Contact Us</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <StyledPara>
            We're here to assist you and answer any questions you may have.
          </StyledPara>
        </Modal.Description>
        <StyledInputContainer>
          <StyledInput
            autoFocus
            size="large"
            placeholder="Enter your name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </StyledInputContainer>
        <StyledInputContainer>
          <StyledInput
            size="large"
            placeholder="Enter your mail id"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </StyledInputContainer>
        <StyledInputContainer>
          <StyledTextArea
            rows={8}
            size="large"
            placeholder="Enter your issue or feedback"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </StyledInputContainer>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={() => onModalClose()}>
          Close
        </Button>
        <Button
          primary
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Send
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default ContactUs;
