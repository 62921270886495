import React, { Component } from "react";
import { fetchAllDeviceConfiguration } from "../../../../../BytebeamClient";
import { Modal, Dropdown, Button, Icon } from "semantic-ui-react";

type UpdateConfigModalProps = {
  isOpen: boolean;
  close: (...args: any[]) => any;
  triggerAction: (
    arg1: string,
    arg2: { id?: string; version?: string },
    ...args: any[]
  ) => any;
  allSelected: boolean;
  selectedDevicesCount: number;
  action_type: string;
};

export type DropdownOptionType = {
  key: string;
  value: string;
  text: string;
};

type UpdateConfigModalState = {
  options: DropdownOptionType[];
  selectedOption: string;
  status: number;
  configs: { [key: string]: any };
};

enum UpdateConfigStatus {
  SelectDeviceConfigVersion,
  TriggerUpdateDeviceConfigVersion,
}

export class UpdateConfigModal extends Component<
  UpdateConfigModalProps,
  UpdateConfigModalState
> {
  state = {
    options: [],
    selectedOption: "",
    status: UpdateConfigStatus.SelectDeviceConfigVersion,
    configs: {},
  };

  async fillDropdownOptions() {
    try {
      const res = await fetchAllDeviceConfiguration();
      const resJSON = res.filter(
        (o) => !o.is_deactivated && o.action_type === this.props.action_type
      );
      const options = resJSON.map((o) => {
        return {
          key: o.tenant_id,
          value: o.version_name,
          text: o.version_name,
        };
      });

      const configs = resJSON.reduce((acc, config) => {
        acc[config.version_name] = config;
        return acc;
      }, {});

      this.setState({ options, configs });
    } catch (e) {
      console.log(e);
    }
  }

  onSelect = async (e, data) => {
    this.setState({
      selectedOption: data.value,
    });
  };

  // Reset state and close modal
  onCloseModal = () => {
    this.setState({
      options: [],
      selectedOption: "",
      status: UpdateConfigStatus.SelectDeviceConfigVersion,
    });
    this.props.close();
  };

  render() {
    const { isOpen } = this.props;

    return (
      <Modal
        open={isOpen}
        onClose={this.onCloseModal}
        size="tiny"
        className="dark"
        onMount={this.fillDropdownOptions.bind(this)}
      >
        <Modal.Header>
          Update{" "}
          {this.props.action_type === "update_geofence" ? "Geofence" : "Device"}{" "}
          Config
        </Modal.Header>
        <Modal.Content>
          {this.state.status ===
            UpdateConfigStatus.SelectDeviceConfigVersion && (
            <Modal.Description>
              <p>
                Select{" "}
                {this.props.action_type === "update_geofence"
                  ? "geofence"
                  : "device"}{" "}
                config version:
              </p>
              <Dropdown
                placeholder="Select Version"
                fluid
                selection
                options={this.state.options}
                onChange={this.onSelect}
              />
            </Modal.Description>
          )}
          {this.state.status ===
            UpdateConfigStatus.TriggerUpdateDeviceConfigVersion && (
            <Modal.Description>
              <div style={{ fontSize: "16px", marginBottom: "16px" }}>
                Are you ready to deploy{" "}
                <b>
                  {this.props.action_type === "update_geofence"
                    ? "geofence"
                    : "device"}{" "}
                  config version {this.state.selectedOption}
                </b>{" "}
                to{" "}
                <b>
                  {this.props.allSelected
                    ? // ? `All (${this.props.selectedDevicesCount} devices)` TODO: Fix this once device count on search is available
                      `All devices`
                    : `${
                        this.props.selectedDevicesCount === 1
                          ? "1 device"
                          : `${this.props.selectedDevicesCount} devices`
                      }`}
                </b>{" "}
                ?
              </div>
              This action will update the config on all selected devices. Please
              click on <b>Update</b> button if you would like to proceed with
              the deployment, else click on <b>Discard</b> button.
            </Modal.Description>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button secondary onClick={() => this.onCloseModal()}>
            <Icon name="remove" /> Discard
          </Button>
          <Button
            primary
            onClick={() => {
              if (
                this.state.status ===
                UpdateConfigStatus.SelectDeviceConfigVersion
              ) {
                if (this.state.selectedOption === "") {
                  window.toastr.error("Please select a Config version");
                } else {
                  this.setState({
                    status: UpdateConfigStatus.TriggerUpdateDeviceConfigVersion,
                  });
                }
              } else {
                this.props.triggerAction(
                  this.props.action_type,
                  Object.assign(
                    { version: this.state.selectedOption },
                    this.state.configs[this.state.selectedOption]["config_json"]
                  )
                );
                this.onCloseModal();
              }
            }}
          >
            {this.state.status ===
            UpdateConfigStatus.SelectDeviceConfigVersion ? (
              <>
                Next <Icon name="angle right" />
              </>
            ) : (
              <>
                <Icon name="checkmark" /> Update
              </>
            )}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
