import React, { useState } from "react";
import { Button, Input, Modal } from "semantic-ui-react";

type ConfirmationModalProps = {
  readonly prefixContent: string;
  readonly expectedText: string;
  readonly onConfirm: () => void;
  readonly trigger: React.ReactNode;
  readonly message: React.ReactNode;
};

export default function ConfirmationModal(props: ConfirmationModalProps) {
  const { prefixContent, expectedText, onConfirm, trigger, message } = props;
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");

  const invalidInput = text !== expectedText;
  const tryConfirm = () => {
    if (text === expectedText) {
      setOpen(false);
      onConfirm();
    }
  };

  const closeModal = () => {
    setText("");
    setOpen(false);
  };

  return (
    <Modal
      className="dark"
      size="tiny"
      open={open}
      onClose={closeModal}
      onOpen={() => setOpen(true)}
      trigger={trigger}
    >
      <Modal.Header>{prefixContent}</Modal.Header>
      <Modal.Content>
        {message}
        <Input
          autoFocus
          style={{ width: "100%" }}
          value={text}
          onPaste={(e) => {
            e.preventDefault();
          }}
          onChange={(_e, d) => setText(d.value)}
        />
      </Modal.Content>
      <Modal.Actions
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button color="green" onClick={closeModal}>
          Cancel
        </Button>
        <Button color="red" disabled={invalidInput} onClick={tryConfirm}>
          Confirm
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
