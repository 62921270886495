export const reportsSubTabs = [
  {
    displayName: "Report Templates",
    slug: "report-templates",
  },
  {
    displayName: "Generated Reports",
    slug: "generated-reports",
  },
];

export const GOOGLE_MAPS_API_KEY = "AIzaSyCJuOL85MvSfJ9VF9zRZDUjghNrnxwLAUE";
