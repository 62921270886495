import React from "react";
import { Button, Icon, ButtonProps, SemanticICONS, SemanticCOLORS } from "semantic-ui-react";
import styled, { css } from "styled-components";

interface ButtonWithIconProps extends ButtonProps {
  color?:
    | SemanticCOLORS
    | "facebook"
    | "google plus"
    | "vk"
    | "twitter"
    | "linkedin"
    | "instagram"
    | "youtube"
    | undefined;
  backgroundcolor?:
    | SemanticCOLORS
    | "facebook"
    | "google plus"
    | "vk"
    | "twitter"
    | "linkedin"
    | "instagram"
    | "youtube"
    | undefined;
  rounded?: boolean;
  icon?: SemanticICONS | undefined;
  type?: "filled" | "outlined";
  width?: string; // Add a prop for controlling button size in px eg. 100px or 100%
  border?: string; // Add a prop for controlling button border in px eg. 1px solid #000
}

const StyledCustomButton = styled(Button)<ButtonWithIconProps>`
  &&& {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: ${(props) =>
      props.width ? props.width : "120px"}; // Adjust the width here
    height: 30px;
    border-radius: 40px !important;
    padding: 12px 0 !important;
    margin-bottom: 0px !important;
    border: ${(props) =>
      props.type === "outlined" && props.backgroundcolor
        ? `1px solid ${props.backgroundcolor}`
        : ""}; // Adjust the width here
    box-shadow: ${({ theme }) =>
      theme.colors["container-box_shadow"]} !important;
    background: ${(props) =>
      props.type === "filled" && props.backgroundcolor
        ? props.backgroundcolor
        : props.theme.colors["background-color"]} !important;
    color: ${(props) =>
      props.color
        ? props.color
        : props.theme.colors["welcome-header-text"]} !important;
    font-size: 12px !important;
    font-weight: 400;
    ${(props) =>
      props.rounded &&
      css`
        border-radius: 25px !important;
      `}
  }
`;

const ButtonWithIcon: React.FC<ButtonWithIconProps> = ({
  color,
  rounded,
  icon,
  type,
  disabled,
  children,
  ...rest
}) => {
  return (
    <StyledCustomButton
      color={color}
      rounded={rounded}
      type={type}
      disabled={disabled}
      {...rest}
    >
      {icon && <Icon name={icon} />}
      {children}
    </StyledCustomButton>
  );
};

export default ButtonWithIcon;
