import React, { useState } from "react";
import { Icon, Table } from "semantic-ui-react";
import { ButtonIcon, DisplayIf, copyContentToClipboard } from "../../util";
import { deleteApiKey } from "../../../../BytebeamClient";
import ConfirmationModal from "../../common/ConfirmationModal";
import { ApiKey, Permission } from "../../../../util";

type APIKeyRowType = {
  apiKey: ApiKey;
  onUpdate: () => Promise<void>;
  permissions: Permission;
};

type DeleteApiKeyConfirmationModalMessageProps = {
  apiKey: ApiKey;
};

function DeleteApiKeyConfirmationModalMessage(
  props: DeleteApiKeyConfirmationModalMessageProps
) {
  const { apiKey } = props;
  return (
    <>
      <p>
        <span>Are you absolutely sure?</span>
        <br />
        You are going to deactivate selected API Key created by{" "}
        <b>{apiKey["created-by"]}</b>.
        <br />
      </p>
      <div
        style={{
          padding: "8px",
          color: "#c50000",
          textAlign: "center",
          background: "#f7d4d6",
          border: "1px solid #c50000",
          borderRadius: "4px",
        }}
      >
        <b>Warning:</b> This action is not reversible. Please be certain.
      </div>
      <p>
        <br /> Please type <b>Delete Key</b> to proceed or press Cancel to
        close.
      </p>
    </>
  );
}

function APIKeyRow(props: APIKeyRowType) {
  const { apiKey, permissions, onUpdate } = props;
  const [showKey, setShowKey] = useState<boolean>(false);

  function maskKey(key) {
    return (
      key.slice(0, 4) +
      "*****-*****-*****-*****-*********" +
      apiKey.key.slice(-4)
    );
  }

  async function handleKeyDelete() {
    await deleteApiKey(apiKey.key);
    onUpdate();
  }

  return (
    <Table.Row>
      <Table.Cell>{showKey ? apiKey.key : maskKey(apiKey.key)}</Table.Cell>
      <Table.Cell>{apiKey["created-by"]}</Table.Cell>
      <DisplayIf cond={permissions.viewRoles}>
        <Table.Cell>{apiKey.role}</Table.Cell>
      </DisplayIf>
      <Table.Cell>
        <Icon
          name={showKey ? "eye slash" : "eye"}
          style={{ cursor: "pointer", marginRight: "16px" }}
          onClick={() => setShowKey(!showKey)}
          title={showKey ? "Hide API Key" : "Show API Key"}
        />
        <Icon
          name={"copy"}
          style={{ cursor: "pointer", marginRight: "16px" }}
          onClick={async () => await copyContentToClipboard(apiKey.key, false)}
          title={"Copy API Key to Clipboard"}
        />
        <ConfirmationModal
          prefixContent="Deactivate API Key"
          expectedText={`Delete Key`}
          onConfirm={async () => {
            await handleKeyDelete();
          }}
          trigger={<ButtonIcon link name="trash" title={"Delete API Key"} />}
          message={<DeleteApiKeyConfirmationModalMessage apiKey={apiKey} />}
        />
      </Table.Cell>
    </Table.Row>
  );
}

export default APIKeyRow;
