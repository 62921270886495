import { AggregateValueData, AggregateValueMetaData } from "./PanelDef";
import React from "react";
import { PanelViewComponent } from "../PanelDef";
import { TableContainer } from "../util";
import { Table, TableHeaderCell } from "semantic-ui-react";
import styled from "styled-components";
import {
  StyledGrid,
  StyledGridRow,
  StyledTitle,
  StyledValue,
} from "../LastValue/ViewLastValue";
import { breakpoints } from "../../../../common/breakpoints";

const OverflowDiv = styled.div`
  overflow: scroll;
`;

class AggregateTable extends PanelViewComponent<
  AggregateValueMetaData,
  AggregateValueData
> {
  render() {
    const aggregator = this.props.panelMeta.aggregator;
    const data = this.props.data;

    return (
      <TableContainer>
        <div className="tableContentContainer">
          <Table compact selectable unstackable size="small">
            <Table.Header>
              <Table.Row>
                <TableHeaderCell>Name</TableHeaderCell>
                <TableHeaderCell>Value</TableHeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {typeof aggregator === "string" ? (
                <>
                  <Table.Row>
                    <Table.Cell>{aggregator}</Table.Cell>
                    <Table.Cell>{`${data["value"]}`}</Table.Cell>
                  </Table.Row>
                </>
              ) : (
                aggregator.map((aggregateName) => {
                  let value = data[aggregateName];
                  if (value === "") value = "-";

                  let text = "-";
                  if (typeof value == "number") {
                    value = Math.round(value * 100) / 100;
                  }

                  if (value !== null && value !== undefined) {
                    text = `${this.props.panelMeta.prefix} ${value} ${this.props.panelMeta.suffix}`;
                  }

                  return (
                    <Table.Row key={aggregateName}>
                      <Table.Cell>{aggregateName}</Table.Cell>
                      <Table.Cell>{text}</Table.Cell>
                    </Table.Row>
                  );
                })
              )}
            </Table.Body>
          </Table>
        </div>
      </TableContainer>
    );
  }
}
export class ViewAggregateValue extends PanelViewComponent<
  AggregateValueMetaData,
  AggregateValueData
> {
  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  ref = React.createRef<HTMLDivElement>();
  state = {
    fontSize: 10,
    fontSizeFactor: 469,
    screenWidth: window.innerWidth,
  };

  canvas = document.createElement("canvas");

  getTextWidth(text, font) {
    let context = this.canvas.getContext("2d");
    if (context) {
      context.font = font;

      let metrics = context.measureText(text);
      return metrics.width;
    }

    return 0;
  }

  calculateFontSize(fontFamily, maxWidth, maxHeight) {
    const text = this.getText(this.props.data.value);
    let numIterations = 0;
    let start = 10;
    let end = Math.max(10, Math.min(200, maxHeight));

    while (true) {
      const fontSize = (start + end) / 2;
      const font = `${fontSize}px ${fontFamily}`;

      numIterations += 1;

      if (numIterations > 20) {
        return { fontSize, characters: text.length };
      }

      const textWidth = this.getTextWidth(text, font);
      if (Math.abs(textWidth - maxWidth) < 2 || Math.abs(end - start) < 2) {
        return { fontSize, characters: text.length };
      }

      if (textWidth < maxWidth) {
        start = fontSize;
      } else {
        end = fontSize;
      }
    }
  }

  getText(value) {
    let formattedValue = "-";

    if (value != null && value !== "") {
      formattedValue = (Math.round(value * 100) / 100).toString();
    }

    return `${this.props.panelMeta.prefix} ${formattedValue} ${this.props.panelMeta.suffix}`;
  }

  updateDimensions = () => {
    this.setState({ screenWidth: window.innerWidth });
  };

  componentDidMount() {
    if (this.ref.current) {
      const rect = this.ref.current.getBoundingClientRect();
      const fontFamily = window.getComputedStyle(this.ref.current).fontFamily;

      const { fontSize, characters } = this.calculateFontSize(
        fontFamily,
        rect.width * 0.9,
        rect.height * 0.5
      );
      let sizeFactor = fontSize * characters;
      this.setState({
        fontSize: 0.8 * fontSize,
        fontSizeFactor: sizeFactor,
      });
    }

    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    const dashboardTitle = this.props.panelMeta.title;

    if (typeof this.props.panelMeta.aggregator === "string") {
      // For older Aggregator version, when Aggregate value was string
      let textValue = this.getText(this.props.data.value);
      let estimatedFontSize = this.state.fontSizeFactor / textValue.length;
      if (this.state.screenWidth > breakpoints.sm) {
        return (
          <div className="big-number-root" ref={this.ref}>
            <div className="big-number-value">
              <span
                style={{
                  fontSize: `${estimatedFontSize ?? 20}px`,
                }}
              ></span>
            </div>
          </div>
        );
      } else {
        return (
          <StyledGrid>
            <StyledGridRow>
              <StyledTitle width={8}>
                {dashboardTitle || "Untitled"}
              </StyledTitle>
              <StyledValue width={8}>
                {this.getText(this.props.data.value)}
              </StyledValue>
            </StyledGridRow>
          </StyledGrid>
        );
      }
    } else if (this.props.panelMeta.aggregator.length === 1) {
      // For latest version which allows multiple Aggregator
      // But with single aggregator
      const aggregator = this.props.panelMeta.aggregator[0];
      const data = this.props.data;
      const autoTextSize = this.props.panelMeta.autoTextSize;
      const customTextSize = this.props.panelMeta.textSize;

      let value = data[aggregator];
      // in case of empty string replace with hyphen
      if (value === "") value = "-";

      let text = "No data";

      if (typeof value == "number") {
        value = Math.round(value * 100) / 100;
      }

      if (value !== null && value !== undefined) {
        text = `${this.props.panelMeta.prefix} ${value} ${this.props.panelMeta.suffix}`;
      }
      let estimatedFontSize = this.state.fontSizeFactor / text.length;

      if (text === "No data") {
        return <div className="panel-no-data">No Data</div>;
      }

      if (this.state.screenWidth > breakpoints.sm) {
        return (
          <OverflowDiv className="big-number-root" ref={this.ref}>
            <div className="big-number-value">
              <div
                style={{
                  fontSize: autoTextSize
                    ? `${estimatedFontSize ?? 20}px`
                    : `${customTextSize}px`,
                  lineHeight: "normal",
                }}
              >
                {text}
              </div>
            </div>
          </OverflowDiv>
        );
      } else {
        return (
          <StyledGrid>
            <StyledGridRow>
              <StyledTitle width={8}>{dashboardTitle}</StyledTitle>
              <StyledValue width={8}>{text}</StyledValue>
            </StyledGridRow>
          </StyledGrid>
        );
      }
    } else {
      return <AggregateTable {...this.props} />;
    }
  }
}
