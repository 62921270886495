import React from "react";
import { Dropdown } from "semantic-ui-react";

const typeOptions = [
  {
    value: "Bool",
    text: "Bool",
  },
  {
    value: "Nullable(Bool)",
    text: "Nullable(Bool)",
  },
  {
    value: "Float32",
    text: "Float32",
  },
  {
    value: "Nullable(Float32)",
    text: "Nullable(Float32)",
  },
  {
    value: "Float64",
    text: "Float64",
  },
  {
    value: "Nullable(Float64)",
    text: "Nullable(Float64)",
  },
  {
    value: "Int8",
    text: "Int8",
  },
  {
    value: "Nullable(Int8)",
    text: "Nullable(Int8)",
  },
  {
    value: "Int16",
    text: "Int16",
  },
  {
    value: "Nullable(Int16)",
    text: "Nullable(Int16)",
  },
  {
    value: "Int32",
    text: "Int32",
  },
  {
    value: "Nullable(Int32)",
    text: "Nullable(Int32)",
  },
  {
    value: "Int64",
    text: "Int64",
  },
  {
    value: "Nullable(Int64)",
    text: "Nullable(Int64)",
  },
  {
    value: "UInt8",
    text: "UInt8",
  },
  {
    value: "Nullable(UInt8)",
    text: "Nullable(UInt8)",
  },
  {
    value: "UInt16",
    text: "UInt16",
  },
  {
    value: "Nullable(UInt16)",
    text: "Nullable(UInt16)",
  },
  {
    value: "UInt32",
    text: "UInt32",
  },
  {
    value: "Nullable(UInt32)",
    text: "Nullable(UInt32)",
  },
  {
    value: "UInt64",
    text: "UInt64",
  },
  {
    value: "Nullable(UInt64)",
    text: "Nullable(UInt64)",
  },
  {
    value: "String",
    text: "String",
  },
  {
    value: "Nullable(String)",
    text: "Nullable(String)",
  },
  {
    value: "Date",
    text: "Date",
  },
  {
    value: "Nullable(Date)",
    text: "Nullable(Date)",
  },
  {
    value: "DateTime",
    text: "Timestamp",
  },
  {
    value: "Nullable(DateTime)",
    text: "Nullable Timestamp",
  },
  {
    value: "DateTime64(3)",
    text: "Timestamp milliseconds",
  },
  {
    value: "Nullable(DateTime64(3))",
    text: "Nullable Timestamp milliseconds",
  },
  {
    value: "DateTime64(6)",
    text: "Timestamp microseconds",
  },
  {
    value: "Nullable(DateTime64(6))",
    text: "Nullable Timestamp microseconds",
  },
];

const fullOptions = typeOptions.map((x) => ({
  key: x.value,
  text: x.text,
  value: x.value,
}));

export default function TypeSelection({ value, onChange, disabled, id }) {
  let typeValue = "";
  for (const opt of fullOptions) {
    if (opt.value === value) {
      typeValue = opt.value;
    }
  }
  return (
    <Dropdown
      id={id}
      fluid
      search
      selection
      placeholder="Select Type"
      value={typeValue}
      onChange={onChange}
      options={fullOptions}
      style={{ width: "100%" }}
      disabled={disabled}
    />
  );
}
