import React, { useCallback, useEffect, useState } from "react";
import PhaseMenuTab from "../PhaseMenuTab";
import DeviceSelectionSection from "./DeviceSelectionSection";
import LoadingAnimation from "../../../../common/Loader";
import { PhaseData } from "./NewAction";
import ActionFilterSection from "./ActionFilterSection";
import {
  Device,
  DeviceFilterOption,
  DeviceFilters,
  Key,
  SearchDeviceResponse,
  fetchAllMetadataKeys,
  fetchDeviceFilterOptions,
  searchDevices,
} from "../../../../../BytebeamClient";
import { FilterListObject } from "../../action-modals/NewActionModal";
import { ErrorMessage } from "../../../../common/ErrorMessage";
import { toRoman } from "../../../../../helpers/helpers";
import moment from "moment";
import styled from "styled-components";
import AdvancedPhasedSection from "./AdvancedPhasedSection";
import { ThinDivider } from "../../../Dashboards/Panel/util";
import { validateTimestampInterval } from "../../../util";

const PhaseSectionContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const PhasesMenuWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 40px;
`;

type PhaseErrorsMessage = { [key: string]: string | null };

type PhaseContentProps = {
  phase: PhaseData;
  phasesData: PhaseData[];
  setPhasesData: (phasesData: PhaseData[]) => void;
  activePhase: string;
  phaseErrorsMessage: PhaseErrorsMessage;
  setPhaseErrorsMessage: (
    error: (prevErrors: PhaseErrorsMessage) => PhaseErrorsMessage
  ) => void;
  filterLoading: boolean;
  filterList: FilterListObject[];
  setFilterList: (filterList: FilterListObject[]) => void;
  filters: DeviceFilters;
  filterOptions: DeviceFilterOption[];
  updateFilters: (filterName: string, filterValues: string[]) => void;
  resetFilters: () => void;
  devices?: SearchDeviceResponse;
  devicesLoading: boolean;
  allSelected: boolean;
  setAllSelected: (allSelected: boolean) => void;
  selectedDevices: SearchDeviceResponse;
  setSelectedDevices: (selectedDevices: SearchDeviceResponse) => void;
  page: number;
  pageLimit: number;
  onPageChange: (e, { activePage }) => void;
};

const PhaseContent = (props: PhaseContentProps) => {
  const {
    phase,
    phasesData,
    setPhasesData,
    activePhase,
    phaseErrorsMessage,
    setPhaseErrorsMessage,
    filterLoading,
    filterList,
    setFilterList,
    filters,
    filterOptions,
    updateFilters,
    resetFilters,
    devices,
    devicesLoading,
    allSelected,
    setAllSelected,
    selectedDevices,
    setSelectedDevices,
    page,
    pageLimit,
    onPageChange,
  } = props;

  const getNumericalDeviceIDs = (deviceIDArr) => {
    let arr: number[] = [];
    deviceIDArr.map((deviceID: Device) => arr.push(deviceID.id));
    return arr;
  };

  const upDateActivePhaseTimestamp = (time, activePhase: string) => {
    // Validate if the timestamp has a 5-minute interval
    const isValidTimestamp = validateTimestampInterval(
      time,
      activePhase,
      phasesData
    );

    if (!isValidTimestamp) {
      setPhaseErrorsMessage((prevErrors: PhaseErrorsMessage) => ({
        ...prevErrors,
        [activePhase]:
          "Please ensure a minimum interval of 5 minutes from the previous phase.",
      }));
    } else {
      setPhaseErrorsMessage((prevErrors: PhaseErrorsMessage) => ({
        ...prevErrors,
        [activePhase]: null,
      }));
    }

    const newPhasesData = phasesData.map((phase) => {
      if (phase.name === activePhase) {
        phase.trigger_on.timestamp = moment(time).toDate();
      }
      return phase;
    });
    setPhasesData(newPhasesData);
  };

  const upDateActivePhaseFraction = (value, activePhase) => {
    const newPhasesData = phasesData.map((phase) => {
      if (phase.name === activePhase) {
        phase.info.fraction = parseInt(value);
      }
      return phase;
    });
    setPhasesData(newPhasesData);
  };

  const updateFixedListPhaseData = (updatedDevices: SearchDeviceResponse) => {
    let updatedPhaseData: PhaseData = { ...phasesData[phase.id - 1] };
    updatedPhaseData.info.device_ids = getNumericalDeviceIDs(
      updatedDevices.devices
    );
    updatedPhaseData.info.filter = filters;
    updatedPhaseData.info.fraction = 100;
    if (updatedDevices.devices.length === 0) {
      updatedPhaseData.info.type =
        Object.keys(filters).length === 0 ? "fixed-list" : "filter-fraction";
    } else {
      updatedPhaseData.info.type = "fixed-list";
    }

    let updatedPhasesData: PhaseData[] = [...phasesData];
    updatedPhasesData[phase.id - 1] = updatedPhaseData;
    setPhasesData(updatedPhasesData);
  };

  const selectDevice = (device: Device) => {
    let updatedDevices: SearchDeviceResponse = {
      devices: [...selectedDevices.devices, device],
      count: selectedDevices.count + 1,
    };
    setSelectedDevices(updatedDevices);

    // Update the phase info with the selected devices ids (With or Without filters)
    updateFixedListPhaseData(updatedDevices);
  };

  const clearDevice = (device: Device) => {
    const filteredDevices = selectedDevices.devices.filter(
      (selectedDevice) => selectedDevice.id !== device.id
    );

    const updatedDevices: SearchDeviceResponse = {
      devices: filteredDevices,
      count: selectedDevices.count - 1,
    };
    setSelectedDevices(updatedDevices);

    // Update the phase info with the removed devices ids (With or Without filters)
    updateFixedListPhaseData(updatedDevices);
  };

  const handleAllDevicesSelect = (selectAll: boolean) => {
    setAllSelected(selectAll);

    let updatedPhaseData: PhaseData = { ...phasesData[phase.id - 1] };
    if (Object.keys(filters).length === 0 && !selectAll) {
      updatedPhaseData.info = {
        type: "fixed-list",
        device_ids: [],
      };
    } else {
      updatedPhaseData.info = {
        type: "filter-fraction",
        filter: filters,
        fraction: 100,
      };
    }
    let updatedPhasesData: PhaseData[] = [...phasesData];
    updatedPhasesData[phase.id - 1] = updatedPhaseData;
    setPhasesData(updatedPhasesData);
  };

  const handleClearFilters = () => {
    resetFilters();

    // Reset the phase info to default
    const newPhasesData = phasesData.map((phase) => {
      if (phase.name === activePhase) {
        phase = {
          id: phase.id,
          name: phase.name,
          trigger_on: phase.trigger_on,
          info: {
            type: "fixed-list",
            device_ids: [],
          },
        };
      }
      return phase;
    });
    setPhasesData(newPhasesData);
  };

  return (
    <PhaseSectionContainer
      id={`phaseContent-${phasesData.length + 2 - phase.id}`}
      style={{
        display: activePhase === phase.name ? "block" : "none",
      }}
    >
      {!filterLoading && filterOptions && filterOptions?.length !== 0 && (
        <ActionFilterSection
          filterOptions={filterOptions}
          updateFilters={updateFilters}
          filterList={filterList}
          setFilterList={setFilterList}
          filters={filters}
          resetFilters={handleClearFilters}
        />
      )}

      {/* <ActionDisplaySection
          showActionDisplayDropdown={showActionDisplayDropdown}
          setShowActionDisplayDropdown={setShowActionDisplayDropdown}
        /> */}

      {(filterLoading || devicesLoading) && (
        <LoadingAnimation
          loaderContainerHeight="350px"
          loadingText="Loading devices"
          fontSize="20px"
          loaderSize="48px"
        />
      )}
      {!filterLoading &&
        !devicesLoading &&
        devices &&
        devices.devices.length !== 0 && (
          <DeviceSelectionSection
            devices={devices}
            allSelected={allSelected}
            setAllSelected={handleAllDevicesSelect}
            selectedDevices={selectedDevices}
            setSelectedDevices={setSelectedDevices}
            selectDevice={selectDevice}
            clearDevice={clearDevice}
            page={page}
            pageLimit={pageLimit}
            onPageChange={onPageChange}
          />
        )}

      {!filterLoading &&
        !devicesLoading &&
        devices?.devices.length === 0 &&
        filterOptions?.length === 0 && (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ErrorMessage marginTop="30px" message={"No devices found!"} />
          </div>
        )}

      <ThinDivider />

      <div
        style={{
          marginBottom: "0px",
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            paddingLeft: "30px",
            paddingTop: "30px",
            marginBottom: "40px",
            display: "grid",
            gridTemplateAreas: `"auto auto auto auto"`,
          }}
        >
          <AdvancedPhasedSection
            key={activePhase}
            phases={phasesData}
            activePhase={activePhase}
            allSelected={allSelected}
            phaseErrorsMessage={phaseErrorsMessage}
            upDateActivePhaseTimestamp={upDateActivePhaseTimestamp}
            upDateActivePhaseFraction={upDateActivePhaseFraction}
          />
        </div>
      </div>
    </PhaseSectionContainer>
  );
};

type PhaseControlSectionProps = {
  phasesData: PhaseData[];
  setPhasesData: (phases: PhaseData[]) => void;
};

const PhaseControlSection = (props: PhaseControlSectionProps) => {
  const { phasesData, setPhasesData } = props;

  let abortController = new AbortController();

  const [filters, setFilters] = useState<DeviceFilters>({});
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [selectedDevices, setSelectedDevices] = useState<SearchDeviceResponse>({
    devices: [],
    count: 0,
  });
  const [page, setPage] = useState<number>(1);
  const [devicesLoading, setDevicesLoading] = useState<boolean>(true);
  const [filterList, setFilterList] = useState<FilterListObject[]>([]);
  const [filterOptions, setFilterOptions] = useState<DeviceFilterOption[]>([]);
  const [devices, setDevices] = useState<SearchDeviceResponse>();
  const [filterLoading, setFilterLoading] = useState<boolean>(true);
  const [phaseErrorsMessage, setPhaseErrorsMessage] =
    useState<PhaseErrorsMessage>({});
  const [activePhase, setActivePhase] = useState<string>(`Phase ${toRoman(1)}`);
  const [allowedFilterBys, setAllowedFilterBys] = useState<Key[]>([]);
  const [pageLimit] = useState<number>(5);

  const refreshDevices = (pageNumber: number, myFilters: DeviceFilters) => {
    setDevicesLoading(true);

    abortController.abort();
    abortController = new AbortController();

    //setTimeout here is to allow change in filters/page using setState to take affect before refreshing devices;
    setTimeout(async () => {
      const status = "active";
      await searchDevices(
        myFilters,
        pageNumber,
        pageLimit,
        status,
        abortController.signal
      ).then((devices) => {
        setDevices(devices);
        setDevicesLoading(false);
      });
    });
  };

  const fetchMetadataKeys = async () => {
    setFilterLoading(true);
    setDevicesLoading(true);
    await fetchAllMetadataKeys().then((keys) => {
      const idKey = { key: "id" };
      setAllowedFilterBys([idKey, ...keys]);
      refreshFilterOptions({}, [idKey, ...keys]);
    });
  };

  const onPageChange = useCallback(
    (e, { activePage }) => {
      setDevicesLoading(true);
      setPage(activePage as number);
      refreshDevices(activePage, filters);
    },
    [filters] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const refreshFilterOptions = async (filters: DeviceFilters, keys) => {
    await fetchDeviceFilterOptions(filters).then((allFilterOptions) => {
      const filterOptionsMap = Object.fromEntries(
        allFilterOptions.map(({ filterName, filterValues }) => [
          filterName,
          filterValues,
        ])
      );
      if (keys) {
        let map = keys.map((filterName) => {
          return {
            filterName: filterName.key,
            filterValues: filterOptionsMap[filterName.key],
          };
        });
        setFilterOptions(map);
      }
      setFilterLoading(false);
    });
  };

  const resetFilters = () => {
    setDevicesLoading(true);
    setPage(1);
    setFilterLoading(true);
    setAllowedFilterBys([]);
    setFilters({});
    setFilterList([]);
    setSelectedDevices({ devices: [], count: 0 });
    setAllSelected(false);
  };

  const addPhase = () => {
    const currentDateTime = new Date();

    const newPhase: PhaseData = {
      id: phasesData.length + 1,
      name: `Phase ${toRoman(phasesData.length + 1)}`,
      trigger_on: {
        timestamp:
          moment(phasesData[phasesData.length - 1].trigger_on.timestamp)
            .add(15, "minutes")
            .toDate() ?? new Date(currentDateTime.getTime() + 15 * 60 * 1000),
      },
      info: {
        type: "fixed-list",
        device_ids: [],
      },
    };

    const newPhasesData: PhaseData[] = [...phasesData, newPhase];
    setPhasesData(newPhasesData);
    setActivePhase(`Phase ${toRoman(phasesData.length + 1)}`);

    // Reset filters when adding a new phase
    resetFilters();
  };

  const removePhase = useCallback(
    (e) => {
      e.stopPropagation();

      const newPhasesData = phasesData.filter(
        (phase) => phase.id !== phasesData.length
      );
      setPhasesData(newPhasesData);
      setActivePhase(`Phase ${toRoman(phasesData.length - 1)}`);

      // Reset filters when removing a phase
      resetFilters();
    },
    [phasesData, activePhase] //eslint-disable-line react-hooks/exhaustive-deps
  );

  const updateFilters = (filterName: string, filterValues: string[]) => {
    const myFilters = { ...filters };

    if (filterValues.length > 0) {
      myFilters[filterName] = filterValues;
    } else {
      delete myFilters[filterName];
    }

    setFilters(myFilters);
    setPage(1);
    setDevicesLoading(true);
    setSelectedDevices({ devices: [], count: 0 });
    setAllSelected(false);

    refreshFilterOptions(myFilters, allowedFilterBys);
    refreshDevices(page, myFilters);

    // If filters are applied, update the phase info with the filter
    if (Object.keys(myFilters).length !== 0) {
      let updatedPhasesData: PhaseData[] = phasesData.map((phase) => {
        if (phase.name === activePhase) {
          phase.info.type = "filter-fraction";
          phase.info.filter = myFilters;
          phase.info.fraction = 100;
        }
        return phase;
      });
      setPhasesData(updatedPhasesData);
    }
  };

  const handlePhaseClick = (name: string) => {
    setActivePhase(name);
    resetFilters();

    const selectedPhase = phasesData.find((phase) => phase.name === name);
    if (!selectedPhase) return;

    const { type, filter, device_ids } = selectedPhase.info;

    if (type === "filter-fraction" || type === "filter-fraction-lazy") {
      const newFilters = filter ?? {};
      setFilters(newFilters);

      const newFilterList = Object.entries(newFilters).map(([key, value]) => ({
        filterName: key,
        filterValue: value,
      }));
      setFilterList((filterList) => [...filterList, ...newFilterList]);

      if (type === "filter-fraction" && Object.keys(newFilters).length === 0) {
        setAllSelected(true);
      }
    } else if (type === "fixed-list") {
      const newDevices = device_ids?.map((id) => ({ id } as Device)) ?? [];
      setSelectedDevices({
        devices: newDevices,
        count: newDevices.length,
      });
    }
  };

  useEffect(() => {
    const initialFetch = () => {
      fetchMetadataKeys();
      refreshDevices(1, {});
    };

    if (
      Object.keys(filters).length === 0 &&
      (!allowedFilterBys || allowedFilterBys.length === 0)
    ) {
      initialFetch();
    }
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchMetadataKeys();
    refreshDevices(1, filters);
  }, [activePhase]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <PhasesMenuWrapper>
        {phasesData.map((phase) => (
          <PhaseMenuTab
            key={phase.id}
            id={phasesData.length + 2 - phase.id}
            phaseTabSum={phasesData.length + 1}
            tempPhaseRemoveCheck={phase.id === phasesData.length}
            firstElement={phase.id === 1}
            name={phase.name}
            active={activePhase === phase.name}
            removePhase={(e) => removePhase(e)}
            onClick={() => {
              handlePhaseClick(phase.name);
            }}
          />
        ))}
        <PhaseMenuTab
          id={1}
          lastElement
          onClick={() => {
            addPhase();
          }}
        />
      </PhasesMenuWrapper>
      {phasesData.map((phase) => (
        <PhaseContent
          key={phase.id}
          phase={phase}
          phasesData={phasesData}
          setPhasesData={setPhasesData}
          activePhase={activePhase}
          phaseErrorsMessage={phaseErrorsMessage}
          setPhaseErrorsMessage={setPhaseErrorsMessage}
          filterLoading={filterLoading}
          filterList={filterList}
          setFilterList={setFilterList}
          filters={filters}
          filterOptions={filterOptions}
          updateFilters={updateFilters}
          resetFilters={resetFilters}
          devices={devices}
          devicesLoading={devicesLoading}
          allSelected={allSelected}
          setAllSelected={setAllSelected}
          selectedDevices={selectedDevices}
          setSelectedDevices={setSelectedDevices}
          page={page}
          pageLimit={pageLimit}
          onPageChange={onPageChange}
        />
      ))}
    </>
  );
};

export default PhaseControlSection;
