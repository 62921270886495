import { DeviceStatusData, DeviceStatusMetaData } from "./PanelDef";
import React from "react";
import { PanelViewComponent } from "../PanelDef";
import { Icon, Table } from "semantic-ui-react";

import styled from "styled-components";
import OfflineIcon from './Images/signal-slash.svg'
import { capitalizeFirstLetter } from "../../../util";

// const ImageContainer = styled.div`
//   width: 40px;
//   height: 40px;
//   border-radius: 50%;
//   display: inline-flex;
//   align-items: center;
//   justify-content: center;
// `

const DeviceStatus = styled.div`
  margin-left: 10px;
  display: inline-flex;
  justify-content: flex-end;
`

export class ViewDeviceStatus extends PanelViewComponent<
  DeviceStatusMetaData,
  DeviceStatusData
> {
  ref = React.createRef<HTMLDivElement>();

  render() {
    const data = this.props.data.data;

    const statusCounts = data.reduce((acc, cur) => {
      const status = capitalizeFirstLetter(cur.status);
      if (acc[status]) {
        acc[status] += 1;
      } else {
        acc[status] = 1;
      }
      return acc;
    }, {} as { [key: string]: number });

    const total = Object.values(statusCounts).reduce((acc, cur) => acc + cur, 0);
    
    const containerStyle = {
      marginTop: "30px",
      padding: "20px 20px",
      overflowY: "scroll",
      height: "calc(100% - 30px)"
    } as React.CSSProperties;

    return (
      <div style={containerStyle}>
        <Table size="small" selectable style={{border: "none"}}>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Icon name="location arrow" color="green" size="big"/>
                <DeviceStatus>
                  Running
                </DeviceStatus>
              </Table.Cell>
              <Table.Cell>
                {statusCounts.Running || 0}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Icon name="bolt" color="blue" size="big"/>
                <DeviceStatus>
                  Charging
                </DeviceStatus>
              </Table.Cell>
              <Table.Cell>
                {statusCounts.Charging || 0}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Icon name="moon" color="grey" size="big"/>
                <DeviceStatus>
                  Idle
                </DeviceStatus>               
              </Table.Cell>
              <Table.Cell>
                {statusCounts.Idle || 0}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Icon name="stop" color="yellow" size="big"/>
                <DeviceStatus>
                  Stopped
                </DeviceStatus>
              </Table.Cell>
              <Table.Cell>
                {statusCounts.Off || 0}
              </Table.Cell>
            </Table.Row>
            <Table.Row verticalAlign="middle">
              <Table.Cell>
                <div style={{display: "inline-flex", flexDirection: "row", alignItems: "center"}}>
                  <img src={OfflineIcon} style={{width: "25px", height: "25px"}} alt="no signal" />
                  <DeviceStatus>
                    Offline
                  </DeviceStatus>
                </div>
              </Table.Cell>
              <Table.Cell>
                {statusCounts.Offline || 0}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Icon name="wrench" color="yellow" size="big" style={{visibility: "hidden"}} />
                <DeviceStatus>
                  Total
                </DeviceStatus>
              </Table.Cell>
              <Table.Cell>
                <b>{total}</b>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

      </div>
    );
  }
}
