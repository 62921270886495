import React, { useContext } from "react";
import { Menu, Dropdown, Icon } from "semantic-ui-react";
import Toggle from "../Toggle";
import ByteBeamLogoSVG from "../ByteBeamLogoSVG";
import { Mixpanel } from "../../Screens/common/MixPanel";
import styled, { ThemeContext, keyframes } from "styled-components";
import {
  // urlToDocId,
  currentReleaseId,
  UserSettings,
  User,
  showGetStartedForHost,
} from "../../../util";
import { updateUserSettings } from "../../../BytebeamClient";
import { DisplayIf, capitalizeFirstLetter } from "../../Screens/util";
import { Link, useHistory } from "react-router-dom";
import UserSummaryWithProjectPicker from "./UserSummaryWithProjectPicker";

const breatheAnimation = keyframes`
  0%, 67% { 
  opacity: 0;
  }
  34%, 100% {
    opacity: 1;
  }
`;

const NotificationHighlight = styled.span<{ isVisible: boolean }>`
  position: absolute;
  display: ${(props) => (props.isVisible ? "none" : "inline")};
  bottom: 0px;
  right: 0px;
  width: 0px;
  height: 0px;
  border-left: 7px solid transparent;
  border-top: 7px solid transparent;
  border-right: 7px solid #609d5a;
  border-bottom: 7px solid #609d5a;
  animation-name: ${breatheAnimation};
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
`;

type DesktopNavBarProps = {
  user: User;
  getCurrentUser: () => Promise<void>;
  activeItem: string;
  setActiveItem: (item: string) => void;
  currentTenant: string;
  setCurrentTenant: (tenant: string) => void;
  isMultiTenant: boolean;
  showSettingsTab: () => boolean;
  setShowNewProjectModal: (show: boolean) => void;
  handleThemeToggle: () => Promise<void>;
};

function DesktopNavBar(props: DesktopNavBarProps) {
  const {
    user,
    getCurrentUser,
    activeItem,
    setActiveItem,
    currentTenant,
    isMultiTenant,
    setCurrentTenant,
    showSettingsTab,
    setShowNewProjectModal,
    handleThemeToggle,
  } = props;

  const permissions = user?.role?.permissions || {};
  const userSettings = user?.settings as UserSettings;
  const userSettingsGetStartedSkip = user?.settings?.get_started?.skip ?? false;

  const history = useHistory();

  const themeContext = useContext(ThemeContext);

  function openArchBeeWidget(type: string) {

    if (type === "release") {
      window.open('https://docs.bytebeam.io/docs/r17-26-jan-2024', '_blank');
    } else {
      window.open('https://bytebeam.io/docs/', '_blank');
    }

    // default page is Bytebeam Docs Home page
    // let docId = "VTG-LgN15IRRD-noF9Tht";

    // if (type === "release") {
    //   docId = currentReleaseId;
    // } else {
    //   const path = getPathFromURL();
    //   docId = urlToDocId[path] ?? docId;
    // }

    // window?._archbee.push({
    //   eventType: "show-widget",
    //   docId: `${docId}`,
    // });
  }

  // Function for handling click on Release Note Dropdown option
  async function handleReleaseNoteClick() {
    const userSettings = user?.settings ?? ({} as UserSettings);
    openArchBeeWidget("release");
    if (currentReleaseId !== userSettings?.current_release_id) {
      try {
        const res = await updateUserSettings({
          settings: {
            ...userSettings,
            current_release_id: currentReleaseId,
          },
        });

        if (res !== undefined && res !== null) {
          await getCurrentUser();
        }
      } catch (error) {
        console.log("Error while updating user settings", error);
      }
    }
  }

  // Navbar Item
  function menuTab(
    name: string,
    title: string,
    to: string,
    shouldDisplay: boolean
  ) {
    return (
      <DisplayIf
        cond={shouldDisplay && window.location.pathname !== "/welcome"}
      >
        <Menu.Item
          as={Link}
          to={to}
          name={name}
          active={activeItem === name}
          onClick={() => setActiveItem(name)}
        >
          <h4 style={{ fontSize: "14px", fontWeight: 600 }}>{title}</h4>
        </Menu.Item>
      </DisplayIf>
    );
  }

  return (
    <Menu fixed="top" className="main-navbar desktop-view">
      {/* Navbar Left Elements */}
      <Menu.Item
        style={{
          padding: "12px 24px",
        }}
        className="logo"
        as={Link}
        to={
          window.location.pathname === "/welcome"
            ? window.location.pathname
            : permissions.showDeviceManagementTab
            ? `/projects/${currentTenant}/device-management/devices`
            : `/projects/${currentTenant}/dashboards`
        }
        onClick={() => {
          if (window.location.pathname !== "/welcome")
            permissions.showDeviceManagementTab
              ? setActiveItem("device-management")
              : setActiveItem("dashboards");
        }}
      >
        <ByteBeamLogoSVG height="30px" />
      </Menu.Item>

      {/* Navbar Left Elements */}
      {menuTab(
        "get-started",
        "Get Started",
        `/projects/${currentTenant}/get-started`,
        !userSettingsGetStartedSkip && showGetStartedForHost
      )}
      {menuTab(
        "device-management",
        "Device Management",
        `/projects/${currentTenant}/device-management/devices`,
        permissions.showDeviceManagementTab
      )}
      {menuTab(
        "dashboards",
        "Dashboards",
        `/projects/${currentTenant}/dashboards`,
        permissions.showDashboardsTab
      )}
      {menuTab(
        "actionsv3",
        "Actions",
        `/projects/${currentTenant}/actionsv3`,
        permissions.showActionsTab
      )}
      {/* {menuTab(
        "actionsv3",
        "Actions V3",
        `/projects/${currentTenant}/actionsv3`,
        permissions.showActionsTab
      )} */}
      {/* {menuTab(
        "alerts",
        "Alerts",
        `/projects/${currentTenant}/alerts`,
        user.role.name === "admin"
      )} */}

      {/* Navbar Right Elements */}
      {user && (
        <Menu.Menu position="right">
          {window.location.pathname !== "/welcome" && (
            <Menu.Item name="help" onClick={() => openArchBeeWidget("help")}>
              <Icon name="help circle" size="small" className="help-icon" />
            </Menu.Item>
          )}

          <Menu.Item
            style={{
              padding: "0px",
              position: "relative",
            }}
            name="user"
            active={activeItem === "user"}
          >
            <NotificationHighlight
              isVisible={userSettings?.current_release_id === currentReleaseId}
            />
            <Dropdown
              className="link item customDropDown"
              style={{
                height: "100%",
                minWidth: "160px",
                display: "flex",
                justifyContent: "center",
                alignItem: "center",
              }}
              labeled
              button
              scrolling
              text={capitalizeFirstLetter(user?.name)}
            >
              <Dropdown.Menu>
                <UserSummaryWithProjectPicker
                  currentTenant={currentTenant}
                  setCurrentTenant={setCurrentTenant}
                  isMultiTenant={isMultiTenant}
                  setShowNewProjectModal={setShowNewProjectModal}
                />

                {/* Below Dropdown.Item contains code contains Release Notes */}
                <Dropdown.Item
                  style={{ position: "relative" }}
                  onClick={() => {
                    setActiveItem("user");
                    handleReleaseNoteClick();
                  }}
                >
                  <NotificationHighlight
                    isVisible={
                      userSettings?.current_release_id === currentReleaseId
                    }
                  />
                  <Icon name="heart" /> What's New !
                </Dropdown.Item>

                {(userSettingsGetStartedSkip || !showGetStartedForHost) && (
                  <Dropdown.Item
                    icon="book"
                    text="Get Started"
                    onClick={() => {
                      history.push(`/projects/${currentTenant}/get-started`);
                      setActiveItem("user");
                    }}
                  />
                )}

                {showSettingsTab() && (
                  <Dropdown.Item
                    icon="setting"
                    text="Settings"
                    onClick={() => {
                      history.push(`/projects/${currentTenant}/settings`);
                      setActiveItem("user");
                    }}
                  />
                )}

                {/* Below Dropdown.Item contains code contains theme toggle */}
                <Dropdown.Item style={{ position: "relative" }}>
                  <Icon name="adjust" /> Dark Mode{" "}
                  <Toggle
                    id="theme-toggle"
                    size="medium"
                    style={{
                      marginLeft: "12px",
                      top: "4px",
                    }}
                    // checked={userSettings?.theme !== "light"}
                    checked={themeContext.name.toLowerCase() !== "light"}
                    onClick={() => handleThemeToggle()}
                  />
                </Dropdown.Item>

                {/* Below Dropdown.Item contains code contains Logout  */}
                <Dropdown.Item
                  icon="power off"
                  text="Logout"
                  onClick={() => {
                    Mixpanel.track("Logout");
                    window.location.href = "/logout";
                  }}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
        </Menu.Menu>
      )}
    </Menu>
  );
}

export default DesktopNavBar;
