import React, { useState, useEffect } from "react";
import { Button, Dropdown, DropdownItemProps, Modal } from "semantic-ui-react";
import { Role } from "../../../../util";
import { StyledInput, StyledLabel } from "../actiontypes/ActionTypesModal";

interface CreateApiKeyModalProps {
  apiKey: { role: string };
  title: string;
  onSubmit: (ApiKey) => void;
  trigger: React.ReactNode;
  roles: Role[];
}

export default function CreateApiKeyModal(props: CreateApiKeyModalProps) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = useState<DropdownItemProps[]>([]);
  const [apikey, setApikey] = useState<{ role: string }>(props.apiKey);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    setOptions(
      props.roles.map((role, index) => ({
        key: role.id,
        text: role.name,
        value: index,
      }))
    );
  }, [props.roles]);

  useEffect(() => {
    if (apikey.role.length > 0) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [apikey]);

  return (
    <Modal
      className="dark"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="mini"
      trigger={props.trigger}
    >
      <Modal.Header>{props.title}</Modal.Header>
      <Modal.Description></Modal.Description>
      <Modal.Content>
        <StyledInput labelPosition="left">
          <StyledLabel>Select Role</StyledLabel>
          <Dropdown
            placeholder="Please your select Role"
            fluid
            selection
            search
            options={options}
            // value={apikey.role_id}
            onChange={(_e, d) => {
              setApikey({
                ...apikey,
                role: props.roles[d.value as number]["name"],
              });
            }}
            style={{
              padding: "8px 12px",
              border: "none",
            }}
          />
        </StyledInput>
      </Modal.Content>
      <Modal.Actions>
        <Button
          secondary
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          primary
          disabled={buttonDisabled}
          onClick={() => {
            props.onSubmit(apikey);
          }}
        >
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
