import React, { useState, useEffect } from "react";
import {
  Route,
  Switch,
  useLocation,
  RouteComponentProps,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import styled from "styled-components";
import LiveActionsIcon from "../../../common/LiveActionsIcon";
import ActionsMenuTab from "./ActionsMenuTab";
import { DisplayIf } from "../../util";
import LiveActions from "./LiveActions";
import InventoryManagement from "../Inventory/InventoryManagement";
import NewAction from "./new-action/NewAction";
import { useUser } from "../../../../context/User.context";
import LogsManagement from "../Logs/LogsManagement";
import ActionButton from "./ActionButton";

export const ActionsMenuWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
`;

interface ActionProps extends RouteComponentProps {
  readonly currentTenant: string;
}

function ActionsV3(props: ActionProps) {
  const { user } = useUser();
  const permissions = user.role.permissions;
  const { path } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const [activeItem, setActiveItem] = useState<string>("");
  const [doParamsExist, setDoParamsExist] = useState<boolean>(false);

  const searchParams = new URLSearchParams(location.search);

  const [actionIDParam, setActionIDParam] = useState<any>(
    searchParams.get("action_id")
  );

  const handleItemClick = (name) => {
    setActiveItem(name);
  };

  useEffect(() => {
    document.title = "Action | Bytebeam";
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let activeItem: string;

    switch (location.pathname) {
      case `${path}/actionsv3`:
        activeItem = "live actions";
        // props.history.push(
        //   `${path}/actionsv3/live-actions`,
        // );
        break;

      case `${path}/actionsv3/`:
        activeItem = "live actions";
        // props.history.push(
        //   `${path}/actionsv3/live-actions`,
        // );
        break;

      case `${path}/actionsv3/live-actions`:
        activeItem = "live actions";
        break;

      case `${path}/actionsv3/inventory`:
        activeItem = "actions inventory";
        break;

      case `${path}/actionsv3/inventory/firmware-files`:
        activeItem = "actions inventory";
        break;

      case `${path}/actionsv3/new-action`:
        activeItem = "new action";
        break;

      default:
        activeItem = "live actions";
        break;
    }

    // handleItemClick(activeItem);
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {/* <Router basename={`/projects/${props.currentTenant}/actionsv3/`}> */}
      {doParamsExist ? (
        <ActionButton
          marginLeft="0px"
          label="Action Summary"
          icon="left chevron"
          onClick={() => {
            history.push(`${path}/live-actions?action_id=${actionIDParam}`);
          }}
        />
      ) : (
        <ActionsMenuWrapper className="desktop-view">
          <ActionsMenuTab
            firstElement
            name={"Action Details"}
            icon={LiveActionsIcon}
            to={`${path}/live-actions`}
            displayIf={true}
            active={activeItem === "live actions"}
            onClick={() => {
              handleItemClick("live actions");
            }}
          />

          <ActionsMenuTab
            firstDivider
            name={"Inventory"}
            icon={"boxes"}
            to={`${path}/inventory`}
            displayIf={true}
            active={activeItem === "actions inventory"}
            onClick={() => {
              handleItemClick("actions inventory");
            }}
          />

          <ActionsMenuTab
            name={"New Action"}
            icon={"plus square outline"}
            to={`${path}/new-action`}
            displayIf={true}
            active={activeItem === "new action"}
            onClick={() => {
              handleItemClick("new action");
            }}
            lastElement
          />
        </ActionsMenuWrapper>
      )}

      <Switch>
        <Route exact path={`${path}/`}>
          <DisplayIf cond={permissions.allowedActions?.length > 0}>
            <LiveActions
              doParamsExist={doParamsExist}
              setDoParamsExist={(value) => setDoParamsExist(value)}
              setActionIDParam={(actionID) => setActionIDParam(actionID)}
              setActiveItem={(value) => setActiveItem(value)}
            />
          </DisplayIf>
        </Route>

        <Route exact path={`${path}/live-actions`}>
          <DisplayIf cond={permissions.allowedActions?.length > 0}>
            <LiveActions
              doParamsExist={doParamsExist}
              setDoParamsExist={(value) => setDoParamsExist(value)}
              setActionIDParam={(actionID) => setActionIDParam(actionID)}
              setActiveItem={(value) => setActiveItem(value)}
            />
          </DisplayIf>
        </Route>

        <Route path={`${path}/inventory`}>
          <DisplayIf
            cond={permissions.viewFirmwares || permissions.viewDeviceConfigs}
          >
            <InventoryManagement />
          </DisplayIf>
        </Route>

        <Route exact path={`${path}/new-action`}>
          <DisplayIf cond={permissions.allowedActions?.length > 0}>
            <NewAction />
          </DisplayIf>
        </Route>

        <Route path={`${path}/logs`}>
          <DisplayIf cond={true}>
            <LogsManagement />
          </DisplayIf>
        </Route>
        {/* <Redirect exact from={path} to={`${path}/action-summary`} /> */}
      </Switch>
      {/* </Router> */}
    </>
  );
}

export default ActionsV3;
