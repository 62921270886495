import React, { useState, useEffect } from "react";
import { Table, Button, Grid, Popup } from "semantic-ui-react";
import { ErrorMessage } from "../../../../common/ErrorMessage";
import {
  fetchAllFirmwares,
  downloadFirmware,
  FirmwareType,
} from "../../../../../BytebeamClient";
import ActivateFirmwareModal from "./ActivateFirmwareModal";
import CreateFirmwareModal from "./CreateFirmwareModal";
import { DisplayIf, getHumanReadableFileSizeString } from "../../../util";
import Toggle from "../../../../common/Toggle";
import LoadingAnimation from "../../../../common/Loader";
import { useUser } from "../../../../../context/User.context";
import BrowserUpdatedIcon from "../../../../../assets/svg/BrowserUpdatedIcon";
import BackupIcon from "../../../../../assets/svg/BackupIcon";
import SlicedTextPopUp from "../../../DeviceMangaement/Devices/SlicedTextPopUp";

function FirmwareVersions() {
  const { user } = useUser();
  const permissions = user?.role?.permissions || {};

  const [loading, setLoading] = useState<boolean>(true);
  const [firmwareVersion, setFirmwareVersion] = useState<FirmwareType>(
    {} as FirmwareType
  );
  const [firmwareVersions, setFirmwareVersions] = useState<FirmwareType[]>(
    [] as FirmwareType[]
  );
  const [allExistingFirmwareVersions, setAllExistingFirmwareVersions] =
    useState<FirmwareType[]>([] as FirmwareType[]);
  const [activateFirmwareModalIsOpen, setActivateFirmwareModalIsOpen] =
    useState<boolean>(false);
  const [createFirmwareModalIsOpen, setCreateFirmwareModalIsOpen] =
    useState<boolean>(false);
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);
  const [showDeactivated, setShowDeactivated] = useState<boolean>(false);

  const [toggleIsLocked, setToggleIsLocked] = useState<boolean>(true);

  // Create Firmware Modal
  const openCreateFirmwareModal = () => {
    setCreateFirmwareModalIsOpen(true);
  };
  const closeCreateFirmwareModal = () => {
    setCreateFirmwareModalIsOpen(false);
  };

  // Activate Firmware Modal
  const openActivateFirmwareModal = () => {
    setActivateFirmwareModalIsOpen(true);
  };
  const closeActivateFirmwareModal = () => {
    setActivateFirmwareModalIsOpen(false);
  };

  async function fillFirmwareTable(showDeactivatedFirmwares?: boolean) {
    setLoading(true);
    try {
      const response = await fetchAllFirmwares();
      const filteredResponse = response.filter(
        (version) =>
          (typeof showDeactivatedFirmwares === "boolean"
            ? showDeactivatedFirmwares
            : showDeactivated) || !version.is_deactivated
      );
      setAllExistingFirmwareVersions(response);
      setFirmwareVersions(filteredResponse);
    } catch (error) {
      setErrorOccurred(true);
    } finally {
      setLoading(false);
    }
  }

  async function downloadFile(data: Blob, fileName: string) {
    try {
      const element = document.createElement("a");
      const file = new Blob([data], {
        type: "text/plain",
      });

      element.href = URL.createObjectURL(file);
      element.download = fileName;
      document.body.appendChild(element);
      setTimeout(() => element.click());
    } catch (error) {
      console.log("Error while creating file: ", error);
    }
  }

  async function downloadFirmwareByVersion(version: string, file: string) {
    try {
      const res = await downloadFirmware(version);
      const blob: Blob = await res?.blob();
      downloadFile(blob, `firmware_${version}-${file}`);
    } catch (error) {
      console.log("Error while downloading firmware: ", error);
    }
  }

  async function handleShowDeactivatedToggle() {
    setShowDeactivated(!showDeactivated);
    await fillFirmwareTable(!showDeactivated);
  }

  useEffect(() => {
    fillFirmwareTable();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (errorOccurred) {
    return <ErrorMessage marginTop="30px" errorMessage />;
  }

  if (loading) {
    return (
      <LoadingAnimation
        loaderContainerHeight="65vh"
        fontSize="1.5rem"
        loadingText="Loading Firmware Versions"
      />
    );
  }

  return (
    <Grid>
      {/* Modal for activating and deactivating firmware */}
      <ActivateFirmwareModal
        isOpen={activateFirmwareModalIsOpen}
        close={closeActivateFirmwareModal}
        firmwareVersion={firmwareVersion}
        fillFirmwareTable={fillFirmwareTable}
        toggleHandler={() => setToggleIsLocked(!toggleIsLocked)}
      />

      {/* Modal for creating new firmware version */}
      <CreateFirmwareModal
        type="Add"
        id=""
        isOpen={createFirmwareModalIsOpen}
        close={closeCreateFirmwareModal}
        allExistingFirmwareVersions={allExistingFirmwareVersions}
        fillFirmwareTable={fillFirmwareTable}
      />

      <Grid.Row>
        <Grid.Column width="3">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
              marginTop: "16px",
              fontWeight: "bold",
            }}
          >
            <div>Show Deactivated</div>
            <div>
              <Toggle
                id="showDeactivatedFirmware"
                checked={showDeactivated}
                onClick={async () => await handleShowDeactivatedToggle()}
                style={{
                  top: "3px",
                }}
              />
            </div>
          </div>
        </Grid.Column>

        <Grid.Column floated="right" width="3">
          <DisplayIf cond={permissions.editFirmwares}>
            <Button
              id="upload_firmware_button"
              primary
              floated="right"
              onClick={() => {
                openCreateFirmwareModal();
              }}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "18px",
                whiteSpace: "nowrap",
              }}
            >
              <BackupIcon height="16px" altColor="#fff" />
              Upload New Firmware
            </Button>
          </DisplayIf>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Table >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="center">
                  Version Number
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  File Name
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  File Size
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  Created At
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Status</Table.HeaderCell>
                <DisplayIf cond={permissions.editFirmwares}>
                  <Table.HeaderCell textAlign="center">
                    Options
                  </Table.HeaderCell>
                </DisplayIf>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {firmwareVersions.length !== 0 ? (
                firmwareVersions.map((firmware) => {
                  return (
                    <Table.Row key={firmware.version_number}>
                      <Table.Cell textAlign="center">
                        {firmware.version_number}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <SlicedTextPopUp
                          text={firmware.file_name}
                          length={18}
                        />
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {getHumanReadableFileSizeString(
                          firmware.content_length
                        )}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {new Date(firmware.created_at).toLocaleString()}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {firmware.is_deactivated ? "Deactivated" : "Active"}
                      </Table.Cell>
                      {/* check access to edit firmware */}
                      <DisplayIf cond={permissions.editFirmwares}>
                        <Table.Cell
                          textAlign="center"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "18px",
                          }}
                        >
                          <Popup
                            trigger={
                              <BrowserUpdatedIcon
                                height="14px"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  downloadFirmwareByVersion(
                                    firmware.version_number,
                                    firmware.file_name
                                  );
                                }}
                              />
                            }
                            content="Download Firmware Version"
                            position="top center"
                            inverted
                          />
                          <Popup
                            trigger={
                              <Toggle
                                id={`${firmware.version_number} deactivateFirmwareToggle`}
                                checked={!firmware.is_deactivated}
                                isLocked={toggleIsLocked}
                                onClick={() => {
                                  openActivateFirmwareModal();
                                  setFirmwareVersion(firmware);
                                  setToggleIsLocked(true);
                                }}
                              />
                            }
                            content={`Click to ${
                              firmware.is_deactivated
                                ? "activate"
                                : "deactivate"
                            } firmware version`}
                            position="top center"
                            inverted
                            style={{ whiteSpace: "nowrap" }}
                          />
                        </Table.Cell>
                      </DisplayIf>
                    </Table.Row>
                  );
                })
              ) : (
                <Table.Row>
                  <Table.Cell
                    textAlign="center"
                    colspan={permissions.editFirmwares ? "6" : "5"}
                  >
                    <ErrorMessage
                      marginTop="30px"
                      message={"No Firmwares Uploaded!"}
                    />
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default FirmwareVersions;
