import React from "react";
import { Grid, Table } from "semantic-ui-react";
import { StyledGridColumn, LightMutedText } from "./Device";
import TextWithToolTip from "./TextWithToolTip";
import { capitalizeFirstLetter } from "../../util";
import { MetadataCell, StyledIcon } from "./MetadataCell";
import LastActionInfoTable, { LastActionType } from "./LastActionInfoTable";
import { Permission } from "../../../../util";
import { Device } from "../../../../BytebeamClient";
import { DeviceStyledTable } from "../../Actions/DeviceList";

type DeviceOverviewType = {
  permissions: Permission;
  stateKeys: string[];
  device: Device;
  deviceId: number;
  lastAction: LastActionType;
  metadataKeys: string[];
  enablePinMetadata: boolean;
  pinMetadata?: (key: string) => Promise<void>;
  pinnedMetadataKeys?: string[];
  editableMetadataKeys: Set<string>;
  specificAction?: boolean;
};

function DeviceOverview(props: DeviceOverviewType) {
  const {
    permissions,
    stateKeys,
    device,
    deviceId,
    lastAction,
    metadataKeys,
    enablePinMetadata,
    pinMetadata,
    pinnedMetadataKeys,
    editableMetadataKeys,
    specificAction,
  } = props;

  const isMetadataEmpty: boolean = metadataKeys?.length !== 0;

  return (
    <Grid.Row>
      {Object.keys(permissions?.tables).length > 0 && (
        <StyledGridColumn style={{ alignItems: "flex-start" }}>
          <DeviceStyledTable compact fixed id={`${deviceId}-device_shadow`}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ padding: "9px 9.8px" }} colSpan={2}>
                  Device Shadow
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {stateKeys.map((i) => {
                return (
                  i !== "timestamp" &&
                  i !== "sequence" &&
                  !i.endsWith("_timestamp") && (
                    <Table.Row key={`${i}`}>
                      <Table.Cell>
                        <LightMutedText>
                          {capitalizeFirstLetter(i)}
                        </LightMutedText>
                      </Table.Cell>
                      <Table.Cell>
                        <TextWithToolTip
                          text={device?.state[i]}
                          textList={[
                            device?.state[`.${i}_timestamp`] &&
                            (device?.state[i] || device?.state[i] === 0)
                              ? device?.state[`.${i}_timestamp`] === 0 ||
                                new Date(
                                  device?.state[`.${i}_timestamp`] + "Z"
                                ).getTime() === 0 // checking if it epoch time
                                ? ""
                                : `Updated: ${new Date(
                                    device?.state[`.${i}_timestamp`] + "Z"
                                  ).toLocaleString("en-GB")}`
                              : "",
                          ]}
                          length={10}
                        />
                      </Table.Cell>
                    </Table.Row>
                  )
                );
              })}
            </Table.Body>
          </DeviceStyledTable>
        </StyledGridColumn>
      )}

      <StyledGridColumn style={{ alignItems: "flex-start" }}>
        <DeviceStyledTable compact fixed id={`${deviceId}-metadata`}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{ padding: "9px 9.8px" }} colSpan={2}>
                Metadata
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {isMetadataEmpty ? (
              Object.keys(device?.metadata)?.map((key) => {
                return (
                  <Table.Row key={`${deviceId}-${key}`}>
                    <Table.Cell>
                      <LightMutedText className="metadata-cell-container">
                        <TextWithToolTip
                          notBold
                          text={capitalizeFirstLetter(key)}
                          length={10}
                        />
                        {permissions.editTenantSettings && enablePinMetadata ? (
                          <StyledIcon
                            name="pin"
                            id={`${deviceId}-pin-${key}`}
                            className="metadata-cell-icon"
                            style={
                              pinnedMetadataKeys?.includes(key)
                                ? {
                                    visibility: "visible",
                                    transform: "rotate(45deg)",
                                    cursor: "pointer",
                                  }
                                : {
                                    transform: "rotate(45deg)",
                                    cursor: "pointer",
                                  }
                            }
                            onClick={async () => {
                              if (
                                pinMetadata !== undefined &&
                                enablePinMetadata
                              )
                                await pinMetadata(key);
                            }}
                          />
                        ) : null}
                      </LightMutedText>
                    </Table.Cell>
                    <Table.Cell>
                      <MetadataCell
                        id={deviceId}
                        keyName={key}
                        text={device?.metadata[key]}
                        editable={editableMetadataKeys.has(key)}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })
            ) : (
              <Table.Row>
                <Table.Cell>No Metadata</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </DeviceStyledTable>
      </StyledGridColumn>

      <StyledGridColumn style={{ alignItems: "flex-start" }}>
        <LastActionInfoTable
          lastAction={lastAction}
          deviceId={deviceId}
          specificAction={specificAction}
        />
      </StyledGridColumn>
    </Grid.Row>
  );
}

export default DeviceOverview;
