import React, { Component } from "react";
import { Button, Modal, Icon } from "semantic-ui-react";
import { fetchAllDevices, fetchDevicesCount } from "../../../../../BytebeamClient";
import { MessageContainer } from "./BulkMetadataUpdateModal";
import csvjson from "csvjson";
import { saveAs } from "file-saver";
import LoadingAnimation from "../../../../common/Loader";

function replaceNullWithEmptyString(object) {
  for (let key in object) {
    if (object[key] === null) {
      object[key] = "";
    }
  }
}

// Returns a csv file content with all devices metadata
export async function getAllDevicesMetadata(
  totalDevices: number
): Promise<string> {
  try {
    // totalDevices is possible maximum number of devices (totalDevices = page * devicesPerPage)
    const devices = await fetchAllDevices(1, totalDevices);
    let metadata: any[] = [];

    for (const device of devices) {
      const object = {};
      object["id"] = device["id"];
      replaceNullWithEmptyString(device["metadata"]);
      object["Metadata"] = device["metadata"];
      metadata.push(object);
    }

    let csvData: string = csvjson.toCSV(metadata, { delimiter: "," });

    // To give a proper header to the csv file
    csvData = csvData.replace("[].", "");
    csvData = csvData.replaceAll("[].Metadata.", "");
    return csvData;
  } catch (error) {
    console.error("Error While Fetching all devices data: ", error);
    throw error;
  }
}

type DownloadMetadataModalProps = {
  close: (...args: any[]) => any;
  totalDevices: number;
};

type DownloadMetadataModalState = {
  buttonDisabled: boolean;
};

export class DownloadMetadataModal extends Component<
  DownloadMetadataModalProps,
  DownloadMetadataModalState
> {
  constructor(props) {
    super(props);
    this.state = {
      buttonDisabled: false,
    };
  }

  async downloadAllDevicesMetadata() {
    try {
      // Have to fetch total devices count again, because the props will have totalDevices count of the filtered selection
      let totalDevices = await fetchDevicesCount( "active");
      const csvData: BlobPart = await getAllDevicesMetadata(
        totalDevices
      );
      this.setState({
        buttonDisabled: false,
      });

      const file = new File([csvData], "metadata.csv", {
        type: "text/csv",
      });
      saveAs(file);
    } catch (error) {
      console.log("Error in downloading metadata: ", error);
    }
  }

  render() {
    return (
      <>
        <Modal.Content>
          <Modal.Description>
            <MessageContainer>
              {this.state.buttonDisabled ? (
                <LoadingAnimation
                  loadingText="Downloading all devices metadata..."
                  loaderBorderSize="3px"
                  loaderSize="30px"
                  fontSize="14px"
                />
              ) : (
                <p>
                  Download metadata for all the devices by clicking on the{" "}
                  <b>Download Metadata</b> button. Alternatively, click on the{" "}
                  <b>Back</b> button to return to the previous screen.
                </p>
              )}
            </MessageContainer>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            secondary
            onClick={() => {
              this.props.close();
            }}
          >
            <Icon name="angle left" /> Back
          </Button>
          {/* disable the button unless parsing and fetching is done */}
          <Button
            primary
            disabled={this.state.buttonDisabled}
            onClick={async () => {
              this.setState({
                buttonDisabled: true,
              });
              await this.downloadAllDevicesMetadata();
              this.props.close();
            }}
          >
            <Icon name="checkmark" /> Download Metadata
          </Button>
        </Modal.Actions>
      </>
    );
  }
}
