import React, { Component } from "react";
import { ActionButtons, ActionButton } from "./ActionButtons";
import { UpdateConfigModal } from "./ActionModals/UpdateConfigModal";
import { UpdateFirmwareModal } from "./ActionModals/UpdateFirmwareModal";
import { RemoteShellModal } from "./ActionModals/RemoteShellModal";
import DeviceProvisionModal from "../deviceProvisionModal";
import { ActionConfirmationModal } from "./ActionModals/ActionConfirmationModal";
import { UpDownArrows } from "../../common/UpDownArrows.svg";
import {
  Icon,
  Input,
  SemanticICONS,
  Accordion,
  Popup,
  Dropdown,
  Pagination,
  Checkbox,
  Container,
  Grid,
  MenuItem,
} from "semantic-ui-react";
import {
  fetchAllDevices,
  fetchDevicesCount,
  filterDeviceSearch,
  triggerDeviceAction,
  downloadCertificates,
  changeDeviceStatus,
  Device,
  fetchAllDashboards,
  fetchTableInfo,
  devicesPerPageOptions,
} from "../../../../BytebeamClient";
import { Mixpanel } from "../../common/MixPanel";
import Toggle from "../../../common/Toggle";
import {
  User,
  Permission,
  ActionType,
  validateWholeNumber,
} from "../../../../util";
import {
  Column,
  capitalizeFirstLetter,
  DisplayIf,
  HARDWARE_TYPES,
  filterTableInfo,
} from "../../util";
import moment, { Moment } from "moment";
import DeviceCard, { StyledGridColumn } from "./Device";
import BulkMetadataOperationsModal from "./ActionModals/BulkMetadataOperationsModal";
import { SendFileModal } from "./ActionModals/SendFileModal";
import { ErrorMessage } from "../../../common/ErrorMessage";
import styled from "styled-components";
import _ from "lodash";
import { ThinDivider } from "../../Dashboards/Panel/util";
import StyledSVGIcon from "../../../common/StyledSVGIcon";
import { SearchPageInput } from "../../Actions/action-status";
import { SendScriptModal } from "./ActionModals/SendScriptModal";
import LoadingAnimation from "../../../common/Loader";
import { StyledDevicePerPageWidget } from "../../../common/commonStyledComps";

type DevicesProps = {
  user: User;
};

type DevicesState = {
  loading: boolean;
  devices: Device[];
  pageCount: number;
  options: Array<{ key: string; text: string; value: string }>;
  selectedDevices: Set<string | number>;
  selectedDeviceCount: number | string;
  updateFirmwareModalIsOpen: boolean;
  sendFileModalIsOpen: boolean;
  updateConfigModalIsOpen: boolean;
  updateGeofenceModalIsOpen: boolean;
  updateScriptModalIsOpen: boolean;
  bulkMetadataOperationsModalIsOpen: boolean;
  openConfirmationModal: boolean;
  remoteShellDeviceId: number;
  activeSearchTab: string;
  searchModeOn: boolean;
  allSelected: boolean;
  stateKeys: string[];
  metadataKeys: string[];
  errorOccurred: boolean;
  activePage: number;
  actionButtonsDisabled: boolean;
  searchQuery: string;
  lastRefreshTime: Moment | null;
  isRefreshStale: boolean;
  eventType: ActionType;
  activeIndex: number;
  showInactiveDevice: boolean;
  showInactiveDeviceToggle: boolean;
  devicesPerPage: number;
  dashboards: any[];
  streamsList: Record<string, string[]>;
  devicesCount: number;
  inputPageNumber: number;
};

export function getStringifiedDeviceIDs(deviceIDArr) {
  return deviceIDArr.map((deviceID) => "" + deviceID);
}

const DeviceCountLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 56px;
  background: #fff;
  color: #000;
  height: 28px;
  width: 52px;
  font-size: 12px !important;
  font-weight: 700 !important;
  border-radius: 0px 8px 8px 0px;
  border: ${(props) => props.theme.colors["container-border"]} !important;
  box-shadow: ${(props) => props.theme.colors["container-box_shadow"]};
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 100;

  &::before {
    content: "";
    position: absolute;
    left: -14px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 14px 14px 14px 0;
    border-color: transparent #ffffff transparent transparent;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const SearchInput = styled(Input)`
  margin-bottom: 0 !important;
  color: ${({ theme }) => theme.colors["foreground-color"]} !important;
  background: ${({ theme }) =>
    theme.colors["search_input-background"]} !important;
  border-radius: 6px !important;
  border: ${({ theme }) => theme.colors["container-border"]} !important;
  box-shadow: ${({ theme }) =>
    theme.colors["search_input-box_shadow"]} !important;
  input {
    color: ${({ theme }) => theme.colors["foreground-color"]} !important;
    background: ${({ theme }) =>
      theme.colors["search_input-background"]} !important;
    border-radius: 6px !important;
  }

  input::placeholder {
    color: ${({ theme }) =>
      theme.colors["search_input-placeholder-color"]} !important;
  }

  input:focus::placeholder {
    color: ${({ theme }) =>
      theme.colors["search_input-placeholder-color-focus"]} !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    color: ${({ theme }) => theme.colors["foreground-color"]} !important;
    -moz-appearance: textfield;
    appearance: textfield;
  }
  .dropdown {
    color: ${({ theme }) => theme.colors["foreground-color"]} !important;
    border-left: none !important;
    .divider.text {
      color: ${({ theme }) => theme.colors["foreground-color"]} !important;
    }
    &::focus {
      background: ${({ theme }) =>
        theme.colors["search_input-background"]} !important;
      outline: none !important;
    }
  }
`;

export const SelectDevicesPerPage = styled(Dropdown)`
  min-width: 65px !important;
  color: ${({ theme }) => theme.colors["text"]} !important;
  border: none !important;

  .visible.menu {
    margin: 0px !important;
  }
  .visible.menu::after {
    display: none !important;
  }

  &:hover {
    color: white !important;
  }
`;

export const StyledHeaderContainer = styled(Container)`
  padding: 20px;
  margin-top: 20px;
  color: #ffffff50;
  border-radius: 12px !important;
`;

export const StyledBodyContainer = styled(Container)`
  margin-bottom: 20px;
`;

const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
`;

const ActionBarRight = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
`;

export default class Devices extends Component<DevicesProps, DevicesState> {
  state = {
    loading: true,
    devices: [] as Device[],
    pageCount: 0,
    options: [{ key: "id", text: "Device ID", value: "id" }],
    selectedDevices: new Set(""),
    selectedDeviceCount: 0,
    updateFirmwareModalIsOpen: false,
    sendFileModalIsOpen: false,
    updateConfigModalIsOpen: false,
    updateGeofenceModalIsOpen: false,
    updateScriptModalIsOpen: false,
    bulkMetadataOperationsModalIsOpen: false,
    openConfirmationModal: false,
    remoteShellDeviceId: -1,
    activeSearchTab: "id",
    searchModeOn: false,
    allSelected: false,
    stateKeys: [],
    metadataKeys: [],
    errorOccurred: false,
    activePage: 1,
    inputPageNumber: 0,
    actionButtonsDisabled: false,
    searchQuery: "",
    lastRefreshTime: null,
    isRefreshStale: false,
    eventType: {
      type: "",
      icon: "" as SemanticICONS,
      payload_type: "none",
    },
    activeIndex: -1,
    // showInactiveDevice hides or shows the devices
    showInactiveDevice: false,
    // showInactiveDeviceToggle hides or shows the toggle and not the devices
    showInactiveDeviceToggle: true,
    devicesPerPage: 10,
    dashboards: [],
    streamsList: {},
    devicesCount: 0,
  };

  abortController = new AbortController();
  timeoutId;
  isComponentMounted: boolean = true;

  onSelectedDeviceCountChange = (count: number | string) => {
    this.setState({
      selectedDeviceCount: count,
    });
  };

  selectAll = () => {
    this.setState({
      allSelected: true,
    });
    this.onSelectedDeviceCountChange("All");
  };

  clearAll = () => {
    this.setState({
      allSelected: false,
      selectedDevices: new Set(),
    });
    this.onSelectedDeviceCountChange(0);
  };

  selectDevice = (id) => {
    const newSet: Set<string | number> = new Set(
      Array.from(this.state.selectedDevices)
    ).add(id);
    this.setState({
      selectedDevices: newSet,
    });
    this.onSelectedDeviceCountChange(newSet.size);
  };

  clearDevice = (id: number) => {
    const newSet: Set<string | number> = new Set(
      Array.from(this.state.selectedDevices)
    );
    const deleted: boolean = newSet.delete(id);
    if (deleted) {
      this.setState({
        selectedDevices: newSet,
        allSelected: false,
      });
    }
    this.onSelectedDeviceCountChange(newSet.size);
  };

  setActionButtonsDisabled = (value) => {
    this.setState({
      actionButtonsDisabled: value,
    });
  };

  checkIfSelectedDevicesAreActive = () => {
    const selectedDevices = Array.from(this.state.selectedDevices);
    const activeDevices = this.state.devices
      .filter((device) => device.status === "active")
      .map((device) => device.id.toString());
    return selectedDevices.some((id) => activeDevices.includes(id.toString()));
  };

  triggerAction = async (actionName, params = {}) => {
    let body = {};
    this.setActionButtonsDisabled(true);
    try {
      if (this.state.allSelected) {
        if (this.state.searchModeOn) {
          body = {
            device_ids: [],
            search_type: "allSearch",
            search_key: this.state.activeSearchTab,
            search_query: this.state.searchQuery,
          };
        } else {
          body = { device_ids: [], search_type: "all" };
        }
      } else {
        if (!this.checkIfSelectedDevicesAreActive()) {
          window.toastr.info(`Can not trigger actions for inactive devices!`);
          return;
        }
        body = {
          device_ids: getStringifiedDeviceIDs([
            ...Array.from(this.state.selectedDevices),
          ]),
          search_type: "default",
        };
      }

      body = {
        ...body,
        action: actionName,
        params,
      };
      await triggerDeviceAction(body);
      window.toastr.success(`${actionName} triggered successfully!`);
      Mixpanel.track("Triggered Action", {
        action: actionName,
      });
    } catch (e) {
      console.log(e);
      Mixpanel.track("Failure", {
        type: `trigger action ${actionName}`,
        error: JSON.stringify(e),
      });
    } finally {
      this.setActionButtonsDisabled(false);
    }
  };

  openUpdateFirmwareModal = () => {
    this.setState({
      updateFirmwareModalIsOpen: true,
    });
  };

  closeUpdateFirmwareModal = () => {
    this.setState({
      updateFirmwareModalIsOpen: false,
    });
  };

  openConfirmationModal = (actionType) => {
    this.setState({
      openConfirmationModal: true,
      eventType: actionType,
    });
  };

  closeConfirmationModal = () => {
    this.setState({
      openConfirmationModal: false,
    });
  };

  openUpdateConfigModal = () => {
    this.setState({
      updateConfigModalIsOpen: true,
    });
  };

  closeUpdateConfigModal = () => {
    this.setState({
      updateConfigModalIsOpen: false,
    });
  };

  openUpdateGeofenceModal = () => {
    this.setState({
      updateGeofenceModalIsOpen: true,
    });
  };

  closeUpdateGeofenceModal = () => {
    this.setState({
      updateGeofenceModalIsOpen: false,
    });
  };

  openUploadScriptModal = () => {
    this.setState({
      updateScriptModalIsOpen: true,
    });
  };

  closeUploadScriptModal = () => {
    this.setState({
      updateScriptModalIsOpen: false,
    });
  };

  openSendFileModal = () => {
    this.setState({
      sendFileModalIsOpen: true,
    });
  };

  closeSendFileModal = () => {
    this.setState({
      sendFileModalIsOpen: false,
    });
  };

  openBulkMetadataOperationsModal = () => {
    this.setState({
      bulkMetadataOperationsModalIsOpen: true,
    });
  };

  closeBulkMetadataOperationsModal = () => {
    this.setState({
      bulkMetadataOperationsModalIsOpen: false,
    });
  };

  setDevicePageToLoadingState = () => this.setState({ loading: true });

  handlePaginationInputChange = (event) => {
    const newValue = event.target.value;
    this.setState({
      inputPageNumber: newValue,
    });
  };

  handlePaginationInputKeyDown = (event) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      // If the pressed key is "Enter", trigger the function for changing active page

      if (validateWholeNumber(this.state.inputPageNumber.toString())) {
        this.handlePaginationChange(event, {
          activePage:
            this.state.inputPageNumber && this.state.inputPageNumber > 0
              ? this.state.inputPageNumber > this.state.pageCount
                ? this.state.pageCount
                : this.state.inputPageNumber
              : 1,
        });
        this.setState({
          inputPageNumber: 0,
        });
      } else {
        window.toastr.error("Please enter whole number for jump to page.");
      }
    }
  };

  handlePaginationChange = async (event, data) => {
    this.setState({ activePage: data.activePage, loading: true });
    await this.refreshDevices();
  };

  setPageCount = async (
    key: string,
    query: string = "",
    status: string = "active",
    abortSignal: AbortSignal | null = null
  ) => {
    try {
      const res = await fetchDevicesCount(key, query, status, abortSignal);
      const pageCount = Math.ceil(res / this.state.devicesPerPage);
      this.setState({
        pageCount,
        devicesCount: res,
      });
    } catch (e) {
      console.log(e);
    }
  };

  setRemoteShellDeviceId = (deviceId: number) => {
    this.setState({ remoteShellDeviceId: deviceId });
  };

  async refreshDevices() {
    const {
      showInactiveDevice,
      activeSearchTab,
      searchQuery,
      activePage,
      devicesPerPage,
    } = this.state;
    let devices: Device[] | Promise<Device[]> = [];
    let status = showInactiveDevice ? "all" : "active";

    this.abortController.abort();
    this.abortController = new AbortController();
    try {
      if (this.state.searchModeOn) {
        devices = await filterDeviceSearch(
          searchQuery,
          activeSearchTab,
          activePage,
          devicesPerPage,
          status,
          this.abortController.signal
        );
      } else {
        devices = await fetchAllDevices(
          activePage,
          devicesPerPage,
          status,
          this.abortController.signal
        );
      }

      this.setState({
        devices: devices,
      });

      await this.setPageCount(
        activeSearchTab,
        searchQuery,
        status,
        this.abortController.signal
      );
    } catch (e) {
      if (e instanceof DOMException) {
        // Request was cancelled by abort controller
        return;
      }
      this.setState({ errorOccurred: true });
      return;
    }
    this.setState({
      loading: false,
      lastRefreshTime: moment(),
    });
  }

  setShowInactiveDevice = (value: boolean) => {
    this.setState({
      showInactiveDevice: value,
    });
  };

  handleToggleClick = async () => {
    this.setShowInactiveDevice(!this.state.showInactiveDevice);
    this.setState({
      loading: true,
      activePage: 1,
      selectedDevices: new Set(),
      selectedDeviceCount: 0,
      allSelected: false,
    });
    await this.refreshDevices();
  };

  async getDashboardList() {
    try {
      const res = await fetchAllDashboards();
      this.setState({
        dashboards: res,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async getStreamList() {
    try {
      const res = filterTableInfo(await fetchTableInfo());
      this.setState({
        streamsList: res,
      });
    } catch (e) {
      console.log(e);
    }
  }

  downloadFile = async (data, key) => {
    const element = document.createElement("a");
    const file = new Blob([data], {
      type: "text/plain",
    });

    element.href = URL.createObjectURL(file);
    element.download = key;
    document.body.appendChild(element);

    setTimeout(() => element.click());
  };

  downloadCertificates = async (id: number) => {
    const res = await downloadCertificates(id);
    await this.downloadFile(JSON.stringify(res), `device_${id}.json`);
  };

  changeDeviceStatus = async (id: number, status: string) => {
    try {
      this.setState({ loading: true });
      await changeDeviceStatus(id, status);
      await this.refreshDevices();
      window.toastr.success(`Device status set to ${status}`);
    } catch (e) {
      window.toastr.error("Failed to change device status");
      console.error("Error in changeDeviceStatus: ", e);
    }
  };

  changeDevicesPerPage = async (e, data) => {
    try {
      this.setState({
        loading: true,
        devicesPerPage: data.value,
        activePage: 1,
      });
      window.localStorage.setItem("devicesPerPage", data.value);
      await this.refreshDevices();
    } catch (e) {
      window.toastr.error("Failed to change number of devices per page");
      console.error("Error in changeDeviceStatus: ", e);
    }
  };

  onSelect = (e, data) => {
    this.setState({
      activeSearchTab: data.value,
      searchModeOn: false,
      searchQuery: "",
      activePage: 1,
    });
  };

  setActiveIndex = (value: number) => {
    this.setState({ activeIndex: value });
  };

  // Functions used to set the state of the stateKeys and metadataKeys when the user value changes
  usingUpdatedUserPermissions = () => {
    const permissions: Permission = this.props.user?.role?.permissions;
    const deviceFilterKeys = Object.keys(permissions.devices || {});

    // Choosing not to show sequence in UI table, even though data is available via API.
    const stateKeys: string[] = (
      (permissions.tables["device_shadow"] as string[]) || ([] as string[])
    ).filter((key: string) => key !== "sequence");
    const metadataKeys: string[] =
      (permissions.viewMetadata as string[]) || ([] as string[]);

    const keyOptions = metadataKeys
      .filter((key) => deviceFilterKeys.indexOf(key) === -1)
      .map((key) => {
        return { key: key, text: capitalizeFirstLetter(key), value: key };
      });

    this.setState({
      options: _.uniq([
        { key: "id", text: "Device ID", value: "id" },
        ...keyOptions,
      ]),
      stateKeys,
      metadataKeys,
    });
  };

  async componentDidMount() {
    // Scroll to top and set document title
    this.initializeUI();

    // Run initial setup
    await this.runInitialSetup();

    // Start device refresh loop
    await this.startDeviceRefreshLoop();

    // Load settings from local storage
    this.loadSettingsFromLocalStorage();

    // Update user permissions
    this.usingUpdatedUserPermissions();
  }

  initializeUI() {
    window.scrollTo(0, 0);
    document.title = "Devices | Bytebeam";
  }

  async runInitialSetup() {
    try {
      const devices = await fetchAllDevices(
        this.state.activePage,
        this.state.devicesPerPage,
        "active"
      );
      await this.setPageCount("id", "", "active", this.abortController.signal);
      this.setState({
        devices,
        loading: false,
        lastRefreshTime: moment(),
      });

      await this.getDashboardList();
      await this.getStreamList();
    } catch (error) {
      console.error("Error during initial setup:", error);
    }
  }

  async startDeviceRefreshLoop() {
    try {
      await this.refreshDevices();
      if (this.isComponentMounted) {
        this.timeoutId = setTimeout(() => {
          this.startDeviceRefreshLoop();
        }, 1000);
      }
    } catch (error) {
      console.error("Error during device refresh:", error);
    }
  }

  loadSettingsFromLocalStorage() {
    const devicesCountPerPage = parseInt(
      window.localStorage.getItem("devicesPerPage") ?? "10"
    );
    this.setState({
      devicesPerPage: devicesCountPerPage,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user !== this.props.user) {
      // setting the state of the stateKeys and metadataKeys on user change
      this.usingUpdatedUserPermissions();
    }
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
    this.onSelectedDeviceCountChange(0);
    this.abortController.abort();
    clearTimeout(this.timeoutId);
  }

  renderDeviceTable(theme: string) {
    const permissions: Permission = this.props.user?.role?.permissions;
    const tenant_settings = this.props.user["tenant-settings"] ?? {
      common_settings: {
        pin_metadata: [],
      },
      dashboard_settings: {
        custom_time_ranges: {},
      },
      hardware_type: HARDWARE_TYPES[0],
    };
    const common_settings = tenant_settings?.common_settings ?? {
      pin_metadata: [],
    };
    const pinnedMetadataKeys = common_settings?.pin_metadata ?? [];
    const { stateKeys, metadataKeys, allSelected, selectedDevices } =
      this.state;
    const allowedActions = permissions.allowedActions || [];
    const editableMetadataKeys: Set<string> = new Set(
      permissions.editMetadata || []
    );

    function getColumnWidth(cellType: string) {
      switch (cellType) {
        // Actions column width should be 1
        case "action_checkbox":
          return 1;

        // id column width is dependent on the allowed actions and number of pinned metadata keys
        case "id":
          if (allowedActions.length > 0 && pinnedMetadataKeys.length > 0)
            return 1;
          else if (allowedActions.length > 0 && pinnedMetadataKeys.length === 0)
            return 2;
          else if (allowedActions.length === 0 && pinnedMetadataKeys.length > 0)
            return 1;
          else return 3;

        // Last Heartbeat column width is dependent on the number of pinned metadata keys
        case "last_heartbeat":
          if (pinnedMetadataKeys.length === 3) return 2;
          else return 3;

        // Status column width is also dependent on the number of pinned metadata keys
        case "status":
          if (pinnedMetadataKeys.length > 0) {
            if (pinnedMetadataKeys.length >= 2) return 1;
            else return 2;
          } else return 3;

        // Pinned metadata keys column width is always 2 for all pinned metadata keys
        case "pinned_metadata":
          return 2;

        // Action Type Column width is dependent on the number of pinned metadata keys
        case "action_type":
          if (pinnedMetadataKeys.length === 3) return 2;
          else return 3;

        // Last Action column width is dependent on the number of pinned metadata keys
        case "last_action":
          if (pinnedMetadataKeys.length >= 2) return 2;
          else return 3;

        default:
          return 1;
      }
    }

    return (
      <>
        <StyledHeaderContainer fluid>
          <Grid>
            <Grid.Row>
              {allowedActions.length > 0 ? (
                <StyledGridColumn
                  style={{ position: "relative", justifyContent: "flex-start" }}
                >
                  <Checkbox
                    width={getColumnWidth("action_checkbox")}
                    checked={this.state.allSelected}
                    // Select all devices checkbox should be disabled if there are no devices to restrict enabling Actions
                    disabled={this.state.devices.length === 0}
                    onChange={(e, { checked }) => {
                      if (checked) this.selectAll();
                      else this.clearAll();
                    }}
                  />
                  {this.state.selectedDeviceCount ? (
                    <Popup
                      content={"Unselect the devices"}
                      position="top center"
                      inverted
                      trigger={
                        <DeviceCountLabel
                          id="deviceCount_label"
                          onClick={this.clearAll}
                        >
                          {this.state.selectedDeviceCount}
                        </DeviceCountLabel>
                      }
                    />
                  ) : null}
                </StyledGridColumn>
              ) : null}
              <StyledGridColumn
                style={{ fontWeight: 600 }}
                width={getColumnWidth("id")}
              >
                #Id
              </StyledGridColumn>
              {Object.keys(permissions?.tables).length > 0 && (
                <StyledGridColumn
                  style={{ fontWeight: 600 }}
                  width={getColumnWidth("last_heartbeat")}
                >
                  Last Heartbeat
                </StyledGridColumn>
              )}
              <StyledGridColumn
                style={{ fontWeight: 600 }}
                width={getColumnWidth("status")}
              >
                Status
              </StyledGridColumn>

              {pinnedMetadataKeys.length !== 0 &&
                pinnedMetadataKeys.map((key) => (
                  <StyledGridColumn
                    style={{ fontWeight: 600 }}
                    width={getColumnWidth("pinned_metadata")}
                    key={key}
                  >
                    {capitalizeFirstLetter(key)}
                  </StyledGridColumn>
                ))}

              <StyledGridColumn
                style={{ fontWeight: 600 }}
                width={getColumnWidth("action_type")}
              >
                Action Type
              </StyledGridColumn>

              <StyledGridColumn
                style={{ fontWeight: 600 }}
                width={getColumnWidth("last_action")}
              >
                Last Action
              </StyledGridColumn>
            </Grid.Row>
          </Grid>
        </StyledHeaderContainer>

        <ThinDivider />

        {this.state.devices?.length !== 0 ? (
          <StyledBodyContainer fluid>
            <Accordion>
              {this.state.devices.map((device) => (
                <DeviceCard
                  key={device.id}
                  device={device}
                  dashboards={this.state.dashboards}
                  streamsList={this.state.streamsList}
                  allowedActions={allowedActions}
                  allSelected={allSelected}
                  selectedDevices={selectedDevices}
                  selectDevice={this.selectDevice}
                  clearDevice={this.clearDevice}
                  stateKeys={stateKeys}
                  metadataKeys={metadataKeys}
                  editableMetadataKeys={editableMetadataKeys}
                  downloadCertificates={this.downloadCertificates}
                  changeDeviceStatus={this.changeDeviceStatus}
                  setRemoteShellDeviceId={this.setRemoteShellDeviceId}
                  permissions={permissions}
                  activeIndex={this.state.activeIndex}
                  setActiveIndex={this.setActiveIndex}
                  tenant_settings={tenant_settings}
                  getColumnWidth={getColumnWidth}
                  setDevicePageToLoadingState={this.setDevicePageToLoadingState}
                />
              ))}
            </Accordion>
          </StyledBodyContainer>
        ) : (
          <div
            style={{
              height: "55vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ErrorMessage
              marginTop="30px"
              message={
                "No devices found! Please check your filters or add a new device."
              }
            />
          </div>
        )}
      </>
    );
  }

  filterSearchTimeout: any = null;

  render() {
    const permissions: Permission = this.props.user?.role?.permissions;
    const theme = this.props.user?.settings?.theme ?? "dark";
    const allowedActions = permissions.allowedActions || [];
    const totalDevices = this.state.pageCount * this.state.devicesPerPage;

    return (
      <Column>
        <BulkMetadataOperationsModal
          isOpen={this.state.bulkMetadataOperationsModalIsOpen}
          close={this.closeBulkMetadataOperationsModal}
          totalDevices={totalDevices}
          searchValue={this.state.searchQuery}
          searchKey={this.state.activeSearchTab}
          setLoading={() => this.setState({ loading: true })}
          theme={theme}
        />

        {/* ======================================== Action Modals ======================================== */}
        <UpdateFirmwareModal
          isOpen={this.state.updateFirmwareModalIsOpen}
          close={this.closeUpdateFirmwareModal}
          triggerAction={this.triggerAction}
          // Selected Devices Count is based on two state 1) allSelected 2) searchModeOn
          // TODO: devices count based searchModeOn is not implemented yet, so we are using allSelected and "All" is hardcoded without device count
          selectedDevicesCount={
            this.state.allSelected
              ? this.state.devicesCount
              : this.state.selectedDevices.size
          }
          allSelected={this.state.allSelected}
        />
        <UpdateConfigModal
          isOpen={this.state.updateConfigModalIsOpen}
          close={this.closeUpdateConfigModal}
          triggerAction={this.triggerAction}
          // Selected Devices Count is based on two state 1) allSelected 2) searchModeOn
          // TODO: devices count based searchModeOn is not implemented yet, so we are using allSelected and "All" is hardcoded without device count
          selectedDevicesCount={
            this.state.allSelected
              ? this.state.devicesCount
              : this.state.selectedDevices.size
          }
          allSelected={this.state.allSelected}
          action_type="update_config"
        />

        <UpdateConfigModal
          isOpen={this.state.updateGeofenceModalIsOpen}
          close={this.closeUpdateGeofenceModal}
          triggerAction={this.triggerAction}
          // Selected Devices Count is based on two state 1) allSelected 2) searchModeOn
          // TODO: devices count based searchModeOn is not implemented yet, so we are using allSelected and "All" is hardcoded without device count
          selectedDevicesCount={
            this.state.allSelected
              ? this.state.devicesCount
              : this.state.selectedDevices.size
          }
          allSelected={this.state.allSelected}
          action_type="update_geofence"
        />

        <SendFileModal
          isOpen={this.state.sendFileModalIsOpen}
          close={this.closeSendFileModal}
          triggerAction={this.triggerAction}
          // Selected Devices Count is based on two state 1) allSelected 2) searchModeOn
          // TODO: devices count based searchModeOn is not implemented yet, so we are using allSelected and "All" is hardcoded without device count
          selectedDevicesCount={
            this.state.allSelected
              ? this.state.devicesCount
              : this.state.selectedDevices.size
          }
          allSelected={this.state.allSelected}
        />

        <SendScriptModal
          isOpen={this.state.updateScriptModalIsOpen}
          close={this.closeUploadScriptModal}
          triggerAction={this.triggerAction}
          // Selected Devices Count is based on two state 1) allSelected 2) searchModeOn
          // TODO: devices count based searchModeOn is not implemented yet, so we are using allSelected and "All" is hardcoded without device count
          selectedDevicesCount={
            this.state.allSelected
              ? this.state.devicesCount
              : this.state.selectedDevices.size
          }
          allSelected={this.state.allSelected}
        />

        <ActionConfirmationModal
          actionType={this.state.eventType}
          isOpen={this.state.openConfirmationModal}
          close={this.closeConfirmationModal}
          triggerAction={this.triggerAction}
          // Selected Devices Count is based on two state 1) allSelected 2) searchModeOn
          // TODO: devices count based searchModeOn is not implemented yet, so we are using allSelected and "All" is hardcoded without device count
          selectedDevicesCount={
            this.state.allSelected
              ? this.state.devicesCount
              : this.state.selectedDevices.size
          }
          allSelected={this.state.allSelected}
          theme={theme}
        />

        <RemoteShellModal
          deviceId={this.state.remoteShellDeviceId}
          isOpen={this.state.remoteShellDeviceId > 0}
          close={() => this.setState({ remoteShellDeviceId: -1 })}
        />

        {/* ======================================== Action Modals Ends ======================================== */}

        <ActionBar>
          <ActionButtons
            allSelected={this.state.allSelected}
            selectedDevices={this.state.selectedDevices}
            openUploadScriptModal={this.openUploadScriptModal}
            openConfirmationModal={this.openConfirmationModal}
            openUpdateFirmwareModal={this.openUpdateFirmwareModal}
            openUpdateConfigModal={this.openUpdateConfigModal}
            openSendFileModal={this.openSendFileModal}
            openUpdateGeofenceModal={this.openUpdateGeofenceModal}
            triggerAction={this.triggerAction}
            allowedActions={allowedActions}
            actionButtonsDisabled={this.state.actionButtonsDisabled}
          />

          <ActionBarRight>
            <SearchInput
              action={
                <Dropdown
                  selection
                  style={{ minWidth: "124px" }}
                  options={this.state.options}
                  value={this.state.activeSearchTab}
                  onChange={this.onSelect}
                />
              }
              type={this.state.activeSearchTab === "id" ? "number" : "text"}
              actionPosition="left"
              icon="search"
              placeholder="Find Device by..."
              onChange={(evt) => {
                const searchKeyword = evt.target.value;
                let searchModeUpdate = {};

                if (searchKeyword.length > 0) {
                  searchModeUpdate = {
                    searchModeOn: true,
                    activePage: 1,
                  };
                } else {
                  searchModeUpdate = { searchModeOn: false };
                }
                this.setState({
                  selectedDeviceCount: 0,
                  selectedDevices: new Set(""),
                  allSelected: false,
                });

                // Clear the existing timeout
                clearTimeout(this.filterSearchTimeout);

                // Set a new timeout
                this.filterSearchTimeout = setTimeout(async () => {
                  this.setState({
                    ...searchModeUpdate,
                    searchQuery: searchKeyword,
                    loading: true,
                  });
                  await this.refreshDevices();
                }, 700);
              }}
            />

            <Popup
              content="Download/Update Metadata in bulk"
              position="top center"
              inverted
              trigger={
                <ActionButton
                  onClick={this.openBulkMetadataOperationsModal}
                  // Metadata button should be disabled when No devices are present
                  disabled={
                    this.state.loading || this.state.devices.length === 0
                  }
                  style={{
                    background: "transparent",
                    marginRight: "0px",
                    color: theme === "light" ? "#17191d" : "#C1C1C1",
                    border: `1px solid ${
                      theme === "light" ? "#17191d" : "#C1C1C1"
                    }`,
                    padding: "9px 16px",
                    borderRadius: "4px",
                  }}
                >
                  <StyledSVGIcon
                    height={"15px"}
                    svgContent={`${UpDownArrows}`}
                  />
                  <span>Metadata</span>
                </ActionButton>
              }
            />

            <DisplayIf cond={permissions.allowCreatingDevices}>
              <DeviceProvisionModal
                onConfirm={async () => {
                  this.setState({ loading: true });
                  await this.refreshDevices();
                }}
                keys={this.state.metadataKeys}
              />
            </DisplayIf>
          </ActionBarRight>
        </ActionBar>

        {this.state.loading ? (
          <LoadingAnimation
            loaderContainerHeight="65vh"
            fontSize="1.5rem"
            loadingText="Loading devices"
          />
        ) : (
          this.renderDeviceTable(theme)
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {this.state.pageCount > 0 && !this.state.loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "nowrap",
                gap: "16px",
              }}
            >
              <Pagination
                boundaryRange={0}
                defaultActivePage={this.state.activePage}
                ellipsisItem={null}
                siblingRange={2}
                totalPages={this.state.pageCount}
                onPageChange={this.handlePaginationChange}
              />

              <SearchPageInput
                icon="search"
                placeholder="Jump to page..."
                name="activePage"
                min={1}
                onChange={this.handlePaginationInputChange}
                onKeyDown={this.handlePaginationInputKeyDown}
                type="number"
                value={
                  this.state.inputPageNumber ? this.state.inputPageNumber : ""
                }
              />

              <StyledDevicePerPageWidget>
                <MenuItem>Devices per page</MenuItem>
                <MenuItem style={{ padding: "0px" }}>
                  <SelectDevicesPerPage
                    compact
                    selection
                    options={devicesPerPageOptions}
                    value={this.state.devicesPerPage}
                    onChange={this.changeDevicesPerPage}
                  />
                </MenuItem>
              </StyledDevicePerPageWidget>
            </div>
          ) : (
            <div></div>
          )}
          {this.state.showInactiveDeviceToggle && !this.state.loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                flexWrap: "nowrap",
                gap: "16px",
              }}
            >
              <div style={{ marginLeft: "auto" }}>Show Inactive Devices</div>
              <Toggle
                id="showInactiveDeviceToggle"
                checked={this.state.showInactiveDevice}
                onClick={() => {
                  this.handleToggleClick();
                }}
              />
            </div>
          ) : (
            <div></div>
          )}
        </div>

        <div>
          {/* Show only when, once it has refreshed */}
          {this.state.lastRefreshTime ? (
            <div className="last-refresh-time">
              <Icon
                name="info"
                style={{
                  marginRight: "7px",
                }}
              />
              Last refreshed:{" "}
              {moment(this.state.lastRefreshTime).format("HH:mm:ss")}
            </div>
          ) : (
            <></>
          )}
        </div>
      </Column>
    );
  }
}
