import React, { useEffect, useState, useRef } from "react";
import {
  NotificationRule,
  WebhookNotificationChannel,
} from "../CreateNotificationRule";
import { StyledInput, StyledLabel } from "../CreateAlertRuleModal";
import { Header, HttpHeaders } from "../HttpHeaders";
import { ErrorMessage } from "../../roles/CreateOrEditRoleModal";

type CreateWebhookNotificationChannelProps = {
  readonly channel: WebhookNotificationChannel;
  readonly setChannel: (channel: WebhookNotificationChannel) => void;
  readonly setIsValid: (isValid: boolean) => void;
  readonly notificationRule: NotificationRule;
};

export default function CreateWebhookNotificationChannel({
  setIsValid,
  channel,
  setChannel,
  notificationRule,
}: CreateWebhookNotificationChannelProps) {
  const [validationError, setValidationError] = useState<string | null>(null);
  const [blurred, setBlurred] = useState(false);

  const urlInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const areHeadersValid = (headers: { [key: string]: string }) => {
      if (Object.keys(headers).length === 0) {
        return true;
      }

      // Filter for invalid headers. There should be none
      return (
        Object.keys(headers).filter((headerKey) => {
          return headerKey.length === 0 || headers[headerKey].length === 0;
        }).length === 0
      );
    };

    const isValid =
      channel.url.length > 0 &&
      areHeadersValid(channel.headers) &&
      notificationRule.alertRuleId !== "" &&
      !validationError;

    setIsValid(isValid);
  }, [channel, setIsValid, notificationRule.alertRuleId, validationError]);

  const setUrl = (url: string) => {
    if (
      url.length > 0 &&
      urlInputRef.current &&
      !urlInputRef.current.checkValidity()
    ) {
      setValidationError("Invalid URL format");
    } else {
      setBlurred(false);
      setValidationError(null);
    }

    setChannel({
      ...channel,
      url,
    });
  };

  const handleBlur = () => {
    setBlurred(true);
  };

  const handleFocus = () => {
    if (!validationError) {
      setBlurred(false);
    }
  };

  const setHeaders = (headersArray: Header[]) => {
    const headersMap: { [key: string]: string } = {};
    headersArray.forEach((header) => {
      headersMap[header.key] = header.value;
    });

    setChannel({
      ...channel,
      headers: headersMap,
    });
  };

  return (
    <>
      <div style={{ marginBottom: "14px" }}>
        <StyledInput style={{ marginBottom: "0px" }} labelPosition="left">
          <StyledLabel>Url</StyledLabel>
          <input
            ref={urlInputRef}
            value={channel.url}
            type="url"
            placeholder="URL"
            onChange={(e) => setUrl(e.target.value)}
            onBlur={handleBlur}
            onFocus={handleFocus}
          />
        </StyledInput>
        {blurred && validationError && (
          <ErrorMessage style={{ fontWeight: 700, top: "0px" }}>
            {validationError}
          </ErrorMessage>
        )}
      </div>

      <div style={{ marginBottom: "24px" }}>
        <HttpHeaders
          headers={Object.entries(channel.headers).map(([key, value]) => ({
            key,
            value,
          }))}
          onChange={setHeaders}
        />
      </div>
    </>
  );
}
