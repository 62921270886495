import React from "react";
import { GaugeChartColumn, GaugeChartMetaData } from "./PanelDef";
import { Input, Label, Tab } from "semantic-ui-react";
import RelativeTimePicker from "../../Datetime/RelativeTimePicker";
import { PanelEditComponent, PartialMetaData } from "../PanelDef";
import {
  EditMetaRoot,
  EditMetaRow,
  ThinDivider,
  LabelHeading,
  ToggleLabel,
  DisplayValueDiv,
  EditAnimatedMetaInput,
  EditAnimatedMetaDropdown,
  EditPanelFormContainer,
  StyledSketchPickerTrigger,
  StyledInputDiv,
} from "../util";
import { SketchPicker } from "react-color";
import ToggleSwitch from "../../../common/ToggleSwitch";
import { TableInfo } from "../../../../../BytebeamClient";

export type EditGaugeChartMetaProps = {
  panelMeta: GaugeChartMetaData;
  tables: TableInfo;
};

export type EditGaugeChartMetaState = {
  table: string;
  columns: Array<GaugeChartColumn>;
  showPercentage: boolean;
  divisionCount: number;
  valUnit: string;
  maxThreshold: number;
  minThreshold: number | undefined;
  displayStartPicker: boolean;
  displayEndPicker: boolean;
  startColor: string;
  endColor: string;
  error: boolean;
};

export class EditGaugeChartMeta extends PanelEditComponent<
  GaugeChartMetaData,
  EditGaugeChartMetaState
> {
  titleRef = React.createRef<HTMLInputElement>();
  descriptionRef = React.createRef<HTMLInputElement>();
  durationRef = React.createRef<RelativeTimePicker>();
  divisionCountRef = React.createRef<HTMLInputElement>();
  valUnitRef = React.createRef<HTMLInputElement>();
  maxThresholdRef = React.createRef<HTMLInputElement>();
  // minThresholdRef = React.createRef<HTMLInputElement>();

  constructor(props: EditGaugeChartMetaProps) {
    super(props);

    if (props.panelMeta) {
      // fetch values from panelMeta for Edit
      this.state = {
        table: props.panelMeta?.table,
        columns: props.panelMeta?.columns,
        showPercentage: props.panelMeta.showPercentage
          ? props.panelMeta.showPercentage
          : false,
        divisionCount: props.panelMeta.divisionCount
          ? props.panelMeta.divisionCount
          : 5,
        valUnit: props.panelMeta.valUnit ? props.panelMeta.valUnit : "",
        maxThreshold: props.panelMeta.maxThreshold
          ? props.panelMeta.maxThreshold
          : 0,
        minThreshold: props.panelMeta.minThreshold
          ? props.panelMeta.minThreshold
          : undefined,
        displayStartPicker: false,
        displayEndPicker: false,
        startColor: this.props.panelMeta.startColor
          ? this.props.panelMeta.startColor
          : "#39fa39",
        endColor: this.props.panelMeta.endColor
          ? this.props.panelMeta.endColor
          : "#fa1c1c",
        error: false,
      };
    } else {
      // Put default values
      this.state = {
        table: "",
        columns: [],
        showPercentage: false,
        divisionCount: 5,
        valUnit: "",
        maxThreshold: 0,
        minThreshold: undefined,
        displayStartPicker: false,
        displayEndPicker: false,
        startColor: "#39fa39",
        endColor: "#fa1c1c",
        error: false,
      };
    }
  }

  getPanelMeta(type): PartialMetaData<GaugeChartMetaData> {
    const meta: GaugeChartMetaData = {
      type: "gauge_chart", // Have to change before pushing
      id: this.props.panelMeta.id,
      title: this.titleRef.current?.value || "",
      description: this.descriptionRef.current?.value || "",
      table: this.state.table || "",
      columns: this.state.columns,
      showPercentage: this.state.showPercentage,
      divisionCount: this.state.divisionCount ? this.state.divisionCount : 5,
      valUnit: this.valUnitRef.current?.value
        ? this.valUnitRef.current.value
        : "",
      maxThreshold: this.state.maxThreshold
        ? this.state.maxThreshold
        : undefined,
      minThreshold: this.state.minThreshold
        ? this.state.minThreshold
        : undefined,
      startColor: this.state.startColor ? this.state.startColor : "#39fa39",
      endColor: this.state.endColor ? this.state.endColor : "#fa1c1c",
    };

    return {
      meta: meta,
      complete: this.isValidPanelMeta(meta, type),
    };
  }

  isValidPanelMeta(meta: GaugeChartMetaData, type?: string) {
    // type is used here to differentiate between submit and refresh in edit mode
    if (
      !(
        !!meta.table &&
        meta.columns.length > 0 &&
        meta.maxThreshold !== undefined
      ) &&
      type === "submit"
    ) {
      this.setState({ error: true });
    } else if (type === "submit") {
      this.setState({ error: false });
    }
    return (
      !!meta.table && meta.columns.length > 0 && meta.maxThreshold !== undefined
    );
  }

  setTable(_event, data) {
    this.setState({
      table: data.value,
      columns: [],
    });
  }

  setColumn(index: number, key: string, value?: any) {
    const v = { ...this.state.columns[index] };

    v[key] = value;

    this.setState({
      columns: [
        ...this.state.columns.slice(0, index),
        v,
        ...this.state.columns.slice(index + 1),
      ],
    });
  }

  addColumn(value?: any) {
    if (value) {
      this.setState({
        columns: [{ name: value }],
      });
    }
  }

  handlePickerClick(e, pickerVal) {
    e.stopPropagation();
    pickerVal === "start"
      ? this.setState({ displayStartPicker: !this.state.displayStartPicker })
      : this.setState({ displayEndPicker: !this.state.displayEndPicker });
  }

  handleSliderChange = (event) => {
    this.setState({ divisionCount: event.target.value });
  };

  onTogglePercentage = (checked) => {
    this.setState({ showPercentage: checked });
  };

  render() {
    const title = this.props.panelMeta.title;
    const description = this.props.panelMeta.description;
    const currentUnit = this.props.panelMeta
      ? this.props.panelMeta.valUnit
      : "";
    const currentMaximum = this.props.panelMeta
      ? this.props.panelMeta.maxThreshold
      : undefined;
    const currentMinimum = this.props.panelMeta
      ? this.props.panelMeta.minThreshold
      : undefined;

    const tableOptions = Object.keys(this.props.tables).map((t) => {
      return {
        key: t,
        text: t,
        value: t,
      };
    });

    let columnOptions: Array<{ key: string; value: string; text: string }> = [];

    if (!!this.state.table) {
      const columns = this.props.tables[this.state.table].filter(
        (column: { name: string; type: string }) =>
          this.state.columns.filter((c) => c.name === column.name).length === 0
      );

      columnOptions = columns.map((f: { name: string; type: string }) => {
        return {
          key: f.name,
          text: f.name,
          value: f.name,
        };
      });
    }

    const panes = [
      {
        menuItem: "General",
        pane: (
          <Tab.Pane key="general">
            <EditPanelFormContainer>
              <div style={{ width: "100%", marginTop: "16px" }} />
              <EditMetaRow>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    autoFocus={true}
                    defaultRef={this.titleRef}
                    defaultValue={title}
                    label="Title"
                  />
                </StyledInputDiv>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    defaultRef={this.descriptionRef}
                    defaultValue={description}
                    label="Description"
                  />
                </StyledInputDiv>
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    defaultValue={currentMinimum}
                    label="Min Threshold"
                    type="number"
                    onChangeEvent={(e) =>
                      this.setState({
                        minThreshold: parseFloat(e.target.value),
                      })
                    }
                  />
                </StyledInputDiv>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    defaultValue={currentMaximum}
                    label="Max Threshold"
                    type="number"
                    error={
                      this.state.error &&
                      (this.state.maxThreshold === undefined ||
                        this.state.maxThreshold === 0)
                    }
                    onChangeEvent={(e) =>
                      this.setState({
                        maxThreshold: parseFloat(e.target.value),
                      })
                    }
                  />
                </StyledInputDiv>
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>
                <StyledInputDiv width="50%" marginTop="10px">
                  <EditAnimatedMetaDropdown
                    placeholder="Select Stream"
                    text={this.state.table}
                    search
                    selection
                    options={tableOptions}
                    onChange={this.setTable.bind(this)}
                    defaultValue={this.props.panelMeta.table}
                    elementid={"tableGuageChart"}
                    error={this.state.error && !this.state.table}
                  />
                </StyledInputDiv>
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>
                <StyledInputDiv width="50%" marginTop="10px">
                  <EditAnimatedMetaDropdown
                    placeholder="Select Field"
                    text={
                      this.state.columns.length
                        ? this.state.columns[0]["name"]
                        : "Select Field"
                    }
                    search
                    selection
                    options={columnOptions}
                    defaultValue={
                      this.state.columns.length
                        ? this.state.columns[0]["name"]
                        : ""
                    }
                    value={
                      this.state.columns.length
                        ? this.state.columns[0]["name"]
                        : ""
                    }
                    onChange={(_, data) => this.addColumn(data.value)}
                    disabled={columnOptions.length === 0}
                    error={this.state.error && this.state.columns.length === 0}
                    elementid={"tableGuageChartColumn"}
                  />
                </StyledInputDiv>
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "View",
        pane: (
          <Tab.Pane key="view">
            <EditPanelFormContainer>
              <EditMetaRow>
                <ToggleLabel>Percentage Value</ToggleLabel>
                <ToggleSwitch
                  id="showAreaChartToggle"
                  defaultChecked={this.state.showPercentage}
                  disabled={false}
                  Text={["Yes", "No"]}
                  onToggleChange={this.onTogglePercentage}
                />
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>
                <LabelHeading>Choose Start Color</LabelHeading>
                <StyledSketchPickerTrigger
                  id="default_color"
                  style={{
                    backgroundColor: `${this.state.startColor}`,
                  }}
                  onClick={(e) => this.handlePickerClick(e, "start")}
                />
                <div
                  style={{
                    display: this.state.displayStartPicker ? "block" : "none",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <SketchPicker
                    color={this.state.startColor}
                    onChange={(color) => {
                      this.setState({ startColor: color.hex });
                    }}
                  />
                </div>
              </EditMetaRow>
              <EditMetaRow>
                <LabelHeading>Choose End Color</LabelHeading>
                <StyledSketchPickerTrigger
                  id="default_color"
                  style={{
                    backgroundColor: `${this.state.endColor}`,
                  }}
                  onClick={(e) => this.handlePickerClick(e, "end")}
                />
                <div
                  style={{
                    display: this.state.displayEndPicker ? "block" : "none",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <SketchPicker
                    color={this.state.endColor}
                    onChange={(color) => {
                      this.setState({ endColor: color.hex });
                    }}
                  />
                </div>
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>
                <LabelHeading>Choose Divisions</LabelHeading>
                <DisplayValueDiv>{this.state.divisionCount}</DisplayValueDiv>
                <div style={{ width: "100%", padding: "15px 35px 0px 5px" }}>
                  <input
                    style={{ width: "100%", height: "5%" }}
                    id="typeinp"
                    type="range"
                    min="3"
                    max="30"
                    value={this.state.divisionCount}
                    onChange={this.handleSliderChange}
                    step="1"
                  />
                </div>
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>
                <Input className="add-panel-title-input" labelPosition="left">
                  <Label>Show Unit</Label>
                  <input
                    type="text"
                    defaultValue={currentUnit}
                    ref={this.valUnitRef}
                    placeholder={""}
                  />
                </Input>
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <EditMetaRoot>
        <div
          onClick={() => {
            this.setState({
              displayEndPicker: false,
              displayStartPicker: false,
            });
          }}
        >
          <Tab menu={{}} panes={panes} renderActiveOnly={false} />
        </div>
      </EditMetaRoot>
    );
  }
}
