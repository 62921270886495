import React, { useState } from "react";
import { Table, Input, Icon } from "semantic-ui-react";
import { hasSpecialCharacters } from "../../util";
import TypeSelection from "./TypeSelection";

const AddColButton = ({ onClick, disabled }) => (
  <Icon
    style={{
      cursor: disabled === true ? "not-allowed" : "pointer",
    }}
    link={!disabled}
    name="add"
    onClick={onClick}
    disabled={disabled}
  />
);

export default function AddNewColumn({ tableName, addNewCol, columnNameSet }) {
  const [columnName, setColumnName] = useState("");
  const [columnType, setColumnType] = useState("");

  const isValid = columnType !== "" && columnName !== "";

  return (
    <Table.Row key={tableName + ":add-col"}>
      <Table.Cell>
        <Input
          placeholder="Field Name"
          value={columnName}
          onChange={(_e, d) =>
            setColumnName(d.value?.replace(" ", "_")?.trim())
          }
        />
      </Table.Cell>

      <Table.Cell>
        <TypeSelection
          value={columnType}
          onChange={(_e, d) => setColumnType(d.value)}
        />
      </Table.Cell>

      <Table.Cell>
        <AddColButton
          onClick={() => {
            if (columnNameSet?.has(columnName)) {
              window.toastr.error(`${columnName} is already present`);
            } else if (hasSpecialCharacters(columnName)) {
              window.toastr.error(
                "Field Name cannot contain special characters except underscore"
              );
            } else addNewCol(tableName, columnName, columnType);
          }}
          disabled={!isValid}
        />
      </Table.Cell>
    </Table.Row>
  );
}
