import { GridLayout } from "./components/Screens/Dashboards/DashboardBody";
import {
  SerializedTimeRange,
  TimeRange,
} from "./components/Screens/Dashboards/Datetime/TimeRange";
import {
  DashboardMeta,
  DashboardType,
} from "./components/Screens/Dashboards/EditDashboardModal";
import {
  PanelMetaData,
  PanelDef,
} from "./components/Screens/Dashboards/Panel/PanelDef";
import { getPanelDefForPanelType } from "./components/Screens/Dashboards/Panel/util";
import {
  Role,
  User,
  Settings,
  IUserResult,
  Permission,
  IUser,
  ActionType,
  Auth,
  timeoutDelay,
  ApiKey,
  TenantSettings,
  SessionType,
  AlertRule,
  UserSettings,
  urlToDocId,
  AlertNotificationRule,
} from "./util";
import axios from "axios";
import { objectToQuery } from "./components/Screens/common/QuerySelector";
import { Mixpanel } from "./components/Screens/common/MixPanel";
import { SemanticICONS } from "semantic-ui-react";

export function getTenantFromURL() {
  try {
    const currentURL = new URL(window.location.href);
    const path = currentURL.href.replace(currentURL.origin, "");
    let tenant = path.split("/");
    if (tenant.length > 2 && tenant[2] && tenant[1] === "projects") {
      return tenant[2];
    } else {
      return "";
    }
  } catch (e) {
    console.error(`Error in URL parsing for tenant: ${e}`);
    return "";
  }
}

export function getKeyFromPath(path) {
  // Extracting the main part of the path not params or hash
  const mainPath = path.split("?")[0].split("#")[0];

  // Finding the right key in urlToDocId
  for (const key in urlToDocId) {
    if (mainPath.includes(`/${key}`)) {
      return key;
    }
  }

  // If no key is found, it's not available
  return "na";
}

export function getPathFromURL() {
  try {
    const currentURL = new URL(window.location.href);
    const path = currentURL.pathname; // This gets you just the path portion of the URL

    //we'll extract the right key from this path
    const key = getKeyFromPath(path);
    return key;
  } catch (e) {
    console.error(`Error in URL parsing for path: ${e}`);
    return "";
  }
}

function shouldSkipAccessDeniedRouting(url: string, method?: string): boolean {
  // Default to "get" if method is undefined
  const effectiveMethod = method ? method.toLowerCase() : "get";

  const excludedUrls = [
    { path: "action-types", method: "get" },
    // Add more URLs and their respective methods as needed
    // { path: "another-url", method: "post" },
  ];

  return excludedUrls.some(
    (excluded) =>
      url.includes(excluded.path) && effectiveMethod === excluded.method,
  );
}

export async function apiCall(
  url: string,
  withTenantId: boolean,
  params: any = {},
): Promise<any> {
  const tenant = getTenantFromURL();
  let headers = params?.headers ?? {};
  let h = headers;

  if (withTenantId && tenant) {
    h = Object.assign({}, headers, { "X-Bytebeam-Tenant": tenant });
  }

  let p = params ? Object.assign({}, params, { headers: h }) : { headers: h };

  try {
    const res = await fetch(url, p);

    if (!res.status.toString().startsWith("2")) {
      if (res.status === 401 || res.status === 403) {
        // Check if we should skip error handling for this URL and method
        if (shouldSkipAccessDeniedRouting(url, p.method)) {
          // Do nothing
          // window.toastr.error("Access to Actions not granted!");
          // Its to handle if there are multiple RBAC controlled API calls and even if one fails, we don't want to show access denied
          // Eg. Action types API call is used in device mgmt and if it fails, we don't want to show access denied to whole page
        } else {
          window.toastr.error("Access Denied!");
          Mixpanel.track("Session Timeout");
          await timeoutDelay(200);
          window.location.assign(`/projects/${tenant}/accessDenied`);
        }
      } else {
        // removing toast messages here, to ignore unnecessary error display.
        console.error(
          "Oops, something went wrong! Contact support@bytebeam.io",
        );
      }
      throw new Error(`Fetch failed with status ${res.status}`);
    } else {
      if (res.status !== 204) {
        const contentType = res.headers.get("content-type");
        if (!contentType) {
          return res;
        }
        if (contentType.indexOf("application/json") !== -1) {
          return res.json();
        }
        if (contentType.indexOf("text/plain") !== -1) {
          return res.text();
        } else {
          console.warn("Unknown content type in apiCall(): ", contentType);
          return res;
        }
      } else {
        return res;
      }
    }
  } catch (error) {
    console.error("Error occurred in apiCall.");
    console.error(error);
    throw error;
  }
}

export type Key = {
  key: string;
};

export type ActionDropdownType = {
  text: string;
  value: string;
  icon: SemanticICONS;
  payload_type: string;
  json_schema?: string;
  json_ui_schema?: string;
};

export type Stream = {
  fields: string[];
  computedFrom?: {
    stream: string;
    lang: string;
    code: string;
    source: string;
  };
};

export type DeviceFilterOption = {
  filterName: string;
  filterValues: string[];
};

export async function fetchAllRoles(): Promise<Role[]> {
  const res = await apiCall("/api/v1/roles", true);
  return res.result;
}

export async function fetchAllLogTags(table?: string): Promise<string[]> {
  const queryParameters = table ? `?table=${encodeURIComponent(table)}` : "";
  const url = `/api/v1/log-tags${queryParameters}`;
  const res = await apiCall(url, true);
  return res.tags;
}

export async function fetchAllActionTypes(): Promise<ActionType[]> {
  const res = await apiCall("/api/v1/action-types", true);
  return res;
}

export async function fetchAllStreams(): Promise<{ result: string[] }> {
  const res = await apiCall("/api/v1/streams", true);
  return res;
}

export type StreamFieldDetails = {
  type: string;
  required: boolean;
  seq_id: number;
};

export type StreamDetails = {
  fields: { [key: string]: StreamFieldDetails };
};

export type FetchStreamsAPIResponse = { [key: string]: StreamDetails };

export async function fetchAllStreamsWithDetails(): Promise<FetchStreamsAPIResponse> {
  const res = await apiCall("/api/v1/streams/detailed", true);
  return res.streams;
}

export async function fetchDeviceFilterOptions(
  filters: DeviceFilters,
): Promise<DeviceFilterOption[]> {
  if (filters.state) {
    delete filters.state;
  }

  const e = Object.entries(filters).map(([key, values]) => [
    key,
    values.join(","),
  ]);
  const query = new URLSearchParams(Object.fromEntries(e));

  const res = await apiCall(
    `/api/v1/devices/filter-options?status=active&${query}`,
    true,
  );

  return res;
}

export type MetadataType = {
  id: any;
  key: string;
  value: string;
};

export async function fetchAllMetadataKeys(): Promise<{ key: string }[]> {
  const res = await apiCall(`/api/v1/devices/metadata-keys`, true);
  return res;
}

export async function fetchMicelioAlertsPanelDetails(pageNumber: number, rowsPerPage: number, alertType: string) {
  const response = await fetch(`/api/v1/dashboards/panels/micelio-alerts-panel?page=${pageNumber - 1}&limit=${rowsPerPage}&alertType=${alertType}`)
  const details = await response.json()

  return details;
}

export type DashboardAPIResponse = {
  timeRange: TimeRange;
  id: number;
  config: {
    timeRange: SerializedTimeRange;
    panels: PanelMetaData[];
    dashboardMeta: DashboardMeta;
    gridLayouts: GridLayout;
  };
};

export type PanelDataMap = { [key: string]: PanelData<PanelMetaData, {}> };

export async function fetchDashboard(id: number): Promise<Dashboard> {
  const url = `/api/v1/dashboards/${id}`;
  const dashboard = await apiCall(url, true);

  const panels: PanelDataMap = {};

  (dashboard.config.panels || []).forEach((panel: PanelMetaData) => {
    panels[panel.id] = {
      meta: panel,
      panelDef: getPanelDefForPanelType(panel.type),
      isDirty: false,
      loading: false,
      error: false,
    };
  });

  const gridLayouts = dashboard.config.gridLayouts || {
    lg: [],
    xs: [],
  };

  return {
    id: dashboard.id,
    dashboardMeta: dashboard.config.dashboardMeta,
    gridLayouts: gridLayouts,
    panels: panels,
    isDirty: false,
  };
}

export type TableInfo = {
  [key: string]: Array<{
    name: string;
    type: string;
    seq_id: number;
    required: boolean;
  }>;
};

export async function fetchTableInfo(): Promise<TableInfo> {
  const res = await apiCall("/api/v1/stream_info", true);
  return res;
}

export type DeviceFilters = {
  [key: string]: string[];
};

export type Dashboard = {
  id: number;
  isDirty: boolean;
  panels: PanelDataMap;
  dashboardMeta: DashboardMeta;
  gridLayouts: GridLayout;
};

export type DeviceMetadata = Object;

export type Device = {
  id: number;
  status: string;
  metadata: DeviceMetadata;
  state: {
    timestamp: string;
    sequence: number;
    Status: string;
  };
};

export type SearchDeviceResponse = {
  devices: Device[];
  count: number;
};

export const devicesPerPageOptions = [
  {
    key: 5,
    text: 5,
    value: 5,
  },
  {
    key: 10,
    text: 10,
    value: 10,
  },
  {
    key: 25,
    text: 25,
    value: 25,
  },
  {
    key: 50,
    text: 50,
    value: 50,
  },
  {
    key: 100,
    text: 100,
    value: 100,
  },
];

export const rowsPerPageOptions = [
  {
    key: 5,
    text: 5,
    value: 5,
  },
  {
    key: 10,
    text: 10,
    value: 10,
  },
  {
    key: 20,
    text: 20,
    value: 20,
  },
  {
    key: 50,
    text: 50,
    value: 50,
  },
  {
    key: 100,
    text: 100,
    value: 100,
  },
];

export async function searchDevices(
  filters: DeviceFilters,
  page: number,
  limit: number,
  status: string = "active",
  abortSignal: AbortSignal | null = null,
): Promise<SearchDeviceResponse> {
  try {
    const requestBody = {
      metadata: filters,
      page: page,
      limit: limit,
      status: status,
    };

    const res = await apiCall(`/api/v1/devices/search`, true, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
      },
      signal: abortSignal,
    });

    return res;
  } catch (err) {
    throw err;
  }
}

export async function searchDevicesWithSpecificAction(
  filters: DeviceFilters,
  page: number,
  limit: number,
  actionID: number,
  status: string = "active",
  abortSignal: AbortSignal | null = null,
): Promise<SearchDeviceResponse> {
  try {
    const requestBody = {
      metadata: filters,
      page: page,
      limit: limit,
      status: status,
      "action-id": actionID,
    };

    const res = await apiCall(`/api/v1/devices/search`, true, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
      },
      signal: abortSignal,
    });

    return res;
  } catch (err) {
    console.error("Error occurred in searchDevicesWithSpecificAction.");
    throw err;
  }
}

export async function fetchDevice(
  id: number,
  abortSignal: AbortSignal | null = null,
): Promise<Device> {
  try {
    const devices = await searchDevices(
      { id: [id.toString()] },
      1,
      1,
      "all",
      abortSignal,
    );
    return devices.devices[0];
  } catch (err) {
    console.error("Error occurred in fetchDevice.");
    throw err;
  }
}

export async function fetchDeviceWithSpecificAction(
  id: number,
  actionID: number,
  abortSignal: AbortSignal | null = null,
): Promise<Device> {
  try {
    const devices = await searchDevicesWithSpecificAction(
      { id: [id.toString()] },
      1,
      1,
      actionID,
      "all",
      abortSignal,
    );
    return devices.devices[0];
  } catch (err) {
    console.error("Error occurred in fetchDeviceWithSpecificAction.");
    throw err;
  }
}

export async function createTenant(tenantId: string): Promise<any> {
  const res = await apiCall(encodeURI(`/api/v1/tenants/${tenantId}`), false, {
    method: "POST",
  });
  return res;
}

export async function fetchCurrentUser(): Promise<User> {
  const tenant = getTenantFromURL();
  let h = { "X-Bytebeam-Tenant": tenant };

  let p = { headers: h };
  const res = await fetch("/api/v1/me", p);

  if (!res.status.toString().startsWith("2")) {
    throw new Error(`Fetch failed with status ${res.status}`);
  } else {
    if (res.status === 200) {
      const jsonRes = await res.json();
      return jsonRes;
    } else {
      throw new Error("Received non 200 response");
    }
  }
}

export async function fetchSettings(): Promise<Settings> {
  const tenant = getTenantFromURL();
  let h = { "X-Bytebeam-Tenant": tenant };

  let p = { headers: h };
  const res = await fetch("/api/v1/server/settings", p);

  if (!res.status.toString().startsWith("2")) {
    throw new Error(`Fetch failed with status ${res.status}`);
  } else {
    if (res.status === 200) {
      const jsonRes = await res.json();
      return jsonRes;
    } else {
      throw new Error("Received non 200 response");
    }
  }
}

export async function deleteActionType(action: string): Promise<any> {
  const res = await apiCall(
    `/api/v1/action-types/${encodeURIComponent(action)}`,
    true,
    {
      method: "DELETE",
    },
  );
  return res;
}

export async function deleteMetadataKey(key: string): Promise<any> {
  const res = await apiCall(
    `/api/v1/devices/metadata-keys/${encodeURIComponent(key)}`,
    true,
    {
      method: "DELETE",
    },
  );
  return res;
}

type TableFieldType = {
  seq_id: number;
  type: string;
  required: string;
};

export type StreamTableFieldsType = {
  [key: string]: TableFieldType;
};

export async function fetchStreamTableFields(
  tableName: string,
): Promise<{ result: StreamTableFieldsType }> {
  const res = await apiCall(
    `/api/v1/streams/${encodeURIComponent(tableName)}/fields`,
    true,
  );
  return res;
}

export async function deleteStreamTableField(
  tableName: string,
  fieldName: string,
): Promise<any> {
  const res = await apiCall(
    `/api/v1/streams/${encodeURIComponent(
      tableName,
    )}/fields/${encodeURIComponent(fieldName)}`,
    true,
    {
      method: "DELETE",
    },
  );
  return res;
}

export async function fetchAllUsers(): Promise<IUserResult> {
  const res = await apiCall("/api/v1/users", true);
  return res;
}

export async function deleteUser(userId: string): Promise<any> {
  const res = await apiCall(
    `/api/v1/users/${encodeURIComponent(userId)}`,
    true,
    {
      method: "DELETE",
    },
  );
  return res;
}

export async function deleteRole(roleId: number): Promise<any> {
  const res = await apiCall(
    `/api/v1/roles/${encodeURIComponent(roleId)}`,
    true,
    {
      method: "DELETE",
    },
  );
  return res;
}

export async function fetchAllApiKeys(): Promise<ApiKey[]> {
  try {
    const res = await apiCall("/api/v1/apikeys", true);
    return res;
  } catch (err) {
    throw err;
  }
}

export async function deleteApiKey(key: string): Promise<any> {
  const res = await apiCall(
    `/api/v1/apikeys/${encodeURIComponent(key)}`,
    true,
    {
      method: "DELETE",
    },
  );
  return res;
}

export type FirmwareType = {
  content_length: number;
  created_at: Date;
  tenant_id: string;
  download_url: string;
  download_url_expires_at: Date;
  file_name: string;
  is_deactivated: boolean;
  version_number: string;
};

export async function fetchAllFirmwares(): Promise<FirmwareType[]> {
  const res = await apiCall("/api/v1/firmwares", true);
  return res;
}

export type DeviceConfigurationType = {
  action_type: string; // action_type: update_geofence / update_config
  tenant_id: string;
  is_deactivated: boolean;
  version_name: string;
  config_json: any;
};

export async function fetchAllDeviceConfiguration(): Promise<
  DeviceConfigurationType[]
> {
  const res = await apiCall("/api/v1/device-configs", true);
  return res;
}

export async function updateDeviceMetadata(
  metadataBody: MetadataType,
): Promise<void> {
  await apiCall(`/api/v1/devices/metadata`, true, {
    method: "POST",
    body: JSON.stringify(metadataBody),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function filterDeviceSearch(
  // Handle showing active and inactive device here
  query: string,
  key: string,
  page: number | string,
  limit: number | string,
  status: string = "active",
  abortSignal: AbortSignal | null = null,
): Promise<Device[]> {
  const res = await apiCall(
    encodeURI(
      `/api/v1/devices/search?query=${query}&key=${key}&page=${page}&limit=${limit}&status=${status}`,
    ),
    true,
    { signal: abortSignal },
  );
  return res;
}

export type StatusTypeStruct = {
  Queued?: number;
  Initiated?: number;
  Failed?: number;
  Completed?: number;
  ShellSpawned?: number;
  in_progress?: number;
  Progress?: number;
  "Waiting for user"?: number;
  "Waiting for Bluetooth"?: number;
  Downloaded?: number;
  Received?: number;
  "Extracting payload"?: number;
  "Finalizing update"?: number;
  Installing?: number;
  Installed?: number;
  Downloading?: number;
  Scheduled?: number; // Pending approval
};

export type ActionStatusResponse = {
  actions: ActionStatusType;
  count: number;
};

export type ActionStatusType = {
  action_id: number;
  user_name: string;
  type: string;
  created_at: Date;
  statuses: StatusTypeStruct;
  statuses_phased?: StatusTypeStruct;
  params: string;
  payload_type: string;
  schedule?: any;
};

export type ActionStatusDetail = {
  device_id: number;
  status: string;
  progress: number;
  errors: string[];
  updated_at: Date;
};

// --------------------------------------------------------- Actions APIs ---------------------------------------------------------
export async function fetchActionStatusDetails(
  actionId: number,
  status: any,
  pageNumber: number,
  limit: number,
): Promise<{ device_actions: ActionStatusDetail[] }> {
  let url = "";
  let partialURL = "";
  if (Array.isArray(status)) {
    status.forEach((status) => {
      partialURL += `status=${status}&`;
    });
    url = `/api/v1/actions/${actionId}/device_actions?${partialURL}page=${
      pageNumber - 1
    }&limit=${limit}`;
  } else
    url = `/api/v1/actions/${actionId}/device_actions?status=${status}&page=${
      pageNumber - 1
    }&limit=${limit}`;
  const res = await apiCall(encodeURI(url), true);
  return res;
}

export async function fetchDeviceActionStatusDetailsWithPhase(
  actionId: number,
  status: any,
  pageNumber: number,
  limit: number,
  phaseIndex: number,
): Promise<{ device_actions: ActionStatusDetail[] }> {
  let url = "";
  let partialURL = "";
  if (Array.isArray(status)) {
    status.forEach((status) => {
      partialURL += `status=${status}&`;
    });
    url = `/api/v1/actions/${actionId}/device_actions?${partialURL}page=${
      pageNumber - 1
    }&limit=${limit}`;
  } else {
    url = `/api/v1/actions/${actionId}/device_actions?status=${status}&page=${
      pageNumber - 1
    }&limit=${limit}`;
  }
  if (phaseIndex >= 0) {
    url = `${url}&phase=${phaseIndex}`;
  }
  const res = await apiCall(encodeURI(url), true);
  return res;
}

export async function fetchAction(actionId: number): Promise<ActionStatusType> {
  const url = `/api/v1/actions/${actionId}`;
  const res = await apiCall(url, true);
  return res;
}

export async function triggerDeviceAction(actionBody: any): Promise<void> {
  return await apiCall("/api/v1/actions", true, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(actionBody),
  });
}

export async function approveAction(actionId: string): Promise<void> {
  const url = `/api/v1/actions/${encodeURIComponent(actionId)}/approve`;
  await apiCall(url, true, {
    method: "PUT",
  });
}

export async function fetchActionParams(actionId: string): Promise<{
  params: string;
}> {
  const url = `/api/v1/actions/${actionId}/params`;
  const res = await apiCall(url, true, {
    method: "GET",
  });
  return res;
}

export async function fetchAllActionStatus(
  pageNumber: number,
  pageLimit: number,
  sortByIncomplete: boolean = false,
  abortSignal: AbortSignal | null = null,
): Promise<ActionStatusResponse> {
  const url = `/api/v1/actions?page=${pageNumber}&limit=${pageLimit}&incomplete=${sortByIncomplete}`;
  const res = await apiCall(encodeURI(url), true, {
    signal: abortSignal,
  });
  return res;
}

export async function fetchAllDeviceActions(deviceId: number): Promise<any> {
  // use proper type
  const url = `/api/v1/devices/${encodeURIComponent(deviceId)}/actions`;
  const res = await apiCall(url, true, {
    method: "GET",
  });
  return res;
}

export async function markAllDeviceActionAsCompleted(
  deviceId: number,
): Promise<any> {
  // PUT: /devices/:id/actions/mark_as_completed
  const url = `/api/v1/devices/${encodeURIComponent(
    deviceId,
  )}/actions/mark_as_completed`;
  const res = await apiCall(url, true, {
    method: "PUT",
  });
  return res;
}

export async function markActionAsCompleted(actionId: string): Promise<any> {
  const url = `/api/v1/actions/${encodeURIComponent(
    actionId,
  )}/mark_as_completed`;
  let res = await apiCall(url, true, {
    method: "PUT",
  });
  return res;
}

export async function createFirmware(
  firmwareBody: FirmwareType,
): Promise<FirmwareType> {
  const res = await apiCall(`/api/v1/firmwares`, true, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(firmwareBody),
  });
  return res;
}

export async function uploadFile(
  url: string,
  body: FormData,
  onUploadProgress: Function,
): Promise<any> {
  const tenant = getTenantFromURL();

  const res = await axios.request({
    method: "POST",
    url: encodeURI(url),
    data: body,
    onUploadProgress: (p) => onUploadProgress(p),
    headers: {
      "X-Bytebeam-Tenant": tenant,
    },
  });

  return res;
}

export async function activateFirmware(
  version: string,
  deactivate: boolean,
): Promise<FirmwareType> {
  const res = await apiCall(
    `/api/v1/firmwares/${encodeURIComponent(version)}`,
    true,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        is_deactivated: deactivate,
      }),
    },
  );
  return res;
}

export function createDeviceConfiguration(
  version_name: string,
  config_json: any,
  action_type: string,
): Promise<any> {
  return apiCall(`/api/v1/device-configs`, true, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      version_name,
      config_json,
      action_type,
    }),
  });
}

export async function editDeviceConfiguration(
  version_name: string,
  config_json: any,
): Promise<any> {
  const res = await apiCall(
    `/api/v1/device-configs/${encodeURIComponent(version_name)}`,
    true,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        config_json,
      }),
    },
  );
  return res;
}

export async function fetchDeviceConfigurationDetails(
  name: string,
): Promise<DeviceConfigurationType> {
  const res = await apiCall(
    `/api/v1/device-configs/${encodeURIComponent(name)}`,
    true,
    {
      method: "GET",
    },
  );
  return res;
}

export async function deactivateDeviceConfig(
  version: string,
): Promise<DeviceConfigurationType> {
  const res = await apiCall(
    `/api/v1/device-configs/${encodeURIComponent(version)}/deactivate`,
    true,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  return res;
}
// --------------------------------------------------------- End of Actions APIs ---------------------------------------------------------

// --------------------------------------------------------- Start of Dashboard APIs ---------------------------------------------------------
export async function fetchDashboardShareOptions(): Promise<any> {
  const url = `/api/v1/dashboard-share-options`;
  const res = await apiCall(url, true);
  return res;
}

export async function editDashboard(
  dashboardId: number,
  dashboardBody: any,
): Promise<any> {
  const res = await apiCall(`/api/v1/dashboards/${dashboardId}`, true, {
    method: "POST",
    body: JSON.stringify(dashboardBody),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return res;
}

export async function deleteDashboard(dashboardId: number): Promise<void> {
  await apiCall(`/api/v1/dashboards/${dashboardId}`, true, {
    method: "DELETE",
  });
}

export type PanelData<MetaDataType extends PanelMetaData, DataType> = {
  panelDef: PanelDef<MetaDataType, DataType>;
  meta: MetaDataType;
  data?: DataType;
  isDirty: boolean;
  loading: boolean;
  error?: boolean;
};

export type FetchParams = {
  groupBys: string[];
  filterBys: { [key: string]: string[] };
  timeRange: TimeRange;
  fetchAll: boolean;
};

export async function fetchPanelData(
  metas: PanelMetaData[],
  { groupBys, filterBys, timeRange, fetchAll }: FetchParams,
  abortSignal: AbortSignal | null = null,
  dashboardType: DashboardType | null = null,
) {
  const startTime = Math.round(timeRange.getStartTime().toDate().valueOf());
  const endTime = Math.round(timeRange.getEndTime().toDate().valueOf());

  const aggregationInterval = Math.max(
    Math.round((endTime - startTime) / 200),
    1,
  );

  // For filter query, converting UI object to HoneySql query
  const queryMetas = metas.map((meta) => {
    if (meta.query && meta.query[0] !== "and" && meta.query.length > 0) {
      meta.query = objectToQuery(meta.query);
    } else if (meta.query?.length === 0) {
      delete meta["query"];
    }
    return meta;
  });

  // Doing dashboardType check here to customize the linechart metas to add groupBys
  const newMetas = queryMetas.map((meta) => {
    if (
      dashboardType &&
      dashboardType === "fleet" &&
      meta.type === "line_chart"
    ) {
      meta.timeseries = meta.timeseries.map((ts) => {
        ts.groupBys = ["id"];
        return ts;
      });
    }
    return meta;
  });

  // filterBys Cleanup due to state param begin appended from url
  if (filterBys.state) {
    delete filterBys.state;
  }

  const requestBody = {
    startTime,
    endTime,
    aggregationInterval,
    filterBys: filterBys,
    groupBys: groupBys,
    panels: newMetas,
    fetchAll: fetchAll,
  };

  try {
    const res = await apiCall(`/api/v1/panel-data`, true, {
      method: "post",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
      },
      signal: abortSignal,
    });
    return res;
  } catch (error) {
    console.error("Error occurred in fetchPanelData.");
    console.error(error);
    throw error;
  }
}

export async function fetchPanelDataFixedTimeRange(
  metas: PanelMetaData[],
  { groupBys, filterBys, timeRange, fetchAll }: FetchParams,
  startTimestamp,
  endTimestamp,
  abortSignal: AbortSignal | null = null,
) {
  const startTime =
    startTimestamp ?? Math.round(timeRange.getStartTime().toDate().valueOf());
  const endTime =
    endTimestamp ?? Math.round(timeRange.getEndTime().toDate().valueOf());

  const aggregationInterval = Math.max(
    Math.round((endTime - startTime) / 200),
    1,
  );

  const newMetas = metas.map((meta) => {
    if (meta.query && meta.query[0] !== "and" && meta.query.length > 0) {
      meta.query = objectToQuery(meta.query);
    } else if (meta.query?.length === 0) {
      delete meta["query"];
    }
    return meta;
  });

  // filterBys Cleanup due to state param begin appended from url
  if (filterBys.state) {
    delete filterBys.state;
  }

  const requestBody = {
    startTime,
    endTime,
    aggregationInterval,
    filterBys: filterBys,
    groupBys: groupBys,
    panels: newMetas,
    fetchAll: fetchAll,
  };

  try {
    const res = await apiCall(`/api/v1/panel-data`, true, {
      method: "post",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
      },
      signal: abortSignal,
    });
    return res;
  } catch (error) {
    console.error("Error occurred in fetchPanelData.");
    console.error(error);
    throw error;
  }
}

export async function fetchMicelioStatsPanelDetails(
  meta: PanelMetaData,
  {groupBys, filterBys, timeRange, fetchAll}: FetchParams
) {
  const startTime = Math.round(timeRange.getStartTime().toDate().valueOf());
  const endTime = Math.round(timeRange.getEndTime().toDate().valueOf());

  const aggregationInterval = Math.max(Math.round((endTime - startTime) / 200), 1);

  const requestBody = {
    startTime,
    endTime,
    aggregationInterval,
    filterBys: filterBys,
    groupBys: groupBys,
    panelMeta: meta,
    fetchAll: fetchAll
  }

  const response = await fetch(`/api/v1/micelio-stats-panel-details`, {
    method: "POST",
    body: JSON.stringify(requestBody),
    headers: {
      "Content-Type": "application/json"
    }
  })

  const details = await response.json();

  return details;
}

export async function fetchStreamTimeSeries(requestBody) {
  try {
    const res = await apiCall(`/api/v1/panel-data`, true, {
      method: "post",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (error) {
    console.error("Error occurred in fetchStreamTimeSeries.");
    console.error(error);
  }
}
// --------------------------------------------------------- End of Dashboard APIs ---------------------------------------------------------

export async function fetchAllDevices(
  pageNo: number | string = 1,
  limit: number | string = 10,
  // Handle showing active and inactive device here, status can be active, inactive or all
  status: string = "active",
  abortSignal: AbortSignal | null = null,
): Promise<Device[]> {
  const res = await apiCall(
    encodeURI(`/api/v1/devices?page=${pageNo}&limit=${limit}&status=${status}`),
    true,
    { signal: abortSignal },
  );
  return res;
}

export async function fetchDevicesCount(
  key: string,
  query: string = "",
  status: string = "active",
  abortSignal: AbortSignal | null = null,
): Promise<number> {
  // Handle showing active and inactive device here
  let apiEndpoint =
    query !== ""
      ? `/api/v1/devices/count?key=${key}&query=${query}&status=${status}`
      : `/api/v1/devices/count?status=${status}`;
  const res = await apiCall(encodeURI(apiEndpoint), true, {
    signal: abortSignal,
  });
  let data = await res.text();
  const deviceCount = parseInt(data);
  return deviceCount;
}

export async function createUser(user: User): Promise<{ result: IUser }> {
  const res = await apiCall("/api/v1/users", true, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  });
  return res;
}

export async function createApiKey(apiKey: ApiKey) {
  const res = await apiCall("/api/v1/apikeys", true, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(apiKey),
  });
  return res;
}

export async function editUser(
  userId: string,
  user: User,
): Promise<{ result: IUser }> {
  const res = await apiCall(
    `/api/v1/users/${encodeURIComponent(userId)}`,
    true,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    },
  );
  return res;
}

export async function createRole(role: {
  name: string;
  permissions: Permission;
}): Promise<{ result: { id: number; name: string } }> {
  const res = await apiCall("/api/v1/roles", true, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(role),
  });
  return res;
}

export async function editRole(
  roleId: number,
  role: { name: string; permissions: Permission },
): Promise<{ result: { id: number; name: string } }> {
  const res = await apiCall(`/api/v1/roles/${roleId}`, true, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(role),
  });
  return res;
}

export async function createMetadataKey(key: {
  key: string;
}): Promise<{ result: string }> {
  const res = await apiCall("/api/v1/devices/metadata-keys", true, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(key),
  });
  return res;
}

export async function editMetadataKey(
  oldKey: string,
  newkey: { key: string },
): Promise<{ result: string }> {
  const res = await apiCall(
    `/api/v1/devices/metadata-keys/${encodeURIComponent(oldKey)}`,
    true,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newkey),
    },
  );
  return res;
}

export async function createActionType(
  actionType: ActionType,
): Promise<{ result: ActionType }> {
  const res = await apiCall("/api/v1/action-types", true, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(actionType),
  });
  return res;
}

export async function editActionType(
  action: string,
  actionType: ActionType,
): Promise<{ result: ActionType }> {
  const request: any = { ...actionType };
  delete request.type;
  const res = await apiCall(
    `/api/v1/action-types/${encodeURIComponent(action)}`,
    true,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    },
  );
  return res;
}

export async function createStreamField(fieldBody: {
  fieldName: string;
  fieldType: string;
  streamName: string;
}): Promise<any> {
  const res = await apiCall("/api/v1/streams/fields", true, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(fieldBody),
  });
  return res;
}

export async function deleteStream(tableName: string): Promise<any> {
  const res = await apiCall(
    `/api/v1/streams/${encodeURIComponent(tableName)}`,
    true,
    {
      method: "DELETE",
    },
  );
  return res;
}

export async function deleteStreamField(
  tableName: string,
  columnName: string,
): Promise<any> {
  const res = await apiCall(
    `/api/v1/streams/${encodeURIComponent(
      tableName,
    )}/fields/${encodeURIComponent(columnName)}`,
    true,
    {
      method: "DELETE",
    },
  );
  return res;
}

export const streamProtobuff = "/api/v1/streams/protobuff";

export async function updateStreamField(fieldBody: {
  fieldName: string;
  fieldType: string;
  streamName: string;
}): Promise<any> {
  const res = await apiCall("/api/v1/streams/fields/update", true, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(fieldBody),
  });
  return res;
}

export async function createComputedStream(streamBody: {
  stream: { streamName: string; fields: { any } };
}): Promise<any> {
  const res = await apiCall("/api/v1/streams/computed_streams", true, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(streamBody),
  });
  return res;
}

export async function createNonComputedStream(stream: {
  streamName: string;
  fields: { any };
}): Promise<any> {
  const res = await apiCall("/api/v1/streams", true, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(stream),
  });
  return res;
}

export async function createDevice(
  metadata: any,
  dedup: string,
): Promise<{ cert: string; id: number; priv_key: string; pub_key: string }> {
  const res = await apiCall(
    `/api/v1/devices/provision?dedup=${encodeURIComponent(dedup)}`,
    true,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ metadata }),
    },
  );
  console.log('Device provision API response:');
  console.log(res);
  console.log(typeof res);
  return res;
}

export async function fetchAllReportTemplates(): Promise<any> {
  const res = await apiCall("/api/v1/reports/templates", false);
  return res;
}

export function downloadReportInstance(id: number): string {
  return `/api/v1/reports/instance/${id}/download`;
}

export async function fetchAllReportInstances(): Promise<any> {
  const res = await apiCall("/api/v1/reports/instances", false);
  return res;
}

export async function fetchReportTemplate(id: number): Promise<any> {
  const res = await apiCall(`/api/v1/reports/templates/${id}`, false);
  return res;
}

export function generateSampleReport(query: string): string {
  return `/api/v1/reports/sample?query=${encodeURIComponent(query)}`;
}

export async function createReport(
  url: string,
  method: string,
  reportBody: any,
): Promise<any> {
  const res = await apiCall(url, false, {
    method,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reportBody),
  });
  return res;
}

export async function fetchParserRule(id: string): Promise<any> {
  const res = await apiCall(
    `/api/v1/parser-rules/${encodeURIComponent(id)}`,
    false,
  );
  return res;
}

export async function fetchAllPacketFieldNames(
  packetType: string,
): Promise<any> {
  const res = await apiCall(
    `/api/v1/packet-types/${encodeURIComponent(packetType)}/field-names`,
    false,
  );
  return res;
}

export async function fetchAllPacketTypes(): Promise<any> {
  const res = await apiCall("/api/v1/packet-types", false);
  return res;
}

export async function fetchAllParserRules(): Promise<any> {
  const res = await apiCall("/api/v1/parser-rules", false);
  return res;
}

export async function deleteParserRule(id: number): Promise<any> {
  const res = await apiCall(
    `/api/v1/parser-rules/${encodeURIComponent(id)}`,
    false,
    {
      method: "DELETE",
    },
  );
  return res;
}

export async function editParserRule(id: number, ruleBody: any): Promise<any> {
  const res = await apiCall(`/api/v1/parser-rules/${id}`, false, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(ruleBody),
  });
  return res;
}

export async function fetchAllDashboards(): Promise<DashboardAPIResponse[]> {
  const res = await apiCall("/api/v1/dashboards", true);
  return res;
}

export async function createDashboard(dbBody: any): Promise<any> {
  const res = await apiCall("/api/v1/dashboards", true, {
    method: "POST",
    body: JSON.stringify(dbBody),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return res;
}

export function getEndUserAuth(): Promise<Auth> {
  // TODO this won't work in single tenant case!
  // const tenant = localStorage.getItem("tenant");
  const tenant = getTenantFromURL();

  return apiCall(`/api/v1/tenants/${tenant}/auth`, true);
}

export function putEndUserAuth(auth: Auth): Promise<any> {
  // TODO this won't work in single tenant case!
  // const tenant = localStorage.getItem("tenant");
  const tenant = getTenantFromURL();

  return apiCall(`/api/v1/tenants/${tenant}/auth`, true, {
    method: "PUT",
    body: JSON.stringify(auth),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function downloadCertificates(
  deviceId: number,
): Promise<Device[]> {
  const res = await apiCall(`/api/v1/devices/${deviceId}/cert`, true);
  return res.json();
}

export async function changeDeviceStatus(
  deviceId: number,
  state: string,
): Promise<Device[]> {
  const res = await apiCall(`/api/v1/devices/${deviceId}`, true, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      status: state, //"active|inactive"
    }),
  });
  return res;
}

export async function downloadFirmware(version: string): Promise<any> {
  const res = await apiCall(
    `/api/v1/firmwares/${encodeURIComponent(version)}/artifact`,
    true,
  );
  return res;
}

export async function downloadUpdateMetadataTemplate(): Promise<any> {
  const res = await apiCall("/api/v1/devices/metadata-bulk-template", true);
  return res;
}

export async function updateTenantSettings(settingBody: {
  settings: TenantSettings;
}): Promise<TenantSettings> {
  const res = await apiCall("/api/v1/me/tenant/settings", true, {
    method: "PUT",
    body: JSON.stringify(settingBody),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return res;
}

export async function updateUserSettings(settingBody: {
  settings: UserSettings;
}): Promise<UserSettings> {
  const res = await apiCall("/api/v1/me/settings", true, {
    method: "PUT",
    body: JSON.stringify(settingBody),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return res;
}

// --------------------------------------------------------- Start of Alert and Session APIs ---------------------------------------------------------
export async function fetchAlertTypes() {
  const res = await apiCall("/api/v1/alert-types", true);
  return res;
}

export async function fetchSessionTypes(): Promise<SessionType[]> {
  const res = await apiCall("/api/v1/session-types", true);
  return res;
}

export async function fetchAlertRules(): Promise<AlertRule[]> {
  const res = await apiCall("/api/v1/alert-rules", true);
  return res;
}

export async function createSessionType(
  sessionType: SessionType,
): Promise<any> {
  const res = await apiCall("/api/v1/session-types", true, {
    method: "POST",
    body: JSON.stringify(sessionType),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return res;
}

export async function createAlertRule(alertRule: AlertRule): Promise<any> {
  const res = await apiCall("/api/v1/alert-rules", true, {
    method: "POST",
    body: JSON.stringify(alertRule),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return res;
}

export async function createAlertNotificationRule(
  notificationRule: AlertNotificationRule,
): Promise<any> {
  const alertRuleId = notificationRule.alert_rule_id;
  const url = `/api/v1/alert-rules/${encodeURIComponent(
    alertRuleId,
  )}/alert-notification-rules`;

  const body = {
    channel_type: notificationRule.channel_type,
    channel_parameters: notificationRule.channel_parameters,
    interval_seconds: notificationRule.interval_seconds,
    notification_template: notificationRule.notification_template,
  };

  const res = await apiCall(url, true, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return res;
}

export async function updateAlertNotificationRule(
  notificationRule: AlertNotificationRule,
): Promise<any> {
  const alertRuleId = notificationRule.alert_rule_id;
  const notificationRuleId = notificationRule.id;

  if (!alertRuleId || !notificationRuleId) {
    console.error(
      "updateAlertNotificationRule: alertRuleId or notificationRuleId is null",
      notificationRule,
    );
    return;
  }

  const url = `/api/v1/alert-rules/${encodeURIComponent(
    alertRuleId,
  )}/alert-notification-rules/${encodeURIComponent(notificationRuleId)}`;

  const body = {
    channel_type: notificationRule.channel_type,
    channel_parameters: notificationRule.channel_parameters,
    interval_seconds: notificationRule.interval_seconds,
    notification_template: notificationRule.notification_template,
  };

  const res = await apiCall(url, true, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return res;
}

export async function deleteAlertNotificationRule(
  notificationRule: AlertNotificationRule,
): Promise<any> {
  const alertRuleId = notificationRule.alert_rule_id;
  const notificationRuleId = notificationRule.id;

  if (!alertRuleId || !notificationRuleId) {
    console.error(
      "deleteAlertNotificationRule: alertRuleId or notificationRuleId is null",
    );
    return;
  }

  const url = `/api/v1/alert-rules/${encodeURIComponent(
    alertRuleId,
  )}/alert-notification-rules/${encodeURIComponent(notificationRuleId)}`;

  const res = await apiCall(url, true, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });

  return res;
}

export async function startAlertRule(alertRuleId: string): Promise<any> {
  const res = await apiCall(
    `/api/v1/alert-rules/${encodeURIComponent(alertRuleId)}/start`,
    true,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  return res;
}

export async function stopAlertRule(alertRuleId: string): Promise<any> {
  const res = await apiCall(
    `/api/v1/alert-rules/${encodeURIComponent(alertRuleId)}/stop`,
    true,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  return res;
}

export async function startSessionType(sessionTypeName: string): Promise<any> {
  const res = await apiCall(
    `/api/v1/session-types/${encodeURIComponent(sessionTypeName)}/start`,
    true,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  return res;
}

export async function stopSessionType(sessionTypeName: string): Promise<any> {
  const res = await apiCall(
    `/api/v1/session-types/${encodeURIComponent(sessionTypeName)}/stop`,
    true,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  return res;
}

export async function deleteSessionType(sessionTypeName: string): Promise<any> {
  const res = await apiCall(
    `/api/v1/session-types/${encodeURIComponent(sessionTypeName)}`,
    true,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  return res;
}

export async function deleteAlertRule(alertRuleId: string): Promise<any> {
  const res = await apiCall(
    `/api/v1/alert-rules/${encodeURIComponent(alertRuleId)}`,
    true,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  return res;
}

export async function updateAlertRule(
  alertRuleId: string,
  alertRule: AlertRule,
): Promise<any> {
  const res = await apiCall(
    `/api/v1/alert-rules/${encodeURIComponent(alertRuleId)}`,
    true,
    {
      method: "PUT",
      body: JSON.stringify(alertRule),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  return res;
}
// --------------------------------------------------------- End of Alert and Session APIs ---------------------------------------------------------
