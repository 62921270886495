import React from "react";
import ThemeSchema from "../../../theme/schema";
import { useUser } from "../../../context/User.context";

interface ProgressChartProps {
  setInitiatedListCount: number;
  setQueuedListCount: number;
  setProgressListCount: number;
  setFailedListCount: number;
  setCompletedListCount: number;
  forDevice: boolean;
  actionStatus?: string;
}

function ProgressChart(props: ProgressChartProps) {

  const { user } = useUser();
  const theme = user?.settings?.theme;

  const statusArray = [
    {
      label: "Queued",
      class: "queued",
      count: props.setQueuedListCount,
    },
    {
      label: "Initiated",
      class: "initiated",
      count: props.setInitiatedListCount,
    },
    {
      label: "In Progress",
      class: "in-progress",
      count: props.setProgressListCount,
    },
    {
      label: "Completed",
      class: "completed",
      count: props.setCompletedListCount,
    },
    {
      label: "Failed",
      class: "failed",
      count: props.setFailedListCount,
    },
  ];

  let activeConnector = 0;

  if (props.setQueuedListCount > 0) activeConnector = 1;
  if (props.setProgressListCount > 0) activeConnector = 2;
  if (props.setCompletedListCount > 0) activeConnector = 3;
  if (props.setFailedListCount > 0) activeConnector = 4;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {statusArray.map((item) => (
        <>
          <div className={`action-progress-item ${item.class}`}>
            {props.forDevice === false
              ? item.count > 0 && (
                  <div
                    className={`action-progress-pulsating-circle ${item.class}`}
                  />
                )
              : props.actionStatus === item.label && (
                  <div
                    className={`action-progress-pulsating-circle ${item.class}`}
                  />
                )}
            <p style={{ margin: 0, color: ThemeSchema.data[theme ?? "dark"]?.colors["progress-bar-inner-text-color"] }}>
              {props.forDevice
                ? item.count === 0
                  ? props.actionStatus === item.label
                    ? item.count || item.count === 0
                      ? item.count + "%"
                      : "--"
                    : "--"
                  : item.count || item.count === 0
                  ? item.count + "%"
                  : "--"
                : item.count ?? "--"}
            </p>
            {props.forDevice === false
              ? item.count > 0 && (
                  <p
                    style={{
                      margin: 0,
                      color: ThemeSchema.data[theme ?? "dark"]?.colors["progress-bar-inner-text-color"],
                      position: "absolute",
                    }}
                  >
                    {item.count ?? "--"}
                  </p>
                )
              : props.actionStatus === item.label && (
                  <p
                    style={{
                      margin: 0,
                      color: ThemeSchema.data[theme ?? "dark"]?.colors["progress-bar-inner-text-color"],
                      position: "absolute",
                    }}
                  >
                    {props.forDevice
                      ? item.count === 0
                        ? props.actionStatus === item.label
                          ? item.count || item.count === 0
                            ? item.count + "%"
                            : "--"
                          : "--"
                        : item.count || item.count === 0
                        ? item.count + "%"
                        : "--"
                      : item.count ?? "--"}
                  </p>
                )}
            <p
              style={{
                margin: 0,
                position: "absolute",
                top: "60px",
                whiteSpace: "nowrap",
              }}
            >
              {item.label}
            </p>
          </div>
          {statusArray.lastIndexOf(item) !== statusArray.length - 1 && (
            <div
              className={`action-progress-connector ${
                statusArray.indexOf(item) + 1 <= activeConnector
                  ? statusArray.indexOf(item) === 3 && activeConnector === 4
                    ? "failed"
                    : "active"
                  : "inactive"
              }`}
            />
          )}
        </>
      ))}
    </div>
  );
}

export default ProgressChart;
