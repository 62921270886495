import styled from "styled-components"

import batteryAlertLight from './Icons/alerts/light/Icon-Battery.png'
import crashAlertLight from './Icons/alerts/light/Icon-Crash and fall.png'
import motorAlertLight from './Icons/alerts/light/Icon-Motor.png'
import operationalAlertLight from './Icons/alerts/light/Icon-Operational.png'
import sosAlertLight from './Icons/alerts/light/Icon-sos.png'
import theftAndTowAlertLight from './Icons/alerts/light/Icon-Theft and tow.png'
import unauthorizedAlertLight from './Icons/alerts/light/Icon-Unauthorized Access.png'

import batteryAlertDark from './Icons/alerts/dark/Icon-Battery.png'
import crashAlertDark from './Icons/alerts/dark/Icon-Crash and fall.png'
import motorAlertDark from './Icons/alerts/dark/Icon-Motor.png'
import operationalAlertDark from './Icons/alerts/dark/Icon-Operational.png'
import sosAlertDark from './Icons/alerts/dark/Icon-sos.png'
import theftAndTowAlertDark from './Icons/alerts/dark/Icon-Theft and tow.png'
import unauthorizedAlertDark from './Icons/alerts/dark/Icon-Unauthorized Access.png'

import IdleHighPriorityIcon  from './Icons/status/ICONS-Idle-High Priority.png'
import IdleLowPriorityIcon   from './Icons/status/ICONS-Idle-Low Priority.png'
import IdleNoalertIcon       from './Icons/status/ICONS-Idle-no alert.png'
import OfflineHighPriorityIcon from './Icons/status/ICONS-Offline-High Priority.png'
import OfflineLowPriorityIcon from './Icons/status/ICONS-Offline-Low Priority.png'
import OfflineNoalertIcon    from './Icons/status/ICONS-Offline-no alert.png'
import OntripHighPriorityIcon from './Icons/status/ICONS-Ontrip-High Priority.png'
import OntripLowIcon         from './Icons/status/ICONS-Ontrip-Low.png'
import OntripNoalertIcon     from './Icons/status/ICONS-Ontrip-no alert.png'
import PausedHighPriorityIcon from './Icons/status/ICONS-Paused-High Priority.png'
import PausedLowPriorityIcon from './Icons/status/ICONS-Paused-Low Priority.png'
import PausedNoalertIcon     from './Icons/status/ICONS-Paused-no alert.png'

export const alertIcons = {
  Battery: {
    light: batteryAlertLight,
    dark: batteryAlertDark
  },
  "Crash/Fall": {
    light: crashAlertLight,
    dark: crashAlertDark 
  },
  Motor: {
    light: motorAlertLight,
    dark: motorAlertDark 
  },
  Operational: {
    light: operationalAlertLight,
    dark: operationalAlertDark 
  },
  SoS: {
    light: sosAlertLight,
    dark: sosAlertDark 
  },
  "Theft/Tow": {
    light: theftAndTowAlertLight,
    dark: theftAndTowAlertDark 
  },
  "Unauthorized Access": {
    light: unauthorizedAlertLight,
    dark: unauthorizedAlertDark 
  }
}

export const stateIcons = {
  Idle: {
    highPriority: IdleHighPriorityIcon,
    lowPriority: IdleLowPriorityIcon,
    noalert: IdleNoalertIcon,
  },
  Offline: {
    highPriority: OfflineHighPriorityIcon,
    lowPriority: OfflineLowPriorityIcon,
    noalert: OfflineNoalertIcon
  },
  OnTrip: {
    highPriority: OntripHighPriorityIcon,
    lowPriority: OntripLowIcon,
    noalert: OntripNoalertIcon
  },
  Paused: {
    highPriority: PausedHighPriorityIcon,
    lowPriority: PausedLowPriorityIcon,
    noalert: PausedNoalertIcon
  }
}

export const InfoWindowContainer = styled.div`
  min-width: 250px;
  color: black;
`

export const InfoWindowHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const InfoWindowRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
`

export const InfoWindowDivider = styled.div`
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-top: 1px solid rgba(0, 0, 0, 0.5);
`

export const AlertIcon = styled.img`
  width: 40px;
  margin: 5px;
`

export const AlertText = styled.div`
  font-weight: bold;
  padding-right: 10px;
`

export const blrPolygon = [
  [77.5964355, 13.1122489],
  [77.4542999, 13.0403485],
  [77.4611664, 12.8549836],
  [77.7131653, 12.7890356],
  [77.7928162, 12.9844859],
  [77.5964355, 13.1122489]
] 

