import React, { Component, Fragment } from "react";
import "./dashboard-styles.css";

import { DashboardMeta, EditDashboardModal } from "./EditDashboardModal";
import ConfirmationModal from "../common/ConfirmationModal";
import ConfirmationModalMessage from "../common/ConfirmationModalMessage";
import { GridLayout } from "./DashboardBody";
import { SerializedTimeRange } from "./Datetime/TimeRange";
import { PanelMetaData } from "./Panel/PanelDef";
import { Table, Button, Icon, Grid, Dropdown } from "semantic-ui-react";

import { RouteComponentProps, withRouter } from "react-router-dom";

import * as uuid from "uuid";
import { User } from "../../../util";
import {
  canCreateDashboard,
  canDeleteDashboard,
  canEditDashboard,
  isUserAdmin,
} from "./util";
import { ButtonIcon } from "../util";

import styled from "styled-components";
import {
  createDashboard,
  deleteDashboard,
  editDashboard,
  fetchAllDashboards,
} from "../../../BytebeamClient";
import { Mixpanel } from "../common/MixPanel";
import { ErrorMessage } from "../../common/ErrorMessage";
import _ from "lodash";
import LoadingAnimation from "../../common/Loader";
import { StyledCard } from "../DeviceMangaement/Devices/Device";

export const DashboardTitle = styled.label`
  color: ${(props) => props.theme.colors["link-text-color"]} !important;
  cursor: pointer;
`;

export type Dashboard = {
  id: number;
  config: {
    dashboardMeta: DashboardMeta;
    gridLayouts: GridLayout;
    timeRange: SerializedTimeRange;
    panels: PanelMetaData[];
  };
};

type ListDashboardsState = {
  loading: boolean;
  dashboards: Dashboard[];
  showEditDashboardModal: boolean;
  showDeleteDashboardModal: boolean;
  editDashboardKey: string;
  editDashboard?: Dashboard;
  errorOccurred: boolean;
  cloneDashboard: boolean;
  sortColumn: string; // default sort column
  sortDirection: "ascending" | "descending" | undefined; // default sort direction
};

interface ListDashboardsProps extends RouteComponentProps {
  user: User;
  currentTenant: string;
}

export class ListDashboardsComponent extends Component<
  ListDashboardsProps,
  ListDashboardsState
> {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      dashboards: [],
      showEditDashboardModal: false,
      showDeleteDashboardModal: false,
      editDashboardKey: uuid.v4(),
      errorOccurred: false,
      cloneDashboard: false,
      sortColumn: "id", // default sort column
      sortDirection: "ascending", // default sort direction
    };
  }

  // async refreshDashboards() {
  //   this.setState({ loading: true });
  //   try {
  //     const res = await fetchAllDashboards();
  //     // filter dashboards for admin here
  //     //{"tenant_id":"demo","config":{"dashboardMeta":{"owners":["user:1"],"viewers":[],"hideFromAdmins":false,"allowedFilterBys":[],"timeRange":{"type":"RelativeTimeRange","units":"minutes","duration":5},"showTimeRange":true,"showRefreshInterval":true,"refreshInterval":-1,"type":"device","dashboardLinks":[],"title":"adminHide","presetFilters":{},"showMetadataKeys":[],"allowedGroupBys":[],"info":""}},"id":527}
  //     this.setState({
  //       dashboards: res,
  //       loading: false,
  //     });
  //   } catch (e) {
  //     this.setState({ errorOccurred: true });
  //     console.log(e);
  //   }
  // }
  async refreshDashboards() {
    this.setState({ loading: true });
    try {
      const res = await fetchAllDashboards();
      const userIdString = "user:" + this.props.user.id || "xx"; // Replace 'user.id' with the actual current user's ID
      const roleString = "role:" + this.props.user.role.id || "xx"; // Replace 'user.role' with the actual current user's role
      const filteredDashboards = res.filter((dashboard) => {
        const owners = dashboard?.config?.dashboardMeta?.owners;
        if (dashboard?.config?.dashboardMeta?.hideFromAdmins) {
          return owners && (owners.includes(userIdString) || owners.includes(roleString));
        } else {
          return true;
        }
      });

      this.setState({
        dashboards: filteredDashboards,
        loading: false,
      });
    } catch (e) {
      this.setState({ errorOccurred: true });
      console.log(e);
    }
  }

  onEditDashboardCancel() {
    this.setState({
      showEditDashboardModal: false,
      editDashboard: undefined,
      cloneDashboard: false,
    });
  }

  async updateDashboard(dashboard: Dashboard, dashboardMeta: DashboardMeta) {
    const config = {
      ...dashboard.config,
      dashboardMeta: dashboardMeta,
    };

    this.setState({
      showEditDashboardModal: false,
      editDashboard: undefined,
    });
    try {
      await editDashboard(dashboard.id, { config });
      Mixpanel.track("Edit Dashboard Meta", {
        dashboardID: dashboard.id,
      });
      await this.refreshDashboards();
    } catch (e) {
      Mixpanel.track("Failure", {
        type: "Edit Dashboard Meta",
        error: JSON.stringify(e),
      });
      console.log(e);
    }
  }

  async createDashboard(dashboardMeta: DashboardMeta) {
    const config = { dashboardMeta };

    this.setState({
      loading: true,
      showEditDashboardModal: false,
    });

    try {
      const dashboard = await createDashboard({ config });
      Mixpanel.track("Create Dashboard", {
        dashboardID: dashboard.id,
      });
      this.props.history.push(
        `/projects/${this.props.currentTenant}/dashboards/${dashboard.id}`
      );
    } catch (e) {
      console.log(e);
      window.toastr.error("Failed to Create Dashboard.");
      Mixpanel.track("Failure", {
        type: "Create Dashboard",
        error: JSON.stringify(e),
      });
      this.setState({
        loading: false,
      });
    }
  }

  async cloneDashboard(dashboard: Dashboard, dashboardMeta: DashboardMeta) {
    const config = {
      ...dashboard.config,
      dashboardMeta: dashboardMeta,
    };

    this.setState({
      loading: true,
      showEditDashboardModal: false,
      cloneDashboard: false,
    });

    try {
      const dashboard = await createDashboard({ config });
      Mixpanel.track("Clone Dashboard", {
        Cloned_dashboardID: dashboard.id,
      });
      this.props.history.push(
        `/projects/${this.props.currentTenant}/dashboards/${dashboard.id}`
      );
    } catch (e) {
      Mixpanel.track("Failure", {
        type: "Clone Dashboard",
        error: JSON.stringify(e),
      });
      console.log(e);
    }
  }

  onEditDashboardSubmit(dashboardMeta: DashboardMeta) {
    if (this.state.editDashboard) {
      if (this.state.cloneDashboard) {
        this.cloneDashboard(this.state.editDashboard, dashboardMeta);
      } else {
        this.updateDashboard(this.state.editDashboard, dashboardMeta);
      }
    } else {
      this.createDashboard(dashboardMeta);
    }
  }

  onEditDashboard(dashboard: Dashboard) {
    this.setState({
      showEditDashboardModal: true,
      editDashboardKey: uuid.v4(),
      editDashboard: dashboard,
    });
  }

  onCloneDashboard(dashboard: Dashboard) {
    const dashboardMeta = {
      ...dashboard.config.dashboardMeta,
      title: "Copy of " + dashboard.config.dashboardMeta.title,
    };

    const config = {
      ...dashboard.config,
      dashboardMeta: dashboardMeta,
    };

    this.setState({
      showEditDashboardModal: true,
      editDashboardKey: uuid.v4(),
      editDashboard: { id: dashboard.id, config: config },
      cloneDashboard: true,
    });
  }

  onAddDashboard() {
    this.setState({
      showEditDashboardModal: true,
      editDashboardKey: uuid.v4(),
      editDashboard: undefined,
    });
  }

  async onDeleteDashboard(dashboard: Dashboard) {
    try {
      await deleteDashboard(dashboard.id);
      await this.refreshDashboards();
    } catch (e) {
      console.log(e);
    }
  }

  componentDidMount() {
    document.title = "Dashboards | Bytebeam";
    // To scroll to top of the screen
    window.scrollTo(0, 0);
    this.refreshDashboards();
  }

  createDashboardInfo = (dashboards) =>
    dashboards.map((item) => ({
      id: item.id,
      title: item.config.dashboardMeta.title,
      type: item.config.dashboardMeta.type,
    }));

  sortDashboards(dashboards, column, direction) {
    let orderByDirection = direction === "ascending" ? "asc" : "desc";
    if (column === "id") {
      return _.orderBy(
        dashboards,
        [(dashboard) => dashboard.id],
        [orderByDirection]
      );
    } else {
      return _.orderBy(
        dashboards,
        [(dashboard) => dashboard.config.dashboardMeta[column].toLowerCase()],
        [orderByDirection]
      );
    }
  }

  handleSort = (column) => {
    let direction: "ascending" | "descending" | undefined =
      this.state.sortDirection === "ascending" ? "descending" : "ascending";
    if (this.state.sortColumn !== column) {
      direction = "ascending";
    }
    this.setState(
      {
        sortColumn: column,
        sortDirection: direction,
      },
      () => {
        this.setState({
          dashboards: this.sortDashboards(
            this.state.dashboards,
            column,
            direction
          ),
        });
      }
    );
  };

  render() {
    const { user } = this.props;

    const {
      loading,
      dashboards,
      showEditDashboardModal,
      editDashboard,
      editDashboardKey,
      cloneDashboard,
      sortColumn,
      sortDirection,
    } = this.state;

    if (loading) {
      return (
        <LoadingAnimation
          loaderContainerHeight="calc(100vh - 130px)"
          loadingText="Loading Dashboards"
          fontSize="20px"
        />
      );
    }

    const allDashboardInfo = this.createDashboardInfo(dashboards);

    return (
      <div>
        <div className="list-dashboards-header">
          <h3> Dashboards </h3>
          {canCreateDashboard(user) ? (
            <Button
              icon
              floated="right"
              labelPosition="left"
              primary
              onClick={this.onAddDashboard.bind(this)}
            >
              <Icon name="plus" />
              Create Dashboard
            </Button>
          ) : (
            ""
          )}
        </div>

        <EditDashboardModal
          user={user}
          isOpen={showEditDashboardModal}
          key={editDashboardKey}
          dashboardMeta={editDashboard?.config.dashboardMeta}
          dashboardJSON={editDashboard?.config}
          dashboardId={editDashboard ? editDashboard.id : -1}
          onCancel={this.onEditDashboardCancel.bind(this)}
          onSubmit={this.onEditDashboardSubmit.bind(this)}
          refreshFunction={this.refreshDashboards.bind(this)}
          isAdmin={isUserAdmin(user)}
          cloneDashboard={cloneDashboard}
          allDashboards={allDashboardInfo}
        />

        <Table className="responsive-table" celled selectable fixed sortable>
          <Table.Header className="desktop-view">
            <Table.Row>
              <Table.HeaderCell
                sorted={sortColumn === "id" ? sortDirection : undefined}
                onClick={() => this.handleSort("id")}
                width={1}
              >
                ID
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sortColumn === "title" ? sortDirection : undefined}
                onClick={() => this.handleSort("title")}
                width={4}
              >
                Title
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sortColumn === "info" ? sortDirection : undefined}
                onClick={() => this.handleSort("info")}
                width={5}
              >
                Description
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sortColumn === "type" ? sortDirection : undefined}
                onClick={() => this.handleSort("type")}
                width={2}
              >
                Type
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {dashboards.length !== 0 ? (
              dashboards.map((dashboard) => {
                const isDashboardEditable = canEditDashboard(
                  dashboard.config.dashboardMeta,
                  user
                );
                const isDashboardCloneable = canCreateDashboard(user);
                const isDashboardDeletable = canDeleteDashboard(
                  dashboard.config.dashboardMeta,
                  user
                );

                const isActionsColumnVisible =
                  isDashboardEditable ||
                  isDashboardCloneable ||
                  isDashboardDeletable;

                return (
                  <Fragment key={dashboard.id}>
                    <Table.Row
                      className="desktop-view"
                      key={dashboard.id}
                      style={{ cursor: "pointer" }}
                    >
                      <Table.Cell
                        onClick={() => {
                          this.props.history.push(
                            `/projects/${this.props.currentTenant}/dashboards/${dashboard.id}`
                          );
                        }}
                      >
                        {dashboard.id}
                      </Table.Cell>

                      <Table.Cell
                        id={`${dashboard.config.dashboardMeta.title}`}
                        onClick={() => {
                          this.props.history.push(
                            `/projects/${this.props.currentTenant}/dashboards/${dashboard.id}`
                          );
                        }}
                      >
                        <DashboardTitle>
                          {dashboard.config.dashboardMeta.title}
                        </DashboardTitle>
                      </Table.Cell>

                      <Table.Cell>
                        {dashboard.config.dashboardMeta.info || "--"}
                      </Table.Cell>

                      <Table.Cell>
                        {_.capitalize(dashboard.config.dashboardMeta.type) ||
                          "--"}
                      </Table.Cell>

                      <Table.Cell>
                        {isDashboardEditable ? (
                          <ButtonIcon
                            link
                            onClick={() => this.onEditDashboard(dashboard)}
                            title="Edit Dashboard"
                            name="edit"
                          />
                        ) : (
                          ""
                        )}

                        {isDashboardDeletable ? (
                          <ConfirmationModal
                            prefixContent="Delete dashboard"
                            expectedText={dashboard.config.dashboardMeta.title}
                            onConfirm={async () =>
                              await this.onDeleteDashboard(dashboard)
                            }
                            trigger={
                              <ButtonIcon
                                link
                                title="Delete Dashboard"
                                name="trash"
                              />
                            }
                            message={
                              <ConfirmationModalMessage
                                name={dashboard.config.dashboardMeta.title}
                                type={"Dashboard Item"}
                                specialMessage=""
                              />
                            }
                          />
                        ) : (
                          ""
                        )}

                        {isDashboardCloneable ? (
                          <ButtonIcon
                            link
                            onClick={() => this.onCloneDashboard(dashboard)}
                            title="Clone Dashboard"
                            name="clone"
                          />
                        ) : (
                          ""
                        )}
                      </Table.Cell>
                    </Table.Row>
                    <StyledCard className="mob-view">
                      <Grid>
                        <Grid.Row>
                          <Grid.Column
                            width={isActionsColumnVisible ? 14 : 16}
                            onClick={() => {
                              this.props.history.push(
                                `/projects/${this.props.currentTenant}/dashboards/${dashboard.id}`
                              );
                            }}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              cursor: "pointer",
                            }}
                          >
                            <DashboardTitle>
                              {dashboard.config.dashboardMeta.title}
                            </DashboardTitle>
                          </Grid.Column>
                          {isActionsColumnVisible && (
                            <Grid.Column
                              width={2}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "0px",
                              }}
                            >
                              <Dropdown
                                style={{ border: "none" }}
                                direction="left"
                                trigger={<Icon name="ellipsis vertical" />}
                                icon={null}
                              >
                                <Dropdown.Menu>
                                  {isDashboardEditable && (
                                    <Dropdown.Item
                                      onClick={() =>
                                        this.onEditDashboard(dashboard)
                                      }
                                    >
                                      <Icon name="edit" />
                                      Edit Dashboard
                                    </Dropdown.Item>
                                  )}
                                  {isDashboardCloneable && (
                                    <Dropdown.Item
                                      onClick={() =>
                                        this.onCloneDashboard(dashboard)
                                      }
                                    >
                                      <Icon name="clone" />
                                      Clone Dashboard
                                    </Dropdown.Item>
                                  )}
                                  {isDashboardDeletable && (
                                    <ConfirmationModal
                                      prefixContent="Delete dashboard"
                                      expectedText={
                                        dashboard.config.dashboardMeta.title
                                      }
                                      onConfirm={async () =>
                                        await this.onDeleteDashboard(dashboard)
                                      }
                                      trigger={
                                        <Dropdown.Item>
                                          <Icon name="trash" />
                                          Delete Dashboard
                                        </Dropdown.Item>
                                      }
                                      message={
                                        <ConfirmationModalMessage
                                          name={
                                            dashboard.config.dashboardMeta.title
                                          }
                                          type={"Dashboard Item"}
                                          specialMessage=""
                                        />
                                      }
                                    />
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </Grid.Column>
                          )}
                        </Grid.Row>
                      </Grid>
                    </StyledCard>
                  </Fragment>
                );
              })
            ) : (
              <Table.Row>
                <Table.Cell colspan={"5"}>
                  <ErrorMessage
                    marginTop="30px"
                    message={"No Dashboards Found!"}
                  />
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export const ListDashboards = withRouter(ListDashboardsComponent);
