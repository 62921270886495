import { MicelioTrackDevicesMetaData } from "./PanelDef";
import React from "react";

import { Tab } from "semantic-ui-react";
import {
  PanelEditComponent,
  EditComponentProps,
  PartialMetaData,
} from "../PanelDef";
import {
  EditMetaRow,
  EditMetaRoot,
  filterNumericalTypeColumnOptions,
  EditPanelFormContainer,
  EditAnimatedMetaInput,
  EditAnimatedMetaDropdown,
} from "../util";

export type MicelioEditTrackDevicesMetaState = {
  table: string;
  latitude_column: string;
  longitude_column: string;
};

export class MicelioEditTrackDevicesMeta extends PanelEditComponent<
  MicelioTrackDevicesMetaData,
  MicelioEditTrackDevicesMetaState
> {
  titleRef = React.createRef<HTMLInputElement>();
  descriptionRef = React.createRef<HTMLInputElement>();
  deviceDashboardIdRef = React.createRef<HTMLInputElement>();

  constructor(props: EditComponentProps<MicelioTrackDevicesMetaData>) {
    super(props);

    if (props.panelMeta) {
      this.state = {
        table: props.panelMeta.table,
        latitude_column: props.panelMeta.latitude_column,
        longitude_column: props.panelMeta.longitude_column,
      };
    } else {
      this.state = {
        table: "",
        latitude_column: "",
        longitude_column: "",
      };
    }
  }

  getPanelMeta(type): PartialMetaData<MicelioTrackDevicesMetaData> {
    const complete =
      !!this.titleRef.current?.value &&
      !!this.state.table &&
      !!this.state.latitude_column &&
      !!this.state.longitude_column;

    return {
      meta: {
        type: "micelio_track_devices",
        id: this.props.panelMeta.id,
        title: this.titleRef.current?.value || "",
        description: this.descriptionRef.current?.value || "",
        table: this.state.table || "",
        latitude_column: this.state.latitude_column || "",
        longitude_column: this.state.longitude_column || "",
        device_dashboard_id: this.deviceDashboardIdRef.current?.value || "",
        trafficLayerFlag: false,
        satelliteLayerFlag: false,
      },
      complete: complete,
    };
  }

  isValidPanelMeta(meta: MicelioTrackDevicesMetaData) {
    return !!meta.table && !!meta.latitude_column && !!meta.longitude_column;
  }

  setTable(_event, data) {
    this.setState({
      table: data.value,
    });
  }

  setLatitudeColumn(_event, data) {
    this.setState({
      latitude_column: data.value,
    });
  }

  setLongitudeColumn(_event, data) {
    this.setState({
      longitude_column: data.value,
    });
  }

  render() {
    const title = this.props.panelMeta.title;
    const description = this.props.panelMeta.description;
    const deviceDashboardId = this.props.panelMeta.device_dashboard_id;

    const tableOptions = Object.keys(this.props.tables).map((t) => {
      return {
        key: t,
        text: t,
        value: t,
      };
    });

    let latitudeColumnOptions: Array<{
      key: string;
      value: string;
      text: string;
    }> = [];
    let longitudeColumnOptions: Array<{
      key: string;
      value: string;
      text: string;
    }> = [];

    if (!!this.state.table) {
      latitudeColumnOptions = filterNumericalTypeColumnOptions(
        this.props.tables[this.state.table]
      ).map((f: { name: string; type: string }) => {
        return {
          key: f.name,
          text: f.name,
          value: f.name,
        };
      });
    }

    if (!!this.state.table) {
      longitudeColumnOptions = filterNumericalTypeColumnOptions(
        this.props.tables[this.state.table]
      ).map((f: { name: string; type: string }) => {
        return {
          key: f.name,
          text: f.name,
          value: f.name,
        };
      });
    }

    const panes = [
      {
        menuItem: "General",
        pane: (
          <Tab.Pane key={"general"}>
            <EditPanelFormContainer>
              <div style={{ width: "100%", marginTop: "16px" }} />
              <EditMetaRow>
                <div style={{ width: "48%" }}>
                  <EditAnimatedMetaInput
                    defaultRef={this.titleRef}
                    defaultValue={title}
                    label="Title"
                  />
                </div>
                <div style={{ width: "48%" }}>
                  <EditAnimatedMetaInput
                    defaultRef={this.descriptionRef}
                    defaultValue={description}
                    label="Description"
                  />
                </div>
              </EditMetaRow>

              <EditMetaRow>
                <div style={{ width: "50%", marginTop: "10px" }}>
                  <EditAnimatedMetaDropdown
                    placeholder="Table"
                    text={this.state.table}
                    fluid
                    search
                    selection
                    options={tableOptions}
                    onChange={this.setTable.bind(this)}
                    defaultValue={this.props.panelMeta.table}
                    elementID={`tableMicelioTrackDevices`}
                  />
                </div>
              </EditMetaRow>

              <EditMetaRow>
                <div style={{ width: "50%", marginTop: "10px" }}>
                  <EditAnimatedMetaDropdown
                    placeholder="Latitude Column"
                    text={this.state.latitude_column}
                    fluid
                    search
                    selection
                    disabled={this.state.table === ""}
                    options={latitudeColumnOptions}
                    onChange={this.setLatitudeColumn.bind(this)}
                    defaultValue={this.props.panelMeta.latitude_column}
                    elementID={`micelioLatitudeColumn`}
                  />
                </div>
              </EditMetaRow>

              <EditMetaRow>
                <div style={{ width: "50%", marginTop: "10px" }}>
                  <EditAnimatedMetaDropdown
                    placeholder="Longitude Column"
                    text={this.state.longitude_column}
                    fluid
                    search
                    selection
                    disabled={this.state.table === ""}
                    options={longitudeColumnOptions}
                    onChange={this.setLongitudeColumn.bind(this)}
                    defaultValue={this.props.panelMeta.longitude_column}
                    elementID={`micelioLongitudeColumn`}
                  />
                </div>
              </EditMetaRow>

              <EditMetaRow>
                <div style={{ width: "48%" }}>
                  <EditAnimatedMetaInput
                    defaultRef={this.deviceDashboardIdRef}
                    defaultValue={deviceDashboardId}
                    label="Device dashboard ID"
                  />
                </div>
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <EditMetaRoot>
        <Tab menu={{}} panes={panes} renderActiveOnly={false} />
      </EditMetaRoot>
    );

  }
}
