import React from "react";
import styled from "styled-components";
import { Header } from "semantic-ui-react";


export const StyledHeader = styled(Header)`
  color: ${(props) => props.theme.colors["text"]};
  margin-bottom: 0.2rem;
`;

const SelectableItemWrapper = styled.div<{ selected: boolean }>`
  display: flex;
  border-radius: 5px;
  border: 1px solid #1e1e1e;
  background-color: ${(props) =>
    props.selected
      ? props.theme.colors["action-selectable-item-selected"]
      : props.theme.colors["background-color"]};
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: 300ms all;

  /* &:hover {
    background-color: ${(props) =>
    props.theme.colors["action-selectable-item-selected-hover"]};
  } */
`;

export default function (props) {
  return (
    <SelectableItemWrapper selected={props.selected} onClick={props.onClick}>
      <StyledHeader
        as="h3"
        style={{
          marginTop: "0px",
          marginBottom: "0px",
          fontSize: "1.1rem",
          whiteSpace: "nowrap",
        }}
      >
        {props.title}
      </StyledHeader>
    </SelectableItemWrapper>
  );
}
