import React from "react";
import { StyledHeader } from "../SelectableItem";
import ActionModalDeviceFilter from "../../action-modals/ActionModalDeviceFilter";
import { NewActionLabelContainer, StyledNonBoldHeader } from "./NewAction";

const ActionFilterSection = ({
  filterOptions,
  updateFilters,
  filterList,
  setFilterList,
  filters,
  resetFilters,
}) => {
  return (
    <NewActionLabelContainer style={{ marginBottom: "20px" }}>
      <StyledHeader
        as="h3"
        style={{
          marginTop: "0px",
          marginBottom: "0px",
          fontSize: "1.2rem",
          whiteSpace: "nowrap",
        }}
      >
        Filter by
      </StyledHeader>
      <div
        style={{
          display: "flex",
          flexGrow: 1,
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            marginLeft: "20px",
            display: "flex",
            minHeight: "35px",
            flexWrap: "wrap",
          }}
        >
          <ActionModalDeviceFilter
            filterOptions={filterOptions}
            onFilterSelected={updateFilters}
            filterList={filterList}
            setFilterList={setFilterList}
          />
        </div>
        {Object.keys(filters).length !== 0 && (
          <div
            style={{
              alignSelf: "center",
            }}
          >
            <StyledNonBoldHeader
              as="h3"
              style={{
                marginTop: "0px",
                marginBottom: "0px",
                fontSize: "1.1rem",
                whiteSpace: "nowrap",
              }}
              className={`${
                Object.keys(filters).length === 0
                  ? "color-disabled"
                  : "selectable-item underline hover-underline color-blue"
              }`}
              onClick={() => {
                resetFilters();
              }}
            >
              Clear All
            </StyledNonBoldHeader>
          </div>
        )}
      </div>
    </NewActionLabelContainer>
  );
};

export default ActionFilterSection;
