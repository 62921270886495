import uuid from "uuid";
import { PanelDef } from "../PanelDef";
import { ViewMicelioStatusTable } from "./ViewMicelioStatusTable";
import { EditMicelioStatusTableMeta } from "./EditMicelioStatusTableMeta";

export class MicelioStatusTableMetaData {
  type = "micelio_status_table";
  title = "Status";
  description: string = "";
  id = uuid.v4();
}

export type MicelioStatusTableData = {
  statuses: {[key: string]: {count: string}};
  total: number;
}

export const MicelioStatusTablePanelDef: PanelDef<
  MicelioStatusTableMetaData,
  MicelioStatusTableData> = {
  ViewComponent: ViewMicelioStatusTable,
  EditComponent: EditMicelioStatusTableMeta,
  formatData: (data) => data,
  previewAspectRatio: { width: 500, height: 300 },
  metaConstructor: MicelioStatusTableMetaData
}
