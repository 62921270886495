import uuid from "uuid";
import { TimeRange } from "../../Datetime/TimeRange";
import { ReplayState } from "../../DashboardHeader";
import { PanelDef } from "../PanelDef";
import { ViewTrackDevices } from "./ViewTrackDevices";
import { EditTrackDevicesMeta } from "./EditTrackDevicesMeta";
import { Alert } from "../Alerts/PanelDef";

export class TrackDevicesMetaData {
  type = "track_devices";
  table: string = "";
  latitude_column: string = "";
  longitude_column: string = "";
  id: string = uuid.v4();
  title: string = "";
  description: string = "";
  device_dashboard_id: string = "";
  trafficLayerFlag: boolean = false;
  satelliteLayerFlag: boolean = false;
  markerIcon?: string = "default";
}

export type DeviceCoordinates = {
  latitude: number;
  longitude: number;
  timestamp: number;
};

export type DeviceRoute = {
  id: string;
  coordinates: DeviceCoordinates[];
  metadata: {[key: string]: string};
  state: {[key: string]: string};
  device_config: Array<Array<[number, number]>>;
  alerts: Alert[];
};

export type TrackDevicesData = DeviceRoute[];

export function formatData(
  data: TrackDevicesData,
  timeRange: TimeRange,
  replayState: ReplayState,
  replayStep: number
): TrackDevicesData {
  if (replayState === ReplayState.ReplayStopped) {
    return data;
  }

  let index = 0;
  const startTimestamp = timeRange.getStartTime().toDate().valueOf();
  const endTimestamp = timeRange.getEndTime().toDate().valueOf();

  return data.map((deviceRoute) => {
    const coords = deviceRoute.coordinates;
    let newCoords: DeviceCoordinates[] = [];

    for (let i = startTimestamp; i <= endTimestamp; i += replayStep) {
      while (index < coords.length) {
        if (coords[index].timestamp > i) {
          break;
        }

        index++;
      }

      index = index === 0 ? 0 : index - 1;

      newCoords.push(coords[index]);
    }

    return {
      id: deviceRoute.id,
      coordinates: newCoords,
      metadata: deviceRoute.metadata,
      state: deviceRoute.state,
      device_config: deviceRoute.device_config,
      alerts: deviceRoute.alerts,
    };
  });
}

export const TrackDevicesPanelDef: PanelDef<
  TrackDevicesMetaData,
  TrackDevicesData
> = {
  ViewComponent: ViewTrackDevices,
  EditComponent: EditTrackDevicesMeta,
  formatData: formatData,
  metaConstructor: TrackDevicesMetaData,
  previewAspectRatio: { width: 500, height: 300 },
};
