import React from "react";
import "react-grid-layout/css/styles.css";
import "./dashboard-styles.css";
import "react-resizable/css/styles.css";
import "react-datetime/css/react-datetime.css";
import { Divider, Icon } from "semantic-ui-react";
import {
  TimeRange,
  RelativeTimeRange,
  AbsoluteTimeRange,
  deserializeTimeRange,
  SerializedTimeRange,
} from "./Datetime/TimeRange";

import { PanelData, ThinDivider, getPanelDefForPanelType } from "./Panel/util";

import { EditPanelModal } from "./Panel/EditPanelModal";
import * as uuid from "uuid";
import { DashboardHeader, ReplayState } from "./DashboardHeader";
import { GridLayout, DashboardBody } from "./DashboardBody";
import {
  EditDashboardModal,
  DashboardMeta,
  DashboardType,
} from "./EditDashboardModal";
import deepEquals from "fast-deep-equal";
import { PanelMetaData, PanelDef } from "./Panel/PanelDef";
import { AddPanelModal } from "./Panel/AddPanelModal";
import Slider, { createSliderWithTooltip } from "rc-slider";
import "rc-slider/assets/index.css";
import { AbsoluteTimestamp } from "./Datetime/Timestamp";
import { filterStreamTableInfo } from "../util";
import moment, { Moment } from "moment";
import { Settings, User } from "../../../util";
import { canEditDashboard, isUserAdmin } from "./util";
import {
  fetchDashboard,
  fetchAllDashboards,
  PanelDataMap,
  TableInfo,
  fetchDevice,
  Device,
  DeviceFilters,
  editDashboard,
  fetchPanelData,
  fetchAllStreamsWithDetails,
  getTenantFromURL,
} from "../../../BytebeamClient";
import { DeviceSearch } from "./DeviceSearch/DeviceSearch";
import { queryToObject } from "../common/QuerySelector";
import { Mixpanel } from "../common/MixPanel";
import _ from "lodash";
import LoadingAnimation from "../../common/Loader";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { LogsMetaData } from "./Panel/Logs/PanelDef";

type ReplaySliderProps = {
  timeRange: TimeRange;
  currentTimestamp: number;
  onTimestampChange: (value: number) => void;
};

function ReplaySlider(props: ReplaySliderProps) {
  const start = props.timeRange.getStartTime().toDate().valueOf();
  const end = props.timeRange.getEndTime().toDate().valueOf();
  const SliderWithTooltip = createSliderWithTooltip(Slider);

  return (
    <SliderWithTooltip
      min={start}
      max={end}
      value={props.currentTimestamp}
      tipFormatter={(value) => moment(new Date(value)).format("DD-MM-YY HH:mm")}
      onChange={(value) => props.onTimestampChange(value)}
    />
  );
}

export type DashboardsInfo = {
  type: string;
  id: number;
  title: string;
};

type ViewDashboardState = {
  id: number;
  dashboardMeta: DashboardMeta;
  device: Device;
  panels: PanelDataMap;
  timeRange: TimeRange;
  //dataTimeRange: This field is used so that Panels get updated time-range when the refreshed data is fetched.
  dataTimeRange: TimeRange;
  breakPoint: string;
  refreshInterval: number;
  gridLayouts: GridLayout;
  showEditPanelScreen: boolean;
  showAddPanelScreen: boolean;
  editPanelData?: PanelData<PanelMetaData, any>;
  editPanelKey: string;
  addPanelKey: string;
  comparisonVisible: boolean;
  filters: { [key: string]: string[] };
  isDirty: boolean;
  showWarningModal: boolean;
  showEditDashboardModal: boolean;
  editDashboardKey: string;
  groupByOptions: string[];
  tableInfo: TableInfo;
  loading: boolean;
  loadingPanels: boolean;
  refreshing: boolean;
  replayState: ReplayState;
  replayTimestamp: number;
  replayStartTimestamp: number;
  lastRefreshTime: Moment | null;
  isRefreshStale: boolean;
  showError: string;
  // panelKeyPrefix is use to force a rerender of panels whenever a panel is resized or a filter is selected.
  // This is required for big number to resize its contents; maps widget to reset zoom; time-series table widget to reset pagination
  panelKeyPrefix: string;
  dashboards: DashboardsInfo[];
  randomIncrementKey: string;
  hoverPoint?: any;
};

interface ViewDashboardProps extends RouteComponentProps {
  id: number;
  user: User;
  settings: Settings;
  beingCompared?: boolean;
}

export class ViewDashboard extends React.Component<
  ViewDashboardProps,
  ViewDashboardState
> {
  state: ViewDashboardState = {
    id: 0,
    dashboardMeta: {
      title: "Untitled",
      info: "",
      allowedFilterBys: [],
      showMetadataKeys: [],
      allowedGroupBys: [],
      refreshInterval: 5,
      owners: [],
      viewers: [],
      type: DashboardType.FleetDashboard,
      timeRange: new RelativeTimeRange(5, "minutes").serialize(),
      showTimeRange: true,
      showRefreshInterval: true,
    },
    panels: {},
    gridLayouts: {
      lg: [],
      xs: [],
    },
    breakPoint: "lg",
    timeRange: new RelativeTimeRange(15, "minutes"),
    dataTimeRange: new RelativeTimeRange(15, "minutes"),
    refreshInterval: 5,
    showEditPanelScreen: false,
    showAddPanelScreen: false,
    editPanelKey: uuid.v4(),
    addPanelKey: uuid.v4(),
    filters: {},
    comparisonVisible: false,
    isDirty: false,
    showWarningModal: false,
    showEditDashboardModal: false,
    editDashboardKey: uuid.v4(),
    groupByOptions: [],
    tableInfo: {},
    loading: true,
    loadingPanels: true,
    refreshing: false,
    replayState: ReplayState.ReplayStopped,
    replayTimestamp: new Date().valueOf(),
    replayStartTimestamp: new Date().valueOf(),
    panelKeyPrefix: uuid.v4(),
    device: {
      id: 0,
      metadata: {},
    } as Device,
    dashboards: [{ id: 0, title: "", type: "" }],
    lastRefreshTime: null,
    isRefreshStale: false,
    randomIncrementKey: uuid.v4(),
    showError: "",
  };

  cancelled: boolean = false;
  abortController = new AbortController();
  abortControllerFilter = new AbortController();
  abortControllerFilterIncrement = new AbortController();

  async refreshDevice(deviceId: string) {
    try {
      this.abortController.abort();
      this.abortController = new AbortController();
      const device = await fetchDevice(
        parseInt(deviceId),
        this.abortController.signal
      );
      this.setState({ device: device });
    } catch (e) {
      console.error("refreshDevice erred");
    }
  }

  async refreshPanelData(
    panelsMap: PanelDataMap,
    timeRange: TimeRange,
    fetchAll: boolean,
    forcePanelRefresh: boolean
  ) {
    if (this.state.dashboardMeta.type === DashboardType.DeviceDashboard) {
      if (!("id" in this.state.filters)) {
        return;
      }
    }

    this.setState({ refreshing: true });

    const panelMetas = Object.values(panelsMap).map((p) => {
      // Assigning ["id"] to groupBys in panelMeta for fleet dashboard to get data for individual device
      if (this.state.dashboardMeta?.type === DashboardType.FleetDashboard) {
        p.meta.groupBys = ["id"];
      }
      return p.meta;
    });

    this.abortControllerFilter.abort();
    this.setState({ refreshing: true });
    this.abortControllerFilter = new AbortController();

    try {
      const panelDatas = await fetchPanelData(
        panelMetas,
        {
          groupBys: this.state.dashboardMeta.allowedGroupBys,
          filterBys: this.state.filters,
          timeRange: timeRange,
          fetchAll,
        },
        this.abortControllerFilter.signal,
        this.state?.dashboardMeta?.type || "device"
      );
      this.setState({ refreshing: false, randomIncrementKey: uuid.v4() });
      const panels: PanelDataMap = this.state.panels;

      if (panelDatas?.length > 0)
        panelDatas.forEach((panel) => {
          let { data, ...meta } = panel;
          // Converting HoneySql format to object to show on UI
          if (meta?.query) {
            meta.query = queryToObject(meta.query);
          }

          const panelDef = getPanelDefForPanelType(panel.type);

          // Inserting panel in state, only if it exists.
          if (panels[panel.id]) {
            panels[panel.id] = {
              meta: meta,
              data: panelDef.formatData(
                data,
                this.state.timeRange,
                this.state.replayState,
                1000
              ),
              panelDef: panelDef,
              isDirty: false,
              loading: false,
            };
          }
        });

      this.setState({
        dataTimeRange: timeRange,
        panels: panels,
        refreshing: false,
        loadingPanels: false,
        panelKeyPrefix: forcePanelRefresh
          ? uuid.v4()
          : this.state.panelKeyPrefix,
        lastRefreshTime: moment(),
        showError: "",
      });
    } catch (error) {
      if (error instanceof Error && !error.message.includes("aborted")) {
        this.setState({
          showError: "Error in refreshing Panels.",
          refreshing: false,
        });
        // Removing Toast here, so that no red toast on network issues.
        console.error("Error occurred in viewDashboard refreshPanelData.");
        console.error(error);
      }
    }
  }

  async incrementalRefreshPanelData(
    panelsMap: PanelDataMap,
    timeRange: TimeRange,
    fetchAll: boolean,
    forcePanelRefresh: boolean,
    randomKey: string
  ) {
    if (this.state.dashboardMeta.type === DashboardType.DeviceDashboard) {
      if (!("id" in this.state.filters)) {
        return;
      }
    }

    this.setState({ refreshing: true });

    //checking if a panel is deleted but has null saved in layout
    const filteredLayout = this.state.gridLayouts[this.state.breakPoint].filter(
      (layout) => {
        if (layout.i !== "null") {
          return layout;
        } else return null;
      }
    );

    // sort panelsMap by panel order (this.state.gridLayouts)
    // so that the panels are refreshed in the appearance order
    const sortedPanelsMap: PanelDataMap = filteredLayout.map((layout) => {
      return panelsMap[layout.i];
    });

    const panelMetas = Object.values(sortedPanelsMap).map((p) => {
      // Assigning ["id"] to groupBys in panelMeta for fleet dashboard to get data for individual device
      if (this.state.dashboardMeta?.type === DashboardType.FleetDashboard) {
        p.meta.groupBys = ["id"];
      }
      return p.meta;
    });

    let panels: PanelDataMap = this.state.panels;

    Object.keys(panels).forEach((panel) => {
      panels[panel].loading = true;
    });

    this.setState({
      panels: panels,
    });

    this.abortControllerFilterIncrement.abort();
    this.abortControllerFilterIncrement = new AbortController();

    this.setState(
      {
        randomIncrementKey: randomKey,
        panelKeyPrefix: forcePanelRefresh
          ? uuid.v4()
          : this.state.panelKeyPrefix,
      },
      async function (this) {
        for (const element of panelMetas) {
          if (randomKey !== this.state.randomIncrementKey) {
            break;
          }

          const panelMeta = element;
          try {
            const panelDatas = await fetchPanelData(
              [panelMeta],
              {
                groupBys: this.state.dashboardMeta.allowedGroupBys,
                filterBys: this.state.filters,
                timeRange: timeRange,
                fetchAll,
              },
              this.abortControllerFilterIncrement.signal,
              this.state?.dashboardMeta?.type || "device"
            );

            if (panelDatas?.length > 0) {
              panelDatas.forEach((panel) => {
                let { data, ...meta } = panel;

                // Converting HoneySql format to object to show on UI
                if (meta?.query) {
                  meta.query = queryToObject(meta.query);
                }

                const panelDef = getPanelDefForPanelType(panel.type);

                // Inserting panel in state, only if it exists.
                if (panels[panel.id]) {
                  panels[panel.id] = {
                    meta: meta,
                    data: panelDef.formatData(
                      data,
                      this.state.timeRange,
                      this.state.replayState,
                      1000
                    ),
                    panelDef: panelDef,
                    isDirty: false,
                    loading: false,
                    error: false,
                  };
                }
              });
            }

            this.setState({
              dataTimeRange: timeRange,
              panels: panels,
              loadingPanels: false,
              showError: "",
            });
          } catch (error) {
            panels[panelMeta.id]["loading"] = false;
            panels[panelMeta.id]["error"] = true;
            this.setState({
              panels: panels, // updating error states
              loadingPanels: false,
            });
            if (!this.abortControllerFilterIncrement.signal.aborted) {
              // Removing Toast here, so that no red toast on network issues.
              console.error(
                "Error occurred in viewDashboard incremental refresh PanelData."
              );
              console.error(error);
            }
          }
        }
        this.setState({
          lastRefreshTime: moment(),
          showError: "",
          refreshing: false,
          loadingPanels: false,
        });
      }
    );
  }

  // This functions is used to append states of panels in url
  async setPanelsStateInURL(urlParams: URLSearchParams, panels: PanelDataMap) {
    const panelsState = Object.entries(panels).reduce(
      (acc, [panelId, panel]) => {
        const panelMeta = panel?.meta;
        if (panelMeta.type === "logs") {
          const { logLevel, tags, searchString } = panelMeta as LogsMetaData;
          acc[panelId] = { logLevel, tags, searchString };
        }
        return acc;
      },
      {} as Record<
        string,
        { logLevel?: string; tags?: string[]; searchString?: string }
      >
    );

    if (Object.keys(panelsState).length > 0) {
      urlParams.set("state", JSON.stringify(panelsState));
    }
  }

  async refreshDashboard() {
    const [p1, p2, p3] = [
      fetchDashboard(this.props.id),
      fetchAllStreamsWithDetails(),
      fetchAllDashboards(),
    ];

    const dashboard = await p1;
    const tableInfo = filterStreamTableInfo(await p2);
    const dashboards = await p3;

    const dashboardsInfo: DashboardsInfo[] = dashboards.map((item) => {
      return {
        id: item.id,
        title: item.config.dashboardMeta.title,
        type: item.config.dashboardMeta.type,
      };
    });
    const urlParams = new URLSearchParams(window.location.search);
    const urlFilters = Object.fromEntries(urlParams);

    let urlTimeRange = urlFilters["timeRange"];
    delete urlFilters["timeRange"];

    let filters: DeviceFilters = dashboard.dashboardMeta.presetFilters || {};
    Object.keys(urlFilters).forEach((key) => {
      filters[key] = urlFilters[key].split(",");
    });

    // Force device dashboard to display only a single device
    if (
      dashboard.dashboardMeta.type === DashboardType.DeviceDashboard &&
      "id" in filters
    ) {
      filters = { id: [filters["id"][0]] };

      await this.refreshDevice(filters["id"][0]);
    }

    let serializedTimeRange =
      dashboard.dashboardMeta.timeRange ||
      new RelativeTimeRange(5, "minutes").serialize();

    if (urlTimeRange) {
      serializedTimeRange = JSON.parse(urlTimeRange) as SerializedTimeRange;
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set("timeRange", JSON.stringify(serializedTimeRange));
      await this.setPanelsStateInURL(urlParams, dashboard?.panels);

      const pageUrl = "?" + urlParams.toString();
      this.props.history.push(pageUrl);
    }

    const timeRange = deserializeTimeRange(serializedTimeRange);

    this.setState({
      ...dashboard,
      timeRange: timeRange,
      refreshInterval: dashboard.dashboardMeta.refreshInterval,
      filters,
      tableInfo: tableInfo,
      loading: false,
      loadingPanels: true,
      dashboards: dashboardsInfo,
    });
    // Aborting the refreshInterval API calls
    // this.abortControllerFilter.abort();
    // this.abortControllerFilterIncrement.abort();
    await this.incrementalRefreshPanelData(
      dashboard.panels,
      timeRange,
      false,
      false,
      uuid.v4()
    );
  }

  async componentDidMount() {
    document.title = "Dashboards | Bytebeam";

    // To scroll to top of the screen
    window.scrollTo(0, 0);

    await this.refreshDashboard();

    const pageTitle = this.state.dashboardMeta?.title
      ? this.state.dashboardMeta?.title + " | Bytebeam"
      : "Dashboard | Bytebeam";
    document.title = pageTitle;

    const refreshFn = async () => {
      if (!this.cancelled) {
        const refreshInterval = this.state.refreshInterval;
        let sleepTime = 1000;
        if (this.state.replayState === ReplayState.ReplayStopped) {
          // This runs by default
          if (refreshInterval > 0) {
            const start = new Date().valueOf();

            await this.refreshPanelData(
              this.state.panels,
              this.state.timeRange,
              false,
              false
            );
            const end = new Date().valueOf();
            sleepTime = Math.max(refreshInterval * 1000 - (end - start), 0);

            if (
              moment().diff(this.state.lastRefreshTime, "seconds") >
              refreshInterval
            ) {
              // Setting if the refresh function has errored or data is stale for any other reason.
              this.setState({
                isRefreshStale: true,
              });
            } else {
              this.setState({
                isRefreshStale: false,
              });
            }
          }
        } else if (this.state.replayState === ReplayState.ReplayRunning) {
          let timeElapsed =
            new Date().valueOf() - this.state.replayStartTimestamp;
          let newTimestamp =
            this.state.timeRange.getStartTime().toDate().valueOf() +
            timeElapsed;
          const endTimestamp = this.state.timeRange
            .getEndTime()
            .toDate()
            .valueOf();

          if (newTimestamp > endTimestamp) {
            newTimestamp = endTimestamp;
          }

          this.setState({
            replayTimestamp: newTimestamp,
          });
        }
        setTimeout(refreshFn, sleepTime);
      }
    };

    await refreshFn();
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
    this.abortController.abort();
    this.abortControllerFilter.abort();
    this.abortControllerFilterIncrement.abort();
    this.cancelled = true;
  }

  onRefreshIntervalChange(value: number) {
    this.setState({
      refreshInterval: value,
    });
  }

  onTimeRangeChange(newRange: TimeRange) {
    this.setState({ timeRange: newRange });

    let serialized = newRange.serialize();
    const urlParams = new URLSearchParams(window.location.search);

    urlParams.set("timeRange", JSON.stringify(serialized));

    const pageUrl = "?" + urlParams.toString();

    this.props.history.push(pageUrl);

    this.incrementalRefreshPanelData(
      this.state.panels,
      newRange,
      false,
      true,
      uuid.v4()
    );
  }

  generateNewLayouts(
    layouts: ReactGridLayout.Layout[],
    maxPanelsPerRow: number,
    meta: PanelMetaData
  ) {
    const lastPanel = layouts.reduce(
      function (prev, current) {
        if (prev["y"] > current["y"]) {
          return prev;
        } else if (prev["y"] < current["y"]) {
          return current;
        } else if (prev["x"] > current["x"]) {
          return prev;
        } else {
          return current;
        }
      },
      { x: -1, y: 0 }
    );

    const lastPanelXEnd = lastPanel["x"] + lastPanel["w"];
    const panelWillFitInRow = lastPanelXEnd + 4 < maxPanelsPerRow * 4;

    const y = panelWillFitInRow ? lastPanel["y"] : lastPanel["y"] + 1;
    const x = panelWillFitInRow ? lastPanelXEnd : 0;

    return [
      ...layouts,
      {
        x: x,
        y: y,
        w: meta.type === "logs" ? 16 : 4,
        h: meta.type === "logs" ? 10 : 5,
        // Adding minimum width of 4 and minimum height of 4
        minW: meta.type === "logs" ? 8 : 4,
        minH: meta.type === "logs" ? 10 : 4,
        i: meta.id,
        isDraggable: true,
      },
    ];
  }

  updatePanelState(
    id: string,
    updates: Partial<PanelData<PanelMetaData, any>>
  ) {
    const panels = Object.assign({}, this.state.panels);

    panels[id] = Object.assign({}, panels[id], updates);

    this.setState({
      panels: panels,
    });
  }

  onRefreshPanel(panelId: string) {
    const panelData = this.state.panels[panelId];
    this.onEditPanelSubmit(panelData.meta);
  }

  onEditPanelSubmit(meta: PanelMetaData) {
    // Aborting the refreshInterval API calls so that edit changes are not replaced
    this.abortControllerFilter.abort();
    this.abortControllerFilterIncrement.abort();
    this.setState({ randomIncrementKey: uuid.v4() });

    this.updatePanelState(
      meta.id,
      Object.assign({}, { meta: meta }, { loading: true })
    );

    this.setState({
      showEditPanelScreen: false,
    });

    setTimeout(async () => {
      try {
        this.setState({ refreshing: true });
        const panelDatas = await fetchPanelData(
          [meta],
          {
            groupBys: this.state.dashboardMeta.allowedGroupBys,
            filterBys: this.state.filters,
            timeRange: this.state.timeRange,
            fetchAll: false,
          },
          null,
          this.state?.dashboardMeta?.type || "device"
        );
        this.setState({ refreshing: false });

        const data = panelDatas[0].data;

        this.updatePanelState(meta.id, {
          loading: false,
          data: data,
          isDirty: true,
          error: false,
        });

        this.setState(
          {
            editPanelData: undefined,
            isDirty: true,
            comparisonVisible: false,
          },
          () => {
            this.refreshPanelData(
              this.state.panels,
              this.state.timeRange,
              false,
              false
            );
          }
        );
      } catch (error) {
        this.setState({ refreshing: false });
        this.updatePanelState(meta.id, {
          loading: false,
          error: true,
        });
        // Removing Toast here, so that no red toast on network issues.
        console.error("Error occurred in viewDashboard onEditPanelSubmit.");
        console.error(error);
      }
    });
  }

  updatePanel(id: string, data: PanelData<PanelMetaData, {}>) {
    const panels = this.state.panels;
    panels[id] = data;
    this.setState({
      panels: panels,
    });
  }

  async onAddPanelSubmit(
    meta: PanelMetaData,
    panelDef: PanelDef<PanelMetaData, {}>
  ) {
    // Assigning ["id"] to groupBys in panelMeta for fleet dashboard to get data for individual device
    if (this.state.dashboardMeta?.type === DashboardType.FleetDashboard) {
      meta.groupBys = ["id"];
    }

    // Aborting the refreshInterval API calls so that edit changes are not replaced
    // this.abortControllerFilter.abort();
    // this.abortControllerFilterIncrement.abort();
    const gridLayouts = {
      lg: this.generateNewLayouts(this.state.gridLayouts.lg, 4, meta),
      xs: this.generateNewLayouts(this.state.gridLayouts.xs, 1, meta),
    };
    this.correctOrdering(gridLayouts);
    this.setState({
      gridLayouts,
      showAddPanelScreen: false,
      isDirty: true,
      loading: false,
    });

    Mixpanel.track("Add Panel", {
      panelID: meta.id,
      panelType: meta.type,
    });

    this.updatePanel(meta.id, {
      meta: meta,
      panelDef: panelDef,
      isDirty: true,
      loading: true,
    });

    try {
      this.setState({ refreshing: true });
      let panelDatas = await fetchPanelData(
        [meta],
        {
          groupBys: this.state.dashboardMeta.allowedGroupBys,
          filterBys: this.state.filters,
          timeRange: this.state.timeRange,
          fetchAll: false,
        },
        null,
        this.state?.dashboardMeta?.type || "device"
      );
      this.setState({ refreshing: false });
      let data = panelDatas[0].data;

      this.updatePanel(meta.id, {
        meta: meta,
        panelDef: panelDef,
        isDirty: true,
        data: data,
        loading: false,
        error: false,
      });
    } catch (error) {
      this.updatePanelState(meta.id, {
        loading: false,
        error: true,
      });
      // Removing Toast here, so that no red toast on network issues.
      console.error("Error occurred in viewDashboard onAddPanelSubmit.");
      console.error(error);
    }
  }

  onGridLayoutChange(
    _layout: ReactGridLayout.Layout[],
    allLayouts: ReactGridLayout.Layouts
  ) {
    const extractKnownKeys = ({ x, y, h, w, i }) => ({ x, y, h, w, i });

    this.correctOrdering(allLayouts);

    // previous layout
    const layouts1 = {
      lg: allLayouts.lg.map(extractKnownKeys),
      xs: allLayouts.xs.map(extractKnownKeys),
    };

    // current layout
    const layouts2 = {
      lg: this.state.gridLayouts.lg.map(extractKnownKeys),
      xs: this.state.gridLayouts.xs.map(extractKnownKeys),
    };

    if (!deepEquals(layouts1, layouts2)) {
      console.log("dirty_Grid_Change"); // for debugging if issue comes, will be removed later
      this.setState({
        gridLayouts: allLayouts as GridLayout,
        isDirty: true,
        panelKeyPrefix: uuid.v4(),
      });
    }
  }

  onBreakpointChange(newBreakpoint: string, newCols: number) {
    // setting breakPoint value in state, when layout changes
    console.log("BrkP_", newBreakpoint);
    this.setState({
      breakPoint: newBreakpoint,
    });
  }

  correctOrdering(layout) {
    // Sorting the 'lg' layout based on (x,y) position
    layout.lg.sort((panelA, panelB) => {
      if (panelA.y === panelB.y) {
        return panelA.x - panelB.x;
      } else {
        return panelA.y - panelB.y;
      }
    });
    // based on the sort order, changing small screen layout
    // creating small screen layout based on large screen layout order and giving height as 5
    // which will reflect any changes done in large screen layout
    if (this.state.breakPoint === "lg") {
      let cumulativeHeight = 0;
      layout.xs = layout.lg.map((item) => {
        const currentY = cumulativeHeight;
        cumulativeHeight += item.h; // Add current item's height to cumulativeHeight for the next iteration

        return {
          x: 0,
          y: currentY,
          h: item.h,
          w: 1,
          i: item.i,
          isDraggable: false,
          isResizable: true,
        };
      });
    } // Why this is not saved.
    // If the breakpoint is small screen, then changing the height of the panels based on the height changes user did in small screen.
    // Copying those changes to large screen layout
    if (this.state.breakPoint === "xs") {
      for (let k = 0; k < layout.lg.length; k++) {
        layout.lg[k]["h"] = layout.xs[k]["h"];
      }
    }
  }

  onEditPanel(panelId: string) {
    const panelData = this.state.panels[panelId];

    if (panelData) {
      this.setState({
        showEditPanelScreen: true,
        editPanelKey: uuid.v4(),
        editPanelData: panelData,
      });
    }
  }

  async onClonePanel(panelId: string) {
    const panelData = _.cloneDeep(this.state.panels[panelId]) as PanelData<
      PanelMetaData,
      {}
    >;
    panelData.meta.id = uuid.v4();
    panelData.meta.title = panelData.meta.title + " (Copy)";
    try {
      await this.onAddPanelSubmit(panelData.meta, panelData.panelDef);
      window.toastr.success(`Panel cloned successfully.`);
    } catch (error) {
      console.error("Error occurred in viewDashboard onClonePanel.");
      console.error(error);
    }
  }

  onFilterSelected(filterName: string, filterValue: string[]) {
    const filters = {
      ...this.state.filters,
    };

    if (filterValue && filterValue.length > 0) {
      filters[filterName] = filterValue;
    } else {
      delete filters[filterName];
    }

    this.setState({ filters, refreshing: true });

    const urlParams = new URLSearchParams(window.location.search);
    const timeRange = urlParams.get("timeRange");

    const newParams = new URLSearchParams(filters as any);

    if (timeRange) {
      newParams.set("timeRange", timeRange);
    }

    this.setPanelsStateInURL(newParams, this.state.panels);

    const pageUrl = "?" + newParams.toString();

    this.props.history.push(pageUrl);

    this.abortControllerFilter.abort();
    this.abortControllerFilterIncrement.abort();

    setTimeout(() => {
      this.incrementalRefreshPanelData(
        this.state.panels,
        this.state.timeRange,
        false,
        false,
        uuid.v4()
      );
    }, 100);
  }

  onDeletePanel(panelId: string) {
    const gridLayouts = {
      lg: this.state.gridLayouts.lg.filter((l) => l["i"] !== panelId),
      xs: this.state.gridLayouts.xs.filter((l) => l["i"] !== panelId),
    };

    const panels = Object.assign({}, this.state.panels);

    delete panels[panelId];

    this.setState({
      gridLayouts,
      panels: panels,
      isDirty: true,
    });
  }

  async onDashboardSave() {
    const panelsMeta = Object.values(this.state.panels).map(
      (panel) => panel.meta
    );

    if (this.state.dashboardMeta.type === DashboardType.DeviceDashboard) {
      // Reset preset filters if its device dashboard
      let dashboardMeta = this.state.dashboardMeta;
      dashboardMeta.presetFilters = {};
      this.setState({ dashboardMeta: dashboardMeta });
    }

    const config = {
      gridLayouts: this.state.gridLayouts,
      dashboardMeta: this.state.dashboardMeta,
      panels: panelsMeta,
    };

    try {
      await editDashboard(this.props.id, { config });
      this.setState({
        loading: true,
        isDirty: false,
      });
      this.refreshDashboard();
    } catch (e) {
      console.log(e);
    }
  }

  onDashboardEdit() {
    this.setState({
      showEditDashboardModal: true,
      editDashboardKey: uuid.v4()
    })
  }

  onEditDashboardSubmit(dashboardMeta: DashboardMeta) {
    this.setState({
      isDirty: true,
      showEditDashboardModal: false,
      dashboardMeta: dashboardMeta,
    });

    setTimeout(() => {
      this.refreshPanelData(
        this.state.panels,
        this.state.timeRange,
        false,
        false
      );
    });
  }

  async onReplayStart() {
    const startTimestamp = new AbsoluteTimestamp(
      this.state.timeRange.getStartTime().toDate()
    );
    const endTimestamp = new AbsoluteTimestamp(
      this.state.timeRange.getEndTime().toDate()
    );
    const newTimeRange = new AbsoluteTimeRange(startTimestamp, endTimestamp);

    this.setState({
      replayState: ReplayState.ReplayFetching,
      replayTimestamp: this.state.timeRange.getStartTime().toDate().valueOf(),
      timeRange: newTimeRange,
      isDirty: true,
      loadingPanels: true,
    });

    await this.refreshPanelData(this.state.panels, newTimeRange, true, false);

    this.setState({
      replayState: ReplayState.ReplayRunning,
      replayStartTimestamp: new Date().valueOf(),
      loadingPanels: false,
    });
  }

  onReplayStop() {
    this.setState({
      replayState: ReplayState.ReplayStopped,
    });
  }

  onReplayPause() {
    this.setState({
      replayState: ReplayState.ReplayPaused,
    });
  }

  onReplayResume() {
    this.setState({
      replayState: ReplayState.ReplayRunning,
    });
  }

  onReplayForward() {
    let newTimestamp = this.state.replayTimestamp + 10 * 1000;
    const endTimestamp = this.state.timeRange.getEndTime().toDate().valueOf();

    if (newTimestamp > endTimestamp) {
      newTimestamp = endTimestamp;
    }

    const replayStartTimestamp =
      this.state.replayStartTimestamp -
      (newTimestamp - this.state.replayTimestamp);

    this.setState({
      replayTimestamp: newTimestamp,
      replayStartTimestamp: replayStartTimestamp,
    });
  }

  onReplayRewind() {
    let newTimestamp = this.state.replayTimestamp - 10 * 1000;
    const startTimestmap = this.state.timeRange
      .getStartTime()
      .toDate()
      .valueOf();

    if (newTimestamp < startTimestmap) {
      newTimestamp = startTimestmap;
    }

    const replayStartTimestamp =
      this.state.replayStartTimestamp -
      (newTimestamp - this.state.replayTimestamp);

    this.setState({
      replayTimestamp: newTimestamp,
      replayStartTimestamp: replayStartTimestamp,
    });
  }

  onReplayTimestampChange(timestamp: number) {
    const replayStartTimestamp =
      this.state.replayStartTimestamp -
      (timestamp - this.state.replayTimestamp);

    this.setState({
      replayTimestamp: timestamp,
      replayStartTimestamp: replayStartTimestamp,
    });
  }

  getReplayStep() {
    if (this.state.replayState === ReplayState.ReplayRunning) {
      const startTimestamp = this.state.timeRange
        .getStartTime()
        .toDate()
        .valueOf();
      return Math.floor((this.state.replayTimestamp - startTimestamp) / 1000);
    }

    return 0;
  }

  onRefresh() {
    this.refreshPanelData(
      this.state.panels,
      this.state.timeRange,
      false,
      true
    );
  }

  onDeviceSearch() {
    if (this.state.dashboardMeta.type === DashboardType.DeviceDashboard) {
      this.onFilterSelected("id", []);
    }
  }

  onDeviceSelected(device: Device) {
    this.setState({
      device: device,
    });

    this.onFilterSelected("id", [device.id.toString()]);
  }

  setHoverPoint(val) {
    this.setState({
      hoverPoint: val,
    });
  }

  getHoverPoint() {
    return this.state.hoverPoint;
  }

  updateWarningModalState(value: boolean) {
    this.setState({ showWarningModal: value });
  }

  renderViewDashboardScreen(fetchParams) {
    let gridLayouts = this.state.gridLayouts;
    gridLayouts["lg"] = gridLayouts["lg"].map((panelLayout) => {
      const panelId = panelLayout["i"],
        panelType = this.state.panels[panelId]?.meta?.type,
        minW = panelType === "logs" ? 8 : 4,
        minH = panelType === "logs" ? 10 : 4;

      const newPanelLayout = {
        ...panelLayout,
        minH,
        minW,
      };
      return newPanelLayout;
    });
    gridLayouts["xs"] = gridLayouts["xs"].map((panelLayout) => {
      const panelId = panelLayout["i"],
        panelType = this.state.panels[panelId]?.meta?.type,
        minW = 1,
        minH = panelType === "logs" ? 6 : 3;

      const newPanelLayout = {
        ...panelLayout,
        minH,
        minW,
      };
      return newPanelLayout;
    });

    return (
      <div style={{ display: "flex", flexDirection: "row", gap: "32px" }}>
        <div style={{ flex: "1" }}>
          <DashboardHeader
            filterKeys={this.state.dashboardMeta.allowedFilterBys}
            selectedFilters={this.state.filters}
            onFilterSelected={this.onFilterSelected.bind(this)}
            dashboardMeta={this.state.dashboardMeta}
            device={this.state.device}
            onTimeRangeChange={this.onTimeRangeChange.bind(this)}
            timeRange={this.state.timeRange}
            isDirty={this.state.isDirty}
            showWarningModal={this.state.showWarningModal}
            updateWarningModal={(value) => this.updateWarningModalState(value)}
            onAddPanel={() => {
              this.setState({
                showAddPanelScreen: true,
                addPanelKey: uuid.v4(),
              });
            }}
            handleCompareClick={() => {
              if (!this.props.beingCompared) {
                const urlTenant = getTenantFromURL();
                window.open(
                  `/projects/${urlTenant}/compare-dashboards/${this.props.id}`
                );
              }
            }}
            comparisonVisible={this.props.beingCompared}
            onDashboardSave={this.onDashboardSave.bind(this)}
            onDashboardEdit={this.onDashboardEdit.bind(this)}
            onRefreshIntervalChange={this.onRefreshIntervalChange.bind(this)}
            currentRefreshInterval={this.state.refreshInterval}
            onRefresh={this.onRefresh.bind(this)}
            refreshing={this.state.refreshing}
            replayEnabled={true}
            replayState={this.state.replayState}
            replayTimestamp={this.state.replayTimestamp}
            onReplayStart={this.onReplayStart.bind(this)}
            onReplayStop={this.onReplayStop.bind(this)}
            onReplayPause={this.onReplayPause.bind(this)}
            onReplayResume={this.onReplayResume.bind(this)}
            onReplayForward={this.onReplayForward.bind(this)}
            onReplayRewind={this.onReplayRewind.bind(this)}
            onDeviceSearch={this.onDeviceSearch.bind(this)}
            editable={canEditDashboard(
              this.state.dashboardMeta,
              this.props.user
            )}
            allDashboards={this.state.dashboards}
            {...this.props}
          />

          {this.state.replayState === ReplayState.ReplayStopped ? (
            <Divider />
          ) : (
            <ReplaySlider
              timeRange={this.state.timeRange}
              currentTimestamp={this.state.replayTimestamp}
              onTimestampChange={this.onReplayTimestampChange.bind(this)}
            />
          )}
          {this.state.loadingPanels ? (
            <LoadingAnimation
              loaderContainerHeight="65vh"
              loadingText="Fetching panel data"
              fontSize="20px"
            />
          ) : (
            <DashboardBody
              onGridLayoutChange={this.onGridLayoutChange.bind(this)}
              onBreakpointChange={this.onBreakpointChange.bind(this)}
              gridLayouts={gridLayouts}
              panels={this.state.panels}
              timeRange={this.state.dataTimeRange}
              onTimeRangeChange={this.onTimeRangeChange.bind(this)}
              onEditPanel={this.onEditPanel.bind(this)}
              onClonePanel={this.onClonePanel.bind(this)}
              onRefreshPanel={this.onRefreshPanel.bind(this)}
              onDeletePanel={this.onDeletePanel.bind(this)}
              replayStep={this.getReplayStep()}
              replayTimestamp={this.state.replayTimestamp}
              replayState={this.state.replayState}
              editable={canEditDashboard(
                this.state.dashboardMeta,
                this.props.user
              )}
              fetchParams={fetchParams}
              panelKeyPrefix={this.state.panelKeyPrefix}
              settings={this.props.settings}
              dashboards={this.state.dashboards}
              setHoverPoint={this.setHoverPoint.bind(this)}
              getHoverPoint={this.getHoverPoint.bind(this)}
              tables={this.state.tableInfo}
            />
          )}
          <EditDashboardModal
            user={this.props.user}
            isOpen={this.state.showEditDashboardModal}
            key={this.state.editDashboardKey}
            dashboardMeta={this.state.dashboardMeta}
            dashboardJSON={{
              gridLayouts: gridLayouts,
              dashboardMeta: this.state.dashboardMeta,
              panels: Object.values(this.state.panels).map(
                (panel) => panel.meta
              ),
            }}
            dashboardId={this.props.id}
            onCancel={() => {
              this.setState({ showEditDashboardModal: false });
            }}
            onSubmit={this.onEditDashboardSubmit.bind(this)}
            refreshFunction={this.refreshDashboard.bind(this)}
            isAdmin={isUserAdmin(this.props.user)}
            allDashboards={this.state.dashboards}
          />
        </div>
      </div>
    );
  }

  renderAddPanelScreen(fetchParams) {
    const escapeFunc = this.escFunction.bind(this);
    document.addEventListener("keydown", escapeFunc, true);

    return (
      <AddPanelModal
        fetchParams={fetchParams}
        key={this.state.addPanelKey}
        onCancel={() => {
          this.setState({ showAddPanelScreen: false });
        }}
        onSubmit={this.onAddPanelSubmit.bind(this)}
        tables={this.state.tableInfo}
        settings={this.props.settings}
        dashboards={this.state.dashboards}
        dashboardMeta={this.state.dashboardMeta}
        hideCancelButton={false}
      />
    );
  }

  renderAddPanelInDashboardScreen(fetchParams) {
    return (
      <>
        <DashboardHeader
          filterKeys={this.state.dashboardMeta.allowedFilterBys}
          selectedFilters={this.state.filters}
          onFilterSelected={this.onFilterSelected.bind(this)}
          dashboardMeta={this.state.dashboardMeta}
          device={this.state.device}
          onTimeRangeChange={this.onTimeRangeChange.bind(this)}
          timeRange={this.state.timeRange}
          isDirty={this.state.isDirty}
          showWarningModal={this.state.showWarningModal}
          updateWarningModal={(value) => this.updateWarningModalState(value)}
          onAddPanel={() => {
            this.setState({ showAddPanelScreen: true, addPanelKey: uuid.v4() });
          }}
          onDashboardSave={this.onDashboardSave.bind(this)}
          onDashboardEdit={this.onDashboardEdit.bind(this)}
          onRefreshIntervalChange={this.onRefreshIntervalChange.bind(this)}
          currentRefreshInterval={this.state.refreshInterval}
          onRefresh={this.onRefresh.bind(this)}
          refreshing={this.state.refreshing}
          replayEnabled={true}
          replayState={this.state.replayState}
          replayTimestamp={this.state.replayTimestamp}
          onReplayStart={this.onReplayStart.bind(this)}
          onReplayStop={this.onReplayStop.bind(this)}
          onReplayPause={this.onReplayPause.bind(this)}
          onReplayResume={this.onReplayResume.bind(this)}
          onReplayForward={this.onReplayForward.bind(this)}
          onReplayRewind={this.onReplayRewind.bind(this)}
          onDeviceSearch={this.onDeviceSearch.bind(this)}
          editable={canEditDashboard(this.state.dashboardMeta, this.props.user)}
          allDashboards={this.state.dashboards}
          {...this.props}
        />
        <ThinDivider />
        <AddPanelModal
          fetchParams={fetchParams}
          key={this.state.addPanelKey}
          onCancel={() => {
            this.setState({ showAddPanelScreen: false });
          }}
          onSubmit={this.onAddPanelSubmit.bind(this)}
          tables={this.state.tableInfo}
          settings={this.props.settings}
          dashboards={this.state.dashboards}
          dashboardMeta={this.state.dashboardMeta}
          hideCancelButton={true}
        />
        <EditDashboardModal
          user={this.props.user}
          isOpen={this.state.showEditDashboardModal}
          key={this.state.editDashboardKey}
          dashboardMeta={this.state.dashboardMeta}
          dashboardJSON={{
            gridLayouts: this.state.gridLayouts,
            dashboardMeta: this.state.dashboardMeta,
            panels: Object.values(this.state.panels).map((panel) => panel.meta),
          }}
          dashboardId={this.props.id}
          onCancel={() => {
            this.setState({ showEditDashboardModal: false });
          }}
          onSubmit={this.onEditDashboardSubmit.bind(this)}
          refreshFunction={this.refreshDashboard.bind(this)}
          isAdmin={isUserAdmin(this.props.user)}
          allDashboards={this.state.dashboards}
        />
      </>
    );
  }

  escFunction(event) {
    if (event.key === "Escape") {
      if (this.state.showEditPanelScreen) {
        this.setState({ showEditPanelScreen: false });
      } else if (this.state.showAddPanelScreen) {
        this.setState({ showAddPanelScreen: false });
      }
    }
  }

  renderEditPanelScreen(fetchParams) {
    if (this.state.editPanelData) {
      this.escFunction = this.escFunction.bind(this);
      document.addEventListener("keydown", this.escFunction, false);

      return (
        <EditPanelModal
          key={this.state.editPanelKey}
          panelMeta={this.state.editPanelData.meta}
          panelDef={this.state.editPanelData.panelDef}
          fetchParams={fetchParams}
          onCancel={() => {
            this.setState({ showEditPanelScreen: false });
          }}
          onSubmit={this.onEditPanelSubmit.bind(this)}
          tables={this.state.tableInfo}
          settings={this.props.settings}
          dashboards={this.state.dashboards}
          dashboardMeta={this.state.dashboardMeta}
        />
      );
    } else {
      return "";
    }
  }

  generateView(fetchParams) {
    if (
      this.state.showEditPanelScreen &&
      this.state.editPanelData &&
      !this.state.loadingPanels
    ) {
      return this.renderEditPanelScreen(fetchParams);
    } else if (this.state.showAddPanelScreen && !this.state.loadingPanels) {
      return this.renderAddPanelScreen(fetchParams);
    } else if (
      this.state.panels &&
      Object.keys(this.state.panels).length === 0
    ) {
      return this.renderAddPanelInDashboardScreen(fetchParams);
    } else {
      return this.renderViewDashboardScreen(fetchParams);
    }
  }

  render() {
    let showStatusBar = true;
    if (this.state.showEditPanelScreen || this.state.showAddPanelScreen) {
      showStatusBar = false;
    }

    if (this.state.dashboardMeta.type === DashboardType.DeviceDashboard) {
      if (!("id" in this.state.filters)) {
        return (
          <DeviceSearch
            title={this.state.dashboardMeta.title}
            allowedFilterBys={this.state.dashboardMeta.allowedFilterBys}
            metadataKeysToShow={this.state.dashboardMeta.showMetadataKeys}
            onDeviceSelected={this.onDeviceSelected.bind(this)}
            updateWarningModal={(value) => this.updateWarningModalState(value)}
            showWarningModal={this.state.showWarningModal}
            isDirty={this.state.isDirty}
            onDashboardSave={() => this.onDashboardSave}
          />
        );
      }
    }

    if (this.state.loading) {
      return (
        <LoadingAnimation
          loaderContainerHeight="calc(100vh - 130px)"
          loadingText="Assembling your graphs"
          fontSize="20px"
        />
      );
    }

    const fetchParams = {
      groupBys: this.state.dashboardMeta.allowedGroupBys,
      filterBys: this.state.filters,
      timeRange: this.state.timeRange,
      fetchAll: false,
    };

    return (
      <div className="bytebeam-dashboard">
        {this.generateView(fetchParams)}

        <div>
          {this.state.showError &&
          this.state.showError.length > 0 &&
          showStatusBar ? (
            <div className="last-refresh-time-error">
              <Icon
                name="exclamation triangle"
                style={{
                  marginRight: "7px",
                }}
              />
              {this.state.showError}
            </div>
          ) : this.state.lastRefreshTime && showStatusBar ? (
            <div
              className={
                this.state.isRefreshStale
                  ? "last-refresh-time-stale"
                  : "last-refresh-time"
              }
            >
              {this.state.refreshing ? (
                <Icon
                  name="refresh"
                  style={{
                    marginRight: "7px",
                  }}
                  loading={this.state.refreshing}
                />
              ) : (
                <Icon
                  name="info"
                  style={{
                    marginRight: "7px",
                  }}
                />
              )}
              Last refreshed:{" "}
              {moment(this.state.lastRefreshTime).format("HH:mm:ss")}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(ViewDashboard);
