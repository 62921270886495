import { HeatmapMetaData } from "./PanelDef";

import { PartialMetaData } from "../PanelDef";
import { EditPieChartMeta } from "../PieChart/EditPieChartMeta";

export class EditHeatmapMeta extends EditPieChartMeta {
  getPanelMeta(type): PartialMetaData<HeatmapMetaData> {
    let { meta, complete } = super.getPanelMeta(type);

    return {
      meta: { ...meta, type: "heatmap" },
      complete: complete,
    };
  }
}
