import uuid from "uuid";
import { PanelDef } from "../PanelDef";
import { ViewMicelioStatsPanel } from "./ViewMicelioStatsPanel";
import { EditMicelioStatsPanelMeta } from "./EditMicelioStatsPanelMeta";

export class MicelioStatsPanelMetaData {
  type = "micelio_stats_panel";
  title: string = "";
  description: string = "";
  id: string = uuid.v4();
  suffix: string = "";
  rowsPerPage: number = 20;
  subType: string = "";
  deviceDashboardId: string = "";
}


export type MicelioStatsPanelDevice = {
  id: number;
  metadata: {[key: string]: string};
  value: number;
}

export type MicelioStatsPanelDeviceData = {
    maxDevice: MicelioStatsPanelDevice;
    maxDeviceValue: number;
    minDevice: MicelioStatsPanelDevice;
    minDeviceValue: number;
    numDevices: number;
    devices: MicelioStatsPanelDevice[]
}


export type MicelioStatsPanelHourlyData = {
  hourlyData: {
    hour: number;
    frequency: number;
    value: string;
  }[]
}

export type MicelioStatsPanelByVehicleData = {
  vehicleList: {
    id: number;
    metadata: {[key: string]: string};
    frequency: number;
    value: string;
  }[];
}

export type MicelioStatsPanelData = {
  value: number;
  changePercentage: number;
  details: MicelioStatsPanelDeviceData | MicelioStatsPanelHourlyData | MicelioStatsPanelByVehicleData
}

export const MicelioStatsPanelDef: PanelDef<
  MicelioStatsPanelMetaData,
  MicelioStatsPanelData> = {
  ViewComponent: ViewMicelioStatsPanel,
  EditComponent: EditMicelioStatsPanelMeta,
  formatData: (data) => data,
  previewAspectRatio: { width: 500, height: 300 },
  metaConstructor: MicelioStatsPanelMetaData
}
