import React from "react";
import { capitalizeFirstLetter } from "../../util";
import { Progress, Table } from "semantic-ui-react";
import { LightMutedText } from "./Device";
import TextWithToolTip from "./TextWithToolTip";
import moment from "moment";
import { DeviceStyledTable } from "../../Actions/DeviceList";

export type LastActionType = {
  action_id: string;
  type: string;
  status: string;
  user_name: string;
  created_at: string;
  progress: number;
  errors: string;
};

type LastActionInfoTableProps = {
  lastAction: LastActionType;
  deviceId: number;
  specificAction?: boolean;
};

export function styleActionProgress(action_status, action_progress) {
  let className: string = "in-progress",
    progress: number = action_progress;

  if (action_status === "Failed") {
    progress =
      action_progress !== 100 || action_progress !== 0 ? action_progress : 100;
    className = "failed";
  } else if (action_status === "Completed") {
    progress = 100;
    className = "completed";
  } else if (action_status === "Initiated") {
    className = "initiated";
    progress = 0;
  } else if (action_status === "Queued") {
    className = "queued";
    progress = 0;
  } else if (action_status === "Scheduled") {
    className = "scheduled";
    progress = 0;
  }

  return {
    progress,
    className,
  };
}

function LastActionInfoTable(props: LastActionInfoTableProps) {
  const { lastAction, deviceId, specificAction } = props;

  let lastActionId: string = String(lastAction?.action_id ?? "--"),
    lastActionType: string = capitalizeFirstLetter(lastAction?.type || "--"),
    lastActionStatus: string = lastAction?.status || "--",
    lastActionProgress: number = styleActionProgress(
      lastActionStatus,
      lastAction?.progress === undefined ? 0 : lastAction?.progress
    )["progress"],
    lastActionTriggeredBy: string = lastAction?.user_name ?? "--",
    lastActionTriggeredAt: string = lastAction?.created_at,
    lastActionErrors: string = "",
    lastActionClassName: string = styleActionProgress(
      lastActionStatus,
      lastAction?.progress === undefined ? 0 : lastAction?.progress
    )["className"];

  // When Actions is mark as completed lastAction.errors is "[]" else if no error it holds undefined or null value
  if (typeof lastAction?.errors === `string` && lastAction?.errors.length > 2) {
    // removing first two and last two characters from string since lastAction.errors is stringified array
    lastActionErrors = lastAction?.errors
      .substring(2, lastAction?.errors.length - 2)
      .trim();
  }

  return (
    <DeviceStyledTable compact fixed id={`${deviceId}-last_action`}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={{ padding: "9px 9.8px" }} colSpan={2}>
            {specificAction ? `Action ID: ${lastActionId}` : "Last Action"}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      {lastActionType !== "--" ? (
        <Table.Body>
          <Table.Row>
            <Table.Cell colSpan={2}>
              <div style={{ margin: "1rem 0" }}>
                <Progress
                  progress
                  indicating={
                    lastActionStatus === "Completed" ||
                    lastActionStatus === "Failed"
                      ? false
                      : true
                  }
                  size="medium"
                  percent={lastActionProgress}
                  className={lastActionClassName}
                  style={{ width: "100%", marginBottom: "0px" }}
                />
              </div>
            </Table.Cell>
          </Table.Row>
          {lastActionErrors !== "" && (
            <Table.Row>
              <Table.Cell>
                <LightMutedText>Errors</LightMutedText>
              </Table.Cell>
              <Table.Cell>
                <TextWithToolTip text={lastActionErrors} length={10} />
              </Table.Cell>
            </Table.Row>
          )}
          <Table.Row>
            <Table.Cell>
              <LightMutedText>Action ID</LightMutedText>
            </Table.Cell>
            <Table.Cell>
              <TextWithToolTip text={lastActionId} length={10} />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              <LightMutedText>Action Type</LightMutedText>
            </Table.Cell>
            <Table.Cell>
              <TextWithToolTip text={lastActionType} length={10} />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              <LightMutedText>Action Status</LightMutedText>
            </Table.Cell>
            <Table.Cell>
              <TextWithToolTip
                text={capitalizeFirstLetter(lastActionStatus)}
                length={10}
              />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              <LightMutedText>Triggered By</LightMutedText>
            </Table.Cell>
            <Table.Cell>
              <TextWithToolTip text={lastActionTriggeredBy} length={10} />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              <LightMutedText>Triggered At</LightMutedText>
            </Table.Cell>
            <Table.Cell>
              <TextWithToolTip
                text={capitalizeFirstLetter(
                  moment
                    .duration(-1 * moment().diff(moment(lastActionTriggeredAt)))
                    .humanize(true)
                )}
                length={10}
                textList={[
                  `${new Date(lastActionTriggeredAt).toLocaleString("en-GB")}`,
                ]}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      ) : (
        <Table.Row>
          <Table.Cell>No Last Action</Table.Cell>
        </Table.Row>
      )}
    </DeviceStyledTable>
  );
}

export default LastActionInfoTable;
