import React from "react";
import { PanelViewComponent } from "../PanelDef";
import { Button, Divider, Icon, Modal, Table } from "semantic-ui-react";

import styled from "styled-components"

import { fetchMicelioStatsPanelDetails, FetchParams, getTenantFromURL } from "../../../../../BytebeamClient"

import { 
  MicelioStatsPanelData,
  MicelioStatsPanelMetaData,
  MicelioStatsPanelHourlyData,
  MicelioStatsPanelDeviceData,
  MicelioStatsPanelByVehicleData
} from "./PanelDef";

import loadingGif from '../../loading.gif'

const StatsPanelContainer = styled.div`
  height: 100%;
  width: 100%;
`

const StatsTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const StatsText = styled.div`
  font-size: 40px;
  height: 40px;
  padding-top: 10px;
`

const StatsChangeText = styled.div`
  padding-left: 5px;
  padding-bottom: 5px;
  padding-top: 10px;
  vertical-align: bottom;
`

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 35px;
`

const AdvancedPanelList = styled.div`
  max-height: 50vh;
  overflow: auto;
  margin-top: 10px;
  margin-bottom: 10px;

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, .25);
  }
`

type DeviceDashboardLinkProps = {
  panelMeta: MicelioStatsPanelMetaData;
  device: {
    id: number;
    metadata: {[key: string]: string};
  };
}

function DeviceDashboardLink(props: DeviceDashboardLinkProps) {
  const deviceDashboardId = props.panelMeta.deviceDashboardId;

  if(!props.device.metadata) {
    return <></>
  }

  if(!deviceDashboardId) {
    return <>{props.device.metadata["registration_number"]}</>
  }

  const currentTenant = getTenantFromURL();
  const url = `/projects/${currentTenant}/dashboards/${deviceDashboardId}?id=${props.device.id}`;

  return <a target="_blank" rel="noopener noreferrer" href={url}>{props.device.metadata["registration_number"]}</a>
}

type VehicleListModal1Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  panelMeta: MicelioStatsPanelMetaData;
  fetchParams: FetchParams;
  data: MicelioStatsPanelData;
}

type VehicleListModal1State = {
  loading: boolean;
  details: MicelioStatsPanelDeviceData;
}


class VehicleListModal1 extends React.Component<VehicleListModal1Props, VehicleListModal1State> {

  constructor(props){
    super(props);

    this.state = {
      loading: true,

      details: {
        maxDevice: {
          id: 0,
          metadata: {},
          value: 0,
        },
        maxDeviceValue: 0,
        minDevice: {
          id: 0,
          metadata: {},
          value: 0,
        },
        minDeviceValue: 0,
        numDevices: 0,
        devices: []
      }
    }
  }

  async loadDetails() {
    if(this.state.loading) {
      const data = await fetchMicelioStatsPanelDetails(this.props.panelMeta, this.props.fetchParams)

      this.setState({
        loading: false,
        details: data.details
      })
    }
  }

  renderContent() {
    const panelMeta = this.props.panelMeta;
    const details = this.state.details;
    const devices = details ? (details.devices || []) : [];

    if (this.state.loading) {
      return <div className="dashboard-loading-gif">
        <img src={loadingGif} alt="" />
        <p> Fetching Details ... </p>
      </div>
    }

    return <>
      <div style={{padding: "5px"}}>
        {
          details.maxDevice ?
          <> <span>Vehicle with MAX({panelMeta.title}) - </span>
            <DeviceDashboardLink device={details.maxDevice} panelMeta={panelMeta} />
            <span>- {details.maxDeviceValue}{panelMeta.suffix} </span>
          </>
          :
          <></>
        }
      </div>
      <div style={{padding: "5px"}}>
        {
          details.minDevice ?
          <> <span>Vehicle with MIN({panelMeta.title}) - </span>
            <DeviceDashboardLink device={details.minDevice} panelMeta={panelMeta} />
            <span>- {details.minDeviceValue}{panelMeta.suffix} </span>
          </>
          :
          <></>
        }
      </div>
      <Divider />
      Vehicle List
      <AdvancedPanelList>
        <Table celled>
          <Table.Body>
            {
              devices.map((device, i) => {
                return <Table.Row key={`${i}-${device.metadata["registration_number"]}`}>  
                  <Table.Cell verticalAlign='top' width={1}>
                    <div style={{padding: "5px"}}>
                      <DeviceDashboardLink
                        device={device}
                        panelMeta={panelMeta}
                      />
                    </div>
                    <div style={{padding: "5px"}}>{device.metadata["city"]} | {device.metadata["hub"]} | {device.metadata["client"]} </div>
                  </Table.Cell>
                  <Table.Cell width={1}>
                    {device.value}{panelMeta.suffix}
                  </Table.Cell>
                </Table.Row>
                })
            }
          </Table.Body>
        </Table>
      </AdvancedPanelList>
    </>
  }

  render() {
    const { changePercentage } = this.props.data;
    const color = changePercentage > 0 ? "green" : "red";
    const icon = changePercentage > 0 ? "arrow up" : "arrow down";
    
    return <Modal
      open={this.props.isOpen}
      size="small"
      className="dark"
      closeOnEscape
      closeOnDocumentClick
      onClose={() => this.props.setIsOpen(false)}
      onOpen={() => this.props.setIsOpen(true)}
      trigger={
        <Button onClick={() => this.loadDetails()}size="mini">
          Advanced
        </Button>
      }>

      <Modal.Header>
        <div style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between"}}>
          <div>
            {this.props.panelMeta.title}
          </div>
          <div style={{color: color}}>{
            Math.abs(changePercentage) > 0.01 ?
              <><Icon name={icon} style={{margin: 0}} color={color}/>{changePercentage.toFixed(2)}%</>
            :
              <></>
          }
          </div>
        </div>
      </Modal.Header>
      <Modal.Content>
        {this.renderContent()}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => this.props.setIsOpen(false)}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  }
}

type HourListModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  panelMeta: MicelioStatsPanelMetaData;
  fetchParams: FetchParams;
  data: MicelioStatsPanelData;
}

type HourListModalState = {
  loading: boolean;
  details: MicelioStatsPanelHourlyData;
}

class HourListModal extends React.Component<HourListModalProps, HourListModalState> {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,

      details: {
        hourlyData: []
      } 
    }
  }

  renderContent() {
    const hours = this.state.details.hourlyData || [];

    if (this.state.loading) {
      return <div className="dashboard-loading-gif">
        <img src={loadingGif} alt="" />
        <p> Fetching Details ... </p>
      </div>
    }

    return <>
      Hour List
      <AdvancedPanelList>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Hour</Table.HeaderCell>
              <Table.HeaderCell>Frequency</Table.HeaderCell>
              <Table.HeaderCell>Value</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              hours.map((hour) => {
                return <Table.Row key={`${hour}`}>   
                  <Table.Cell width={1}>
                    {hour.hour}
                  </Table.Cell>
                  <Table.Cell width={1}>
                    {hour.frequency}
                  </Table.Cell>
                  <Table.Cell width={1}>
                    {hour.value}
                  </Table.Cell>
                </Table.Row>
                })
            }
          </Table.Body>
        </Table>
      </AdvancedPanelList>
    </>
  }

  async loadDetails() {
    if(this.state.loading) {
      const data = await fetchMicelioStatsPanelDetails(this.props.panelMeta, this.props.fetchParams)

      this.setState({
        loading: false,
        details: data.details
      })
    }
  }

  render() {
    return <Modal
      open={this.props.isOpen}
      size="small"
      className="dark"
      closeOnEscape
      closeOnDocumentClick
      onClose={() => this.props.setIsOpen(false)}
      onOpen={() => this.props.setIsOpen(true)}
      trigger={
        <Button onClick={() => this.loadDetails()}size="mini">
          Advanced
        </Button>
      }>

      <Modal.Header>
        <div style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between"}}>
          <div>
            {this.props.panelMeta.title}
          </div>
        </div>
      </Modal.Header>
      <Modal.Content>
        {this.renderContent()}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => this.props.setIsOpen(false)}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  }
}

type VehicleListModal2Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  panelMeta: MicelioStatsPanelMetaData;
  fetchParams: FetchParams;
  data: MicelioStatsPanelData;
}

type VehicleListModal2State = {
  loading: boolean;
  details: MicelioStatsPanelByVehicleData;
}

class VehicleListModal2 extends React.Component<VehicleListModal2Props, VehicleListModal2State> {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,

      details: {
        vehicleList: []
      }
    }
  }

  renderContent() {
    const vehicles = this.state.details.vehicleList || [];

    if (this.state.loading) {
      return <div className="dashboard-loading-gif">
        <img src={loadingGif} alt="" />
        <p> Fetching Details ... </p>
      </div>
    }

    return <>
    Vehicle List
    <AdvancedPanelList>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Vehicle</Table.HeaderCell>
            <Table.HeaderCell>Frequency</Table.HeaderCell>
            <Table.HeaderCell>Value</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            vehicles.map((vehicle) => {
              return <Table.Row key={vehicle.id}>   
                <Table.Cell width={1}>
                  <DeviceDashboardLink
                    device={vehicle}
                    panelMeta={this.props.panelMeta}
                  />
                </Table.Cell>
                <Table.Cell width={1}>
                  {vehicle.frequency}
                </Table.Cell>
                <Table.Cell width={1}>
                  {vehicle.value}
                </Table.Cell>
              </Table.Row>
              })
          }
        </Table.Body>
      </Table>
    </AdvancedPanelList>
    </>
  }

  async loadDetails() {
    if(this.state.loading) {
      const data = await fetchMicelioStatsPanelDetails(this.props.panelMeta, this.props.fetchParams)

      this.setState({
        loading: false,
        details: data.details 
      })
    }
  }

  render() {
    return <Modal
      open={this.props.isOpen}
      size="small"
      className="dark"
      closeOnEscape
      closeOnDocumentClick
      onClose={() => this.props.setIsOpen(false)}
      onOpen={() => this.props.setIsOpen(true)}
      trigger={
        <Button onClick={() => this.loadDetails()}size="mini">
          Advanced
        </Button>
      }>

      <Modal.Header>
        <div style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between"}}>
          <div>
            {this.props.panelMeta.title}
          </div>
        </div>
      </Modal.Header>
      <Modal.Content>
        {this.renderContent()}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => this.props.setIsOpen(false)}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  }
}

const subTypeToModalMapping = {
  total_runtime: VehicleListModal1,
  total_idle_time: VehicleListModal1,
  total_pause_time: VehicleListModal1,
  total_distance: VehicleListModal1,
  daily_average_distance: VehicleListModal1,
  monthly_average_distance: VehicleListModal1,
  bussiest_hour_kms: HourListModal,
  bussiest_hour_mins: HourListModal,
  bussiest_hour_vehicles: HourListModal,
  most_idle_hour_mins: HourListModal,
  most_idle_hour_kms: HourListModal,
  most_idle_hour_vehicles: HourListModal,
  bussiest_vehicle_kms: VehicleListModal2,
  bussiest_vehicle_mins: VehicleListModal2,
  most_idle_vehicle_kms: VehicleListModal2,
  most_idle_vehicle_mins: VehicleListModal2,
  max_pause_interval: VehicleListModal1
}

export class ViewMicelioStatsPanel extends PanelViewComponent<MicelioStatsPanelMetaData, MicelioStatsPanelData>  {
  ref = React.createRef<HTMLDivElement>();

  state = {
    isOpen: false,
    devices: null,
    fontSize: 10
  }

  canvas = document.createElement("canvas");

  getTextWidth(text, font) {
    var context = this.canvas.getContext("2d");
    if (context) {
      context.font = font;

      var metrics = context.measureText(text);
      return metrics.width;
    }

    return 0;
  }

  calculateFontSize(fontFamily, maxWidth, maxHeight) {
    let text = this.getText()

    if(text.length < "ABCDEFGHIJ".length) {
      text = "ABCDEFGHIJ"
    }

    let numIterations = 0;
    let start = 10;
    let end = Math.max(10, Math.min(200, maxHeight));

    while (true) {
      const fontSize = (start + end) / 2;
      const font = `${fontSize}px ${fontFamily}`

      numIterations += 1;

      if (numIterations > 20) {
        return fontSize;
      }

      const textWidth = this.getTextWidth(text, font);
      if (Math.abs(textWidth - maxWidth) < 2 || Math.abs(end - start) < 2) {
        return fontSize;
      }

      if (textWidth < maxWidth) {
        start = fontSize;
      } else {
        end = fontSize;
      }
    }
  }

  componentDidMount() {
    if (this.ref.current) {
      const rect = this.ref.current.getBoundingClientRect();
      const fontFamily = window.getComputedStyle(this.ref.current).fontFamily;

      const fontSize = this.calculateFontSize(fontFamily, rect.width * 0.9, rect.height * 0.45);

      this.setState({
        fontSize: 0.8*fontSize
      })
    }
  }


  getText() {
    const {value} = this.props.data;
    const panelMeta = this.props.panelMeta;

    if(value) {
      return `${value} ${panelMeta.suffix}`
    } else {
      return ""
    }
  }

  renderModal() {
    const subType = this.props.panelMeta.subType;

    if(subType in subTypeToModalMapping) {
      const Modal = subTypeToModalMapping[subType];

      return <ButtonContainer>
        <Modal
          isOpen={this.state.isOpen}
          setIsOpen={(isOpen) => this.setState({isOpen: isOpen})}
          panelMeta={this.props.panelMeta}
          fetchParams={this.props.fetchParams}
          data={this.props.data}
        />
      </ButtonContainer>
    }

    return <></>
  }

  render() {
    const { changePercentage } = this.props.data;
    const color = changePercentage > 0 ? "green" : "red";
    const icon = changePercentage > 0 ? "arrow up" : "arrow down";

    return <StatsPanelContainer ref={this.ref}>
      <StatsTextContainer> 
        <StatsText style={{ fontSize: `${this.state.fontSize}px` }}>
          {this.getText()}
        </StatsText>
        <StatsChangeText style={{color: color}}>
          {
            Math.abs(changePercentage) > 0.01 ?
              <><Icon name={icon} style={{margin: 0}} color={color}/>{changePercentage.toFixed(2)}%</>
            :
              <></>
          }
          
        </StatsChangeText>
      </StatsTextContainer>
      {this.renderModal()}
    </StatsPanelContainer>
  }
}
