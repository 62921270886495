import { PivotTableMetaData } from "./PanelDef";
import React from "react";

import { Label, Divider } from "semantic-ui-react";
import { PanelEditComponent, PartialMetaData } from "../PanelDef";
import {
  EditMetaRoot,
  EditMetaRow,
  EditMetaInput,
  EditMetaDropdown,
} from "../util";
import { TableInfo } from "../../../../../BytebeamClient";

export type EditPivotTableMetaProps = {
  panelMeta: PivotTableMetaData;
  tables: TableInfo;
};

export type EditPivotTableMetaState = {
  table: string;
  rowLabel: string;
  columnLabel: string;
  aggregationColumn: string;
  aggregator: string;
};

const aggregators = ["min", "max", "avg", "sum", "count"];

export class EditPivotTableMeta extends PanelEditComponent<
  PivotTableMetaData,
  EditPivotTableMetaState
> {
  titleRef = React.createRef<HTMLInputElement>();
  descriptionRef = React.createRef<HTMLInputElement>();

  constructor(props: EditPivotTableMetaProps) {
    super(props);

    if (props.panelMeta) {
      this.state = {
        table: props.panelMeta.table,
        rowLabel: props.panelMeta.rowLabel,
        columnLabel: props.panelMeta.columnLabel,
        aggregationColumn: props.panelMeta.aggregationColumn,
        aggregator: props.panelMeta.aggregator,
      };
    } else {
      this.state = {
        table: "",
        rowLabel: "",
        columnLabel: "",
        aggregationColumn: "",
        aggregator: "",
      };
    }
  }

  getPanelMeta(type): PartialMetaData<PivotTableMetaData> {
    const meta: PivotTableMetaData = {
      type: "pivot_table",
      id: this.props.panelMeta.id,
      title: this.titleRef.current?.value || "",
      description: this.descriptionRef.current?.value || "",
      table: this.state.table || "",
      rowLabel: this.state.rowLabel || "",
      columnLabel: this.state.columnLabel || "",
      aggregationColumn: this.state.aggregationColumn || "",
      aggregator: this.state.aggregationColumn || "",
    };

    return {
      meta: meta,
      complete: this.isValidPanelMeta(meta, type),
    };
  }

  isValidPanelMeta(meta: PivotTableMetaData, type?: string) {
    return (
      !!meta.table &&
      !!meta.rowLabel &&
      !!meta.columnLabel &&
      !!meta.aggregationColumn &&
      !!meta.aggregator
    );
  }

  setTable(_event, data) {
    this.setState({
      table: data.value,
    });
  }

  setRowLabel(_event, data) {
    this.setState({
      rowLabel: data.value,
    });
  }

  setColumnLabel(_event, data) {
    this.setState({
      columnLabel: data.value,
    });
  }

  setAggregationColumn(_event, data) {
    this.setState({
      aggregationColumn: data.value,
    });
  }

  setAggregator(_event, data) {
    this.setState({
      aggregator: data.value,
    });
  }

  render() {
    const title = this.props.panelMeta.title;
    const description = this.props.panelMeta.description;

    const tableOptions = Object.keys(this.props.tables).map((t) => {
      return {
        key: t,
        text: t,
        value: t,
      };
    });

    let columnTypeOptions: Array<{ key: string; value: string; text: string }> =
      [];

    if (!!this.state.table) {
      columnTypeOptions = this.props.tables[this.state.table].map(
        (f: { name: string; type: string }) => {
          return {
            key: f.name,
            text: f.name,
            value: f.name,
          };
        }
      );
    }

    const aggregateOptions = aggregators.map((a) => {
      return {
        key: a,
        text: a,
        value: a,
      };
    });

    return (
      <EditMetaRoot>
        <EditMetaRow>
          <EditMetaInput>
            <Label>Title</Label>
            <input ref={this.titleRef} defaultValue={title} />
          </EditMetaInput>
        </EditMetaRow>

        <EditMetaRow>
          <EditMetaInput>
            <Label>Info</Label>
            <input ref={this.descriptionRef} defaultValue={description} />
          </EditMetaInput>
        </EditMetaRow>

        <Divider />

        <EditMetaRow>
          <EditMetaDropdown
            placeholder="Select Stream"
            text={this.state.table}
            search
            selection
            options={tableOptions}
            onChange={this.setTable.bind(this)}
            defaultValue={this.props.panelMeta.table}
          />
        </EditMetaRow>

        <Divider />

        <EditMetaRow>
          <EditMetaDropdown
            placeholder="Row Label"
            text={this.state.rowLabel}
            search
            selection
            disabled={this.state.table === ""}
            options={columnTypeOptions}
            onChange={this.setRowLabel.bind(this)}
            defaultValue={this.props.panelMeta.rowLabel}
          />

          <EditMetaDropdown
            placeholder="Field Label"
            text={this.state.columnLabel}
            search
            selection
            disabled={this.state.table === ""}
            options={columnTypeOptions}
            onChange={this.setColumnLabel.bind(this)}
            defaultValue={this.props.panelMeta.columnLabel}
          />
        </EditMetaRow>

        <Divider />

        <EditMetaRow>
          <EditMetaDropdown
            placeholder="Aggregated Value"
            text={this.state.aggregationColumn}
            search
            selection
            disabled={this.state.table === ""}
            options={columnTypeOptions}
            onChange={this.setAggregationColumn.bind(this)}
            defaultValue={this.props.panelMeta.aggregationColumn}
          />

          <EditMetaDropdown
            placeholder="Aggregator"
            text={this.state.aggregator}
            search
            selection
            disabled={
              this.state.rowLabel === "" ||
              this.state.columnLabel === "" ||
              this.state.aggregationColumn === ""
            }
            options={aggregateOptions}
            onChange={this.setAggregator.bind(this)}
            defaultValue={this.props.panelMeta.aggregator}
          />
        </EditMetaRow>
      </EditMetaRoot>
    );
  }
}
