import React, { useEffect, useState } from "react";
import { Button, Modal, Icon } from "semantic-ui-react";
import SqlWhereClauseBuilder from "../../../common/SqlWhereClauseBuilder";
import {
  CompositeCondition,
  CompositeConditionOperator,
  Condition,
  SimpleCondition,
  SimpleConditionOperator,
} from "../../../../../util";
import { EditAnimatedMetaDropdown } from "../util";
import { StreamFieldDetails } from "../../../../../BytebeamClient";
import { ClearFilterSpanWrapper } from "../LastValue/EditLastValueMeta";
import styled from "styled-components";
import { breakpoints } from "../../../../common/breakpoints";

type QueryObject = {
  column: any;
  operator: SimpleConditionOperator | CompositeConditionOperator | "no_data";
  value: any;
};

type AdvancedSettingsProps = {
  readonly fields: { [key: string]: StreamFieldDetails };
  readonly groupByFields: {
    key: string;
    text: string;
    value: string;
    type: string;
  }[];
  readonly filterCondition: Condition;
  readonly setFilterCondition: (condition: any, operator) => any;
  readonly setGroupByField: (condition: any) => any;
  readonly isOpen: boolean;
  readonly groupByCondition: string[] | undefined;
  readonly dashboardType: string;
  readonly close: (...args: any[]) => any;
};

const ModalDescriptionDropDownDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  margin-top: 20px;

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
  }
`;

export function AdvancedSettingsModal(props: AdvancedSettingsProps) {
  const {
    isOpen,
    close,
    filterCondition,
    setFilterCondition,
    setGroupByField,
    groupByCondition,
    fields,
    groupByFields,
    dashboardType,
  } = props;

  const limitGroupByUpTo = 3;

  const [filter, setFilter] = useState<Condition>(filterCondition);
  const [filteredGroupByFields, setFilteredGroupByFields] =
    useState<any>(filterCondition);
  const [groupBy, setGroupBy] = useState<string[] | undefined>(
    groupByCondition
  );

  // useEffect(() => {
  //   const isValidCondition = (condition: Condition) => {
  //     const compositeOperators = ["and", "or"];

  //     if (compositeOperators.includes(condition.operator)) {
  //       const conditions = (condition as CompositeCondition).conditions;
  //       return conditions.every(isValidCondition);
  //     } else {
  //       const simpleCondition = condition as SimpleCondition;
  //       return (
  //         simpleCondition.field.length > 0 && simpleCondition.value.length > 0
  //       );
  //     }
  //   };
  // }, [filterCondition]);

  useEffect(() => {
    // filter groupByFields so that it only includes type: "string" fields
    const filteredGroupByFields = groupByFields.filter((field) => {
      return field.type === "String" || field.type === "Nullable(String)";
    });
    setFilteredGroupByFields(filteredGroupByFields);
  }, [groupByFields]);

  const setCondition = (condition: Condition | null) => {
    if (condition === null) {
      return;
    }
    const compositeOperators = ["and", "or"];
    const noDataOperator = "no_data";
    if (condition.operator === noDataOperator) {
      return "No Data";
    } else if (compositeOperators.includes(condition.operator)) {
      const conditions = (condition as CompositeCondition).conditions;
      const operator = condition.operator;
      let queryObj: QueryObject[] = [];
      for (let i = 0; i < conditions.length; i++) {
        let condition = conditions[i] as SimpleCondition;
        queryObj.push({
          column: condition.field,
          operator: condition.operator,
          value: condition.value,
        });
      }
      setFilterCondition(queryObj, operator);
    } else {
      const c = condition as SimpleCondition;
      const queryObj = [
        { column: c.field, operator: c.operator, value: c.value },
      ];
      setFilterCondition(queryObj, null);
    }
  };

  // eslint-disable-next-line
  const isValidCondition = (condition: Condition) => {
    const compositeOperators = ["and", "or"];
    if (compositeOperators.includes(condition.operator)) {
      const conditions = (condition as CompositeCondition).conditions;
      return conditions.every(isValidCondition);
    } else {
      const simpleCondition = condition as SimpleCondition;
      return (
        simpleCondition.field.length > 0 && simpleCondition.value.length > 0
      );
    }
  };

  const setGroupByState = (_event, data) => {
    let groupByValue = data.value;
    if (groupByValue.length > limitGroupByUpTo)
      window.toastr.error("Group by is allowed up to 3 fields");
    else setGroupBy(groupByValue);
  };

  return (
    <Modal open={isOpen} size="small" className="dark" onClose={close}>
      <Modal.Header>Advanced Settings</Modal.Header>
      <Modal.Content>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3>Add Filter Conditions</h3>
          <ClearFilterSpanWrapper
            onClick={() => {
              setFilter({
                conditions: [{ field: "", operator: "=", value: "" }],
                operator: "and",
              });
            }}
          >
            Clear Filter
          </ClearFilterSpanWrapper>
        </div>
        <Modal.Description>
          <SqlWhereClauseBuilder
            key={JSON.stringify(filter)}
            fields={fields}
            condition={filter}
            onChange={setFilter}
            showGroupOptions={false}
          />
        </Modal.Description>
        <h3>Add Group By Field</h3>

        {dashboardType === "fleet" && (
          <span style={{ color: "grey" }}>
            Note: Fleet Dashboards are already grouped by device ID
          </span>
        )}

        <Modal.Description>
          <ModalDescriptionDropDownDiv>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <EditAnimatedMetaDropdown
                placeholder="GroupBy Field"
                search
                selection
                multiple
                defaultValue={(groupBy?.length ?? 0) > 0 ? groupBy : []}
                value={(groupBy?.length ?? 0) > 0 ? groupBy : []}
                options={filteredGroupByFields}
                onChange={setGroupByState}
                disabled={dashboardType === "fleet"}
              />
            </div>
          </ModalDescriptionDropDownDiv>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={() => close()}>
          <Icon name="remove" /> Cancel
        </Button>
        <Button
          primary
          onClick={() => {
            setGroupByField(groupBy);
            setCondition(filter);
            close();
          }}
        >
          <Icon name="check circle outline" /> Confirm
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
