import React, { useCallback, useEffect } from "react";
import { Divider } from "semantic-ui-react";
import { EditAnimatedMetaDropdown } from "../../Dashboards/Panel/util";
import { ActionDropdownType } from "../../../../BytebeamClient";
import { ActionType, User } from "../../../../util";
import { capitalizeFirstLetter } from "../../util";
import { OptionType } from "../util";
import SelectOrUploadFirmware from "./SelectOrUploadFirmware";
import SelectOrUploadConfig from "./SelectOrUploadConfig";
import UploadConfig from "./UploadConfig";
import SendFile from "./SendFile";
import UploadText from "./UploadText";
import { DropdownOptionType } from "../../DeviceMangaement/Devices/ActionModals/UpdateConfigModal";
import LoadingAnimation from "../../../common/Loader";
import SendScript from "./SendScript";
import SelectOrUploadGeofenceConfig from "./SelectOrUploadGeofenceConfig";
import { LatLng } from "../Inventory/DeviceConfigurations/GeofenceEditor";
import ThemeSchema from "../../../../theme/schema";
import { useUser } from "../../../../context/User.context";

type UploadPayloadModalProps = {
  user: User;
  devicesCount: number;
  setDisableNextButton: (arg0: boolean) => void;
  uploadTriggered: string;
  setUploadTriggered: (arg0: string) => void;
  uploadTriggeredEach: boolean;
  setActiveModal: (arg0: number) => void;
  activeModal: number;
  setOptionType: (arg0: OptionType) => void;
  optionType: OptionType;
  action: string;
  setAction: (arg0: string) => void;
  payloadType: string;
  setPayloadType: (arg0: string) => void;
  getActionTypes: () => void;
  loading: boolean;
  actionTypes: ActionType[];
  actionDropdownList: ActionDropdownType[];
  selectedAction: string;
  firmwareOptions: DropdownOptionType[];
  allFirmwareOptions: Set<any> | undefined;
  selectedFirmwareVersion: DropdownOptionType;
  setSelectedFirmwareVersion: (arg0: DropdownOptionType) => void;
  selectedConfigVersion: DropdownOptionType;
  setSelectedConfigVersion: (arg0: DropdownOptionType) => void;
  selectedGeofenceVersion: DropdownOptionType;
  setSelectedGeofenceVersion: (arg0: DropdownOptionType) => void;
  selectedCustomFirmwareVersion: string;
  setSelectedCustomFirmwareVersion: (arg0: string) => void;
  selectedCustomConfigVersion: string;
  setSelectedCustomConfigVersion: (arg0: string) => void;
  selectedCustomGeofenceVersion: string;
  setSelectedCustomGeofenceVersion: (arg0: string) => void;
  setSelectedAction: (arg0: string) => void;
  customInputJSONData: string;
  setCustomInputJSONData: (arg0: string) => void;
  customInputTextData: string;
  setCustomInputTextData: (arg0: string) => void;
  customFirmwareInputData: string;
  setCustomFirmwareInputData: (arg0: string) => void;
  customFileInputData: string;
  setCustomFileInputData: (arg0: string) => void;
  customScriptInputData: string;
  setCustomScriptInputData: (arg0: string) => void;
  configActiveIndex: number;
  setConfigActiveIndex: (arg0: number) => void;
  geofenceActiveIndex: number;
  setGeofenceActiveIndex: (arg0: number) => void;
  firmwareActiveIndex: number;
  setFirmwareActiveIndex: (arg0: number) => void;
  configJSONOptions: DropdownOptionType[];
  configGeofenceOptions: DropdownOptionType[];
  allConfigOptions: Set<any> | undefined;
  fillDropdownOptions: () => void;
  fillFirmwareDropdownOptions: () => void;
  firmwareFile: File;
  setFirmwareFile: (arg0: File) => void;
  firmwareFileName: string;
  setFirmwareFileName: (arg0: string) => void;
  showFirmwareUploadProgress: boolean;
  setShowFirmwareUploadProgress: (arg0: boolean) => void;
  firmwareFileLoaded: number;
  setFirmwareFileLoaded: (arg0: number) => void;
  firmwareFileTotal: number;
  setFirmwareFileTotal: (arg0: number) => void;
  uploadedFirmwareFileResponse: {
    status: number;
    data: { id: string };
  };
  setUploadedFirmwareFileResponse: (arg0: {
    status: number;
    data: { id: string };
  }) => void;
  firmwareFileInput: any;
  file: File;
  setFile: (arg0: File) => void;
  fileName: string;
  setFileName: (arg0: string) => void;
  showUploadProgress: boolean;
  setShowUploadProgress: (arg0: boolean) => void;
  fileLoaded: number;
  setFileLoaded: (arg0: number) => void;
  fileTotal: number;
  setFileTotal: (arg0: number) => void;
  uploadedFileResponse: {
    status: number;
    data: { id: string };
  };
  setUploadedFileResponse: (arg0: {
    status: number;
    data: { id: string };
  }) => void;
  script: File;
  setScript: (arg0: File) => void;
  scriptName: string;
  setScriptName: (arg0: string) => void;
  showScriptUploadProgress: boolean;
  setShowScriptUploadProgress: (arg0: boolean) => void;
  scriptLoaded: number;
  setScriptLoaded: (arg0: number) => void;
  scriptTotal: number;
  setScriptTotal: (arg0: number) => void;
  uploadedScriptResponse: {
    status: number;
    data: { id: string };
  };
  setUploadedScriptResponse: (arg0: {
    status: number;
    data: { id: string };
  }) => void;
  fileInput: any;
  scriptInput: any;
  firmwareOptionsLoaded: boolean;
  path: LatLng[];
  setPath: (arg0: LatLng[]) => void;
  isPolygonComplete: boolean;
  setIsPolygonComplete: (arg0: boolean) => void;
  alertThreshold: number;
  setAlertThreshold: (arg0: number) => void;
  latitude: number;
  setLatitude: (arg0: number) => void;
  longitude: number;
  setLongitude: (arg0: number) => void;
};

function UploadPayloadModal(props: UploadPayloadModalProps) {
  const setActionType = useCallback(
    (_event, data) => {
      props.setSelectedAction(data.value);
      let action_type: string = "";
      let payload_type: string = "";
      props.actionTypes?.forEach((action) => {
        if (data.value === action.type) {
          action_type = action.type;
          payload_type = action.payload_type;
        }
      });
      props.setPayloadType(payload_type);
      props.setAction(action_type);
      if (
        payload_type === "none" ||
        action_type === "send_file" ||
        action_type === "send_script"
      ) {
        // because right now payloadType of send_file action is none but technically it should be file therefore futureproofed if type is changed so that it doesnt misbehave
        if (action_type === "send_file")
          props.setOptionType(OptionType.SendFile);
        else if (action_type === "send_script")
          props.setOptionType(OptionType.SendScript);
        else props.setOptionType(OptionType.NoPayloadOption);
        props.setDisableNextButton(false);
      } else if (payload_type === "json" && action_type !== "update_config") {
        props.setOptionType(OptionType.UploadCommonConfig);
        props.setDisableNextButton(false);
      } else if (payload_type === "text") {
        props.setOptionType(OptionType.UploadText);
      } else if (
        action_type === "update_firmware" ||
        action_type === "update_config"
      ) {
        props.setOptionType(OptionType.NoOptionSelected);
        props.setDisableNextButton(true);
      }
    },
    [props.actionTypes] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const { user } = useUser();
  const theme = user?.settings?.theme;

  function returnActionValue() {
    let selectedDropdownOption: string = "";
    props.actionDropdownList.forEach((action) => {
      if (action.value === props.selectedAction)
        selectedDropdownOption = action.value;
    });
    return selectedDropdownOption;
  }

  useEffect(() => {
    if (props.selectedAction) props.setDisableNextButton(false);
    else props.setDisableNextButton(true);
  }, [props.selectedAction]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    props.getActionTypes();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.uploadTriggered === "trigger") {
      switch (props.optionType) {
        case OptionType.NoPayloadOption:
          // functionality for action chosen which has no payload therefore no validation just go on the verfication page to trigger the action
          props.setActiveModal(props.activeModal + 1);
          break;
      }
    }
  }, [props.uploadTriggered, props.uploadTriggeredEach]); // eslint-disable-line react-hooks/exhaustive-deps

  return props.loading ? (
    <LoadingAnimation
      loaderContainerHeight="350px"
      loadingText="Loading action list"
      fontSize="20px"
    />
  ) : (
    <div>
      <div
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ width: "100%" }}>
            <p
              style={{
                fontSize: "1.3rem",
                marginTop: "2rem",
                marginBottom: "2rem",
                fontWeight: "bold",
              }}
            >
              Select Action
            </p>
            <EditAnimatedMetaDropdown
              placeholder="Action"
              search
              selection
              defaultValue={returnActionValue()}
              options={props.actionDropdownList}
              onChange={setActionType}
              elementid={`checkcolumn1`}
              disabled={props.actionDropdownList.length === 0}
            />
          </div>
        </div>
        {props.action && props.action !== "" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "self-end",
              width: "50%",
            }}
          >
            <div
              style={{
                border: `1px solid ${
                  ThemeSchema.data[theme ?? "dark"]?.colors[
                    "table-outer-border"
                  ]
                }`,
                padding: "1rem",
              }}
            >
              <p
                style={{
                  fontSize: "1.1rem",
                }}
              >
                Trigger action: <b>{capitalizeFirstLetter(props.action)}</b>
              </p>
              <p
                style={{
                  fontSize: "1.1rem",
                }}
              >
                Payload type: <b>{props.payloadType}</b>
              </p>
              <p
                style={{
                  fontSize: "1.1rem",
                }}
              >
                Triggering action on:{" "}
                <b>
                  {props.devicesCount}{" "}
                  {props.devicesCount === 1 ? "device" : "devices"}
                </b>
              </p>
            </div>
          </div>
        )}
      </div>
      {props.payloadType === "file" && props.action === "update_firmware" && (
        <>
          <Divider />
          <div style={{ margin: "0 6rem" }}>
            <SelectOrUploadFirmware
              setDisableNextButton={(value) =>
                props.setDisableNextButton(value)
              }
              setOptionType={(option) => props.setOptionType(option)}
              optionType={props.optionType}
              uploadTriggered={props.uploadTriggered}
              setUploadTriggered={(value) => props.setUploadTriggered(value)}
              uploadTriggeredEach={props.uploadTriggeredEach}
              setActiveModal={(index) => props.setActiveModal(index)}
              activeModal={props.activeModal}
              customFirmwareInputData={props.customFirmwareInputData}
              setCustomFirmwareInputData={(version) =>
                props.setCustomFirmwareInputData(version)
              }
              selectedCustomFirmwareVersion={
                props.selectedCustomFirmwareVersion
              }
              setSelectedCustomFirmwareVersion={(version) =>
                props.setSelectedCustomFirmwareVersion(version)
              }
              firmwareActiveIndex={props.firmwareActiveIndex}
              setFirmwareActiveIndex={(index) =>
                props.setFirmwareActiveIndex(index)
              }
              selectedFirmwareVersion={props.selectedFirmwareVersion}
              setSelectedFirmwareVersion={(version) =>
                props.setSelectedFirmwareVersion(version)
              }
              firmwareOptions={props.firmwareOptions}
              fillFirmwareDropdownOptions={() =>
                props.fillFirmwareDropdownOptions()
              }
              allFirmwareOptions={props.allFirmwareOptions}
              firmwareFile={props.firmwareFile}
              setFirmwareFile={(file) => props.setFirmwareFile(file)}
              firmwareFileName={props.firmwareFileName}
              setFirmwareFileName={(name) => props.setFirmwareFileName(name)}
              showFirmwareUploadProgress={props.showFirmwareUploadProgress}
              setShowFirmwareUploadProgress={(progress) =>
                props.setShowFirmwareUploadProgress(progress)
              }
              firmwareFileLoaded={props.firmwareFileLoaded}
              setFirmwareFileLoaded={(value) =>
                props.setFirmwareFileLoaded(value)
              }
              firmwareFileTotal={props.firmwareFileTotal}
              setFirmwareFileTotal={(value) =>
                props.setFirmwareFileTotal(value)
              }
              uploadedFirmwareFileResponse={props.uploadedFirmwareFileResponse}
              setUploadedFirmwareFileResponse={(res) =>
                props.setUploadedFirmwareFileResponse(res)
              }
              firmwareOptionsLoaded={props.firmwareOptionsLoaded}
              firmwareFileInput={props.firmwareFileInput}
            />
          </div>
        </>
      )}
      {(props.payloadType === "file" || props.payloadType === "none") && // because right now payloadType of send_file action is none but technically it should be file therefore futureproofed if type is changed so that it doesnt misbehave
        props.action === "send_file" && (
          <>
            <Divider />
            <div style={{ margin: "0 6rem" }}>
              <SendFile
                setOptionType={(option) => props.setOptionType(option)}
                setDisableNextButton={(value) =>
                  props.setDisableNextButton(value)
                }
                optionType={props.optionType}
                uploadTriggered={props.uploadTriggered}
                setUploadTriggered={(value) => props.setUploadTriggered(value)}
                uploadTriggeredEach={props.uploadTriggeredEach}
                setActiveModal={(index) => props.setActiveModal(index)}
                activeModal={props.activeModal}
                customFileInputData={props.customFileInputData}
                setCustomFileInputData={(version) =>
                  props.setCustomFileInputData(version)
                }
                file={props.file}
                setFile={(file) => props.setFile(file)}
                fileName={props.fileName}
                setFileName={(name) => props.setFileName(name)}
                showUploadProgress={props.showUploadProgress}
                setShowUploadProgress={(progress) =>
                  props.setShowUploadProgress(progress)
                }
                fileLoaded={props.fileLoaded}
                setFileLoaded={(value) => props.setFileLoaded(value)}
                fileTotal={props.fileTotal}
                setFileTotal={(value) => props.setFileTotal(value)}
                uploadedFileResponse={props.uploadedFileResponse}
                setUploadedFileResponse={(res) =>
                  props.setUploadedFileResponse(res)
                }
                script={props.script}
                setScript={(script) => props.setScript(script)}
                scriptName={props.scriptName}
                setScriptName={(name) => props.setScriptName(name)}
                showScriptUploadProgress={props.showScriptUploadProgress}
                setShowScriptUploadProgress={(progress) =>
                  props.setShowScriptUploadProgress(progress)
                }
                scriptLoaded={props.scriptLoaded}
                setScriptLoaded={(value) => props.setScriptLoaded(value)}
                scriptTotal={props.scriptTotal}
                setScriptTotal={(value) => props.setScriptTotal(value)}
                uploadedScriptResponse={props.uploadedScriptResponse}
                setUploadedScriptResponse={(res) =>
                  props.setUploadedScriptResponse(res)
                }
                fileInput={props.fileInput}
                action={props.action}
              />
            </div>
          </>
        )}
      {(props.payloadType === "file" || props.payloadType === "none") && // because right now payloadType of send_file action is none but technically it should be file therefore futureproofed if type is changed so that it doesnt misbehave
        props.action === "send_script" && (
          <>
            <Divider />
            <div style={{ margin: "0 6rem" }}>
              <SendScript
                setOptionType={(option) => props.setOptionType(option)}
                setDisableNextButton={(value) =>
                  props.setDisableNextButton(value)
                }
                optionType={props.optionType}
                uploadTriggered={props.uploadTriggered}
                setUploadTriggered={(value) => props.setUploadTriggered(value)}
                uploadTriggeredEach={props.uploadTriggeredEach}
                setActiveModal={(index) => props.setActiveModal(index)}
                activeModal={props.activeModal}
                customScriptInputData={props.customScriptInputData}
                setCustomScriptInputData={(data) =>
                  props.setCustomScriptInputData(data)
                }
                script={props.script}
                setScript={(script) => props.setScript(script)}
                scriptName={props.scriptName}
                setScriptName={(name) => props.setScriptName(name)}
                showScriptUploadProgress={props.showScriptUploadProgress}
                setShowScriptUploadProgress={(progress) =>
                  props.setShowScriptUploadProgress(progress)
                }
                scriptLoaded={props.scriptLoaded}
                setScriptLoaded={(value) => props.setScriptLoaded(value)}
                scriptTotal={props.scriptTotal}
                setScriptTotal={(value) => props.setScriptTotal(value)}
                uploadedScriptResponse={props.uploadedScriptResponse}
                setUploadedScriptResponse={(res) =>
                  props.setUploadedScriptResponse(res)
                }
                scriptInput={props.scriptInput}
                action={props.action}
              />
            </div>
          </>
        )}
      {props.payloadType === "json" && props.action === "update_config" && (
        <>
          <Divider />
          <div style={{ margin: "0 6rem" }}>
            <SelectOrUploadConfig
              setDisableNextButton={(value) =>
                props.setDisableNextButton(value)
              }
              setOptionType={(option) => props.setOptionType(option)}
              optionType={props.optionType}
              uploadTriggered={props.uploadTriggered}
              setUploadTriggered={(value) => props.setUploadTriggered(value)}
              uploadTriggeredEach={props.uploadTriggeredEach}
              setActiveModal={(index) => props.setActiveModal(index)}
              activeModal={props.activeModal}
              customInputJSONData={props.customInputJSONData}
              setCustomInputJSONData={(data) =>
                props.setCustomInputJSONData(data)
              }
              configActiveIndex={props.configActiveIndex}
              setConfigActiveIndex={(index) =>
                props.setConfigActiveIndex(index)
              }
              selectedConfigVersion={props.selectedConfigVersion}
              setSelectedConfigVersion={(version) =>
                props.setSelectedConfigVersion(version)
              }
              selectedCustomConfigVersion={props.selectedCustomConfigVersion}
              setSelectedCustomConfigVersion={(version) =>
                props.setSelectedCustomConfigVersion(version)
              }
              configJSONOptions={props.configJSONOptions}
              allConfigOptions={props.allConfigOptions}
              fillDropdownOptions={() => props.fillDropdownOptions()}
              theme={props.user?.settings?.theme ?? "dark"}
            />
          </div>
        </>
      )}
      {props.payloadType === "json" && props.action === "update_geofence" && (
        <>
          <Divider />
          <div style={{ margin: "0 6rem" }}>
            <SelectOrUploadGeofenceConfig
              setDisableNextButton={(value) =>
                props.setDisableNextButton(value)
              }
              setOptionType={(option) => props.setOptionType(option)}
              optionType={props.optionType}
              uploadTriggered={props.uploadTriggered}
              setUploadTriggered={(value) => props.setUploadTriggered(value)}
              uploadTriggeredEach={props.uploadTriggeredEach}
              setActiveModal={(index) => props.setActiveModal(index)}
              activeModal={props.activeModal}
              geofenceActiveIndex={props.geofenceActiveIndex}
              setGeofenceActiveIndex={(index) =>
                props.setGeofenceActiveIndex(index)
              }
              selectedGeofenceVersion={props.selectedGeofenceVersion}
              setSelectedGeofenceVersion={(version) =>
                props.setSelectedGeofenceVersion(version)
              }
              selectedCustomGeofenceVersion={
                props.selectedCustomGeofenceVersion
              }
              setSelectedCustomGeofenceVersion={(version) =>
                props.setSelectedCustomGeofenceVersion(version)
              }
              configGeofenceOptions={props.configGeofenceOptions}
              allConfigOptions={props.allConfigOptions}
              fillDropdownOptions={() => props.fillDropdownOptions()}
              path={props.path}
              setPath={(path) => props.setPath(path)}
              isPolygonComplete={props.isPolygonComplete}
              setIsPolygonComplete={(value) =>
                props.setIsPolygonComplete(value)
              }
              alertThreshold={props.alertThreshold}
              setAlertThreshold={(threshold) =>
                props.setAlertThreshold(threshold)
              }
              theme={props.user?.settings?.theme ?? "dark"}
              latitude={props.latitude}
              setLatitude={(latitude) => props.setLatitude(latitude)}
              longitude={props.longitude}
              setLongitude={(longitude) => props.setLongitude(longitude)}
            />
          </div>
        </>
      )}
      {props.payloadType === "json" &&
        props.action !== "update_config" &&
        props.action !== "update_geofence" && (
          <>
            <Divider />
            <div style={{ margin: "0 6rem" }}>
              <UploadConfig
                setDisableNextButton={(value) =>
                  props.setDisableNextButton(value)
                }
                setOptionType={(option) => props.setOptionType(option)}
                optionType={props.optionType}
                uploadTriggered={props.uploadTriggered}
                setUploadTriggered={(value) => props.setUploadTriggered(value)}
                uploadTriggeredEach={props.uploadTriggeredEach}
                setActiveModal={(index) => props.setActiveModal(index)}
                activeModal={props.activeModal}
                customInputJSONData={props.customInputJSONData}
                setCustomInputJSONData={(JSON) =>
                  props.setCustomInputJSONData(JSON)
                }
                theme={props.user?.settings?.theme ?? "dark"}
              />
            </div>
          </>
        )}
      {props.payloadType === "text" && (
        <>
          <Divider />
          <div style={{ margin: "0 6rem" }}>
            <UploadText
              setDisableNextButton={(value) =>
                props.setDisableNextButton(value)
              }
              setOptionType={(option) => props.setOptionType(option)}
              optionType={props.optionType}
              uploadTriggered={props.uploadTriggered}
              setUploadTriggered={(value) => props.setUploadTriggered(value)}
              uploadTriggeredEach={props.uploadTriggeredEach}
              setActiveModal={(index) => props.setActiveModal(index)}
              activeModal={props.activeModal}
              customInputTextData={props.customInputTextData}
              setCustomInputTextData={(text) =>
                props.setCustomInputTextData(text)
              }
            />
          </div>
        </>
      )}
    </div>
  );
}

export default UploadPayloadModal;
