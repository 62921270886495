import {
  LedPanelData,
  LedPanelMetaData,
  offStateDefaultValues,
  onStateDefaultValues,
} from "./PanelDef";
import React from "react";
import { PanelViewComponent } from "../PanelDef";
import { TableContainer } from "../util";
import styled from "styled-components";

const StyledHeaderDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${({ theme }) =>
    theme.colors["table-header-background-color"]};
  padding: 1px 5px;
  border-radius: 5px 5px 0px 0px;
`;

interface LedProps {
  size: number;
  color: string;
}

const LedBulb = (props: LedProps) => (
  <svg height={props.size} width={props.size}>
    <circle
      cx={props.size / 2}
      cy={props.size / 2}
      r={props.size / 2 - 1}
      stroke="black"
      strokeWidth="2"
      fill={props.color}
    />
  </svg>
);

const determineLedColor = (
  value,
  panelMeta,
  booleanCheckTruthyArray,
  booleanCheckFalsyArray
) => {
  if (typeof value === "number" || typeof value === "boolean") {
    return value ? panelMeta.onStateColor : panelMeta.offStateColor;
  }

  const stringValue = (
    typeof value === "string" ? value : String(value)
  ).toLowerCase();

  if (
    booleanCheckTruthyArray.some((text) => text.toLowerCase() === stringValue)
  ) {
    return panelMeta.onStateColor;
  } else if (
    booleanCheckFalsyArray.some((text) => text.toLowerCase() === stringValue)
  ) {
    return panelMeta.offStateColor;
  }
  return "grey";
};

class LedArray extends PanelViewComponent<LedPanelMetaData, LedPanelData> {
  render() {
    let data = this.props.data.data;
    let size = 30;
    if (this.props.panelMeta?.ledSize) {
      size = this.props.panelMeta.ledSize;
    }
    const onStateTextList = this.props.panelMeta?.onStateTextList ?? [];
    const offStateTextList = this.props.panelMeta?.offStateTextList ?? [];

    let booleanCheckTruthyArray: string[] = [
      ...onStateDefaultValues,
      ...onStateTextList,
    ];

    let booleanCheckFalsyArray: string[] = [
      ...offStateDefaultValues,
      ...offStateTextList,
    ];

    return (
      <TableContainer>
        <div className="tableContentContainer">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-around",
              overflow: "auto",
            }}
          >
            {data.map((item) => {
              let value = item.value;
              let columnName = item.column ? item.column : "-";
              let ledColor: string = determineLedColor(
                value,
                this.props.panelMeta,
                booleanCheckTruthyArray,
                booleanCheckFalsyArray
              );

              return (
                <div
                  key={columnName}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    margin: "40px 10px 10px 10px",
                  }}
                >
                  <div>
                    <LedBulb size={size} color={ledColor} />
                  </div>
                  {data.length > 1 && <div>{columnName}</div>}
                </div>
              );
            })}
          </div>
        </div>
      </TableContainer>
    );
  }
}
type InputData = Record<string, string | number>;

type OutputData = {
  id: number; // Assuming id is always present and is numeric or string that can be converted to number
  columns: string[];
  value: (string | number)[];
};
function transformData(inputArray: InputData[]): OutputData[] {
  return inputArray.map((item) => {
    const { id, ...rest } = item; // Destructuring to separate 'id' and the rest of the properties

    // Creating 'columns' array from the keys of 'rest' and 'value' array from the values of 'rest'
    const columns = Object.keys(rest);
    const values = Object.values(rest);

    return {
      id: typeof id === "string" ? parseInt(id) : id, // Convert 'id' to number if it's a string
      columns,
      value: values,
    };
  });
}

class MultiDeviceLedArray extends PanelViewComponent<
  LedPanelMetaData,
  LedPanelData
> {
  render() {
    let data = this.props.data["all-data"];
    data = transformData(data);
    let size = 30;
    if (this.props.panelMeta?.ledSize) {
      size = this.props.panelMeta.ledSize;
    }
    const onStateTextList = this.props.panelMeta?.onStateTextList ?? [];
    const offStateTextList = this.props.panelMeta?.offStateTextList ?? [];

    let booleanCheckTruthyArray: string[] = [
      ...onStateDefaultValues,
      ...onStateTextList,
    ];

    let booleanCheckFalsyArray: string[] = [
      ...offStateDefaultValues,
      ...offStateTextList,
    ];

    return (
      <TableContainer>
        <div className="tableContentContainer">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {data.map((item) => {
              return (
                <div
                  key={item.id}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    margin: "40px 10px 10px 10px",
                    border: "0.1px solid #6e6f75",
                    position: "relative",
                    borderRadius: "5px",
                  }}
                >
                  <StyledHeaderDiv>Device: {item.id}</StyledHeaderDiv>
                  {item.value.map((value, index) => {
                    let ledColor: string = determineLedColor(
                      value,
                      this.props.panelMeta,
                      booleanCheckTruthyArray,
                      booleanCheckFalsyArray
                    );

                    return (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "40px 20px 20px 20px",
                        }}
                      >
                        <div style={{ margin: "0px 5px" }}>
                          <LedBulb size={size} color={ledColor} />
                        </div>
                        <div style={{ margin: "0px 5px" }}>
                          {item.columns[index]}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </TableContainer>
    );
  }
}

export class ViewLedPanel extends PanelViewComponent<
  LedPanelMetaData,
  LedPanelData
> {
  render() {
    const { data } = this.props;
    if (data["all-data"][0]?.id && data["all-data"].length > 0) {
      data["all-data"] = data["all-data"].sort((a, b) => {
        return parseInt(a.id, 10) - parseInt(b.id, 10);
      });
      return <MultiDeviceLedArray {...this.props} />;
    } else if (
      data.data.length > 0 &&
      data.data[0] &&
      data.data[0]["value"] !== undefined
    ) {
      return <LedArray {...this.props} />;
    } else {
      return <div className="panel-no-data">No Data</div>;
    }
  }
}
