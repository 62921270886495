import React, { useRef } from "react";
import moment from "moment";
import { Device, SearchDeviceResponse } from "../../../../BytebeamClient";
import { Checkbox, Table } from "semantic-ui-react";
import { capitalizeFirstLetter } from "../../util";
import { ErrorMessage } from "../../../common/ErrorMessage";

type ActionModalSearchResultsProps = {
  devices: SearchDeviceResponse;
  checkEnabled: boolean;
  allSelected?: boolean;
  setAllSelected?: (arg0: boolean) => void;
  selectedDevices?: SearchDeviceResponse;
  setSelectedDevices?: (arg0: SearchDeviceResponse) => void;
  selectDevice?: (arg0: Device) => void;
  clearDevice?: (arg0: Device) => void;
};

export function ActionModalSearchResults(props: ActionModalSearchResultsProps) {
  const checkboxRefs: any = useRef([]); // Refs for checkboxes

  // Initialize refs for checkboxes and div elements
  const numCheckboxes = props.devices.count; // The number of checkboxes
  if (checkboxRefs.current.length !== numCheckboxes) {
    checkboxRefs.current = Array(numCheckboxes)
      .fill(null)
      .map((_, index) => checkboxRefs.current[index] || React.createRef());
  }

  const isDeviceSelected = (deviceID) => {
    return props.selectedDevices?.devices.some(
      (device) => device.id === deviceID
    );
  };

  const imitateCheckboxClick = (device: Device, index: number) => {
    if (!props.allSelected) {
      const checkboxRef = checkboxRefs.current[index];

      if (checkboxRef.current) {
        if (!checkboxRef.current.state.checked && props.selectDevice) {
          props.selectDevice(device);
        } else {
          if (props.clearDevice) props.clearDevice(device);
        }
      }
    }
  };

  return (
    <Table celled selectable>
      <Table.Header>
        <Table.Row>
          {props.checkEnabled && (
            <Table.HeaderCell>
              <Checkbox
                checked={props.allSelected}
                disabled={props.devices.devices.length === 0}
                onChange={(e, { checked }) => {
                  if (
                    checked &&
                    props.setAllSelected &&
                    props.setSelectedDevices
                  ) {
                    props.setAllSelected(true);
                    props.setSelectedDevices(props.devices);
                  } else {
                    if (props.setAllSelected && props.setSelectedDevices) {
                      props.setAllSelected(false);
                      props.setSelectedDevices({ devices: [], count: 0 });
                    }
                  }
                }}
              />
            </Table.HeaderCell>
          )}
          <Table.HeaderCell>ID</Table.HeaderCell>
          <Table.HeaderCell>Last Heartbeat</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {props.devices.devices.length !== 0 ? (
          props.devices.devices.map((device, index) => (
            <Table.Row
              style={{ cursor: "pointer" }}
              key={device.id}
              onClick={() => {
                imitateCheckboxClick(device, index);
              }}
            >
              {props.checkEnabled && (
                <Table.Cell>
                  <Checkbox
                    ref={checkboxRefs.current[index]}
                    disabled={props.allSelected}
                    checked={props.allSelected || isDeviceSelected(device.id)}
                    onChange={(e, { checked }) => {
                      if (checked && props.selectDevice) {
                        props.selectDevice(device);
                      } else {
                        if (props.clearDevice) props.clearDevice(device);
                      }
                    }}
                  />
                </Table.Cell>
              )}
              <Table.Cell>{device.id}</Table.Cell>
              <Table.Cell>
                {device.state?.timestamp
                  ? capitalizeFirstLetter(
                      moment
                        .duration(
                          -1 *
                            moment().diff(moment.utc(device.state["timestamp"]))
                        )
                        .humanize(true)
                    )
                  : "-"}
              </Table.Cell>
            </Table.Row>
          ))
        ) : (
          <Table.Row>
            <Table.Cell colspan={2}>
              <ErrorMessage marginTop="30px" message={"No data Found!"} />
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
}
