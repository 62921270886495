import { Timestamp, RelativeTimestamp, AbsoluteTimestamp } from "./Timestamp";
import React from "react";
import moment from "moment";
import { Input, Label, Dropdown } from "semantic-ui-react";
import { DateTimeInput } from "semantic-ui-calendar-react";

type DateTimePickerProps = {
  value: Timestamp;
  inputLabel: string;
};

type DateTimePickerState = {
  value: Timestamp;
  dropdownIndex: number;
};

export class DateTimePicker extends React.Component<
  DateTimePickerProps,
  DateTimePickerState
> {
  relativeTimeOptions = [
    new RelativeTimestamp(5, "minutes"),
    new RelativeTimestamp(10, "minutes"),
    new RelativeTimestamp(15, "minutes"),
    new RelativeTimestamp(30, "minutes"),
    new RelativeTimestamp(1, "hours"),
    new RelativeTimestamp(6, "hours"),
    new RelativeTimestamp(1, "days"),
    new RelativeTimestamp(1, "weeks"),
    new RelativeTimestamp(1, "months"),
    new RelativeTimestamp(1, "years"),
  ];
  inputRef: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      dropdownIndex: 0,
    };

    this.inputRef = React.createRef<HTMLInputElement>();
  }

  handleDateTimeInputChange(_event, { value }) {
    const newValue = new AbsoluteTimestamp(moment(value).toDate());
    this.setState({ value: newValue });
  }

  getTimestamp() {
    if (this.inputRef.current) {
      if (this.state.value instanceof RelativeTimestamp) {
        const duration = parseInt(this.inputRef.current.value);
        return new RelativeTimestamp(duration, this.state.value.units);
      } else {
        const timestamp = moment(
          this.inputRef.current.value,
          "DD-MM-YY HH:mm:ss"
        );
        return new AbsoluteTimestamp(timestamp.toDate());
      }
    }

    return this.state.value;
  }

  getRelativeTimeOptionsList() {
    if (window.location.hostname === "exponent.bytebeam.io") {
      this.relativeTimeOptions = [
        new RelativeTimestamp(5, "minutes"),
        new RelativeTimestamp(10, "minutes"),
        new RelativeTimestamp(15, "minutes"),
        new RelativeTimestamp(30, "minutes"),
        new RelativeTimestamp(1, "hours"),
        new RelativeTimestamp(6, "hours"),
        new RelativeTimestamp(1, "days"),
        new RelativeTimestamp(1, "weeks"),
        new RelativeTimestamp(1, "months"),
      ];
    }

    let optionsList: RelativeTimestamp[] = [];
    if (this.props.value instanceof RelativeTimestamp) {
      let relativeTimestampFromProp = new RelativeTimestamp(
        this.props.value.duration,
        this.props.value.units
      );
      this.relativeTimeOptions = this.relativeTimeOptions.filter(
        (option) => option.toString() !== relativeTimestampFromProp.toString()
      );

      optionsList = [relativeTimestampFromProp, ...this.relativeTimeOptions];
    } else {
      optionsList = [...this.relativeTimeOptions];
    }

    return optionsList;
  }

  getDropdownText() {
    if (this.state.value instanceof RelativeTimestamp) {
      return `${this.state.value.units} ago`;
    }
    return " "; // Empty string doesn't work
  }

  getInputText() {
    if (this.state.value instanceof RelativeTimestamp) {
      return this.state.value.duration.toString();
    }
    return moment(this.state.value.toDate()).format("DD-MM-YY HH:mm:ss");
  }

  handleItemClick = (index) => {
    const newValue = this.getRelativeTimeOptionsList()[index];
    this.setState({ value: newValue, dropdownIndex: index });
  };

  renderDropdownMenu() {
    const dropdownOptions = this.getRelativeTimeOptionsList().map(
      (option, i) => {
        const isSelected = this.state.dropdownIndex === i;
        return (
          <Dropdown.Item
            key={option.toString()}
            onClick={() => this.handleItemClick(i)}
            selected={isSelected}
          >
            {option.toString()}
          </Dropdown.Item>
        );
      }
    );
    return <Dropdown.Menu>{dropdownOptions}</Dropdown.Menu>;
  }

  render() {
    return (
      <div className="date-time-picker">
        <div
          className="date-time-input"
          style={{ maxWidth: "fit-content", width: "fit-content" }}
        >
          <Input
            type="text"
            labelPosition="right"
            key={this.getInputText()}
            className="date-time-inputbox"
            style={{ maxWidth: "fit-content" }}
          >
            <Label style={{ display: "flex", alignItems: "center" }}>
              {this.props.inputLabel}
            </Label>
            <input
              id={`${this.props.inputLabel.toLowerCase()}_input`}
              ref={this.inputRef}
              defaultValue={this.getInputText()}
              style={{ width: "100%" }}
            />
          </Input>

          <Dropdown
            style={{
              minWidth: "104px",
            }}
            id={`${this.props.inputLabel.toLowerCase()}_dropdown`}
            fluid
            selection
            text={this.getDropdownText()}
            value={this.state.dropdownIndex}
          >
            {this.renderDropdownMenu()}
          </Dropdown>
        </div>

        <DateTimeInput
          key={this.state.value.toString()}
          name="dateTime"
          placeholder="Date Time"
          value={this.state.value.toDate().toString()}
          dateTimeFormat={moment.defaultFormat}
          iconPosition="left"
          closable
          inline
          pickerStyle={{ minWidth: "0px" }}
          onChange={this.handleDateTimeInputChange.bind(this)}
        />
      </div>
    );
  }
}
