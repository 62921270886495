import React, { useState, Component, createRef, RefObject } from "react";
import { Modal, Button } from "semantic-ui-react";
import { TimeRange, AbsoluteTimeRange } from "./TimeRange";
import { DateTimePicker } from "./DateTimePicker";
import styled from "styled-components";
import ToggleSwitch from "../../common/ToggleSwitch";
import { EditAnimatedMetaInput, StyledInputDiv } from "../Panel/util";
import { TenantSettings, User } from "../../../../util";
import { updateTenantSettings } from "../../../../BytebeamClient";
import { ButtonIcon, HARDWARE_TYPES } from "../../util";
import moment from "moment";
import { breakpoints } from "../../../common/breakpoints";

const TimeRangePickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${breakpoints.sm}px) {
    flex-direction: column;
  }
`;

const StartDateTimePicker = styled.div`
  width: 50%;

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
  }
`;

const EndDateTimePicker = styled.div`
  width: 50%;

  @media (max-width: ${breakpoints.sm}px) {
    margin-top: 20px;
    width: 100%;
  }
`;

const SaveTimeRangeFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 18px 10px;
  gap: 12px;
`;

const SaveTimeRangeFormText = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  font-size: 16px;
  font-weight: 600;

  @media (max-width: ${breakpoints.xs}px) {
    span {
      flex: 1;
    }
  }
`;

const SavedTimeRangeListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px 16px;

  & > h3 {
    margin-bottom: 4%;
    padding-bottom: 12px;
    width: 100%;
    font-size: 16px;
    border-bottom: ${(props) =>
      `1px solid ${props.theme.colors["container-secondary-border-color"]}`} !important;
  }

  @media (max-width: ${breakpoints.xs}px) {
    padding: 0px;
  }
`;

const StyledSavedTimeRangeItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75%;
  gap: 12px;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 6px;

  .icon {
    height: fit-content;
  }

  :hover {
    font-weight: 600;
    opacity: 0.75;
    background-color: rgba(0, 0, 0, 0.1);
  }

  @media (max-width: ${breakpoints.xs}px) {
    width: 100%;
  }
`;

const TimeRangeItemDeleteConfirmation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75%;
  padding: 8px 16px;
  font-size: 14px;
  background: #f7d4d6;
  border-radius: 6px;
  border: 1px solid #c50000;
  color: #c50000;

  div {
    .icon {
      height: fit-content;
      margin: 0;
      color: #614343;
    }
    span {
      font-weight: 900;
      font-style: italic;
    }
  }

  @media (max-width: ${breakpoints.xs}px) {
    width: 100%;
  }
`;

function isDateRangeLessThanAMonth(
  startDateStr: string,
  endDateStr: string
): boolean {
  const startDate = moment(startDateStr, "DD-MM-YY HH:mm:ss");
  const endDate = moment(endDateStr, "DD-MM-YY HH:mm:ss");
  const daysDifference = endDate.diff(startDate, "days"); // difference in days

  return daysDifference <= 30;
}

type TimeRangePickerProps = {
  isOpen: boolean;
  onCancel: () => any;
  onTimeRangePicked: (timeRange: TimeRange) => any;
  timeRange: TimeRange;
  user: User;
  getCurrentUser: () => Promise<void>;
  allCustomTimeRangesLabel: Set<string>;
};

type TimeRangePickerState = {
  saveTimeRange: boolean;
  customTimeRangeLabels: Array<string>;
};

type SavedTimeRangeItemProps = {
  label: string;
  onDelete: (label: string) => Promise<void>;
};

function SavedTimeRangeItem(props: SavedTimeRangeItemProps) {
  const { label, onDelete } = props;

  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const handleDeleteConfirmation = async () => {
    await onDelete(label);
    setConfirmDelete(false);
  };
  return (
    <>
      {confirmDelete ? (
        <TimeRangeItemDeleteConfirmation>
          <div>
            Are you sure, you want to delete <span>{label}</span> time range?
          </div>
          <div style={{ display: "flex", gap: "8px" }}>
            <ButtonIcon
              id={`${label}_cancel`}
              name="close"
              onClick={() => setConfirmDelete(false)}
            />
            <ButtonIcon
              id={`${label}_confirm`}
              name="check"
              onClick={handleDeleteConfirmation}
            />
          </div>
        </TimeRangeItemDeleteConfirmation>
      ) : (
        <StyledSavedTimeRangeItem>
          <span>{label}</span>
          <ButtonIcon
            id={`${label}_delete`}
            name="close"
            onClick={() => setConfirmDelete(true)}
          />
        </StyledSavedTimeRangeItem>
      )}
    </>
  );
}

export class TimeRangePicker extends Component<
  TimeRangePickerProps,
  TimeRangePickerState
> {
  endTimeRef: RefObject<DateTimePicker>;
  startTimeRef: RefObject<DateTimePicker>;
  inputRef: RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);

    this.state = {
      saveTimeRange: false,
      customTimeRangeLabels: Array.from(props.allCustomTimeRangesLabel),
    };

    this.startTimeRef = createRef<DateTimePicker>();
    this.endTimeRef = createRef<DateTimePicker>();
    this.inputRef = createRef<HTMLInputElement>();
  }

  // Resetting the state of the component when modal is closed
  onModalClose = () => {
    this.setState({
      saveTimeRange: false,
    });
    this.props.onCancel();
  };

  handleApply = async () => {
    const { saveTimeRange } = this.state;
    const {
      allCustomTimeRangesLabel,
      user,
      getCurrentUser,
      onTimeRangePicked,
    } = this.props;

    try {
      if (this.startTimeRef.current && this.endTimeRef.current) {
        const startTime = this.startTimeRef.current?.getTimestamp();
        const endTime = this.endTimeRef.current?.getTimestamp();
        const label: string = (this.inputRef.current?.value ?? "").trim();

        const timeRange = new AbsoluteTimeRange(startTime, endTime);

        if (saveTimeRange) {
          if (label === "") {
            window.toastr.error(
              "Please enter a label for your custom time range."
            );
          } else if (allCustomTimeRangesLabel.has(label)) {
            window.toastr.error(
              "A custom time range with this label already exists."
            );
          } else {
            if (window.location.hostname === "exponent.bytebeam.io") {
              let timeRangeString = timeRange.toString();
              const [startString, endString] = timeRangeString.split(" to ");

              if (!isDateRangeLessThanAMonth(startString, endString)) {
                window.toastr.error("Time range should be less than a month");
                return;
              }
            }

            const tenant_settings: TenantSettings = user["tenant-settings"] ?? {
              common_settings: {
                pin_metadata: [],
              },
              dashboard_settings: {
                custom_time_ranges: {},
              },
              hardware_type: HARDWARE_TYPES[0],
            };
            const dashboardSettings = tenant_settings?.dashboard_settings ?? {};
            const customTimeRanges =
              dashboardSettings?.custom_time_ranges ?? {};

            await updateTenantSettings({
              settings: {
                ...tenant_settings,
                dashboard_settings: {
                  ...dashboardSettings,
                  custom_time_ranges: {
                    ...customTimeRanges,
                    [label]: timeRange,
                  },
                },
              },
            });

            await getCurrentUser();
            onTimeRangePicked(timeRange);
            // Resetting State to default values after the modal is closed
            this.onModalClose();
          }
        } else {
          if (window.location.hostname === "exponent.bytebeam.io") {
            let timeRangeString = timeRange.toString();
            const [startString, endString] = timeRangeString.split(" to ");

            if (!isDateRangeLessThanAMonth(startString, endString)) {
              window.toastr.error("Time range should be less than a month");
              return;
            }
          }
          onTimeRangePicked(timeRange);
          // Resetting State to default values after the modal is closed
          this.onModalClose();

          this.props.onTimeRangePicked(timeRange);
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  handleDelete = async (label: string) => {
    const { user, getCurrentUser } = this.props;

    try {
      const tenant_settings: TenantSettings = user["tenant-settings"] ?? {
        common_settings: {
          pin_metadata: [],
        },
        dashboard_settings: {
          custom_time_ranges: {},
        },
        hardware_type: HARDWARE_TYPES[0],
      };
      const dashboardSettings = tenant_settings?.dashboard_settings ?? {};
      const customTimeRanges = dashboardSettings?.custom_time_ranges ?? {};

      delete customTimeRanges[label];

      await updateTenantSettings({
        settings: {
          ...tenant_settings,
          dashboard_settings: {
            ...dashboardSettings,
            custom_time_ranges: {
              ...customTimeRanges,
            },
          },
        },
      });

      await getCurrentUser();
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  deleteCustomTimeRange = async (label: string) => {
    const updatedLabels = this.state.customTimeRangeLabels.filter(
      (item) => item !== label
    );
    this.setState({
      customTimeRangeLabels: updatedLabels,
    });
    this.props.allCustomTimeRangesLabel.delete(label);
    this.handleDelete(label);
  };

  // TODO: Fix this logic to enable/disable the Apply button
  // isApplyButtonDisabled() {
  //   let disabled = false;
  //   if (this.startTimeRef.current?.getTimestamp() === undefined) {
  //     disabled = true;
  //   }
  //   if (this.endTimeRef.current?.getTimestamp() === undefined) {
  //     disabled = true;
  //   }
  //   return disabled;
  // }

  static getDerivedStateFromProps(props) {
    return {
      customTimeRangeLabels: Array.from(props.allCustomTimeRangesLabel),
    };
  }

  render() {
    const { isOpen, timeRange } = this.props;
    const { saveTimeRange, customTimeRangeLabels } = this.state;

    return (
      <Modal
        open={isOpen}
        size="small"
        className="dark"
        onClose={() => this.onModalClose()}
      >
        <Modal.Header>Custom Time Range</Modal.Header>
        <Modal.Content>
          <TimeRangePickerContainer>
            <StartDateTimePicker>
              <DateTimePicker
                value={timeRange.getStartTime()}
                inputLabel="From"
                ref={this.startTimeRef}
              />
            </StartDateTimePicker>
            <EndDateTimePicker>
              <DateTimePicker
                value={timeRange.getEndTime()}
                inputLabel="To"
                ref={this.endTimeRef}
              />
            </EndDateTimePicker>
          </TimeRangePickerContainer>
          <SaveTimeRangeFormContainer>
            <SaveTimeRangeFormText>
              <span>Do you want to save your custom time range?</span>
              <ToggleSwitch
                id="showSaveCustomTimeRangeToggle"
                defaultChecked={saveTimeRange}
                disabled={false}
                Text={["Yes", "No"]}
                onToggleChange={() => {
                  this.setState({
                    saveTimeRange: !saveTimeRange,
                  });
                }}
              />
            </SaveTimeRangeFormText>
            {saveTimeRange && (
              <StyledInputDiv width="75%" marginTop="12px">
                <EditAnimatedMetaInput
                  defaultRef={this.inputRef}
                  defaultValue={""}
                  label="Custom Time Range Label"
                />
              </StyledInputDiv>
            )}
            {saveTimeRange && customTimeRangeLabels.length > 0 && (
              <SavedTimeRangeListContainer>
                <h3>Custom Time Range List</h3>
                {customTimeRangeLabels.map((label) => (
                  <SavedTimeRangeItem
                    key={label}
                    label={label}
                    onDelete={this.deleteCustomTimeRange}
                  />
                ))}
              </SavedTimeRangeListContainer>
            )}
          </SaveTimeRangeFormContainer>
        </Modal.Content>
        <Modal.Actions>
          <Button secondary onClick={this.onModalClose}>
            Cancel
          </Button>
          <Button
            primary
            // disabled={this.isApplyButtonDisabled()}
            onClick={this.handleApply}
          >
            Apply
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
