export interface CardValues {
  key: string;
  heading: string;
  description: string;
  url: string;
  cardSequence: number;
  action?: string;
}

interface DeviceTypes {
  [deviceType: string]: CardValues[];
}

interface GetStarted {
  deviceTypes: DeviceTypes;
}

const Rust_ESP_IDFConfig: CardValues[] = [
  {
    key: "rustESP_GetStarted",
    heading: "Get Started",
    description: "Get started with the ESP using Rust.",
    url: "https://bytebeam.io/docs/rust-esp-idf",
    cardSequence: 1,
  },
  {
    key: "rustESP_SDKSetup",
    heading: "SDK Setup",
    description: "Set up the Bytebeam ESP-RS SDK.",
    url: "https://bytebeam.io/docs/setting-up-bytebeam-esp-rs-sdk",
    cardSequence: 2,
  },
  {
    key: "rustESP_PublishData",
    heading: "Publish Data",
    description: "Send sensor data to the Bytebeam cloud.",
    url: "https://bytebeam.io/docs/pushing-data-to-bytebeam",
    cardSequence: 3,
  },
  {
    key: "rustESP_ReceiveActions",
    heading: "Receive Actions",
    description: "Handle cloud commands on your device.",
    url: "https://bytebeam.io/docs/Ezyk-receiving-actions",
    cardSequence: 4,
  },
  {
    key: "rustESP_SoftwareUpdate",
    heading: "Software Updates",
    description: "Manage remote updates via Bytebeam.",
    url: "https://bytebeam.io/docs/over-the-air-ota-updates",
    cardSequence: 5,
  },
];

const ESP_IDFConfig = [
  {
    key: "ESP_GetStarted",
    heading: "Get Started",
    description: "Start with the ESP.",
    url: "https://bytebeam.io/docs/esp-idf",
    cardSequence: 1,
  },
  {
    key: "ESP_SDKSetup",
    heading: "SDK Setup",
    description: "Set up the Bytebeam ESP-IDF SDK.",
    url: "https://bytebeam.io/docs/setting-up-bytebeam-esp-idf-sdk",
    cardSequence: 2,
  },
  {
    key: "ESP_DataToCloud",
    heading: "Publish Data",
    description: "Push sensor data to the Bytebeam cloud.",
    url: "https://bytebeam.io/docs/pushing-data-to-bytebeam",
    cardSequence: 3,
  },
  {
    key: "ESP_RemoteActions",
    heading: "Remote Commands",
    description: "Operate the ESP32 remotely via Bytebeam.",
    url: "https://bytebeam.io/docs/DPY6-receiving-actions",
    cardSequence: 4,
  },
  {
    key: "ESP_OTAUpdates",
    heading: "OTA Updates",
    description: "Configure OTA updates for the ESP32.",
    url: "https://bytebeam.io/docs/integrate-over-the-air-updates",
    cardSequence: 5,
  },
  {
    key: "ESP_OTAConfig",
    heading: "OTA Configs",
    description: "Modify device settings remotely.",
    url: "https://bytebeam.io/docs/update-configurations",
    cardSequence: 6,
  },
  {
    key: "ESP_CloudLogging",
    heading: "Cloud Logs",
    description: "Log and monitor the device remotely.",
    url: "https://bytebeam.io/docs/cloud-logging",
    cardSequence: 7,
  },
  {
    key: "ESP_PlatformIO",
    heading: "PlatformIO",
    description: "Integrate using PlatformIO Home/CLI.",
    url: "https://bytebeam.io/docs/platformio-integration",
    cardSequence: 8,
  },
  {
    key: "ESP_Heartbeat",
    heading: "Device Heartbeat",
    description: "Monitor the device's health on the platform.",
    url: "https://bytebeam.io/docs/add-device-heartbeat-parameters",
    cardSequence: 9,
  },
];

const ArduinoConfig: CardValues[] = [
  {
    key: "Arduino_GetStarted",
    heading: "Get Started",
    description: "Get started with Arduino.",
    url: "https://bytebeam.io/docs/arduino",
    cardSequence: 1,
  },
  {
    key: "Arduino_SDKSetup",
    heading: "SDK Setup",
    description: "Set up the Bytebeam Arduino SDK.",
    url: "https://bytebeam.io/docs/setting-up-bytebeamarduino-sdk",
    cardSequence: 2,
  },
  {
    key: "Arduino_DataToCloud",
    heading: "Publish Data",
    description: "Transfer device data to the cloud.",
    url: "https://bytebeam.io/docs/BeO2-pushing-data-to-bytebeam",
    cardSequence: 3,
  },
  {
    key: "Arduino_RemoteCommands",
    heading: "Remote Commands",
    description: "Operate your device using Bytebeam.",
    url: "https://bytebeam.io/docs/receiving-actions",
    cardSequence: 4,
  },
  {
    key: "Arduino_OTA",
    heading: "OTA Updates",
    description: "Implement OTA updates using Bytebeam.",
    url: "https://bytebeam.io/docs/Q5xM-integrate-over-the-air-updates",
    cardSequence: 5,
  },
  {
    key: "Arduino_GSM",
    heading: "GSM Setup",
    description: "Configure GSM connectivity.",
    url: "https://bytebeam.io/docs/setting-up-bytebeamarduino-for-gsm-connectivity",
    cardSequence: 6,
  },
  {
    key: "Arduino_AdvancedConfig",
    heading: "Advanced Config",
    description: "Advanced Bytebeam Arduino settings.",
    url: "https://bytebeam.io/docs/advanced-sdk-configurations",
    cardSequence: 7,
  },
  {
    key: "Arduino_PlatformIO",
    heading: "PlatformIO",
    description: "Integrate with PlatformIO Home/CLI.",
    url: "https://bytebeam.io/docs/275H-platformio-integration",
    cardSequence: 8,
  },
];

const LinuxConfig: CardValues[] = [
  {
    key: "Linux_GettingStarted",
    heading: "Get Started",
    description: "Get started with the Bytebeam Linux SDK.",
    url: "https://bytebeam.io/docs/linux",
    cardSequence: 1,
  },
  {
    key: "Linux_DeviceSetup",
    heading: "Device Setup",
    description: "Use the Bytebeam cloud on Linux-based devices.",
    url: "https://bytebeam.io/docs/device-setup",
    cardSequence: 2,
  },
  {
    key: "Linux_ConnectToCloud",
    heading: "Connect to Cloud",
    description: "Provision the device and run Uplink.",
    url: "https://bytebeam.io/docs/connect-to-bytebeam-cloud",
    cardSequence: 3,
  },
  {
    key: "Linux_PushData",
    heading: "Publish Data",
    description: "Push device data to the Bytebeam Cloud.",
    url: "https://bytebeam.io/docs/IuvH-pushing-data-to-bytebeam",
    cardSequence: 4,
  },
  {
    key: "Linux_CustomizeDeviceShadow",
    heading: "Customize Device Shadow",
    description: "Customize the default device_shadow stream data.",
    url: "https://bytebeam.io/docs/customising-device-shadow",
    cardSequence: 5,
  },
  {
    key: "Linux_ReceivingActions",
    heading: "Receive Actions",
    description: "Receive actions from the Cloud.",
    url: "https://bytebeam.io/docs/4H9i-receiving-actions",
    cardSequence: 6,
  },
  {
    key: "Linux_RemoteLogin",
    heading: "Remote Commands",
    description: "Remotely log in to the device using the Remote Shell Action.",
    url: "https://bytebeam.io/docs/remote-login-to-your-linux-device",
    cardSequence: 7,
  },
  {
    key: "Linux_OTAUpdates",
    heading: "OTA Updates",
    description: "Integrate Over-The-Air Updates using Uplink.",
    url: "https://bytebeam.io/docs/hYWy-integrate-over-the-air-updates",
    cardSequence: 8,
  },
  {
    key: "Linux_IntegrateConfigUpdates",
    heading: "OTA Configs",
    description: "Create and update device configs remotely.",
    url: "https://bytebeam.io/docs/integrate-config-updates",
    cardSequence: 9,
  },
  {
    key: "Linux_PushApplicationLogs",
    heading: "Cloud Logs",
    description: "View journald logs on the cloud.",
    url: "https://bytebeam.io/docs/push-application-logs",
    cardSequence: 10,
  },
  {
    key: "Linux_MonitoringDevice",
    heading: "Monitor Device",
    description: "Remotely monitor your device using the Bytebeam Cloud.",
    url: "https://bytebeam.io/docs/monitoring-your-device",
    cardSequence: 11,
  },
];

const GetStartedConfig: GetStarted = {
  deviceTypes: {
    "ESP32 (ESP-IDF)": ESP_IDFConfig,
    "ESP32 (Rust ESP-IDF)": Rust_ESP_IDFConfig,
    Arduino: ArduinoConfig,
    Linux: LinuxConfig,
  },
};

export default GetStartedConfig;
