import React, { useCallback, useEffect } from "react";
import { OptionType } from "../util";
import { Form } from "semantic-ui-react";
import { StyledFileInput } from "../../DeviceMangaement/Devices/ActionModals/SendFileModal";
import { uploadFile } from "../../../../BytebeamClient";
import AnimatedEllipsis from "../../common/AnimatedEllipsis";
import { StyledScriptUploadButton } from "../../DeviceMangaement/Devices/ActionModals/SendScriptModal";

type SendScriptProps = {
  setOptionType: (arg0: OptionType) => void;
  optionType: OptionType;
  uploadTriggered: string;
  setUploadTriggered: (arg0: string) => void;
  uploadTriggeredEach: boolean;
  setActiveModal: (arg0: number) => void;
  activeModal: number;
  customScriptInputData: string;
  setCustomScriptInputData: (arg0: string) => void;
  setDisableNextButton: (arg0: boolean) => void;
  script: File;
  setScript: (arg0: File) => void;
  scriptName: string;
  setScriptName: (arg0: string) => void;
  showScriptUploadProgress: boolean;
  setShowScriptUploadProgress: (arg0: boolean) => void;
  scriptLoaded: number;
  setScriptLoaded: (arg0: number) => void;
  scriptTotal: number;
  setScriptTotal: (arg0: number) => void;
  uploadedScriptResponse: {
    status: number;
    data: { id: string };
  };
  setUploadedScriptResponse: (arg0: {
    status: number;
    data: { id: string };
  }) => void;
  scriptInput: any;
  action: string;
};

export default function SendScript(props: SendScriptProps) {
  const fileUpload = async (file: File, fileName: string) => {
    props.setDisableNextButton(true);
    // File Upload code
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);

    try {
      const url = `/api/v1/file`;
      await uploadFile(url, formData, (p) => {
        props.setScriptLoaded(p.loaded);
        props.setScriptTotal(p.total);
      }).then((res) => {
        props.setUploadedScriptResponse(res);
        window.toastr.success("File uploaded successfully!");
        props.setDisableNextButton(false);

        // move to next modal for summary and triggering respective action
        props.setCustomScriptInputData(res.data.id);
        props.setActiveModal(props.activeModal + 1);
      });
    } catch (error) {
      // Handling Error for File Size exceeding limit from NGINX
      if (String(error).includes("413"))
        window.toastr.error("Upload failed due to size limit!");
      else window.toastr.error("Failed to upload File");
      props.setDisableNextButton(false);
    }
  };

  const onSelect = useCallback((e) => {
    props.setOptionType(OptionType.SendScript);
    props.setShowScriptUploadProgress(false);
    props.setScriptLoaded(0);
    props.setScriptTotal(0);
    if (e.target.files.length !== 0) {
      props.setScript(e.target.files[0]);
      props.setScriptName(e.target.files[0].name);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = () => {
    if (
      props.script !== new File([""], "filename") &&
      props.scriptName !== "" &&
      props.showScriptUploadProgress &&
      props.scriptLoaded !== 0 &&
      props.scriptTotal !== 0 &&
      props.customScriptInputData !== ""
    ) {
      // form already has data, no need to upload again unless file has been selected again
      props.setActiveModal(props.activeModal + 1);
    } else {
      if (props.scriptInput?.current?.files) {
        const fileCount = props.scriptInput.current.files.length;
        if (fileCount === 0) {
          props.setUploadTriggered("");
          setTimeout(() =>
            window.toastr.error("An upload file must be selected!")
          );
        } else {
          // form is valid
          props.setShowScriptUploadProgress(true);
          fileUpload(props.script, props.scriptName);
        }
      }
    }
  };

  useEffect(() => {
    props.setUploadTriggered("");
    if (!props.showScriptUploadProgress) {
      props.setScript(new File([""], "filename"));
      props.setScriptName("");
      props.setCustomScriptInputData("");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      props.script !== new File([""], "filename") &&
      props.scriptName !== ""
    ) {
      props.setOptionType(OptionType.SendScript);
    }

    if (props.uploadTriggered === "trigger") {
      switch (props.optionType) {
        case OptionType.SendScript:
          handleSubmit();
          break;
      }
    }
  }, [props.uploadTriggered, props.uploadTriggeredEach]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form>
      <Form.Field>
        <label>Upload Script from the system:</label>
        <div style={{ position: "relative" }}>
          <StyledScriptUploadButton
            fluid
            content={`Select ${
              props.action === "send_script" ? "Script" : "File"
            }`}
            labelPosition="left"
            icon="file"
          />
          <StyledFileInput
            type="file"
            id="file"
            ref={props.scriptInput}
            onChange={onSelect}
          />
        </div>
        <label style={{ marginTop: "12px" }}>Script Chosen:</label>
        {props.scriptName ? props.scriptName : "No Script Chosen"}
      </Form.Field>

      {props.showScriptUploadProgress && (
        <Form.Field>
          <label htmlFor="file-progress">
            {props.uploadedScriptResponse.status === 0 ? (
              <span>
                Script Uploading
                <AnimatedEllipsis
                  spacing={3}
                  dotSize={"8px"}
                  dotColor={"#ffffff"}
                />
              </span>
            ) : (
              <span>Script Uploaded</span>
            )}
          </label>
          <progress
            id="file-progress"
            max={props.scriptTotal}
            value={props.scriptLoaded}
          />
        </Form.Field>
      )}
    </Form>
  );
}
