import React from "react";
import StyledSVGIcon from "./StyledSVGIcon";

const svgContent = `<svg width="238" height="63" viewBox="0 0 238 63" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M237.352 41.6205H233.522V32.9154L233.222 32.7773C231.187 35.6392 229.152 38.501 227.038 41.4733C225.031 38.7173 223.107 36.0763 221.002 33.1822V41.5607H216.992V20.7871C220.453 25.6734 223.73 30.2974 227.103 35.0594C230.527 30.3388 233.868 25.7378 237.361 20.9159V41.6205H237.352Z" fill="#FFFFFF"/>
<path d="M163.914 21.6696C167.08 21.8583 170.135 21.274 173.102 22.231C176.876 23.4456 177.887 27.651 175.123 30.4944C174.924 30.6968 174.648 30.8487 174.606 31.2398C175.764 31.9943 176.876 32.8409 177.301 34.2902C178.39 37.9894 176.194 41.1549 171.971 41.5598C169.332 41.8129 166.646 41.6058 163.914 41.6058V21.6696ZM168.026 37.856C169.387 37.9342 170.619 38.0308 171.837 37.6444C172.76 37.3499 173.319 36.7794 173.332 35.7672C173.346 34.7135 172.7 34.2074 171.791 33.9359C170.578 33.5771 169.35 33.6507 168.026 33.7657V37.8606V37.856ZM168.016 29.8595C169.502 29.7582 171.044 30.3104 172.063 28.8104C172.571 28.0651 172.663 27.2001 172.036 26.4869C170.901 25.2032 169.401 25.6403 168.016 25.6311V29.8549V29.8595Z" fill="#FFFFFF"/>
<path d="M95.8125 41.5737V21.6881C98.872 21.8307 101.936 21.2602 104.889 22.1988C108.9 23.4779 109.822 27.7108 106.832 30.6923C106.694 30.8349 106.578 31.0005 106.449 31.157C109.209 32.9974 110.076 34.755 109.024 38.0953C108.295 40.4096 106.251 41.3344 104.082 41.5184C101.359 41.7485 98.6043 41.5737 95.8125 41.5737V41.5737ZM99.878 33.7197V37.8606C101.225 37.9435 102.504 38.0493 103.754 37.6444C104.668 37.3499 105.263 36.7426 105.263 35.7442C105.263 34.7872 104.663 34.2488 103.791 33.9636C102.536 33.5541 101.253 33.6967 99.878 33.7197V33.7197ZM99.8503 29.9239C101.392 29.6846 103.039 30.3932 104.022 28.723C104.423 28.0421 104.497 27.2369 104.008 26.602C102.91 25.1756 101.341 25.6587 99.8503 25.6311V29.9239Z" fill="#FFFFFF"/>
<path d="M194.943 41.6065H181.016V21.7715H194.5C194.726 23.0138 194.606 24.233 194.578 25.5581H185.095V29.7128H193.406C193.618 31.0425 193.521 32.2618 193.484 33.5823H185.113V37.6542H194.878C195.053 38.9839 194.966 40.2492 194.943 41.6019V41.6065Z" fill="#FFFFFF"/>
<path d="M159.913 25.6204H150.374V29.7292H158.81V33.6078H150.388V37.6613H160.249V41.5722H146.461V21.7832H159.917V25.6204H159.913Z" fill="#FFFFFF"/>
<path d="M209.914 41.6008C209.402 40.3539 208.881 39.0933 208.345 37.7866H202.023C201.248 38.923 200.957 40.3355 200.247 41.6192H196.273C199.291 34.6901 202.268 27.853 205.244 21.0159L205.609 20.9883C208.585 27.8208 211.562 34.6533 214.589 41.6054H209.91L209.914 41.6008ZM205.23 29.8544C204.515 31.5476 203.952 32.8911 203.375 34.253H206.97C206.407 32.8359 205.895 31.5384 205.226 29.8544H205.23Z" fill="#FFFFFF"/>
<path d="M120.947 41.6162H117.001C117.001 40.7788 116.914 39.9782 117.015 39.2098C117.361 36.5965 116.466 34.3696 115.178 32.1519C113.347 28.991 111.704 25.7243 109.973 22.5036C109.872 22.3195 109.742 22.1585 109.858 21.7122C111.256 21.7122 112.733 21.6892 114.205 21.726C114.62 21.7352 114.754 22.1493 114.929 22.499C116.12 24.9191 117.334 27.3254 118.543 29.7363C118.686 30.017 118.842 30.2931 119.073 30.721C120.582 27.6429 122.013 24.7166 123.439 21.8042H128.427C127.804 22.9314 127.264 23.8931 126.738 24.8639C125.054 27.965 123.411 31.0844 121.676 34.1533C121.141 35.1011 120.896 36.0351 120.933 37.1118C120.984 38.6025 120.947 40.0932 120.947 41.6254V41.6162Z" fill="#FFFFFF"/>
<path d="M138.25 41.6071H134.12V25.6186H129.625V21.7676H142.961C143.266 23.0053 143.012 24.2015 143.137 25.5312H138.25V41.6071Z" fill="#FFFFFF"/>
<path d="M76.1626 15.8275L43.625 0L58.9547 19.3243L76.1626 15.8275Z" fill="#FFFFFF"/>
<path d="M76.1626 46.5827L43.625 62.4102L58.9547 43.0859L76.1626 46.5827Z" fill="#FFFFFF"/>
<path d="M58.9562 19.3301L68.6377 31.2605L58.9562 43.0667L0 31.2007L58.9562 19.3301Z" fill="#FFFFFF"/>
</svg>`;

type ByteBeamLogoSVGProps = {
  height: string;
  // Allowing all other props
  [x: string]: any;
};

function ByteBeamLogoSVG(props: Readonly<ByteBeamLogoSVGProps>) {
  const { height, ...rest } = props;

  return <StyledSVGIcon height={height} {...rest} svgContent={svgContent} />;
}

export default ByteBeamLogoSVG;
