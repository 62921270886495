import { LineChartMetaData, LineChartData } from "./PanelDef";
import React from "react";
import { PanelViewComponent } from "../PanelDef";
import Plot from "react-plotly.js";
import Plotly from "plotly.js-cartesian-dist";
import { AbsoluteTimeRange } from "../../Datetime/TimeRange";
import { AbsoluteTimestamp } from "../../Datetime/Timestamp";
import { ReplayState } from "../../DashboardHeader";
import { Shape } from "plotly.js";
import { UserContext } from "../../../../../context/User.context";
import ThemeSchema from "../../../../../theme/schema";

export class ViewLineChart extends PanelViewComponent<
  LineChartMetaData,
  LineChartData
> {
  plotRef: React.RefObject<Plot> = React.createRef();
  graphNode: Plot | null | undefined;

  onRelayout(event) {
    if ("xaxis.range[0]" in event && this.props.onTimeRangeChange) {
      const startTime = event["xaxis.range[0]"];
      const endTime = event["xaxis.range[1]"];

      this.props.onTimeRangeChange(
        new AbsoluteTimeRange(
          new AbsoluteTimestamp(new Date(startTime)),
          new AbsoluteTimestamp(new Date(endTime))
        )
      );
    }
  }

  // componentDidUpdate(prevProps) {
  // Work In Progress (Correlated Hover)
  // if ((prevProps.hoverPointX !== this.props.hoverPointX)) {
  //   let oldX = prevProps.hoverPointX?.x || 0;
  //   const { hoverPointX, hover } = this.props;
  //   if (hoverPointX && hover) {
  //     const { curveNumber, pointNumber, x } = hoverPointX;
  //     try {
  //       // this.triggerHover(curveNumber, pointNumber);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // else if(!hover && !hoverPointX){
  // try {
  //   this.clearHover();
  // } catch (error) {
  //   console.log(error);
  // }
  // }
  // }
  // }

  // triggerHover(curveNumber, pointNumber) {
  //   {
  //     const chart = this.plotRef.current;
  //     const curveLength = chart?.props.data.length || 0;
  //     let hoverPoints: { curveNumber: number, pointNumber: any }[] = [];
  //     for(let i = 0; i < curveLength; i++){
  //       hoverPoints.push({ curveNumber: i, pointNumber: pointNumber });
  //     }
  //     Plotly.Fx.hover(`plotly-graph-div-${this.props.panelMeta.id}`, hoverPoints);
  //   }
  // }

  // clearHover() {
  //     if (this.plotRef.current) {
  //       const chart = this.plotRef.current;
  //       Plotly.Fx.hover(`plotly-graph-div-${this.props.panelMeta.id}`, []);
  //     }
  // }

  lineChartDataToPlotlyData(lineChartData: LineChartData) {
    // Helper function for sorting
    function compareValues(a: string | number, b: string | number) {
      if (typeof a === "number" && typeof b === "number") {
        return a - b;
      } else {
        return a.toString().localeCompare(b.toString());
      }
    }

    // Sort the data first
    lineChartData.sort((a, b) => {
      let aValue: string | number = Object.values(a.group)[0];
      let bValue: string | number = Object.values(b.group)[0];

      // Convert string numbers to actual numbers
      if (!isNaN(parseInt(aValue))) {
        aValue = parseInt(aValue);
      }
      if (!isNaN(parseInt(bValue))) {
        bValue = parseInt(bValue);
      }

      // Handle null and undefined
      if (aValue === null || aValue === undefined) aValue = "null";
      if (bValue === null || bValue === undefined) bValue = "null";

      return compareValues(aValue, bValue);
    });

    let finalLineChartData = lineChartData.map((lineChart, index) => {
      const { aggregator, label, group, data } = lineChart;

      // Adding nulls here
      const startTime = Math.round(
        this.props.timeRange.getStartTime().toDate().valueOf()
      );
      const endTime = Math.round(
        this.props.timeRange.getEndTime().toDate().valueOf()
      );

      const aggregationInterval = Math.max(
        Math.round((endTime - startTime) / 200),
        1
      );

      let transformedData = new Array<{
        timestamp: number;
        value: number | null | string;
      }>();

      //iterate over the data and add nulls where aggregation interval is not met
      for (let i = 0; i < data.length; i++) {
        const current = data[i];
        const next = data[i + 1];
        transformedData.push(current);
        if (next) {
          const diff = next.timestamp - current.timestamp;
          if (diff > aggregationInterval) {
            const nulls = Math.floor(diff / aggregationInterval);
            for (let j = 1; j < nulls; j++) {
              transformedData.push({
                timestamp: current.timestamp + j * aggregationInterval,
                value: null,
              });
            }
          }
        }
      }

      // Added nulls to transformedData
      const x = transformedData.map((d) => new Date(d.timestamp));
      const y = transformedData.map((d) => d.value);
      const s = Object.entries(group)
        .map(([key, value]) => `${key}=${value}`)
        .join(",");

      const suffix = !!s ? `[${s}]` : s;

      // Check if alternate axis is to be used
      let useAlternateAxis = false;
      if (
        this.props.panelMeta.alternateAxisMap &&
        this.props.panelMeta.alternateAxisMap.length > 0 &&
        this.props.panelMeta.alternateAxisMap[index] &&
        typeof this.props.panelMeta.alternateAxisMap[index] === "object" &&
        this.props.panelMeta.alternateAxisMap[index]["alternateAxis"]
      ) {
        useAlternateAxis =
          this.props.panelMeta.alternateAxisMap[index]["alternateAxis"];
      }
      return {
        x: x,
        y: y,
        type: "scatter",
        mode: this.props.panelMeta.showMarkers ? "lines+markers" : "lines",
        fill: this.props.panelMeta.showAreaChart ? "tozeroy" : "none",
        connectgaps: this.props.panelMeta.connectNullValues,
        line: { width: this.props.panelMeta.lineWidth },
        marker: { size: this.props.panelMeta.markerRadius },
        name: `${aggregator}(${label})${suffix}`,
        yaxis: useAlternateAxis ? "y2" : "",
      } as Plotly.Data;
    });
    // Push Threshold Line if its provided
    // Added Date.now(), so that it will show even if data stops coming
    if (this.props.panelMeta.threshold) {
      finalLineChartData.push({
        x: [
          this.props.timeRange.getStartTime().toDate(),
          this.props.timeRange.getEndTime().toDate(),
        ],
        y: [this.props.panelMeta.threshold, this.props.panelMeta.threshold],
        type: "scatter",
        mode: "lines",
        fill: "none",
        line: { width: 0.7, color: "#D0342C" },
        name: `Threshold`,
      });
    }

    return finalLineChartData;
  }

  render() {
    let startTime = this.props.timeRange.getStartTime().toDate();
    let endTime = this.props.timeRange.getEndTime().toDate();

    let shapes: Partial<Shape>[] = [];

    if (
      this.props.replayState === ReplayState.ReplayRunning ||
      this.props.replayState === ReplayState.ReplayPaused
    ) {
      const length = (endTime.valueOf() - startTime.valueOf()) / 8;

      startTime = new Date(this.props.replayTimestamp - length);
      endTime = new Date(this.props.replayTimestamp + length);

      shapes = [
        {
          type: "line",
          x0: new Date(this.props.replayTimestamp),
          y0: 0,
          x1: new Date(this.props.replayTimestamp),
          yref: "paper",
          y1: 1,
          line: {
            color: "blue",
            width: 1.5,
          },
        },
      ];
    }

    const plotStyle = {
      width: "100%",
      height: "100%",
    };

    const layout = {
      showlegend: true,
      autosize: true,
      responsive: true,
      legend: { orientation: "h", y: -0.4 }, // Added y position so that ticks don't overlap with Margin
      margin: {
        l: this.props.panelMeta.autoMarginEnable ? 25 : this.props.panelMeta.leftMarginYaxis,//25,
        r: 25,
        b: this.props.panelMeta.yAxisLabel ? 90 : 70,
        t: 50,
        pad: 10,
      },
      xaxis: {
        range: [startTime, endTime],
        automargin: true,
        title: {
          text: this.props.panelMeta.xAxisLabel
            ? this.props.panelMeta.xAxisLabel
            : undefined,
          font: {
            size: 18,
            color: "#D3D3D3",
          },
        },
        tickfont: {
          color: "#C0C0C0",
        },
        ticksuffix: this.props.panelMeta.xAxisUnit
          ? " " + this.props.panelMeta.xAxisUnit
          : undefined,
        showgrid: this.props.panelMeta.xAxisGrids,
        spikemode: "across",
        spikethickness: 0.1,
        spikecolor: "#6fa8dc",
      },
      yaxis: {
        automargin: this.props.panelMeta.autoMarginEnable,
        rangemode: this.props.panelMeta.rangeToZero ? "tozero" : "normal",
        title: {
          text: this.props.panelMeta.yAxisLabel
            ? this.props.panelMeta.yAxisLabel
            : undefined,
          font: {
            size: 18,
            color: "#D3D3D3",
          },
        },
        tickfont: {
          color: "#C0C0C0",
        },
        ticksuffix: this.props.panelMeta.yAxisUnit
          ? " " + this.props.panelMeta.yAxisUnit
          : undefined,
        showgrid: this.props.panelMeta.yAxisGrids,
      },
      yaxis2: {
        automargin: true,
        titlefont: { color: "#bed1e6" },
        tickfont: { color: "#bed1e6" },
        anchor: "x",
        overlaying: "y",
        side: "right",
      },
      hoverlabel: { namelength: -1 },
      hoverdistance: 10,
      hovermode: "x unified",
      shapes: shapes,
    } as Partial<Plotly.Layout>;

    if (
      (this.props.panelMeta.minRange || this.props.panelMeta.minRange === 0) &&
      this.props.panelMeta.maxRange
    ) {
      let yAxisConfig = {
        range: [this.props.panelMeta.minRange, this.props.panelMeta.maxRange],
      };
      layout.yaxis = { ...layout.yaxis, ...yAxisConfig };
    }

    if (this.props.data && this.props.data.length > 0) {
      return (
        <UserContext.Consumer>
          {(userContext) => (
            <Plot
              data={this.lineChartDataToPlotlyData(this.props.data)}
              style={plotStyle}
              layout={{
                ...layout,
                xaxis: {
                  ...layout.xaxis,
                  title: {
                    text: this.props.panelMeta.xAxisLabel
                      ? this.props.panelMeta.xAxisLabel
                      : undefined,
                    font: {
                      size: 18,
                      color: ThemeSchema.data[
                        userContext.user?.settings?.theme ?? "dark"
                      ]?.colors["chart-text-color"],
                    },
                  },
                  tickfont: {
                    color:
                      ThemeSchema.data[
                        userContext.user?.settings?.theme ?? "dark"
                      ]?.colors["chart-text-color"],
                  },
                },
                yaxis: {
                  ...layout.yaxis,
                  title: {
                    text: this.props.panelMeta.yAxisLabel
                      ? this.props.panelMeta.yAxisLabel
                      : undefined,
                    font: {
                      size: 18,
                      color: ThemeSchema.data[
                        userContext.user?.settings?.theme ?? "dark"
                      ]?.colors["chart-text-color"],
                    },
                  },
                  tickfont: {
                    color:
                      ThemeSchema.data[
                        userContext.user?.settings?.theme ?? "dark"
                      ]?.colors["chart-text-color"],
                  },
                },
                yaxis2: {
                  ...layout.yaxis2,
                  tickfont: {
                    color:
                      ThemeSchema.data[
                        userContext.user?.settings?.theme ?? "dark"
                      ]?.colors["chart-text-color"],
                  },
                },
              }}
              useResizeHandler={true}
              config={{ displayModeBar: false }}
              onRelayout={this.onRelayout.bind(this)}
              // onHover={this.props.onHoverPanel}
              // onUnhover={this.props.onUnHoverPanel}
              ref={this.plotRef}
              divId={`plotly-graph-div-${this.props.panelMeta.id}`}
            />
          )}
        </UserContext.Consumer>
      );
    } else {
      return <div className="panel-no-data">No Data</div>;
    }
  }
}
