import React from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

type ActionButtonProps = {
  onClick: () => void;
  label: string;
  icon?: any;
  selected?: boolean;
  borderColor?: string;
  borderRadius?: string;
  marginLeft?: string;
  marginBottom?: string;
  padding?: string;
};

const ActionButtonWrapper = styled.button<{
  selected: boolean;
  borderRadius: string;
  marginLeft: string;
  marginBottom: string;
  padding: string;
  borderColor?: string;
}>`
  cursor: pointer;
  transition: all 200ms;
  background-color: ${(props) =>
    props.selected
      ? props.theme.colors["add-button-border-color"]
      : "transparent"};
  border: 1px solid
    ${(props) =>
      props.borderColor
        ? props.borderColor
        : props.theme.colors["add-button-border-color"]};
  border-radius: ${(props) => props.borderRadius};
  padding: ${(props) => props.padding};
  color: ${(props) =>
    props.selected ? props.theme.colors["body"] : props.theme.colors["text"]};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${(props) => props.marginLeft};
  margin-bottom: ${(props) => props.marginBottom};
  width: fit-content !important;
  height: 35px;
  white-space: nowrap;
`;

export default function ActionButton(props: ActionButtonProps) {
  return (
    <ActionButtonWrapper
      onClick={() => props.onClick()}
      selected={props.selected ? props.selected : false}
      borderColor={props.borderColor}
      borderRadius={props.borderRadius ? props.borderRadius : "17px"}
      marginLeft={props.marginLeft ? props.marginLeft : "20px"}
      marginBottom={props.marginBottom ? props.marginBottom : "0px"}
      padding={props.padding ? props.padding : "0.5rem 1.5rem"}
    >
      {props.icon &&
        (typeof props.icon !== "object" ? (
          <Icon
            name={props.icon}
            style={{ position: "relative", bottom: "1px", marginRight: "10px" }}
            // need to update color to as the same as used in border and text
          />
        ) : (
          <div
            style={{
              marginRight: "10px",
              marginTop: "5px",
            }}
          >
            {props.icon}
          </div>
        ))}
      {props.label}
    </ActionButtonWrapper>
  );
}
