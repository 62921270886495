import moment from "moment";
import React from "react";
import styled from "styled-components";

interface ActionsMoreInfoCardProps {
  readonly actionType: string;
  readonly actionID: number;
  readonly actionName?: string;
  readonly createdAt: Date;
  readonly scheduledAt: Date | number | undefined;
}

const ActionMoreInfoCardStyle = styled.div`
  padding: 0px 10px;
  border-radius: 10px;
  width: 100%;

  .action-row {
    display: flex;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .action-label {
    font-weight: bold;
    width: 120px;
    text-align: left;
    margin-right: 10px;
  }

  .action-value {
    text-align: left;
    white-space: nowrap;
    flex: 1;
  }
`;

const ActionsMoreInfoCard = (props: ActionsMoreInfoCardProps) => {
  const { actionType, actionID, actionName, createdAt, scheduledAt } = props;
  return (
    <ActionMoreInfoCardStyle>
      <div className="action-row">
        <span className="action-label">Action Type</span>
        <span className="action-value">: {actionType}</span>
      </div>
      <div className="action-row">
        <span className="action-label">Action ID</span>
        <span className="action-value">: {actionID}</span>
      </div>
      {false && (
        <div className="action-row">
          <span className="action-label">Action Name</span>
          <span className="action-value">: {actionName ?? "--"}</span>
        </div>
      )}
      <div className="action-row">
        <span className="action-label">Created At</span>
        <span className="action-value">
          : {moment(createdAt).format("ddd, MMM Do YYYY, HH:mm:ss")}
        </span>
      </div>
      {scheduledAt && (
        <div className="action-row">
          <span className="action-label">Scheduled At</span>
          <span className="action-value">
            : {moment(scheduledAt).format("ddd, MMM Do YYYY, HH:mm:ss")}
          </span>
        </div>
      )}
    </ActionMoreInfoCardStyle>
  );
};

export default ActionsMoreInfoCard;
