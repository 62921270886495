import React from "react";
// Add default Height and width in panelDef to show in preview..
import { Button } from "semantic-ui-react";

import { PanelMetaData, PanelDef } from "./PanelDef";
import { EditPanelWidget } from "./EditPanelWidget";
import { DashboardsInfo } from "../ViewDashboard";
import { Settings } from "../../../../util";
import { FetchParams } from "./util";
import styled from "styled-components";
import { capitalizeFirstLetter } from "../../util";
import { TableInfo } from "../../../../BytebeamClient";
import { DashboardMeta } from "../EditDashboardModal";

type EditPanelModalProps<MetaDataType extends PanelMetaData, DataType> = {
  panelDef: PanelDef<MetaDataType, DataType>;
  onCancel: () => void;
  onSubmit: (meta: MetaDataType) => void;
  panelMeta: MetaDataType;
  fetchParams: FetchParams;
  tables: TableInfo;
  settings: Settings;
  dashboards: DashboardsInfo[];
  dashboardMeta: DashboardMeta;
};

const PanelHeader = styled.div`
  font-size: large;
  margin: 20px;
`;
const PanelFooter = styled.div`
  display: flex;
  justify-content: right;
  align-items: top;
`;
export class EditPanelModal<
  MetaDataType extends PanelMetaData,
  DataType
> extends React.Component<EditPanelModalProps<MetaDataType, DataType>> {
  editPanelWidgetRef =
    React.createRef<EditPanelWidget<MetaDataType, DataType>>();

  constructor(props) {
    super(props);

    this.state = {
      panelMeta: props.panelMeta,
    };
  }

  onSubmit() {
    const editWidget = this.editPanelWidgetRef.current;

    if (editWidget) {
      const result = editWidget.getPanelMeta('submit');
      if (result) {
        if (!result.complete) {
          window.toastr.error("Please enter value for mandatory fields.");
          return;
        }
        this.props.onSubmit(result.meta);
      }
    }
  }

  render() {
    return (
      <div className="dark" style={{ width: "100%" }}>
        <PanelHeader>
          Edit {capitalizeFirstLetter(this.props.panelMeta.type)}
        </PanelHeader>
        <div>
          <EditPanelWidget
            ref={this.editPanelWidgetRef}
            panelDef={this.props.panelDef}
            panelMeta={this.props.panelMeta}
            fetchParams={this.props.fetchParams}
            tables={this.props.tables}
            settings={this.props.settings}
            dashboards={this.props.dashboards}
            dashboardMeta={this.props.dashboardMeta}
          />
        </div>
        <PanelFooter>
          <Button secondary onClick={this.props.onCancel}>
            Cancel
          </Button>
          <Button primary onClick={this.onSubmit.bind(this)}>
            Submit
          </Button>
        </PanelFooter>
      </div>
    );
  }
}
