import { MicelioAlertsPanelData, MicelioAlertsPanelMetaData } from "./PanelDef";
import React from "react";
import { PanelViewComponent } from "../PanelDef";
import { Column, Row } from "../../../util";
import { alertIcons } from "../map-util";

import styled from 'styled-components';
import { Button, Divider, Pagination } from "semantic-ui-react";
import moment from "moment";

import { fetchPanelData, getTenantFromURL } from "../../../../../BytebeamClient";

const AlertsContainer = styled(Row)`
  margin-top: 50px;
  height: calc(100% - 50px);
  overflow: scroll;
`

const AlertIcon = styled.img`
  width: 50px;
  padding: 10px;
`

const AlertTabContent = styled(Column)`
  flex-grow: 1;
`

const AlertTabContentBody = styled(Column)`
  flex-grow: 1;
  padding: 20px;
  padding-top: 0px;
  background-color: #c1c6cb;
  color: black;
`

const AlertItems = styled(Column)`
  max-height: calc(100% - 50px);
  overflow-y: scroll;
`

const AlertTabs = styled(Column)`
  max-width: 50px;
  margin-left: 5px;
  /* border-right: 1px solid #D0D2E3; */
`

const AlertTitle = styled(Row)`
  justify-content: space-between;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
`

const AlertTabContainer = styled(Column)`
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
  cursor: pointer;
`

const AllAlertsButton = styled(Button)`
  margin-top: 15px !important;
  max-width: 150px;
  align-self: flex-end;
`

type AlertTabProps = {
  icon: {light: string, dark: string};
  isActive: boolean;
  numAlerts: number;

  setAsActiveTab: () => void;
}

function AlertTab(props: AlertTabProps) {
  const backgroundColor = props.isActive ? "#C1C6CB" : "#232526" ;
  const color = props.isActive ? "black" : "#D6D6D6";


  const icon = props.isActive ? props.icon.dark  : props.icon.light;

  return <AlertTabContainer 
      onClick={() => props.setAsActiveTab()}
      style={{backgroundColor: backgroundColor,  color: color}}>
    <AlertIcon 
      src={icon}
    />
    <div>
       {props.numAlerts}
    </div>
  </AlertTabContainer>
}

type AlertItemProps = {
  deviceDashboardId?: string;
  deviceId: number;
  startTimestamp: Date;
  endTimestamp: Date;
  registrationNumber: string;
  alertSubType: string;
  hub: string;
}

const AlertItemContainer = styled.div`
  font-size: 12px;
`

const AlertsTitle = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  margin-left: 5px;
`

const AlertDetail = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 15px;
`

const VehicleDetail = styled(Row)`
  margin-top: 5px;
  margin-left: 15px;
  margin-right: 15px;
  justify-content: space-between;
`


function AlertItem(props: AlertItemProps) {
  const isAlertClosed = props.endTimestamp.getTime() < new Date().getTime();

  let url = "";

  const currentTenant = getTenantFromURL();
  if(props.deviceDashboardId) {
    url = `/projects/${currentTenant}/dashboards/${props.deviceDashboardId}?id=${props.deviceId}`;
  }

  return <AlertItemContainer>
    <AlertsTitle>
      - {moment(props.startTimestamp).format("DD-MM-YY HH:mm")} 
      
      {
        isAlertClosed ? <span>&nbsp;to {moment(props.endTimestamp).format("DD-MM-YY HH:mm")} </span> : <></> 
      }
      
    </AlertsTitle>

    <AlertDetail>{props.registrationNumber}: {props.alertSubType}</AlertDetail>
    <VehicleDetail>
      <div>{props.hub}</div>
      <div>
        {
          url ? <a href={url}>Locate Vehicle</a> : <></>
        }
      </div>
    </VehicleDetail>
    <Divider style={{backgroundColor: "rgba(0, 0, 0, 0.25)"}}/>
  </AlertItemContainer>
}

export class ViewMicelioAlertsPanel extends PanelViewComponent<MicelioAlertsPanelMetaData, MicelioAlertsPanelData>  {
  state = {
    activeTab: 0,
    data: null,
    activePage: 1,
  }

  setActiveTab(n: number) {
    this.fetchAlerts(1, n)
  }

  async fetchAlerts(pageNumber: number, activeTab: number) {
    const alertTypes = Object.keys(alertIcons);
    const alertType = activeTab === -1 ? "All" : alertTypes[activeTab];

    const panelMeta = Object.assign({}, this.props.panelMeta, {page: pageNumber, alertType: alertType});

    const response = await fetchPanelData([panelMeta], this.props.fetchParams);

    this.setState({data: response[0].data, activePage: pageNumber, activeTab: activeTab})
  };


  render() {
    const alertTypes = Object.keys(alertIcons);
    const activeTab = this.state.activeTab;
    const activeAlertType = activeTab === -1 ? "All" : alertTypes[this.state.activeTab];
    const {alerts, counts}= this.state.data || this.props.data;
    const rowsPerPage = this.props.panelMeta.rowsPerPage || 5;

    return <AlertsContainer>
          <AlertTabs>
            {
              alertTypes.map((alertType, i) => 
                <AlertTab 
                  key={`${alertType}-${i}`}
                  icon={alertIcons[alertType]}
                  isActive={this.state.activeTab === i}
                  numAlerts={counts[alertType]}
                  setAsActiveTab={() => this.setActiveTab(i)}
                />)
            }
            <div style={{flexGrow: 1}} />
          </AlertTabs>

          <AlertTabContent>
            <AlertTabContentBody>
              <AlertTitle>
                <div>{activeAlertType}</div>
                <div>{counts[activeAlertType]}</div>
              </AlertTitle>

              <AlertItems>
                {
                  alerts.map((alert, i) => 
                  <AlertItem
                    key={`${alert.metadata.registration_number}-${i}`}
                    deviceId={alert.id}
                    deviceDashboardId={this.props.panelMeta.deviceDashboardId}
                    startTimestamp={new Date(alert.startTimestamp)}
                    endTimestamp={new Date(alert.endTimestamp)}
                    hub={alert.metadata.hub}
                    registrationNumber={alert.metadata.registration_number}
                    alertSubType={alert.alertSubType}
                  />)
                }
              </AlertItems>
            </AlertTabContentBody>
          
            <Pagination
              activePage={this.state.activePage}
              totalPages={Math.ceil(counts[activeAlertType]/rowsPerPage)}
              onPageChange={(_, data) => this.fetchAlerts(data.activePage as number, activeTab)}
            />

            <AllAlertsButton onClick={() => this.fetchAlerts(1, -1)}>
              All alerts
            </AllAlertsButton>
          </AlertTabContent>
      </AlertsContainer>
  }
}
