import { AlertsMetaData } from "./PanelDef";
import React from "react";

import {
  PanelEditComponent,
  PartialMetaData,
} from "../PanelDef";
import { Tab } from "semantic-ui-react";
import {
  EditAnimatedMetaInput,
  EditMetaRoot,
  EditMetaRow,
  ThinDivider,
  EditPanelFormContainer,
} from "../util";

export type EditAlertsMetaState = {
};

export class EditAlertsMeta extends PanelEditComponent<
  AlertsMetaData,
  EditAlertsMetaState
> {
  titleRef = React.createRef<HTMLInputElement>();
  descriptionRef = React.createRef<HTMLInputElement>();

  getPanelMeta(): PartialMetaData<AlertsMetaData> {
    return {
      meta: {
        type: "alerts",
        id: this.props.panelMeta.id,
        title: this.titleRef.current?.value || "",
        description: this.descriptionRef.current?.value || "",
      },
      complete: true,
    };
  }

  render() {
    const title = this.props.panelMeta.title;
    const description = this.props.panelMeta.description;

    const panes = [
      {
        menuItem: "General",
        pane: (
          <Tab.Pane key={"general"}>
            <EditPanelFormContainer>
              <div style={{ width: "100%", marginTop: "16px" }} />
              <EditMetaRow>
                <div style={{ width: "48%" }}>
                  <EditAnimatedMetaInput
                    defaultRef={this.titleRef}
                    defaultValue={title}
                    label="Title"
                  />
                </div>
                <div style={{ width: "48%" }}>
                  <EditAnimatedMetaInput
                    defaultRef={this.descriptionRef}
                    defaultValue={description}
                    label="Description"
                  />
                </div>
              </EditMetaRow>
              <ThinDivider />
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <EditMetaRoot>
        <Tab menu={{}} panes={panes} renderActiveOnly={false} />
      </EditMetaRoot>
    );
  }
}
