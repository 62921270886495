import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { CreateActionContainer, StyledNonBoldHeader } from "./NewAction";
import Toggle from "../../../../common/Toggle";
import { CardContainer } from "../LiveActions";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-markdown";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/theme-chaos";
import ThemeSchema from "../../../../../theme/schema";
import { useUser } from "../../../../../context/User.context";
import { StyledHeader } from "../SelectableItem";
import { EditAnimatedMetaDropdown } from "../../../Dashboards/Panel/util";

const AdvancedSectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 64px;
  margin-left: 24px;
`;

const AdvancedSectionItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
`;

const Header = styled(StyledHeader)`
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: normal;
  white-space: nowrap;
`;

type AdvanceSettingsSectionProps = {
  readonly endTimestamp: Date | number;
  readonly releaseNotes: string;
  readonly setEndTimestamp: (endTimestamp: Date | number) => void;
  readonly setReleaseNotes: (releaseNotes: string) => void;
  readonly setRetryUntil: (retryUntil: Date | number) => void;
};

function AdvanceSettingsSection({
  endTimestamp,
  releaseNotes,
  setEndTimestamp,
  setReleaseNotes,
  setRetryUntil,
}: AdvanceSettingsSectionProps) {
  const { user } = useUser();
  const theme = user?.settings?.theme ?? "dark";

  const [autoRetry, setAutoRetry] = useState<boolean>(false);
  const [isEnabledAdvancedSettings, setIsEnabledAdvancedSettings] =
    useState<boolean>(false);

  const timeOutAfterOptions = useMemo(() => {
    const currentTime = new Date();
    const oneDayInMs = 24 * 60 * 60 * 1000; // milliseconds in a day
    // setting never to timeout after 10 years
    const neverAdvancedTimestamp = new Date(
      currentTime.setFullYear(currentTime.getFullYear() + 10)
    ).getTime();

    return [
      {
        key: "1 day",
        text: "1 day",
        value: currentTime.getTime() + oneDayInMs,
      },
      {
        key: "5 days",
        text: "5 days",
        value: currentTime.getTime() + 5 * oneDayInMs,
      },
      {
        key: "30 days",
        text: "30 days",
        value: currentTime.getTime() + 30 * oneDayInMs,
      },
      { key: "Never", text: "Never", value: neverAdvancedTimestamp },
    ];
  }, []);

  const handleToggleChange = () => {
    const newAutoRetry = !autoRetry;
    setAutoRetry(newAutoRetry);
    if (!newAutoRetry) {
      setRetryUntil(0);
    }
  };

  const handleDropdownChange = (e, data) => {
    const value = data.value as Date | number;
    setEndTimestamp(value);
    if (autoRetry) {
      setRetryUntil(value);
    }
  };

  return (
    <CreateActionContainer>
      <CardContainer marginBottom="5px">
        <AdvancedSectionContainer style={{ marginLeft: "0px" }}>
          <AdvancedSectionItem>
            <Header as="h3">Advanced Settings</Header>
            <Toggle
              id="action-toggle"
              size="large"
              bgColor="#05DB0A"
              checked={isEnabledAdvancedSettings}
              onChange={() =>
                setIsEnabledAdvancedSettings(!isEnabledAdvancedSettings)
              }
            />
          </AdvancedSectionItem>
        </AdvancedSectionContainer>

        {isEnabledAdvancedSettings && (
          <>
            <AdvancedSectionContainer style={{ marginTop: "42px" }}>
              <AdvancedSectionItem>
                <StyledNonBoldHeader as="h3" style={{ marginBottom: "0px" }}>
                  Auto Retry
                </StyledNonBoldHeader>
                <Toggle
                  id="action-toggle"
                  size="small"
                  bgColor="#05DB0A"
                  onChange={handleToggleChange}
                  checked={autoRetry}
                />
              </AdvancedSectionItem>
              <AdvancedSectionItem>
                <StyledNonBoldHeader as="h3" style={{ marginBottom: "0px" }}>
                  Timeout After
                </StyledNonBoldHeader>
                <EditAnimatedMetaDropdown
                  elementid="time-out-after"
                  placeholder="Select Period"
                  selection
                  options={timeOutAfterOptions}
                  value={endTimestamp as number}
                  onChange={handleDropdownChange}
                  labelBg={
                    ThemeSchema.data[theme]?.colors[
                      "action-card-container-background"
                    ]
                  }
                />
              </AdvancedSectionItem>
            </AdvancedSectionContainer>

            <AdvancedSectionContainer>
              <AdvancedSectionItem
                style={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  marginTop: "18px",
                }}
              >
                <StyledNonBoldHeader as="h3" style={{ marginBottom: "0px" }}>
                  Release notes
                </StyledNonBoldHeader>
                <AceEditor
                  height="300px"
                  width="800px"
                  placeholder="Enter Release Notes"
                  mode="markdown"
                  theme={ThemeSchema.data[theme]?.colors["ace-editor-theme"]}
                  name="release-notes"
                  onChange={setReleaseNotes}
                  value={releaseNotes}
                  fontSize={14}
                  showPrintMargin={false}
                  showGutter={true}
                  highlightActiveLine={true}
                  setOptions={{
                    enableBasicAutocompletion: false,
                    enableLiveAutocompletion: false,
                    enableSnippets: false,
                    showLineNumbers: true,
                    tabSize: 2,
                  }}
                  style={{
                    marginBottom: "16px",
                    borderRadius: "4px",
                    border: `${ThemeSchema.data[theme]?.colors["ace-editor-border"]}`,
                    boxShadow: `${ThemeSchema.data[theme]?.colors["ace-editor-box-shadow"]}`,
                  }}
                />
              </AdvancedSectionItem>
            </AdvancedSectionContainer>
          </>
        )}
      </CardContainer>
    </CreateActionContainer>
  );
}

export default AdvanceSettingsSection;
