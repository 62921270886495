import { StaticTextData, StaticTextMetaData } from "./PanelDef";
import React from "react";
import { PanelViewComponent } from "../PanelDef";
import styled from "styled-components";
import { UserContext } from "../../../../../context/User.context";

const OverflowDiv = styled.div`
  .big-number-value {
    word-break: break-word;
  }

  .big-number-value span {
    padding: 10px;
    overflow-y: auto;
    display: inline-block;
  }
`;

export class ViewStaticText extends PanelViewComponent<
  StaticTextMetaData,
  StaticTextData
> {
  ref = React.createRef<HTMLDivElement>();

  canvas = document.createElement("canvas");

  getTextWidth(text, font) {
    var context = this.canvas.getContext("2d");
    if (context) {
      context.font = font;

      var metrics = context.measureText(text);
      return metrics.width;
    }

    return 0;
  }

  getText() {
    return this.props.panelMeta.text;
  }

  getTextColor(theme: string | undefined) {
    if (theme === "light" && this.props.panelMeta.textColor === "#FFF") {
      return "#939493";
    } else {
      return this.props.panelMeta.textColor;
    }
  }

  componentDidMount() {}

  render() {
    return (
      <UserContext.Consumer>
        {(userContext) => (
          <OverflowDiv className="big-number-root" ref={this.ref}>
            <div className="big-number-value">
              <span
                style={{
                  fontSize: this.props.panelMeta.textSize,
                  color: this.getTextColor(userContext?.user?.settings?.theme),
                  fontStyle: this.props.panelMeta.textStyle,
                  lineHeight: "normal",
                  textShadow: "0.1px 0.1px black",
                }}
              >
                {this.getText()}
              </span>
            </div>
          </OverflowDiv>
        )}
      </UserContext.Consumer>
    );
  }
}
