import React, { Component } from "react";
import { updateDeviceMetadata } from "../../../../BytebeamClient";
import { DisplayIf, ButtonIcon } from "../../util";
import { Input } from "semantic-ui-react";
import styled from "styled-components";
import TextWithToolTip from "./TextWithToolTip";

type MetadataCellProps = {
  id: string | number;
  keyName: string;
  text: string;
  editable: boolean;
};

type MetadataCellState = {
  state: string;
  text: string;
};

export const StyledIcon = styled(ButtonIcon)`
  margin-left: 12px !important;
`;

const StyledInput = styled.input`
  max-width: 96px !important;
  padding: 0px 8px !important;
  background-color: transparent !important;
  color: ${({ theme }) => theme.colors["text"]} !important;
`;

const MetadataCellContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  flex-grow: 1;
  white-space: nowrap;
`;

export class MetadataCell extends Component<
  MetadataCellProps,
  MetadataCellState
> {
  constructor(props) {
    super(props);

    this.state = {
      state: "view",
      text: props.text,
    };
  }

  async updateMetadata() {
    this.setState({ state: "updating" });

    const requestBody = {
      id: this.props.id,
      key: this.props.keyName,
      value: this.state.text.trim(),
    };

    await updateDeviceMetadata(requestBody);

    this.setState({ state: "view" });
  }

  handleKeyDown(e) {
    if (e.key === "Enter") this.updateMetadata();
    if (e.key === "Escape")
      this.setState({ state: "view", text: this.props.text });
  }

  handleChange = (e) => {
    this.setState({ text: e.target.value });
  };

  render() {
    if (this.state.state === "view") {
      return (
        <MetadataCellContainer className="metadata-cell-container">
          <TextWithToolTip text={this.state.text} length={10} />
          <DisplayIf cond={this.props.editable}>
            <StyledIcon
              id={`${this.props.id}-edit-${this.props.keyName}`}
              name="pencil"
              className="metadata-cell-icon"
              onClick={() => {
                this.setState({ state: "edit" });
              }}
            />
          </DisplayIf>
        </MetadataCellContainer>
      );
    }
    if (this.state.state === "edit") {
      return (
        <MetadataCellContainer>
          <Input focus style={{ borderWidth: "1px" }}>
            <StyledInput
              id={`${this.props.id}-input-${this.props.keyName}`}
              autoFocus
              onKeyDown={(e) => this.handleKeyDown(e)}
              value={this.state.text}
              onChange={this.handleChange}
            />
          </Input>
          <StyledIcon
            id={`${this.props.id}-submit-${this.props.keyName}`}
            name="check"
            onClick={() => this.updateMetadata()}
          />
        </MetadataCellContainer>
      );
    }

    if (this.state.state === "updating") {
      return (
        <MetadataCellContainer>
          <TextWithToolTip text={this.state.text} length={10} />
          <StyledIcon name="refresh" loading />
        </MetadataCellContainer>
      );
    }
  }
}
