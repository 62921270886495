import React, { useState, useEffect } from "react";
import { Icon } from "semantic-ui-react";
import {
  Route,
  Switch,
  Link,
  useLocation,
  RouteComponentProps,
  useRouteMatch,
  Redirect,
} from "react-router-dom";

import { DisplayIf } from "../util";
import ActionStatusList from "../DeviceMangaement/actionStatus";
import InventoryManagement from "./Inventory/InventoryManagement";
import ActionOverview from "./ActionOverview";
import DeviceOverview from "./DeviceOverview";
import { MenuTabLink } from "../DeviceMangaement/deviceManagement";
import { useUser } from "../../../context/User.context";

interface ActionProps extends RouteComponentProps {
  currentTenant: string;
}

function Actions(props: ActionProps) {
  const { user } = useUser();
  const { permissions } = user.role;
  const { path } = useRouteMatch();
  const location = useLocation();

  const [activeItem, setActiveItem] = useState<string>("actions summary");

  const handleItemClick = (name) => {
    setActiveItem(name);
  };

  const menuTab = (name, icon, to, shouldDisplay) => {
    return (
      <DisplayIf cond={shouldDisplay}>
        <Link to={to}>
          <MenuTabLink
            active={activeItem === name}
            onClick={() => {
              handleItemClick(name);
            }}
          >
            <Icon className={icon} />
            <p>{name}</p>
          </MenuTabLink>
        </Link>
      </DisplayIf>
    );
  };

  useEffect(() => {
    let activeItem: string;

    switch (location.pathname) {
      case `${path}/action-summary`:
        activeItem = "actions summary";
        break;

      case `${path}/inventory`:
        activeItem = "inventory";
        break;

      case `${path}/inventory/firmware-files`:
        activeItem = "inventory";
        break;

      case `${path}/inventory/json-configurations`:
        activeItem = "inventory";
        break;

      case `${path}/inventory/geofence-configurations`:
        activeItem = "inventory";
        break;

      case `${path}/action-settings`:
        activeItem = "settings";
        break;

      default:
        activeItem = "actions summary";
        break;
    }

    handleItemClick(activeItem);
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div
        className="desktop-view"
        style={{ display: "flex", alignItems: "center", gap: 10 }}
      >
        {menuTab(
          "actions summary",
          "wait",
          `${path}/action-summary`,
          permissions.allowedActions?.length > 0 || false
        )}
        {menuTab(
          "inventory",
          "boxes",
          `${path}/inventory`,
          permissions.viewFirmwares || permissions.viewDeviceConfigs || false
        )}
      </div>

      <Switch>
        <Route exact path={`${path}/actions`}>
          <DisplayIf cond={permissions.viewActionTypes}>
            <ActionStatusList user={user} />
          </DisplayIf>
        </Route>

        <Route exact path={`${path}/action-summary`}>
          <DisplayIf cond={permissions.viewActionTypes}>
            <ActionStatusList user={user} />
          </DisplayIf>
        </Route>

        <Route path={`${path}/inventory`}>
          <DisplayIf
            cond={permissions.viewFirmwares || permissions.viewDeviceConfigs}
          >
            <InventoryManagement />
          </DisplayIf>
        </Route>

        {/* <Route exact path={`${path}/action-settings`}>
          <DisplayIf
            cond={true} // for now
          >
            <ActionSettings />
          </DisplayIf>
        </Route> */}

        <Route exact path={`${path}/:actionID/action-overview`}>
          <DisplayIf cond={permissions.allowedActions?.length > 0}>
            <ActionOverview user={user} />
          </DisplayIf>
        </Route>

        <Route exact path={`${path}/:actionID/device-overview/:deviceID`}>
          <DisplayIf cond={permissions.allowedActions?.length > 0}>
            <DeviceOverview user={user} />
          </DisplayIf>
        </Route>
        <Redirect exact from={path} to={`${path}/action-summary`} />
      </Switch>
    </>
  );
}

export default Actions;
