import uuid from "uuid";
import { PanelDef } from "../PanelDef";
import { ViewLastValue } from "./ViewLastValue";
import { EditLastValueMeta } from "./EditLastValueMeta";

export type LastValueColumn = {
  name: string;
  prefix: string;
  suffix: string;
};

export type Query = {
  column: string;
  operator: string;
  value: string;
};
export class LastValueMetaData {
  type = "last_value";
  table: string = "";
  columns: Array<LastValueColumn> = [];
  id: string = uuid.v4();
  title: string = "";
  description: string = "";
  query: Query[] = [];
  textSize: number = 50;
  autoTextSize: boolean = true;
}

export type TimestampedValue = {
  timestamp?: number;
  column: string;
  value: any;
  id?: string;
};

export type LastValueData = {
  data: TimestampedValue[];
};

export const LastValuePanelDef: PanelDef<LastValueMetaData, LastValueData> = {
  ViewComponent: ViewLastValue,
  EditComponent: EditLastValueMeta,
  formatData: (data) => data,
  metaConstructor: LastValueMetaData,
  previewAspectRatio: { width: 500, height: 300 },
};
