import React from "react";
import { Popup } from "semantic-ui-react"; // Import Popup from your UI library
import { copyContentToClipboard } from "../../util";
import styled from "styled-components";

const SlicedTextSpan = styled.span<{ notBold?: boolean }>`
  position: relative;
  display: inline-block;
  word-break: break-word;
  white-space: wrap;
  font-weight: ${({ notBold }) => (notBold ? "normal" : "bolder")} !important;
`;

function toSliceText(text: string, length: number): [boolean, string] {
  let sliced = false,
    slicedText = text;

  if (typeof text === "string" || typeof text === "number") {
    slicedText = String(text);
    if (slicedText?.length > length) {
      slicedText = slicedText.substring(0, length);
      sliced = true;
    }
  }
  return [sliced, slicedText];
}

type SliceTextType = {
  readonly text: string;
  readonly length: number;
  readonly notBold?: boolean;
};

function SlicedTextPopUp(props: SliceTextType) {
  const { text, length, notBold } = props;
  const [sliced, slicedText] = toSliceText(text, length);

  if (!text) {
    return <SlicedTextSpan style={{ cursor: "default" }}>--</SlicedTextSpan>;
  }

  return (
    <Popup
      disabled={!sliced}
      wide
      content={
        <div>
          <SlicedTextSpan notBold={notBold}>{text}</SlicedTextSpan>
        </div>
      }
      inverted
      position="top center"
      trigger={
        <SlicedTextSpan
          notBold={notBold}
          onClick={async () => await copyContentToClipboard(text)}
          style={{ cursor: text === "--" ? "default" : "pointer" }}
        >
          {sliced ? `${slicedText}...` : slicedText}
        </SlicedTextSpan>
      }
    />
  );
}

export default SlicedTextPopUp;
