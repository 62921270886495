import React, { useEffect, useState } from "react";
import { Form } from "semantic-ui-react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/theme-chaos";
import { OptionType } from "../util";
import { DropdownOptionType } from "../../DeviceMangaement/Devices/ActionModals/UpdateConfigModal";
import LoadingAnimation from "../../../common/Loader";
import ThemeSchema from "../../../../theme/schema";

type UploadConfigComponentProps = {
  setOptionType: (arg0: OptionType) => void;
  optionType: OptionType;
  uploadTriggered: string;
  uploadTriggeredEach: boolean;
  setActiveModal: (arg0: number) => void;
  activeModal: number;
  customInputJSONData: string;
  setCustomInputJSONData: (arg0: string) => void;
  configOptions: DropdownOptionType[];
  allConfigOptions: Set<any> | undefined;
  selectedCustomConfigVersion: string;
  setSelectedCustomConfigVersion: (arg0: string) => void;
  theme: string;
};

export function UploadConfigComponent(props: UploadConfigComponentProps) {
  const { theme } = props;

  const [errors, setErrors] = useState<{ versionName: string | null }>();

  const isJSONValid = (str: string) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (props.selectedCustomConfigVersion.length === 0) {
      setErrors({
        versionName: "Please Enter a Version name",
      });
    } else if (props.allConfigOptions) {
      if (props.allConfigOptions.has(props.selectedCustomConfigVersion)) {
        setErrors({
          versionName: "This device config version already exists",
        });
      }
    }
    if (!isJSONValid(props.customInputJSONData)) {
      window.toastr.error("Your Configuration JSON is invalid");
    }
    if (
      props.selectedCustomConfigVersion.length !== 0 &&
      !props.allConfigOptions?.has(props.selectedCustomConfigVersion) &&
      isJSONValid(props.customInputJSONData)
    ) {
      // valid data, move ahead
      props.setActiveModal(props.activeModal + 1);
    }
  };

  useEffect(() => {
    if (props.uploadTriggered === "trigger") {
      switch (props.optionType) {
        case OptionType.UploadConfig:
          handleSubmit();
          break;
      }
    }
  }, [props.uploadTriggered, props.uploadTriggeredEach]); // eslint-disable-line react-hooks/exhaustive-deps

  return props.allConfigOptions && props.configOptions ? (
    <Form className="sendfile-form">
      <Form.Field>
        <label>Version Name</label>
        <Form.Input
          error={errors ? errors.versionName : undefined}
          placeholder={"production v1"}
          value={props.selectedCustomConfigVersion}
          onChange={(e) => {
            props.setOptionType(OptionType.UploadConfig);
            props.setSelectedCustomConfigVersion(e.target.value);
            setErrors({
              versionName: null,
            });
          }}
        />
      </Form.Field>
      <Form.Field>
        <label>Configuration JSON</label>
        <AceEditor
          height="300px"
          width="600px"
          placeholder={"Enter Configuration JSON"}
          mode="json"
          theme={ThemeSchema.data[theme ?? 'dark']?.colors["ace-editor-theme"]}
          name="config-json"
          onChange={(val) => {
            props.setOptionType(OptionType.UploadConfig);
            props.setCustomInputJSONData(val);
          }}
          fontSize={14}
          value={props.customInputJSONData}
          showPrintMargin={false}
          showGutter={true}
          highlightActiveLine={true}
          setOptions={{
            enableBasicAutocompletion: false,
            enableLiveAutocompletion: false,
            enableSnippets: false,
            showLineNumbers: true,
            tabSize: 2,
          }}
          style={{
            marginBottom: "16px",
            borderRadius: "4px",
            border: `${ThemeSchema.data[theme ?? "dark"]?.colors["ace-editor-border"]}`,
            boxShadow: `${ThemeSchema.data[theme ?? "dark"]?.colors["ace-editor-box-shadow"]}`,
          }}
        />
      </Form.Field>
    </Form>
  ) : (
    <LoadingAnimation
      loaderContainerHeight="350px"
      loadingText="Loading"
      fontSize="20px"
    />
  );
}
