import { TimeRange } from "../Datetime/TimeRange";
import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownItem,
  Icon,
  Modal,
  Popup,
} from "semantic-ui-react";
import { PanelMetaData, PanelDef } from "./PanelDef";
import { ReplayState } from "../DashboardHeader";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {
  TableInfo,
  fetchStreamTableFields,
  getTenantFromURL,
} from "../../../../BytebeamClient";
import * as uuid from "uuid";
import { FetchParams } from "./util";
import { Settings } from "../../../../util";
import { DashboardsInfo } from "../ViewDashboard";
import LoadingAnimation from "../../../common/Loader";
import styled from "styled-components";
import { objectToQuery } from "../../common/QuerySelector";

const StyledButton = styled(Button)`
  background: ${({ theme }) => theme.colors["panel-background"]} !important;
  border-radius: 0px 9px 0px 0px !important;
  padding: 15px !important;
  border: none !important;
  .icon.ellipsis {
    color: ${({ theme }) => theme.colors["panel-title"]} !important;
  }
`;

type DownloadPanelDataModalProps = {
  readonly panelMeta: PanelMetaData;
  readonly fetchParams: FetchParams;
  readonly mobileView?: boolean;
};

function DownloadPanelDataModal(props: DownloadPanelDataModalProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [format, setFormat] = useState<string>("csv");
  const [columns, setColumns] = useState<string[]>([]);

  const downloadRequestBody = (format: string) => {
    const panel = { ...props.panelMeta };
    const { timeRange, filterBys, groupBys } = props.fetchParams;

    const startTime = Math.round(timeRange.getStartTime().toDate().valueOf());
    const endTime = Math.round(timeRange.getEndTime().toDate().valueOf());

    const aggregationInterval = Math.max(
      Math.round((endTime - startTime) / 200),
      1
    );
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    document.cookie = `x-bytebeam-tenant=${getTenantFromURL()}`;

    // For filter query, converting UI object to HoneySql query
    if (
      props.panelMeta.query &&
      props.panelMeta.query[0] !== "and" &&
      props.panelMeta.query.length > 0
    ) {
      panel.query = objectToQuery(props.panelMeta.query);
    } else if (props.panelMeta.query?.length === 0) {
      delete panel["query"];
    }

    // For logs panel, converting to timeseries_table to get complete data in given time range
    if (props.panelMeta.type === "logs") {
      panel.type = "timeseries_table";
      panel.columns = columns;
      // panel.columns = ["level", "message", "tag"];
    }

    // filterBys Cleanup due to state param begin appended from url
    if (filterBys.state) {
      delete filterBys.state;
    }

    return {
      panel,
      startTime,
      endTime,
      filterBys,
      groupBys,
      aggregationInterval,
      timezone,
      format,
    };
  };

  const formatOptions = [
    { key: "xlsx", text: "XLSX", value: "xlsx" },
    { key: "csv", text: "CSV", value: "csv" },
  ];

  useEffect(() => {
    async function setColumnsForLogs() {
      try {
        const tableName: string = props.panelMeta?.table as string;
        let res = await fetchStreamTableFields(tableName);
        let logsColumns = Object.keys(res.result).filter(
          (column) => column !== "timestamp"
        );
        setColumns(logsColumns);
      } catch (error) {
        console.log(error);
      }
    }
    if (props.panelMeta.type === "logs") {
      // this is done to fetch columns for downloading logs panel.
      setColumnsForLogs();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      closeIcon
      size="mini"
      className="dark"
      closeOnDimmerClick={false}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      open={isOpen}
      trigger={
        props.mobileView ? (
          <DropdownItem className="mob-view">
            <Icon name="download" />
            Download
          </DropdownItem>
        ) : (
          <span className="bytebeam-panel-icon desktop-view">
            <Popup
              content="Download panel data"
              position="top center"
              inverted
              trigger={<Icon name="download" link />}
            />
          </span>
        )
      }
    >
      <Modal.Header>Download Panel Data</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>
            Download panel data in your preferred format. Choose between XLSX
            and CSV formats.
          </p>
          <form
            style={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "18px",
            }}
            method="post"
            action={`/api/v1/panel-data-csv?tenant=${getTenantFromURL()}`}
          >
            <Dropdown
              placeholder="Select Format"
              fluid
              selection
              options={formatOptions}
              value={format}
              onChange={(_, data) => setFormat(data.value as string)}
            />
            <input
              type="hidden"
              name="json"
              value={JSON.stringify(downloadRequestBody(format))}
            />
            <Button type="submit" secondary icon>
              <Icon name="download" link />
            </Button>
          </form>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}

type PanelContainerProps<MetaDataType extends PanelMetaData, DataType> = {
  panelMeta: MetaDataType;
  panelDef: PanelDef<MetaDataType, DataType>;
  loading?: boolean;
  error?: boolean;
  onTimeRangeChange?: (timeRange: TimeRange) => void;
  timeRange: TimeRange;
  data?: DataType;
  onEdit?: (panelId: string) => void;
  onClone?: (panelId: string) => void;
  onDelete?: (panelId: string) => void;
  onRefresh?: (panelId: string) => void;
  replayStep: number;
  replayTimestamp: number;
  replayState: ReplayState;
  fullPage?: boolean;
  onToggleFullPage?: (panelId: string) => void;
  editable: boolean;
  fetchParams: FetchParams;
  settings: Settings;
  dashboards: DashboardsInfo[];
  tables: TableInfo;
  // onHoverPanel?: (event: PlotMouseEvent) => void;
  // onUnHoverPanel?: () => void;
  // hoverPointX?: any;
  // hover?:boolean;
};

export function PanelContainer<MetaDataType extends PanelMetaData, DataType>(
  props: PanelContainerProps<MetaDataType, DataType>
) {
  const [panelKey, setPanelKey] = useState<string>(uuid.v4());
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  const onDelete = () => {
    if (props.onDelete) {
      props.onDelete(props.panelMeta.id);
    }
  };

  const onEdit = () => {
    if (props.onEdit) {
      props.onEdit(props.panelMeta.id);
    }
  };

  const onClone = () => {
    if (props.onClone) {
      props.onClone(props.panelMeta.id);
    }
  };

  const onRefresh = () => {
    if (props.onRefresh) {
      props.onRefresh(props.panelMeta.id);
    }
  };

  const getPanelContent = () => {
    if (props.data) {
      return (
        <div className="bytebeam-panel-content">
          <props.panelDef.ViewComponent
            timeRange={props.timeRange}
            onTimeRangeChange={
              isFullScreen ? () => {} : props.onTimeRangeChange
            }
            panelMeta={props.panelMeta}
            data={props.data}
            replayStep={props.replayStep}
            replayTimestamp={props.replayTimestamp}
            replayState={props.replayState}
            key={panelKey}
            fetchParams={props.fetchParams}
            settings={props.settings}
            dashboards={props.dashboards}
            tables={props.tables}
            // onHoverPanel={props.onHoverPanel}
            // onUnHoverPanel={props.onUnHoverPanel}
            // hoverPointX={props.hoverPointX}
            // hover={props.hover}
          />
          {props.panelMeta.description ? (
            <Popup
              content={props.panelMeta.description}
              position="left center"
              inverted
              trigger={
                <div className="info-icon">
                  <Icon name="info circle" link />
                </div>
              }
            />
          ) : (
            <></>
          )}
        </div>
      );
    } else {
      return <div className="panel-no-data">No Data</div>;
    }
  };

  const fullScreenHandle = useFullScreenHandle();

  const onFullScreenToggle = (state: boolean) => {
    const newPanelKey = uuid.v4();

    setTimeout(() => {
      setPanelKey(newPanelKey);
      setIsFullScreen(state);
    });
  };

  const fullPage = props.fullPage;

  const onToggleFullPage = () => {
    if (props.onToggleFullPage) {
      props.onToggleFullPage(props.panelMeta.id);
    }
  };

  const panelMeta = props.panelMeta || {};
  const expandIcon = fullPage ? "compress" : "expand arrows alternate";
  const isEditable =
    props.editable &&
    !props.fullPage &&
    props.replayState === ReplayState.ReplayStopped;

  const isCompareDashboard = window.location.pathname.includes(
    "/compare-dashboards/"
  );

  return (
    <FullScreen handle={fullScreenHandle} onChange={onFullScreenToggle}>
      <div className="bytebeam-panel">
        <div className="bytebeam-panel-header">
          <div className="bytebeam-panel-title">
            {panelMeta.title || "Untitled"}
          </div>
          {props.loading ? (
            <span className="panel-loader-icon">
              <LoadingAnimation
                loaderSize="15px"
                marginTopText="0px"
                loaderBorderSize="2px"
              />
            </span>
          ) : (
            ""
          )}
          {props.error ? (
            <span className="panel-error-icon">
              <Popup
                content="Failed to fetch panel data, click here to retry"
                position="top center"
                inverted
                trigger={<Icon name="redo" color="red" onClick={onRefresh} />}
              />
            </span>
          ) : (
            ""
          )}
          {!isCompareDashboard && (
            <>
              {!fullScreenHandle.active ? (
                <span className="bytebeam-panel-icons desktop-view">
                  <span className="bytebeam-panel-icon">
                    <Popup
                      content={
                        props.fullPage ? "Exit full page" : "View full page"
                      }
                      position={props.fullPage ? "bottom right" : "top center"}
                      inverted
                      trigger={
                        <Icon
                          name={expandIcon}
                          link
                          onClick={onToggleFullPage}
                        />
                      }
                    />
                  </span>

                  <span className="bytebeam-panel-icon desktop-view">
                    <Popup
                      content="View full screen"
                      position={props.fullPage ? "bottom right" : "top center"}
                      inverted
                      trigger={
                        <Icon
                          name="expand"
                          link
                          onClick={fullScreenHandle.enter}
                        />
                      }
                    />
                  </span>

                  {props.loading ? (
                    <span className="bytebeam-panel-icon">
                      <LoadingAnimation
                        loaderSize="15px"
                        marginTopText="0px"
                        loaderBorderSize="2px"
                      />
                    </span>
                  ) : (
                    ""
                  )}
                  {props.onEdit && isEditable ? (
                    <span className="bytebeam-panel-icon">
                      <Popup
                        content="Edit panel"
                        position="top center"
                        inverted
                        trigger={<Icon name="pencil" link onClick={onEdit} />}
                      />
                    </span>
                  ) : (
                    ""
                  )}

                  {props.onClone && isEditable ? (
                    <span className="bytebeam-panel-icon">
                      <Popup
                        content="Clone panel"
                        position="top center"
                        inverted
                        trigger={<Icon name="clone" link onClick={onClone} />}
                      />
                    </span>
                  ) : (
                    ""
                  )}

                  {props.onDelete && isEditable ? (
                    <span className="bytebeam-panel-icon">
                      <Popup
                        content="Delete panel"
                        position="top center"
                        inverted
                        trigger={<Icon name="trash" link onClick={onDelete} />}
                      />
                    </span>
                  ) : (
                    ""
                  )}

                  {props.panelDef?.download === "server" ? (
                    <DownloadPanelDataModal
                      panelMeta={props.panelMeta}
                      fetchParams={props.fetchParams}
                    />
                  ) : (
                    ""
                  )}
                </span>
              ) : (
                ""
              )}

              {!fullScreenHandle.active ? (
                <span
                  className="bytebeam-panel-icons mob-view"
                  style={{ padding: "0px" }}
                >
                  <Dropdown
                    style={{ border: "none" }}
                    direction="left"
                    trigger={
                      <StyledButton floated="right" icon>
                        <Icon name="ellipsis vertical" />
                      </StyledButton>
                    }
                    icon={null}
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={onToggleFullPage}>
                        <Icon name={expandIcon} />
                        {props.fullPage ? "Exit Full Page" : "View Full Page"}
                      </Dropdown.Item>
                      {props.onEdit && isEditable && (
                        <Dropdown.Item onClick={onEdit}>
                          <Icon name="pencil" />
                          Edit Panel
                        </Dropdown.Item>
                      )}
                      {props.onEdit && isEditable && (
                        <Dropdown.Item onClick={onClone}>
                          <Icon name="clone" />
                          Clone Panel
                        </Dropdown.Item>
                      )}
                      {props.onEdit && isEditable && (
                        <Dropdown.Item onClick={onDelete}>
                          <Icon name="trash" />
                          Delete Panel
                        </Dropdown.Item>
                      )}
                      {props.panelDef?.download === "server" && (
                        <DownloadPanelDataModal
                          panelMeta={props.panelMeta}
                          fetchParams={props.fetchParams}
                          mobileView={true}
                        />
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </span>
              ) : (
                ""
              )}
            </>
          )}
        </div>
        {getPanelContent()}
      </div>
    </FullScreen>
  );
}
