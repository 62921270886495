import React, { useState, useEffect } from "react";
import { Button, Dropdown, DropdownItemProps, Modal } from "semantic-ui-react";
import { fetchAllStreamsWithDetails, FetchStreamsAPIResponse, StreamFieldDetails } from "../../../../BytebeamClient";
import { CompositeCondition, Condition, dropDownOptionsFromArray, SessionType, SimpleCondition } from "../../../../util";
import SqlWhereClauseBuilder from "../../common/SqlWhereClauseBuilder";
import { StyledInput, StyledLabel } from "../actiontypes/ActionTypesModal";

interface CreateSessionTypeModalProps {
  sessionType?: SessionType;
  title: string;
  onSubmit: (sessionType: SessionType) => void;
  trigger: React.ReactNode;
}

export default function CreateSessionTypeModal(
  props: CreateSessionTypeModalProps
) {
  const [open, setOpen] = React.useState(false);
  const [streamOptions, setStreamOptions] = useState<DropdownItemProps[]>([]);
  const [fields, setFields] = useState<{[key: string]: StreamFieldDetails}>({});
  const [streams, setStreams] = useState<FetchStreamsAPIResponse>({});
  
  const defaultSessionType = {name: "", stream: "", condition: {operator: "=", value: "", field: ""} as SimpleCondition};

  const [sessionType, setSessionType] = useState<SessionType>(props.sessionType || defaultSessionType);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    const fn = async () => {
      const streams = await fetchAllStreamsWithDetails();

      const streamOptions = Object.keys(streams);
      setStreamOptions(dropDownOptionsFromArray(streamOptions));
      setStreams(streams);
    }

    fn();
  }, []); 
  
  useEffect(() => {
    const isValidCondition = (condition: Condition) => {
      const compositeOperators = ['and', 'or'];
  
      if (compositeOperators.includes(condition.operator)) {
        const conditions = (condition as CompositeCondition).conditions;
        return conditions.every(isValidCondition);
      } else {
        const simpleCondition = condition as SimpleCondition;
        return simpleCondition.field.length > 0 && 
               simpleCondition.value !== null && 
               simpleCondition.value !== undefined && 
               simpleCondition.value !== "";
      }
    }

    const isSessionTypeValid = sessionType.name.length > 0 && 
      sessionType.stream.length > 0 && 
      isValidCondition(sessionType.condition);
    
    setButtonDisabled(!isSessionTypeValid);
  }, [sessionType]);

  const setInputStream = (inputStream: string) => {
    setSessionType({
      ...sessionType,
      stream: inputStream as string,
    });

    const fields = streams[inputStream].fields;

    setFields(fields);
  }

  return (
    <Modal
      className="dark"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="large"
      trigger={props.trigger}
    >
      <Modal.Header>{props.title}</Modal.Header>
      <Modal.Description></Modal.Description>
      <Modal.Content>

        <StyledInput labelPosition="left">
          <StyledLabel>Session Name</StyledLabel>
          <input 
            value={sessionType.name} 
            onChange={(e) => setSessionType({...sessionType, name: e.target.value})} 
          />
        </StyledInput>

        <StyledInput labelPosition="left">
          <StyledLabel>Stream</StyledLabel>

          <Dropdown
            fluid
            search
            options={streamOptions}
            value={sessionType.stream}
            onChange={(_e, d) => {
              setInputStream(d.value as string);
            }}
            style={{
              padding: "8px 12px",
              border: "none",
            }}
          />
        </StyledInput>

        <SqlWhereClauseBuilder
          fields={fields}
          condition={sessionType.condition}
          onChange={(condition) => {
            
            setSessionType({
              ...sessionType,
              condition: condition,
            });
          }}
        />

      </Modal.Content>
      <Modal.Actions>
        <Button
          secondary
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          primary
          disabled={buttonDisabled}
          onClick={() => {
            setOpen(false);
            props.onSubmit(sessionType);
          }}
        >
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
