import { MicelioStatusMetaData } from "./PanelDef";
import React from "react";

import { PanelEditComponent, PartialMetaData } from "../PanelDef";
import {
  EditAnimatedMetaInput,
  EditMetaRoot,
  EditMetaRow,
  EditPanelFormContainer,
} from "../util";
import { Tab } from "semantic-ui-react";

export class EditMicelioStatusMeta extends PanelEditComponent<MicelioStatusMetaData> {
  descriptionRef = React.createRef<HTMLInputElement>();

  getPanelMeta(type): PartialMetaData<MicelioStatusMetaData> {
    const meta = new MicelioStatusMetaData();

    meta.id = this.props.panelMeta.id;
    meta.description = this.descriptionRef.current?.value || "";

    return {
      meta: meta,
      complete: true,
    };
  }

  render() {
    const description = this.props.panelMeta.description;

    const panes = [
      {
        menuItem: "General",
        pane: (
          <Tab.Pane key={"general"}>
            <EditPanelFormContainer>
              <div style={{ width: "100%", marginTop: "16px" }} />
              <EditMetaRow>
                <div style={{ width: "48%" }}>
                  <EditAnimatedMetaInput
                    defaultRef={this.descriptionRef}
                    defaultValue={description}
                    label="Description"
                  />
                </div>
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <EditMetaRoot>
        <Tab menu={{}} panes={panes} renderActiveOnly={false} />
      </EditMetaRoot>
    );
  }
}
