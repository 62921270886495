import React, { Component } from "react";
import { Accordion, Grid, Icon } from "semantic-ui-react";
import { Device } from "../../../BytebeamClient";
import { ThinDivider } from "../Dashboards/Panel/util";
import { User } from "../../../util";
import { capitalizeFirstLetter } from "../util";
import _ from "lodash";
import { StyledCard } from "../DeviceMangaement/Devices/Device";
import DeviceOverview from "../DeviceMangaement/Devices/DeviceOverview";

interface DeviceAccordionProps {
  device: Device;
  user: User;
}

interface DeviceAccordionState {
  activeIndex: number;
}

export default class DeviceAccordion extends Component<
  DeviceAccordionProps,
  DeviceAccordionState
> {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
    };
  }

  permissions = this.props.user.role.permissions;
  metadataKeys: string[] =
    (this.permissions.viewMetadata as string[]) || ([] as string[]);
  deviceId = this.props.device["id"];
  editableMetadataKeys: Set<string> = new Set(
    this.permissions.editMetadata || []
  );
  lastAction = this.props.device["action"];
  lastActionType = this.lastAction?.type
    ? capitalizeFirstLetter(this.lastAction?.type)
    : "--";

  // Choosing not to show sequence in UI table, even though data is available
  // via API.
  stateKeysNotUniq: string[] = (
    (this.permissions.tables["device_shadow"] as string[]) || ([] as string[])
  ).filter((key: string) => key !== "sequence");
  stateKeys = _.uniq(this.stateKeysNotUniq);

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  componentDidMount(): void {
    // To scroll to top of the screen
    window.scrollTo(0, 0);
  }

  render() {
    const { activeIndex } = this.state;

    return (
      <StyledCard>
        <Accordion fluid inverted>
          <Accordion.Title
            active={activeIndex === 0}
            index={0}
            onClick={this.handleClick}
          >
            <p
              style={{
                fontSize: "1.2rem",
                margin: 0,
              }}
            >
              <Icon name="dropdown" />
              Device Overview
            </p>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            <Grid>
              <ThinDivider style={{ margin: "20px 0px 8px 0px" }} />
              <DeviceOverview
                permissions={this.permissions}
                stateKeys={this.stateKeys}
                device={this.props.device}
                deviceId={this.deviceId}
                lastAction={this.lastAction}
                metadataKeys={this.metadataKeys}
                enablePinMetadata={false}
                editableMetadataKeys={this.editableMetadataKeys}
              />
            </Grid>
          </Accordion.Content>
        </Accordion>
      </StyledCard>
    );
  }
}
