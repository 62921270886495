import React, { useState } from "react";
import { Icon, Button, Form, Modal } from "semantic-ui-react";
import { createDeviceConfiguration } from "../../../../../../BytebeamClient";
import ThemeSchema from "../../../../../../theme/schema";
import { Mixpanel } from "../../../../common/MixPanel";
import { OptionType } from "../../../util";
import AceEditor from "react-ace";
import { useUser } from "../../../../../../context/User.context";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/theme-chaos";

export type CreateJSONConfigModalProps = {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly setOptionType: (arg: OptionType) => void;
  readonly setSelectedVersion: (version: string) => void;
  readonly allConfigOptions: Set<string>;
};

function CreateJSONConfigModal(props: CreateJSONConfigModalProps) {
  const {
    isOpen,
    onClose,
    setOptionType,
    setSelectedVersion,
    allConfigOptions,
  } = props;

  const { user } = useUser();
  const theme = user?.settings?.theme ?? "dark";

  const [versionName, setVersionName] = useState<string>("");
  const [configJSON, setConfigJSON] = useState<string>("");
  const [errors, setErrors] = useState<{ versionName: string }>({
    versionName: "",
  });

  const createJSONConfig = async () => {
    try {
      const configJson = JSON.parse(configJSON);
      const newConfig = await createDeviceConfiguration(
        versionName.trim(),
        configJson,
        "update_config",
      );

      if (newConfig.version_name) {
        setOptionType(OptionType.UploadConfig);
        setSelectedVersion(newConfig.version_name);

        window.toastr.success(
          `Created Device Configuration ${newConfig.version_name}`,
        );
        Mixpanel.track("Created Configuration", {
          Config: newConfig.version_name,
        });
      }
    } catch (e) {
      Mixpanel.track("Failure", {
        type: "Upload Configuration",
        error: JSON.stringify(e),
      });
      console.log("Error while updating device config ", e);
    } finally {
      onModalClose();
    }
  };

  function isJSONValid(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (versionName.trim().length === 0) {
      setErrors({
        versionName: "Please enter a Version Name",
      });
    } else if (!isJSONValid(configJSON)) {
      window.toastr.error("Please type a valid JSON config");
    } else if (allConfigOptions.has(versionName.trim())) {
      setErrors({
        versionName: "This config version already exists",
      });
    } else {
      // valid data, submit
      await createJSONConfig();
    }
  };

  // Reset state and Close modal
  function onModalClose() {
    setVersionName("");
    setConfigJSON("");
    setErrors({
      versionName: "",
    });
    onClose();
  }

  return (
    <Modal open={isOpen} onClose={onModalClose} size="tiny" className="dark">
      <Modal.Header>{`Create JSON Configuration`}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label htmlFor="version_name">Version Name</label>
            <Form.Input
              id={"version_name"}
              autoFocus={true}
              error={errors.versionName !== "" ? errors.versionName : null}
              placeholder={"production v1"}
              value={versionName}
              onChange={(e) => {
                setVersionName(e.target.value);
                setErrors({
                  versionName: "",
                });
              }}
            />
          </Form.Field>
          <Form.Field>
            <label
              htmlFor="config_json"
              style={{
                marginBottom: "8px",
              }}
            >
              Configuration JSON
            </label>
            <AceEditor
              height="300px"
              width="100%"
              placeholder="Enter Configuration JSON"
              mode="json"
              theme={
                ThemeSchema.data[theme ?? "dark"]?.colors["ace-editor-theme"]
              }
              name="config-json"
              onChange={(val) => {
                setConfigJSON(val);
              }}
              fontSize={14}
              value={configJSON}
              showPrintMargin={false}
              showGutter={true}
              highlightActiveLine={true}
              setOptions={{
                enableBasicAutocompletion: false,
                enableLiveAutocompletion: false,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
              }}
              style={{
                marginBottom: "16px",
                borderRadius: "4px",
                border: `${
                  ThemeSchema.data[theme ?? "dark"]?.colors["ace-editor-border"]
                }`,
                boxShadow: `${
                  ThemeSchema.data[theme ?? "dark"]?.colors[
                    "ace-editor-box-shadow"
                  ]
                }`,
              }}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          secondary
          onClick={() => {
            onModalClose();
          }}
        >
          <Icon name="remove" /> Discard
        </Button>
        <Button
          primary
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          <Icon name={"checkmark"} />
          Create
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default CreateJSONConfigModal;
