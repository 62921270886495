import React, { useRef, useState } from "react";
import { Modal, Input, Button, Dropdown } from "semantic-ui-react";
import { Mixpanel } from "./common/MixPanel";
import { HARDWARE_TYPES, mappingHardwareToDeviceOptions } from "./util";
import { useUser } from "../../context/User.context";
import { TenantSettings, UserSettings } from "../../util";
import { updateTenantSettings, updateUserSettings } from "../../BytebeamClient";

type NewProjectModalProps = {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly setCurrentTenant: (selectedTenant: string) => void;
};

function NewProjectModal(props: NewProjectModalProps) {
  const { isOpen, onClose, setCurrentTenant } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const [provisioning, setProvisioning] = useState(false);
  const [hardwareType, setHardwareType] = useState<string>(HARDWARE_TYPES[0]);

  const { user, getCurrentUser } = useUser();

  async function updateHardwareType() {
    const userSettings = user?.settings ?? ({} as UserSettings);
    const getStarted = userSettings.get_started ?? {};

    const tenant_settings: TenantSettings = user["tenant-settings"] ?? {
      common_settings: {
        pin_metadata: [],
      },
      dashboard_settings: {
        custom_time_ranges: {},
      },
      hardware_type: HARDWARE_TYPES[0],
    };

    try {
      const userSettingsResponse = await updateUserSettings({
        settings: {
          ...userSettings,
          get_started: {
            ...getStarted,
            selectedDevice: mappingHardwareToDeviceOptions[hardwareType],
          },
        },
      });

      const tenantSettingsResponse = await updateTenantSettings({
        settings: {
          ...tenant_settings,
          hardware_type: hardwareType,
        },
      });

      if (
        userSettingsResponse !== undefined &&
        userSettingsResponse !== null &&
        tenantSettingsResponse !== undefined &&
        tenantSettingsResponse !== null
      ) {
        await getCurrentUser();
      }
    } catch (error) {
      console.log("Error while updating user settings", error);
    }
  }

  const hardwareTypeOptions = HARDWARE_TYPES.map((hardwareType) => {
    return {
      key: hardwareType,
      value: hardwareType,
      text: hardwareType,
    };
  });

  const handleHardwareTypeChange = (_e, data) => {
    _e.preventDefault();
    const { value } = data;
    setHardwareType(value);
  };

  const onSubmit = async () => {
    if (inputRef.current?.value) {
      const tenantId = inputRef.current.value.trim();
      const validTenant =
        tenantId.length < 255 &&
        tenantId.toLowerCase().match(/^[a-z][a-z0-9]*$/);
      if (!validTenant) {
        window.toastr.error(
          "Project name can only contain alphabets and numbers"
        );
        return;
      }
      setProvisioning(true);
      try {
        // TODO: Use createTenant from BytebeamClient instead of fetch api
        const resp = await fetch("/api/v1/tenants/" + tenantId, {
          method: "POST",
        });
        if (resp.ok) {
          window.toastr.success("Created project " + tenantId);
          Mixpanel.track("Created Project", {
            tenant: tenantId,
          });

          // Updating hardware type in user settings and tenant settings
          await updateHardwareType();
          setCurrentTenant(tenantId);

          setTimeout(() => {
            window.location.href = `/projects/${tenantId}/`;
          }, 1000);
        } else if (resp.status === 409) {
          setProvisioning(false);
          window.toastr.error(
            "Project name already exists, please try another name."
          );

          Mixpanel.track("Failure", {
            type: "Project creation",
            error: "Duplicate tenant name error",
            response: JSON.stringify(resp),
          });
        } else {
          setProvisioning(false);
          window.toastr.error("Failed to create new project.");
          console.error("Failed to create new tenant", resp);

          Mixpanel.track("Failure", {
            type: "Project creation",
            error: "no backend error",
            response: JSON.stringify(resp),
          });
        }
      } catch (error) {
        setProvisioning(false);
        console.error("Error while creating new project", error);
        Mixpanel.track("Failure", {
          type: "Project creation",
          error: JSON.stringify(error),
        });
      }
    } else {
      window.toastr.error("Please enter a project name.");
    }
  };

  return (
    <Modal className="dark" open={isOpen} size="tiny" onClose={onClose}>
      <Modal.Header> Create a new project </Modal.Header>
      <Modal.Content>
        <div style={{ fontSize: "15px", marginBottom: "8px" }}>
          Please provide the name of the project you would like to create.
        </div>
        <Input fluid>
          <input
            autoFocus
            placeholder="Project Name"
            ref={inputRef}
            disabled={provisioning}
          />
        </Input>

        <div
          style={{ fontSize: "15px", marginBottom: "8px", marginTop: "18px" }}
        >
          Please provide the type of the hardware you would like to create.
        </div>
        <Dropdown
          selection
          search
          options={hardwareTypeOptions}
          onChange={handleHardwareTypeChange}
          value={hardwareType}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={onClose}>
          Cancel
        </Button>
        <Button
          id="submit_createProject"
          primary
          onClick={onSubmit}
          disabled={provisioning}
        >
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default NewProjectModal;
