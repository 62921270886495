import { LastValueData, LastValueMetaData, TimestampedValue } from "./PanelDef";
import React, { Component } from "react";
import { PanelViewComponent } from "../PanelDef";
import moment from "moment";
import { Table, TableHeaderCell, Popup, Grid, GridRow, GridColumn } from "semantic-ui-react";
import { TableContainer, convertDataFormatToLastValueData, formatValue } from "../util";
import styled from "styled-components";
import { breakpoints } from "../../../../common/breakpoints";

export const StyledGrid = styled(Grid)`
  width: 100%;
  height: 100%;
  margin: 0px !important;
`;

export const StyledGridRow = styled(GridRow)`
  padding: 0 !important;
`;

export const StyledTitle = styled(GridColumn)`
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  vertical-align: middle;
  text-align: left;
  padding: 16px !important;
  font-weight: 500;
  font-size: 18px;
  color: ${({ theme }) => theme.colors["secondary-color-2"]};
`;

export const StyledValue = styled(GridColumn)`
  display: flex !important;
  justify-content: flex-end;
  align-items: center;
  vertical-align: middle;
  text-align: right;
  padding: 16px !important;
  font-weight: 900;
  font-size: 22px;
  color: ${({ theme }) => theme.colors["foreground-color"]};
`;

type BigValueProps = {
  data: TimestampedValue;
  panelMeta: LastValueMetaData;
};

class BigValue extends Component<BigValueProps> {
  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  ref = React.createRef<HTMLDivElement>();
  state = {
    fontSize: 10,
    fontSizeFactor: 469,
    screenWidth: window.innerWidth,
  };

  canvas = document.createElement("canvas");

  getTextWidth(text, font) {
    const context = this.canvas.getContext("2d");
    if (context) {
      context.font = font;

      const metrics = context.measureText(text);
      return metrics.width;
    }

    return 0;
  }

  calculateFontSize(fontFamily, maxWidth, maxHeight) {
    let text = "ABCDEFGHIJ";
    // const text = this.getText(data);
    let numIterations = 0;
    let start = 10;
    let end = Math.max(10, Math.min(200, maxHeight));

    while (true) {
      const fontSize = (start + end) / 2;
      const font = `${fontSize}px ${fontFamily}`;

      numIterations += 1;

      if (numIterations > 20) {
        return { fontSize, characters: text.length };
      }

      const textWidth = this.getTextWidth(text, font);
      if (Math.abs(textWidth - maxWidth) < 2 || Math.abs(end - start) < 2) {
        return { fontSize, characters: text.length };
      }

      if (textWidth < maxWidth) {
        start = fontSize;
      } else {
        end = fontSize;
      }
    }
  }

  getText(data) {
    const value = data?.value;
    let text = "-";
    if (value !== undefined && value !== null) {
      text = formatValue(value);
    }
    return `${this.props.panelMeta.columns[0].prefix} ${text} ${this.props.panelMeta.columns[0].suffix}`;
  }

  updateDimensions = () => {
    this.setState({ screenWidth: window.innerWidth });
  };

  componentDidMount() {
    if (this.ref.current) {
      const rect = this.ref.current.getBoundingClientRect();
      const fontFamily = window.getComputedStyle(this.ref.current).fontFamily;
      const { fontSize, characters } = this.calculateFontSize(fontFamily, rect.width * 0.9, rect.height * 0.5);
      let sizeFactor = fontSize * characters;
      this.setState({
        fontSize: 0.8 * fontSize,
        fontSizeFactor: sizeFactor,
      });
    }

    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    const panelTitle = this.props.panelMeta.title;
    const dataPoint = this.props.data;
    const textValue = this.getText(dataPoint);
    // let estimatedFontSize = this.state.fontSizeFactor / textValue.length;

    const autoTextSize = this.props.panelMeta.autoTextSize ?? true;
    const customTextSize = this.props.panelMeta.textSize ?? 20;
    if (this.state.screenWidth > breakpoints.sm) {
      return (
        <div className="big-number-root" ref={this.ref}>
          <div className="big-number-value">
            <span
              style={{
                // fontSize: `${this.state.fontSize}px`,
                fontSize: autoTextSize
                  ? `${this.state.fontSize ?? 20}px`
                  : `${customTextSize}px`,
                lineHeight: "normal",
              }}
            >
              {textValue}
            </span>
          </div>
          {dataPoint?.timestamp ? (
            <div className="big-number-timestamp">At: {moment(dataPoint.timestamp).format("DD-MM-YY HH:mm:ss")}</div>
          ) : null}
        </div>
      );
    } else {
      return (
        <StyledGrid>
          <StyledGridRow>
            <StyledTitle width={8}>{panelTitle || "Untitled"}</StyledTitle>
            <StyledValue width={8}>{textValue}</StyledValue>
          </StyledGridRow>
        </StyledGrid>
      );
    }
  }
}

type LastValueTableProps = {
  data: TimestampedValue[];
  panelMeta: LastValueMetaData;
};

class LastValueTable extends Component<LastValueTableProps> {
  render() {
    const columns = this.props.panelMeta.columns;
    const data = this.props.data;
    const groupedData = {};

    data.forEach((d) => {
      groupedData[d.column] = d;
    });

    return (
      <TableContainer>
        <div className="tableContentContainer">
          <Table compact selectable unstackable style={{minWidth: "230px"}}>
            <Table.Header>
              {data[0]?.id ? (
                <Table.Row>
                  <TableHeaderCell>Device</TableHeaderCell>
                  <TableHeaderCell>{data[0]?.id}</TableHeaderCell>
                </Table.Row>
              ) : (
                <Table.Row>
                  <TableHeaderCell>Name</TableHeaderCell>
                  <TableHeaderCell>Value</TableHeaderCell>
                </Table.Row>
              )}
            </Table.Header>

            <Table.Body>
              {columns.map(({ name, suffix, prefix }) => {
                // validating if all columns exists else returning for non-existent columns
                if (!groupedData[name]) return false;

                let { value, timestamp } = groupedData[name];

                let text = "-";
                // rounding off to two decimal place if its number and has decimal places
                if (typeof value == "number" && value % 1 !== 0) {
                  value = Math.round(value * 100) / 100;
                }

                if (value !== null && value !== undefined) {
                  text = `${prefix} ${value} ${suffix}`;
                }

                return (
                  <Table.Row key={name}>
                    <Table.Cell>{name}</Table.Cell>
                    <Table.Cell>
                      {timestamp ? (
                        <Popup
                          inverted
                          position="top right"
                          content={`At: ${new Date(timestamp).toLocaleString()}`}
                          style={{ marginBottom: "6px" }}
                          trigger={<span>{text}</span>}
                        />
                      ) : (
                        <span>{text}</span>
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      </TableContainer>
    );
  }
}

export class ViewLastValue extends PanelViewComponent<LastValueMetaData, LastValueData> {
  render() {
    const { data, panelMeta } = this.props;

    if (data["all-data"][0]?.id && data["all-data"].length > 1) {
      data["all-data"] = data["all-data"].sort((a, b) => {
        return parseInt(a.id) - parseInt(b.id);
      });

      return (
        <TableContainer>
          <div className="tableContentContainer">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-around",
                overflow: "auto",
              }}
            >
              {data["all-data"].map((data) => {
                if (panelMeta.columns.length > 0) {
                  return (
                    <LastValueTable key={data.id} data={convertDataFormatToLastValueData(data)} panelMeta={panelMeta} />
                  );
                } else {
                  return (
                    <div key={data.id} className="panel-no-data">
                      No Data
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </TableContainer>
      );
    } else if (
      data.data.length > 0 &&
      data.data[0] &&
      data.data[0]["value"] !== undefined
    ) {
      if (panelMeta.columns.length === 1) {
        return <BigValue data={data.data[0]} panelMeta={panelMeta} />;
      } else if (panelMeta.columns.length > 1) {
        return <LastValueTable data={data.data} panelMeta={panelMeta} />;
      } else {
        return <div className="panel-no-data">No Data</div>;
      }
    } else {
      return <div className="panel-no-data">No Data</div>;
    }
  }
}
