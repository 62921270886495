import React, { useCallback, useEffect, useState } from "react";
import { Form } from "semantic-ui-react";
import styled from "styled-components";
import { uploadFile } from "../../../../BytebeamClient";
import { Mixpanel } from "../../common/MixPanel";
import { OptionType } from "../util";
import AnimatedEllipsis from "../../common/AnimatedEllipsis";
import LoadingAnimation from "../../../common/Loader";
import { StyledFileUploadButton } from "../../DeviceMangaement/Devices/ActionModals/SendFileModal";

export const StyledInput = styled.input`
  position: absolute !important;
  top: 0px !important;
  left: 0px !important;
  opacity: 0;
  cursor: pointer;
`;

type UploadFirmwareComponentProps = {
  setOptionType: (arg0: OptionType) => void;
  optionType: OptionType;
  uploadTriggered: string;
  uploadTriggeredEach: boolean;
  setActiveModal: (arg0: number) => void;
  activeModal: number;
  customFirmwareInputData: string;
  setCustomFirmwareInputData: (arg0: string) => void;
  selectedCustomFirmwareVersion: string;
  setSelectedCustomFirmwareVersion: (arg0: string) => void;
  allFirmwareOptions: Set<any> | undefined;
  file: File;
  setFile: (arg0: File) => void;
  fileName: string;
  setFileName: (arg0: string) => void;
  showUploadProgress: boolean;
  setShowUploadProgress: (arg0: boolean) => void;
  fileLoaded: number;
  setFileLoaded: (arg0: number) => void;
  fileTotal: number;
  setFileTotal: (arg0: number) => void;
  uploadedFileResponse: {
    status: number;
    data: { id: string };
  };
  setUploadedFileResponse: (arg0: {
    status: number;
    data: { id: string };
  }) => void;
  fillFirmwareDropdownOptions: () => void;
  firmwareOptionsLoaded: boolean;
  fileInput: any;
};

export function UploadFirmwareComponent(props: UploadFirmwareComponentProps) {
  const [errors, setErrors] = useState<{ versionNumber: string | null }>();

  const onSelect = useCallback((e) => {
    props.setOptionType(OptionType.UploadFirmware);
    props.setShowUploadProgress(false);
    props.setFileLoaded(0);
    props.setFileTotal(0);

    if (e.target.files.length !== 0) {
      props.setFile(e.target.files[0]);
      props.setFileName(e.target.files[0].name);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const uploadFirmware = async () => {
    // Firmware Upload code
    const url = `/api/v1/firmwares`;

    let formData = new FormData();
    formData.append("version-number", props.selectedCustomFirmwareVersion);
    formData.append("file", props.file);

    try {
      await uploadFile(url, formData, (p) => {
        // for loading progress
        props.setFileLoaded(p.loaded);
        props.setFileTotal(p.total);
      }).then((res) => {
        props.setUploadedFileResponse(res);
        if (res.status === 201) {
          window.toastr.success(
            "Created Firmware version " + res.data["version-number"]
          );

          Mixpanel.track("Uploaded Firmware", {
            Firmware: res.data["version-number"],
          });

          // move to next modal for summary and triggering respective action
          props.setCustomFirmwareInputData(res.data["version-number"]);
          props.setActiveModal(props.activeModal + 1);
        }
      });
    } catch (error) {
      Mixpanel.track("Failure", {
        type: "Upload Firmware",
      });
      // Handling Error for File Size exceeding limit from NGINX
      if (String(error).includes("413"))
        window.toastr.error("Upload failed due to size limit!");
      else window.toastr.error("Failed to create Firmware version :(");
    }
  };

  const handleSubmit = () => {
    if (
      props.file !== new File([""], "filename") &&
      props.fileName !== "" &&
      props.showUploadProgress &&
      props.fileLoaded !== 0 &&
      props.fileTotal !== 0 &&
      props.selectedCustomFirmwareVersion !== "" &&
      props.customFirmwareInputData !== ""
    ) {
      // form already has data, no need to upload again unless file has been selected again
      props.setActiveModal(props.activeModal + 1);
    } else {
      if (!props.firmwareOptionsLoaded) {
        setTimeout(handleSubmit, 100);
        return;
      }

      let nameValidation: boolean = false;
      let fileValidation: boolean = false;

      if (props.selectedCustomFirmwareVersion.length === 0) {
        setErrors({
          versionNumber: "Please Enter a Version number",
        });
      } else if (props.allFirmwareOptions) {
        if (props.allFirmwareOptions.has(props.selectedCustomFirmwareVersion)) {
          setErrors({
            versionNumber: "This firmware version already exists",
          });
        } else nameValidation = true;
      }
      if (props.fileInput?.current?.files) {
        const fileCount = props.fileInput.current.files.length;
        if (fileCount === 0) {
          setTimeout(() =>
            window.toastr.error("A firmware file must be selected!")
          );
        }
        // file is valid
        else fileValidation = true;
      }

      if (nameValidation && fileValidation) {
        // whole form is valid
        props.setShowUploadProgress(true);
        uploadFirmware();
      }
    }
  };

  useEffect(() => {
    if (
      props.file !== new File([""], "filename") &&
      props.fileName !== "" &&
      props.selectedCustomFirmwareVersion !== ""
    ) {
      props.fillFirmwareDropdownOptions();
      props.setOptionType(OptionType.UploadFirmware);
    }

    if (props.uploadTriggered === "trigger") {
      switch (props.optionType) {
        case OptionType.UploadFirmware:
          handleSubmit();
          break;
      }
    }
  }, [props.uploadTriggered, props.uploadTriggeredEach]); // eslint-disable-line react-hooks/exhaustive-deps

  return props.allFirmwareOptions ? (
    <Form className="sendfile-form">
      <Form.Field>
        <label>Firmware Version Number</label>
        <Form.Input
          error={errors ? errors.versionNumber : null}
          placeholder="1.0.0"
          value={props.selectedCustomFirmwareVersion}
          onChange={(e) => {
            props.setOptionType(OptionType.UploadFirmware);
            props.setSelectedCustomFirmwareVersion(e.target.value);
            setErrors({
              versionNumber: null,
            });
          }}
        />
      </Form.Field>
      <Form.Field>
        <label>
          <p>Upload file from the system</p>
        </label>
        <div style={{ position: "relative" }}>
          <StyledFileUploadButton
            fluid
            content="Select File"
            labelPosition="left"
            icon="file"
          />
          <StyledInput
            type="file"
            id="file"
            ref={props.fileInput}
            onChange={onSelect}
          />
        </div>
        <label style={{ marginTop: "12px" }}>File Chosen: </label>
        {props.fileName ? props.fileName : "No File Chosen"}
      </Form.Field>

      {props.showUploadProgress && (
        <Form.Field>
          <label htmlFor="file-progress">
            {props.uploadedFileResponse.status === 0 ? (
              <span>
                File Uploading
                <AnimatedEllipsis spacing={3} dotSize={"8px"} />
              </span>
            ) : (
              <span>File Uploaded</span>
            )}
          </label>
          <progress
            id="file-progress"
            max={props.fileTotal}
            value={props.fileLoaded}
          />
        </Form.Field>
      )}
    </Form>
  ) : (
    <LoadingAnimation
      loaderContainerHeight="350px"
      loadingText="Loading"
      fontSize="20px"
    />
  );
}
