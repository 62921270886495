import React from "react";
import { Modal, Button, Icon } from "semantic-ui-react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-markdown";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/theme-chaos";
import { useUser } from "../../../../../context/User.context";
import ThemeSchema from "../../../../../theme/schema";

type ReleaseNotesModalProps = {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly releaseNotes: string;
};

function ReleaseNotesModal(props: ReleaseNotesModalProps) {
  const { isOpen, onClose, releaseNotes } = props;
  const { user } = useUser();
  const theme = user?.settings?.theme ?? "dark";

  // Reset state and close modal
  const closeModal = () => {
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={closeModal} size="tiny" className="dark">
      <Modal.Header>Release Notes</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <AceEditor
            readOnly={true}
            height="400px"
            width="100%"
            placeholder={"Release Notes is empty"}
            mode="json"
            theme={ThemeSchema.data[theme]?.colors["ace-editor-theme"]}
            name="custom-json"
            fontSize={14}
            value={releaseNotes}
            showPrintMargin={false}
            showGutter={true}
            highlightActiveLine={true}
            setOptions={{
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              enableSnippets: false,
              showLineNumbers: true,
              tabSize: 2,
            }}
            style={{
              marginBottom: "16px",
              borderRadius: "4px",
              border: `${ThemeSchema.data[theme]?.colors["ace-editor-border"]}`,
              boxShadow: `${ThemeSchema.data[theme]?.colors["ace-editor-box-shadow"]}`,
            }}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={() => closeModal()}>
          <Icon name="remove" /> Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default ReleaseNotesModal;
