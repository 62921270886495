import React, { useRef } from "react";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";
import { useOutsideClickHandler } from "../../../../helpers/helpers";

export type FilteredValue = {
  id: number;
  name: string;
  icon: any;
};

type ActionFilterDropdownProps = {
  dropdownOptions: FilteredValue[];
  setShowFilterDropdown: (arg0: boolean) => void;
  addFilter: (arg0: FilteredValue) => void;
};

const DropdownWrapper = styled.div`
  z-index: 10;
  position: absolute;
  background-color: ${(props) => props.theme.colors["action-dropdown-color"]};
  border: 1px solid #737373;
  border-radius: 17px;
  overflow: hidden;
`;

const DropdownItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  min-width: 150px;
  background-color: transparent;
  padding: 0.5rem 1.5rem;
  transition: 0.15s ease all;
  &:hover {
    background-color: #676767;
  }
`;

export default function ActionFilterDropdown(props: ActionFilterDropdownProps) {
  const wrapperRef = useRef(null);
  useOutsideClickHandler(wrapperRef, () => props.setShowFilterDropdown(false));
  return (
    <DropdownWrapper ref={wrapperRef}>
      {props.dropdownOptions.flatMap((option) => (
        <DropdownItem onClick={() => props.addFilter(option)}>
          {typeof option.icon !== "object" ? (
            <Icon
              name={option.icon}
              style={{
                position: "relative",
                bottom: "3px",
                marginRight: "10px",
              }}
            />
          ) : (
            option.icon
          )}
          {option.name}
        </DropdownItem>
      ))}
    </DropdownWrapper>
  );
}
