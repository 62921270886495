import React from "react";
import App from "./App";
import { ThemeProvider } from "styled-components";
import ThemeSchema from "./theme/schema";
import { GlobalStyles } from "./theme/GlobalStyles";
import { useUser } from "./context/User.context";
import { UserSettings } from "./util";

function AppWithThemeProvider() {
  const { user } = useUser();
  const isBrowserDefaultDark = () =>
    window.matchMedia("(prefers-color-scheme: dark)").matches;

  const getDefaultTheme = (): string => {
    const userDefault = (user?.settings as UserSettings)?.theme;
    const localStorageTheme = window.localStorage.getItem("defaultTheme");
    const browserDefault = isBrowserDefaultDark() ? "dark" : "light";

    return userDefault || localStorageTheme || browserDefault;
  };

  const theme = getDefaultTheme();

  return (
    <ThemeProvider theme={ThemeSchema.data[theme]}>
      <GlobalStyles />
      <App user={user} />
    </ThemeProvider>
  );
}

export default AppWithThemeProvider;
