import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { User } from "../../../util";
import styled from "styled-components";
import ViewDashboard from "./ViewDashboard";
import { breakpoints } from "../../common/breakpoints";

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  gap: 50px;

  &.split {
    flex-direction: row;
  }

  @media (max-width: ${breakpoints.sm}px) {
    &.split {
      flex-direction: column;
    }
  }

  > * {
    flex: 1;
  }
`;

interface CompareDashboardsProps extends RouteComponentProps {
  user: User;
  currentTenant: string;
  settings: any;
  dashboardId: string;
}

class CompareDashboards extends React.Component<CompareDashboardsProps> {
  componentDidMount(): void {
    document.title = "CompareDashboards | Bytebeam";
  }

  render() {
    return (
      <FlexContainer className={'split'}>
        <ViewDashboard
          id={parseInt(this.props.dashboardId)}
          user={this.props.user}
          settings={this.props.settings}
          beingCompared={true}
        />
        <ViewDashboard
          id={parseInt(this.props.dashboardId)}
          user={this.props.user}
          settings={this.props.settings}
          beingCompared={true}
        />
      </FlexContainer>
    );
  }
}

export default withRouter(CompareDashboards);
