import React, { createRef, useCallback, useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import { StyledInput } from "../../../action-modals/UploadFirmwareComponent";
import { OptionType } from "../../../util";
import AnimatedEllipsis from "../../../../common/AnimatedEllipsis";
import { Mixpanel } from "../../../../common/MixPanel";
import { uploadFile } from "../../../../../../BytebeamClient";
import { StyledFileUploadButton } from "../../../../DeviceMangaement/Devices/ActionModals/SendFileModal";

type UploadFirmwareModalProps = {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly setOptionType: (arg: OptionType) => void;
  readonly setSelectedVersion: (version: string) => void;
  readonly allFirmwareVersions: Set<string>;
  readonly setUploadedFileName: (name: string) => void;
};

export default function UploadFirmwareModal(props: UploadFirmwareModalProps) {
  const firmwareFileInput = createRef<HTMLInputElement>();

  const [firmwareVersion, setFirmwareVersion] = useState<string>("");
  const [firmwareFileName, setFirmwareFileName] = useState<string>("");
  const [firmwareFile, setFirmwareFile] = useState<File>(
    new File([""], "filename"),
  );
  const [showFirmwareUploadProgress, setShowFirmwareUploadProgress] =
    useState<boolean>(false);
  const [firmwareFileLoaded, setFirmwareFileLoaded] = useState<number>(0);
  const [firmwareFileTotal, setFirmwareFileTotal] = useState<number>(0);
  const [uploadedFirmwareFileResponse, setUploadedFirmwareFileResponse] =
    useState<{
      status: number;
      data: { id: string };
    }>({
      status: 0,
      data: { id: "" },
    });
  const [errors, setErrors] = useState<{ versionNumber: string | null }>();

  const onSelect = useCallback((e) => {
    setShowFirmwareUploadProgress(false);
    setFirmwareFileLoaded(0);
    setFirmwareFileTotal(0);

    if (e.target.files.length !== 0) {
      setFirmwareFile(e.target.files[0]);
      setFirmwareFileName(e.target.files[0].name);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const uploadFirmware = async () => {
    // Firmware Upload API Endpoint
    const url = `/api/v1/firmwares`;

    let formData = new FormData();
    formData.append("version-number", firmwareVersion);
    formData.append("file", firmwareFile);

    try {
      await uploadFile(url, formData, (p) => {
        // for loading progress
        setFirmwareFileLoaded(p.loaded);
        setFirmwareFileTotal(p.total);
      }).then((res) => {
        setUploadedFirmwareFileResponse(res);
        if (res.status === 201) {
          props.setOptionType(OptionType.UploadFirmware);
          props.setSelectedVersion(res.data["version-number"]);
          props.setUploadedFileName(res.data["file-name"]);

          window.toastr.success(
            "Created Firmware version " + res.data["version-number"],
          );
          Mixpanel.track("Uploaded Firmware", {
            Firmware: res.data["version-number"],
          });
          props.onClose();
        }
      });
    } catch (error) {
      Mixpanel.track("Failure", {
        type: "Upload Firmware",
      });
      // Handling Error for File Size exceeding limit from NGINX
      if (String(error).includes("413"))
        window.toastr.error("Upload failed due to size limit!");
      else window.toastr.error("Failed to create Firmware version :(");
    }
  };

  const handleUpload = () => {
    let nameValidation: boolean = false;
    let fileValidation: boolean = false;

    if (firmwareVersion.length === 0) {
      setErrors({
        versionNumber: "Please Enter a Version number",
      });
    } else if (props.allFirmwareVersions.has(firmwareVersion)) {
      setErrors({
        versionNumber: "This firmware version already exists",
      });
    } else nameValidation = true;

    if (firmwareFileInput?.current?.files) {
      const fileCount = firmwareFileInput.current.files.length;
      if (fileCount === 0) {
        setTimeout(() =>
          window.toastr.error("A firmware file must be selected!"),
        );
      }
      // file is valid
      else fileValidation = true;
    }

    if (nameValidation && fileValidation) {
      // complete form is valid
      setShowFirmwareUploadProgress(true);
      uploadFirmware();
    }
  };

  return (
    <Modal
      closeIcon
      className="dark"
      open={props.isOpen}
      size="tiny"
      onClose={() => {
        props.onClose();
      }}
    >
      <Modal.Header>Upload Firmware File</Modal.Header>

      <Modal.Content>
        <Form>
          <Form.Field>
            <label htmlFor="version_input">Firmware Version Number</label>
            <Form.Input
              id="version_input"
              error={errors ? errors.versionNumber : null}
              placeholder="1.0.0"
              value={firmwareVersion}
              onChange={(e) => {
                setFirmwareVersion(e.target.value);
                setErrors({
                  versionNumber: null,
                });
              }}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="upload_file">
              <p
                style={{
                  fontSize: "1.1rem",
                }}
              >
                Upload file from the system
              </p>
            </label>
            <div style={{ position: "relative" }}>
              <StyledFileUploadButton
                fluid
                content="Select File"
                labelPosition="left"
                icon="file"
              />
              <StyledInput
                type="file"
                id="upload_file"
                ref={firmwareFileInput}
                onChange={onSelect}
              />
            </div>
            <label htmlFor="file_name" style={{ marginTop: "12px" }}>
              File Chosen:{" "}
            </label>
            <span id="file_name">
              {firmwareFileName !== "" ? firmwareFileName : "No File Chosen"}
            </span>
          </Form.Field>

          {showFirmwareUploadProgress && (
            <Form.Field>
              <label htmlFor="file-progress">
                {uploadedFirmwareFileResponse.status === 0 ? (
                  <span>
                    File Uploading
                    <AnimatedEllipsis spacing={3} dotSize={"8px"} />
                  </span>
                ) : (
                  <span>File Uploaded</span>
                )}
              </label>
              <progress
                id="file-progress"
                max={firmwareFileTotal}
                value={firmwareFileLoaded}
              />
            </Form.Field>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          onClick={() => {
            handleUpload();
          }}
        >
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
