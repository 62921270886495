import React, { useState } from "react";
import { Grid, Icon } from "semantic-ui-react";
import TypeSelection from "./TypeSelection";
import ConfirmationModal from "../../common/ConfirmationModal";

const TwoItems = ({ left, right }) => (
  <Grid columns={2}>
    <Grid.Row>
      <Grid.Column floated="left">{left}</Grid.Column>
      <Grid.Column verticalAlign="middle" floated="right">
        {right}
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

export default function ColumnType({
  required,
  value,
  pendingType,
  status,
  columnName,
  onChange,
  permission,
}) {
  const [newType, setNewType] = useState(value);
  const [editMode, setEditMode] = useState(false);

  if (required) {
    return value;
  }

  if (status === "editing") {
    return value + "->" + pendingType;
  }
  // TODO: make icons align right
  if (!editMode) {
    let right = <></>;
    if (status === "ready" && permission) {
      right = <Icon link name="edit" onClick={() => setEditMode(true)} />;
    }

    return <TwoItems left={value} right={right} />;
  } else {
    return (
      <TwoItems
        left={
          <TypeSelection
            value={newType}
            onChange={(_e, d) => setNewType(d.value)}
          />
        }
        right={
          <>
            <ConfirmationModal
              prefixContent="Edit column type"
              expectedText={columnName}
              onConfirm={() => {
                setEditMode(false);
                onChange(newType);
              }}
              trigger={
                <Icon
                  link
                  size="large"
                  name="save"
                  disabled={newType === value}
                />
              }
              message={
                <>
                  <p>
                    <span>Are you absolutely sure?</span>
                    <br />
                    You are going to edit <b>{columnName}</b>, this action will
                    edit column type for {columnName} column.
                  </p>
                  <div
                    style={{
                      padding: "8px",
                      color: "#c50000",
                      textAlign: "center",
                      background: "#f7d4d6",
                      border: "1px solid #c50000",
                      borderRadius: "4px",
                    }}
                  >
                    <b>Warning:</b> This action is not reversible. Please be
                    certain.
                  </div>
                  <p>
                    <br /> Please type <b>{columnName}</b> to proceed or press
                    Cancel to close.
                  </p>
                </>
              }
            />
            <Icon
              link
              name="cancel"
              size="large"
              onClick={() => setEditMode(false)}
            />
          </>
        }
      />
    );
  }
}
