import React from "react";
import { Icon, IconProps } from "semantic-ui-react";
import styled from "styled-components";
import {
  FetchStreamsAPIResponse,
  getTenantFromURL,
} from "../../BytebeamClient";
import moment from "moment";
import { PhaseData } from "./Actions/ActionsV3/new-action/AdvancedPhasedSection";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonIcon = (props: IconProps) => {
  return (
    <Icon
      style={Object.assign({ cursor: "pointer" }, props.style || {})}
      {...props}
    />
  );
};

export const DisplayIf: React.FunctionComponent<{ cond: boolean }> = (
  props
) => {
  const tenant = getTenantFromURL();

  if ((props.cond || tenant === "demo") && props.children) {
    return <React.Fragment>{props.children}</React.Fragment>;
  }

  return <React.Fragment />;
};

export function capitalizeFirstLetter(string: string) {
  if (string) {
    return string
      ?.split("_")
      ?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
      ?.join(" ");
  } else return "";
}

export function getActionName(actionType: string): string {
  if (actionType === "lock") {
    return "Immobilize";
  }

  if(actionType === "unlock") {
    return "Mobilize"
  }

  return capitalizeFirstLetter(actionType);
}

export function getFileExtension(filename) {
  return filename.split(".").pop();
}

export function getHumanReadableFileSizeString(
  bytes: number,
  standard: string = "iec", // si = 1000, iec = 1024 threshold
  decimalPlaces: number = 1
) {
  // SI units are power-of-ten based, IEC units are power-of-two based
  const threshold = standard === "si" ? 1000 : 1024;

  let unitIndex = -1;
  const rounded = 10 ** decimalPlaces;

  const units =
    standard === "si"
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];

  if (Math.abs(bytes) < threshold) {
    return bytes + " B";
  }

  do {
    bytes /= threshold;
    ++unitIndex;
  } while (
    Math.round(Math.abs(bytes) * rounded) / rounded >= threshold &&
    unitIndex < units.length - 1
  );

  return bytes.toFixed(decimalPlaces) + " " + units[unitIndex];
}

// Stream Info API
export const filterTableInfo = (tablesObj) => {
  const filteredTablesInfo = Object.keys(tablesObj)
    .filter((tableName) => !tableName.endsWith("_local"))
    .reduce((filteredTableObj, tableName) => {
      return Object.assign(filteredTableObj, {
        [tableName]: tablesObj[tableName],
      });
    }, {});
  return filteredTablesInfo;
};

// Filtering Streams Table Data, detailed with types
export function filterStreamTableInfo(
  tablesObj: FetchStreamsAPIResponse,
  filterUplinkTables = false
) {
  const filteredTables = {};
  Object.keys(tablesObj).forEach((stream) => {
    let streamName = stream;
    if (filterUplinkTables) {
      if (!streamName.endsWith("_local") && !streamName.startsWith("uplink_")) {
        filteredTables[streamName] = Object.keys(tablesObj[stream].fields)
          .filter((field) => !field.endsWith("_timestamp"))
          .map((item) => {
            return {
              name: item,
              type: tablesObj[stream]?.fields[item]?.type,
              seq_id: tablesObj[stream]?.fields[item]?.seq_id,
              required: tablesObj[stream]?.fields[item]?.required,
            };
          });
      }
    } else {
      if (!streamName.endsWith("_local")) {
        filteredTables[streamName] = Object.keys(tablesObj[stream].fields)
          .filter((field) => !field.endsWith("_timestamp"))
          .map((item) => {
            return {
              name: item,
              type: tablesObj[stream]?.fields[item]?.type,
              seq_id: tablesObj[stream]?.fields[item]?.seq_id,
              required: tablesObj[stream]?.fields[item]?.required,
            };
          });
      }
    }
  });
  return filteredTables;
}

// Function to check if string has special characters except underscore and space.
export function hasSpecialCharacters(str: string) {
  const regex = /[^a-zA-Z0-9\s_]/;
  return regex.test(str);
}

export async function copyContentToClipboard(
  text: string,
  showTextWhenCopied: boolean = true
) {
  try {
    await navigator.clipboard.writeText(text);
    if (text) {
      window.toastr.success(
        showTextWhenCopied
          ? `"${text}" copied to clipboard`
          : "Content Copied to clipboard"
      );
    }
  } catch (err) {
    console.error("Failed to copy: ", err);
  }
}

export const validateTimestampInterval = (
  timestamp: Date | null | number,
  activePhase: string,
  phasesData: PhaseData[]
) => {
  // Find the index of the active phase in the phasesData array
  const activePhaseIndex = phasesData.findIndex(
    (phase) => phase.name === activePhase
  );

  // Check if the difference between the selected timestamp and the timestamp of the previous phase is at least 5 minutes
  if (activePhaseIndex > 0) {
    const prevTimestamp = phasesData[activePhaseIndex - 1].trigger_on.timestamp;
    const diffInMinutes = moment(timestamp).diff(
      moment(prevTimestamp),
      "minutes"
    );
    return diffInMinutes >= 5;
  }

  return true;
};

export const HARDWARE_TYPES = [
  "ESP32",
  "Arduino",
  "Raspberry Pi",
  "BeagleBone Black",
  "Linux",
  "Android",
  "Other",
];

// Mapping hardware type to device options in getting started page
export const mappingHardwareToDeviceOptions = {
  ESP32: "ESP32 (ESP-IDF)",
  Arduino: "Arduino",
  "Raspberry Pi": "Linux",
  "BeagleBone Black": "Linux",
  Linux: "Linux",
  Android: "ESP32 (ESP-IDF)",
  Other: "ESP32 (ESP-IDF)",
};

export function formatDuration(seconds) {
  const duration = moment.duration(seconds, "seconds");
  const yrs = duration.years();
  const d = duration.days();
  const hrs = duration.hours();
  const mins = duration.minutes();
  const secs = duration.seconds();

  let result = "";
  if (yrs > 0) result += `${yrs} yr${yrs > 1 ? "s" : ""} `;
  if (d > 0) result += `${d} d `;
  if (hrs > 0) result += `${hrs} hr${hrs > 1 ? "s" : ""} `;
  if (mins > 0) result += `${mins} min `;
  if (secs > 0) result += `${secs} sec`;

  return result.trim();
}
