import { LocateDevicesMetaData } from "./PanelDef";
import React from "react";

import {
  Tab,
  Radio,
  Icon,
  Popup,
  IconProps,
  Button,
  Input,
  Label,
} from "semantic-ui-react";

import {
  PanelEditComponent,
  EditComponentProps,
  PartialMetaData,
} from "../PanelDef";

import {
  EditMetaRoot,
  EditMetaRow,
  EditAnimatedMetaInput,
  EditAnimatedMetaDropdown,
  ThinDivider,
  filterNumericalTypeColumnOptions,
  LabelHeading,
  EditPanelFormContainer,
  StyledSketchPickerTrigger,
  StyledInputDiv,
} from "../util";
import { StateColor } from "../StateTimeline/PanelDef";

import { SketchPicker } from "react-color";
import {
  fetchAllMetadataKeys,
  fetchStreamTableFields,
} from "../../../../../BytebeamClient";
import { dropDownOptionsFromArray } from "../../../../../util";
import styled from "styled-components";

type styledButtonProps = {
  colorButton: string;
};

const StyledButton = styled(Button)`
  &&& {
    color: ${(props: styledButtonProps) => props?.colorButton} !important;
    margin: 5px !important;
    white-space: nowrap;

    &.inverted {
      box-shadow: 0px 0px 0px 2px
        ${(props: styledButtonProps) => props?.colorButton} inset !important;
    }

    &:hover {
      color: white !important;
      background-color: ${(props: styledButtonProps) =>
        props?.colorButton} !important;
    }
  }
`;

const MarkerIconContainer = styled.div<{ bordered: boolean }>`
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-right: 8px;
  width: 42px;
  height: 42px;
  border: ${(props) =>
    props.bordered
      ? `2px solid ${props.theme.colors["marker_icon-border-color"]}`
      : "none"};
  border-radius: 8px;
  cursor: pointer;
`;

function MarkerIcon(props: IconProps) {
  const { bordered, onClick, name } = props;
  return (
    <MarkerIconContainer bordered={bordered ?? false} onClick={onClick}>
      <Icon name={name} size="large" style={{ margin: "0px" }} />
    </MarkerIconContainer>
  );
}

export type EditLocateDevicesMetaState = {
  table: string;
  latitude_column: string;
  longitude_column: string;
  trafficLayerFlag: boolean;
  satelliteLayerFlag: boolean;
  title: string;
  description: string;
  deviceDashboardIds: string[];
  markerIcon: string;
  colorMapping: StateColor[];
  metadataKeys: string[];
  allMetadataKeys: string[];
  deviceShadowKeys: string[];
  allDeviceShadowKeys: string[];
  error: boolean;
};

export class EditLocateDevicesMeta extends PanelEditComponent<
  LocateDevicesMetaData,
  EditLocateDevicesMetaState
> {
  titleRef = React.createRef<HTMLInputElement>();
  descriptionRef = React.createRef<HTMLInputElement>();

  constructor(props: EditComponentProps<LocateDevicesMetaData>) {
    super(props);

    let deviceDashboardIds = props.panelMeta.device_dashboard_ids ?? [];

    if (
      deviceDashboardIds.length === 0 &&
      props.panelMeta.device_dashboard_id
    ) {
      deviceDashboardIds = [props.panelMeta.device_dashboard_id];
    }
    if (props.panelMeta) {
      this.state = {
        table: props.panelMeta.table,
        latitude_column: props.panelMeta.latitude_column,
        longitude_column: props.panelMeta.longitude_column,
        trafficLayerFlag: props.panelMeta.trafficLayerFlag
          ? props.panelMeta.trafficLayerFlag
          : false,
        satelliteLayerFlag: props.panelMeta.satelliteLayerFlag
          ? props.panelMeta.satelliteLayerFlag
          : false,
        title: props.panelMeta.title,
        description: props.panelMeta.description,
        deviceDashboardIds: deviceDashboardIds,
        markerIcon: props.panelMeta.markerIcon || "default",
        colorMapping: props.panelMeta.colorMapping || [],
        metadataKeys: props.panelMeta.metadataKeys || [],
        allMetadataKeys: [],
        deviceShadowKeys: props.panelMeta.deviceShadowKeys || [],
        allDeviceShadowKeys: [],
        error: false,
      };
    } else {
      this.state = {
        table: "",
        latitude_column: "",
        longitude_column: "",
        trafficLayerFlag: false,
        satelliteLayerFlag: false,
        title: "",
        description: "",
        deviceDashboardIds: [],
        markerIcon: "default",
        colorMapping: [],
        metadataKeys: [],
        allMetadataKeys: [],
        deviceShadowKeys: [],
        allDeviceShadowKeys: [],
        error: false,
      };
    }
  }

  async fetchMetadataKeys() {
    try {
      const res = await fetchAllMetadataKeys();
      this.setState({ allMetadataKeys: res.map(({ key }) => key) });
    } catch (e) {
      console.log(e);
    }
  }

  async fetchDeviceShadowKeys() {
    try {
      const res = await fetchStreamTableFields("device_shadow");
      if (!res) return;
      let responseKeys = Object.keys(res.result);
      const filteredArray = responseKeys.filter(
        (item) => !item.endsWith("_timestamp")
      );
      this.setState({ allDeviceShadowKeys: filteredArray });
    } catch (e) {
      console.log(e);
    }
  }

  componentDidMount(): void {
    this.fetchMetadataKeys();
    this.fetchDeviceShadowKeys();
  }

  getPanelMeta(type): PartialMetaData<LocateDevicesMetaData> {
    const meta: LocateDevicesMetaData = {
      type: "locate_devices",
      id: this.props.panelMeta.id,
      // title: this.titleRef.current?.value || "",
      title: this.state.title,
      description: this.descriptionRef.current?.value || "",
      table: this.state.table || "",
      latitude_column: this.state.latitude_column || "",
      longitude_column: this.state.longitude_column || "",
      device_dashboard_ids: this.state.deviceDashboardIds,
      device_dashboard_id: this.props.panelMeta.device_dashboard_id,
      trafficLayerFlag: this.state.trafficLayerFlag,
      satelliteLayerFlag: this.state.satelliteLayerFlag,
      markerIcon: this.state.markerIcon,
      colorMapping: this.state.colorMapping,
      metadataKeys: this.state.metadataKeys,
      deviceShadowKeys: this.state.deviceShadowKeys,
    };

    return {
      meta: meta,
      complete: this.isValidPanelMeta(meta, type),
    };
  }

  isValidPanelMeta(meta: LocateDevicesMetaData, type?: string) {
    // type is used here to differentiate between submit and refresh in edit mode
    if (
      !(!!meta.table && !!meta.latitude_column && !!meta.longitude_column) &&
      type === "submit"
    ) {
      this.setState({ error: true });
    } else if (type === "submit") {
      this.setState({ error: false });
    }
    return !!meta.table && !!meta.latitude_column && !!meta.longitude_column;
  }

  setTable(_event, data) {
    this.setState({
      table: data.value,
      latitude_column: "",
      longitude_column: "",
    });
  }

  setLatitudeColumn(_event, data) {
    this.setState({
      latitude_column: data.value,
    });
  }

  setLongitudeColumn(_event, data) {
    this.setState({
      longitude_column: data.value,
    });
  }

  setTitleColumn(_event) {
    const val = _event.target.value;
    this.setState({
      title: val,
    });
  }

  setDashboardIdsColumn(_event, data) {
    this.setState({
      deviceDashboardIds: data.value,
    });
  }

  setMetadataKeys(value: string[]) {
    this.setState({ metadataKeys: value });
  }

  setDeviceShadowKeys(value: string[]) {
    this.setState({ deviceShadowKeys: value });
  }

  handleColorPickerClick(e, key) {
    e.stopPropagation();
    this.setState(({ colorMapping }) => ({
      colorMapping: [
        ...colorMapping.slice(0, key),
        {
          ...colorMapping[key],
          pickerOpen: !colorMapping[key]["pickerOpen"],
        },
        ...colorMapping.slice(key + 1),
      ],
    }));
  }

  updateStateColorMapping(color, key) {
    this.setState(({ colorMapping }) => ({
      colorMapping: [
        ...colorMapping.slice(0, key),
        {
          ...colorMapping[key],
          color: color.hex,
        },
        ...colorMapping.slice(key + 1),
      ],
    }));
  }

  handleStateTextChange(value, key) {
    this.setState(({ colorMapping }) => ({
      colorMapping: [
        ...colorMapping.slice(0, key),
        {
          ...colorMapping[key],
          state: value,
        },
        ...colorMapping.slice(key + 1),
      ],
    }));
  }

  handleOutsideClick() {
    this.setState(({ colorMapping }) => ({
      colorMapping: colorMapping.map((i) => {
        return { ...i, pickerOpen: false };
      }),
    }));
  }

  addStateColorMapping() {
    this.setState(({ colorMapping }) => ({
      colorMapping: [
        ...colorMapping,
        {
          state: "",
          color: "#7db2ef",
          pickerOpen: false,
        },
      ],
    }));
  }

  removeStateColorMapping() {
    // Should put with each color not just last
    this.setState(({ colorMapping }) => ({
      colorMapping: colorMapping.slice(0, colorMapping.length - 1),
    }));
  }

  render() {
    const description = this.props.panelMeta.description;
    const { title } = this.state;

    const tableOptions = Object.keys(this.props.tables).map((t) => {
      return {
        key: t,
        text: t,
        value: t,
      };
    });

    let latitudeColumnOptions: Array<{
      key: string;
      value: string;
      text: string;
    }> = [];
    let longitudeColumnOptions: Array<{
      key: string;
      value: string;
      text: string;
    }> = [];
    let deviceDashboardIdOptions:
      | Array<{
          key: string;
          value: string;
          text: string;
        }>
      | undefined = undefined;
    let filteredDeviceDashboards: any = this.props.dashboards?.filter(
      (item) => {
        if (item.type === "fleet") {
          return false;
        } else return true;
      }
    );
    deviceDashboardIdOptions = filteredDeviceDashboards.map((item) => {
      const obj = {
        key: String(item.id),
        value: String(item.id),
        text: `${item.id} - ${item.title}`,
      };
      return obj;
    });

    if (!!this.state.table) {
      latitudeColumnOptions = filterNumericalTypeColumnOptions(
        this.props.tables[this.state.table]
      ).map((f: { name: string; type: string }) => {
        return {
          key: f.name,
          text: f.name,
          value: f.name,
        };
      });
    }

    if (!!this.state.table) {
      longitudeColumnOptions = filterNumericalTypeColumnOptions(
        this.props.tables[this.state.table]
      ).map((f: { name: string; type: string }) => {
        return {
          key: f.name,
          text: f.name,
          value: f.name,
        };
      });
    }

    const panes = [
      {
        menuItem: "General",
        pane: (
          <Tab.Pane key={"general"}>
            <EditPanelFormContainer>
              <div style={{ width: "100%", marginTop: "16px" }} />
              <EditMetaRow>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    autoFocus={true}
                    defaultRef={this.titleRef}
                    defaultValue={title}
                    label="Title"
                    onChangeEvent={(evt) => this.setTitleColumn(evt)}
                  />
                </StyledInputDiv>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    defaultRef={this.descriptionRef}
                    defaultValue={description}
                    label="Description"
                  />
                </StyledInputDiv>
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>
                <StyledInputDiv width="50%" marginTop="10px">
                  <EditAnimatedMetaDropdown
                    placeholder="Select Stream"
                    text={this.state.table || "Select Stream"}
                    search
                    selection
                    options={tableOptions}
                    onChange={this.setTable.bind(this)}
                    defaultValue={this.state.table || ""}
                    value={this.state.table || ""}
                    elementid={"LocateDevicesTable"}
                    error={this.state.error && !this.state.table}
                  />
                </StyledInputDiv>
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>
                <StyledInputDiv width="48%" marginTop="10px">
                  <EditAnimatedMetaDropdown
                    placeholder="Latitude Field"
                    text={this.state.latitude_column || "Latitude Field"}
                    search
                    selection
                    disabled={this.state.table === ""}
                    options={latitudeColumnOptions}
                    onChange={this.setLatitudeColumn.bind(this)}
                    defaultValue={this.state.latitude_column || ""}
                    value={this.state.latitude_column || ""}
                    elementid={"LocateDevicesColumn"}
                    error={this.state.error && !this.state.latitude_column}
                  />
                </StyledInputDiv>
                <StyledInputDiv width="48%" marginTop="10px">
                  <EditAnimatedMetaDropdown
                    placeholder="Longitude Field"
                    text={this.state.longitude_column || "Longitude Field"}
                    search
                    selection
                    disabled={this.state.table === ""}
                    options={longitudeColumnOptions}
                    onChange={this.setLongitudeColumn.bind(this)}
                    defaultValue={this.state.longitude_column || ""}
                    value={this.state.longitude_column || ""}
                    elementid={"LocateDevicesLongitude"}
                    error={this.state.error && !this.state.longitude_column}
                  />
                </StyledInputDiv>
              </EditMetaRow>
              <ThinDivider />
              <div style={{ width: "100%", height: "10px" }} />
              <EditMetaRow>
                <StyledInputDiv width="48%" marginTop="10px" smInputWidth="85%">
                  <EditAnimatedMetaDropdown
                    placeholder="Device dashboard links"
                    search
                    selection
                    multiple
                    options={deviceDashboardIdOptions}
                    onChange={this.setDashboardIdsColumn.bind(this)}
                    value={this.state.deviceDashboardIds}
                    defaultValue={this.state.deviceDashboardIds}
                    elementid={"LocateDevicesDashboardID"}
                  />
                </StyledInputDiv>{" "}
                <Popup
                  inverted
                  content="Dashboard to view the selected device data"
                  trigger={
                    <Icon
                      style={{ marginLeft: "5px", justifyContent: "center" }}
                      name="info circle"
                    />
                  }
                />
              </EditMetaRow>

              <div style={{ width: "100%", height: "5px" }} />

              <EditMetaRow>
                <StyledInputDiv width="48%" marginTop="10px" smInputWidth="85%">
                  <EditAnimatedMetaDropdown
                    placeholder="Metadata to show"
                    search
                    selection
                    multiple
                    options={dropDownOptionsFromArray(
                      this.state.allMetadataKeys
                    )}
                    onChange={(_event, data) =>
                      this.setMetadataKeys(data.value as string[])
                    }
                    defaultValue={this.state.metadataKeys}
                    value={this.state.metadataKeys}
                    elementID={"LocateDevicesMetadataKey"}
                  />
                </StyledInputDiv>{" "}
                <Popup
                  inverted
                  content="Select metadata fields to show in the popover"
                  trigger={
                    <Icon style={{ marginLeft: "5px" }} name="info circle" />
                  }
                />
              </EditMetaRow>

              <div style={{ width: "100%", height: "5px" }} />

              <EditMetaRow>
                <StyledInputDiv width="48%" marginTop="10px" smInputWidth="85%">
                  <EditAnimatedMetaDropdown
                    placeholder="Devices shadow data to show"
                    search
                    selection
                    multiple
                    options={dropDownOptionsFromArray(
                      this.state.allDeviceShadowKeys
                    )}
                    onChange={(_event, data) =>
                      this.setDeviceShadowKeys(data.value as string[])
                    }
                    defaultValue={this.state.deviceShadowKeys}
                    value={this.state.deviceShadowKeys}
                    elementID={"LocateDevicesDeviceShadowKey"}
                  />
                </StyledInputDiv>{" "}
                <Popup
                  inverted
                  content="Select device shadow fields to show in the popover"
                  trigger={
                    <Icon style={{ marginLeft: "5px" }} name="info circle" />
                  }
                />
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "View",
        pane: (
          <Tab.Pane key={"view"}>
            <EditPanelFormContainer>
              <EditMetaRow>
                <label style={{ paddingRight: "10px", fontWeight: "bold" }}>
                  {" "}
                  Show Map Layers{" "}
                </label>
              </EditMetaRow>
              <EditMetaRow>
                <label style={{ paddingRight: "10px", width: "150px" }}>
                  {" "}
                  Satellite Layer{" "}
                </label>
                <Radio
                  toggle
                  checked={this.state.satelliteLayerFlag}
                  onChange={(_event, data) => {
                    this.setState({
                      satelliteLayerFlag: data.checked || false,
                    });
                    if (data.checked) {
                      this.setState({ trafficLayerFlag: false });
                    }
                  }}
                />
              </EditMetaRow>
              <EditMetaRow>
                <label style={{ paddingRight: "10px", width: "150px" }}>
                  {" "}
                  Traffic Layer{" "}
                </label>
                <Radio
                  toggle
                  checked={this.state.trafficLayerFlag}
                  onChange={(_event, data) => {
                    this.setState({ trafficLayerFlag: data.checked ?? false });
                    if (data.checked) {
                      this.setState({ satelliteLayerFlag: false });
                    }
                  }}
                />
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>
                <label style={{ paddingRight: "10px", fontWeight: "bold" }}>
                  {" "}
                  Select Icon{" "}
                </label>
              </EditMetaRow>
              <EditMetaRow>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <MarkerIcon
                    name="map marker alternate"
                    bordered={this.state.markerIcon === "default"}
                    onClick={() => this.setState({ markerIcon: "default" })}
                  />

                  <MarkerIcon
                    name="motorcycle"
                    bordered={this.state.markerIcon === "motorcycle"}
                    onClick={() => this.setState({ markerIcon: "motorcycle" })}
                  />

                  <MarkerIcon
                    name="car"
                    bordered={this.state.markerIcon === "car"}
                    onClick={() => this.setState({ markerIcon: "car" })}
                  />

                  <MarkerIcon
                    name="truck"
                    bordered={this.state.markerIcon === "truck"}
                    onClick={() => this.setState({ markerIcon: "truck" })}
                  />

                  <MarkerIcon
                    name="location arrow"
                    bordered={this.state.markerIcon === "arrow"}
                    onClick={() => this.setState({ markerIcon: "arrow" })}
                  />
                </div>
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>
                <LabelHeading>
                  Map Icon color using device shadow status field
                </LabelHeading>
                {this.state.colorMapping.map((colorPicker, key) => {
                  return (
                    <div style={{ marginTop: "15px" }} key={key}>
                      <EditMetaRow>
                        <Input
                          className="add-panel-title-input"
                          labelPosition="left"
                        >
                          <Label>State</Label>
                          <input
                            type="text"
                            defaultValue={
                              colorPicker.state.length > 0
                                ? colorPicker.state
                                : undefined
                            }
                            placeholder={"Enter Status Field Value"}
                            onChange={(e) =>
                              this.handleStateTextChange(e.target.value, key)
                            }
                          />
                        </Input>
                        <StyledSketchPickerTrigger
                          id="default_color"
                          style={{
                            backgroundColor: `${colorPicker.color}`,
                            marginTop: "4px",
                            width: 100,
                            height: 30,
                          }}
                          onClick={(e) => this.handleColorPickerClick(e, key)}
                        />
                        <div
                          style={{
                            display: colorPicker.pickerOpen ? "block" : "none",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <SketchPicker
                            color={colorPicker.color}
                            onChange={(color) => {
                              this.updateStateColorMapping(color, key);
                            }}
                          />
                        </div>
                      </EditMetaRow>
                    </div>
                  );
                })}
              </EditMetaRow>
              <EditMetaRow>
                <StyledButton
                  inverted
                  colorButton="#00adff"
                  onClick={this.addStateColorMapping.bind(this)}
                >
                  Add State
                </StyledButton>
                <StyledButton
                  inverted
                  colorButton="#eb2a1c"
                  onClick={this.removeStateColorMapping.bind(this)}
                  disabled={this.state.colorMapping.length === 0}
                >
                  Delete State
                </StyledButton>
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <EditMetaRoot>
        <div onClick={() => this.handleOutsideClick()}>
          <Tab menu={{}} panes={panes} renderActiveOnly={false} />
        </div>
      </EditMetaRoot>
    );
  }
}
