import { PieChartMetaData } from "./PanelDef";
import React from "react";

import { Label, Divider } from "semantic-ui-react";
import { PanelEditComponent, PartialMetaData } from "../PanelDef";
import {
  EditMetaRoot,
  EditMetaRow,
  EditMetaInput,
  EditMetaDropdown,
} from "../util";
import { TableInfo } from "../../../../../BytebeamClient";

export type EditPieChartMetaProps = {
  panelMeta: PieChartMetaData;
  tables: TableInfo;
};

export type EditPieChartMetaState = {
  table: string;
  aggregate_column: string;
  groupby_column: string;
  aggregator: string;
};

const aggregators = ["min", "max", "avg", "sum", "count"];

export class EditPieChartMeta extends PanelEditComponent<
  PieChartMetaData,
  EditPieChartMetaState
> {
  titleRef = React.createRef<HTMLInputElement>();
  descriptionRef = React.createRef<HTMLInputElement>();

  constructor(props: EditPieChartMetaProps) {
    super(props);

    if (props.panelMeta) {
      this.state = {
        table: props.panelMeta.table,
        aggregate_column: props.panelMeta.aggregateColumn,
        groupby_column: props.panelMeta.groupByColumn,
        aggregator: props.panelMeta.aggregator,
      };
    } else {
      this.state = {
        table: "",
        aggregate_column: "",
        groupby_column: "",
        aggregator: "",
      };
    }
  }

  getPanelMeta(type): PartialMetaData<PieChartMetaData> {
    const meta: PieChartMetaData = {
      type: "pie_chart",
      id: this.props.panelMeta.id,
      title: this.titleRef.current?.value || "",
      description: this.descriptionRef.current?.value || "",
      table: this.state.table || "",
      aggregateColumn: this.state.aggregate_column || "",
      groupByColumn: this.state.groupby_column || "",
      aggregator: this.state.aggregator || "",
    };

    return {
      meta: meta,
      complete: this.isValidPanelMeta(meta, type),
    };
  }

  isValidPanelMeta(meta: PieChartMetaData, type?: string) {
    return (
      !!meta.table &&
      !!meta.aggregateColumn &&
      !!meta.groupByColumn &&
      !!meta.aggregator
    );
  }

  setTable(_event, data) {
    this.setState({
      table: data.value,
    });
  }

  setAggregateColumn(_event, data) {
    this.setState({
      aggregate_column: data.value,
    });
  }

  setGroupByColumn(_event, data) {
    this.setState({
      groupby_column: data.value,
    });
  }

  setAggregate(_event, data) {
    this.setState({
      aggregator: data.value,
    });
  }

  render() {
    const title = this.props.panelMeta.title;
    const description = this.props.panelMeta.description;

    const tableOptions = Object.keys(this.props.tables).map((t) => {
      return {
        key: t,
        text: t,
        value: t,
      };
    });

    let columnTypeOptions: Array<{ key: string; value: string; text: string }> =
      [];

    if (!!this.state.table) {
      columnTypeOptions = this.props.tables[this.state.table].map(
        (f: { name: string; type: string }) => {
          return {
            key: f.name,
            text: f.name,
            value: f.name,
          };
        }
      );
    }

    const aggregateOptions = aggregators.map((a) => {
      return {
        key: a,
        text: a,
        value: a,
      };
    });

    return (
      <EditMetaRoot>
        <EditMetaRow>
          <EditMetaInput>
            <Label>Title</Label>
            <input ref={this.titleRef} defaultValue={title} />
          </EditMetaInput>
        </EditMetaRow>

        <EditMetaRow>
          <EditMetaInput>
            <Label>Info</Label>
            <input ref={this.descriptionRef} defaultValue={description} />
          </EditMetaInput>
        </EditMetaRow>

        <Divider />

        <EditMetaRow>
          <EditMetaDropdown
            placeholder="Select Stream"
            text={this.state.table}
            search
            selection
            options={tableOptions}
            onChange={this.setTable.bind(this)}
            defaultValue={this.props.panelMeta.table}
          />

          <EditMetaDropdown
            placeholder="Aggregate Field"
            text={this.state.aggregate_column}
            search
            selection
            disabled={this.state.table === ""}
            options={columnTypeOptions}
            onChange={this.setAggregateColumn.bind(this)}
            defaultValue={this.props.panelMeta.aggregateColumn}
          />

          <EditMetaDropdown
            placeholder="Group By Field"
            text={this.state.groupby_column}
            search
            selection
            disabled={this.state.table === ""}
            options={columnTypeOptions}
            onChange={this.setGroupByColumn.bind(this)}
            defaultValue={this.props.panelMeta.groupByColumn}
          />

          <EditMetaDropdown
            placeholder="Aggregator"
            text={this.state.aggregator}
            search
            selection
            disabled={
              this.state.aggregate_column === "" ||
              this.state.groupby_column === ""
            }
            options={aggregateOptions}
            onChange={this.setAggregate.bind(this)}
            defaultValue={this.props.panelMeta.aggregator}
          />
        </EditMetaRow>
      </EditMetaRoot>
    );
  }
}
