import React, { Component } from "react";
import {
  Table,
  Icon,
  Button,
  Grid,
  Message,
  Progress,
  Accordion,
  Pagination,
} from "semantic-ui-react";
import "./device-management-styles.scss";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { DisplayIf, getActionName, capitalizeFirstLetter } from "../util";
import { ErrorMessage } from "../../common/ErrorMessage";
import {
  fetchAllActionStatus,
  markActionAsCompleted,
  fetchDeviceActionStatusDetailsWithPhase,
} from "../../../BytebeamClient";
import styled from "styled-components";
import Toggle from "../../common/Toggle";
import { StyledCard } from "./Devices/Device";
import LoadingAnimation from "../../common/Loader";
// import ThemeSchema from "../../../theme/schema"; 
import ButtonWithIcon from "../../common/StyledComponents/buttonWithIcon";
import NewActionModal from "../Actions/action-modals/NewActionModal";
import ViewPayloadModal from "./ActionPayloadModal";

const ViewPayloadTxt = styled.span`
  margin-left: 6px;
  position: absolute;
  right: 30px;
  bottom: 15px;
  color: ${(props) => props.theme.colors["link-text-color"]};
  cursor: pointer;
`;

const MessageHeaderTitleStyle = styled.div`
  width: 55%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .deviceAndTimeMessage {
    min-width: 180px;
    text-align: right;
  }
`;

const PhaseDetailsTitleStyle = styled.div`
  width: 55%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;

  .deviceAndTimeMessage {
    min-width: 180px;
    text-align: right;
  }
`;

// class ViewActionPayload extends Component {
//   render() {
//     const { isOpen, theme } = this.props;
//     const payload = this.props.action.params
//       ? JSON.parse(this.props.action.params)
//       : "";

//     return (
//       <Modal
//         centered={true}
//         open={isOpen}
//         onClose={this.props.close}
//         size="small"
//         className="dark"
//       >
//         <Modal.Header>Action Payload</Modal.Header>
//         <Modal.Content>
//           <AceEditor
//             height="200px"
//             width="100%"
//             mode="json"
//             theme={
//               ThemeSchema.data[theme ?? "dark"]?.colors["ace-editor-theme"]
//             }
//             name="custom-json"
//             fontSize={14}
//             value={JSON.stringify(payload, null, 2)}
//             showPrintMargin={false}
//             showGutter={true}
//             highlightActiveLine={true}
//             setOptions={{
//               readOnly: true,
//               enableBasicAutocompletion: false,
//               enableLiveAutocompletion: false,
//               enableSnippets: false,
//               showLineNumbers: false,
//               tabSize: 2,
//             }}
//             style={{
//               marginBottom: "16px",
//               borderRadius: "4px",
//               border: `${
//                 ThemeSchema.data[theme ?? "dark"]?.colors["ace-editor-border"]
//               }`,
//               boxShadow: `${
//                 ThemeSchema.data[theme ?? "dark"]?.colors[
//                   "ace-editor-box-shadow"
//                 ]
//               }`,
//             }}
//           />
//         </Modal.Content>
//         <Modal.Actions>
//           <Button primary onClick={() => this.props.close()}>
//             OK
//           </Button>
//         </Modal.Actions>
//       </Modal>
//     );
//   }
// }

class ActionStatus extends Component {
  state = {
    active: false,
    pageNumber: 1,
    pageLimit: 20,
    items: [],
    loading: false,
  };

  interval = null;

  onToggleDetails = () => {
    this.clearStatusFetchInterval();

    if (!this.state.active && this.props.count > 0) {
      this.setState({ loading: true });
      this.interval = setInterval(() => {
        this.fetchStatusDetails(this.state.pageNumber);
      }, 2000);
    }

    this.setState({
      active: !this.state.active,
    });
  };

  clearStatusFetchInterval() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  componentWillUnmount() {
    this.clearStatusFetchInterval();
  }

  onPaginationChange = (event, { activePage }) => {
    this.setState({
      pageNumber: activePage,
    });
    this.fetchStatusDetails(activePage);
  };

  async fetchStatusDetails(pageNumber) {
    let { actionId, status, phaseIndex } = this.props;
    let { pageLimit } = this.state;
    try {
      const res = await fetchDeviceActionStatusDetailsWithPhase(
        actionId,
        status,
        pageNumber,
        pageLimit,
        phaseIndex
      );
      this.setState({
        items: res.device_actions,
        loading: false,
      });
    } catch (e) {
      console.log(e);
    }
  }

  renderItems() {
    if (this.state.items.length === 0) {
      return (
        <div
          style={{
            height: "20vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ErrorMessage
            marginTop="5px"
            message={`No devices in ${this.props.status} state`}
          />
        </div>
      );
    }
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Device ID</Table.HeaderCell>
            <Table.HeaderCell>Progress (%)</Table.HeaderCell>
            <Table.HeaderCell>Errors</Table.HeaderCell>
            <Table.HeaderCell>Last updated at</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.state.items.map((item) => {
            return (
              <Table.Row key={item.device_id}>
                <Table.Cell>{item.device_id}</Table.Cell>
                <Table.Cell>
                  <Progress
                    progress
                    percent={item.progress}
                    color={this.props.className}
                    className="progress-bar-action-status"
                  />
                </Table.Cell>
                <Table.Cell>{item.errors.join("; ")}</Table.Cell>
                <Table.Cell>
                  {item.updated_at
                    ? capitalizeFirstLetter(moment(item.updated_at).fromNow())
                    : "--"}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  }

  render() {
    const progressLineStyle = {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: "20px",
    };

    const progressStyle = {
      width: "50%",
      marginBottom: "0px",
      marginTop: "0px",
    };

    return (
      <div className="action">
        <span className={`${this.props.className} title`}>
          {this.props.status}
        </span>
        <Accordion>
          <Accordion.Title
            active={this.state.active}
            onClick={this.onToggleDetails}
          >
            <div style={progressLineStyle}>
              <Progress
                progress="ratio"
                value={this.props.count}
                total={this.props.total}
                className={this.props.className}
                style={progressStyle}
              />
              <Icon name="dropdown" />
              Details
            </div>
          </Accordion.Title>
          <Accordion.Content active={this.state.active}>
            {this.state.loading ? (
              // Loading Inline
              <LoadingAnimation
                loadingText={`Loading devices in ${this.props.status} state`}
                fontSize="20px"
              />
            ) : (
              <>
                {this.renderItems()}

                {this.props.count > 0 && (
                  <Pagination
                    boundaryRange={0}
                    defaultActivePage={this.state.pageNumber}
                    ellipsisItem={null}
                    siblingRange={2}
                    totalPages={Math.ceil(
                      this.props.count / this.state.pageLimit
                    )}
                    onPageChange={this.onPaginationChange.bind(this)}
                  />
                )}
              </>
            )}
          </Accordion.Content>
        </Accordion>
      </div>
    );
  }
}

class BatchedAction extends Component {
  state = {
    openPayloadModal: false,
    selectedPhase: "all", // Initially, display all phases
    statuses: this.props.action?.statuses || {},
    totalNumber: Object.values(this.props.action?.statuses).reduce(
      (a, b) => a + b
    ),
  };

  async markAsCompleted() {
    let actionId = this.props.action.action_id;

    try {
      await markActionAsCompleted(actionId);
      window.toastr.success(`Marked ${actionId} as completed!`);
    } catch (e) {
      console.log(e);
    }
  }

  // Function to handle phase selection
  handlePhaseSelect = (phaseName) => {
    // update status data here in state
    if (phaseName === "all") {
      let totalDevicesInPhase = Object.values(
        this.props.action?.statuses
      ).reduce((a, b) => a + b);
      this.setState({
        selectedPhase: phaseName,
        statuses: this.props.action?.statuses || {},
        totalNumber: totalDevicesInPhase,
      });
    } else {
      let phaseStatuses = {};
      let phaseIndex = this.props.action?.schedule?.phases.findIndex(
        (phase) => phase.name === phaseName
      );
      if (phaseIndex >= 0) {
        phaseStatuses =
          this.props.action?.statuses_phased["" + phaseIndex] || {};
      }
      let totalDevicesInPhase = Object.values(phaseStatuses).reduce(
        (a, b) => a + b
      );
      this.setState({
        selectedPhase: phaseName,
        statuses: phaseStatuses,
        totalNumber: totalDevicesInPhase,
      });
    }
  };

  openViewPayloadModal() {
    this.setState({
      openPayloadModal: true,
    });
  }

  closeViewPayloadModal = () => {
    this.setState({
      openPayloadModal: false,
    });
  };

  getPhaseTimestamp = () => {
    let phaseIndex = this.props.action?.schedule?.phases.findIndex(
      (phase) => phase.name === this.state.selectedPhase
    );
    if (phaseIndex >= 0) {
      let returnTimeStamp =
        this.props.action?.schedule?.phases[phaseIndex].timestamp;
      return capitalizeFirstLetter(moment(returnTimeStamp).fromNow());
    }
    return "";
  };

  render() {
    const { action_id, type, user_name, statuses, created_at } =
      this.props.action;

    let phases = this.props.action?.schedule?.phases || [];

    const numTotal = Object.values(statuses).reduce((a, b) => a + b);

    let { Queued, Initiated, Completed, Failed, ...remaining } =
      this.state.statuses; //all statuses in all phases

    const messageHeaderStyle = {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    };

    const showPayloadTxt =
      this.props.action?.params?.length > 0 &&
      this.props.action?.params !== "{}";
    let version_text = "";
    if (showPayloadTxt && this.props.action.params) {
      let parsedParams;
      try {
        parsedParams = JSON.parse(this.props.action.params);
      } catch (e) {
        console.log("error in parsing Action params");
        console.log(e);
      }
      if (
        parsedParams?.version &&
        (type === "update_firmware" || type === "update_config")
      ) {
        version_text = `${parsedParams.version}`;
      }
    }

    return (
      <>
        <ViewPayloadModal
          isOpen={this.state.openPayloadModal}
          onClose={this.closeViewPayloadModal}
          actionId={action_id}
        />

        <StyledCard>
          <Message.Header
            style={{
              marginBottom: "16px",
            }}
          >
            <div style={messageHeaderStyle}>
              <MessageHeaderTitleStyle>
                <span>
                  {`${action_id}. ${user_name} triggered ${getActionName(type)} ${
                    version_text !== "" ? " [" + version_text + "]" : ""
                  } `}
                </span>
                <span className="deviceAndTimeMessage">
                  {`[${
                    numTotal === 1 ? "1 device" : numTotal + " devices"
                  } | ${capitalizeFirstLetter(moment(created_at).fromNow())}]`}
                </span>
              </MessageHeaderTitleStyle>
              <div>
                {(Completed || 0) + (Failed || 0) < numTotal ? (
                  <DisplayIf
                    cond={
                      this.props.user.role.permissions
                        .allowMarkActionAsCompleted
                    }
                  >
                    <Button onClick={this.markAsCompleted.bind(this)} secondary>
                      Mark as completed
                    </Button>
                  </DisplayIf>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Message.Header>

          <div>
            {/* Phase selection buttons */}
            {phases && phases.length > 0 && (
              <div
                style={{ marginBottom: "16px", display: "flex", gap: "10px" }}
              >
                <ButtonWithIcon
                  backgroundcolor={
                    this.state.selectedPhase === "all" ? "grey" : "black"
                  }
                  type="outlined"
                  onClick={() => this.handlePhaseSelect("all")}
                >
                  All Phases
                </ButtonWithIcon>

                {phases.map((phase, index) => (
                  <ButtonWithIcon
                    key={index}
                    backgroundcolor={
                      this.state.selectedPhase === phase.name ? "grey" : "black"
                    }
                    type="outlined"
                    onClick={() => this.handlePhaseSelect(phase.name)}
                  >
                    {phase.name}
                  </ButtonWithIcon>
                ))}
              </div>
            )}

            {/* Your existing card */}
            <Message.Content>
              <PhaseDetailsTitleStyle>
                <span className="deviceAndTimeMessage">
                  {phases && phases.length > 0 ? (
                    this.state.selectedPhase === "all" ? (
                      <p>&nbsp;</p>
                    ) : (
                      <p>{`[Schedule: ${this.getPhaseTimestamp()}]`}</p>
                    )
                  ) : (
                    <></>
                  )}
                </span>
              </PhaseDetailsTitleStyle>
              {Queued > 0 ? (
                <ActionStatus
                  actionId={action_id}
                  status="Queued"
                  count={Queued}
                  total={this.state.totalNumber || numTotal}
                  className="queued"
                  phaseIndex={this.props.action?.schedule?.phases.findIndex(
                    (phase) => phase.name === this.state.selectedPhase
                  )}
                />
              ) : (
                ""
              )}

              {Initiated > 0 ? (
                <ActionStatus
                  actionId={action_id}
                  status="Initiated"
                  count={Initiated}
                  total={this.state.totalNumber || numTotal}
                  className="initiated"
                  phaseIndex={this.props.action?.schedule?.phases.findIndex(
                    (phase) => phase.name === this.state.selectedPhase
                  )}
                />
              ) : (
                ""
              )}

              {Object.keys(remaining).map((status) => (
                <ActionStatus
                  key={action_id}
                  actionId={action_id}
                  status={status}
                  count={remaining[status]}
                  total={this.state.totalNumber || numTotal}
                  className="in-progress"
                  phaseIndex={this.props.action?.schedule?.phases.findIndex(
                    (phase) => phase.name === this.state.selectedPhase
                  )}
                />
              ))}

              <ActionStatus
                actionId={action_id}
                status="Completed"
                count={Completed || 0}
                total={this.state.totalNumber || numTotal}
                className="completed"
                phaseIndex={this.props.action?.schedule?.phases.findIndex(
                  (phase) => phase.name === this.state.selectedPhase
                )}
              />
              {Failed > 0 ? (
                <ActionStatus
                  actionId={action_id}
                  status="Failed"
                  count={Failed}
                  total={this.state.totalNumber || numTotal}
                  className="failed"
                  phaseIndex={this.props.action?.schedule?.phases.findIndex(
                    (phase) => phase.name === this.state.selectedPhase
                  )}
                />
              ) : (
                ""
              )}
            </Message.Content>
          </div>
          {showPayloadTxt ? (
            <ViewPayloadTxt>
              <p onClick={() => this.openViewPayloadModal()}>View Payload</p>
            </ViewPayloadTxt>
          ) : (
            <></>
          )}
        </StyledCard>
      </>
    );
  }
}

class ActionStatusList extends Component {
  state = {
    loading: true,
    actions: [],
    errorOccurred: false,
    showIncompleteTop: false,
    actionsPageNumber: 1,
    actionsPageLimit: 25,
    totalActions: 0,
    newActionModalOpen: false,
  };

  // Flag state to track the operations like pagination and actions per page change
  operationInProgress = false;
  controller = new AbortController();
  _isMounted = false;
  timeoutID = null;

  fetchActions = async (pageNumber) => {
    try {
      const res = await fetchAllActionStatus(
        pageNumber,
        this.state.actionsPageLimit,
        this.state.showIncompleteTop,
        this.controller?.signal
      );
      this.setState({
        loading: false,
        actions: res.actions,
        totalActions: res.count,
        errorOccurred: false,
      });
    } catch (e) {
      console.log(e);
      this.setState({ errorOccurred: true });
    }
  };

  fetchAllActions = async () => {
    if (!this._isMounted) return;
    try {
      if (!this.operationInProgress) {
        await this.fetchActions(this.state.actionsPageNumber);
      }
    } catch (e) {
      console.log(e);
      this.setState({ errorOccurred: true });
    } finally {
      if (this._isMounted) {
        this.timeoutId = setTimeout(this.fetchAllActions, 2 * 1000);
      }
    }
  };

  componentDidMount() {
    document.title = "Action Status | Bytebeam";

    this._isMounted = true;

    this.setState({ loading: true });
    this.fetchAllActions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.actionsPageNumber !== prevState.actionsPageNumber ||
      this.state.showIncompleteTop !== prevState.showIncompleteTop
    ) {
      this.fetchActions(this.state.actionsPageNumber);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  sortClickHandler = async () => {
    this.operationInProgress = true;
    try {
      this.setState({
        showIncompleteTop: !this.state.showIncompleteTop,
        loading: true,
      });

      this.controller.abort();
      this.controller = new AbortController();
    } catch (e) {
      console.log(e);
    } finally {
      this.operationInProgress = false;
    }
  };

  onActionPaginationChange = async (event, { activePage }) => {
    this.operationInProgress = true;
    try {
      this.setState({
        loading: true,
        actionsPageNumber: activePage,
      });

      this.controller.abort();
      this.controller = new AbortController();
    } catch (e) {
      console.log(e);
    } finally {
      this.operationInProgress = false;
    }
  };

  render() {
    if (this.state.loading) {
      return (
        <LoadingAnimation
          loaderContainerHeight="65vh"
          fontSize="1.5rem"
          loadingText="Loading action statuses"
        />
      );
    }

    return (
      <Grid style={{ marginTop: "10px" }}>
        <Grid.Row>
          <Grid.Column width={5}>
            {this.state.actions.length > 0 && (
              <div
                style={{
                  marginTop: "16px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "16px",
                }}
              >
                <div>Sort by Incomplete Actions</div>
                <div>
                  <Toggle
                    id="sortByIncompleteActions"
                    checked={this.state.showIncompleteTop}
                    onClick={() => this.sortClickHandler()}
                    style={{
                      top: "4px",
                    }}
                  />
                </div>
              </div>
            )}
          </Grid.Column>
          <Grid.Column width={11}>
            <NewActionModal
              isOpen={this.state.newActionModalOpen}
              // fetchAllActions is needed to refresh the Action-status page after the action is triggered.
              // fetchAllActions={() => fetchAllActions(actionsPageLimit)}
              onClose={() => this.setState({ newActionModalOpen: false })}
              setLoading={() => {
                this.setState({ loading: true });
              }}
              // Have to change loading text msg here to Triggering ACtion
            />
            <Button
              floated="right"
              primary
              onClick={() => this.setState({ newActionModalOpen: true })}
            >
              Create New Action
            </Button>
          </Grid.Column>
        </Grid.Row>

        {this.state.actions.length > 0 ? (
          <>
            {this.state.actions.map((action, key) => {
              return (
                <Grid.Row key={action.action_id}>
                  <Grid.Column width={16}>
                    <BatchedAction action={action} user={this.props.user} />
                  </Grid.Column>
                </Grid.Row>
              );
            })}
            {!this.state.loading && this.state.actions.length > 0 && (
              <div
                style={{
                  marginTop: "1rem",
                }}
              >
                <Pagination
                  boundaryRange={0}
                  defaultActivePage={this.state.actionsPageNumber}
                  ellipsisItem={null}
                  siblingRange={2}
                  totalPages={Math.ceil(
                    this.state.totalActions / this.state.actionsPageLimit
                  )}
                  onPageChange={this.onActionPaginationChange.bind(this)}
                />
              </div>
            )}
          </>
        ) : (
          <Grid.Row>
            <Grid.Column
              width={16}
              style={{
                height: "60vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ErrorMessage
                marginTop="30px"
                message={"Please trigger an Action to view its progress here"}
              />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    );
  }
}

export default withRouter(ActionStatusList);
