import React, { useState } from "react";
import {
  Dropdown,
  DropdownProps,
  Button,
  Icon,
  IconProps,
  Popup,
} from "semantic-ui-react";
import { TimeRangePicker } from "./TimeRangePicker";
import {
  RelativeTimeRange,
  TimeRange,
  NamedTimeRange,
  AbsoluteTimeRange,
} from "./TimeRange";
import { AbsoluteTimestamp, RelativeTimestamp, Timestamp } from "./Timestamp";
import styled from "styled-components";
import { useUser } from "../../../../context/User.context";
import { TenantSettings } from "../../../../util";
import moment, { DurationInputArg2 } from "moment";
import { HARDWARE_TYPES } from "../../util";

type DateTimeWidgetProps = {
  timeRange: TimeRange;
  onTimeRangeChange: (timeRange: TimeRange) => any;
  disabled: boolean;
  showControls: boolean;
};

const StyledDropdownButton = styled(Button)`
  width: 30px !important;
  padding: 0px !important;
  margin-right: 2px !important;
  background: ${(props) =>
    props.theme.colors["date_time_dropdown_button-background"]} !important;
  color: ${(props) =>
    props.theme.colors["date_time_dropdown_button-color"]} !important;
`;

function DropdownButton(props: IconProps) {
  const newProps = Object.assign({}, props, { onClick: undefined });

  return (
    <StyledDropdownButton icon onClick={props.onClick}>
      <Icon {...newProps} />
    </StyledDropdownButton>
  );
}

export function DateTimeDropdown(props: DateTimeWidgetProps) {
  const { disabled, onTimeRangeChange, showControls, timeRange } = props;

  const [showTimeRangePicker, setShowTimeRangePicker] =
    useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<number>(0);

  const { user, getCurrentUser } = useUser();
  const tenant_settings: TenantSettings = user["tenant-settings"] ?? {
    common_settings: {
      pin_metadata: [],
    },
    dashboard_settings: {
      custom_time_ranges: {},
    },
    hardware_type: HARDWARE_TYPES[0],
  };
  const dashboardSettings = tenant_settings?.dashboard_settings ?? {};
  const customTimeRanges = dashboardSettings?.custom_time_ranges ?? {};

  const customTimeRangesValue: Array<TimeRange> = Object.values(
    customTimeRanges
  )
    .map(
      (range) =>
        new AbsoluteTimeRange(getTimestamp(range.from), getTimestamp(range.to))
    )
    .reverse(); // Reverse so that the most recent custom time range is last
  let defaultRelativeTimeRanges: Array<TimeRange> = [
    ...NamedTimeRange.timeRanges,
    new RelativeTimeRange(5, "minutes"),
    new RelativeTimeRange(15, "minutes"),
    new RelativeTimeRange(30, "minutes"),
    new RelativeTimeRange(1, "hour"),
    new RelativeTimeRange(3, "hours"),
    new RelativeTimeRange(6, "hours"),
    new RelativeTimeRange(24, "hours"),
    new RelativeTimeRange(2, "days"),
  ];

  if (window.location.hostname === "exponent.bytebeam.io") {
    defaultRelativeTimeRanges = [
      new RelativeTimeRange(5, "minutes"),
      new RelativeTimeRange(15, "minutes"),
      new RelativeTimeRange(30, "minutes"),
      new RelativeTimeRange(1, "hour"),
      new RelativeTimeRange(3, "hours"),
      new RelativeTimeRange(6, "hours"),
      new RelativeTimeRange(12, "hours"),
      new RelativeTimeRange(24, "hours"),
      new RelativeTimeRange(2, "days"),
      new RelativeTimeRange(1, "week"),
      new RelativeTimeRange(1, "month"),
    ];
  }

  const defaultRanges: Array<TimeRange> = [
    ...defaultRelativeTimeRanges,
    ...customTimeRangesValue,
  ];

  const dropdownText = `${timeRange.toString()}`;

  let isCustomTimeRange = false;
  //checking if the timeRange text matches the predefined texts
  isCustomTimeRange = defaultRanges.some((range) =>
    range.toString().includes(timeRange.toString())
  );

  const customTimeRangeOption = {
    key: "customTimeRange",
    text: "Custom Time Range",
    value: 0,
    onClick: () => {
      setShowTimeRangePicker(true);
    },
  };

  const options = [
    customTimeRangeOption,
    ...defaultRanges.map((option, i) => {
      return {
        key: i + option.toString(), // Added index for handling duplicate keys
        text:
          i < defaultRelativeTimeRanges.length
            ? option.toString()
            : Object.keys(customTimeRanges).reverse()[ // Reverse so that the most recent custom time range is last
                i - defaultRelativeTimeRanges.length
              ],
        value: i + 1,
      };
    }),
  ];

  function onPredefinedTimeRangePicked(_event: any, data: DropdownProps) {
    if (data.value === 0) {
      setCurrentValue(0);
    } else {
      const value = Number(data.value);
      setCurrentValue(value);
      onTimeRangeChange(defaultRanges[value - 1]);
    }
  }

  function onCustomTimeRangePicked(timeRange: TimeRange) {
    setCurrentValue(0);
    onTimeRangeChange(timeRange);
    setShowTimeRangePicker(false);
  }

  function onShiftTimeRangeLeft() {
    const currentTimeRange = timeRange;
    const start = currentTimeRange.getStartTime().toDate();
    const end = currentTimeRange.getEndTime().toDate();

    const newStart = new Date(
      start.valueOf() - (end.valueOf() - start.valueOf())
    );
    const newEnd = start;

    onCustomTimeRangePicked(
      new AbsoluteTimeRange(
        new AbsoluteTimestamp(newStart),
        new AbsoluteTimestamp(newEnd)
      )
    );
  }

  function onShiftTimeRangeRight() {
    const currentTimeRange = timeRange;
    const start = currentTimeRange.getStartTime().toDate();
    const end = currentTimeRange.getEndTime().toDate();

    const newStart = end;
    const newEnd = new Date(end.valueOf() + (end.valueOf() - start.valueOf()));

    onCustomTimeRangePicked(
      new AbsoluteTimeRange(
        new AbsoluteTimestamp(newStart),
        new AbsoluteTimestamp(newEnd)
      )
    );
  }

  function onZoomInTimeRange() {
    const currentTimeRange = timeRange;
    const start = currentTimeRange.getStartTime().toDate().valueOf();
    const end = currentTimeRange.getEndTime().toDate().valueOf();

    const newLength = (end - start) / 2;
    const midpoint = (start + end) / 2;
    const newStart = new Date(midpoint - newLength / 2);
    const newEnd = new Date(midpoint + newLength / 2);

    onCustomTimeRangePicked(
      new AbsoluteTimeRange(
        new AbsoluteTimestamp(newStart),
        new AbsoluteTimestamp(newEnd)
      )
    );
  }

  function onZoomOutTimeRange() {
    const currentTimeRange = timeRange;
    const start = currentTimeRange.getStartTime().toDate().valueOf();
    const end = currentTimeRange.getEndTime().toDate().valueOf();

    const newLength = (end - start) * 2;
    const midpoint = (start + end) / 2;
    const newStart = new Date(midpoint - newLength / 2);
    const newEnd = new Date(midpoint + newLength / 2);

    onCustomTimeRangePicked(
      new AbsoluteTimeRange(
        new AbsoluteTimestamp(newStart),
        new AbsoluteTimestamp(newEnd)
      )
    );
  }

  function getTimestamp(
    TimestampObject:
      | Timestamp
      | {
          date: string;
        }
      | {
          units: DurationInputArg2;
          duration: number;
        }
  ): Timestamp {
    if ("units" in TimestampObject && "duration" in TimestampObject) {
      const { units, duration } = TimestampObject;
      return new RelativeTimestamp(duration, units);
    } else if ("date" in TimestampObject) {
      const timestamp = moment(
        TimestampObject.date,
        "YYYY-MM-DDTHH:mm:ss.SSSZ"
      );
      return new AbsoluteTimestamp(timestamp.toDate());
    } else {
      throw new Error("Invalid TimestampObject");
    }
  }

  return (
    <>
      <TimeRangePicker
        isOpen={showTimeRangePicker}
        onCancel={() => {
          setShowTimeRangePicker(false);
        }}
        onTimeRangePicked={onCustomTimeRangePicked}
        timeRange={timeRange}
        user={user}
        getCurrentUser={getCurrentUser}
        allCustomTimeRangesLabel={new Set(Object.keys(customTimeRanges))}
      />

      <Button.Group>
        {showControls ? (
          <DropdownButton name="angle left" onClick={onShiftTimeRangeLeft} />
        ) : (
          <></>
        )}

        <Popup
          inverted
          position="top left"
          content="Time Range"
          style={{ marginBottom: "6px" }}
          trigger={
            <Dropdown
              style={{
                minWidth: "150px",
                borderRadius: "0px",
                marginRight: "2px",
                border: "none",
                "&:focus": {
                  border: "none",
                },
              }}
              text={dropdownText}
              options={options}
              button
              selection
              onChange={onPredefinedTimeRangePicked}
              disabled={disabled}
              value={isCustomTimeRange ? currentValue : 0}
            />
          }
        />

        {showControls ? (
          <>
            <DropdownButton name="zoom in" onClick={onZoomInTimeRange} />
            <DropdownButton name="zoom out" onClick={onZoomOutTimeRange} />
            <DropdownButton
              name="angle right"
              onClick={onShiftTimeRangeRight}
            />
          </>
        ) : (
          <></>
        )}
      </Button.Group>
    </>
  );
}
