import React from "react";
import { OptionType } from "../util";
import SelectOrUploadFirmwareAccordion from "./SelectOrUploadFirmwareAccordion";
import { DropdownOptionType } from "../../DeviceMangaement/Devices/ActionModals/UpdateConfigModal";

type SelectOrUploadFirmwareProps = {
  setDisableNextButton: (arg0: boolean) => void;
  setOptionType: (arg0: OptionType) => void;
  optionType: OptionType;
  uploadTriggered: string;
  setUploadTriggered: (arg0: string) => void;
  uploadTriggeredEach: boolean;
  setActiveModal: (arg0: number) => void;
  activeModal: number;
  customFirmwareInputData: string;
  setCustomFirmwareInputData: (arg0: string) => void;
  firmwareActiveIndex: number;
  setFirmwareActiveIndex: (arg0: number) => void;
  selectedFirmwareVersion: DropdownOptionType;
  setSelectedFirmwareVersion: (arg0: DropdownOptionType) => void;
  firmwareOptions: DropdownOptionType[];
  fillFirmwareDropdownOptions: () => void;
  selectedCustomFirmwareVersion: string;
  setSelectedCustomFirmwareVersion: (arg0: string) => void;
  allFirmwareOptions: Set<any> | undefined;
  firmwareFile: File;
  setFirmwareFile: (arg0: File) => void;
  firmwareFileName: string;
  setFirmwareFileName: (arg0: string) => void;
  showFirmwareUploadProgress: boolean;
  setShowFirmwareUploadProgress: (arg0: boolean) => void;
  firmwareFileLoaded: number;
  setFirmwareFileLoaded: (arg0: number) => void;
  firmwareFileTotal: number;
  setFirmwareFileTotal: (arg0: number) => void;
  uploadedFirmwareFileResponse: {
    status: number;
    data: { id: string };
  };
  setUploadedFirmwareFileResponse: (arg0: {
    status: number;
    data: { id: string };
  }) => void;
  firmwareOptionsLoaded: boolean;
  firmwareFileInput: any;
};

export default function SelectOrUploadFirmware(
  props: SelectOrUploadFirmwareProps
) {
  return (
    <SelectOrUploadFirmwareAccordion
      setDisableNextButton={(value) => props.setDisableNextButton(value)}
      setOptionType={(option) => props.setOptionType(option)}
      optionType={props.optionType}
      uploadTriggered={props.uploadTriggered}
      setUploadTriggered={(value) => props.setUploadTriggered(value)}
      setActiveModal={(index) => props.setActiveModal(index)}
      activeModal={props.activeModal}
      customFirmwareInputData={props.customFirmwareInputData}
      setCustomFirmwareInputData={(version) =>
        props.setCustomFirmwareInputData(version)
      }
      uploadTriggeredEach={props.uploadTriggeredEach}
      activeIndex={props.firmwareActiveIndex}
      setActiveIndex={(index) => props.setFirmwareActiveIndex(index)}
      selectedFirmwareVersion={props.selectedFirmwareVersion}
      setSelectedFirmwareVersion={(version) =>
        props.setSelectedFirmwareVersion(version)
      }
      firmwareOptions={props.firmwareOptions}
      fillFirmwareDropdownOptions={() => props.fillFirmwareDropdownOptions()}
      selectedCustomFirmwareVersion={props.selectedCustomFirmwareVersion}
      setSelectedCustomFirmwareVersion={(version) =>
        props.setSelectedCustomFirmwareVersion(version)
      }
      allFirmwareOptions={props.allFirmwareOptions}
      firmwareFile={props.firmwareFile}
      setFirmwareFile={(file) => props.setFirmwareFile(file)}
      firmwareFileName={props.firmwareFileName}
      setFirmwareFileName={(name) => props.setFirmwareFileName(name)}
      showFirmwareUploadProgress={props.showFirmwareUploadProgress}
      setShowFirmwareUploadProgress={(progress) =>
        props.setShowFirmwareUploadProgress(progress)
      }
      firmwareFileLoaded={props.firmwareFileLoaded}
      setFirmwareFileLoaded={(value) => props.setFirmwareFileLoaded(value)}
      firmwareFileTotal={props.firmwareFileTotal}
      setFirmwareFileTotal={(value) => props.setFirmwareFileTotal(value)}
      uploadedFirmwareFileResponse={props.uploadedFirmwareFileResponse}
      setUploadedFirmwareFileResponse={(res) =>
        props.setUploadedFirmwareFileResponse(res)
      }
      firmwareOptionsLoaded={props.firmwareOptionsLoaded}
      firmwareFileInput={props.firmwareFileInput}
    />
  );
}
