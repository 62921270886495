import React from "react";

type ConfirmationModalMessageNonDeleteProps = {
  readonly name: string;
  readonly type: string;
  readonly specialMessage?: string;
  readonly expectedText: string;
};

function ConfirmationModalMessageNonDelete(
  props: ConfirmationModalMessageNonDeleteProps,
) {
  const { name, specialMessage, expectedText } = props;
  return (
    <>
      <p>
        <span>Are you absolutely sure?</span>
        <br />
        You are going to <b>{name}</b>.
        <br />
        {specialMessage && (
          <b>
            <em>{specialMessage}</em>
          </b>
        )}
      </p>
      <div
        style={{
          padding: "8px",
          color: "#c50000",
          textAlign: "center",
          background: "#f7d4d6",
          border: "1px solid #c50000",
          borderRadius: "4px",
        }}
      >
        <b>Warning:</b> This action is not reversible. Please be certain.
      </div>
      <p>
        <br /> Please type <b>{expectedText}</b> to proceed or press Cancel to
        close.
      </p>
    </>
  );
}

export default ConfirmationModalMessageNonDelete;
