import { TimeseriesTableData, TimeseriesTableMetaData } from "./PanelDef";
import React from "react";
import { PanelViewComponent } from "../PanelDef";
import {
  PaginationProps,
  Table,
  TableHeaderCell,
  Dimmer,
  Popup,
  Icon,
  MenuItem,
} from "semantic-ui-react";
import { formatValue, TableContainer } from "../util";
import {
  fetchPanelData,
  fetchPanelDataFixedTimeRange,
  rowsPerPageOptions,
} from "../../../../../BytebeamClient";
import LoadingAnimation from "../../../../common/Loader";
import styled from "styled-components";
import { validateWholeNumber } from "../../../../../util";
import {
  StyledCardSearchPageInput,
  StyledPagination,
  StyledSecondaryDevicePerPageWidget,
} from "../../../../common/commonStyledComps";
import { SelectDevicesPerPage } from "../../../DeviceMangaement/Devices/Devices";

const TimeRangeContainer = styled.div`
  height: fit-content;
  margin: 4px 0px 4px 16px;
  padding: 10px 12px;
  background: ${({ theme }) =>
    theme.colors["time-range_container-background"]} !important;
  color: ${({ theme }) => theme.colors["time-range_container-color"]};
  border-radius: 4px;
  border: ${({ theme }) =>
    theme.colors["time-range_container-border"]} !important;
  box-shadow: ${({ theme }) =>
    theme.colors["time-range_container-box_shadow"]} !important;
`;

type ViewTimeseriesTableState = {
  data?: TimeseriesTableData;
  sortOrder?: string;
  currentPage: number;
  loading: boolean;
  currentStartTimestamp?: number;
  currentEndTimestamp?: number;
  inputPageNumber: number;
  devicesPerPage: number;
  totalPages: number;
};

export class ViewTimeseriesTable extends PanelViewComponent<
  TimeseriesTableMetaData,
  TimeseriesTableData,
  ViewTimeseriesTableState
> {
  state = {} as ViewTimeseriesTableState;

  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      devicesPerPage: 20,
      inputPageNumber: 0,
      totalPages: 10,
      loading: false,
    };
  }

  handlePaginationInputChange = (event) => {
    const newValue = event.target.value;
    this.setState({
      inputPageNumber: Number(newValue),
    });
  };

  handlePaginationInputKeyDown = (event, totalPages) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      // If the pressed key is "Enter", trigger the function for changing active page
      if (validateWholeNumber(this.state.inputPageNumber.toString())) {
        this.handlePaginationChange(event, {
          activePage:
            this.state.inputPageNumber && this.state.inputPageNumber > 0
              ? this.state.inputPageNumber > totalPages
                ? (totalPages as number)
                : this.state.inputPageNumber
              : 1,
          totalPages: totalPages,
        });
        this.setState({
          inputPageNumber: 0,
        });
      } else {
        window.toastr.error("Please enter whole number for jump to page.");
      }
    }
  };

  async handlePaginationChange(
    event: React.MouseEvent<HTMLAnchorElement>,
    data: PaginationProps
  ) {
    if (data.activePage) {
      const sortOrder = this.state.sortOrder || this.props.panelMeta.sortOrder;
      this.refreshDataStateTime(
        data.activePage as number,
        sortOrder,
        this.state.devicesPerPage ?? this.props.panelMeta.rowsPerPage
      );
    }
  }

  changeDevicesPerPage(e, data) {
    try {
      this.setState({
        devicesPerPage: parseInt(data.value),
        currentPage: 1,
      });
      const sortOrder = this.state.sortOrder || this.props.panelMeta.sortOrder;
      this.refreshDataStateTime(1, sortOrder, data.value);
    } catch (e) {
      window.toastr.error("Failed to change number of devices per page");
      console.error("Error in changeDeviceStatus: ", e);
    }
  }

  async refreshDataStateTime(
    pageNumber: number,
    sortOrder: string,
    devicesPerPage: number
  ) {
    try {
      this.setState({ loading: true });
      const panelMeta: TimeseriesTableMetaData = Object.assign(
        {},
        this.props.panelMeta,
        {
          rowsPerPage: devicesPerPage,
          page: pageNumber,
          sortOrder: sortOrder,
        }
      );
      const response = await fetchPanelDataFixedTimeRange(
        [panelMeta],
        this.props.fetchParams,
        this.state.currentStartTimestamp,
        this.state.currentEndTimestamp
      );
      this.setState({
        data: response[0].data,
        currentPage: pageNumber,
        sortOrder: sortOrder,
        totalPages: Math.ceil(response[0].data.totalRows / devicesPerPage),
        loading: false,
      });
    } catch (error) {
      // Removing Toast here, so that no red toast on network issues.
      console.error(
        "Error occurred in viewDashboard viewTimeSeriesTable refreshData. ",
        error
      );
    }
  }

  async refreshData(
    pageNumber: number,
    sortOrder: string,
    devicesPerPage: number
  ) {
    try {
      this.setState({ loading: true });
      const panelMeta = Object.assign({}, this.props.panelMeta, {
        rowsPerPage: devicesPerPage,
        page: pageNumber,
        sortOrder: sortOrder,
      });
      this.setState({
        currentStartTimestamp: this.props.fetchParams.timeRange
          .getStartTime()
          .toDate()
          .valueOf(),
        currentEndTimestamp: this.props.fetchParams.timeRange
          .getEndTime()
          .toDate()
          .valueOf(),
      });
      await fetchPanelData([panelMeta], this.props.fetchParams, null, null);
      // Setting the state as undefined here, as the component will re-render and instead of reading this.state.data, it will read this.props.data
      this.setState({
        data: undefined,
        currentPage: 1,
        sortOrder: sortOrder,
        loading: false,
      });
    } catch (error) {
      // Removing Toast here, so that no red toast on network issues.
      console.error(
        "Error occurred in viewDashboard viewTimeSeriesTable refreshData. ",
        error
      );
    }
  }

  onChangeSortOrder() {
    const sortOrder = this.state.sortOrder || this.props.panelMeta.sortOrder;
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    this.refreshDataStateTime(
      this.state.currentPage,
      newSortOrder,
      this.state.devicesPerPage
    );
  }

  onRefresh() {
    const currentPage = 1;
    this.refreshData(
      currentPage,
      this.state.sortOrder ?? this.props.panelMeta.sortOrder,
      this.state.devicesPerPage ?? this.props.panelMeta.rowsPerPage
    );
  }

  renderValue(column, value) {
    // Early return if the value is a sequence
    if (column === "sequence") {
      return value;
    }

    // Retrieve the column type
    const columnMeta = this.props.tables[this.props.panelMeta.table]?.find(
      (item) => item?.name === column
    );
    const columnType = columnMeta?.type;

    // Check if the column is a date or timestamp
    if (column.includes("timestamp") || columnType?.includes("Date")) {
      // Return formatted date
      return new Date(value).toLocaleString("en-GB");
    }

    // For other cases, use a separate formatter function
    return formatValue(value, this.props.panelMeta.showNumericValuesInHex);
  }

  componentDidMount(): void {
    const data = this.state.data || this.props.data;
    this.setState({
      currentStartTimestamp: this.props.fetchParams.timeRange
        .getStartTime()
        .toDate()
        .valueOf(),
      currentEndTimestamp: this.props.fetchParams.timeRange
        .getEndTime()
        .toDate()
        .valueOf(),
      totalPages: Math.ceil(data.totalRows / this.state.devicesPerPage),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data.totalRows !== this.props.data.totalRows) {
      const data = this.state.data || this.props.data;
      this.setState({
        totalPages: Math.ceil(data.totalRows / this.state.devicesPerPage),
      });
    }
  }

  render() {
    // The panel reads state data if it is present, else it reads props data.
    // We set the state data only when the user interacts with the panel, like changing the page number or changing the sort order.
    // When the user interacts with the panel, we freeze the timerange and do pagination inside that timerange. When he resets, then we again use global/relative timerange and show data from props.
    const data = this.state.data ?? this.props.data;
    if (this.state.data) {
      console.log(`[DEV]TSState data`, this.state.currentPage);
    }
    const stateData = this.state.data;

    const columns = this.props.panelMeta.columns;

    const sortOrder =
      (this.state.sortOrder || this.props.panelMeta.sortOrder) === "asc"
        ? "ascending"
        : "descending";

    if (data?.data && data.data.length > 0) {
      return (
        <TableContainer>
          <div className="tableContentContainer">
            <Dimmer.Dimmable dimmed={this.state.loading}>
              <Dimmer active={this.state.loading}>
                <LoadingAnimation loadingText="Loading" loaderSize="42px" />
              </Dimmer>

              <Table
                sortable={!this.state.loading}
                compact
                selectable
                unstackable
                size="small"
              >
                <Table.Header>
                  <Table.Row>
                    <TableHeaderCell style={{ cursor: "unset" }}>
                      Device ID
                    </TableHeaderCell>
                    <TableHeaderCell
                      sorted={sortOrder}
                      onClick={this.onChangeSortOrder.bind(this)}
                    >
                      Timestamp
                    </TableHeaderCell>

                    {columns.map((column) => (
                      <TableHeaderCell style={{ cursor: "unset" }} key={column}>
                        {column}
                      </TableHeaderCell>
                    ))}
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {data.data.map((p, i) => {
                    return (
                      <Table.Row key={`${p.id}-${p.timestamp}-${i}`}>
                        <Table.Cell>{p.id}</Table.Cell>
                        <Table.Cell>
                          {new Date(p.timestamp).toLocaleString("en-GB")}
                        </Table.Cell>
                        {columns.map((column) => (
                          <Table.Cell key={column}>
                            {this.renderValue(column, p[column])}
                          </Table.Cell>
                        ))}
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {!this.state.loading && this.state.totalPages !== 0 && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: "16px",
                    }}
                  >
                    <StyledPagination
                      activePage={this.state.currentPage}
                      siblingRange={1}
                      totalPages={this.state.totalPages}
                      onPageChange={this.handlePaginationChange.bind(this)}
                    />

                    <StyledCardSearchPageInput
                      icon="search"
                      placeholder="Jump to page..."
                      name="activePage"
                      min={1}
                      onChange={this.handlePaginationInputChange}
                      onKeyDown={(event) =>
                        this.handlePaginationInputKeyDown(
                          event,
                          this.state.totalPages
                        )
                      }
                      type="number"
                      value={
                        this.state.inputPageNumber
                          ? this.state.inputPageNumber
                          : ""
                      }
                    />
                    <StyledSecondaryDevicePerPageWidget>
                      <MenuItem>Devices per page</MenuItem>
                      <MenuItem style={{ padding: "0px" }}>
                        <SelectDevicesPerPage
                          pointing="bottom"
                          compact
                          selection
                          options={rowsPerPageOptions}
                          value={this.state.devicesPerPage}
                          onChange={this.changeDevicesPerPage.bind(this)}
                        />
                      </MenuItem>
                    </StyledSecondaryDevicePerPageWidget>
                  </div>
                )}

                {stateData &&
                this.state.currentStartTimestamp &&
                this.state.currentEndTimestamp ? (
                  <TimeRangeContainer>
                    Timerange:{" "}
                    {new Date(this.state.currentStartTimestamp).toLocaleString(
                      "en-GB"
                    )}{" "}
                    -{" "}
                    {new Date(this.state.currentEndTimestamp).toLocaleString(
                      "en-GB"
                    )}
                    <Popup
                      content="Timerange fixed due to panel interaction. Click to refresh"
                      position="top center"
                      inverted
                      trigger={
                        <Icon
                          name="redo"
                          color="blue"
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                          onClick={this.onRefresh.bind(this)}
                        />
                      }
                    />
                  </TimeRangeContainer>
                ) : (
                  <></>
                )}
              </div>
            </Dimmer.Dimmable>
          </div>
        </TableContainer>
      );
    } else {
      return <div className="panel-no-data">No Data</div>;
    }
  }
}
